import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  DateTime: any;
  JSON: any;
  JSONObject: any;
  Json: any;
};

/** Account info */
export type Account = {
  accountOrganization?: Maybe<Array<OrganizationInfo>>;
  accountOrganizationDivisionsAccess: Array<Organization>;
  accountOrganizationDivisionsAccessCount?: Maybe<Scalars['Float']>;
  /** Account status object */
  accountStatus?: Maybe<AccountStatus>;
  accountStatusId: Scalars['Int'];
  accountSystemRoles: Array<SystemRole>;
  accountUrlTypes?: Maybe<Array<AccountUrlType>>;
  artists: Array<Artist>;
  /** Account billing info */
  billingInfo?: Maybe<AccountBillingInfo>;
  /** Country object */
  country?: Maybe<Country>;
  countryId: Scalars['Int'];
  created: Scalars['DateTime'];
  /** Divisions that user belongs to */
  divisions?: Maybe<Array<Organization>>;
  email: Scalars['String'];
  /** Holds the generated token value, till it gets expired or the email update verification done successfully. */
  emailResetToken?: Maybe<Scalars['String']>;
  /** Holds the value to reset the original account email till it gets verified */
  emailToReset?: Maybe<Scalars['String']>;
  /** Returns organization features */
  features?: Maybe<Array<Feature>>;
  firstName: Scalars['String'];
  former?: Maybe<Scalars['Boolean']>;
  /** Primary ID */
  id: Scalars['Int'];
  ipcInfo: IpcInfo;
  /** Checks if user is in role Independent Project Creator */
  isAccountIPC: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  /** Checks if user is in role Casting Director */
  isCCD: Scalars['Boolean'];
  /** Checks if user is in role Independent Project Creator */
  isIPC: Scalars['Boolean'];
  /** Checks if the current/switched user is PC or not */
  isInPCContext: Scalars['Boolean'];
  /** Language Info object */
  language?: Maybe<LanguageInfo>;
  languageLocaleId?: Maybe<Scalars['Int']>;
  lastActiveSession?: Maybe<Scalars['DateTime']>;
  lastArtistId?: Maybe<Scalars['Int']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  /** Legacy email */
  legacyEmail?: Maybe<Scalars['String']>;
  /** Legacy instance */
  legacyInstance?: Maybe<Scalars['String']>;
  /** Legacy login */
  legacyLogin?: Maybe<Scalars['String']>;
  legacyProfileStatus?: Maybe<ProfileStatus>;
  linkedAccountDivisionCount?: Maybe<Scalars['Float']>;
  linkedAccountOrganization?: Maybe<Array<Organization>>;
  linkedOrganization?: Maybe<Scalars['Float']>;
  linkedToAccountId?: Maybe<Scalars['Int']>;
  loginAttemptCount?: Maybe<Scalars['Int']>;
  loginAttemptFailedAt?: Maybe<Scalars['DateTime']>;
  notificationSubscriptions: Array<NotificationTypeNotificationSubscription>;
  organizationDivisionsCount?: Maybe<Scalars['Float']>;
  /** Organizations that user belongs to */
  organizations?: Maybe<Array<Organization>>;
  passwordHash?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  preferences: AccountPreferences;
  /** Roster invitation token to hold the invitation token till logged in user will not create the profile using it */
  rosterInvitationToken?: Maybe<Scalars['String']>;
  /** @deprecated Use artist active subscription */
  subscription?: Maybe<ActiveSubscriptionModel>;
  /** system roles */
  systemRoles?: Maybe<Array<Scalars['String']>>;
  systemRolesIds?: Maybe<Array<Scalars['Float']>>;
  termsOfUse: Scalars['Boolean'];
  /** Time zone object */
  timeZone?: Maybe<TimeZone>;
  /** Updated at Date */
  updated?: Maybe<Scalars['DateTime']>;
};

/** Account info */
export type AccountAccountOrganizationArgs = {
  systemRoleCode?: InputMaybe<Array<Scalars['String']>>;
};

/** Account info */
export type AccountOrganizationsArgs = {
  systemRoleCode?: InputMaybe<Array<Scalars['String']>>;
};

export type AccountActiveOnboardedPaypal = {
  accountId: Scalars['Float'];
  artistId: Scalars['Float'];
  legacyInstance: Scalars['String'];
  paypalCode: Scalars['String'];
};

/** Account billing info */
export type AccountBillingInfo = {
  cardExpirationMonth?: Maybe<Scalars['Int']>;
  cardExpirationYear?: Maybe<Scalars['Int']>;
  cardType?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AccountContext = {
  artistId?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['Float']>;
  role?: Maybe<ContextRole>;
};

/** Creates a user account based on given input values */
export type AccountCreateInput = {
  countryId: Scalars['ID'];
  /** Closest casting market for PC user */
  dmaId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  languageLocaleId?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  /** Marketing option subscription option */
  marketingOptIn?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  professionalBio?: InputMaybe<Scalars['String']>;
  profileAccessToken?: InputMaybe<Scalars['String']>;
  rosterInvitationToken?: InputMaybe<Scalars['String']>;
  systemRoleCode?: InputMaybe<SystemRoleEnum>;
  termsOfUse: Scalars['Boolean'];
  timeZoneId: Scalars['Int'];
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
};

export type AccountItem = {
  accountStatus?: Maybe<AccountStatus>;
  accountSystemRole?: Maybe<Array<SystemRole>>;
  created?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emailToReset?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  lastSuccessfulLogin?: Maybe<Scalars['DateTime']>;
  legacyEmail?: Maybe<Scalars['String']>;
  legacyInstance?: Maybe<Scalars['String']>;
  legacyLogin?: Maybe<Scalars['String']>;
  legacyProfileStatus?: Maybe<ProfileStatus>;
  organizationIds?: Maybe<Array<AccountOrganizationItem>>;
  phone?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type AccountItemPagedList = {
  data: Array<AccountItem>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum AccountOnboardingStatusEnum {
  BauActive = 'BAU_ACTIVE',
  BauUnclaimed = 'BAU_UNCLAIMED',
  BauUnverified = 'BAU_UNVERIFIED',
  BauVerified = 'BAU_VERIFIED',
}

export type AccountOrganizationItem = {
  admin?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  organizationId: Scalars['Float'];
  organizationParentId?: Maybe<Scalars['Float']>;
};

/** Get Onboarded Active Paypal Accounts Input */
export type AccountPaypalLocationInput = {
  /** Paypal locations */
  locations: Array<PaypalLocation>;
};

export type AccountPreferences = {
  projectListFilters?: Maybe<AccountProjectFiltersPreferences>;
  schedule?: Maybe<AccountSchedulePreferences>;
};

export type AccountProjectFiltersInput = {
  castingOrganizationIds?: InputMaybe<Array<Scalars['Float']>>;
  dmaIds?: InputMaybe<Array<Scalars['Float']>>;
  hasRequests?: InputMaybe<Scalars['Boolean']>;
  hasSelects?: InputMaybe<Scalars['Boolean']>;
  hasSubmissions?: InputMaybe<Scalars['Boolean']>;
  isKidsRolesOnly?: InputMaybe<Scalars['Boolean']>;
  projectName?: InputMaybe<Scalars['String']>;
  projectTypeIds?: InputMaybe<Array<Scalars['Float']>>;
  projectUnionIds?: InputMaybe<Array<Scalars['Float']>>;
  projectViewStatus?: InputMaybe<ProjectViewFilterType>;
  roleName?: InputMaybe<Scalars['String']>;
  roleTypes?: InputMaybe<Array<Scalars['String']>>;
  submissionDueDate?: InputMaybe<Scalars['String']>;
};

export type AccountProjectFiltersPreferences = {
  /** Casting Organisation Search Settings */
  castingOrganizations?: Maybe<Array<Organization>>;
  hasRequests?: Maybe<Scalars['Boolean']>;
  hasSelects?: Maybe<Scalars['Boolean']>;
  hasSubmissions?: Maybe<Scalars['Boolean']>;
  isKidsRolesOnly?: Maybe<Scalars['Boolean']>;
  projectName?: Maybe<Scalars['String']>;
  /** Region Search Settings */
  projectRegion?: Maybe<Array<Dma>>;
  /** Project Types Search Settings */
  projectTypes?: Maybe<Array<ProjectType>>;
  /** Project Union Search Settings */
  projectUnion?: Maybe<Array<TalentUnion>>;
  projectViewStatus?: Maybe<ProjectViewFilterType>;
  roleName?: Maybe<Scalars['String']>;
  /** Role Types Search Settings */
  roleTypes?: Maybe<Array<RoleType>>;
  submissionDueDate?: Maybe<Scalars['String']>;
};

/** Account Recovery Type by EMAILANDPHONE|PROJECT|STAGE */
export enum AccountRecoveryType {
  EmailAndPhone = 'EmailAndPhone',
  Phone = 'Phone',
  Project = 'Project',
  StageName = 'StageName',
}

export type AccountSchedulePreferences = {
  showUnionStatus: Scalars['Boolean'];
  thumbnailType: ThumbnailType;
};

export type AccountSchedulePreferencesInput = {
  showUnionStatus: Scalars['Boolean'];
  thumbnailType: ThumbnailType;
};

/** Account status object */
export type AccountStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum AccountStatusEnum {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Incomplete = 'INCOMPLETE',
  Joined = 'JOINED',
  Locked = 'LOCKED',
  Unclaimed = 'UNCLAIMED',
  Unverified = 'UNVERIFIED',
}

export type AccountUrlType = {
  accountId: Scalars['Float'];
  /** Primary ID */
  id: Scalars['ID'];
  url: Scalars['String'];
  urlType: UrlType;
  urlTypeId: Scalars['Float'];
};

export type AccountsSystemRoleCodes = {
  accountId: Scalars['Float'];
  /** Primary ID */
  id: Scalars['ID'];
  systemRoleCodes: Array<Scalars['String']>;
};

/** Subscription */
export type ActiveSubscriptionModel = {
  id: Scalars['ID'];
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  plans?: Maybe<Array<PlanModel>>;
};

export type ActivityLog = {
  account?: Maybe<ActivityLogAccount>;
  auditionGroup?: Maybe<ActivityLogAuditionGroup>;
  auditionSession?: Maybe<ActivityLogAuditionSession>;
  calendarSlot?: Maybe<ActivityLogCalendarSlot>;
  id: Scalars['ID'];
  note?: Maybe<ActivityLogNote>;
  operation: ActivityLogOperation;
  organization?: Maybe<ActivityLogOrganization>;
  presentation?: Maybe<ActivityLogPresentation>;
  presentationFolder?: Maybe<ActivityLogPresentationFolder>;
  presentationGroup?: Maybe<ActivityLogPresentationGroup>;
  project?: Maybe<ActivityLogProject>;
  request?: Maybe<ActivityLogRequest>;
  role?: Maybe<ActivityLogRole>;
  schedule?: Maybe<ActivityLogSchedule>;
  submission?: Maybe<ActivityLogSubmission>;
  timestamp: Scalars['DateTime'];
  type: ActivityLogType;
};

export type ActivityLogAccount = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  isCCD: Scalars['Boolean'];
  lastName: Scalars['String'];
};

export type ActivityLogAuditionGroup = {
  id: Scalars['Int'];
  mediaByType?: Maybe<MediaByType>;
  medias?: Maybe<Array<WfMedia>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type ActivityLogAuditionSession = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type ActivityLogCalendarSlot = {
  id: Scalars['Int'];
  slotEndTime: Scalars['DateTime'];
  slotStartTime: Scalars['DateTime'];
};

export type ActivityLogNote = {
  comment: Scalars['String'];
  noteModifier?: Maybe<WfBasicSettingModel>;
};

export enum ActivityLogOperation {
  Delete = 'DELETE',
  Insert = 'INSERT',
  Update = 'UPDATE',
}

export type ActivityLogOrganization = {
  abbreviation?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type ActivityLogPagedList = {
  data: Array<ActivityLog>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ActivityLogPresentation = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ActivityLogPresentationFolder = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ActivityLogPresentationGroup = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type ActivityLogProject = {
  id: Scalars['Int'];
  note: Scalars['String'];
};

export type ActivityLogRequest = {
  additionalMedia?: Maybe<Array<WfMedia>>;
  answer?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  masterRequestId?: Maybe<Scalars['String']>;
  mediaByType?: Maybe<MediaByType>;
  medias?: Maybe<Array<WfMedia>>;
  note?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  talentInstructions?: Maybe<Scalars['String']>;
  type: RequestType;
};

export type ActivityLogRole = {
  id: Scalars['Int'];
  internalName: Scalars['String'];
  name: Scalars['String'];
};

export type ActivityLogSchedule = {
  id: Scalars['Int'];
  scheduleDate?: Maybe<Scalars['DateTime']>;
  scheduleType?: Maybe<Scalars['String']>;
};

export type ActivityLogSubmission = {
  id: Scalars['Int'];
  media?: Maybe<Array<WfMedia>>;
  mediaByType?: Maybe<MediaByType>;
  newRole?: Maybe<WfRoleShort>;
  note?: Maybe<Scalars['String']>;
  oldRole?: Maybe<WfRoleShort>;
  requestMedia?: Maybe<Array<WfMedia>>;
  requestMediaByType?: Maybe<MediaByType>;
  selectionTagCode?: Maybe<Scalars['String']>;
  selectionTagId?: Maybe<Scalars['Float']>;
  selectionTagName?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['Float']>;
  statusName?: Maybe<Scalars['String']>;
};

export enum ActivityLogType {
  FastCaptureNoteAdded = 'FAST_CAPTURE_NOTE_ADDED',
  RequestAskquestion = 'REQUEST_ASKQUESTION',
  RequestAudition = 'REQUEST_AUDITION',
  RequestCallback = 'REQUEST_CALLBACK',
  RequestMedia = 'REQUEST_MEDIA',
  SubmissionAddedToAuditionGroup = 'SUBMISSION_ADDED_TO_AUDITION_GROUP',
  SubmissionAddedToPresentationGroup = 'SUBMISSION_ADDED_TO_PRESENTATION_GROUP',
  SubmissionCreated = 'SUBMISSION_CREATED',
  SubmissionMovedToRole = 'SUBMISSION_MOVED_TO_ROLE',
  SubmissionNoteAdded = 'SUBMISSION_NOTE_ADDED',
  SubmissionSelectiontagAssigned = 'SUBMISSION_SELECTIONTAG_ASSIGNED',
  SubmissionSelectiontagUpdated = 'SUBMISSION_SELECTIONTAG_UPDATED',
  SubmissionStatusUpdated = 'SUBMISSION_STATUS_UPDATED',
  SubmissionUpdated = 'SUBMISSION_UPDATED',
}

export type AddArtistFreeCreditsInput = {
  artistId: Scalars['Int'];
  credits: Scalars['Int'];
  itemCode: BillingItemCode;
};

export type AddBillingInfoInput = {
  backupPaymentMethod?: InputMaybe<Scalars['Boolean']>;
  primaryPaymentMethod?: InputMaybe<Scalars['Boolean']>;
  threeDSecureActionResultTokenId?: InputMaybe<Scalars['String']>;
  tokenId: Scalars['String'];
};

export type AddCastingListProfileToRoleInput = {
  listId?: InputMaybe<Scalars['ID']>;
  listProfileIds?: InputMaybe<Array<Scalars['ID']>>;
  roleId: Scalars['ID'];
  tileSchemaValueId: Scalars['ID'];
};

/** Add Note Input Type */
export type AddNoteInput = {
  /** Text of the note */
  comment: Scalars['String'];
  /** Entity ID that note will be assigned to */
  entityId: Scalars['BigInt'];
  /** Entity Type CODE */
  entityTypeCode: Scalars['String'];
  /** Note Modifier CODE */
  noteModifierCode?: InputMaybe<Scalars['String']>;
  /** Note Type CODE */
  noteTypeCode?: InputMaybe<Scalars['String']>;
  /** Organization Id that will take assignment from the context if not supplied explicitly */
  organizationId?: InputMaybe<Scalars['Int']>;
};

export type AddNoteToCastingListProfileResult = {
  error?: Maybe<AddNoteToProfileError>;
  success: Scalars['Boolean'];
};

export type AddNoteToProfileError = ValidationError;

/** Add or Delete List Entities Input */
export type AddOrDeleteListEntitiesInput = {
  /** List entities ids */
  entitiesIds: Array<Scalars['BigInt']>;
  /** List Id */
  listId: Scalars['Float'];
};

export type AddProfileError = ValidationError;

/** Add Profile List Input */
export type AddProfileListInput = {
  /** List Name */
  listName: Scalars['String'];
  /** List of profile ids */
  profileIds: Array<Scalars['Int']>;
};

export type AddProfileNoteInput = {
  listProfileId: Scalars['ID'];
  note: Scalars['String'];
};

export type AddProfileSubmissionsToCastingListInput = {
  listIds: Array<Scalars['ID']>;
  projectId: Scalars['Int'];
  submissionIds: Array<Scalars['String']>;
};

export type AddProfileToAuditionGroupAddInInput = {
  auditionGroupId: Scalars['Int'];
  email: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  roleId: Scalars['Int'];
  stageName: Scalars['String'];
};

export type AddProfileToAuditionGroupInput = {
  auditionGroupId: Scalars['Int'];
  profileId: Scalars['Int'];
  roleId: Scalars['Int'];
  tileSchemeValueCode: TileSchemeValueEnum;
};

export type AddProfileToCastingListInput = {
  listId: Scalars['ID'];
  profileIds: Array<Scalars['ID']>;
};

export type AddProfileToCastingListResult = {
  error?: Maybe<AddProfileError>;
  success: Scalars['Boolean'];
};

/** Adds a Profile to Project. */
export type AddProfileToProjectInput = {
  profileId: Scalars['ID'];
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
  /** Submission Status CODE */
  submissionStatusCode: Scalars['String'];
  /** Field is DEPRECATED!!! */
  submissionStatusId?: InputMaybe<Scalars['ID']>;
  /** Tile Schema Value CODE */
  tileSchemeValueCode: Scalars['String'];
  /** Field is DEPRECATED!!! */
  tileSchemeValueId?: InputMaybe<Scalars['ID']>;
};

export type AddProfileToRoleError = MissingDataError | NotFoundError | ValidationError;

export type AddProfileToRoleResult = {
  error?: Maybe<AddProfileToRoleError>;
  /** Failed Profile Ids */
  failedIds?: Maybe<Array<Scalars['Float']>>;
  success: Scalars['Boolean'];
};

export type AddProfileToTimeSlotAddInInput = {
  email: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  roleId: Scalars['Int'];
  scheduleId: Scalars['Int'];
  slotId: Scalars['Int'];
  stageName: Scalars['String'];
};

export type AddProfileToTimeSlotInput = {
  profileId: Scalars['Int'];
  roleId: Scalars['Int'];
  scheduleId: Scalars['Int'];
  slotId: Scalars['Int'];
  tileSchemeValue: TileSchemeValueEnum;
};

/** Add Representative List Input */
export type AddRepresentativeListInput = {
  /** List Name */
  listName: Scalars['String'];
  publicationList?: InputMaybe<Scalars['Boolean']>;
  /** List of repIds */
  representativeIds: Array<Scalars['Float']>;
};

/** Input object for assigning submissions to the timeframe */
export type AddSubmissionsToTimeframeInput = {
  /** List of role Ids */
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Schedule Id */
  scheduleId?: InputMaybe<Scalars['Int']>;
  searchInput?: InputMaybe<SubmissionSearchInput>;
  /** Calendar Slot Id */
  slotId?: InputMaybe<Scalars['Int']>;
  /** Slot's start time */
  startTime?: InputMaybe<Scalars['DateTime']>;
  /** List of submission Ids */
  submissionIds: Array<Scalars['BigInt']>;
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
  /** Timeframe Id */
  timeframeId?: InputMaybe<Scalars['Int']>;
};

/** AddTimeframe input type */
export type AddTimeframeInput = {
  /** End time */
  endTime: Scalars['DateTime'];
  isBreak?: InputMaybe<Scalars['Boolean']>;
  /** Timeframe Name */
  name?: InputMaybe<Scalars['String']>;
  /** Input type for Role Talents */
  roleTalents: Array<RoleTalent>;
  /** Schedule Id */
  scheduleId: Scalars['ID'];
  /** Slot Durations */
  slotDurations?: InputMaybe<Array<Scalars['Float']>>;
  /** Start time */
  startTime: Scalars['DateTime'];
};

/** Address object type */
export type Address = {
  /** City */
  city: Scalars['String'];
  country?: Maybe<Country>;
  /** Address Id */
  id: Scalars['Int'];
  /** Line #1 of address */
  line1: Scalars['String'];
  /** Line #2 of address */
  line2: Scalars['String'];
  /** Line #3 of address */
  line3: Scalars['String'];
  /** Note */
  note: Scalars['String'];
  /** Other detail */
  otherDetail: Scalars['String'];
  /** Postal code */
  postalCode: Scalars['String'];
  state?: Maybe<State>;
  /** Division state ID */
  stateId: Scalars['Int'];
};

/** Address Input Type */
export type AddressInput = {
  addressId?: InputMaybe<Scalars['Int']>;
  /** Address Line 1 */
  addressLine1: Scalars['String'];
  /** Address Line 2 */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** Address Line 3 */
  addressLine3?: InputMaybe<Scalars['String']>;
  /** Address Type CODE */
  addressTypeCode: Scalars['String'];
  /** City */
  city: Scalars['String'];
  /** Country Id */
  countryId?: InputMaybe<Scalars['Int']>;
  /** Division City Id */
  divisionCityId?: InputMaybe<Scalars['Int']>;
  /** Note */
  note?: InputMaybe<Scalars['String']>;
  /** Other detail */
  otherDetail?: InputMaybe<Scalars['String']>;
  /** Postal Code */
  postalCode?: InputMaybe<Scalars['String']>;
  /** State / Province CODE */
  stateCode?: InputMaybe<Scalars['String']>;
  /** State Id */
  stateId?: InputMaybe<Scalars['Int']>;
};

export enum AddressTypeEnum {
  Audition = 'AUDITION',
  Callback = 'CALLBACK',
  Company = 'COMPANY',
}

export type AgeRange = {
  ageMax?: Maybe<Scalars['Int']>;
  ageMin?: Maybe<Scalars['Int']>;
  inMonths?: Maybe<Scalars['Boolean']>;
};

/** Input type for AgeRangeInput */
export type AgeRangeInput = {
  /** The minimum age a talent can play */
  ageMax?: InputMaybe<Scalars['Int']>;
  /** The maximum age a talent can play */
  ageMin?: InputMaybe<Scalars['Int']>;
  /** Ages set in months. Default value: true */
  inMonths?: InputMaybe<Scalars['Boolean']>;
};

export type AgencyCallListDivisionGroup = {
  id: Scalars['Int'];
  organizationName: Scalars['String'];
  phone?: Maybe<Array<Scalars['String']>>;
  submissions: Array<AgencyCallListSubmission>;
};

export type AgencyCallListProjectInfo = {
  adAgency?: Maybe<Scalars['String']>;
  castingDirector?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  producer?: Maybe<Scalars['String']>;
  productionCompany?: Maybe<Scalars['String']>;
};

export type AgencyCallListReportCreateInput = {
  scheduleId: Scalars['Int'];
};

export type AgencyCallListReportModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<AgencyCallListReportPayloadModel>;
  reportType: ReportTypeEnum;
  scheduleId: Scalars['Int'];
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Agency Call List Report Payload */
export type AgencyCallListReportPayloadModel = {
  divisionLogo?: Maybe<ReportMediaModel>;
  divisionName?: Maybe<Scalars['String']>;
  personalSubmissions: Array<AgencyCallListSubmission>;
  projectInfo: AgencyCallListProjectInfo;
  reppedSubmissions: Array<AgencyCallListDivisionGroup>;
  scheduleInfo: AgencyCallListScheduleInfo;
};

export type AgencyCallListScheduleInfo = {
  scheduleDate: Scalars['DateTime'];
  scheduleName: Scalars['String'];
  scheduleType: Scalars['String'];
};

export type AgencyCallListSubmission = {
  cover?: Maybe<ReportMediaModel>;
  deletedProfile: Scalars['Boolean'];
  id: Scalars['BigInt'];
  isPersonal: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roleName: Scalars['String'];
  scheduleResponseStatus?: Maybe<Scalars['String']>;
  slotStartDate: Scalars['DateTime'];
  stageName?: Maybe<Scalars['String']>;
  talentUnions?: Maybe<Array<Scalars['String']>>;
};

/** Creates or update Rep organization contact, address, publishing categories, dma and workingLocation on given input values */
export type AgentOrganizationInput = {
  /** Representation age categories */
  ageCategories?: InputMaybe<Array<Scalars['Float']>>;
  /** DMA id */
  dmaId: Scalars['Float'];
  /** Organization input */
  organizationInput: OrganizationInput;
  /** Primary working organization */
  primaryWorkingLocation: Array<Scalars['Float']>;
  /** Publishing categories */
  publishingCategories: Array<Scalars['Float']>;
};

/** All Talent Submission Count Model */
export type AllTalentSubmissionCountModel = {
  auditionsCount: Scalars['Int'];
  callbacksCount: Scalars['Int'];
  mediaRequestCount: Scalars['Int'];
  profile?: Maybe<AllTalentSubmissionProfileModel>;
  submissionsCount: Scalars['Int'];
};

/** All Talent Submission Report */
export type AllTalentSubmissionModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  id: Scalars['ID'];
  includeFormerClient?: Maybe<Scalars['Boolean']>;
  organizationId: Scalars['Int'];
  payload?: Maybe<AllTalentSubmissionPayloadModel>;
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** All Talent Submission Report Payload */
export type AllTalentSubmissionPayloadModel = {
  castingCompanyInfo?: Maybe<CompanyInfoModel>;
  data: Array<AllTalentSubmissionCountModel>;
};

/** All Talent Submission Profile Model */
export type AllTalentSubmissionProfileModel = {
  isFormerClient: Scalars['Boolean'];
  profileId: Scalars['Int'];
  stageName?: Maybe<Scalars['String']>;
};

/** All Talent Submission Report Input Arguments */
export type AllTalentSubmissionReportCreateInput = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  includeFormerClient: Scalars['Boolean'];
};

/** Input type for PC project approve */
export type ApprovePcProjectInput = {
  projectId?: InputMaybe<Scalars['Int']>;
  /** Send notifications to talent (FFY) */
  sendNotifications?: InputMaybe<Scalars['Boolean']>;
};

export type ArchiveMediaInput = {
  mediaIds: Array<Scalars['ID']>;
};

export type ArchiveMediaResponse = {
  media: Array<Media>;
  success: Scalars['Boolean'];
};

/** Artist info */
export type Artist = {
  account?: Maybe<Account>;
  activeSubscription?: Maybe<Subscription>;
  /** Artist Id */
  artistId: Scalars['Int'];
  artistSubscriptions?: Maybe<Array<SubscriptionModel>>;
  /** Created Date */
  created: Scalars['DateTime'];
  /** First Name */
  firstName: Scalars['String'];
  hasAugeoBenefits?: Maybe<Scalars['Boolean']>;
  /** Primary ID */
  id: Scalars['ID'];
  /** Last Name */
  lastName: Scalars['String'];
  mediaCounts?: Maybe<Array<MediaCount>>;
  mediaEntitlement: ArtistMediaEntitlement;
  mediaUtilization: ArtistMediaUtilization;
  /** True if artist is minor */
  minor: Scalars['Boolean'];
  notificationPhone?: Maybe<Scalars['String']>;
  previousSubscription?: Maybe<Subscription>;
  profiles?: Maybe<Array<Profile>>;
  storageLimitBytes?: Maybe<Scalars['Int']>;
  subscriptionMediaUsage?: Maybe<ArtistSubscriptionMediaEntitlement>;
};

/** Artist info */
export type ArtistMediaCountsArgs = {
  isLocked: Scalars['Boolean'];
};

/** Artist info */
export type ArtistProfilesArgs = {
  oldest?: InputMaybe<Scalars['Boolean']>;
};

export type ArtistBillingFeatureModel = {
  artistId: Scalars['Float'];
  credit: Scalars['Int'];
  itemCode: Scalars['String'];
};

/** Input type for Artist Credits */
export type ArtistCreditsInput = {
  /** Credit type id */
  creditTypeId: Scalars['Int'];
  /** The director of the Project */
  director: Scalars['String'];
  /** Display order descending */
  displayOrder?: InputMaybe<Scalars['Int']>;
  /** Highlighted */
  highlighted?: InputMaybe<Scalars['Boolean']>;
  /** Profile ids */
  profileIds: Array<Scalars['ID']>;
  /** Role name */
  role: Scalars['String'];
  /** Credit title */
  title: Scalars['String'];
  /** Year */
  year?: InputMaybe<Scalars['Int']>;
};

/** Artist Media */
export type ArtistMedia = {
  /** Profile media details - is primary, order */
  artistMediaProfile?: Maybe<Array<ArtistMediaProfile>>;
  /** Primary ID */
  id: Scalars['ID'];
  /** The media object */
  media: Media;
  /** Media Id */
  mediaId: Scalars['ID'];
};

/** An overview of the media entitlement for an artist, broken down by media type. */
export type ArtistMediaEntitlement = {
  artist: Artist;
  artistId: Scalars['ID'];
  audioUpload: Scalars['Int'];
  photoUpload: Scalars['Int'];
  videoUpload: Scalars['Int'];
};

/** Basic profile media */
export type ArtistMediaProfile = {
  /** Checks if profile have granted access to roster */
  accessGranted: Scalars['Boolean'];
  accountId?: Maybe<Scalars['Float']>;
  ageInYears?: Maybe<Scalars['Boolean']>;
  /** Profile Artist ID */
  artistId?: Maybe<Scalars['Float']>;
  /** Profile audio assets */
  audios?: Maybe<Array<ProfileMedia>>;
  billingFeatures?: Maybe<Array<ArtistBillingFeatureModel>>;
  /** @deprecated Use 'billingFeatures' instead. ID will now return 0. */
  billingItems?: Maybe<Array<BillingItemModel>>;
  /** Blurb */
  blurb?: Maybe<Scalars['String']>;
  /** Bust Object */
  bust?: Maybe<Bust>;
  /** Chest size Object */
  chestSize?: Maybe<ChestSize>;
  /** Coat Object */
  coat?: Maybe<Coat>;
  /** Conflicts for the profile */
  conflicts: Array<ProfileConflict>;
  /** Dynamic field. Based on profile status and representative type */
  contactInfo?: Maybe<ProfileContactInfo>;
  contactedByRep?: Maybe<Array<ProfileContactedByRep>>;
  cover?: Maybe<Media>;
  /** Created at Date */
  created?: Maybe<Scalars['DateTime']>;
  /** Profile Credits */
  credits: Array<Credit>;
  /** Profile Credits Count */
  creditsCount: Scalars['Int'];
  /** Cup size Object */
  cupSize?: Maybe<CupSize>;
  customSlug?: Maybe<CustomSlug>;
  /** Date of birth */
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** stores/holds the id of the person who has deactivated the profile */
  deactivatedBy?: Maybe<Scalars['Float']>;
  /** Default submission note */
  defaultSubmissionNote?: Maybe<Scalars['String']>;
  /** Document type(s) */
  documentTypes?: Maybe<Array<Document>>;
  /** Profile document assets */
  documents?: Maybe<Array<ProfileMedia>>;
  /** Dress size Object */
  dressSize?: Maybe<ClothesSize>;
  /** Ethnic Appearance(s) */
  ethnicAppearances?: Maybe<Array<ProfileEthnicAppearance>>;
  /** Eye Color Object */
  eyeColor?: Maybe<EyeColor>;
  /** Facial Hair Type(s) */
  facialHair?: Maybe<Array<FacialHair>>;
  /** Gender Appearance(s) */
  genderAppearances?: Maybe<Array<GenderAppearance>>;
  /** Glove size Object */
  gloveSize?: Maybe<Glove>;
  /** GUID */
  guid?: Maybe<Scalars['String']>;
  /** Hair Color Object */
  hairColor?: Maybe<HairColor>;
  /** Hair Style Object */
  hairStyle?: Maybe<HairStyle>;
  /** Profile has audio reel */
  hasAudioReel?: Maybe<Scalars['Boolean']>;
  hasAuditionHistory: Scalars['Boolean'];
  /** has Representation */
  hasRepresentation?: Maybe<Scalars['Boolean']>;
  /** Profile has resume */
  hasResume?: Maybe<Scalars['Boolean']>;
  /** Profile has video reel */
  hasVideoReel?: Maybe<Scalars['Boolean']>;
  /** Hat size Object */
  hatSize?: Maybe<HatSize>;
  /** Height Object */
  height?: Maybe<Height>;
  /**
   * Deprecated
   * @deprecated This field is redundant as profile media visibility depends only on existence in profile_media table.
   */
  hidden: Scalars['Boolean'];
  /** Hide credit year */
  hideCreditYear: Scalars['Boolean'];
  /** Hip size Object */
  hipSize?: Maybe<Hip>;
  /** Primary ID */
  id: Scalars['ID'];
  /** Inseam size Object */
  inseam?: Maybe<Inseam>;
  isActive: Scalars['Boolean'];
  /**
   * Returns true if profile was deactivated by a Rep user, returns false if
   * profile was deactivated by a Talent, returns null when there is no information
   * about the deactivating account
   */
  isDeactivatedByReps?: Maybe<Scalars['Boolean']>;
  isDropped: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  /** If Profile is overscaled */
  isOverscaled: Scalars['Boolean'];
  /** Identify the personal or represented profile. */
  isPersonal: Scalars['Boolean'];
  isPlayableAgeLockedToTalent: Scalars['Boolean'];
  /** Is media primary (primary photo, video reel or audio reel) */
  isPrimary: Scalars['Boolean'];
  /** Identify represented profile */
  isRepresented: Scalars['Boolean'];
  /** Is talent seeking representation */
  isSeekingRepresentation?: Maybe<Scalars['Boolean']>;
  /** Last note for the profile */
  lastNote?: Maybe<Note>;
  /** Luminate profile Data */
  luminateProfile?: Maybe<LuminateProfile>;
  /** Manager Email */
  managerEmail?: Maybe<Scalars['String']>;
  /** All profile media */
  mediaAssets?: Maybe<Array<ProfileMedia>>;
  /** Profile's primary photo */
  mediaSummary: Array<MediaCount>;
  /** Is minor */
  minor?: Maybe<Scalars['Boolean']>;
  /** Multiple Birth Object */
  multipleBirth?: Maybe<MultipleBirth>;
  /** Neck size Object */
  neck?: Maybe<Neck>;
  /** Note */
  note?: Maybe<Scalars['String']>;
  /** Note to Representative */
  noteToRepresentative?: Maybe<Scalars['String']>;
  /** Notes for the profile */
  notes: Array<Note>;
  /** Order of display for the media */
  order?: Maybe<Scalars['Int']>;
  organization?: Maybe<Organization>;
  organizations?: Maybe<Array<Organization>>;
  /** Other experiences */
  otherExperiences?: Maybe<Scalars['String']>;
  /** Overscale Note */
  overscaleNote?: Maybe<Scalars['String']>;
  /** Parent Name */
  parent?: Maybe<Scalars['String']>;
  /** Profile phone */
  phone?: Maybe<Scalars['String']>;
  /** Profile photo assets */
  photos?: Maybe<Array<ProfileMedia>>;
  /** Note about piercing */
  piercingNote?: Maybe<Scalars['String']>;
  /** The age a talent can play */
  playableAge?: Maybe<Scalars['Float']>;
  /** The minimum age a talent can play */
  playableAgeMax?: Maybe<Scalars['Float']>;
  /** The maximum age a talent can play */
  playableAgeMin?: Maybe<Scalars['Float']>;
  /**
   * Date of pregnancy
   * @deprecated This field is deprecated and will be deleted once the compatible changes at the front end will be added everyewhere for pregnancyDue reference. Use pregnancyDue instead
   */
  pregnancy?: Maybe<Scalars['DateTime']>;
  /** Date of pregnancy */
  pregnancyDue?: Maybe<Scalars['DateTime']>;
  /** Profession */
  profession?: Maybe<Scalars['String']>;
  /** Access Token of a Profile, generated by Granting Access or Creating a Profile */
  profileAccessToken?: Maybe<Scalars['String']>;
  /** ProfileAccessToken created date */
  profileAccessTokenCreated?: Maybe<Scalars['DateTime']>;
  /** Profile Account System Role */
  profileAccountRole?: Maybe<Scalars['String']>;
  /** Get profile bookouts */
  profileBookOuts: Array<ProfileBookOuts>;
  /** Gender Identities */
  profileGenderIdentities?: Maybe<Array<ProfileGenderIdentity>>;
  /** Primary ID */
  profileId: Scalars['ID'];
  /** The Profile List ids where current profile participated */
  profileLists: Array<Scalars['Int']>;
  profileMainDivision?: Maybe<Organization>;
  profileMainOrganization?: Maybe<Organization>;
  /** media assets */
  profileMedia?: Maybe<Array<ProfileMedia>>;
  /** Profile Pants */
  profilePants: Array<Pants>;
  /** Profile pets */
  profilePets: Array<ProfilePet>;
  /** Profile piercings */
  profilePiercings: Array<Piercing>;
  /** Profile shoe sizes */
  profileShoeSizes: Array<ProfileShoeSize>;
  /** Profile general size */
  profileSizes: Array<ProfileSize>;
  /** Profile Status Object */
  profileStatus?: Maybe<ProfileStatus>;
  profileStatusId?: Maybe<Scalars['Int']>;
  /** Profile TshirtSize */
  profileTshirtSizes: Array<TShirtSize>;
  /** Profile Type */
  profileType?: Maybe<ProfileType>;
  /** Profile vehicles */
  profileVehicles: Array<ProfileVehicle>;
  /** profileVisibility Object */
  profileVisiblities?: Maybe<Array<ProfileVisibility>>;
  /** Wardrobes */
  profileWardrobes?: Maybe<Array<ProfileWardrobe>>;
  /** Project Type */
  projectType?: Maybe<Scalars['String']>;
  /** Prop Type(s) */
  props?: Maybe<Array<ProfileProp>>;
  /** Job Persuing Type(s) */
  pursuing?: Maybe<Array<Pursuing>>;
  /** User's resume document */
  resume?: Maybe<ProfileMedia>;
  /** Roster hidden */
  rosterHidden?: Maybe<Scalars['Boolean']>;
  /** Search hidden */
  searchHidden?: Maybe<Scalars['Boolean']>;
  /** SeekingRepresentation Object */
  seekingRepresentations?: Maybe<Array<SeekingRepresentation>>;
  selectionType: ProfileSelectionType;
  /** Skills and their Levels */
  skills?: Maybe<Array<ProfileSkill>>;
  /** Sleeve Object */
  sleeve?: Maybe<Sleeve>;
  /** Sport equipments */
  sportEquipments?: Maybe<Array<ProfileSportEquipment>>;
  /** Stage Name */
  stageName: Scalars['String'];
  /** Returns submission */
  submission?: Maybe<Submission>;
  /** Submission request's media assets */
  submissionRequestMedia: Array<SubmissionMedia>;
  /** talent's contacts regardless representative status */
  talentContactInfo?: Maybe<ProfileContactInfo>;
  /** Profile email */
  talentEmail?: Maybe<Scalars['String']>;
  talentFolders?: Maybe<Array<PresentationFolderTalentPresence>>;
  /** Talent unions */
  talentUnions?: Maybe<Array<TalentProfileUnion>>;
  /** Profile tattoo */
  tattoo?: Maybe<Tattoo>;
  /** Tattoo Note */
  tattooNote?: Maybe<Scalars['String']>;
  /** Underbust size Object */
  underbust?: Maybe<UnderBust>;
  /** Unique Attribute Type(s) */
  uniqueAttributes?: Maybe<Array<UniqueAttribute>>;
  /** Updated at Date */
  updated?: Maybe<Scalars['DateTime']>;
  /** Profile video assets */
  videos?: Maybe<Array<ProfileMedia>>;
  /** Waist Object */
  waist?: Maybe<Waist>;
  /** Weight Object */
  weight?: Maybe<Weight>;
  /** Willingness Objects */
  willingness: Array<Willingness>;
  /** Work permit expiration date */
  workPermitExp?: Maybe<Scalars['DateTime']>;
  /** Working Locations */
  workingLocations?: Maybe<Array<ProfileWorkingLocation>>;
};

/** Basic profile media */
export type ArtistMediaProfileHasAuditionHistoryArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};

/** Basic profile media */
export type ArtistMediaProfilePhotosArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};

/** Basic profile media */
export type ArtistMediaProfileSelectionTypeArgs = {
  roleId: Scalars['Float'];
};

/** Basic profile media */
export type ArtistMediaProfileSubmissionArgs = {
  roleId: Scalars['Float'];
};

/** Basic profile media */
export type ArtistMediaProfileSubmissionRequestMediaArgs = {
  roleId: Scalars['Float'];
};

/** Basic profile media */
export type ArtistMediaProfileTalentFoldersArgs = {
  folderId: Scalars['Int'];
};

/** An overview of the media utilization for an artist, broken down by media type. */
export type ArtistMediaUtilization = {
  artist: Artist;
  artistId: Scalars['ID'];
  audioUpload: Scalars['Int'];
  countByMediaTypes: Array<ArtistMediaUtilizationMediaTypeCount>;
  countByPurchaseStatus: Array<ArtistMediaUtilizationPurchaseStatusCount>;
  photoUpload: Scalars['Int'];
  videoUpload: Scalars['Int'];
};

export enum ArtistMediaUtilizationMediaType {
  Audio = 'AUDIO',
  Photo = 'PHOTO',
  Video = 'VIDEO',
}

/** A count of media utilization by media type. */
export type ArtistMediaUtilizationMediaTypeCount = {
  count: Scalars['Int'];
  countByPurchaseStatus: Array<ArtistMediaUtilizationPurchaseStatusCount>;
  media: Array<Media>;
  mediaType: ArtistMediaUtilizationMediaType;
};

/** A count of media utilization by purchase type. */
export type ArtistMediaUtilizationPurchaseStatusCount = {
  count: Scalars['Int'];
  media: Array<Media>;
  purchaseStatus: ArtistMediaUtilizationPurchaseType;
};

export enum ArtistMediaUtilizationPurchaseType {
  FreeCredit = 'FREE_CREDIT',
  NotPurchased = 'NOT_PURCHASED',
  Purchased = 'PURCHASED',
}

/** Artist Media Utilization */
export type ArtistStorageUtilization = {
  /** The storage limit in bytes for the artist. Returns null if the artist has no storage limit. */
  artistStorageLimitBytes?: Maybe<Scalars['BigInt']>;
  /** The remaining storage in bytes for the artist. Returns null if the artist has no storage limit. */
  artistStorageRemainingBytes?: Maybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  /** Each media type is broken down with its storage utilization */
  mediaStorages: Array<MediaStorage>;
  /** Total size of all media types used in bytes */
  totalSizeBytes: Scalars['BigInt'];
};

/** Subscription Input */
export type ArtistSubscriptionInput = {
  /** Artist id */
  artistId: Scalars['Int'];
};

export type ArtistSubscriptionMediaEntitlement = {
  available: Array<SubscriptionMediaEntitlement>;
  entitle: Array<SubscriptionMediaEntitlement>;
};

/** Input for Updating Artist */
export type ArtistUpdateInput = {
  /** Account Id */
  accountId?: InputMaybe<Scalars['Int']>;
  /** Artist Id */
  artistId: Scalars['Int'];
  /** First Name */
  firstName?: InputMaybe<Scalars['String']>;
  /** Last Name */
  lastName?: InputMaybe<Scalars['String']>;
  /** Organization Id */
  organizationId?: InputMaybe<Scalars['Int']>;
  /** Profile access token granted by Rep */
  profileAccessToken?: InputMaybe<Scalars['String']>;
};

/** 'Ask Question Request' Type */
export type AskQuestionRequest = {
  createdAt: Scalars['DateTime'];
  draftSubmissions?: Maybe<WfSubmissionPagedList>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dueDateTimeZone?: Maybe<WfTimeZone>;
  id: Scalars['ID'];
  includeSides: Scalars['Boolean'];
  isDraft?: Maybe<Scalars['Boolean']>;
  messageForRepresentatives?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['Int'];
  question: Scalars['String'];
  responses: AskQuestionResponsePagedList;
  shouldResend: Scalars['Boolean'];
  submissionIds: Array<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** 'Ask Question Request' Type */
export type AskQuestionRequestDraftSubmissionsArgs = {
  page?: InputMaybe<PageInput>;
};

/** 'Ask Question Request' Type */
export type AskQuestionRequestResponsesArgs = {
  page: PageInput;
};

export type AskQuestionRequestInput = {
  dueDate?: InputMaybe<Scalars['DateTime']>;
  dueDateTimeZoneId?: InputMaybe<Scalars['Int']>;
  excludeSubmissionIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['String']>;
  includeSides: Scalars['Boolean'];
  isDraft: Scalars['Boolean'];
  messageForRepresentatives?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['Int'];
  question: Scalars['String'];
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  submissionIds: Array<Scalars['Int']>;
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
};

export enum AskQuestionRequestStatus {
  Answered = 'ANSWERED',
  Cancelled = 'CANCELLED',
  NotSent = 'NOT_SENT',
  Pending = 'PENDING',
}

export type AskQuestionResponse = {
  answer?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  repliedAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  status: AskQuestionRequestStatus;
  submission: WfSubmission;
  /** @deprecated Replaced with field 'submission'. */
  submissionId: Scalars['Float'];
};

export type AskQuestionResponseInput = {
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type AskQuestionResponsePagedList = {
  data: Array<AskQuestionResponse>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** 'Ask Question Sub-Request' Type */
export type AskQuestionSubRequest = {
  /** Cancellation note */
  cancellationNote?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dueDateTimeZone?: Maybe<WfTimeZone>;
  /** Forwarded note */
  forwardNote?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  includeSides: Scalars['Boolean'];
  messageForRepresentatives?: Maybe<Scalars['String']>;
  profile?: Maybe<WfProfile>;
  project: WfProject;
  question: Scalars['String'];
  /** The date when response was sent */
  repliedAt?: Maybe<Scalars['DateTime']>;
  /** Reps Worksheet Status */
  repsWorksheetStatus: Scalars['String'];
  /** The answer to the question */
  response?: Maybe<Scalars['String']>;
  role: WfRole;
  /** The date when request was sent */
  sentAt: Scalars['DateTime'];
  status: AskQuestionRequestStatus;
  submission: WfSubmission;
  /** @deprecated Replaced with field 'submission'. */
  submissionId: Scalars['Int'];
};

export type AttachMediaToProfileInput = {
  /** Is media primary? (Primary photo, video reel or audio reel) */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** Media Id */
  mediaId: Scalars['Int'];
  /** Media Moderation Status Code can be found in Tackle box */
  mediaModerationStatusCode?: InputMaybe<Scalars['String']>;
  /** Media order of appearance */
  order?: InputMaybe<Scalars['Int']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  /** Profile Id */
  profileId: Scalars['Int'];
  tag?: InputMaybe<Scalars['String']>;
};

export type AttachMultipleMediaToProfileInput = {
  /** Media Ids to attach */
  mediaIds: Array<Scalars['Int']>;
  /** Media Moderation Status Code can be found in Tackle box */
  mediaModerationStatusCode?: InputMaybe<Scalars['String']>;
  /** Profile Id */
  profileId: Scalars['Int'];
};

export type AttachmentDetails = {
  name: Scalars['String'];
  url: Scalars['String'];
};

/** Audition address */
export type AuditionAddress = {
  /** Address Line 1 */
  addressLine1?: Maybe<Scalars['String']>;
  /** Address Line 2 */
  addressLine2?: Maybe<Scalars['String']>;
  /** City */
  city?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Array<Country>>;
  /** Address ID */
  id: Scalars['ID'];
  /** Location notes */
  locationNotes?: Maybe<Scalars['String']>;
  /** ZIP/Postal Code */
  postalCode?: Maybe<Scalars['String']>;
  /** State / Province */
  state?: Maybe<Array<State>>;
};

export type AuditionGroup = {
  archived?: Maybe<Scalars['Boolean']>;
  /** Gets audition session */
  auditionSession?: Maybe<AuditionSession>;
  /** Id of audition session to which the audition session group is assigned */
  auditionSessionId: Scalars['Int'];
  cover?: Maybe<Media>;
  /** Created date of audition group */
  created: Scalars['DateTime'];
  /** Gets Account object whos created the audition group */
  createdBy?: Maybe<Account>;
  /** Id of audition session group */
  id: Scalars['Int'];
  /** Media Count */
  mediaCount?: Maybe<Array<MediaCount>>;
  /** Gets related to group media */
  medias?: Maybe<Array<AuditionGroupMedia>>;
  /** Name of audition session group */
  name?: Maybe<Scalars['String']>;
  /** Group order */
  order?: Maybe<Scalars['Int']>;
  /** Submissions Count */
  submissionCount?: Maybe<Scalars['BigInt']>;
  /** Gets related to group submissions */
  submissions?: Maybe<Array<Submission>>;
  /** Updated date of audition group */
  updated?: Maybe<Scalars['DateTime']>;
};

export type AuditionGroupSubmissionsArgs = {
  pageSize?: InputMaybe<Scalars['Float']>;
};

/** Audition Session Group input type */
export type AuditionGroupBulkUpdateInput = {
  /** Archive status */
  archived: Scalars['Boolean'];
};

/** Audition Session Group input type */
export type AuditionGroupInput = {
  /** Id of audition session to which the audition session group will be assigned */
  auditionSessionId?: InputMaybe<Scalars['Int']>;
  /** ID of the creator */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Name for audition session group */
  name?: InputMaybe<Scalars['String']>;
  /** Note for the group */
  note?: InputMaybe<Scalars['String']>;
  /** Order of appearance */
  order?: InputMaybe<Scalars['Int']>;
  /** Thumbnail. Id of existing media */
  thumbnailMediaId?: InputMaybe<Scalars['Int']>;
};

/** Represent relation between Audition Group and Media */
export type AuditionGroupMedia = {
  id: Scalars['Int'];
  /** Related media object */
  media: Media;
  /** Id of media */
  mediaId: Scalars['Int'];
  /** Name of relation */
  name: Scalars['String'];
  /** Order of appearance */
  order: Scalars['Int'];
};

/** Audition Group Media input type */
export type AuditionGroupMediaInput = {
  /** Id of audition group to which media will be added */
  auditionGroupId: Scalars['Int'];
  /** List of Media items */
  media: Array<AuditionGroupMediaItemInput>;
};

/** Media input type for Audition Group */
export type AuditionGroupMediaItemInput = {
  /** Id of existing Media */
  mediaId: Scalars['Int'];
  /** Name for the media */
  name?: InputMaybe<Scalars['String']>;
  /** Order of appearance */
  order?: InputMaybe<Scalars['Int']>;
};

export type AuditionGroupMediaOrderUpdateInput = {
  auditionGroupId: Scalars['Int'];
  groupMediaId: Scalars['Int'];
  moveAfterGroupMediaId?: InputMaybe<Scalars['Int']>;
};

/** Paginated result with [AuditionGroup] objects */
export type AuditionGroupPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of AuditionGroup objects */
  objects: Array<AuditionGroup>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Audition Group Talent */
export type AuditionGroupTalent = {
  /** Audition Group ids */
  auditionGroupIds?: Maybe<Array<Scalars['Int']>>;
  /** Talent profile Id */
  profileId?: Maybe<Scalars['Int']>;
};

export type AuditionGrouping = {
  details?: Maybe<AuditionGroupDetails>;
  entityId: Scalars['Float'];
  itemCount: Scalars['Float'];
};

export type AuditionHistory = {
  auditionGroups?: Maybe<Array<AuditionHistorySessionGroupItem>>;
  date: Scalars['DateTime'];
  projectName: Scalars['String'];
  roleName: Scalars['String'];
  snapshotMedia?: Maybe<Media>;
  submissionStatus: SubmissionStatus;
};

/** Paginated result with [AuditionHistory] objects */
export type AuditionHistoryPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of AuditionHistory objects */
  objects: Array<AuditionHistory>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type AuditionHistorySessionGroupItem = {
  auditionSession: AuditionHistorySessionItem;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AuditionHistorySessionItem = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AuditionPagedListSearch = {
  /** Pagination Next Page Key */
  after?: Maybe<Array<Scalars['String']>>;
  data: Array<MediaSubRequest>;
  /** Artist does not pay for YOUR_AUDITIONS. */
  isBasic: Scalars['Boolean'];
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Audition Presentation input type */
export type AuditionPresentationInput = {
  /** List of linked audition sessions. Required for Live Presentation */
  auditionSessionIds?: InputMaybe<Array<Scalars['Int']>>;
  isLive?: InputMaybe<Scalars['Boolean']>;
  /** Name for audition presentation */
  name: Scalars['String'];
  /** Project ID */
  projectId: Scalars['Int'];
};

/** Audition session */
export type AuditionSession = {
  /** Created date of audition session */
  created: Scalars['DateTime'];
  /** Returns Account object who`s created the audition session */
  createdBy?: Maybe<Account>;
  /** Indicates that the audition session should be hidden */
  hidden?: Maybe<Scalars['Boolean']>;
  /** ID of audition session */
  id: Scalars['Int'];
  /** Name of audition session */
  name?: Maybe<Scalars['String']>;
  /** Note of audition session */
  note?: Maybe<Scalars['String']>;
  /** Order of appearence of audition session */
  order: Scalars['Int'];
  /** Project id */
  projectId?: Maybe<Scalars['Int']>;
  /** Returns Forms associated to Media Request */
  requestForms?: Maybe<RequestForms>;
  /** Gets AuditionSource object */
  source?: Maybe<AuditionSource>;
  /** Submissions Count */
  submissionCount?: Maybe<Scalars['BigInt']>;
  /** Virtual Audition Details */
  virtualAudition?: Maybe<ScheduleVirtualAudition>;
};

export type AuditionSessionCreateInput = {
  /** ID of the creator */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Indicates that the audition session should be hidden */
  hidden?: InputMaybe<Scalars['Boolean']>;
  /** Name of audition session */
  name: Scalars['String'];
  /** ID of the project to which will be assigned audition session */
  projectId: Scalars['Int'];
  /** Source of audition session */
  sourceCode: Scalars['String'];
};

export type AuditionSessionFilterInput = {
  searchKey?: InputMaybe<Scalars['String']>;
};

/** Paginated result with [AuditionSession] objects */
export type AuditionSessionPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of AuditionSession objects */
  objects: Array<AuditionSession>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Sort condition for audition session */
export type AuditionSessionSortInstructionInput = {
  /** Sort by */
  sortBy?: InputMaybe<Scalars['String']>;
  /** Sort by */
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type AuditionSessionUpdateInput = {
  /** Indicates that the audition session should be hidden */
  hidden?: InputMaybe<Scalars['Boolean']>;
  /** ID of existing audition submission */
  id: Scalars['Int'];
  /** Name of audition session */
  name?: InputMaybe<Scalars['String']>;
  /** Note of audition session */
  note?: InputMaybe<Scalars['String']>;
  /** Order of appearance */
  order?: InputMaybe<Scalars['Int']>;
  /** Source of audition session */
  sourceCode?: InputMaybe<Scalars['String']>;
};

/** AuditionSource object */
export type AuditionSource = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Audition Submission Snapshot input type */
export type AuditionSubmissionSnapshotInput = {
  /** Snapshot Id */
  snapshotId: Scalars['Int'];
  /** Submission Ids */
  submissionId: Scalars['BigInt'];
};

/** Audition Talent Pool */
export type AuditionTalentPool = {
  /** ID of audition session */
  auditionSessionId?: Maybe<Scalars['Int']>;
  /** Slot End Time */
  endTime?: Maybe<Scalars['DateTime']>;
  /** ID of audition session */
  id?: Maybe<Scalars['ID']>;
  /** Schedule Date */
  scheduleDate?: Maybe<Scalars['DateTime']>;
  /** Slot Start Time */
  startTime?: Maybe<Scalars['DateTime']>;
  /** List of Submissions */
  submissions?: Maybe<Array<Submission>>;
};

/** Auditiontype object */
export type AuditionType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type AuditionsFilterInput = {
  /** Filters by casting orgs */
  orgs?: InputMaybe<Array<Scalars['Int']>>;
  /** Filters by profiles */
  profiles?: InputMaybe<Array<Scalars['Int']>>;
  /** Filters by projects */
  projects?: InputMaybe<Array<Scalars['Int']>>;
};

export enum AuditionsGroupBy {
  CastingCompanyName = 'castingCompanyName',
  ProjectName = 'projectName',
  TalentName = 'talentName',
}

export type AuditionsGroups = {
  /** Pagination Next Page Key */
  after?: Maybe<Array<GroupLastKey>>;
  groups: Array<AuditionGrouping>;
  /** Artist does not pay for YOUR_AUDITIONS. */
  isBasic: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Organization info */
export type AuditionsOrganization = {
  /** Organization ID */
  id: Scalars['Int'];
  /** Logo Media ID */
  logoMediaId?: Maybe<Scalars['Int']>;
  /** Organization name */
  name: Scalars['String'];
};

export type AuditionsSearchSortOptionsInput = {
  /** Field name by which should be sorted */
  orderColumn: AuditionsSortColumn;
  /** Sort order value(asc or desc). Default value: asc */
  orderDirection?: InputMaybe<SortOrder>;
};

export enum AuditionsSortColumn {
  CastingCompanyName = 'castingCompanyName',
  ProjectName = 'projectName',
  TalentName = 'talentName',
  UpdatedAt = 'updatedAt',
}

export type AuthToken = {
  access: Scalars['String'];
  refresh: Scalars['String'];
};

export type AuthTokenItem = {
  access?: Maybe<Scalars['String']>;
  refresh?: Maybe<Scalars['String']>;
};

/** Context object for base email context */
export type BaseEmailContext = {
  buttonText: Scalars['String'];
  buttonURL: Scalars['String'];
  heading: Scalars['String'];
  receiverName: Scalars['String'];
  roleCode: Array<Scalars['String']>;
};

export type BaseEmailContextNotificationContextAndRecipient = {
  context: BaseEmailContext;
  to?: Maybe<Scalars['String']>;
  toAccountId?: Maybe<Scalars['Int']>;
  toOrgId?: Maybe<Scalars['Int']>;
  toProfileId?: Maybe<Scalars['Int']>;
};

/** Base object */
export type BasicSetting = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** BAU Account Object */
export type BauAccount = {
  accountStatusId: Scalars['ID'];
  countryId?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasPreMappedOrg?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isLegacyEmailUnique?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  legacyAccountID?: Maybe<Scalars['Float']>;
  legacyClaim?: Maybe<Scalars['Float']>;
  legacyEmail?: Maybe<Scalars['String']>;
  legacyInstance?: Maybe<Scalars['String']>;
  legacyLogin?: Maybe<Scalars['String']>;
  legacyPersonID?: Maybe<Scalars['Float']>;
  onboardingStatus?: Maybe<Scalars['String']>;
  onboardingStatusId: Scalars['ID'];
  paypalAccountCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryAccountId?: Maybe<Scalars['Float']>;
  recurlyAccountCode?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<SystemRole>>;
  status?: Maybe<Scalars['String']>;
  timeZoneId?: Maybe<Scalars['Float']>;
  vip?: Maybe<Scalars['Boolean']>;
};

/** Creates or update a user account based on given input values */
export type BauAccountInput = {
  audience: Scalars['String'];
  countryId: Scalars['ID'];
  /** Closest casting market for PC user */
  dmaId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  termsOfUse: Scalars['Boolean'];
  timeZoneId: Scalars['Float'];
};

/** BAU Artist Object */
export type BauArtist = {
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profiles?: Maybe<Array<BauProfile>>;
};

/** Update or Create B21 Profile from BAU */
export type BauArtistInput = {
  /** Artist first name */
  firstName: Scalars['String'];
  /** Is artist minor */
  isMinor: Scalars['Boolean'];
  /** Artist last name */
  lastName: Scalars['String'];
  /** Personal Profile Info */
  personalProfile: BauPersonalProfileInput;
  /** Primary Artist ID */
  primaryArtistId?: InputMaybe<Scalars['Int']>;
  /** Array of Bau Profiles */
  profiles: Array<BauProfileInput>;
};

/** Organization Object */
export type BauCountry = {
  /** Country code */
  code?: Maybe<Scalars['String']>;
  /** Continent id */
  continentId?: Maybe<Scalars['Int']>;
  /** Has dma */
  hasDma?: Maybe<Scalars['Boolean']>;
  /** Has states */
  hasState?: Maybe<Scalars['Boolean']>;
  /** Country id */
  id: Scalars['Int'];
  /** Country name */
  name?: Maybe<Scalars['String']>;
  /** Supporting country id */
  supportingCountryId?: Maybe<Scalars['Int']>;
};

export type BauHeight = {
  cm: Scalars['Int'];
  /** Primary ID */
  id: Scalars['ID'];
  imperial: Scalars['String'];
  inch: Scalars['Float'];
};

export type BauLoginByLegacyDataInput = {
  /** Recovery Type EMAILANDPHONE|PROJECT|STAGE */
  accountRecoveryType: AccountRecoveryType;
  email: Scalars['String'];
  /** project name for validation */
  instance?: InputMaybe<Scalars['String']>;
  /** additional account id */
  migratedAccountID?: InputMaybe<Scalars['Float']>;
  phoneEnding: Scalars['String'];
  /** project name for validation */
  projectName?: InputMaybe<Scalars['String']>;
  /** stage name for validation */
  stageName?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

/** Creates a user account based on given input values */
export type BauLoginInput = {
  instance: Scalars['String'];
  migratedAccountID: Scalars['Float'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type BauMedia = {
  created?: Maybe<Scalars['DateTime']>;
  filename?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  /** Primary ID */
  id: Scalars['ID'];
  length?: Maybe<Scalars['Float']>;
  mediaFileKey?: Maybe<Scalars['String']>;
  mediaId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
  width?: Maybe<Scalars['Int']>;
};

export type BauPcAccountUpdateInput = {
  companyName?: InputMaybe<Scalars['String']>;
  facebookLink?: InputMaybe<Scalars['String']>;
  imdbLink?: InputMaybe<Scalars['String']>;
  instagramLink?: InputMaybe<Scalars['String']>;
  institutionName?: InputMaybe<Scalars['String']>;
  portfolio?: InputMaybe<Scalars['String']>;
  professionalBio?: InputMaybe<Scalars['String']>;
  twitterLink?: InputMaybe<Scalars['String']>;
  vimeoLink?: InputMaybe<Scalars['String']>;
  youtubeLink?: InputMaybe<Scalars['String']>;
};

/** Update or Create B21 Profile from BAU */
export type BauPersonalProfileInput = {
  /** Artist ID */
  artistId?: InputMaybe<Scalars['Int']>;
  /** Profile max playable age */
  maxPlayableAge: Scalars['Int'];
  /** Profile min playable age */
  minPlayableAge: Scalars['Int'];
  /** Profile from REP|UNREP|NEW */
  personalProfileFrom: ProfileFromEnum;
  /** Profile primary appearance ID */
  primaryAppearanceId: Scalars['Int'];
  /** Profile primary location ID */
  primaryLocationId: Scalars['Int'];
  /** Profile ID */
  profileId: Scalars['Int'];
};

/** BAU Profile Object */
export type BauProfile = {
  /** Profile Artist ID */
  artistId?: Maybe<Scalars['Int']>;
  /** Profile  date of birth */
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** Profile Division ID */
  divisionId?: Maybe<Scalars['Int']>;
  /** Profile Division Name */
  divisionName?: Maybe<Scalars['String']>;
  /** Profile height */
  height?: Maybe<BauHeight>;
  /** Profile ID */
  id: Scalars['Int'];
  /** Profile is Personal? */
  isPersonal?: Maybe<Scalars['Boolean']>;
  /** Legacy Profile ID */
  legacyProfileId?: Maybe<Scalars['String']>;
  /** Profile Medias */
  medias?: Maybe<Array<BauMedia>>;
  /** Profile Playable Max Age */
  playableMaxAge?: Maybe<Scalars['Int']>;
  /** Profile Playable Min Age */
  playableMinAge?: Maybe<Scalars['Int']>;
  /** Profile Primary Working Location ID */
  primaryWorkingLocationId?: Maybe<Scalars['Int']>;
  /** Profile Primary Working Location Name */
  primaryWorkingLocationName?: Maybe<Scalars['String']>;
  /** Profile Stage Name */
  stageName?: Maybe<Scalars['String']>;
  /** Profile Status Object */
  status?: Maybe<ProfileStatus>;
  /** Profile talent union name */
  talentUnions?: Maybe<Array<BauTalentUnion>>;
  /** Profile weight */
  weight?: Maybe<BauWeight>;
};

/** Update or Create B21 Profile from BAU */
export type BauProfileInput = {
  /** Artist ID */
  artistId?: InputMaybe<Scalars['Int']>;
  /** Profile division (organization) ID */
  divisionId?: InputMaybe<Scalars['Int']>;
  /** Profile max playable age */
  maxPlayableAge: Scalars['Int'];
  /** Profile min playable age */
  minPlayableAge: Scalars['Int'];
  /** Profile primary appearance ID */
  primaryAppearanceId: Scalars['Int'];
  /** Profile primary location ID */
  primaryLocationId: Scalars['Int'];
  /** Profile ID */
  profileId: Scalars['Int'];
};

/** Update or Create B21 Profile from BAU */
export type BauProfileUpdateInput = {
  /** Array of Bau Artists */
  artists: Array<BauArtistInput>;
};

/** Talent Union object */
export type BauTalentUnion = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type BauWeight = {
  /** Primary ID */
  id: Scalars['ID'];
  kg: Scalars['Float'];
  lbs: Scalars['Int'];
};

export enum BillingCode {
  CbAnnual = 'cb_annual',
  CbM2m = 'cb_m2m',
  CbSemiannual = 'cb_semiannual',
  CbmhAnnual = 'cbmh_annual',
  CbmhM2m = 'cbmh_m2m',
  CbmhSemiannual = 'cbmh_semiannual',
  CbmhpuAnnual = 'cbmhpu_annual',
  CbmhpuM2m = 'cbmhpu_m2m',
  CbmhpuSemiannual = 'cbmhpu_semiannual',
  CbpuAnnual = 'cbpu_annual',
  CbpuM2m = 'cbpu_m2m',
  CbpuSemiannual = 'cbpu_semiannual',
  ConciergeAnnual = 'concierge_annual',
  ConciergeM2m = 'concierge_m2m',
  ConciergeSemiannual = 'concierge_semiannual',
  EssentialAnnual = 'essential_annual',
  EssentialComped = 'essential_comped',
  EssentialM2m = 'essential_m2m',
  LaCbAnnual = 'la_cb_annual',
  LaCbM2m = 'la_cb_m2m',
  LaCbSemiannual = 'la_cb_semiannual',
  LaCbmhAnnual = 'la_cbmh_annual',
  LaCbmhM2m = 'la_cbmh_m2m',
  LaCbmhSemiannual = 'la_cbmh_semiannual',
  LaCbmuAnnual = 'la_cbmu_annual',
  LaCbmuM2m = 'la_cbmu_m2m',
  LaCbmuSemiannual = 'la_cbmu_semiannual',
  LaMhAnnual = 'la_mh_annual',
  LaMhM2m = 'la_mh_m2m',
  LaMhSemiannual = 'la_mh_semiannual',
  MhAnnual = 'mh_annual',
  MhM2m = 'mh_m2m',
  MhSemiannual = 'mh_semiannual',
  MhpuAnnual = 'mhpu_annual',
  MhpuM2m = 'mhpu_m2m',
  MhpuSemiannual = 'mhpu_semiannual',
  PremiumAnnual = 'premium_annual',
  PremiumComped = 'premium_comped',
  PremiumM2m = 'premium_m2m',
  PuAnnual = 'pu_annual',
  PuM2m = 'pu_m2m',
  PuSemiannual = 'pu_semiannual',
  Registration = 'registration',
  UltimateAnnual = 'ultimate_annual',
  UltimateComped = 'ultimate_comped',
  UltimateM2m = 'ultimate_m2m',
}

/** Billing information */
export type BillingInfo = {
  cardType?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isBackupPaymentMethod?: Maybe<Scalars['Boolean']>;
  isPrimaryPaymentMethod?: Maybe<Scalars['Boolean']>;
  lastFour?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  paymentType?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export enum BillingItemCode {
  AudioUpload = 'AUDIO_UPLOAD',
  Benefits = 'BENEFITS',
  BenefitsPlus = 'BENEFITS_PLUS',
  CustomUrlAccess = 'CUSTOM_URL_ACCESS',
  MediaUpload = 'MEDIA_UPLOAD',
  MobileReelsAccess = 'MOBILE_REELS_ACCESS',
  PhotoUpload = 'PHOTO_UPLOAD',
  RoleTrackerAccess = 'ROLE_TRACKER_ACCESS',
  StorageLimitMb = 'STORAGE_LIMIT_MB',
  Submission = 'SUBMISSION',
  SubmissionTrends = 'SUBMISSION_TRENDS',
  SystemAccess = 'SYSTEM_ACCESS',
  TalentScoutAccess = 'TALENT_SCOUT_ACCESS',
  VideoUpload = 'VIDEO_UPLOAD',
  YourAuditions = 'YOUR_AUDITIONS',
  Registration = 'registration',
}

/** Billing Item */
export type BillingItemModel = {
  availableCredit?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceInCents?: Maybe<Scalars['Int']>;
  qty?: Maybe<Scalars['Int']>;
};

/** Billing Plan */
export type BillingPlan = {
  billingCode: Scalars['String'];
  code: Scalars['String'];
  currency: Currency;
  id: Scalars['Int'];
  isBasePlan: Scalars['Boolean'];
  isComped: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  term: BillingPlanTerm;
  trialLengthDays: Scalars['Int'];
};

/** Billing Plan Term */
export type BillingPlanTerm = {
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type BillingPlansInput = {
  billingPath?: InputMaybe<Scalars['String']>;
};

/** Custom URL Slugs cannot contain this blocked keyword */
export type BlockedKeyword = {
  /** Reason why this keyword is blocked - a subset of CustomUrlSlugExpiredReason */
  blockedReason: CustomUrlSlugExpiredReason;
  /** Optional note for why this keyword is blocked */
  blockedReasonNote?: Maybe<Scalars['String']>;
  /** The account that created this blocked keyword */
  createdByAccount: Account;
  /** Date when the keyword was created */
  createdDate: Scalars['DateTime'];
  /** Keyword Id */
  id: Scalars['ID'];
  /** Keyword that custom slugs may not use */
  keyword: Scalars['String'];
};

/** Paginated result with [BlockedKeyword] objects */
export type BlockedKeywordPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of BlockedKeyword objects */
  objects: Array<BlockedKeyword>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type BookingCallListDivisionSubmissionsModel = {
  id?: Maybe<Scalars['Float']>;
  orgName?: Maybe<Scalars['String']>;
  orgParentName?: Maybe<Scalars['String']>;
  orgPhone?: Maybe<Array<Scalars['String']>>;
  submissions?: Maybe<Array<BookingCallListPersonalProfilesModel>>;
};

export type BookingCallListPersonalProfilesModel = {
  cover?: Maybe<ReportMediaModel>;
  deletedProfile: Scalars['Boolean'];
  id?: Maybe<Scalars['Int']>;
  isPersonal?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roleName: Scalars['String'];
  roleRateSummary?: Maybe<Scalars['String']>;
  snapshotMedia?: Maybe<ReportMediaModel>;
  stageName?: Maybe<Scalars['String']>;
  talentUnion?: Maybe<Array<TalentUnionModel>>;
};

/** Booking Call List Report Payload */
export type BookingCallListReportPayloadModel = {
  castingCompanyLogo?: Maybe<ReportMediaModel>;
  divisionSubmissions: Array<BookingCallListDivisionSubmissionsModel>;
  personalSubmissions: Array<BookingCallListPersonalProfilesModel>;
  projectInfo: ProjectReportInfoModel;
};

export type BookingCallListsReportModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<BookingCallListReportPayloadModel>;
  projectId: Scalars['Int'];
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Bust = {
  /** Imperial Feet Inches */
  IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  cm: Scalars['Int'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
  /** Inches rounded */
  inchRounded: Scalars['String'];
  /** Show in imperial UI */
  showInImperialUI: Scalars['Boolean'];
};

export type CdWorksheetFilterInput = {
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
  hasRepresentation?: InputMaybe<Scalars['Boolean']>;
  otherRequest?: InputMaybe<SubmissionRequestSearchInput>;
  presentationIds?: InputMaybe<Array<Scalars['Int']>>;
  projectId: Scalars['Int'];
  representativeIds?: InputMaybe<Array<Scalars['Int']>>;
  representativeListIds?: InputMaybe<Array<Scalars['Int']>>;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  scheduleIds?: InputMaybe<Array<Scalars['Int']>>;
  scheduleRequest?: InputMaybe<SubmissionScheduleRequestSearchInput>;
  sessionIds?: InputMaybe<Array<Scalars['Int']>>;
  stageName?: InputMaybe<Scalars['String']>;
  statusCodes?: InputMaybe<Array<Scalars['String']>>;
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
};

/** Calendar Type object */
export type CalendarType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum CalendarTypeEnum {
  Audition = 'AUDITION',
  Callback = 'CALLBACK',
}

/** Says whether cancel was successful */
export type CancelBenefits = {
  success: Scalars['Boolean'];
};

export type CancelBenefitsInput = {
  cancelReason?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type CancelRequestInput = {
  note?: InputMaybe<Scalars['String']>;
  submissionIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Cancel Schedule Input */
export type CancelScheduleInput = {
  /** Schedule cancellation message */
  cancellationMessage?: InputMaybe<Scalars['String']>;
  /** Schedule ID */
  scheduleId: Scalars['ID'];
};

/** Search Casting Billboard Input */
export type CastingBillboardSearchInput = {
  autofill?: InputMaybe<Scalars['Boolean']>;
  ethnicAppearanceIds?: InputMaybe<Array<Scalars['Int']>>;
  ethnicAppearances?: InputMaybe<Array<EthnicAppearanceEnum>>;
  excludeSexualSituations?: InputMaybe<Scalars['Boolean']>;
  genderAppearanceIds?: InputMaybe<Array<Scalars['Int']>>;
  genderAppearances?: InputMaybe<Array<GenderAppearanceEnum>>;
  /** Keywords */
  keywords?: InputMaybe<Scalars['String']>;
  payPrefences?: InputMaybe<Array<PayPreferencesEnum>>;
  payPreferenceIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Playable Age Range */
  playableAge?: InputMaybe<AgeRangeInput>;
  profileUnionIds?: InputMaybe<Array<Scalars['Int']>>;
  profileUnions?: InputMaybe<Array<TalentUnionEnum>>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectTypeIds?: InputMaybe<Array<Scalars['Int']>>;
  roleId?: InputMaybe<Scalars['Int']>;
  /** Search by Project/Role Names and Descriptions */
  roleProjectKeywordSearch?: InputMaybe<RoleProjectKeywordSearchEnum>;
  roleTargetIds?: InputMaybe<Array<Scalars['Int']>>;
  roleTargets?: InputMaybe<Array<RoleTargetsEnum>>;
  showRolesNoLongerAcceptingSubmissions?: InputMaybe<Scalars['Boolean']>;
  workingLocations?: InputMaybe<Array<Scalars['Int']>>;
};

export type CastingListFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CastingListProfile = {
  audios?: Maybe<Array<CastingListProfileMedia>>;
  credits?: Maybe<Array<Credit>>;
  id: Scalars['ID'];
  listId: Scalars['ID'];
  note: Scalars['String'];
  photos?: Maybe<Array<CastingListProfileMedia>>;
  profile: Profile;
  videos?: Maybe<Array<CastingListProfileMedia>>;
};

export type CastingListProfileMedia = {
  isPrimary: Scalars['Boolean'];
  media: Media;
};

export type CastingListProfilePagedList = {
  data: Array<CastingListProfile>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type CdMediaResponseCounts = {
  confirmed: Scalars['Int'];
  declined: Scalars['Int'];
  notSent: Scalars['Int'];
  receivedResponse: Scalars['Int'];
  sentPending: Scalars['Int'];
};

export type ChangeAuditionGroupTalentOrderInput = {
  /** Submission ID behind which the item should be moved. If not passed will be moved to the beginning */
  behindSubmissionId?: InputMaybe<Scalars['BigInt']>;
  groupId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
};

export type ChangeAuditionSessionTalentRoleNameInput = {
  entityId: Scalars['BigInt'];
  entityTypeCode: EntityTypeEnum;
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
};

/** Create Chat Message Input */
export type ChatMessageCreateInput = {
  messageBody: Scalars['String'];
  topicKey: Scalars['String'];
};

/** Chat Message */
export type ChatMessageModel = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  messageBody: Scalars['String'];
  messageType: ChatMessageType;
  ownMessage: Scalars['Boolean'];
  senderAccount?: Maybe<Account>;
  senderAccountId?: Maybe<Scalars['Int']>;
  senderOrgId?: Maybe<Scalars['Int']>;
  senderOrganization?: Maybe<Organization>;
  topicKey: Scalars['String'];
};

export enum ChatMessageType {
  System = 'System',
  User = 'User',
}

export type ChatSenderAccount = {
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
};

export type ChatTopic = {
  lastUserMessage?: Maybe<ChatMessageModel>;
  topicKey: Scalars['String'];
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

export type ChestSize = {
  /** Imperial Feet Inches */
  IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  cm: Scalars['Int'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
  /** Inches rounded */
  inchRounded: Scalars['String'];
  /** Show in imperial UI */
  showInImperialUI: Scalars['Boolean'];
};

/** Dress size object */
export type ClothesSize = {
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['Int'];
  /** Size for AU */
  sizeAU: Scalars['String'];
  /** Size for EU */
  sizeEU: Scalars['String'];
  /** Numeric size */
  sizeNumeric: Scalars['Int'];
  /** Size for UK */
  sizeUK: Scalars['String'];
  /** Size for US */
  sizeUS: Scalars['String'];
};

/** Coat object */
export type Coat = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Creates a user account based on given input values */
export type CollaboratorAccountCreateInput = {
  countryId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  invitationId: Scalars['String'];
  languageLocaleId?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  termsOfUse: Scalars['Boolean'];
  timeZoneId: Scalars['Int'];
};

export type CollaboratorDetails = {
  collaboratorId?: Maybe<Scalars['Float']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

/** Creates an invite for a user to Collaborate on a project */
export type CollaboratorInviteCreateInput = {
  email: Scalars['String'];
  projectId: Scalars['ID'];
};

export type CollaboratorInviteFromOrgDetails = {
  orgId?: Maybe<Scalars['ID']>;
  /** The name of the organization that the invite is from */
  orgName?: Maybe<Scalars['String']>;
};

export type CollaboratorInviteProjectDetails = {
  internalName?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['Float']>;
  projectName?: Maybe<Scalars['String']>;
};

export enum CollaboratorInviteStatusCode {
  Invited = 'INVITED',
  Joined = 'JOINED',
  Revoked = 'REVOKED',
}

/** Revokes Access For User from Organization */
export type CollaboratorRevokeAccessFromOrgInput = {
  email: Scalars['String'];
};

/** Revokes Access For User from Project */
export type CollaboratorRevokeAccessFromProjectInput = {
  inviteId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type CollaboratorsInOrganization = {
  collaboratorDetails?: Maybe<CollaboratorDetails>;
  collaboratorInviteeOrgDetails?: Maybe<CollaboratorInviteFromOrgDetails>;
  collaboratorsProjectDetails?: Maybe<Array<CollaboratorInviteProjectDetails>>;
  email?: Maybe<Scalars['String']>;
};

export type CollaboratorsInOrganizationCollaboratorsProjectDetailsArgs = {
  pageProjects?: InputMaybe<PageInput>;
};

/** Paginated result with [CollaboratorsInOrganization] objects */
export type CollaboratorsInOrganizationPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of CollaboratorsInOrganization objects */
  objects: Array<CollaboratorsInOrganization>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type CollaboratorsInvite = {
  collaborator?: Maybe<CollaboratorDetails>;
  collaboratorInviteFromOrgDetails?: Maybe<CollaboratorInviteFromOrgDetails>;
  collaboratorInviteProjectDetails?: Maybe<CollaboratorInviteProjectDetails>;
  email: Scalars['String'];
  inviteId: Scalars['String'];
  inviteStatus: CollaboratorInviteStatusCode;
};

/** Paginated result with [CollaboratorsInvite] objects */
export type CollaboratorsInvitePaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of CollaboratorsInvite objects */
  objects: Array<CollaboratorsInvite>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Company Info Model */
export type CompanyInfoModel = {
  logo?: Maybe<ReportMediaModel>;
  name?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
};

/** Compensation object */
export type Compensation = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum CompensationEnum {
  Copy = 'COPY',
  Credit = 'CREDIT',
  Meal = 'MEAL',
  Other = 'OTHER',
}

export type CompleteArtistMediaCreationInput = {
  artistId: Scalars['Int'];
  media: Array<CompleteMediaCreationInputItem>;
};

export type CompleteMediaCreationInputItem = {
  duration?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  mediaId: Scalars['Int'];
  size?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['String']>;
};

/** Conflict object */
export type Conflict = {
  /** child objects */
  childObjects: Array<Conflict>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** array of parent objects */
  parentObject: Array<Conflict>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum ConflictEnum {
  AcneProducts = 'ACNE_PRODUCTS',
  AdhesiveBandages = 'ADHESIVE_BANDAGES',
  AdultIncontinence = 'ADULT_INCONTINENCE',
  AdultNutrition = 'ADULT_NUTRITION',
  AeronauticalCompanies = 'AERONAUTICAL_COMPANIES',
  AestheticFacialInjectables = 'AESTHETIC_FACIAL_INJECTABLES',
  AgriculturalChemical = 'AGRICULTURAL_CHEMICAL',
  AgriculturalFungicides = 'AGRICULTURAL_FUNGICIDES',
  AgriculturalProducts = 'AGRICULTURAL_PRODUCTS',
  Agriculture = 'AGRICULTURE',
  Airlines = 'AIRLINES',
  AirCare = 'AIR_CARE',
  AirFilters = 'AIR_FILTERS',
  AirFresheners = 'AIR_FRESHENERS',
  AirTravel = 'AIR_TRAVEL',
  Alcohol = 'ALCOHOL',
  AlcoholTobacco = 'ALCOHOL_TOBACCO',
  AllergyMedication = 'ALLERGY_MEDICATION',
  Amazon = 'AMAZON',
  Analphylaxis = 'ANALPHYLAXIS',
  Anesthesia = 'ANESTHESIA',
  Antacids = 'ANTACIDS',
  Antiaging = 'ANTIAGING',
  Anticoagulants = 'ANTICOAGULANTS',
  Antidepressent = 'ANTIDEPRESSENT',
  Antifungal = 'ANTIFUNGAL',
  AntipersperantDeodorant = 'ANTIPERSPERANT_DEODORANT',
  Antiplatelet = 'ANTIPLATELET',
  Antivirals = 'ANTIVIRALS',
  Apparel = 'APPAREL',
  Appliances = 'APPLIANCES',
  ArthritisMedications = 'ARTHRITIS_MEDICATIONS',
  Asthma = 'ASTHMA',
  AthleticBrandsSportswear = 'ATHLETIC_BRANDS_SPORTSWEAR',
  AtrialFibrillation = 'ATRIAL_FIBRILLATION',
  AudioEquipment = 'AUDIO_EQUIPMENT',
  AutomobileDealerships = 'AUTOMOBILE_DEALERSHIPS',
  AutomobileServices = 'AUTOMOBILE_SERVICES',
  Automotive = 'AUTOMOTIVE',
  AutoInsurance = 'AUTO_INSURANCE',
  AutoParts = 'AUTO_PARTS',
  AutoRacing = 'AUTO_RACING',
  BabyDiapers = 'BABY_DIAPERS',
  BabyFormula = 'BABY_FORMULA',
  BabyProducts = 'BABY_PRODUCTS',
  BackgroundChecking = 'BACKGROUND_CHECKING',
  BakingProducts = 'BAKING_PRODUCTS',
  Banking = 'BANKING',
  Banks = 'BANKS',
  Batteries = 'BATTERIES',
  Beauty = 'BEAUTY',
  BedsMattressesAndPillows = 'BEDS__MATTRESSES_AND_PILLOWS',
  Beers = 'BEERS',
  BigBoxStores = 'BIG_BOX_STORES',
  BladesAndRazors = 'BLADES_AND_RAZORS',
  BodyLotion = 'BODY_LOTION',
  BoneDensity = 'BONE_DENSITY',
  BottledWater = 'BOTTLED_WATER',
  Bowling = 'BOWLING',
  Bread = 'BREAD',
  BreakfastCereal = 'BREAKFAST_CEREAL',
  Brokerage = 'BROKERAGE',
  BrokerageServices = 'BROKERAGE_SERVICES',
  CableServiceProviders = 'CABLE_SERVICE_PROVIDERS',
  CallingCards = 'CALLING_CARDS',
  CameraCompanies = 'CAMERA_COMPANIES',
  Cancer = 'CANCER',
  Candy = 'CANDY',
  CannedFoods = 'CANNED_FOODS',
  CarbonatedBeverages = 'CARBONATED_BEVERAGES',
  Cardiovascular = 'CARDIOVASCULAR',
  CashAdvance = 'CASH_ADVANCE',
  Casinos = 'CASINOS',
  CasualApparel = 'CASUAL_APPAREL',
  CasualDining = 'CASUAL_DINING',
  CatFood = 'CAT_FOOD',
  ChainRestaurants = 'CHAIN_RESTAURANTS',
  CheckCashing = 'CHECK_CASHING',
  Cheese = 'CHEESE',
  ChewingGums = 'CHEWING_GUMS',
  Children = 'CHILDREN',
  ChildrensHospitals = 'CHILDRENS_HOSPITALS',
  ChipsSemiconductorManufacturers = 'CHIPS_SEMICONDUCTOR_MANUFACTURERS',
  ChipsSnacks = 'CHIPS_SNACKS',
  Chocolate = 'CHOCOLATE',
  Cholesterol = 'CHOLESTEROL',
  Christmas = 'CHRISTMAS',
  ChrohnsDisease = 'CHROHNS_DISEASE',
  ChronicBronchitis = 'CHRONIC_BRONCHITIS',
  Cigarettes = 'CIGARETTES',
  CleaningProducts = 'CLEANING_PRODUCTS',
  Clinics = 'CLINICS',
  Clothing = 'CLOTHING',
  Coffee = 'COFFEE',
  CoffeeMakers = 'COFFEE_MAKERS',
  Cola = 'COLA',
  ColdFluMedications = 'COLD_FLU_MEDICATIONS',
  Colleges = 'COLLEGES',
  CompressionWear = 'COMPRESSION_WEAR',
  ComputerHardware = 'COMPUTER_HARDWARE',
  Concrete = 'CONCRETE',
  Condiments = 'CONDIMENTS',
  Confectionary = 'CONFECTIONARY',
  ConsumerElectronics = 'CONSUMER_ELECTRONICS',
  ConsumerTechnology = 'CONSUMER_TECHNOLOGY',
  Contraception = 'CONTRACEPTION',
  ConvenvienceStores = 'CONVENVIENCE_STORES',
  Cookies = 'COOKIES',
  CookingSprays = 'COOKING_SPRAYS',
  CorporateBanking = 'CORPORATE_BANKING',
  Cosmetics = 'COSMETICS',
  CosmeticInjectables = 'COSMETIC_INJECTABLES',
  CoughColdRemedies = 'COUGH_COLD_REMEDIES',
  CoughDrops = 'COUGH_DROPS',
  Crackers = 'CRACKERS',
  CraftStores = 'CRAFT_STORES',
  CreditDebitCards = 'CREDIT_DEBIT_CARDS',
  CreditUnions = 'CREDIT_UNIONS',
  CruiseLines = 'CRUISE_LINES',
  CurlingIrons = 'CURLING_IRONS',
  Dairy = 'DAIRY',
  DatingSites = 'DATING_SITES',
  Dealerships = 'DEALERSHIPS',
  DeliveryServices = 'DELIVERY_SERVICES',
  DeliMeats = 'DELI_MEATS',
  DementiaProducts = 'DEMENTIA_PRODUCTS',
  Dental = 'DENTAL',
  DentalProducts = 'DENTAL_PRODUCTS',
  DentalServices = 'DENTAL_SERVICES',
  DepartmentStores = 'DEPARTMENT_STORES',
  Dermatology = 'DERMATOLOGY',
  Desserts = 'DESSERTS',
  Detergent = 'DETERGENT',
  DiabetesMedication = 'DIABETES_MEDICATION',
  DiabeticPeripheralNeuropathyDrugs = 'DIABETIC_PERIPHERAL_NEUROPATHY_DRUGS',
  DicedTomatoes = 'DICED_TOMATOES',
  DigitalTvServiceProviders = 'DIGITAL_TV_SERVICE_PROVIDERS',
  DineinRestaurants = 'DINEIN_RESTAURANTS',
  DiscountRetail = 'DISCOUNT_RETAIL',
  DiscountShoeRetailers = 'DISCOUNT_SHOE_RETAILERS',
  DogFood = 'DOG_FOOD',
  DogTreats = 'DOG_TREATS',
  DollarStores = 'DOLLAR_STORES',
  DrugStores = 'DRUG_STORES',
  Ecigarettes = 'ECIGARETTES',
  Ecommerce = 'ECOMMERCE',
  Education = 'EDUCATION',
  EducationalInstitutions = 'EDUCATIONAL_INSTITUTIONS',
  Eggs = 'EGGS',
  ElderlyCare = 'ELDERLY_CARE',
  ElectricalCompany = 'ELECTRICAL_COMPANY',
  Electronics = 'ELECTRONICS',
  ElectronicsRetailStores = 'ELECTRONICS_RETAIL_STORES',
  EmailMarketing = 'EMAIL_MARKETING',
  Emphysema = 'EMPHYSEMA',
  EnergyCompanies = 'ENERGY_COMPANIES',
  EnergyDrinks = 'ENERGY_DRINKS',
  EnergyProviders = 'ENERGY_PROVIDERS',
  EnterpriseSoftware = 'ENTERPRISE_SOFTWARE',
  Entertainment = 'ENTERTAINMENT',
  Epinephrine = 'EPINEPHRINE',
  ErectileDysfunction = 'ERECTILE_DYSFUNCTION',
  ExerciseEquipment = 'EXERCISE_EQUIPMENT',
  Eyecare = 'EYECARE',
  Eyeglasses = 'EYEGLASSES',
  Eyewear = 'EYEWEAR',
  Fabric = 'FABRIC',
  FabricSofteners = 'FABRIC_SOFTENERS',
  FantasySports = 'FANTASY_SPORTS',
  Fashion = 'FASHION',
  FastFoodRestaurants = 'FAST_FOOD_RESTAURANTS',
  Fedex = 'FEDEX',
  FeminineHygieneProducts = 'FEMININE_HYGIENE_PRODUCTS',
  FiberSupplements = 'FIBER_SUPPLEMENTS',
  Fibromyalgia = 'FIBROMYALGIA',
  Financial = 'FINANCIAL',
  FinancialInstitutions = 'FINANCIAL_INSTITUTIONS',
  FinancialServiceGroups = 'FINANCIAL_SERVICE_GROUPS',
  Fitness = 'FITNESS',
  FitnessCentersGyms = 'FITNESS_CENTERS_GYMS',
  FitnessTrackers = 'FITNESS_TRACKERS',
  FlooringCompanies = 'FLOORING_COMPANIES',
  Food = 'FOOD',
  Footwear = 'FOOTWEAR',
  Fragrance = 'FRAGRANCE',
  FreightCompanies = 'FREIGHT_COMPANIES',
  FrozenDessert = 'FROZEN_DESSERT',
  FrozenFoods = 'FROZEN_FOODS',
  FrozenMeals = 'FROZEN_MEALS',
  FrozenPizza = 'FROZEN_PIZZA',
  FruitDried = 'FRUIT_DRIED',
  FruitFresh = 'FRUIT_FRESH',
  FruitJuice = 'FRUIT_JUICE',
  Furniture = 'FURNITURE',
  Gaming = 'GAMING',
  GamingRetailers = 'GAMING_RETAILERS',
  Gastreoenterology = 'GASTREOENTEROLOGY',
  GasCompany = 'GAS_COMPANY',
  GasStations = 'GAS_STATIONS',
  Glaucoma = 'GLAUCOMA',
  GlucoseMonitors = 'GLUCOSE_MONITORS',
  GolfEquipment = 'GOLF_EQUIPMENT',
  GroceryStores = 'GROCERY_STORES',
  Hair = 'HAIR',
  HairCare = 'HAIR_CARE',
  HairColor = 'HAIR_COLOR',
  HairGrowth = 'HAIR_GROWTH',
  HairProducts = 'HAIR_PRODUCTS',
  HairRegrowth = 'HAIR_REGROWTH',
  HairRemoval = 'HAIR_REMOVAL',
  Hardware = 'HARDWARE',
  Healthcare = 'HEALTHCARE',
  HealthInsurance = 'HEALTH_INSURANCE',
  Heartburn = 'HEARTBURN',
  Hematology = 'HEMATOLOGY',
  HighSpeedInternetServiceProviders = 'HIGH_SPEED_INTERNET_SERVICE_PROVIDERS',
  HivAidsPhamaceuticals = 'HIV_AIDS_PHAMACEUTICALS',
  Hmos = 'HMOS',
  Holidays = 'HOLIDAYS',
  Home = 'HOME',
  HomeCooling = 'HOME_COOLING',
  HomeFurnishings = 'HOME_FURNISHINGS',
  HomeHeating = 'HOME_HEATING',
  HomeImprovement = 'HOME_IMPROVEMENT',
  HomeImprovementStores = 'HOME_IMPROVEMENT_STORES',
  HomeInsurance = 'HOME_INSURANCE',
  HomeSecurityAutomation = 'HOME_SECURITY_AUTOMATION',
  Hospitals = 'HOSPITALS',
  HotelHospitality = 'HOTEL_HOSPITALITY',
  HotBeverages = 'HOT_BEVERAGES',
  Household = 'HOUSEHOLD',
  HouseholdPaperGoods = 'HOUSEHOLD_PAPER_GOODS',
  Hvac = 'HVAC',
  Hygiene = 'HYGIENE',
  IceCream = 'ICE_CREAM',
  Incontience = 'INCONTIENCE',
  Insurance = 'INSURANCE',
  InternationalCampaigns = 'INTERNATIONAL_CAMPAIGNS',
  InternetServiceProviders = 'INTERNET_SERVICE_PROVIDERS',
  Intimates = 'INTIMATES',
  Jewelry = 'JEWELRY',
  KidsApparel = 'KIDS_APPAREL',
  KitchenAppliances = 'KITCHEN_APPLIANCES',
  LanguageInstitutes = 'LANGUAGE_INSTITUTES',
  Laptops = 'LAPTOPS',
  LawnGarden = 'LAWN_GARDEN',
  Laxatives = 'LAXATIVES',
  LegacyConflict = 'LEGACY_CONFLICT',
  LifeInsurance = 'LIFE_INSURANCE',
  Lightbulbs = 'LIGHTBULBS',
  Liquor = 'LIQUOR',
  LiverDisease = 'LIVER_DISEASE',
  LocalCasinos = 'LOCAL_CASINOS',
  LocalFastFood = 'LOCAL_FAST_FOOD',
  LocalGaming = 'LOCAL_GAMING',
  LocalHospitals = 'LOCAL_HOSPITALS',
  Makeup = 'MAKEUP',
  MaltBeverages = 'MALT_BEVERAGES',
  MayonnaiseBrands = 'MAYONNAISE_BRANDS',
  MealKitDeliveryServices = 'MEAL_KIT_DELIVERY_SERVICES',
  Meat = 'MEAT',
  MedicalCenters = 'MEDICAL_CENTERS',
  MedicalGradeSilicone = 'MEDICAL_GRADE_SILICONE',
  Medicare = 'MEDICARE',
  Medication = 'MEDICATION',
  Menopause = 'MENOPAUSE',
  MilkAlternatives = 'MILK_ALTERNATIVES',
  Mints = 'MINTS',
  Miscellaneous = 'MISCELLANEOUS',
  Mlb = 'MLB',
  MobileCellPhones = 'MOBILE_CELL_PHONES',
  Mobility = 'MOBILITY',
  Moisturizer = 'MOISTURIZER',
  MortgageServices = 'MORTGAGE_SERVICES',
  MotorOil = 'MOTOR_OIL',
  Mouthwash = 'MOUTHWASH',
  MutualFunds = 'MUTUAL_FUNDS',
  Nba = 'NBA',
  Neuropathy = 'NEUROPATHY',
  Nfl = 'NFL',
  Nhl = 'NHL',
  NonalcoholicMaltBeverages = 'NONALCOHOLIC_MALT_BEVERAGES',
  NutButters = 'NUT_BUTTERS',
  OfficeSupplyRetailers = 'OFFICE_SUPPLY_RETAILERS',
  Oncology = 'ONCOLOGY',
  OnlineAutoSales = 'ONLINE_AUTO_SALES',
  OnlineTravelSites = 'ONLINE_TRAVEL_SITES',
  OralCare = 'ORAL_CARE',
  OralDentureProducts = 'ORAL_DENTURE_PRODUCTS',
  OralHygiene = 'ORAL_HYGIENE',
  OverTheCounter = 'OVER_THE_COUNTER',
  PainRelief = 'PAIN_RELIEF',
  PaperTowel = 'PAPER_TOWEL',
  Pasta = 'PASTA',
  Perfumes = 'PERFUMES',
  PersonalBanking = 'PERSONAL_BANKING',
  Petroleum = 'PETROLEUM',
  Pets = 'PETS',
  PetProducts = 'PET_PRODUCTS',
  PetStores = 'PET_STORES',
  Pharmaceutical = 'PHARMACEUTICAL',
  Pharmacies = 'PHARMACIES',
  PhoneChargers = 'PHONE_CHARGERS',
  PhoneServiceProviders = 'PHONE_SERVICE_PROVIDERS',
  Plumbing = 'PLUMBING',
  Ppos = 'PPOS',
  PrepackagedLunch = 'PREPACKAGED_LUNCH',
  ProfessionalSportLeagues = 'PROFESSIONAL_SPORT_LEAGUES',
  Psoriasis = 'PSORIASIS',
  QuickServiceRestaurants = 'QUICK_SERVICE_RESTAURANTS',
  QuickServiceRestaurantsBurger = 'QUICK_SERVICE_RESTAURANTS_BURGER',
  QuickServiceRestaurantsChicken = 'QUICK_SERVICE_RESTAURANTS_CHICKEN',
  QuickServiceRestaurantsPizza = 'QUICK_SERVICE_RESTAURANTS_PIZZA',
  QuickServiceRestaurantsSandwich = 'QUICK_SERVICE_RESTAURANTS_SANDWICH',
  QuickServiceRestaurantsSeafood = 'QUICK_SERVICE_RESTAURANTS_SEAFOOD',
  RealEstate = 'REAL_ESTATE',
  RecommerceResale = 'RECOMMERCE_RESALE',
  RegionalCasinos = 'REGIONAL_CASINOS',
  RegionalFastFood = 'REGIONAL_FAST_FOOD',
  RegionalGaming = 'REGIONAL_GAMING',
  RegionalHospitals = 'REGIONAL_HOSPITALS',
  RegionalLotteries = 'REGIONAL_LOTTERIES',
  RentalCars = 'RENTAL_CARS',
  Resorts = 'RESORTS',
  Restaurants = 'RESTAURANTS',
  Retail = 'RETAIL',
  RetailChains = 'RETAIL_CHAINS',
  RetailEcommerce = 'RETAIL_ECOMMERCE',
  RetirementServices = 'RETIREMENT_SERVICES',
  RheumatoidArthritis = 'RHEUMATOID_ARTHRITIS',
  Rheumatology = 'RHEUMATOLOGY',
  Rx = 'RX',
  SaltySnacks = 'SALTY_SNACKS',
  SatelliteTvServiceProviders = 'SATELLITE_TV_SERVICE_PROVIDERS',
  Sauces = 'SAUCES',
  Sausage = 'SAUSAGE',
  Seafood = 'SEAFOOD',
  ShampooConditioner = 'SHAMPOO_CONDITIONER',
  Shipping = 'SHIPPING',
  Shoes = 'SHOES',
  SkinCare = 'SKIN_CARE',
  Smarthome = 'SMARTHOME',
  Snacks = 'SNACKS',
  SnackBars = 'SNACK_BARS',
  SocialMedia = 'SOCIAL_MEDIA',
  SocialSecurity = 'SOCIAL_SECURITY',
  Software = 'SOFTWARE',
  Soups = 'SOUPS',
  Spirits = 'SPIRITS',
  SportingGoods = 'SPORTING_GOODS',
  Sports = 'SPORTS',
  Sportswear = 'SPORTSWEAR',
  SportsDrinks = 'SPORTS_DRINKS',
  SportsMedia = 'SPORTS_MEDIA',
  StateLotteries = 'STATE_LOTTERIES',
  StreamingTvProviders = 'STREAMING_TV_PROVIDERS',
  SugarBased = 'SUGAR_BASED',
  Sunscreen = 'SUNSCREEN',
  Supermarkets = 'SUPERMARKETS',
  SupplementalHealthInsurance = 'SUPPLEMENTAL_HEALTH_INSURANCE',
  Supplements = 'SUPPLEMENTS',
  SweetSnacks = 'SWEET_SNACKS',
  Tablets = 'TABLETS',
  TabletReaders = 'TABLET_READERS',
  TanningProducts = 'TANNING_PRODUCTS',
  TaxPreparation = 'TAX_PREPARATION',
  Tea = 'TEA',
  Technology = 'TECHNOLOGY',
  TechnologyCompanies = 'TECHNOLOGY_COMPANIES',
  TeethWhitening = 'TEETH_WHITENING',
  Telecommunications = 'TELECOMMUNICATIONS',
  Testosterone = 'TESTOSTERONE',
  ThemeParks = 'THEME_PARKS',
  ThriftStores = 'THRIFT_STORES',
  TireCompanies = 'TIRE_COMPANIES',
  TireRetailers = 'TIRE_RETAILERS',
  TnfBlockers = 'TNF_BLOCKERS',
  Tobacco = 'TOBACCO',
  ToiletPaper = 'TOILET_PAPER',
  Toothpastes = 'TOOTHPASTES',
  Tourism = 'TOURISM',
  Toys = 'TOYS',
  TradeSchools = 'TRADE_SCHOOLS',
  Travel = 'TRAVEL',
  TravelProducts = 'TRAVEL_PRODUCTS',
  TravelServices = 'TRAVEL_SERVICES',
  Trucks = 'TRUCKS',
  Underwear = 'UNDERWEAR',
  Universities = 'UNIVERSITIES',
  Usps = 'USPS',
  Utilities = 'UTILITIES',
  Vacation = 'VACATION',
  VacationDestinations = 'VACATION_DESTINATIONS',
  Vaccines = 'VACCINES',
  VacuumCleaners = 'VACUUM_CLEANERS',
  VideoGames = 'VIDEO_GAMES',
  Vision = 'VISION',
  Vitamins = 'VITAMINS',
  Watches = 'WATCHES',
  Water = 'WATER',
  WaterFilters = 'WATER_FILTERS',
  Websites = 'WEBSITES',
  WeedKillers = 'WEED_KILLERS',
  WeightLossDiet = 'WEIGHT_LOSS_DIET',
  Wine = 'WINE',
  WirelessPhoneServiceProviders = 'WIRELESS_PHONE_SERVICE_PROVIDERS',
  Yogurt = 'YOGURT',
}

/** Contact information */
export type Contact = {
  contactId: Scalars['Float'];
  /** Email */
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  /** Contact ID */
  id: Scalars['ID'];
  /** Defines organization main contact */
  mainContact: Scalars['Boolean'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Phone */
  phone: Scalars['String'];
};

/** Input type for contact */
export type ContactInput = {
  contactId?: InputMaybe<Scalars['Int']>;
  /** Email */
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Defines organization main contact */
  mainContact: Scalars['Boolean'];
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Phone */
  phone: Scalars['String'];
};

export type ContactSubTalentInput = {
  includeProjectRole?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
  roleId: Scalars['Int'];
  subject?: InputMaybe<Scalars['String']>;
  submissionStatusType: RepsSubmissionTypeEnum;
  talentCanSeeSenderAddress?: InputMaybe<Scalars['Boolean']>;
};

export type ContextRole = {
  code: SystemRoleCode;
  id: Scalars['Int'];
};

/** Continent object */
export type Continent = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Contract Status Object */
export type ContractStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum ContractStatusEnum {
  Approved = 'APPROVED',
  Pending = 'PENDING',
}

export type CopyAllProfileToCastingListInput = {
  fromListId: Scalars['ID'];
  includeNotes?: Scalars['Boolean'];
  toListIds: Array<Scalars['ID']>;
};

export type CopyPresentationInput = {
  /** Name of new presentation */
  name: Scalars['String'];
  /** Presentation Id to copy */
  presentationId: Scalars['Int'];
  /** Project ID */
  projectId: Scalars['Int'];
};

export type CopyProfileToCastingListError = ValidationError;

export type CopyProfileToCastingListInput = {
  includeNotes?: Scalars['Boolean'];
  listProfileIds: Array<Scalars['ID']>;
  toListIds: Array<Scalars['ID']>;
};

export type CopyProfileToCastingListResult = {
  error?: Maybe<CopyProfileToCastingListError>;
  success: Scalars['Boolean'];
};

/** Project Union */
export type Country = {
  /** code */
  code: Scalars['String'];
  /** Continent */
  continent: Continent;
  /** Currency */
  currency?: Maybe<Currency>;
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  regions?: Maybe<Array<Region>>;
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Supporting country id */
  supportingCountry?: Maybe<Country>;
  /** Unit System */
  unitSystem: UnitSystem;
};

export enum CountryEnum {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cs = 'CS',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xk = 'XK',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
  '00' = '_00',
}

export type CountryItem = {
  /** code */
  code: Scalars['String'];
  /** Is this enbaled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type Coupon = {
  code: Scalars['String'];
  description: Scalars['String'];
  isRedeemed: Scalars['Boolean'];
  state: Scalars['String'];
};

/** Creates a user account with Google id token and refresh token */
export type CreateAccountWithGoogleInput = {
  authCode: Scalars['String'];
  countryId: Scalars['ID'];
  /** Closest casting market for PC user */
  dmaId?: InputMaybe<Scalars['Int']>;
  isMobileAppClient?: InputMaybe<Scalars['Boolean']>;
  languageLocaleId?: InputMaybe<Scalars['Int']>;
  /** Marketing option subscription option */
  marketingOptIn?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  professionalBio?: InputMaybe<Scalars['String']>;
  profileAccessToken?: InputMaybe<Scalars['String']>;
  rosterInvitationToken?: InputMaybe<Scalars['String']>;
  systemRoleCode?: InputMaybe<SystemRoleEnum>;
  termsOfUse: Scalars['Boolean'];
  timeZoneId: Scalars['Int'];
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
};

export type CreateArtistMediaInput = {
  artistId: Scalars['Int'];
  media: Array<CreateMediaInputItem>;
};

export type CreateArtistMediaResponse = ICreateMediaResponse & {
  artist: Artist;
  media: Array<Media>;
};

export type CreateCastingListResult = {
  error?: Maybe<MutateListError>;
  success?: Maybe<Scalars['Int']>;
};

/** Create Comped Subscription Input */
export type CreateCompedSubscriptionInput = {
  /** Account id */
  accountId: Scalars['Int'];
  /** Artist id */
  artistId: Scalars['Int'];
  /** Billing code */
  billingCode: Scalars['String'];
  /** Subscription ends at */
  endsAt: Scalars['DateTime'];
};

/** Create Coupon Redemption Input */
export type CreateCouponRedemptionInput = {
  couponId: Scalars['String'];
};

export type CreateFormResult = {
  error?: Maybe<MutateFormError>;
  /** The ID of the created form */
  formId?: Maybe<Scalars['Int']>;
};

export type CreateMediaInputItem = {
  filename: Scalars['String'];
};

/** Input type for Organization */
export type CreateOrganizationInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  addresses?: InputMaybe<Array<AddressInput>>;
  contacts?: InputMaybe<Array<ContactInput>>;
  creatorId?: InputMaybe<Scalars['ID']>;
  diversityDisclaimer?: InputMaybe<Scalars['String']>;
  /** Devisions could have multiple locations/dma's */
  dmaIds?: InputMaybe<Array<Scalars['ID']>>;
  featureId?: InputMaybe<Array<Scalars['ID']>>;
  features?: InputMaybe<Array<OrganizationFeatureInput>>;
  logoMediaId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  organizationParentId?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  profileListId?: InputMaybe<Scalars['ID']>;
  projectCastingAssistant?: InputMaybe<Scalars['String']>;
  projectCastingAssociate?: InputMaybe<Scalars['String']>;
  projectCastingDirector?: InputMaybe<Scalars['String']>;
  representationAgeCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  representationCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  representationTypeId?: InputMaybe<Scalars['ID']>;
  scheduleAddressNote?: InputMaybe<Scalars['String']>;
  systemRoleId?: InputMaybe<Scalars['ID']>;
  tileSchemeId?: InputMaybe<Scalars['ID']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

/** Input type for PC Project */
export type CreatePcProjectInput = {
  adAgency?: InputMaybe<Scalars['String']>;
  additionalProvisions?: InputMaybe<Scalars['String']>;
  /** Uploaded media ids */
  attachmentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Project sides visibility CODE */
  attachmentVisibilityCode?: InputMaybe<Scalars['String']>;
  auditionCity?: InputMaybe<Scalars['String']>;
  auditionDates?: InputMaybe<Array<Scalars['DateTime']>>;
  auditionTypeId?: InputMaybe<Scalars['ID']>;
  canTalentSubmitSelfTape?: InputMaybe<Scalars['Boolean']>;
  castingAssistant?: InputMaybe<Scalars['String']>;
  castingAssociate?: InputMaybe<Scalars['String']>;
  castingDirector?: InputMaybe<Scalars['String']>;
  conflictIds?: InputMaybe<Array<Scalars['ID']>>;
  conflictNote?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactInfoPermissions: Scalars['Boolean'];
  contactPhone?: InputMaybe<Scalars['String']>;
  /** Contract Status Code */
  contractStatusCode?: InputMaybe<Scalars['String']>;
  determinedLocationInput?: InputMaybe<PcProjectDeterminedAddressInput>;
  director?: InputMaybe<Scalars['String']>;
  executiveProducer?: InputMaybe<Scalars['String']>;
  fieldRestrictionSettings?: InputMaybe<Array<ProjectFieldRestrictionInput>>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  hideProjectRate?: InputMaybe<Scalars['Boolean']>;
  internalName?: InputMaybe<Scalars['String']>;
  /**
   * If null/undefined/false than PC project audition address not specified, if
   * true than the isSpecificLocation should be not null/undefined
   */
  isAuditionInPerson?: InputMaybe<Scalars['Boolean']>;
  /**
   * Can not be null/undefined while isAuditionInPerson is true, if this field is
   * true than API will use specificLocationInput, if false than API use
   * determinedLocationInput
   */
  isSpecificLocation?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  network?: InputMaybe<Scalars['String']>;
  newSeriesName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Int']>;
  photographer?: InputMaybe<Scalars['String']>;
  producer?: InputMaybe<Scalars['String']>;
  productionCompany?: InputMaybe<Scalars['String']>;
  projectOrganization?: InputMaybe<Array<ProjectOrganizationInput>>;
  projectParentId?: InputMaybe<Scalars['ID']>;
  projectSeriesEpisode?: InputMaybe<Scalars['Int']>;
  projectSeriesId?: InputMaybe<Scalars['ID']>;
  projectSeriesSeason?: InputMaybe<Scalars['Int']>;
  projectStatusCode?: InputMaybe<Scalars['String']>;
  projectStatusId?: InputMaybe<Scalars['ID']>;
  projectTypeId: Scalars['ID'];
  projectUnionId?: InputMaybe<Scalars['ID']>;
  rate?: InputMaybe<Scalars['String']>;
  /** PC project release location codes */
  releaseLocations?: InputMaybe<Array<Scalars['String']>>;
  requestBrief?: InputMaybe<Scalars['Boolean']>;
  requestBriefInstruction?: InputMaybe<Scalars['String']>;
  roleDefaults?: InputMaybe<RoleDefaultsInput>;
  /** Uploaded script Id */
  scriptFileId?: InputMaybe<Scalars['Int']>;
  specificLocationInput?: InputMaybe<PcProjectSpecificAddressInput>;
  studio?: InputMaybe<Scalars['String']>;
  submissionsDueBy?: InputMaybe<Scalars['DateTime']>;
  synopsis?: InputMaybe<Scalars['String']>;
  tileSchemeId?: InputMaybe<Scalars['ID']>;
  unionContractId?: InputMaybe<Scalars['String']>;
  usage?: InputMaybe<Scalars['String']>;
  workCity?: InputMaybe<Scalars['String']>;
  workDates?: InputMaybe<Array<Scalars['DateTime']>>;
  writer?: InputMaybe<Scalars['String']>;
};

/** Create profile conflict input */
export type CreateProfileConflictInput = {
  conflictId: Scalars['Int'];
  expiration?: InputMaybe<Scalars['DateTime']>;
  note?: InputMaybe<Scalars['String']>;
  profileId: Scalars['Int'];
};

/** Input type for creating profile submission */
export type CreateProfileSubmissionInput = {
  /** ID of the creator */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** Profile working location id */
  dmaId?: InputMaybe<Scalars['Int']>;
  /** Represents that profile is overscale or not */
  isOverscale?: InputMaybe<Scalars['Boolean']>;
  /** Note for submission */
  note?: InputMaybe<Scalars['String']>;
  /** Over scale note */
  overscaleNote?: InputMaybe<Scalars['String']>;
  /** Array of profile ids */
  profileIds: Array<Scalars['Int']>;
  /** Request media(s) */
  requestMedia?: InputMaybe<Array<SubmissionMediaInput>>;
  /** Role Id */
  roleId: Scalars['Int'];
  /** Submission profile media(s) */
  submissionProfileMedia?: InputMaybe<Array<SubmissionMediaInput>>;
  /** Submission status code */
  submissionStatusCode: Scalars['String'];
};

export type CreateProfileSubmissionOrGetInput = {
  profileId: Scalars['Int'];
  roleId: Scalars['Int'];
};

/** Input type for Project */
export type CreateProjectInput = {
  adAgency?: InputMaybe<Scalars['String']>;
  additionalProvisions?: InputMaybe<Scalars['String']>;
  /** Uploaded media ids */
  attachmentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Project sides visibility CODE */
  attachmentVisibilityCode?: InputMaybe<Scalars['String']>;
  auditionCity?: InputMaybe<Scalars['String']>;
  auditionDates?: InputMaybe<Array<Scalars['DateTime']>>;
  auditionTypeId?: InputMaybe<Scalars['ID']>;
  castingAssistant?: InputMaybe<Scalars['String']>;
  castingAssociate?: InputMaybe<Scalars['String']>;
  castingDirector?: InputMaybe<Scalars['String']>;
  conflictIds?: InputMaybe<Array<Scalars['ID']>>;
  conflictNote?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactInfoPermissions: Scalars['Boolean'];
  contactPhone?: InputMaybe<Scalars['String']>;
  /** Contract Status Code */
  contractStatusCode?: InputMaybe<Scalars['String']>;
  director?: InputMaybe<Scalars['String']>;
  executiveProducer?: InputMaybe<Scalars['String']>;
  fieldRestrictionSettings?: InputMaybe<Array<ProjectFieldRestrictionInput>>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  hideProjectRate?: InputMaybe<Scalars['Boolean']>;
  internalName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  network?: InputMaybe<Scalars['String']>;
  newSeriesName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Int']>;
  photographer?: InputMaybe<Scalars['String']>;
  producer?: InputMaybe<Scalars['String']>;
  productionCompany?: InputMaybe<Scalars['String']>;
  projectOrganization?: InputMaybe<Array<ProjectOrganizationInput>>;
  projectParentId?: InputMaybe<Scalars['ID']>;
  projectSeriesEpisode?: InputMaybe<Scalars['Int']>;
  projectSeriesId?: InputMaybe<Scalars['ID']>;
  projectSeriesSeason?: InputMaybe<Scalars['Int']>;
  projectStatusCode?: InputMaybe<Scalars['String']>;
  projectStatusId?: InputMaybe<Scalars['ID']>;
  projectTypeId: Scalars['ID'];
  projectUnionId?: InputMaybe<Scalars['ID']>;
  rate?: InputMaybe<Scalars['String']>;
  requestBrief?: InputMaybe<Scalars['Boolean']>;
  requestBriefInstruction?: InputMaybe<Scalars['String']>;
  roleDefaults?: InputMaybe<RoleDefaultsInput>;
  /** Uploaded script Id */
  scriptFileId?: InputMaybe<Scalars['Int']>;
  studio?: InputMaybe<Scalars['String']>;
  submissionsDueBy?: InputMaybe<Scalars['DateTime']>;
  synopsis?: InputMaybe<Scalars['String']>;
  tileSchemeId?: InputMaybe<Scalars['ID']>;
  unionContractId?: InputMaybe<Scalars['String']>;
  usage?: InputMaybe<Scalars['String']>;
  workCity?: InputMaybe<Scalars['String']>;
  workDates?: InputMaybe<Array<Scalars['DateTime']>>;
  writer?: InputMaybe<Scalars['String']>;
};

export type CreateProjectSeriesInput = {
  seriesName: Scalars['String'];
};

export type CreateSavedSearchInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notificationType?: InputMaybe<Scalars['String']>;
  searchArgs: CastingBillboardSearchInput;
};

/** Schedule Input */
export type CreateScheduleInput = {
  /** Address Id */
  addressId?: InputMaybe<Scalars['Int']>;
  /** Is location hidden */
  isLocationHidden?: InputMaybe<Scalars['Boolean']>;
  /** Is Virtual Audition */
  isVirtualAudition?: InputMaybe<Scalars['Boolean']>;
  /** Schedule Name */
  name: Scalars['String'];
  /** Note */
  note?: InputMaybe<Scalars['String']>;
  /** Project Id */
  projectId: Scalars['ID'];
  /** Schedule Date */
  scheduleDate: Scalars['String'];
  /** Schedule Type Code */
  scheduleTypeCode: Scalars['String'];
  /** Time Zone CODE */
  timeZoneCode?: InputMaybe<Scalars['String']>;
  /** Time Zone Id */
  timeZoneId?: InputMaybe<Scalars['ID']>;
};

/** Create Subscription Input */
export type CreateSubscriptionInput = {
  /** Account code */
  accountCode: Scalars['String'];
  /** Artist id */
  artistId: Scalars['Float'];
  /** Billing code */
  billingCode: BillingCode;
  /** Country id */
  countryId: Scalars['Float'];
  /** Coupon code */
  coupon?: InputMaybe<Scalars['String']>;
  /** Next billing date */
  nextBillDate: Scalars['DateTime'];
};

export type CreateTalentScoutHistoryInput = {
  message: Scalars['String'];
  profileId: Scalars['Int'];
};

/** Credit Attribute */
export type Credit = {
  /** Created at Date */
  created: Scalars['DateTime'];
  creditMedia: Array<Media>;
  /** Credit Sort ordering */
  creditOrder: Scalars['Int'];
  /** Credit Type */
  creditType?: Maybe<CreditType>;
  /** The director of the Project */
  director?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  /** Highlighted */
  highlighted: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Profile Credit Type Id */
  profileCreditTypeId: Scalars['Float'];
  /** Profile ID */
  profileId: Scalars['ID'];
  /** Role title */
  role?: Maybe<Scalars['String']>;
  /** Credit Type Sort ordering */
  sortOrder: Scalars['Int'];
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Updated at Date */
  updated: Scalars['DateTime'];
  /** Year */
  year?: Maybe<Scalars['Int']>;
};

export enum CreditCardTypeEnum {
  Commercial = 'COMMERCIAL',
  Corporate = 'CORPORATE',
  Dance = 'DANCE',
  Documentary = 'DOCUMENTARY',
  Education = 'EDUCATION',
  Film = 'FILM',
  Hosting = 'HOSTING',
  Industrial = 'INDUSTRIAL',
  Infomercial = 'INFOMERCIAL',
  Modeling = 'MODELING',
  Music = 'MUSIC',
  MusicalTheater = 'MUSICAL_THEATER',
  MusicVideo = 'MUSIC_VIDEO',
  OnlineContent = 'ONLINE_CONTENT',
  Opera = 'OPERA',
  Print = 'PRINT',
  Radio = 'RADIO',
  RealityTv = 'REALITY_TV',
  ShortFilm = 'SHORT_FILM',
  StandUpComedy = 'STAND_UP_COMEDY',
  Television = 'TELEVISION',
  Theater = 'THEATER',
  Training = 'TRAINING',
  VideoGame = 'VIDEO_GAME',
  VoiceOver = 'VOICE_OVER',
  WebSeries = 'WEB_SERIES',
}

/** Input type for Credit */
export type CreditInput = {
  /** Credit type id */
  creditTypeId: Scalars['Int'];
  /** The director of the Project */
  director: Scalars['String'];
  /** Display order descending */
  displayOrder?: InputMaybe<Scalars['Int']>;
  /** Highlighted */
  highlighted?: InputMaybe<Scalars['Boolean']>;
  /** Profile id */
  profileId: Scalars['Int'];
  /** Role name */
  role: Scalars['String'];
  /** Credit title */
  title: Scalars['String'];
  /** Year */
  year?: InputMaybe<Scalars['Int']>;
};

/** Input type for Credit Order */
export type CreditOrderInput = {
  /** Credit order */
  creditOrder: Scalars['Int'];
  /** Credit id */
  id: Scalars['Int'];
};

/** CreditType object */
export type CreditType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for Credit */
export type CreditUpdateInput = {
  /** Credit type id */
  creditTypeId?: InputMaybe<Scalars['Int']>;
  /** The director of the Project */
  director?: InputMaybe<Scalars['String']>;
  /** Display Credit Type order descending */
  displayOrder?: InputMaybe<Scalars['Int']>;
  /** Highlighted */
  highlighted?: InputMaybe<Scalars['Boolean']>;
  /** Credit id */
  id: Scalars['Int'];
  /** Role name */
  role?: InputMaybe<Scalars['String']>;
  /** Credit title */
  title?: InputMaybe<Scalars['String']>;
  /** Year */
  year?: InputMaybe<Scalars['Int']>;
};

/** Cup sizes object */
export type CupSize = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Currency object */
export type Currency = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
  symbol: Scalars['String'];
};

export enum CurrencyEnum {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ggp = 'GGP',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Std = 'STD',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
}

export type CustomMediaInput = {
  /** Is Primary Media */
  isPrimary?: Scalars['Boolean'];
  /** Media Id */
  mediaId: Scalars['ID'];
};

export type CustomProfileInput = {
  /** Audio Customisation */
  audios?: InputMaybe<Array<CustomMediaInput>>;
  /** Custom Credits */
  credits?: InputMaybe<Array<Scalars['ID']>>;
  /** Photos Customisation */
  photos?: InputMaybe<Array<CustomMediaInput>>;
  /** Video Customisation */
  videos?: InputMaybe<Array<CustomMediaInput>>;
};

/** Custom URL Slug */
export type CustomSlug = {
  /** The account that created this slug */
  createdBy: Account;
  /** Date when the slug was created and assigned to this user */
  createdDate: Scalars['DateTime'];
  /** The date after which the user may change their slug again */
  creationCooldownDate?: Maybe<Scalars['DateTime']>;
  /** The account that expired this slug */
  expiredBy?: Maybe<Account>;
  /** Date when the slug is or will be expired */
  expiredDate?: Maybe<Scalars['DateTime']>;
  /** Reason why the slug was expired */
  expiredReason?: Maybe<CustomUrlSlugExpiredReason>;
  /** Slug Id */
  id: Scalars['ID'];
  /** The profile that this slug is assigned to */
  profile?: Maybe<Profile>;
  /** Slug Name */
  slugName: Scalars['String'];
};

export enum CustomSlugBlockedReasonEnum {
  Prohibited = 'PROHIBITED',
  Reserved = 'RESERVED',
  Support = 'SUPPORT',
}

export enum CustomSlugExpiredReasonEnum {
  Discarded = 'DISCARDED',
  Downgraded = 'DOWNGRADED',
  Prohibited = 'PROHIBITED',
  Reserved = 'RESERVED',
  Support = 'SUPPORT',
}

/** Custom Slug expired reason */
export type CustomUrlSlugExpiredReason = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type CustomizeTalentInFolderInput = {
  folderId: Scalars['Int'];
  /** Represents that profile is overscale or not */
  isOverscale?: InputMaybe<Scalars['Boolean']>;
  /** profile customized media(s) */
  media?: InputMaybe<Array<PresentationFolderTalentCustomizedMediaInput>>;
  /** Note for submission */
  note?: InputMaybe<Scalars['String']>;
  /** Over scale note */
  overscaleNote?: InputMaybe<Scalars['String']>;
  profileId: Scalars['Int'];
};

/** Input type for DateRangeInput */
export type DateRangeInput = {
  /** The maximum date */
  dateMax?: InputMaybe<Scalars['DateTime']>;
  /** The minimum date */
  dateMin?: InputMaybe<Scalars['DateTime']>;
};

/** Profile Fit For You Values */
export type DefaultFitForYouArtist = {
  /** Is age displayed in years> */
  ageInYears: Scalars['Boolean'];
  ethnicAppearances?: Maybe<Array<EthnicAppearance>>;
  genderAppearances?: Maybe<Array<GenderAppearance>>;
  payPreferences?: Maybe<Array<PaidType>>;
  /** Maximum Playable Age */
  playableAgeMax?: Maybe<Scalars['Int']>;
  /** Mimimumm Playable Age */
  playableAgeMin?: Maybe<Scalars['Int']>;
  projectTypes?: Maybe<Array<ProjectType>>;
  roleTargets?: Maybe<Array<RoleTarget>>;
  talentUnions?: Maybe<Array<TalentUnion>>;
  workingLocations: Array<Dma>;
};

export type DeleteCastingListResult = {
  error?: Maybe<MutateListError>;
  success: Scalars['Boolean'];
};

export type DeleteMediaInput = {
  mediaIds: Array<Scalars['ID']>;
};

export type DeleteMediaResponse = {
  media: Array<Media>;
  success: Scalars['Boolean'];
};

export type DeleteMediaV2Input = {
  mediaIds: Array<Scalars['ID']>;
};

/** DeleteMediaV2Response */
export type DeleteMediaV2Response = {
  success: Scalars['Boolean'];
};

export type DeletePresentationProfilePickPublickInput = {
  pickId: Scalars['String'];
  presentationGuid: Scalars['String'];
  profileId: Scalars['Int'];
  userName: Scalars['String'];
};

export type DeleteProjectSeriesInput = {
  projectSeriesId: Array<Scalars['ID']>;
};

/** Holds the Input for Deleting Submission from a Slot */
export type DeleteSubmissionFromSlotInput = {
  /** Message for Cancellation */
  cancellationMessage?: InputMaybe<Scalars['String']>;
  /** Slot Id */
  slotId: Scalars['ID'];
  /** Submission Id */
  submissionId: Scalars['ID'];
};

export type DeleteSubmissionsFromSlotInput = {
  /** Message for Cancellation */
  cancellationMessage?: InputMaybe<Scalars['String']>;
  slotSubmissions: Array<SlotSubmissionsInput>;
};

/** DeleteTimeframe input type */
export type DeleteTimeframeInput = {
  /** Timeframe cancelation note */
  cancelationNote?: InputMaybe<Scalars['String']>;
  /** Schedule Id */
  scheduleId: Scalars['Float'];
  /** Timeframe Id */
  timeframeId: Scalars['Float'];
};

export enum DeviceTokenType {
  Apn = 'APN',
}

export enum DisplayOnlyTalentWithPicksEnum {
  All = 'All',
  CommentsOnly = 'CommentsOnly',
  PicksOnly = 'PicksOnly',
}

export enum DisplayRoleOptionsEnum {
  BasicRoleInformation = 'BasicRoleInformation',
  FullRoleDetails = 'FullRoleDetails',
}

export enum DisplayTalentInfoOptionsEnum {
  KidsAges = 'KidsAges',
  PicksAndFeedback = 'PicksAndFeedback',
  ProjectNotes = 'ProjectNotes',
  TalentContactInfo = 'TalentContactInfo',
  TalentRepresentationInfo = 'TalentRepresentationInfo',
  UnionStatuses = 'UnionStatuses',
}

export enum DisplayTalentPerPageOptionsEnum {
  OnePerPage = 'OnePerPage',
  TwoPerPage = 'TwoPerPage',
}

export enum DisplayTalentPhotoOptionsEnum {
  AuditionSnapshots = 'AuditionSnapshots',
  Headshots = 'Headshots',
  NoPhoto = 'NoPhoto',
}

/** DMA for Profile */
export type Dma = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** child objects */
  region?: Maybe<Region>;
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum DmaEnum {
  Adelaide = 'ADELAIDE',
  Albuquerque = 'ALBUQUERQUE',
  Anchorage = 'ANCHORAGE',
  Atlanta = 'ATLANTA',
  Auckland = 'AUCKLAND',
  Austin = 'AUSTIN',
  Baltimore = 'BALTIMORE',
  Barcelona = 'BARCELONA',
  Belfast = 'BELFAST',
  Birmingham = 'BIRMINGHAM',
  Bogota = 'BOGOTA',
  Boise = 'BOISE',
  Boston = 'BOSTON',
  Brisbane = 'BRISBANE',
  BuenosAires = 'BUENOS_AIRES',
  Buffalo = 'BUFFALO',
  Calgary = 'CALGARY',
  Canberra = 'CANBERRA',
  CapeTown = 'CAPE_TOWN',
  Caracas = 'CARACAS',
  Cardiff = 'CARDIFF',
  Charleston = 'CHARLESTON',
  Charlotte = 'CHARLOTTE',
  Chicago = 'CHICAGO',
  Christchurch = 'CHRISTCHURCH',
  Cincinnati = 'CINCINNATI',
  Cleveland = 'CLEVELAND',
  Dallas = 'DALLAS',
  Darwin = 'DARWIN',
  Denver = 'DENVER',
  Detroit = 'DETROIT',
  Dublin = 'DUBLIN',
  Edinburgh = 'EDINBURGH',
  Glasgow = 'GLASGOW',
  Guadalajara = 'GUADALAJARA',
  Halifax = 'HALIFAX',
  Hobart = 'HOBART',
  Honolulu = 'HONOLULU',
  Houston = 'HOUSTON',
  Indianapolis = 'INDIANAPOLIS',
  Jacksonville = 'JACKSONVILLE',
  Johannesburg = 'JOHANNESBURG',
  KansasCityMo = 'KANSAS_CITY_MO',
  LasVegas = 'LAS_VEGAS',
  Leeds = 'LEEDS',
  Lima = 'LIMA',
  Liverpool = 'LIVERPOOL',
  London = 'LONDON',
  LosAngeles = 'LOS_ANGELES',
  Louisville = 'LOUISVILLE',
  Madrid = 'MADRID',
  Manchester = 'MANCHESTER',
  Melbourne = 'MELBOURNE',
  Memphis = 'MEMPHIS',
  MexicoCity = 'MEXICO_CITY',
  Miami = 'MIAMI',
  Milwaukee = 'MILWAUKEE',
  Minneapolis = 'MINNEAPOLIS',
  Monterrey = 'MONTERREY',
  Montreal = 'MONTREAL',
  Nashville = 'NASHVILLE',
  NewOrleans = 'NEW_ORLEANS',
  NewYorkCity = 'NEW_YORK_CITY',
  OklahomaCity = 'OKLAHOMA_CITY',
  Orlando = 'ORLANDO',
  Ottawa = 'OTTAWA',
  Paris = 'PARIS',
  Perth = 'PERTH',
  Philadelphia = 'PHILADELPHIA',
  Phoenix = 'PHOENIX',
  Pittsburgh = 'PITTSBURGH',
  Portland = 'PORTLAND',
  Providence = 'PROVIDENCE',
  QuebecCity = 'QUEBEC_CITY',
  Regina = 'REGINA',
  Reno = 'RENO',
  Richmond = 'RICHMOND',
  RioDeJaneiro = 'RIO_DE_JANEIRO',
  Sacramento = 'SACRAMENTO',
  SaltLakeCity = 'SALT_LAKE_CITY',
  Santiago = 'SANTIAGO',
  SanAntonio = 'SAN_ANTONIO',
  SanDiego = 'SAN_DIEGO',
  SanFrancisco = 'SAN_FRANCISCO',
  SanJuan = 'SAN_JUAN',
  SaoPaulo = 'SAO_PAULO',
  Seattle = 'SEATTLE',
  Spokane = 'SPOKANE',
  StLouis = 'ST_LOUIS',
  Sydney = 'SYDNEY',
  Tampa = 'TAMPA',
  Toronto = 'TORONTO',
  Vancouver = 'VANCOUVER',
  WashingtonDc = 'WASHINGTON_DC',
  Wellington = 'WELLINGTON',
  Winnipeg = 'WINNIPEG',
}

export type DmaItem = {
  /** code */
  code: Scalars['String'];
  /** Is this enbaled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  region?: Maybe<RegionItem>;
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** General size object */
export type Document = {
  /** child objects */
  childObjects: Array<Document>;
  /** code */
  code: Scalars['String'];
  /** country */
  country?: Maybe<Country>;
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Document>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type EditCastingListResult = {
  error?: Maybe<EditListError>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EditListError = MissingDataError | NotFoundError | ValidationError;

export type EditProfileCastingListResult = {
  error?: Maybe<EditProfileError>;
  success: Scalars['Boolean'];
};

export type EditProfileError = MissingDataError | NotFoundError | ValidationError;

export type EditProfileInput = {
  /** List Profile Id */
  listProfileId: Scalars['ID'];
  /** Profile Customisations to Save */
  profilesCustomisation?: InputMaybe<CustomProfileInput>;
};

export type EditProjectSeriesInput = {
  projectSeriesId: Scalars['ID'];
  projectSeriesName: Scalars['String'];
};

export type EmailHistory = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailType: EmailHistoryTypeEnum;
  messageToReps?: Maybe<Scalars['String']>;
  messageToTalents?: Maybe<Scalars['String']>;
  publicationSettings: Array<PublicationSetting>;
  roles: Array<Role>;
};

/** Paginated result with [EmailHistory] objects */
export type EmailHistoryPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of EmailHistory objects */
  objects: Array<EmailHistory>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Email History type */
export enum EmailHistoryTypeEnum {
  PublicationAdditionalSubmissions = 'Publication_AdditionalSubmissions',
  PublicationApproved = 'Publication_Approved',
  PublicationPublished = 'Publication_Published',
  PublicationRepsEmailed = 'Publication_RepsEmailed',
  PublicationWidened = 'Publication_Widened',
}

/** Creates a end user account based on given input values */
export type EndUserAccountInput = {
  companyAdmin: Scalars['Boolean'];
  countryId: Scalars['Int'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationIds: Array<Scalars['Float']>;
  phone: Scalars['String'];
  roleId: Scalars['Float'];
  timeZoneId: Scalars['Float'];
};

/** Says whether enrollment as successful */
export type EnrollBenefits = {
  success: Scalars['Boolean'];
};

export type EnrollBenefitsInput = {
  acceptedTerms?: InputMaybe<Scalars['Boolean']>;
  address: Array<Scalars['String']>;
  city: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  state: Scalars['String'];
  work?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

/** Input type for entity type subscription */
export type EntitySubscriptionInput = {
  /** Entity Type CODE */
  entityTypeCode: Scalars['String'];
  /** Entity value CODE. e.g if entityTypeCode is ROLE_TARGET then entityValueCode must be PRINCIPAL Or BACKGROUND */
  entityValueCode: Scalars['String'];
};

/** Entity Type object */
export type EntityType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum EntityTypeEnum {
  Account = 'ACCOUNT',
  Address = 'ADDRESS',
  Artist = 'ARTIST',
  AuditionSession = 'AUDITION_SESSION',
  Calendar = 'CALENDAR',
  CalendarEvent = 'CALENDAR_EVENT',
  CalendarSlot = 'CALENDAR_SLOT',
  Media = 'MEDIA',
  MediaType = 'MEDIA_TYPE',
  NotificationNature = 'NOTIFICATION_NATURE',
  Organization = 'ORGANIZATION',
  PaidType = 'PAID_TYPE',
  Profile = 'PROFILE',
  Project = 'PROJECT',
  ProjectType = 'PROJECT_TYPE',
  Role = 'ROLE',
  RoleTarget = 'ROLE_TARGET',
  Submission = 'SUBMISSION',
  TalentUnion = 'TALENT_UNION',
}

/** EthnicAppearance object */
export type EthnicAppearance = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum EthnicAppearanceEnum {
  AboriginalTorresStraitIslander = 'ABORIGINAL_TORRES_STRAIT_ISLANDER',
  AfricanAmericanBlack = 'AFRICAN_AMERICAN_BLACK',
  AmericanIndian = 'AMERICAN_INDIAN',
  CaucasianWhite = 'CAUCASIAN_WHITE',
  EasternEuropean = 'EASTERN_EUROPEAN',
  EastAsian = 'EAST_ASIAN',
  EastIndianSouthAsian = 'EAST_INDIAN_SOUTH_ASIAN',
  EthnicallyAmbiguous = 'ETHNICALLY_AMBIGUOUS',
  Latinx = 'LATINX',
  Maori = 'MAORI',
  Mediterranean = 'MEDITERRANEAN',
  MiddleEastern = 'MIDDLE_EASTERN',
  PacificIslander = 'PACIFIC_ISLANDER',
  SoutheastAsian = 'SOUTHEAST_ASIAN',
}

export type ExpireCompedSubscriptionInput = {
  /** Subscription id */
  subscriptionId: Scalars['Int'];
};

export enum ExternalAuthenticationProvider {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Protunes = 'PROTUNES',
  Twitter = 'TWITTER',
}

/** Eye color object */
export type EyeColor = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** FFY Context input */
export type FfyContextInput = {
  artistIds: Array<Scalars['Int']>;
  roleIds: Array<Scalars['Int']>;
};

export type Facet = {
  buckets: Array<FacetBucket>;
  name: Scalars['String'];
};

export type FacetBucket = {
  docCount: Scalars['Int'];
  facets?: Maybe<Array<Facet>>;
  key: Scalars['ID'];
};

/** Facial Hair object */
export type FacialHair = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type Feature = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum FeatureEnum {
  AccessListAtOrg = 'ACCESS_LIST_AT_ORG',
  AllowCastingToHideRoleRates = 'ALLOW_CASTING_TO_HIDE_ROLE_RATES',
  AudioVideoUpload = 'AUDIO_VIDEO_UPLOAD',
  AutomaticallyShareCreatedProfilesWithTalent = 'AUTOMATICALLY_SHARE_CREATED_PROFILES_WITH_TALENT',
  CanAccessTalentScout = 'CAN_ACCESS_TALENT_SCOUT',
  CastingBillboardSubmission = 'CASTING_BILLBOARD_SUBMISSION',
  Createprofile = 'CREATEPROFILE',
  Createproject = 'CREATEPROJECT',
  GateMediaRequest = 'GATE_MEDIA_REQUEST',
  Getprofile = 'GETPROFILE',
  Getproject = 'GETPROJECT',
  LockAgeRangeForTalent = 'LOCK_AGE_RANGE_FOR_TALENT',
  PhotoUpload = 'PHOTO_UPLOAD',
  RoleTrackerOptOut = 'ROLE_TRACKER_OPT_OUT',
  SetDefaultsToBackgroundForExtrasCasting = 'SET_DEFAULTS_TO_BACKGROUND_FOR_EXTRAS_CASTING',
  SystemAccess = 'SYSTEM_ACCESS',
  Updateprofile = 'UPDATEPROFILE',
  Updateproject = 'UPDATEPROJECT',
}

export type FeatureInput = {
  enabled: Scalars['Boolean'];
  featureId: Scalars['Float'];
};

export type FilterInput = {
  keyType: KeyType;
  keys: Array<Scalars['ID']>;
};

export enum FilterOperator {
  And = 'AND',
  Or = 'OR',
}

export type FinalizeMediaUploadInput = {
  /** The media items that are to be uploaded. */
  media: Array<FinalizeUploadMediaInput>;
};

export type FinalizeMediaUploadResponse = {
  media?: Maybe<Array<Media>>;
  storageUtilization?: Maybe<ArtistStorageUtilization>;
};

export type FinalizeUploadMediaInput = {
  /** The duration of the new file in seconds. This is required for audio and video uploads. */
  fileDuration?: InputMaybe<Scalars['Int']>;
  /** The height of the new file in pixels. This is required for image and video uploads. */
  fileHeight?: InputMaybe<Scalars['Int']>;
  /** The width of the new file in pixels. */
  fileWidth?: InputMaybe<Scalars['Int']>;
  /** A unique identifier for the new file. */
  guid: Scalars['String'];
  transformation?: InputMaybe<MediaTransformationInput>;
};

export type FormAssociatedProject = {
  projectId: Scalars['Int'];
  projectName: Scalars['String'];
};

/** Form Bulk Response Form Response */
export type FormBulkResponseFormResponse = {
  formVersionId?: Maybe<Scalars['Int']>;
  responseId: Scalars['String'];
  responseSchema: Scalars['JSON'];
};

/** Form Bulk Response Profile Details */
export type FormBulkResponseProfileDetails = {
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  stageName: Scalars['String'];
  submissionPhotoUrl?: Maybe<Scalars['String']>;
};

/** Form Bulk Response Rep Details */
export type FormBulkResponseRepDetails = {
  company?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type FormDetails = {
  description?: Maybe<Scalars['String']>;
  formId: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
};

export type FormGqlModel = {
  associatedProjects?: Maybe<Array<FormAssociatedProject>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Account>;
  description?: Maybe<Scalars['String']>;
  formId: Scalars['Int'];
  /** The schema of the form */
  formSchema?: Maybe<Scalars['JSON']>;
  orgId?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormGqlModelAssociatedProjectsArgs = {
  pageProjects?: InputMaybe<PageInput>;
};

export type FormGqlModelPagedList = {
  data: Array<FormGqlModel>;
  page: Scalars['Float'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Form details */
export type FormInfo = {
  /** ID of form */
  formId: Scalars['Float'];
  /** Title of form */
  title?: Maybe<Scalars['String']>;
};

export type FormResponseGqlModel = {
  description?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['Int']>;
  formResponseId?: Maybe<Scalars['BigInt']>;
  /** The schema of the form response */
  formResponseSchema?: Maybe<Scalars['JSON']>;
  /** The schema of the form */
  formSchema?: Maybe<Scalars['JSON']>;
  profile?: Maybe<ProfileGqlModel>;
  title?: Maybe<Scalars['String']>;
};

/** value used to sort forms by name, created at, updated at, or creator name */
export enum FormSortBy {
  CreatedAt = 'CreatedAt',
  CreatorName = 'CreatorName',
  FormTitle = 'FormTitle',
  UpdatedAt = 'UpdatedAt',
}

/** Sort condition for forms */
export type FormSortConditionInput = {
  /** Sort by */
  sortBy?: InputMaybe<FormSortBy>;
  /** Sort by */
  sortOrder?: InputMaybe<FormSortOrder>;
};

/** value used to sort forms in ascending or descending order */
export enum FormSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type FormsFilterArgs = {
  formName?: InputMaybe<Scalars['String']>;
};

/** Forms Report */
export type FormsReportModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  formId: Scalars['Int'];
  id: Scalars['ID'];
  mediaRequestId: Scalars['String'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<FormsReportPayload>;
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Forms Report Payload */
export type FormsReportPayload = {
  data: Array<RpcFormBulkResponseItem>;
  formSchema: Scalars['JSON'];
  mediaRequestName: Scalars['String'];
  projectName: Scalars['String'];
};

export type FreeCreditItemModel = {
  artistId: Scalars['Float'];
  created: Scalars['DateTime'];
  createdBy: Scalars['Float'];
  credits: Scalars['Float'];
  itemCode: BillingItemCode;
  itemId: Scalars['Float'];
  updated?: Maybe<Scalars['DateTime']>;
};

export type GqlFormBulkResponseFormResponse = {
  formVersionId?: Maybe<Scalars['Int']>;
  responseId: Scalars['String'];
  /** The schema of the form */
  responseSchema?: Maybe<Scalars['JSON']>;
};

export type GqlFormBulkResponseProfileDetails = {
  email: Scalars['String'];
  id: Scalars['Int'];
  phone: Scalars['String'];
  stageName: Scalars['String'];
  submissionPhotoUrl: Scalars['String'];
};

export type GqlFormBulkResponseRepDetails = {
  company: Scalars['String'];
  division: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type GqlFormBulkResponseRoleDetails = {
  id: Scalars['Int'];
  internalName: Scalars['String'];
  name: Scalars['String'];
};

export type GqlRpcFormBulkResponse = {
  data: Array<GqlRpcFormBulkResponseItem>;
  /** The schema of the form */
  formSchema?: Maybe<Scalars['JSON']>;
  mediaRequestName: Scalars['String'];
  projectName: Scalars['String'];
};

export type GqlRpcFormBulkResponseItem = {
  formResponseDetails: GqlFormBulkResponseFormResponse;
  profileDetails: GqlFormBulkResponseProfileDetails;
  repDetails?: Maybe<GqlFormBulkResponseRepDetails>;
  roleDetails?: Maybe<GqlFormBulkResponseRoleDetails>;
};

/** Gender Appearance object */
export type GenderAppearance = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum GenderAppearanceEnum {
  Man = 'MAN',
  NonBinaryPerson = 'NON_BINARY_PERSON',
  TransMan = 'TRANS_MAN',
  TransWoman = 'TRANS_WOMAN',
  Woman = 'WOMAN',
}

/** Gender Identitie object */
export type GenderIdentity = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum GenderIdentityEnum {
  NonBinary = 'NON_BINARY',
  Other = 'OTHER',
  Trans = 'TRANS',
  Undefined = 'UNDEFINED',
}

/** Input type for Gender picker */
export type GenderInput = {
  /**
   * Gender Appearance Ids. NOTE: Ok for now. TODO: Should simply be integer, not
   * array. Function update_profile_gender_appearance has _gender_appearance_id integer[]
   */
  genderAppearanceIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Gender Identity Ids */
  genderIdentityIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Gender Other Identity Note */
  genderOtherIdentityNote?: InputMaybe<Scalars['String']>;
};

/** General size object */
export type GeneralSize = {
  category: BasicSetting;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Size gender ID */
  sizeGenderId: Scalars['ID'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type GetBillingInfoInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type GetCastingListProfileArgs = {
  listId: Scalars['ID'];
  pageNumber?: Scalars['Int'];
  pageSize?: Scalars['Int'];
};

export type GetCastingListProfileResult = {
  error?: Maybe<GetCastingListProfileResultError>;
  success?: Maybe<CastingListProfilePagedList>;
};

export type GetCastingListProfileResultError = ValidationError;

export type GetChatMessagesByTopicModel = {
  lastViewedMessageId?: Maybe<Scalars['String']>;
  messages: Array<ChatMessageModel>;
  participantOrganizations?: Maybe<Array<Organization>>;
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** Collaborator Email */
export type GetCollaboratorByEmailInput = {
  email: Scalars['String'];
};

/** Creates an invite for a user to Collaborate on a project */
export type GetCollaboratorInviteInput = {
  inviteId: Scalars['ID'];
};

/** Get or create an attendee for the Virtual Audition Session associated to a Schedule */
export type GetOrCreateVirtualAuditionAttendeeInput = {
  /** Schedule Id */
  scheduleId: Scalars['Int'];
};

/** Get Organization Representative Lists Input */
export type GetOrganizationRepresentativeListsInput = {
  /** Reps list sort order */
  sortOrder?: InputMaybe<ListSortOrderEnum>;
};

export type GetProjectSeriesByIdInput = {
  projectSeriesId: Array<Scalars['ID']>;
};

export type GetProjectSeriesByNameInput = {
  projectSeriesName?: InputMaybe<Scalars['String']>;
};

/** Input type for fetching submissions by role and profile */
export type GetSubmissionByRoleProfileInput = {
  /** Profile ID */
  profileId: Scalars['Int'];
  /** Role ID */
  roleId: Scalars['Int'];
};

export type Glove = {
  /** Imperial Feet Inches */
  IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  cm: Scalars['Int'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
  /** Inches rounded */
  inchRounded: Scalars['String'];
  /** Show in imperial UI */
  showInImperialUI: Scalars['Boolean'];
};

export type GroupLastKey = {
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type GroupLastKeyInput = {
  id: Scalars['Float'];
  name: Scalars['String'];
};

/** HairColor object */
export type HairColor = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** HairStyle object */
export type HairStyle = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Is this artist actively enrolled in this benefit? */
export type HasActiveEnrolledBenefit = {
  artistId: Scalars['Float'];
  hasActiveEnrolledBenefits: Scalars['Boolean'];
};

export type HasActiveEnrolledBenefitInput = {
  artistIds: Array<Scalars['Int']>;
  benefitsProvider: Scalars['String'];
};

/** General hat size object */
export type HatSize = {
  /** cm */
  cm: Scalars['Float'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** inch */
  inch: Scalars['String'];
  /** size */
  size: Scalars['String'];
};

export type Height = {
  /** Imperial Feet Inches */
  IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  cm: Scalars['Int'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
  /** Inches rounded */
  inchRounded: Scalars['String'];
  /** Show in imperial UI */
  showInImperialUI: Scalars['Boolean'];
};

export type Hip = {
  /** Imperial Feet Inches */
  IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  cm: Scalars['Int'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
  /** Inches rounded */
  inchRounded: Scalars['String'];
  /** Show in imperial UI */
  showInImperialUI: Scalars['Boolean'];
};

export type ICreateMediaResponse = {
  media: Array<Media>;
};

export type IError = {
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type IpcInfo = {
  ipcProfessionalBio?: Maybe<Scalars['String']>;
};

/** ID range input */
export type IdRangeInput = {
  /** The minimum ID value */
  from?: InputMaybe<Scalars['Int']>;
  /** The maximum ID value */
  to?: InputMaybe<Scalars['Int']>;
};

export type InitiateMediaBulkDownloadInput = {
  /** Media ids to be downloaded */
  ids: Array<Scalars['ID']>;
};

export type InitiateMediaBulkDownloadResponse = {
  /** Whether the operation was successful */
  success: Scalars['Boolean'];
};

export type InitiateMediaUploadInput = {
  /** The ID of the artist associated with the upload. This field is required for certain users. */
  artistId?: InputMaybe<Scalars['ID']>;
  /** Indicates whether the new media should be created as a draft, which will result in automatic archiving. */
  draft?: InputMaybe<Scalars['Boolean']>;
  /** The media items that are to be uploaded. */
  media: Array<InitiateUploadMediaInput>;
};

export type InitiateMediaUploadResponse = {
  media?: Maybe<Array<Media>>;
  storageUtilization?: Maybe<ArtistStorageUtilization>;
};

export type InitiateUploadMediaInput = {
  /** The duration of the new file in seconds. This is required for audio and video uploads. */
  fileDuration?: InputMaybe<Scalars['Int']>;
  /** The height of the new file in pixels. This is required for image and video uploads. */
  fileHeight?: InputMaybe<Scalars['Int']>;
  /** The name of the new file. */
  fileName: Scalars['String'];
  /** The size of the new file in bytes. */
  fileSize: Scalars['Int'];
  /** The extension of the new file. */
  fileType: MediaFileTypeExtension;
  /** The width of the new file in pixels. */
  fileWidth?: InputMaybe<Scalars['Int']>;
};

export type Inseam = {
  /** Imperial Feet Inches */
  IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  cm: Scalars['Int'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
  /** Inches rounded */
  inchRounded: Scalars['String'];
  /** Show in imperial UI */
  showInImperialUI: Scalars['Boolean'];
};

/** Creates a internal user account based on given input values */
export type InternalAccountCreateInput = {
  email: Scalars['String'];
  env?: InputMaybe<Scalars['String']>;
  roleId: Array<Scalars['ID']>;
};

/** Context object for internal welcome */
export type InternalWelcomeContext = {
  env: Scalars['String'];
};

export type InternalWelcomeContextNotificationContextAndRecipient = {
  context: InternalWelcomeContext;
  to?: Maybe<Scalars['String']>;
  toAccountId?: Maybe<Scalars['Int']>;
  toOrgId?: Maybe<Scalars['Int']>;
  toProfileId?: Maybe<Scalars['Int']>;
};

/** Date interval */
export enum IntervalEnum {
  Month = 'Month',
  Quarter = 'Quarter',
  Week = 'Week',
}

export enum ItemEnum {
  AudioUpload = 'AUDIO_UPLOAD',
  Benefits = 'BENEFITS',
  BenefitsPlus = 'BENEFITS_PLUS',
  CustomUrlAccess = 'CUSTOM_URL_ACCESS',
  MobileReelsAccess = 'MOBILE_REELS_ACCESS',
  PhotoUpload = 'PHOTO_UPLOAD',
  RoleTrackerAccess = 'ROLE_TRACKER_ACCESS',
  StorageLimitMb = 'STORAGE_LIMIT_MB',
  Submission = 'SUBMISSION',
  SubmissionTrends = 'SUBMISSION_TRENDS',
  SystemAccess = 'SYSTEM_ACCESS',
  TalentScoutAccess = 'TALENT_SCOUT_ACCESS',
  VideoUpload = 'VIDEO_UPLOAD',
  YourAuditions = 'YOUR_AUDITIONS',
}

/** Type of the identifier to use for dictionary lookup. */
export enum KeyType {
  Code = 'code',
  Id = 'id',
}

export enum LanguageEnum {
  English = 'ENGLISH',
  Spanish = 'SPANISH',
}

export type LanguageInfo = {
  baseLocale: Scalars['String'];
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['Int'];
  locale: Scalars['String'];
  name: Scalars['String'];
};

/** Legacy Account By Profile */
export type LegacyAccount = {
  /** Account Id */
  accountId: Scalars['Float'];
  /** Legacy login */
  legacyLogin: Scalars['String'];
};

export type ListGqlModel = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  project: Project;
  /** Project ID */
  projectId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ListGqlModelPagedList = {
  data: Array<ListGqlModel>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Sort order for list of lists */
export enum ListSortOrderEnum {
  Alphabetical = 'ALPHABETICAL',
  LastModified = 'LAST_MODIFIED',
}

/** Roster type object */
export type ListType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Luminate company */
export type LuminateCompanyModel = {
  contacts?: Maybe<Array<LuminateContactModel>>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Luminate contact */
export type LuminateContactModel = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Luminate profile Data */
export type LuminateProfile = {
  /** Luminate profile agencies */
  agencies?: Maybe<Array<LuminateCompanyModel>>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  /** Luminate profile credits */
  credits?: Maybe<Array<Credit>>;
  dateOfBirth?: Maybe<Scalars['String']>;
  /** Luminate profile is deceased */
  isDeceased?: Maybe<Scalars['Boolean']>;
  /** Luminate profile management companies */
  managements?: Maybe<Array<LuminateCompanyModel>>;
  pronounce?: Maybe<Scalars['String']>;
  screenScore?: Maybe<Scalars['String']>;
};

export type Media = {
  accountId: Scalars['Float'];
  appearOnMediaRequests: Scalars['Boolean'];
  appearOnSubmissions: Scalars['Boolean'];
  appearancesOnProfiles: Scalars['Int'];
  archivableOn?: Maybe<Scalars['DateTime']>;
  created?: Maybe<Scalars['DateTime']>;
  /** Returns the Organization of the media create, if one exists */
  createdByOrg?: Maybe<Organization>;
  /** Formatted size with units */
  displaySize?: Maybe<MediaDisplaySize>;
  fileKey?: Maybe<Scalars['String']>;
  fileType: MediaFileType;
  filename?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  /** Primary ID */
  id: Scalars['ID'];
  isLocked?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['Float']>;
  mediaId: Scalars['Int'];
  mediaParentId?: Maybe<Scalars['Float']>;
  mediaStatus: MediaStatus;
  mediaStorageStatus: MediaStorageStatus;
  mediaType?: Maybe<MediaType>;
  moderationStatus: MediaModerationStatus;
  name?: Maybe<Scalars['String']>;
  profiles?: Maybe<Array<ProfileMedia>>;
  purchaseStatus?: Maybe<MediaPurchaseStatus>;
  purpose: MediaPurpose;
  /** @deprecated use url field instead */
  resizedPaths: Array<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Media>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  transformation?: Maybe<MediaTransformation>;
  transformations: Array<Media>;
  updated?: Maybe<Scalars['DateTime']>;
  /** @deprecated use url field instead */
  uploadPath?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaByType = {
  AUDIO: Array<Scalars['Int']>;
  DOCUMENT: Array<Scalars['Int']>;
  PHOTO: Array<Scalars['Int']>;
  VIDEO: Array<Scalars['Int']>;
};

/** Media files to change */
export type MediaChangeInput = {
  /** Media sub request ID to change */
  id: Scalars['ID'];
  /** List of the media IDs to change */
  media: Array<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
};

/** Media Count */
export type MediaCount = {
  /** Count of the media */
  count: Scalars['Int'];
  /** Media Type */
  type: MediaType;
};

/** MediaDisplaySize */
export type MediaDisplaySize = {
  size: Scalars['Float'];
  unit: Scalars['String'];
};

/** Media File Types */
export type MediaFileType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** MediaType */
  mediaType: MediaType;
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Describes the file type extension for media files. */
export enum MediaFileTypeExtension {
  Aac = 'AAC',
  Aiff = 'AIFF',
  Amr = 'AMR',
  Avi = 'AVI',
  Doc = 'DOC',
  Docx = 'DOCX',
  Flac = 'FLAC',
  Flv = 'FLV',
  Fmp4 = 'FMP4',
  Gif = 'GIF',
  Heic = 'HEIC',
  Jfif = 'JFIF',
  Jpe = 'JPE',
  Jpeg = 'JPEG',
  Jpg = 'JPG',
  M4A = 'M4A',
  M4V = 'M4V',
  Mkv = 'MKV',
  Mov = 'MOV',
  Mp3 = 'MP3',
  Mp4 = 'MP4',
  Mpeg = 'MPEG',
  Mpg = 'MPG',
  Mxf = 'MXF',
  Odt = 'ODT',
  Oga = 'OGA',
  Ogg = 'OGG',
  Ogv = 'OGV',
  Pdf = 'PDF',
  Png = 'PNG',
  Rtf = 'RTF',
  Tif = 'TIF',
  Ts = 'TS',
  Wav = 'WAV',
  Webm = 'WEBM',
  Wma = 'WMA',
  Wmv = 'WMV',
}

export type MediaInitUploadInput = {
  count: Scalars['Int'];
  /** Entity CODE to which media should be assigned */
  entity?: InputMaybe<Scalars['String']>;
  /** Entity Id to which media should be assigned */
  entityId?: InputMaybe<Scalars['Int']>;
};

/** Media Input Object */
export type MediaInput = {
  /** Media ID */
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Media Invoice Model */
export type MediaInvoice = {
  coupon?: Maybe<Coupon>;
  creditsApplied: Scalars['Float'];
  currency: Currency;
  discount: Scalars['Float'];
  email: Scalars['String'];
  invoiceId?: Maybe<Scalars['String']>;
  items: Array<MediaPurchase>;
  paymentDetails?: Maybe<PaymentDetails>;
  total: Scalars['Float'];
};

export type MediaItem = {
  /** Media ID */
  mediaId: Scalars['Int'];
  /** name */
  name?: Maybe<Scalars['String']>;
  /** Upload Path */
  uploadPath?: Maybe<Scalars['String']>;
};

/** MediaMediaType */
export type MediaMediaType = {
  mediaId: Scalars['Int'];
  mediaType?: Maybe<MediaType>;
  mediaTypeId: Scalars['Int'];
};

export type MediaMetadataInput = {
  filename: Scalars['String'];
  /** The extension of uploaded file */
  format: Scalars['String'];
  guid: Scalars['ID'];
  height?: InputMaybe<Scalars['Int']>;
  length?: InputMaybe<Scalars['Int']>;
  size: Scalars['Int'];
  transformation?: InputMaybe<MediaTransformationInput>;
  width?: InputMaybe<Scalars['Int']>;
};

/** MediaModerationStatus object */
export type MediaModerationStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum MediaModerationStatusEnum {
  Pending = 'PENDING',
  Ready = 'READY',
  Required = 'REQUIRED',
}

/** Media Purchase Model */
export type MediaPurchase = {
  currency: Currency;
  media: Media;
  price: Scalars['Float'];
};

export enum MediaPurchaseStatus {
  FreeCredit = 'FREE_CREDIT',
  FreeCreditReady = 'FREE_CREDIT_READY',
  NotPurchased = 'NOT_PURCHASED',
  Purchased = 'PURCHASED',
  Unlimited = 'UNLIMITED',
}

/** MediaPurpose object */
export type MediaPurpose = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** 'Media Request' Type */
export type MediaRequest = {
  allowAdditionalMedia?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  draftSubmissions?: Maybe<WfSubmissionPagedList>;
  dueDate?: Maybe<Scalars['DateTime']>;
  dueDateTimeZone?: Maybe<WfTimeZone>;
  formDetails?: Maybe<Array<FormDetails>>;
  id: Scalars['ID'];
  includeSides: Scalars['Boolean'];
  isDraft?: Maybe<Scalars['Boolean']>;
  maxNumberOfMediaFiles: Scalars['Int'];
  mediaTypes: Array<RequestMediaType>;
  messageForRepresentatives?: Maybe<Scalars['String']>;
  messageForRepresentativesUpdatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  projectId: Scalars['Int'];
  responses: MediaResponsePagedList;
  shouldResend: Scalars['Boolean'];
  submissionIds: Array<Scalars['Int']>;
  talentInstructions?: Maybe<Scalars['String']>;
  talentInstructionsUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** 'Media Request' Type */
export type MediaRequestDraftSubmissionsArgs = {
  page?: InputMaybe<PageInput>;
};

/** 'Media Request' Type */
export type MediaRequestResponsesArgs = {
  page: PageInput;
  statuses?: InputMaybe<Array<Scalars['String']>>;
};

export enum MediaRequestFilterType {
  WithoutMediaRequest = 'WITHOUT_MEDIA_REQUEST',
  WithMediaRequest = 'WITH_MEDIA_REQUEST',
}

export type MediaRequestInput = {
  allowAdditionalMedia?: InputMaybe<Scalars['Boolean']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  dueDateTimeZoneId?: InputMaybe<Scalars['Int']>;
  excludeSubmissionIds?: InputMaybe<Array<Scalars['Int']>>;
  formIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['String']>;
  includeSides: Scalars['Boolean'];
  isDraft: Scalars['Boolean'];
  maxNumberOfMediaFiles: Scalars['Int'];
  mediaTypes: Array<RequestMediaType>;
  messageForRepresentatives?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['Int'];
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  submissionIds: Array<Scalars['Int']>;
  talentInstructions?: InputMaybe<Scalars['String']>;
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
};

export enum MediaRequestStatus {
  AdditionalMediaAdded = 'ADDITIONAL_MEDIA_ADDED',
  Cancelled = 'CANCELLED',
  ChangeRequested = 'CHANGE_REQUESTED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  MediaAdded = 'MEDIA_ADDED',
  NotSent = 'NOT_SENT',
  Pending = 'PENDING',
}

export type MediaResponse = {
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chatTopic?: Maybe<WfChatTopic>;
  chatTopicKey: Scalars['String'];
  formDetails?: Maybe<Array<MediaResponseFormDetails>>;
  id: Scalars['ID'];
  mediaList?: Maybe<Array<MediaResponseItem>>;
  /** @deprecated please use mediaList */
  medias?: Maybe<Array<WfMedia>>;
  note?: Maybe<Scalars['String']>;
  repliedAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  status: MediaRequestStatus;
  submission: WfSubmission;
  /** @deprecated Replaced with field 'submission'. */
  submissionId: Scalars['Float'];
};

export type MediaResponseCounts = {
  count: Scalars['Float'];
  status: MediaRequestStatus;
};

export type MediaResponseFormDetails = {
  formId: Scalars['Int'];
  formResponseId?: Maybe<Scalars['Int']>;
  formTitle: Scalars['String'];
  requestId?: Maybe<Scalars['String']>;
};

export type MediaResponseInput = {
  audios?: InputMaybe<Array<Scalars['Int']>>;
  documents?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['ID'];
  mediaAlias?: InputMaybe<Array<MediaInput>>;
  note?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<Array<Scalars['Int']>>;
  response?: InputMaybe<MediaResponseStatus>;
  videos?: InputMaybe<Array<Scalars['Int']>>;
};

export type MediaResponseItem = {
  alias?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAdditional?: Maybe<Scalars['Boolean']>;
  isForwardedToCD?: Maybe<Scalars['Boolean']>;
  media: WfMedia;
  timestamp: Scalars['DateTime'];
  type: Scalars['String'];
};

export type MediaResponsePagedList = {
  data: Array<MediaResponse>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum MediaResponseStatus {
  AdditionalMediaAdded = 'ADDITIONAL_MEDIA_ADDED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  MediaAdded = 'MEDIA_ADDED',
}

/** MediaStatus object */
export type MediaStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type MediaStorage = {
  /** Type of Media (i.e. Audio) */
  mediaType: MediaType;
  /** Total size of media type used in bytes */
  storageSizeBytes: Scalars['BigInt'];
};

export enum MediaStorageEnum {
  Archived = 'ARCHIVED',
  MigrationCleaned = 'MIGRATION_CLEANED',
  MigrationData = 'MIGRATION_DATA',
  MigrationFileMissing = 'MIGRATION_FILE_MISSING',
  MigrationMoved = 'MIGRATION_MOVED',
  MigrationProcessed = 'MIGRATION_PROCESSED',
  MigrationReady = 'MIGRATION_READY',
  Ready = 'READY',
}

/** Media Storage Status object */
export type MediaStorageStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum MediaStorageStatusEnum {
  BauMigrated = 'BAU_MIGRATED',
  CldCleaned = 'CLD_CLEANED',
  Downloaded = 'DOWNLOADED',
  Downloading = 'DOWNLOADING',
  DownloadFailed = 'DOWNLOAD_FAILED',
  Uploaded = 'UPLOADED',
  Uploading = 'UPLOADING',
  UploadFailed = 'UPLOAD_FAILED',
}

/** 'Media Sub-Request' Type for (Reps|Talent) */
export type MediaSubRequest = {
  allowAdditionalMedia?: Maybe<Scalars['Boolean']>;
  /** Cancellation note */
  cancellationNote?: Maybe<Scalars['String']>;
  /** The date when request was cancelled */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chatTopic?: Maybe<WfChatTopic>;
  chatTopicKey: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  dueDateTimeZone?: Maybe<WfTimeZone>;
  formDetails?: Maybe<Array<MediaResponseFormDetails>>;
  /** Forwarded Note */
  forwardNote?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  includeSides: Scalars['Boolean'];
  isGated: Scalars['Boolean'];
  maxNumberOfMediaFiles: Scalars['Int'];
  mediaList?: Maybe<Array<MediaResponseItem>>;
  mediaTypes: Array<RequestMediaType>;
  /**
   * The media files sent in response
   * @deprecated please use mediaList
   */
  medias?: Maybe<Array<WfMedia>>;
  messageForRepresentatives?: Maybe<Scalars['String']>;
  messageForRepresentativesUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The note sent in response */
  note?: Maybe<Scalars['String']>;
  profile?: Maybe<WfProfile>;
  project: WfProject;
  /** The date when response was sent */
  repliedAt?: Maybe<Scalars['DateTime']>;
  /** Reps Worksheet Status */
  repsWorksheetStatus: Scalars['String'];
  requestedChangesMedia?: Maybe<Array<Scalars['Int']>>;
  requestedChangesNote?: Maybe<Scalars['String']>;
  role: WfRole;
  /** The date when request was sent */
  sentAt?: Maybe<Scalars['DateTime']>;
  status: MediaRequestStatus;
  submission: WfSubmission;
  /** @deprecated Replaced with field 'submission'. */
  submissionId: Scalars['Int'];
  talentInstructions: Scalars['String'];
  talentInstructionsUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedMedia?: Maybe<Array<Scalars['Int']>>;
};

export type MediaToArchive = {
  filePath: Scalars['String'];
  format: Scalars['String'];
  guid: Scalars['String'];
  storageStatus: MediaStorageStatusEnum;
  type: MediaTypeEnum;
};

export type MediaTransformation = {
  height?: Maybe<Scalars['Float']>;
  rotate?: Maybe<Scalars['Float']>;
  startOffset?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  xAxis?: Maybe<Scalars['Float']>;
  yAxis?: Maybe<Scalars['Float']>;
};

export type MediaTransformationInput = {
  height?: InputMaybe<Scalars['Float']>;
  rotate?: InputMaybe<Scalars['Float']>;
  startOffset?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Float']>;
  xAxis?: InputMaybe<Scalars['Float']>;
  yAxis?: InputMaybe<Scalars['Float']>;
};

/** MediaType object */
export type MediaType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  isRequestable: Scalars['Boolean'];
  mediaVersionTypes: Array<MediaVersionType>;
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum MediaTypeCodeEnum {
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Photo = 'PHOTO',
  Video = 'VIDEO',
}

export enum MediaTypeEnum {
  Image = 'IMAGE',
  Video = 'VIDEO',
}

/** Media Version Types */
export type MediaVersionType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  landscapeHeight: Scalars['Float'];
  landscapeWidth: Scalars['Float'];
  /** name */
  name: Scalars['String'];
  portraitHeight: Scalars['Float'];
  portraitWidth: Scalars['Float'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum MicroServices {
  Backlot = 'BACKLOT',
  FastCapture = 'FAST_CAPTURE',
}

export type MissingDataError = IError & {
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type MoveAllProfileToCastingListInput = {
  fromListId: Scalars['ID'];
  includeNotes?: InputMaybe<Scalars['Boolean']>;
  toListId: Scalars['ID'];
};

export type MoveProfileToCastingListError = ValidationError;

export type MoveProfileToCastingListInput = {
  ids: Array<Scalars['ID']>;
  includeNotes?: InputMaybe<Scalars['Boolean']>;
  toListId: Scalars['ID'];
};

export type MoveProfileToCastingListResult = {
  error?: Maybe<MoveProfileToCastingListError>;
  success: Scalars['Boolean'];
};

export type MoveSubmissionsFromSlotsInput = {
  from: Array<SlotSubmissionsInput>;
  newSlotId: Scalars['Int'];
};

/** MultipleBirth object */
export type MultipleBirth = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type MutateFormError = ValidationErrorForm;

export type MutateListError = ValidationError;

export type Mutation = {
  /** Denotes if the project has been accepted successfully along with it(s) associated role(s) */
  acceptProjectModification: Scalars['Boolean'];
  /** Adds Add-in profile to the timeslot. Returns added submission */
  addAddInProfileToScheduleTimeSlot: Submission;
  addAddress: Scalars['Float'];
  /** Adding submissions for all profiles by search input filters. Returns count of created submissions. */
  addAllProfilesSubmissions: Scalars['Float'];
  /** Adds all profiles to list based on provided filters. */
  addAllProfilesToList: Scalars['Float'];
  /** @deprecated Search for another endpoint in payments */
  addArtistFreeCredits: Scalars['Boolean'];
  /** Adds new audition session. Returns id. */
  addAuditionSession?: Maybe<Scalars['Int']>;
  /** Adds new audition session group */
  addAuditionSessionGroup?: Maybe<Scalars['Int']>;
  addBillingInfo: BillingInfo;
  /** Block a keyword from being used in a custom slug */
  addBlockedKeyword: BlockedKeyword;
  addCastingListProfilesToRole: AddProfileToRoleResult;
  addCommentToPresentationSubmissionPublic: PresentationSubmissionComment;
  /** Adds comment for the submission */
  addCommentToSubmission: PresentationSubmissionComment;
  addNewSlug: CustomSlug;
  addNote: Note;
  addOrganizationAccount: Account;
  addOrganizationLogo: Media;
  /** Adds pick for the profile in the package */
  addPickToPackageProfile: Scalars['Int'];
  addPickToPackageProfilePublic: Scalars['String'];
  addPickToPresentationSubmissionPublic: Scalars['String'];
  /** Adds pick for the submission */
  addPickToSubmission: Scalars['Int'];
  addProfileBookout: Scalars['Boolean'];
  /** Creating profile conflict */
  addProfileConflict: Scalars['Boolean'];
  /** Adds a list of profiles. */
  addProfileList: ProfileList;
  /** Adds profiles (ids) to organization profile list */
  addProfileListEntities: Scalars['Float'];
  addProfilePet: ProfilePet;
  addProfileProp: ProfileProp;
  addProfileSkill: Scalars['Boolean'];
  addProfileSportsEquipment: ProfileSportEquipment;
  /** Adding profile submission */
  addProfileSubmission: Array<Submission>;
  addProfileSubmissionOrGet: SubmissionCreated;
  addProfileToAuditionGroup: Scalars['Boolean'];
  addProfileToAuditionGroupAddIn: Scalars['Boolean'];
  addProfileToCastingList: AddProfileToCastingListResult;
  /**
   * Adds a profile to a Project
   * @deprecated That mutation is deprecated. Please use addSubmission for the same
   */
  addProfileToProject: Submission;
  /** Adds profile to the timeslot. Returns ID of added submission */
  addProfileToScheduleTimeSlot: Submission;
  addProfileVehicle: ProfileVehicle;
  addProfileWardrobe: ProfileWardrobe;
  addProjectNote?: Maybe<Scalars['Float']>;
  /** Adds a list of representatives. */
  addRepresentativeList: Scalars['Float'];
  /** Add break to schedule */
  addScheduleBreak: Scalars['Int'];
  /** Quick add submission (FastCapture support) */
  addSubmissionQuick: Scalars['Int'];
  /** Add submission snapshot */
  addSubmissionSnapshot: Scalars['Int'];
  /** Mutation for adding submission to the Presentation Group */
  addSubmissionToPresentationGroup: Array<PresentationGroupSubmission>;
  /** Add Submissions to Audition Group */
  addSubmissionsToAuditionGroup: Scalars['Int'];
  addSubmissionsToAuditionTalentPool: Scalars['Boolean'];
  addSubmissionsToExistingSession: Scalars['Int'];
  /** Assign submissions to the calendar slot. Returns added submission ids */
  addSubmissionsToSlot: Array<Scalars['BigInt']>;
  addSubmittedProfilesToCastingLists: AddProfileToCastingListResult;
  addTalentToMediaRequest: MediaRequest;
  /** Add the talent(s) to Presentation Folder */
  addTalentToPresentationFolder: Scalars['Boolean'];
  addTalentToQuestionRequest: AskQuestionRequest;
  /** Add Timeframe to a Schedule */
  addTimeframeToSchedule: Scalars['Float'];
  approvePCProject: Scalars['Boolean'];
  archiveMedia: ArchiveMediaResponse;
  /** @deprecated use archiveMediaAssetV2 instead */
  archiveMediaAsset: Scalars['Boolean'];
  archiveMediaAssetV2: Scalars['Boolean'];
  /** Archive notification */
  archiveNotifications: Scalars['Boolean'];
  archiveProjects: Scalars['String'];
  archiveRole: Scalars['String'];
  attachCreditVideo: Array<Media>;
  attachMediaToArtist: Media;
  /** Attaches uploaded media to the Audition Group */
  attachMediaToAuditionGroup: Scalars['Boolean'];
  /** Mutation for attaching media to the presentation group */
  attachMediaToPresentationGroup: Scalars['Boolean'];
  attachMediaToProfile: Media;
  attachMediaToProfileSkill: Media;
  attachMediasToProfile: Scalars['Boolean'];
  attachMultipleMediaToProfile: Array<ProfileMedia>;
  /** Attaches uploaded media to the Audition Group in defined order */
  attachOrderedMediaToAuditionGroup: Scalars['Boolean'];
  /** Attach Zendesk ticket number to a project */
  attachProjectSupportTicketNumber: Scalars['Boolean'];
  /** Returns a session bearer token. */
  bauLogin: Scalars['String'];
  /** BAU login by providing legacy data. Returns onboarding token. */
  bauLoginByLegacyData: Scalars['Json'];
  cancelBenefits: CancelBenefits;
  cancelRequest: Scalars['Boolean'];
  /** Cancel Schedule */
  cancelSchedule: Scalars['Boolean'];
  /** @deprecated We use preCancel endpoint, this mutation will be removed in the future */
  cancelSubscription: Scalars['Boolean'];
  changeAuditionSessionTalentRoleName: Scalars['Boolean'];
  /** Changes order of the role. Returns either the order was changed or not */
  changeRoleOrder: Scalars['Boolean'];
  /** Changes Submission order in Audition Group */
  changeSubmissionOrderInAuditionGroup: Scalars['Boolean'];
  checkEmailOtp: Scalars['Boolean'];
  checkOtpCode: Scalars['Boolean'];
  /** Claims Rep/CD account multiple times */
  claimEnterpriseAccount: Scalars['Json'];
  cloneMedia: Media;
  closeDivision?: Maybe<Scalars['Float']>;
  completeArtistMediaCreation: CreateArtistMediaResponse;
  contactSubTalent: Scalars['Boolean'];
  copyAllProfileToCastingList: CopyProfileToCastingListResult;
  /** Copy the selected groups to the specific session */
  copyAuditionGroupsToAuditionSession?: Maybe<Scalars['Int']>;
  copyPresentation: Scalars['Int'];
  copyProfileData: Scalars['Float'];
  copyProfileFromExisting: Profile;
  copyProfileToCastingList: CopyProfileToCastingListResult;
  copyProfileUnderSameArtist: Scalars['Boolean'];
  /**
   * Copies submission data to another role
   * @deprecated Use moveSubmissionsToRole
   */
  copySubmissionToRole: Submission;
  createAccount: AuthToken;
  createAccountWithGoogle: AuthToken;
  createAgencyCallListReport: Scalars['String'];
  createAllTalentSubmissionReport: Scalars['String'];
  createArtistCredits: Array<Credit>;
  createArtistMedia: CreateArtistMediaResponse;
  /** Creates 'Ask Question' request type */
  createAskQuestionRequest: AskQuestionRequest;
  /** Mutation for creating Audition Presentation */
  createAuditionPresentation: Scalars['Int'];
  /** Creates new pair of tokens */
  createAuthContextByAccountId: AuthToken;
  createBookingCallListReport: Scalars['String'];
  createChatMessage: ChatMessageModel;
  createChatTopicFromSubRequest: Scalars['Boolean'];
  createCollaboratorAccount: AuthToken;
  createCollaboratorInvite: Scalars['ID'];
  createCompedSubscription: Scalars['Boolean'];
  createCouponRedemption: Scalars['Boolean'];
  createCredit: Credit;
  createEndUserAccount: Account;
  createForm: CreateFormResult;
  createFormResponseReport: Scalars['String'];
  createInternalAccount: Scalars['Float'];
  createList: CreateCastingListResult;
  /** Creates 'Media' request type */
  createMediaRequest: MediaRequest;
  createOrganization: Organization;
  createPCProject: Project;
  createPCRole: Role;
  /** Mutation for creating Presentation Folder */
  createPresentationFolder: Scalars['Int'];
  /** Mutation for creating Presentation Group in a Presentation Folder */
  createPresentationGroup: Scalars['Int'];
  createPresentationLogReport: Scalars['String'];
  createPresentationLogReportPublic: Scalars['String'];
  createPresentationPickReport: Scalars['String'];
  createPresentationSizeSheetsReport: Scalars['String'];
  createPresentationSizeSheetsReportPublic: Scalars['String'];
  createProfile: Profile;
  createProject: Project;
  createProjectOverviewCDReport: Scalars['String'];
  createProjectOverviewReport: Scalars['String'];
  /** Project Series ID */
  createProjectSeries: Scalars['Float'];
  createProjectSubmissionReport: Scalars['String'];
  /** Mutation for creating Rep Package Presentation */
  createRepPackagePresentation: Presentation;
  /** Sends request to media-request for all selected request statuses */
  createRequest: Scalars['Boolean'];
  createRole: Role;
  /** Creates 'Roster Invitation Request' type */
  createRosterInvitationRequest: RosterInvitationRequest;
  createSavedSearch: SavedSearch;
  /** Creates a schedule */
  createSchedule: ScheduleDetail;
  createScheduleReport: Scalars['String'];
  createScheduleSizeSheetsReport: Scalars['String'];
  createSessionAndAddSubmissions: Scalars['Int'];
  /**
   * Mutation for creating Share Selects Presentation
   * @deprecated use createShareTalentPresentation instead
   */
  createShareSelectPresentation: Presentation;
  /** Mutation for creating Share Selects Presentation */
  createShareTalentPresentation: Presentation;
  /** @deprecated Use createCompedSubscription instead */
  createSubscription: Scalars['Boolean'];
  createTalentScoutHistory: TalentScoutModel;
  createTalentSubmissionReport: Scalars['String'];
  customizeTalentInFolder: PresentationFolderTalentCustomization;
  /** Delete account */
  deleteAccount: Scalars['Boolean'];
  /** Delete submission */
  deleteAllSubmissions: Scalars['Float'];
  /** Returns true if deleted successfully. */
  deleteAuditionSession: Scalars['Boolean'];
  /** Unblock a keyword from being used in a custom slug */
  deleteBlockedKeyword: SuccessResponse;
  /** Delete comment for the submission */
  deleteCommentToPresentationSubmissionPublic: Scalars['Boolean'];
  deleteCredit: Scalars['Boolean'];
  deleteCreditMedia: Scalars['Boolean'];
  deleteList: DeleteCastingListResult;
  deleteMedia: DeleteMediaResponse;
  /** @deprecated use deleteMediaV2 instead */
  deleteMediaAsset: Scalars['Boolean'];
  /** Deletes Audition Group Media */
  deleteMediaFromAuditionGroup: Scalars['Int'];
  /** Mutation for deleting media from the presentation group */
  deleteMediaFromPresentationGroup: Scalars['Boolean'];
  deleteMediaV2: DeleteMediaV2Response;
  deleteNote: Scalars['Boolean'];
  deleteOrganzationAddress: Scalars['Boolean'];
  deletePick: Scalars['Boolean'];
  deletePickFromPresentationProfilePublic: Scalars['Boolean'];
  deletePickFromPresentationSubmissionPublic: Scalars['Boolean'];
  /** Removes pick from the submission */
  deletePickFromSubmission: Scalars['Boolean'];
  /** Mutation for deleting Presentation */
  deletePresentation?: Maybe<Scalars['Boolean']>;
  /** Archives the Presentation Folder */
  deletePresentationFolder: Scalars['Boolean'];
  /** Deletes the talent from Presentation Folder */
  deletePresentationFolderTalent: Scalars['Boolean'];
  /** Mutation for deleting Presentation Group */
  deletePresentationGroup: Scalars['Boolean'];
  deleteProfile: Scalars['Boolean'];
  /** Creating profile conflict */
  deleteProfileConflict: Scalars['Boolean'];
  /** Deletes the profile list by listId. */
  deleteProfileList: Scalars['Float'];
  /** Removes profiles (ids) from organization profile list */
  deleteProfileListEntities: Scalars['Float'];
  deleteProfileMedia: Scalars['Boolean'];
  deleteProfilePet: Scalars['Boolean'];
  deleteProfileProp: Scalars['Boolean'];
  deleteProfileSkill: Scalars['Boolean'];
  deleteProfileSportsEquipment: Scalars['Boolean'];
  deleteProfileVehicle: Scalars['Boolean'];
  deleteProfileWardrobe: Scalars['Boolean'];
  /** Count of items deleted */
  deleteProjectSeries: Scalars['Float'];
  /** Deletes the representative list by ListId. */
  deleteRepresentativeList: Scalars['Float'];
  deleteRequest: Scalars['Boolean'];
  deleteRole: Scalars['Boolean'];
  deleteSavedSearch: Scalars['Int'];
  /** Delete submission */
  deleteSubmission: Scalars['Boolean'];
  /** Delete comment for the submission */
  deleteSubmissionComment: Scalars['Boolean'];
  /** Mutation for deleting submission from the Presentation Group */
  deleteSubmissionFromPresentationGroup: Scalars['Boolean'];
  /**
   * Remove submission from calendar slot
   * @deprecated use deleteSubmissionsFromSlot
   */
  deleteSubmissionFromSlot: Scalars['Boolean'];
  /** Delete submission */
  deleteSubmissionsBulk: Scalars['Boolean'];
  /** Delete Submissions from Audition Group */
  deleteSubmissionsFromAuditionGroup: Scalars['Int'];
  /** Remove submissions from calendar slots */
  deleteSubmissionsFromSlot: Scalars['Boolean'];
  /** @deprecated We should not delete subscriptions, we should cancel them instead */
  deleteSubscription: Scalars['Boolean'];
  /** Delete Timeframe from a Schedule */
  deleteTimeframeFromSchedule: Scalars['Boolean'];
  editCastingList: EditCastingListResult;
  editCastingListProfiles: EditProfileCastingListResult;
  editMedia: Media;
  editMediaThumbnail: Media;
  /** Count of Modified Series */
  editProjectSeries: Scalars['Float'];
  enrollBenefits: EnrollBenefits;
  expireCompedSubscription: Scalars['Boolean'];
  expireSlug: SuccessResponse;
  finalizeMediaUpload: Array<Media>;
  finalizeMediaUploadV2: FinalizeMediaUploadResponse;
  forwardRequests: Array<RepWorksheetItem>;
  /** Generates signature for uploading media */
  getMediaUploadSignature: Scalars['String'];
  /** Get or create an attendee for the virtual audition */
  getOrCreateVirtualAuditionAttendee?: Maybe<VirtualAuditionAttendee>;
  /** Returns a session bearer token for internal user. */
  googleLoginInternal: AuthToken;
  grantTalentAccess: Scalars['Boolean'];
  hideProfileInRoster: Profile;
  hideProfileInRosterAndSearch: Profile;
  /** Mutation for import Audition Group to the Presentation Folder */
  importPresentationFolderFromAuditionGroup?: Maybe<PresentationFolder>;
  /** Mutation for import Audition Session data to the Presentation */
  importPresentationFromAudition: Scalars['Int'];
  initMediaUpload: Array<Media>;
  /** Initiates presentation's media downloading */
  initPresentationMediaToDownloadPublic: Scalars['String'];
  /** Initiates media bulk download */
  initiateMediaBulkDownload: InitiateMediaBulkDownloadResponse;
  initiateMediaUploadV2: InitiateMediaUploadResponse;
  /** Returns a session bearer token. */
  login: AuthToken;
  /** Sign in user on behalf of internal service */
  loginDelegatedUser: AuthToken;
  /** Creates new pair of tokens for CSR user */
  loginImpersonatedAccount: AuthToken;
  /** Returns a session bearer token. */
  loginWithAuthProvider: AuthToken;
  /** Deletes refresh token */
  logout: Scalars['Boolean'];
  markAllAsRead: Scalars['Boolean'];
  markChatMessageAsReadFromId: Scalars['Boolean'];
  /** Marks notifications as viewed */
  markNotifications: Scalars['Boolean'];
  mergeOrganization: Scalars['Boolean'];
  mergeUserAccounts: Scalars['Boolean'];
  moveAllProfileToCastingList: MoveProfileToCastingListResult;
  moveProfileToCastingList: MoveProfileToCastingListResult;
  /** Moves Submissions to the new time slot */
  moveSubmissionsFromSlotBulk: Scalars['Boolean'];
  /** Move submissions to another role */
  moveSubmissionsToRole: Array<Submission>;
  /** Prepare account for onboarding. */
  prepareAccountForOnboarding: Scalars['Boolean'];
  publishRoles: Scalars['Boolean'];
  purchaseMedia: MediaInvoice;
  purchaseSubscription: SubscriptionInvoice;
  reAddUserToOrganization?: Maybe<Scalars['Float']>;
  reassignProjectToOrganization?: Maybe<Scalars['Boolean']>;
  reassociateChildOrgtoParent?: Maybe<Scalars['Boolean']>;
  /** Creates new pair of tokens */
  refreshToken: AuthToken;
  registerAccountDevice: Scalars['Boolean'];
  /** Denotes if the project has been rejected successfully along with it(s) associated role(s) */
  rejectProjectModification: Scalars['Boolean'];
  removeActiveCouponRedemption: Scalars['Boolean'];
  removeBillingInfo: Scalars['Boolean'];
  removeOrganizationLogo: Organization;
  removeProfileBookout: Scalars['Boolean'];
  removeProfileFromCastingList: RemoveProfileFromCastingListResult;
  removeProfileResume: Scalars['Boolean'];
  removeUserFromOrganization?: Maybe<Scalars['Float']>;
  renewSubscription: SubscriptionRenewal;
  reopenDivision: Scalars['Boolean'];
  reorderList: ReorderResult;
  reorderProfile: ReorderResult;
  /** Reply to 'Ask Question' request */
  replyToAskQuestionRequest: AskQuestionSubRequest;
  /** Reply to 'Media' request */
  replyToMediaRequest: MediaSubRequest;
  /** Reply to 'Schedule Invite' request */
  replyToScheduleInviteRequest: ScheduleInviteSubRequest;
  /** Request changes on 'Media' request */
  requestMediaChanges: MediaSubRequest;
  /** Request for additional submissions for provided roles. Returns either request was sent or not. */
  requestSubmissionsForRoles: Scalars['Boolean'];
  resendCreatePasswordLink: Scalars['Boolean'];
  resendEmailVerification?: Maybe<Scalars['String']>;
  /** Resent Verification email */
  resentVerificationEmail: Scalars['Json'];
  /** Creates a temporary, single-use email otp code */
  resetEmail: Scalars['String'];
  /** Creates a temporary, single-use password reset token. */
  resetPassword: Scalars['String'];
  /** Reset password using B21 email, BAU email, BAU username. */
  resetPasswordBauB21: Scalars['Json'];
  /** Creates a temporary, single-use password reset token by legacy login and legacy instance. */
  resetPasswordByLegacyLogin: Scalars['String'];
  /** Deletes the current observer url and generates a new one */
  resetSessionObserverUrl: ResetSessionObserverUrlOutput;
  restoreArchivedMedia: RestoreArchivedMediaResponse;
  restoreDeletedMedia: RestoreDeletedMediaResponse;
  /** Restore notification */
  restoreNotifications: Scalars['Boolean'];
  restoreProjects: Scalars['String'];
  restoreRole: Scalars['String'];
  resumeSubscription: Scalars['Boolean'];
  revokeCollaboratorAccessFromOrg: Scalars['Boolean'];
  revokeCollaboratorAccessFromProject: Scalars['Boolean'];
  /** Saves 'Ask Question' request type as Draft */
  saveDraftAskQuestionRequest: AskQuestionRequest;
  /** Saves 'Media' request type as Draft */
  saveDraftMediaRequest: MediaRequest;
  saveFormResponse: SaveFormResponseResult;
  /** Add submissions for all profiles supplied. Returns count of created submissions. */
  selectProfilesFromRoster: Scalars['Float'];
  sendAccountEmailVerificationCode: Scalars['String'];
  sendDirectEmailToPickedPackageTalent: Scalars['Boolean'];
  sendDirectEmailToPresentationFolderTalent: Scalars['Boolean'];
  sendDirectMessageToTalents: Scalars['Boolean'];
  /** Email presentation link */
  sendPresentationLinkEmail: Scalars['Boolean'];
  /** Sends notification to all representatives */
  sendProjectUpdateMessageToReps: Scalars['Boolean'];
  /** Send 'Schedule Invite' request */
  sendScheduleInviteRequest: Scalars['Boolean'];
  sendVerificationCode: Scalars['String'];
  /** Sends Voice Over 'Media' request */
  sendVoiceOverMediaRequest: Array<MediaSubRequest>;
  setAccountProjectListFilterPreferences: Scalars['Boolean'];
  setAccountSchedulePreferences: Scalars['Boolean'];
  setCastingListProfileNote: AddNoteToCastingListProfileResult;
  setProfileActive: Profile;
  setProfileResume: Media;
  /** Denotes if the update went successfully */
  setProjectAsViewed: Scalars['Boolean'];
  setProjectStatus: Scalars['Boolean'];
  setRoleRepStatus: Scalars['Boolean'];
  /** Casting Director can set reply status */
  setSheduleInviteRequestStatus: ScheduleInviteSubRequest;
  setSubscriptionPaymentMethod: Scalars['Boolean'];
  shiftScheduleTimeframe?: Maybe<ScheduleTimeframeShiftedResult>;
  submitPCProjectForApproval: Scalars['Boolean'];
  /** Submits all selected submissions by REPS for the role */
  submitSelectedSubmissionForRole: Scalars['Boolean'];
  /** Submits all selected submissions by REPS for the role */
  submitSelectedSubmissionForRoleV1: Scalars['Int'];
  /** Creates new pair of tokens */
  switchAuthContext: AuthToken;
  unHideProfileInRosterAndSearch: Profile;
  unlockMedia: UnlockMediaResponse;
  updateAccount: Account;
  updateAccountBillingInfo: Scalars['Boolean'];
  updateAccountName: Account;
  updateAccountPhone: Account;
  /** Returns a session bearer token. */
  updateAccountPreMappedOrganizations: UpdateLegacyResult;
  updateAccountRosterInvitationToken: Account;
  /** Mutation for updating address */
  updateAddress: Scalars['Float'];
  /** Update organization for Agent rep User */
  updateAgentOrganization: UpdateOrganizationResult;
  updateArtist: Scalars['Boolean'];
  /** Updates 'Ask Question' request type */
  updateAskQuestionRequest: AskQuestionRequest;
  /** Update AuditionGroupMedia */
  updateAuditionGroupMedia: Scalars['Int'];
  /** Mutation for updating audition group sort order */
  updateAuditionGroupMediaOrder: Scalars['Boolean'];
  /** Set thumbnail media to the audition group */
  updateAuditionGroupThumbnail: AuditionGroup;
  /** Updates existing audition session. Returns true if updated successfully. */
  updateAuditionSession: Scalars['Boolean'];
  /** Updates new audition session group */
  updateAuditionSessionGroup?: Maybe<Scalars['Boolean']>;
  /** Updates audition session groups */
  updateAuditionSessionGroupsBulk?: Maybe<Scalars['Float']>;
  /** Update existing account */
  updateBauAccount: Scalars['Json'];
  /** Resent Verification email */
  updateBauEmailAddress: Scalars['Json'];
  updateBillingInfo: BillingInfo;
  /** Update organization for casting director user */
  updateCastingDirectorOrganization: UpdateOrganizationResult;
  updateCredit: Credit;
  updateCreditOrder: Scalars['Boolean'];
  updateEmailWithToken: AuthToken;
  updateForm: FormGqlModel;
  /** Update existing account */
  updateLegacyProfile: UpdateLegacyResult;
  updateMediaAsset: Media;
  /** Updates 'Media' request type */
  updateMediaRequest: MediaRequest;
  updateMediaRequestMessageForRepresentatives: MediaRequest;
  updateMediaRequestTalentInstructions: MediaRequest;
  updateNote: Scalars['Boolean'];
  updateNotificationEntityTypeSubscription: Scalars['Boolean'];
  updateNotificationNotificationTypeSubscription: Scalars['Boolean'];
  updateOrganization: Organization;
  updateOrganizationAccount?: Maybe<Scalars['Boolean']>;
  updateOrganizationFeatures: Array<Feature>;
  updatePCAccount?: Maybe<Scalars['Float']>;
  /** Returns a session bearer token. */
  updatePCOrganizations: UpdateLegacyResult;
  updatePCProject: Scalars['Boolean'];
  updatePassword: Scalars['String'];
  updatePasswordWithToken: Scalars['Boolean'];
  /** Mutation for updating Presentation */
  updatePresentation?: Maybe<Scalars['Int']>;
  /** Updates presentation folder */
  updatePresentationFolder: Scalars['Boolean'];
  /** Updates presentation folder sort order */
  updatePresentationFolderOrder: Scalars['Boolean'];
  /** Mutation for updating Presentation Group */
  updatePresentationGroup: PresentationGroup;
  /** Mutation for updating presentation group sort order */
  updatePresentationGroupMediaOrder: Scalars['Boolean'];
  /** Mutation for updating presentation group sort order */
  updatePresentationGroupOrder: Scalars['Boolean'];
  /** Set thumbnail media to the presentation group */
  updatePresentationGroupThumbnail: PresentationGroup;
  updateProfile: Profile;
  updateProfileArtist: Scalars['Int'];
  updateProfileBookout: Scalars['Boolean'];
  /** Creating profile conflict */
  updateProfileConflict: Scalars['Boolean'];
  updateProfileCreditTypeSortOrder: Scalars['Boolean'];
  /** Updates the name of a Profile List. */
  updateProfileListName: Scalars['Float'];
  /** Update single profile media */
  updateProfileMedia: Scalars['Boolean'];
  /** Update profile media list */
  updateProfileMediaList: Scalars['Boolean'];
  updateProfileMediaVisibility: Scalars['Boolean'];
  updateProfileMoveToArtist: Profile;
  updateProfilePet: ProfilePet;
  updateProfileProp: ProfileProp;
  updateProfileSkill: Scalars['Boolean'];
  updateProfileSportsEquipment: ProfileSportEquipment;
  updateProfileVehicle: ProfileVehicle;
  updateProfileVisibilities: Scalars['Boolean'];
  updateProfileWardrobe: ProfileWardrobe;
  updateProject: Project;
  /** Returns a session bearer token. */
  updateProjectCreatorAccount: AuthTokenItem;
  /** Updates a list of representatives. */
  updateRepresentativeList: Scalars['Float'];
  /** Updates the name of a Reps List. */
  updateRepresentativeListName: Scalars['Float'];
  updateRequestDueDate: RequestUnion;
  updateRole?: Maybe<Role>;
  updateRoleOpenCall?: Maybe<Role>;
  updateRoleStatus: Scalars['Boolean'];
  updateRolesWardrobeBulk: Scalars['Boolean'];
  updateSavedSearch: SavedSearch;
  /** Update break */
  updateScheduleBreak: Scalars['Boolean'];
  /** Update schedule */
  updateScheduleWithAddress: Scalars['Boolean'];
  /** Update submission */
  updateSubmission: Submission;
  /** Updates submission Selction Tag and Gets Related Submissions for the artist */
  updateSubmissionSelection: SubmissionSelectionResult;
  /** Update submission status */
  updateSubmissionStatus: Scalars['Boolean'];
  updateSubmissionsInAuditionTalentPool: Scalars['Boolean'];
  /** Update status of a bunch submissions */
  updateSubmissionsStatus: Array<Submission>;
  /** Update account email and resend email verification link. Returns new emailResetToken. */
  updateVerificationEmail: Scalars['String'];
  upgradePCOrgToCD?: Maybe<Scalars['Boolean']>;
  verifyAccountEmail: AuthToken;
  /** Verify BAU account from email link */
  verifyBauAccount: Scalars['String'];
  widenPublicationRoles: Scalars['Boolean'];
};

export type MutationAcceptProjectModificationArgs = {
  id: Scalars['ID'];
};

export type MutationAddAddInProfileToScheduleTimeSlotArgs = {
  input: AddProfileToTimeSlotAddInInput;
};

export type MutationAddAddressArgs = {
  addToOrganization?: InputMaybe<Scalars['Boolean']>;
  input: AddressInput;
};

export type MutationAddAllProfilesSubmissionsArgs = {
  roleId: Scalars['Int'];
  searchInput: ProfileSearchInput;
};

export type MutationAddAllProfilesToListArgs = {
  input: ProfileSearchInput;
  listId: Scalars['Float'];
};

export type MutationAddArtistFreeCreditsArgs = {
  input: AddArtistFreeCreditsInput;
};

export type MutationAddAuditionSessionArgs = {
  inputModel: AuditionSessionCreateInput;
};

export type MutationAddAuditionSessionGroupArgs = {
  inputModel: AuditionGroupInput;
};

export type MutationAddBillingInfoArgs = {
  input: AddBillingInfoInput;
};

export type MutationAddBlockedKeywordArgs = {
  blockedReason?: InputMaybe<CustomSlugBlockedReasonEnum>;
  blockedReasonCode?: InputMaybe<Scalars['String']>;
  blockedReasonNote?: InputMaybe<Scalars['String']>;
  keyword: Scalars['String'];
};

export type MutationAddCastingListProfilesToRoleArgs = {
  input: AddCastingListProfileToRoleInput;
};

export type MutationAddCommentToPresentationSubmissionPublicArgs = {
  comment: Scalars['String'];
  presentationId?: InputMaybe<Scalars['Float']>;
  submissionId: Scalars['BigInt'];
  userName: Scalars['String'];
};

export type MutationAddCommentToSubmissionArgs = {
  comment: Scalars['String'];
  presentationId?: InputMaybe<Scalars['Float']>;
  submissionId: Scalars['BigInt'];
};

export type MutationAddNewSlugArgs = {
  profileId: Scalars['Int'];
  slugName: Scalars['String'];
};

export type MutationAddNoteArgs = {
  input: AddNoteInput;
};

export type MutationAddOrganizationAccountArgs = {
  companyAdmin: Scalars['Boolean'];
  organizationId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationAddOrganizationLogoArgs = {
  mediaId: Scalars['Int'];
  organizationId: Scalars['Int'];
};

export type MutationAddPickToPackageProfileArgs = {
  packageId: Scalars['Float'];
  profileId: Scalars['Float'];
};

export type MutationAddPickToPackageProfilePublicArgs = {
  packageId: Scalars['Float'];
  profileId: Scalars['Float'];
  userName: Scalars['String'];
};

export type MutationAddPickToPresentationSubmissionPublicArgs = {
  presentationId?: InputMaybe<Scalars['Float']>;
  submissionId: Scalars['BigInt'];
  userName: Scalars['String'];
};

export type MutationAddPickToSubmissionArgs = {
  presentationId?: InputMaybe<Scalars['Float']>;
  submissionId: Scalars['BigInt'];
};

export type MutationAddProfileBookoutArgs = {
  artistId?: InputMaybe<Scalars['Float']>;
  input: ProfileBookOutInput;
};

export type MutationAddProfileConflictArgs = {
  input: CreateProfileConflictInput;
};

export type MutationAddProfileListArgs = {
  input: AddProfileListInput;
};

export type MutationAddProfileListEntitiesArgs = {
  input: AddOrDeleteListEntitiesInput;
};

export type MutationAddProfilePetArgs = {
  input: ProfilePetInput;
  profileId: Scalars['Float'];
};

export type MutationAddProfilePropArgs = {
  profileId: Scalars['Float'];
  propId: Scalars['Float'];
};

export type MutationAddProfileSkillArgs = {
  profileId: Scalars['Float'];
  skillIds: Array<Scalars['Float']>;
};

export type MutationAddProfileSportsEquipmentArgs = {
  profileId: Scalars['Float'];
  sportEquipmentId: Scalars['Float'];
};

export type MutationAddProfileSubmissionArgs = {
  input: CreateProfileSubmissionInput;
  syncIndexing?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAddProfileSubmissionOrGetArgs = {
  input: CreateProfileSubmissionOrGetInput;
};

export type MutationAddProfileToAuditionGroupArgs = {
  input: AddProfileToAuditionGroupInput;
};

export type MutationAddProfileToAuditionGroupAddInArgs = {
  input: AddProfileToAuditionGroupAddInInput;
};

export type MutationAddProfileToCastingListArgs = {
  input: AddProfileToCastingListInput;
};

export type MutationAddProfileToProjectArgs = {
  input: AddProfileToProjectInput;
};

export type MutationAddProfileToScheduleTimeSlotArgs = {
  input: AddProfileToTimeSlotInput;
};

export type MutationAddProfileVehicleArgs = {
  input: ProfileVehicleInput;
  profileId: Scalars['Float'];
};

export type MutationAddProfileWardrobeArgs = {
  profileId: Scalars['Float'];
  wardrobeId: Scalars['Float'];
};

export type MutationAddProjectNoteArgs = {
  input: ProjectNoteInput;
};

export type MutationAddRepresentativeListArgs = {
  input: AddRepresentativeListInput;
};

export type MutationAddScheduleBreakArgs = {
  endTime: Scalars['DateTime'];
  scheduleId: Scalars['Int'];
  startTime: Scalars['DateTime'];
};

export type MutationAddSubmissionQuickArgs = {
  input: SubmissionQuickAddInput;
};

export type MutationAddSubmissionSnapshotArgs = {
  snapshotId: Scalars['Int'];
  startOffset?: InputMaybe<Scalars['String']>;
  submissionsId: Scalars['BigInt'];
};

export type MutationAddSubmissionToPresentationGroupArgs = {
  groupId: Scalars['Int'];
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  searchInput?: InputMaybe<SubmissionSearchInput>;
  submissionIds: Array<Scalars['BigInt']>;
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationAddSubmissionsToAuditionGroupArgs = {
  createdBy?: InputMaybe<Scalars['Int']>;
  groupId: Scalars['Int'];
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  searchInput?: InputMaybe<SubmissionSearchInput>;
  submissionIds: Array<Scalars['BigInt']>;
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationAddSubmissionsToAuditionTalentPoolArgs = {
  createdBy?: InputMaybe<Scalars['Int']>;
  sessionId: Scalars['Int'];
  submissionIds: Array<Scalars['BigInt']>;
};

export type MutationAddSubmissionsToExistingSessionArgs = {
  sessionId: Scalars['Int'];
  submissionIds: Array<Scalars['BigInt']>;
};

export type MutationAddSubmissionsToSlotArgs = {
  input: AddSubmissionsToTimeframeInput;
};

export type MutationAddSubmittedProfilesToCastingListsArgs = {
  input: AddProfileSubmissionsToCastingListInput;
};

export type MutationAddTalentToMediaRequestArgs = {
  id: Scalars['ID'];
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  searchInput?: InputMaybe<WfSubmissionSearchInput>;
  submissionIds: Array<Scalars['Int']>;
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationAddTalentToPresentationFolderArgs = {
  presentationFolderId: Scalars['Int'];
  profileId: Array<Scalars['Int']>;
};

export type MutationAddTalentToQuestionRequestArgs = {
  id: Scalars['ID'];
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  searchInput?: InputMaybe<WfSubmissionSearchInput>;
  submissionIds: Array<Scalars['Int']>;
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationAddTimeframeToScheduleArgs = {
  input: AddTimeframeInput;
};

export type MutationApprovePcProjectArgs = {
  input: ApprovePcProjectInput;
};

export type MutationArchiveMediaArgs = {
  input: ArchiveMediaInput;
};

export type MutationArchiveMediaAssetArgs = {
  accountId?: InputMaybe<Scalars['Float']>;
  artistId?: InputMaybe<Scalars['Float']>;
  mediaId?: InputMaybe<Scalars['Float']>;
  mediaIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type MutationArchiveMediaAssetV2Args = {
  accountId?: InputMaybe<Scalars['Float']>;
  artistId?: InputMaybe<Scalars['Float']>;
  mediaIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type MutationArchiveNotificationsArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationArchiveProjectsArgs = {
  projectIds: Array<Scalars['Float']>;
};

export type MutationArchiveRoleArgs = {
  roleIds: Array<Scalars['String']>;
};

export type MutationAttachCreditVideoArgs = {
  attachToProfile?: InputMaybe<Scalars['Boolean']>;
  checkPayment?: InputMaybe<Scalars['Boolean']>;
  creditId: Scalars['Float'];
  mediaIds: Array<Scalars['Float']>;
};

export type MutationAttachMediaToArtistArgs = {
  artistId?: InputMaybe<Scalars['Int']>;
  mediaId: Scalars['Int'];
};

export type MutationAttachMediaToAuditionGroupArgs = {
  inputModel: AuditionGroupMediaInput;
};

export type MutationAttachMediaToPresentationGroupArgs = {
  groupId: Scalars['Int'];
  mediaIds: Array<Scalars['Int']>;
};

export type MutationAttachMediaToProfileArgs = {
  input: AttachMediaToProfileInput;
};

export type MutationAttachMediaToProfileSkillArgs = {
  checkPayment?: InputMaybe<Scalars['Boolean']>;
  mediaId: Scalars['Int'];
  profileId: Scalars['Int'];
  profileSkillId: Scalars['BigInt'];
};

export type MutationAttachMediasToProfileArgs = {
  mediaIds: Array<Scalars['Int']>;
  profileId: Scalars['Int'];
};

export type MutationAttachMultipleMediaToProfileArgs = {
  input: AttachMultipleMediaToProfileInput;
};

export type MutationAttachOrderedMediaToAuditionGroupArgs = {
  inputModel: AuditionGroupMediaInput;
};

export type MutationAttachProjectSupportTicketNumberArgs = {
  input: ProjectAttachSupportTicketNumber;
};

export type MutationBauLoginArgs = {
  input: BauLoginInput;
};

export type MutationBauLoginByLegacyDataArgs = {
  input: BauLoginByLegacyDataInput;
};

export type MutationCancelBenefitsArgs = {
  input: CancelBenefitsInput;
};

export type MutationCancelRequestArgs = {
  id: Scalars['ID'];
  input: CancelRequestInput;
};

export type MutationCancelScheduleArgs = {
  input: CancelScheduleInput;
};

export type MutationCancelSubscriptionArgs = {
  subscriptionId: Scalars['Int'];
};

export type MutationChangeAuditionSessionTalentRoleNameArgs = {
  input: ChangeAuditionSessionTalentRoleNameInput;
};

export type MutationChangeRoleOrderArgs = {
  moveBehind?: InputMaybe<Scalars['Boolean']>;
  positionalRoleId: Scalars['Int'];
  projectId: Scalars['Int'];
  roleId: Scalars['Int'];
};

export type MutationChangeSubmissionOrderInAuditionGroupArgs = {
  input: ChangeAuditionGroupTalentOrderInput;
};

export type MutationCheckEmailOtpArgs = {
  otp: Scalars['String'];
  verificationId: Scalars['String'];
};

export type MutationCheckOtpCodeArgs = {
  otpCode: Scalars['String'];
  verificationId: Scalars['String'];
};

export type MutationClaimEnterpriseAccountArgs = {
  input: BauAccountInput;
};

export type MutationCloneMediaArgs = {
  id: Scalars['Int'];
  input: MediaTransformationInput;
};

export type MutationCloseDivisionArgs = {
  divisionId: Scalars['Float'];
};

export type MutationCompleteArtistMediaCreationArgs = {
  input: CompleteArtistMediaCreationInput;
};

export type MutationContactSubTalentArgs = {
  input: ContactSubTalentInput;
};

export type MutationCopyAllProfileToCastingListArgs = {
  input: CopyAllProfileToCastingListInput;
};

export type MutationCopyAuditionGroupsToAuditionSessionArgs = {
  auditionGroups: Array<Scalars['Int']>;
  auditionSession: Scalars['Int'];
};

export type MutationCopyPresentationArgs = {
  input: CopyPresentationInput;
};

export type MutationCopyProfileDataArgs = {
  fromProfileId: Scalars['Float'];
  toProfileId: Scalars['Float'];
};

export type MutationCopyProfileFromExistingArgs = {
  invitationToken?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  profileId: Scalars['Float'];
};

export type MutationCopyProfileToCastingListArgs = {
  input: CopyProfileToCastingListInput;
};

export type MutationCopyProfileUnderSameArtistArgs = {
  fromProfileId: Scalars['Float'];
  toProfileId: Scalars['Float'];
};

export type MutationCopySubmissionToRoleArgs = {
  roleId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
  submissionStatusCode: SubmissionStatusCodeEnum;
  tileSchemaValueCode: Scalars['String'];
};

export type MutationCreateAccountArgs = {
  audience?: InputMaybe<Scalars['String']>;
  input: AccountCreateInput;
  isEmailVerificationRequired?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateAccountWithGoogleArgs = {
  input: CreateAccountWithGoogleInput;
};

export type MutationCreateAgencyCallListReportArgs = {
  input: AgencyCallListReportCreateInput;
};

export type MutationCreateAllTalentSubmissionReportArgs = {
  input: AllTalentSubmissionReportCreateInput;
};

export type MutationCreateArtistCreditsArgs = {
  credit: ArtistCreditsInput;
};

export type MutationCreateArtistMediaArgs = {
  input: CreateArtistMediaInput;
};

export type MutationCreateAskQuestionRequestArgs = {
  input: AskQuestionRequestInput;
};

export type MutationCreateAuditionPresentationArgs = {
  input: AuditionPresentationInput;
};

export type MutationCreateAuthContextByAccountIdArgs = {
  accountId: Scalars['Int'];
  organizationId?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<MicroServices>;
};

export type MutationCreateBookingCallListReportArgs = {
  input: ProjectReportCreateInput;
};

export type MutationCreateChatMessageArgs = {
  messageInput: ChatMessageCreateInput;
};

export type MutationCreateChatTopicFromSubRequestArgs = {
  subRequestId: Scalars['String'];
};

export type MutationCreateCollaboratorAccountArgs = {
  input: CollaboratorAccountCreateInput;
};

export type MutationCreateCollaboratorInviteArgs = {
  input: CollaboratorInviteCreateInput;
};

export type MutationCreateCompedSubscriptionArgs = {
  input: CreateCompedSubscriptionInput;
};

export type MutationCreateCouponRedemptionArgs = {
  input: CreateCouponRedemptionInput;
};

export type MutationCreateCreditArgs = {
  credit: CreditInput;
};

export type MutationCreateEndUserAccountArgs = {
  input: EndUserAccountInput;
};

export type MutationCreateFormArgs = {
  description: Scalars['String'];
  formSchema: Scalars['JSON'];
  title: Scalars['String'];
};

export type MutationCreateFormResponseReportArgs = {
  formId: Scalars['Float'];
  mediaRequestId: Scalars['String'];
  presentationKey?: InputMaybe<Scalars['String']>;
  sessionName?: InputMaybe<Scalars['String']>;
};

export type MutationCreateInternalAccountArgs = {
  input: InternalAccountCreateInput;
};

export type MutationCreateListArgs = {
  name: Scalars['String'];
  projectId: Scalars['Int'];
};

export type MutationCreateMediaRequestArgs = {
  input: MediaRequestInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreatePcProjectArgs = {
  input: CreatePcProjectInput;
};

export type MutationCreatePcRoleArgs = {
  input: RoleCreateInput;
};

export type MutationCreatePresentationFolderArgs = {
  input: PresentationFolderInput;
};

export type MutationCreatePresentationGroupArgs = {
  folderId: Scalars['Int'];
};

export type MutationCreatePresentationLogReportArgs = {
  input: PresentationReportCreateInput;
};

export type MutationCreatePresentationLogReportPublicArgs = {
  input: PresentationReportCreatePublicInput;
};

export type MutationCreatePresentationPickReportArgs = {
  input: PresentationReportCreateInput;
};

export type MutationCreatePresentationSizeSheetsReportArgs = {
  input: PresentationReportCreateInput;
};

export type MutationCreatePresentationSizeSheetsReportPublicArgs = {
  input: PresentationReportCreatePublicInput;
};

export type MutationCreateProfileArgs = {
  accountId?: InputMaybe<Scalars['Float']>;
  input: ProfileCreateInput;
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationCreateProjectOverviewCdReportArgs = {
  input: ProjectReportCdCreateInput;
};

export type MutationCreateProjectOverviewReportArgs = {
  input: ProjectReportCreateInput;
};

export type MutationCreateProjectSeriesArgs = {
  input: CreateProjectSeriesInput;
};

export type MutationCreateProjectSubmissionReportArgs = {
  input: ProjectSubmissionCreateInput;
};

export type MutationCreateRepPackagePresentationArgs = {
  name: Scalars['String'];
};

export type MutationCreateRequestArgs = {
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  sendTo: Array<RequestSendTo>;
};

export type MutationCreateRoleArgs = {
  input: RoleCreateInput;
};

export type MutationCreateRosterInvitationRequestArgs = {
  input: RosterInvitationRequestInput;
};

export type MutationCreateSavedSearchArgs = {
  input: CreateSavedSearchInput;
};

export type MutationCreateScheduleArgs = {
  input: CreateScheduleInput;
};

export type MutationCreateScheduleReportArgs = {
  input: ScheduleReportCreateInput;
};

export type MutationCreateScheduleSizeSheetsReportArgs = {
  input: ScheduleSizeSheetsReportCreateInput;
};

export type MutationCreateSessionAndAddSubmissionsArgs = {
  sessionName: Scalars['String'];
  submissionIds: Array<Scalars['BigInt']>;
};

export type MutationCreateShareSelectPresentationArgs = {
  input: ShareSelectPresentationInput;
};

export type MutationCreateShareTalentPresentationArgs = {
  input: ShareTalentPresentationInput;
};

export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};

export type MutationCreateTalentScoutHistoryArgs = {
  input: CreateTalentScoutHistoryInput;
};

export type MutationCreateTalentSubmissionReportArgs = {
  input: TalentSubmissionReportCreateInput;
};

export type MutationCustomizeTalentInFolderArgs = {
  input: CustomizeTalentInFolderInput;
};

export type MutationDeleteAccountArgs = {
  accountId: Scalars['Float'];
};

export type MutationDeleteAllSubmissionsArgs = {
  projectId: Scalars['Float'];
  roleId: Scalars['Float'];
};

export type MutationDeleteAuditionSessionArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteBlockedKeywordArgs = {
  keyword: Scalars['String'];
};

export type MutationDeleteCommentToPresentationSubmissionPublicArgs = {
  commentId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
  userName: Scalars['String'];
};

export type MutationDeleteCreditArgs = {
  creditId: Scalars['Float'];
};

export type MutationDeleteCreditMediaArgs = {
  creditId: Scalars['Float'];
  mediaId: Scalars['Float'];
};

export type MutationDeleteListArgs = {
  listId: Array<Scalars['ID']>;
};

export type MutationDeleteMediaArgs = {
  input: DeleteMediaInput;
};

export type MutationDeleteMediaAssetArgs = {
  accountId?: InputMaybe<Scalars['Float']>;
  mediaId: Scalars['Float'];
};

export type MutationDeleteMediaFromAuditionGroupArgs = {
  groupId: Scalars['Int'];
  mediaIds: Array<Scalars['Int']>;
};

export type MutationDeleteMediaFromPresentationGroupArgs = {
  groupId: Scalars['Int'];
  mediaIds: Array<Scalars['Int']>;
};

export type MutationDeleteMediaV2Args = {
  input: DeleteMediaV2Input;
};

export type MutationDeleteNoteArgs = {
  noteId: Scalars['Int'];
};

export type MutationDeleteOrganzationAddressArgs = {
  addressId: Scalars['Int'];
  organizationId: Scalars['Int'];
};

export type MutationDeletePickArgs = {
  pickId: Scalars['Int'];
};

export type MutationDeletePickFromPresentationProfilePublicArgs = {
  input: DeletePresentationProfilePickPublickInput;
};

export type MutationDeletePickFromPresentationSubmissionPublicArgs = {
  guid: Scalars['String'];
  pickId: Scalars['String'];
  submissionId: Scalars['BigInt'];
};

export type MutationDeletePickFromSubmissionArgs = {
  pickId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
};

export type MutationDeletePresentationArgs = {
  presentationId: Scalars['Int'];
};

export type MutationDeletePresentationFolderArgs = {
  presentationFolderId: Scalars['Int'];
};

export type MutationDeletePresentationFolderTalentArgs = {
  presentationFolderId: Scalars['Int'];
  profileId: Scalars['Int'];
};

export type MutationDeletePresentationGroupArgs = {
  groupId: Scalars['Int'];
};

export type MutationDeleteProfileArgs = {
  profileId: Scalars['Int'];
};

export type MutationDeleteProfileConflictArgs = {
  profileConflictId: Scalars['Float'];
};

export type MutationDeleteProfileListArgs = {
  listId: Scalars['Float'];
};

export type MutationDeleteProfileListEntitiesArgs = {
  input: AddOrDeleteListEntitiesInput;
};

export type MutationDeleteProfileMediaArgs = {
  mediaId: Scalars['Float'];
  profileId: Scalars['Float'];
};

export type MutationDeleteProfilePetArgs = {
  profileId: Scalars['Float'];
  profilePetId: Scalars['Float'];
};

export type MutationDeleteProfilePropArgs = {
  profileId: Scalars['Float'];
  profilePropId: Scalars['Float'];
};

export type MutationDeleteProfileSkillArgs = {
  profileSkillId: Scalars['BigInt'];
};

export type MutationDeleteProfileSportsEquipmentArgs = {
  profileId: Scalars['Float'];
  profileSportEquipmentId: Scalars['Float'];
};

export type MutationDeleteProfileVehicleArgs = {
  profileId: Scalars['Float'];
  profileVehicleId: Scalars['Float'];
};

export type MutationDeleteProfileWardrobeArgs = {
  profileId: Scalars['Float'];
  profileWardrobeId: Scalars['Float'];
};

export type MutationDeleteProjectSeriesArgs = {
  input: DeleteProjectSeriesInput;
};

export type MutationDeleteRepresentativeListArgs = {
  listId: Scalars['Float'];
};

export type MutationDeleteRequestArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRoleArgs = {
  roleId: Scalars['Float'];
};

export type MutationDeleteSavedSearchArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationDeleteSubmissionArgs = {
  id: Scalars['BigInt'];
};

export type MutationDeleteSubmissionCommentArgs = {
  commentId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
};

export type MutationDeleteSubmissionFromPresentationGroupArgs = {
  groupId: Scalars['Int'];
  submissionId: Scalars['BigInt'];
};

export type MutationDeleteSubmissionFromSlotArgs = {
  input: DeleteSubmissionFromSlotInput;
};

export type MutationDeleteSubmissionsBulkArgs = {
  ids: Array<Scalars['BigInt']>;
};

export type MutationDeleteSubmissionsFromAuditionGroupArgs = {
  groupId: Scalars['Int'];
  submissionIds: Array<Scalars['BigInt']>;
};

export type MutationDeleteSubmissionsFromSlotArgs = {
  input: DeleteSubmissionsFromSlotInput;
};

export type MutationDeleteSubscriptionArgs = {
  transaction: Scalars['String'];
};

export type MutationDeleteTimeframeFromScheduleArgs = {
  input: DeleteTimeframeInput;
};

export type MutationEditCastingListArgs = {
  listId: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationEditCastingListProfilesArgs = {
  input: EditProfileInput;
};

export type MutationEditMediaArgs = {
  id: Scalars['Int'];
  input: MediaTransformationInput;
};

export type MutationEditMediaThumbnailArgs = {
  id: Scalars['Int'];
  input: MediaTransformationInput;
};

export type MutationEditProjectSeriesArgs = {
  input: EditProjectSeriesInput;
};

export type MutationEnrollBenefitsArgs = {
  input: EnrollBenefitsInput;
};

export type MutationExpireCompedSubscriptionArgs = {
  input: ExpireCompedSubscriptionInput;
};

export type MutationExpireSlugArgs = {
  expiredReason?: InputMaybe<CustomSlugExpiredReasonEnum>;
  expiredReasonCode?: InputMaybe<Scalars['String']>;
  profileId: Scalars['Int'];
};

export type MutationFinalizeMediaUploadArgs = {
  input: Array<MediaMetadataInput>;
};

export type MutationFinalizeMediaUploadV2Args = {
  input: FinalizeMediaUploadInput;
};

export type MutationForwardRequestsArgs = {
  ccManagerEmail?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
};

export type MutationGetMediaUploadSignatureArgs = {
  params: Scalars['JSONObject'];
};

export type MutationGetOrCreateVirtualAuditionAttendeeArgs = {
  input: GetOrCreateVirtualAuditionAttendeeInput;
};

export type MutationGoogleLoginInternalArgs = {
  googleAuthToken: Scalars['String'];
};

export type MutationGrantTalentAccessArgs = {
  note: Scalars['String'];
  organizationId: Scalars['Float'];
  profileId: Scalars['Float'];
};

export type MutationHideProfileInRosterArgs = {
  id: Scalars['ID'];
};

export type MutationHideProfileInRosterAndSearchArgs = {
  id: Scalars['ID'];
};

export type MutationImportPresentationFolderFromAuditionGroupArgs = {
  auditionGroupIds: Array<Scalars['Int']>;
  presentationFolderId?: InputMaybe<Scalars['Int']>;
  presentationFolderName?: InputMaybe<Scalars['String']>;
  presentationId?: InputMaybe<Scalars['Int']>;
  presentationName?: InputMaybe<Scalars['String']>;
  projectId: Scalars['Int'];
};

export type MutationImportPresentationFromAuditionArgs = {
  auditionIds: Array<Scalars['Int']>;
  isLive: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  presentationId?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['Int'];
};

export type MutationInitMediaUploadArgs = {
  input: MediaInitUploadInput;
};

export type MutationInitPresentationMediaToDownloadPublicArgs = {
  input: PresentationMediaDownloadInput;
};

export type MutationInitiateMediaBulkDownloadArgs = {
  input: InitiateMediaBulkDownloadInput;
};

export type MutationInitiateMediaUploadV2Args = {
  input: InitiateMediaUploadInput;
};

export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationLoginDelegatedUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  service: MicroServices;
};

export type MutationLoginImpersonatedAccountArgs = {
  accountId: Scalars['Float'];
};

export type MutationLoginWithAuthProviderArgs = {
  authCode: Scalars['String'];
  authProvider: ExternalAuthenticationProvider;
  isMobileAppClient?: InputMaybe<Scalars['Boolean']>;
};

export type MutationLogoutArgs = {
  refreshToken: Scalars['String'];
};

export type MutationMarkAllAsReadArgs = {
  topicKey: Scalars['String'];
};

export type MutationMarkChatMessageAsReadFromIdArgs = {
  messageId: Scalars['String'];
};

export type MutationMarkNotificationsArgs = {
  ids: Array<Scalars['ID']>;
  viewed: Scalars['Boolean'];
};

export type MutationMergeOrganizationArgs = {
  fromOrganizationId: Scalars['Float'];
  toOrganizationId: Scalars['Float'];
};

export type MutationMergeUserAccountsArgs = {
  fromAccountId: Scalars['Float'];
  toAccountId: Scalars['Float'];
};

export type MutationMoveAllProfileToCastingListArgs = {
  input: MoveAllProfileToCastingListInput;
};

export type MutationMoveProfileToCastingListArgs = {
  input: MoveProfileToCastingListInput;
};

export type MutationMoveSubmissionsFromSlotBulkArgs = {
  input: MoveSubmissionsFromSlotsInput;
};

export type MutationMoveSubmissionsToRoleArgs = {
  roleId: Scalars['Int'];
  submissionIds: Array<Scalars['BigInt']>;
  submissionStatusCode?: InputMaybe<SubmissionStatusCodeEnum>;
  tileSchemaValueCode: Scalars['String'];
};

export type MutationPrepareAccountForOnboardingArgs = {
  accountId: Scalars['Float'];
  paypalCode?: InputMaybe<Scalars['String']>;
};

export type MutationPublishRolesArgs = {
  messageToReps?: InputMaybe<Scalars['String']>;
  messageToTalents?: InputMaybe<Scalars['String']>;
  roleIds: Array<Scalars['ID']>;
};

export type MutationPurchaseMediaArgs = {
  input: PurchaseMediaInput;
};

export type MutationPurchaseSubscriptionArgs = {
  input: PurchaseSubscriptionInput;
};

export type MutationReAddUserToOrganizationArgs = {
  accountId: Scalars['Float'];
  organizationId: Scalars['Float'];
};

export type MutationReassignProjectToOrganizationArgs = {
  fromOrganizationId: Scalars['Float'];
  toOrganizationId: Scalars['Float'];
};

export type MutationReassociateChildOrgtoParentArgs = {
  childOrgId: Scalars['Float'];
  parentOrgId: Scalars['Float'];
};

export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationRegisterAccountDeviceArgs = {
  input: RegisterDeviceInput;
};

export type MutationRejectProjectModificationArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveBillingInfoArgs = {
  input: RemoveBillingInfoInput;
};

export type MutationRemoveOrganizationLogoArgs = {
  organizationId: Scalars['Float'];
};

export type MutationRemoveProfileBookoutArgs = {
  input: ProfileBookOutInput;
};

export type MutationRemoveProfileFromCastingListArgs = {
  input: RemoveProfileFromCastingListInput;
};

export type MutationRemoveProfileResumeArgs = {
  profileId: Scalars['Int'];
};

export type MutationRemoveUserFromOrganizationArgs = {
  accountId: Scalars['Float'];
  organizationId: Scalars['Float'];
};

export type MutationRenewSubscriptionArgs = {
  input: RenewSubscriptionInput;
};

export type MutationReopenDivisionArgs = {
  divisionId: Scalars['Float'];
};

export type MutationReorderListArgs = {
  moveBehind: Scalars['Boolean'];
  movingId: Scalars['ID'];
  refId: Scalars['ID'];
};

export type MutationReorderProfileArgs = {
  moveBehind: Scalars['Boolean'];
  movingId: Scalars['ID'];
  refId: Scalars['ID'];
};

export type MutationReplyToAskQuestionRequestArgs = {
  input: AskQuestionResponseInput;
};

export type MutationReplyToMediaRequestArgs = {
  input: MediaResponseInput;
};

export type MutationReplyToScheduleInviteRequestArgs = {
  input: ScheduleInviteResponseInput;
};

export type MutationRequestMediaChangesArgs = {
  input: MediaChangeInput;
};

export type MutationRequestSubmissionsForRolesArgs = {
  messageToReps?: InputMaybe<Scalars['String']>;
  messageToTalents?: InputMaybe<Scalars['String']>;
  roleIds: Array<Scalars['Int']>;
};

export type MutationResendCreatePasswordLinkArgs = {
  token: Scalars['String'];
};

export type MutationResendEmailVerificationArgs = {
  audience: Scalars['String'];
  token: Scalars['String'];
};

export type MutationResentVerificationEmailArgs = {
  audience: Scalars['String'];
};

export type MutationResetEmailArgs = {
  audience: Scalars['String'];
  updateInfo: UpdateAccountInput;
};

export type MutationResetPasswordArgs = {
  audience?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

export type MutationResetPasswordBauB21Args = {
  legacyInstance?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
};

export type MutationResetPasswordByLegacyLoginArgs = {
  legacyInstance?: InputMaybe<Scalars['String']>;
  legacyLogin: Scalars['String'];
};

export type MutationResetSessionObserverUrlArgs = {
  input: ResetSessionObserverUrlInput;
};

export type MutationRestoreArchivedMediaArgs = {
  input: RestoreArchivedMediaInput;
};

export type MutationRestoreDeletedMediaArgs = {
  input: RestoreDeletedMediaInput;
};

export type MutationRestoreNotificationsArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationRestoreProjectsArgs = {
  projectIds: Array<Scalars['Float']>;
};

export type MutationRestoreRoleArgs = {
  roleIds: Array<Scalars['String']>;
};

export type MutationResumeSubscriptionArgs = {
  input: ResumeSubscriptionInput;
};

export type MutationRevokeCollaboratorAccessFromOrgArgs = {
  input: CollaboratorRevokeAccessFromOrgInput;
};

export type MutationRevokeCollaboratorAccessFromProjectArgs = {
  input: CollaboratorRevokeAccessFromProjectInput;
};

export type MutationSaveDraftAskQuestionRequestArgs = {
  input: SaveDraftAskQuestionRequestArgs;
};

export type MutationSaveDraftMediaRequestArgs = {
  input: SaveDraftMediaRequestArgs;
};

export type MutationSaveFormResponseArgs = {
  formId: Scalars['Int'];
  requestId: Scalars['String'];
  responseSchema: Scalars['JSON'];
};

export type MutationSelectProfilesFromRosterArgs = {
  profileIds: Array<Scalars['Int']>;
  roleId: Scalars['Int'];
};

export type MutationSendDirectEmailToPickedPackageTalentArgs = {
  message: Scalars['String'];
  packageFolderId: Scalars['Int'];
  subject?: InputMaybe<Scalars['String']>;
  talentCanSeeSenderAddress?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSendDirectEmailToPresentationFolderTalentArgs = {
  message: Scalars['String'];
  presentationFolderId: Scalars['Int'];
  subject?: InputMaybe<Scalars['String']>;
  talentCanSeeSenderAddress?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSendDirectMessageToTalentsArgs = {
  input: SendDirectMessageToTalentsInput;
};

export type MutationSendPresentationLinkEmailArgs = {
  input: PresentationLinkEmailInput;
};

export type MutationSendProjectUpdateMessageToRepsArgs = {
  input: ProjectNotificationToRepsInput;
};

export type MutationSendScheduleInviteRequestArgs = {
  input: ScheduleInviteRequestInput;
};

export type MutationSendVerificationCodeArgs = {
  language?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type MutationSendVoiceOverMediaRequestArgs = {
  input: SendVoiceOverMediaRequestInput;
};

export type MutationSetAccountProjectListFilterPreferencesArgs = {
  preferences: AccountProjectFiltersInput;
};

export type MutationSetAccountSchedulePreferencesArgs = {
  preferences: AccountSchedulePreferencesInput;
};

export type MutationSetCastingListProfileNoteArgs = {
  input: AddProfileNoteInput;
};

export type MutationSetProfileActiveArgs = {
  id: Scalars['ID'];
};

export type MutationSetProfileResumeArgs = {
  mediaId: Scalars['Int'];
  profileId: Scalars['Int'];
};

export type MutationSetProjectAsViewedArgs = {
  id: Scalars['ID'];
};

export type MutationSetProjectStatusArgs = {
  projectId: Scalars['ID'];
  projectStatusCodeToUpdate: Scalars['String'];
};

export type MutationSetRoleRepStatusArgs = {
  newStatus: RepRoleStatusEnum;
  roleId: Scalars['Float'];
};

export type MutationSetSheduleInviteRequestStatusArgs = {
  input: ScheduleInviteSetStatusInput;
};

export type MutationSetSubscriptionPaymentMethodArgs = {
  input: SetSubscriptionPaymentMethodInput;
};

export type MutationShiftScheduleTimeframeArgs = {
  input: ShiftTimeframeInput;
};

export type MutationSubmitPcProjectForApprovalArgs = {
  input: UpdatePcProjectInput;
};

export type MutationSubmitSelectedSubmissionForRoleArgs = {
  mediaRequestStatuses?: InputMaybe<Array<MediaRequestStatus>>;
  roleId: Scalars['Int'];
};

export type MutationSubmitSelectedSubmissionForRoleV1Args = {
  mediaRequestStatuses?: InputMaybe<Array<MediaRequestStatus>>;
  roleId: Scalars['Int'];
};

export type MutationSwitchAuthContextArgs = {
  app?: InputMaybe<Scalars['String']>;
  artistId?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['Int']>;
  refreshToken: Scalars['String'];
  role?: InputMaybe<SystemRoleCode>;
};

export type MutationUnHideProfileInRosterAndSearchArgs = {
  id: Scalars['ID'];
};

export type MutationUnlockMediaArgs = {
  input: UnlockMediaInput;
};

export type MutationUpdateAccountArgs = {
  accountId: Scalars['Float'];
  input: UpdateAccountInput;
};

export type MutationUpdateAccountBillingInfoArgs = {
  threeDSecureActionResultTokenId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateAccountNameArgs = {
  updateInfo: UpdateAccountInput;
};

export type MutationUpdateAccountPhoneArgs = {
  updateInfo: UpdateAccountInput;
};

export type MutationUpdateAccountRosterInvitationTokenArgs = {
  updateInfo: UpdateAccountInput;
};

export type MutationUpdateAddressArgs = {
  addToOrganization?: InputMaybe<Scalars['Boolean']>;
  addressId: Scalars['Float'];
  input: AddressInput;
};

export type MutationUpdateAgentOrganizationArgs = {
  input: AgentOrganizationInput;
};

export type MutationUpdateArtistArgs = {
  input: ArtistUpdateInput;
};

export type MutationUpdateAskQuestionRequestArgs = {
  id: Scalars['ID'];
  input: AskQuestionRequestInput;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type MutationUpdateAuditionGroupMediaArgs = {
  inputModel: AuditionGroupMediaInput;
};

export type MutationUpdateAuditionGroupMediaOrderArgs = {
  input: AuditionGroupMediaOrderUpdateInput;
};

export type MutationUpdateAuditionGroupThumbnailArgs = {
  groupId: Scalars['Int'];
  mediaId: Scalars['Int'];
  startOffset?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateAuditionSessionArgs = {
  inputModel: AuditionSessionUpdateInput;
};

export type MutationUpdateAuditionSessionGroupArgs = {
  auditionGroupId: Scalars['Int'];
  inputModel: AuditionGroupInput;
};

export type MutationUpdateAuditionSessionGroupsBulkArgs = {
  auditionGroupIds: Array<Scalars['Int']>;
  inputModel: AuditionGroupBulkUpdateInput;
};

export type MutationUpdateBauAccountArgs = {
  input: BauAccountInput;
};

export type MutationUpdateBauEmailAddressArgs = {
  audience: Scalars['String'];
  email: Scalars['String'];
};

export type MutationUpdateBillingInfoArgs = {
  input: UpdateBillingInfoInput;
};

export type MutationUpdateCastingDirectorOrganizationArgs = {
  input: OrganizationInput;
};

export type MutationUpdateCreditArgs = {
  credit: CreditUpdateInput;
};

export type MutationUpdateCreditOrderArgs = {
  input: Array<CreditOrderInput>;
};

export type MutationUpdateEmailWithTokenArgs = {
  token: Scalars['String'];
};

export type MutationUpdateFormArgs = {
  description?: InputMaybe<Scalars['String']>;
  formId: Scalars['Int'];
  formSchema?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateLegacyProfileArgs = {
  input: BauProfileUpdateInput;
};

export type MutationUpdateMediaAssetArgs = {
  input: UpdateMediaInput;
};

export type MutationUpdateMediaRequestArgs = {
  id: Scalars['ID'];
  input: MediaRequestInput;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type MutationUpdateMediaRequestMessageForRepresentativesArgs = {
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateMediaRequestTalentInstructionsArgs = {
  id: Scalars['ID'];
  instructions: Scalars['String'];
};

export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};

export type MutationUpdateNotificationEntityTypeSubscriptionArgs = {
  input: NotificationSubscriptionInput;
};

export type MutationUpdateNotificationNotificationTypeSubscriptionArgs = {
  input: NotificationSubscriptionInput;
};

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

export type MutationUpdateOrganizationAccountArgs = {
  accountId: Scalars['Float'];
  accountsRoots: Array<OrganizationAccountInput>;
};

export type MutationUpdateOrganizationFeaturesArgs = {
  features: Array<OrganizationFeatureInput>;
};

export type MutationUpdatePcAccountArgs = {
  input: PcAccountUpdateInput;
};

export type MutationUpdatePcOrganizationsArgs = {
  input: BauPcAccountUpdateInput;
};

export type MutationUpdatePcProjectArgs = {
  input: UpdatePcProjectInput;
};

export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type MutationUpdatePasswordWithTokenArgs = {
  isEmailNotificationRequired: Scalars['Boolean'];
  languageLocaleId?: InputMaybe<Scalars['Int']>;
  newPassword: Scalars['String'];
  timeZoneCode?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MutationUpdatePresentationArgs = {
  input: PresentationUpdateInput;
};

export type MutationUpdatePresentationFolderArgs = {
  input: PresentationFolderUpdateInput;
};

export type MutationUpdatePresentationFolderOrderArgs = {
  behindFolderId?: InputMaybe<Scalars['Int']>;
  folderId: Scalars['Int'];
};

export type MutationUpdatePresentationGroupArgs = {
  input: PresentationGroupUpdateInput;
};

export type MutationUpdatePresentationGroupMediaOrderArgs = {
  groupMediaId: Scalars['Int'];
  moveAfterGroupMediaId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdatePresentationGroupOrderArgs = {
  groupId: Scalars['Int'];
  moveAfterGroupMediaId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdatePresentationGroupThumbnailArgs = {
  groupId: Scalars['Int'];
  mediaId: Scalars['Int'];
  startOffset?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateProfileArgs = {
  accountId?: InputMaybe<Scalars['Float']>;
  input: ProfileUpdateInput;
};

export type MutationUpdateProfileArtistArgs = {
  artistId: Scalars['Int'];
  profileAccessToken: Scalars['String'];
};

export type MutationUpdateProfileBookoutArgs = {
  input: ProfileBookOutInput;
};

export type MutationUpdateProfileConflictArgs = {
  input: UpdateProfileConflictInput;
};

export type MutationUpdateProfileCreditTypeSortOrderArgs = {
  firstProfileCreditTypeId: Scalars['Float'];
  secondProfileCreditTypeId: Scalars['Float'];
};

export type MutationUpdateProfileListNameArgs = {
  input: UpdateListNameInput;
};

export type MutationUpdateProfileMediaArgs = {
  profileMedia: ProfileMediaInput;
};

export type MutationUpdateProfileMediaListArgs = {
  profileMediaList: Array<ProfileMediaInput>;
};

export type MutationUpdateProfileMediaVisibilityArgs = {
  mediaId: Scalars['Int'];
  profileIds: Array<Scalars['Int']>;
};

export type MutationUpdateProfileMoveToArtistArgs = {
  input: UpdateProfileMoveToArtistInput;
};

export type MutationUpdateProfilePetArgs = {
  input: ProfilePetInput;
  profileId: Scalars['Float'];
  profilePetId: Scalars['Float'];
};

export type MutationUpdateProfilePropArgs = {
  profileId: Scalars['Float'];
  profilePropId: Scalars['Float'];
  propId: Scalars['Float'];
};

export type MutationUpdateProfileSkillArgs = {
  input: ProfileSkillInput;
};

export type MutationUpdateProfileSportsEquipmentArgs = {
  profileId: Scalars['Float'];
  profileSportEquipmentId: Scalars['Float'];
  sportEquipmentId: Scalars['Float'];
};

export type MutationUpdateProfileVehicleArgs = {
  input: UpdateProfileVehicleInput;
  profileId: Scalars['Float'];
  profileVehicleId: Scalars['Float'];
};

export type MutationUpdateProfileVisibilitiesArgs = {
  profileId: Scalars['Float'];
  profileVisibilityCodes: Array<Scalars['String']>;
};

export type MutationUpdateProfileWardrobeArgs = {
  profileId: Scalars['Float'];
  profileWardrobeId: Scalars['Float'];
  wardrobeId: Scalars['Float'];
};

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

export type MutationUpdateRepresentativeListArgs = {
  input: UpdateRepresentativeListInput;
};

export type MutationUpdateRepresentativeListNameArgs = {
  input: UpdateListNameInput;
};

export type MutationUpdateRequestDueDateArgs = {
  id: Scalars['ID'];
  input: UpdateDueDateInput;
};

export type MutationUpdateRoleArgs = {
  input: RoleUpdateInput;
};

export type MutationUpdateRoleOpenCallArgs = {
  isOpenCall: Scalars['Boolean'];
  roleId: Scalars['Int'];
};

export type MutationUpdateRoleStatusArgs = {
  roleIds: Array<Scalars['Int']>;
  roleStatusCode: RoleStatusCodes;
};

export type MutationUpdateRolesWardrobeBulkArgs = {
  input: Array<RoleUpdateWardrobeInput>;
};

export type MutationUpdateSavedSearchArgs = {
  input: UpdateSavedSearchInput;
};

export type MutationUpdateScheduleBreakArgs = {
  breakId: Scalars['Int'];
  endTime: Scalars['DateTime'];
  scheduleId: Scalars['Int'];
  startTime: Scalars['DateTime'];
};

export type MutationUpdateScheduleWithAddressArgs = {
  addressInput?: InputMaybe<AddressInput>;
  scheduleInput: UpdateScheduleInput;
};

export type MutationUpdateSubmissionArgs = {
  input: SubmissionUpdateInput;
  syncIndexing?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateSubmissionSelectionArgs = {
  input: SubmissionUpdateSelectionInput;
};

export type MutationUpdateSubmissionStatusArgs = {
  input: UpdateSubmissionStatusInput;
};

export type MutationUpdateSubmissionsInAuditionTalentPoolArgs = {
  sessionId: Scalars['Int'];
  submissionSnapshotIds: Array<AuditionSubmissionSnapshotInput>;
};

export type MutationUpdateSubmissionsStatusArgs = {
  statusCode: Scalars['String'];
  submissionIds: Array<Scalars['BigInt']>;
};

export type MutationUpdateVerificationEmailArgs = {
  audience: Scalars['String'];
  email: Scalars['String'];
};

export type MutationUpgradePcOrgToCdArgs = {
  organizationId: Scalars['Float'];
};

export type MutationVerifyBauAccountArgs = {
  token: Scalars['String'];
};

export type MutationWidenPublicationRolesArgs = {
  messageToReps?: InputMaybe<Scalars['String']>;
  messageToTalents?: InputMaybe<Scalars['String']>;
  roleIds: Array<Scalars['Int']>;
  rolePublicationSettings: Array<RolePublicationSettingInput>;
};

export type Neck = {
  /** Imperial Feet Inches */
  IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  cm: Scalars['Int'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
  /** Inches rounded */
  inchRounded: Scalars['String'];
  /** Show in imperial UI */
  showInImperialUI: Scalars['Boolean'];
};

export type NotFoundError = IError & {
  code: Scalars['Int'];
  message: Scalars['String'];
};

/** Note object type */
export type Note = {
  /** Get Account object for the Note */
  account: Account;
  /** Account id who created the note */
  accountId: Scalars['Int'];
  /** Comment */
  comment: Scalars['String'];
  /** Created Date */
  created: Scalars['DateTime'];
  entityId: Scalars['BigInt'];
  entityType: EntityType;
  /** Note Id */
  id: Scalars['Int'];
  noteModifier?: Maybe<NoteModifier>;
  noteType?: Maybe<NoteType>;
  /** Project status at the moment when note was added */
  projectStatus?: Maybe<ProjectStatus>;
};

/** Note Modifier object */
export type NoteModifier = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  entityType: EntityType;
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum NoteModifierEnum {
  BadArtist = 'BAD_ARTIST',
  BadProfile = 'BAD_PROFILE',
  GoodArtist = 'GOOD_ARTIST',
  GoodProfile = 'GOOD_PROFILE',
  VeryBadArtist = 'VERY_BAD_ARTIST',
  VeryBadProfile = 'VERY_BAD_PROFILE',
  VeryGoodArtist = 'VERY_GOOD_ARTIST',
  VeryGoodProfile = 'VERY_GOOD_PROFILE',
}

/** Paginated result with [Note] objects */
export type NotePaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of Note objects */
  objects: Array<Note>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Note Type object */
export type NoteType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum NoteTypeEnum {
  AddIn = 'ADD_IN',
  AdminNote = 'ADMIN_NOTE',
  AgentNote = 'AGENT_NOTE',
  CdTalentNote = 'CD_TALENT_NOTE',
  FastcaptureNote = 'FASTCAPTURE_NOTE',
  PermanentNote = 'PERMANENT_NOTE',
  PermanentSubmissionNote = 'PERMANENT_SUBMISSION_NOTE',
  SubmissionNote = 'SUBMISSION_NOTE',
}

export type Notification = {
  archivedAt?: Maybe<Scalars['DateTime']>;
  context?: Maybe<NotificationContext>;
  from?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  message: Scalars['String'];
  subject: Scalars['String'];
  timestamp: Scalars['DateTime'];
  toAccountId?: Maybe<Scalars['Int']>;
  toArtistId?: Maybe<Scalars['Int']>;
  toProfileId: Scalars['Int'];
  type: Scalars['String'];
  viewed?: Maybe<Scalars['Boolean']>;
};

export type NotificationContext = {
  auditionDateUTC?: Maybe<Scalars['DateTime']>;
  requestId?: Maybe<Scalars['ID']>;
  requestType?: Maybe<RequestType>;
};

export type NotificationEntityDetails = PaidType | ProjectType | RoleTarget | TalentUnion;

/** Notification Entity Subscription object type */
export type NotificationEntitySubscription = {
  /** Subscription Creator Id */
  accountId: Scalars['Int'];
  /** Artist Id */
  artistId: Scalars['Int'];
  /** Created Date */
  created: Scalars['DateTime'];
  entity?: Maybe<NotificationEntityDetails>;
  /** Entity Id */
  entityId?: Maybe<Scalars['BigInt']>;
  entityType?: Maybe<EntityType>;
  /** Entity Type Id */
  entityTypeId?: Maybe<Scalars['Int']>;
  /** Notification Id */
  id?: Maybe<Scalars['ID']>;
  /** Notification Id */
  notificationId?: Maybe<Scalars['Int']>;
  notificationNature?: Maybe<NotificationNature>;
  notificationType?: Maybe<NotificationType>;
  /** Organization Id */
  organizationId?: Maybe<Scalars['Int']>;
  /** @deprecated Use "entity" field instead */
  projectType?: Maybe<ProjectType>;
  /** @deprecated Use "entity" field instead */
  rolePaidType?: Maybe<PaidType>;
  /** @deprecated Use "entity" field instead */
  roleTarget?: Maybe<RoleTarget>;
};

/** Notification Nature object */
export type NotificationNature = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum NotificationNatureEnum {
  Alert = 'ALERT',
  Email = 'EMAIL',
  Push = 'PUSH',
  Text = 'TEXT',
}

/** Order By options, default set to timestamp DESC */
export type NotificationOrderByInput = {
  /** Timestamp Sort field */
  timestamp: SortOrder;
};

export type NotificationPagedList = {
  data: Array<Notification>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Add/Update Notification Subscription Input Type */
export type NotificationSubscriptionInput = {
  /** Account Id that notification subscription will be assigned to */
  accountId?: InputMaybe<Scalars['Int']>;
  /** Artist Id that notification subscription will be assigned to */
  artistId?: InputMaybe<Scalars['Int']>;
  /** Entity Type Subscription Array */
  entitySubscription?: InputMaybe<Array<EntitySubscriptionInput>>;
  /** Notification Subscription ID which should be updated */
  notificationId?: InputMaybe<Scalars['ID']>;
  notificationPhone?: InputMaybe<Scalars['ID']>;
  /** Notification Type Subscription Array */
  notificationTypeSubscription?: InputMaybe<Array<NotificationTypeSubscriptionInput>>;
  /** Organization Id that notification subscription will be assigned to */
  organizationId?: InputMaybe<Scalars['Int']>;
};

/** Notification Type object */
export type NotificationType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum NotificationTypeEnum {
  Chat = 'CHAT',
  Marketing = 'MARKETING',
  Project = 'PROJECT',
  ProjectApproval = 'PROJECT_APPROVAL',
  Request = 'REQUEST',
}

/** Notification Notitifcation Type Subscription object type */
export type NotificationTypeNotificationSubscription = {
  /** Subscription Creator Id */
  accountId: Scalars['Int'];
  /** Artist Id */
  artistId: Scalars['Int'];
  /** Created Date */
  created: Scalars['DateTime'];
  entity?: Maybe<NotificationEntityDetails>;
  entityType?: Maybe<EntityType>;
  /** Notification Id */
  notificationId?: Maybe<Scalars['Int']>;
  notificationNature?: Maybe<NotificationNature>;
  /** Notification Nature Id */
  notificationNatureId?: Maybe<Scalars['Int']>;
  notificationType?: Maybe<NotificationType>;
  /** Notification Type Id */
  notificationTypeId?: Maybe<Scalars['Int']>;
  /** Organization Id */
  organizationId?: Maybe<Scalars['Int']>;
  /** @deprecated Use "entity" field instead */
  projectType?: Maybe<ProjectType>;
  /** @deprecated Use "entity" field instead */
  rolePaidType?: Maybe<PaidType>;
  /** @deprecated Use "entity" field instead */
  roleTarget?: Maybe<RoleTarget>;
};

/** Input type for notification type subscription */
export type NotificationTypeSubscriptionInput = {
  /** Notification Nature CODE */
  notificationNatureCode: Scalars['String'];
  /** Notification Type CODE */
  notificationTypeCode: Scalars['String'];
  /** Subscription status */
  subscriptionStatus: Scalars['Boolean'];
};

/** Account status object */
export type OnboardingStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for ordered items like Working Location or Ethnic Appearance */
export type OrderedItemInput = {
  id: Scalars['ID'];
  order: Scalars['Int'];
};

/** Organization object */
export type Organization = {
  /** Abbreviation */
  abbreviation?: Maybe<Scalars['String']>;
  accountCount?: Maybe<Scalars['Float']>;
  /** Organization accounts */
  accounts: Array<Account>;
  /** Linked organizations */
  addresses?: Maybe<Array<Address>>;
  contacts?: Maybe<Array<Contact>>;
  country: Array<Country>;
  created?: Maybe<Scalars['DateTime']>;
  /** Creator ID */
  creatorId?: Maybe<Scalars['Int']>;
  /** Diversity disclaimer */
  diversityDisclaimer?: Maybe<Scalars['String']>;
  divisions: Array<Organization>;
  dmas?: Maybe<Array<Dma>>;
  features?: Maybe<Array<Feature>>;
  /** Primary ID */
  id: Scalars['Int'];
  /** Tells whether it is organization or division */
  isOrganization?: Maybe<Scalars['Boolean']>;
  /** Last updated/modified datetime */
  lastUpdated?: Maybe<Scalars['DateTime']>;
  linkedAccount: Scalars['Float'];
  linkedAccountDivision: Array<Organization>;
  linkedDivisionId?: Maybe<Scalars['Float']>;
  linkedDivisions: Array<Organization>;
  /** Linked organizations */
  linkedOrganizations: Array<Organization>;
  logo?: Maybe<Media>;
  /** Logo media ID */
  logoMediaId?: Maybe<Scalars['Int']>;
  /** Organization name */
  name?: Maybe<Scalars['String']>;
  /** Note */
  note?: Maybe<Scalars['String']>;
  /** Parent organization ID */
  organizationParentId?: Maybe<Scalars['Int']>;
  organizationPhone?: Maybe<Array<Phone>>;
  organizationStatus: Array<OrganizationStatus>;
  /** Parent Organization name */
  parentName?: Maybe<Scalars['String']>;
  parentOrganization?: Maybe<Organization>;
  /** Organization phone list */
  phone: Array<Scalars['String']>;
  profileCount?: Maybe<Scalars['Float']>;
  /** Profile list ID */
  profileListId?: Maybe<Scalars['Int']>;
  /** Organization profiles */
  profiles: Array<Profile>;
  /** Project casting assistant */
  projectCastingAssistant?: Maybe<Scalars['String']>;
  /** Project casting associate */
  projectCastingAssociate?: Maybe<Scalars['String']>;
  /** Project casting director */
  projectCastingDirector?: Maybe<Scalars['String']>;
  representationAgeCategory?: Maybe<Array<RepresentationAgeCategory>>;
  representationCategory?: Maybe<Array<RepresentationCategory>>;
  representationCategoryList?: Maybe<Array<Scalars['Int']>>;
  representationProfile: Array<Profile>;
  representationType: Array<RepresentationType>;
  /** Representation type ID */
  representationTypeId?: Maybe<Scalars['Int']>;
  representationTypes: Array<RepresentationType>;
  /** Schedule address note */
  scheduleAddressNote?: Maybe<Scalars['String']>;
  searchDivisions: Array<Organization>;
  /** Organization status */
  status?: Maybe<Scalars['Float']>;
  systemRoleCode?: Maybe<Scalars['String']>;
  /** System role ID */
  sytemRoleId?: Maybe<Scalars['Int']>;
  tags: Array<Tag>;
  /** Tile scheme ID */
  tileSchemeId?: Maybe<Scalars['Int']>;
  /** Website url */
  websiteUrl?: Maybe<Scalars['String']>;
};

/** Organization object */
export type OrganizationAccountsArgs = {
  statusIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Object that represent realtion between organization and account */
export type OrganizationAccount = {
  accountId: Scalars['Float'];
  isAdmin: Scalars['Boolean'];
  organizationId: Scalars['Float'];
  organizationStatus: Scalars['Float'];
};

export type OrganizationAccountInput = {
  enabled: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  organizationId: Scalars['Int'];
};

export type OrganizationDictionaryItem = {
  /** Organization abbreviation */
  abbreviation?: Maybe<Scalars['String']>;
  /** Primary ID */
  id: Scalars['Int'];
  /** Organization name */
  name?: Maybe<Scalars['String']>;
};

export type OrganizationDictionaryItemPagedList = {
  data: Array<OrganizationDictionaryItem>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Input type for Organization Feature */
export type OrganizationFeatureInput = {
  enabled: Scalars['Boolean'];
  featureId: Scalars['Int'];
};

export type OrganizationFilter = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type OrganizationInfo = {
  category: Array<RepresentationCategory>;
  categoryName: Array<Scalars['String']>;
  /** Primary ID */
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Creates or update organization contact or address on given input values */
export type OrganizationInput = {
  /** Address Id */
  addressId?: InputMaybe<Scalars['Float']>;
  /** City */
  city: Scalars['String'];
  /** Company Address Line 1 */
  companyAddressLine1: Scalars['String'];
  /** Company Address Line 2 */
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  /** Company Phone Number */
  companyPhoneNumber?: InputMaybe<Scalars['String']>;
  /** Contact Id */
  contactId?: InputMaybe<Scalars['Float']>;
  /** Country Id */
  countryId: Scalars['Float'];
  /** is update org info */
  isUpdateOrgInfo: Scalars['Boolean'];
  /** Organization Id */
  organizationId: Scalars['Float'];
  /** Organization Parent Id */
  parentOrganizationId?: InputMaybe<Scalars['Float']>;
  /** Primary Contact Email */
  primaryContactEmail: Scalars['String'];
  /** Primary Contact Name */
  primaryContactName: Scalars['String'];
  /** Primary Contact Phone Number */
  primaryContactPhoneNumber: Scalars['String'];
  /** State Id */
  stateId?: InputMaybe<Scalars['Float']>;
  /** Zip */
  zip: Scalars['String'];
};

export type OrganizationItem = {
  /** abbreviation */
  abbreviation?: Maybe<Scalars['String']>;
  /** Account Count */
  accountCount?: Maybe<Scalars['Int']>;
  accounts?: Maybe<Array<AccountItem>>;
  created?: Maybe<Scalars['DateTime']>;
  divisions?: Maybe<Array<OrganizationItem>>;
  dmas?: Maybe<Array<DmaItem>>;
  /** Primary ID */
  id: Scalars['Int'];
  logo?: Maybe<MediaItem>;
  /** Organization name */
  name?: Maybe<Scalars['String']>;
  /** Parent organization ID */
  organizationParentId?: Maybe<Scalars['Int']>;
  organizationStatus: OrganizationStatus;
  /** Profile Count */
  profileCount?: Maybe<Scalars['Int']>;
  representationCategory?: Maybe<Array<RepresentationCategory>>;
  representationType?: Maybe<RepresentationType>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type OrganizationItemPagedList = {
  data: Array<OrganizationItem>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type OrganizationLogo = {
  fileKey: Scalars['String'];
  id: Scalars['Float'];
};

export type OrganizationProjectFilterInput = {
  /** Project name filter */
  name?: InputMaybe<Scalars['String']>;
  /** Project status codes */
  projectStatusCodes?: InputMaybe<Array<Scalars['String']>>;
  /** Project type codes */
  projectTypeCodes?: InputMaybe<Array<Scalars['String']>>;
  /** Project series filter */
  seriesIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type OrganizationProjectGroup = {
  /** Organization project group details */
  details: OrganizationProjectGroupDetails;
  itemCount: Scalars['Int'];
};

export type OrganizationProjectGroupDetails = ProjectSeries | ProjectStatus | ProjectType;

/** Paginated result with [OrganizationProjectGroup] objects */
export type OrganizationProjectGroupPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of OrganizationProjectGroup objects */
  objects: Array<OrganizationProjectGroup>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Organization search input */
export type OrganizationSearchInput = {
  /** Organization abbreviation */
  abbreviation?: InputMaybe<Scalars['String']>;
  /** List of DMA Id */
  dmas?: InputMaybe<Array<Scalars['Int']>>;
  /** Defines whether search should use 'add' or 'or' clause with input parameters */
  multiMatch?: InputMaybe<Scalars['Boolean']>;
  /** Organization name */
  name?: InputMaybe<Scalars['String']>;
  /** Organization Id */
  organizationId?: InputMaybe<Scalars['Int']>;
  /** Organization parent Id */
  organizationParentId?: InputMaybe<Scalars['Int']>;
  /** Organization parent Ids */
  organizationParentIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Organization Status Ids */
  organizationStatusIds?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<Scalars['Boolean']>;
  /** List of Representation Age Category Id */
  representationAgeCategoryIds?: InputMaybe<Array<Scalars['Int']>>;
  /** List of Representation Category Id */
  representationCategoryIds?: InputMaybe<Array<Scalars['Int']>>;
  /** List of Representation Category Id */
  representationCategoryList?: InputMaybe<Array<Scalars['Int']>>;
  /** Organization Type Id */
  representationTypeId?: InputMaybe<Scalars['Int']>;
  /** List of Representation Type Id */
  representationTypeIds?: InputMaybe<Array<Scalars['Int']>>;
  /** System Role Id for account/user */
  systemRoleId?: InputMaybe<Scalars['Int']>;
};

/** Organization search result */
export type OrganizationSearchResult = {
  /** List of organization */
  organizations?: Maybe<Array<Organization>>;
  /** Total records count */
  totalCount: Scalars['Int'];
};

/** Organization Status */
export type OrganizationStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum OrganizationStatusEnum {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Joined = 'JOINED',
  Unclaimed = 'UNCLAIMED',
}

/** Input for get orgs by representation type + piece of/full name of org */
export type OrganizationsByRepTypeAndNameInput = {
  /**
   * Org name or part of the Org name. Could be null in case if first N(page cfg)
   * records need to be presented in order to sort by name asc
   */
  orgName?: InputMaybe<Scalars['String']>;
  /** Codes of sought org representation type */
  orgRepTypeCodes: Array<Scalars['String']>;
  /** Pagination config */
  page: PageInput;
  /** Optional: ids of orgs used for search by org rep type+org name */
  targetOrganizationIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Object that represent organization data retreived by org representation type and partial/full name match */
export type OrganizationsByRepTypeAndNameResult = {
  orgId: Scalars['Float'];
  /** Organization object */
  organization: Organization;
};

/** Paginated result with [OrganizationsByRepTypeAndNameResult] objects */
export type OrganizationsByRepTypeAndNameResultPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of OrganizationsByRepTypeAndNameResult objects */
  objects: Array<OrganizationsByRepTypeAndNameResult>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Object that represent Reps divisions/agencies and count of their submissions to particular role */
export type OrganizationsByRoleSubmissionsAndName = {
  orgId: Scalars['Int'];
  /** Organization object */
  organization: Organization;
  submissionsCount: Scalars['BigInt'];
};

/** Paginated result with [OrganizationsByRoleSubmissionsAndName] objects */
export type OrganizationsByRoleSubmissionsAndNamePaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of OrganizationsByRoleSubmissionsAndName objects */
  objects: Array<OrganizationsByRoleSubmissionsAndName>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type PcAccountUpdateInput = {
  accountId: Scalars['ID'];
  companyName?: InputMaybe<Scalars['String']>;
  facebookLink?: InputMaybe<Scalars['String']>;
  imdbLink?: InputMaybe<Scalars['String']>;
  instagramLink?: InputMaybe<Scalars['String']>;
  institutionName?: InputMaybe<Scalars['String']>;
  portfolio?: InputMaybe<Scalars['String']>;
  professionalBio?: InputMaybe<Scalars['String']>;
  twitterLink?: InputMaybe<Scalars['String']>;
  vimeoLink?: InputMaybe<Scalars['String']>;
  youtubeLink?: InputMaybe<Scalars['String']>;
};

/** Determined location Address Input for creating PC project */
export type PcProjectDeterminedAddressInput = {
  /** City */
  city: Scalars['String'];
  /** Country CODE */
  countryCode: Scalars['String'];
  /** Location notes */
  locationNotes?: InputMaybe<Scalars['String']>;
};

/** Specific location Address Input for creating IPC project */
export type PcProjectSpecificAddressInput = {
  /** Address Line 1 */
  addressLine1: Scalars['String'];
  /** Address Line 2 */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** City */
  city: Scalars['String'];
  /** Country CODE */
  countryCode: Scalars['String'];
  /** Location notes */
  locationNotes?: InputMaybe<Scalars['String']>;
  /** ZIP/Postal Code */
  postalCode: Scalars['String'];
  /** State / Province Id */
  stateId: Scalars['Int'];
};

/** Page Input object */
export type PageInput = {
  /** Page number */
  pageNumber: Scalars['Int'];
  /** Page size */
  pageSize: Scalars['Int'];
};

/** Paginated array of Custom URL Slug with relevant blocked keyword */
export type PaginatedProhibitedCustomSlug = {
  lastKey?: Maybe<Scalars['String']>;
  objects: Array<ProhibitedCustomSlug>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedSearchArtistMediaResult = {
  artist: Artist;
  objects: Array<Media>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** PaidType object */
export type PaidType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum PaidTypeEnum {
  No = 'NO',
  PayIsDeferred = 'PAY_IS_DEFERRED',
  Unlisted = 'UNLISTED',
  Yes = 'YES',
}

/** Measurement object */
export type Pants = {
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Gender */
  gender: BasicSetting;
  /** Primary ID */
  id: Scalars['Int'];
  /** Size for AU */
  sizeAU: Scalars['String'];
  /** Size for EU */
  sizeEU: Scalars['String'];
  /** Numeric size */
  sizeNumeric: Scalars['Int'];
  /** Size for UK */
  sizeUK: Scalars['String'];
  /** Size for US */
  sizeUS: Scalars['String'];
};

/** Partial BAU account details */
export type PartialBauAccountDetails = {
  accountStatusCode: Scalars['String'];
  partialEmail: Scalars['String'];
  partialPhones: Array<Scalars['String']>;
  projectNames?: Maybe<Array<Scalars['String']>>;
  roles: Array<SystemRole>;
  stageName?: Maybe<Scalars['String']>;
};

export type PartialBauAccountDetailsInput = {
  legacyInstance?: InputMaybe<Scalars['String']>;
  legacyLogin: Scalars['String'];
  migratedAccountID?: InputMaybe<Scalars['Float']>;
};

export enum PayPreferencesEnum {
  No = 'NO',
  PayIsDeferred = 'PAY_IS_DEFERRED',
  Unlisted = 'UNLISTED',
  Yes = 'YES',
}

/** Payment Details */
export type PaymentDetails = {
  cardType?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
};

export enum PaypalLocation {
  Au = 'AU',
  Eu = 'EU',
}

export type PermissionsModel = {
  action: Scalars['String'];
  subject: Scalars['String'];
};

/** Pet object */
export type Pet = {
  /** child objects */
  childObjects: Array<Pet>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Pet>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum PetEnum {
  Bird = 'BIRD',
  Cat = 'CAT',
  Chicken = 'CHICKEN',
  Dog = 'DOG',
  DogAkita = 'DOG_AKITA',
  DogBeagle = 'DOG_BEAGLE',
  DogBorderCollie = 'DOG_BORDER_COLLIE',
  DogBoxer = 'DOG_BOXER',
  DogBulldog = 'DOG_BULLDOG',
  DogCavalierKingCharlesSpaniel = 'DOG_CAVALIER_KING_CHARLES_SPANIEL',
  DogChihuahua = 'DOG_CHIHUAHUA',
  DogDachshund = 'DOG_DACHSHUND',
  DogEnglishCockerSpaniel = 'DOG_ENGLISH_COCKER_SPANIEL',
  DogEnglishSetter = 'DOG_ENGLISH_SETTER',
  DogFrenchBulldog = 'DOG_FRENCH_BULLDOG',
  DogGermanShepherd = 'DOG_GERMAN_SHEPHERD',
  DogGermanSpitz = 'DOG_GERMAN_SPITZ',
  DogGoldenRetriever = 'DOG_GOLDEN_RETRIEVER',
  DogJackRussellTerrier = 'DOG_JACK_RUSSELL_TERRIER',
  DogLabrador = 'DOG_LABRADOR',
  DogMaltese = 'DOG_MALTESE',
  DogMiniatureSchnauzer = 'DOG_MINIATURE_SCHNAUZER',
  DogOther = 'DOG_OTHER',
  DogPitBull = 'DOG_PIT_BULL',
  DogPomeranian = 'DOG_POMERANIAN',
  DogPoodle = 'DOG_POODLE',
  DogPug = 'DOG_PUG',
  DogRottweiler = 'DOG_ROTTWEILER',
  DogShihTzu = 'DOG_SHIH_TZU',
  DogSiberianHusky = 'DOG_SIBERIAN_HUSKY',
  DogUnknownMixed = 'DOG_UNKNOWN_MIXED',
  DogYorkshireTerrier = 'DOG_YORKSHIRE_TERRIER',
  Duck = 'DUCK',
  Fish = 'FISH',
  Goat = 'GOAT',
  Goose = 'GOOSE',
  Hamster = 'HAMSTER',
  Horse = 'HORSE',
  Iguana = 'IGUANA',
  Lizard = 'LIZARD',
  Mouse = 'MOUSE',
  Pig = 'PIG',
  Rabbit = 'RABBIT',
  Reptile = 'REPTILE',
  Rodents = 'RODENTS',
  Snake = 'SNAKE',
  Spider = 'SPIDER',
  Turtle = 'TURTLE',
}

/** PetSize object */
export type PetSize = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type Phone = {
  entityId?: Maybe<Scalars['BigInt']>;
  entityTypeId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneTypeId?: Maybe<Scalars['Int']>;
};

/** Piercing object */
export type Piercing = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for search by piercing attributes */
export type PiercingInput = {
  /** Piercing id list */
  piercingId?: InputMaybe<Array<Scalars['ID']>>;
  /** Piercing description */
  piercingNote?: InputMaybe<Scalars['String']>;
};

export type PlanFeatureModel = {
  code: Scalars['String'];
  credit: Scalars['Int'];
  currency?: Maybe<Currency>;
  isPermanent: Scalars['Boolean'];
  itemCode: Scalars['String'];
  itemId: Scalars['ID'];
  name: Scalars['String'];
  planId: Scalars['ID'];
  price?: Maybe<Scalars['Float']>;
};

/** Subscription Plan */
export type PlanModel = {
  billingCode?: Maybe<Scalars['String']>;
  billingProviderId?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isBasePlan?: Maybe<Scalars['Boolean']>;
  isComped?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  planCurrencyCode?: Maybe<Scalars['String']>;
  planStatusId?: Maybe<Scalars['ID']>;
  planTerm?: Maybe<PlanTermModel>;
  price?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export enum PlanTermEnum {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  Semiannual = 'SEMIANNUAL',
}

export type PlanTermModel = {
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type PreCancelInput = {
  /** The URL to redirect the user to after the retention process is completed */
  callbackUrl: Scalars['String'];
  /** The URL to redirect the user to after the retention process is canceled, default to callbackUrl */
  cancelUrl?: InputMaybe<Scalars['String']>;
  /** The URL to redirect the user to after the retention process was successful, default to callbackUrl */
  saveUrl?: InputMaybe<Scalars['String']>;
  /** The ID of the subscription to be retained */
  subscriptionId: Scalars['Int'];
};

/** Presentation */
export type Presentation = {
  /** Creation Date */
  createdAt: Scalars['DateTime'];
  /** Account of creator */
  createdBy?: Maybe<Account>;
  /** Creator Account ID */
  createdById: Scalars['Int'];
  /** Number of Folders in Presentation */
  foldersCount: Scalars['Int'];
  /** GUID */
  guid: Scalars['ID'];
  /** ID of presentation */
  id: Scalars['Int'];
  isLive: Scalars['Boolean'];
  /** Account of updater */
  linkExpired?: Maybe<Scalars['Boolean']>;
  /** Link expiration date */
  linkExpiresAt?: Maybe<Scalars['DateTime']>;
  /** Name of presentation */
  name: Scalars['String'];
  organization: Organization;
  passwordSet: Scalars['Boolean'];
  /** presentation type */
  project?: Maybe<Project>;
  /** Project ID of presentation */
  projectId?: Maybe<Scalars['Int']>;
  settings?: Maybe<PresentationSettings>;
  /** presentation type */
  type?: Maybe<PresentationType>;
  /** Update Date */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Account of updater */
  updatedBy?: Maybe<Account>;
  /** Updated Account ID */
  updatedById?: Maybe<Scalars['Int']>;
};

export type PresentationFilterData = {
  commentersAccountIds?: Maybe<Array<Scalars['String']>>;
  folders?: Maybe<Array<PresentationFilterFolder>>;
  picksUser?: Maybe<Array<Scalars['String']>>;
  roles?: Maybe<Array<PresentationFilterRole>>;
};

export type PresentationFilterFolder = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PresentationFilterInput = {
  /** Users who made comments on presentation */
  commentersAccountIds?: InputMaybe<Array<Scalars['String']>>;
  /** Users who made pick on presenatation */
  pickUsers?: InputMaybe<Array<Scalars['String']>>;
  presenetationFolderIds?: InputMaybe<Array<Scalars['Int']>>;
  presentationGuid?: InputMaybe<Scalars['String']>;
  presentationId?: InputMaybe<Scalars['Int']>;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  roleNames?: InputMaybe<Array<Scalars['String']>>;
  stageName?: InputMaybe<Scalars['String']>;
};

export type PresentationFilterRole = {
  id?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

/** Presentation Folder */
export type PresentationFolder = {
  /** Gets audition session */
  auditionSession?: Maybe<AuditionSession>;
  /** Date when was created */
  createdAt: Scalars['DateTime'];
  /** Groups Count */
  groupCount: Scalars['Int'];
  /** ID of presentation folder */
  id: Scalars['Int'];
  /** Name of presentation folder */
  name: Scalars['String'];
  /** Order of appearance */
  order: Scalars['Int'];
  /** ID of presentation */
  presentationId: Scalars['Int'];
  /** Submissions Count */
  submissionCount: Scalars['Int'];
  /** Talents Count */
  talentCount: Scalars['Int'];
  /** Date when was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Presentation Folder input type */
export type PresentationFolderInput = {
  /** Name for presentation folder */
  name: Scalars['String'];
  /** Presentation ID */
  presentationId: Scalars['Int'];
};

export type PresentationFolderPublic = {
  /** Gets audition session */
  auditionSession?: Maybe<AuditionSession>;
  /** Date when was created */
  createdAt: Scalars['DateTime'];
  /** Groups Count */
  groupCount: Scalars['Int'];
  groups: Array<PresentationGroupPublic>;
  groupsByFilter: Array<PresentationGroupPublic>;
  /** ID of presentation folder */
  id: Scalars['Int'];
  /** Name of presentation folder */
  name: Scalars['String'];
  /** Order of appearance */
  order: Scalars['Int'];
  presentation: PresentationPublic;
  /** ID of presentation */
  presentationId: Scalars['Int'];
  /** Submissions Count */
  submissionCount: Scalars['Int'];
  /** Talents Count */
  talentCount: Scalars['Int'];
  /** Folder talents of the package presentation */
  talents: Array<PresentationTalentPublic>;
  talentsWithPicksCount: Scalars['Int'];
  /** Date when was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PresentationFolderPublicGroupsArgs = {
  hasPicks?: InputMaybe<Scalars['Boolean']>;
};

export type PresentationFolderPublicGroupsByFilterArgs = {
  input: PresentationFilterInput;
};

export type PresentationFolderPublicTalentsArgs = {
  pageInput?: InputMaybe<PageInput>;
};

/** Presentation Folder Talent */
export type PresentationFolderTalent = {
  /** Total count of comments for the talent */
  commentCount: Scalars['Int'];
  /** Retrieves cover for profile in the package folder. Based on customization and profile cover */
  cover?: Maybe<Media>;
  /** ID of the presentation folder */
  folderId: Scalars['Int'];
  /** ID of the presentation group */
  groupId: Scalars['Int'];
  id: Scalars['ID'];
  /** Total count of picks for the talent */
  pickCount: Scalars['Int'];
  pickedBy?: Maybe<Array<ProfilePick>>;
  profile?: Maybe<Profile>;
  /** Retrieves profile customizations in the package folder */
  profileCustomization?: Maybe<PresentationFolderTalentCustomization>;
};

export type PresentationFolderTalentCustomization = {
  id: Scalars['Int'];
  isOverscale?: Maybe<Scalars['Boolean']>;
  /** Retrieves profile's customized media */
  media?: Maybe<Array<PresentationFolderTalentMediaCustomization>>;
  note?: Maybe<Scalars['String']>;
  overscaleNote?: Maybe<Scalars['String']>;
};

export type PresentationFolderTalentCustomizedMediaInput = {
  /** Is media primary? (Primary photo, video reel or audio reel) */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** Media Id */
  mediaId: Scalars['ID'];
  /** Customize media file name */
  name?: InputMaybe<Scalars['String']>;
  /** Media order of appearance */
  order?: InputMaybe<Scalars['Int']>;
};

export type PresentationFolderTalentMediaCustomization = {
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  media: Media;
  mediaId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** Presentation Folder Talent Presence */
export type PresentationFolderTalentPresence = {
  /** Presentation Folder Id */
  folderId: Scalars['Float'];
  /** Presentation Folder Name */
  folderName: Scalars['String'];
};

/** Presentation Folder Update input type */
export type PresentationFolderUpdateInput = {
  /** Name for presentation folder */
  name: Scalars['String'];
  /** Presentation Folder ID */
  presentationFolderId: Scalars['Int'];
};

/** Presentation Group */
export type PresentationGroup = {
  cover?: Maybe<Media>;
  /** Date when created */
  createdAt: Scalars['DateTime'];
  /** ID of presentation group */
  id: Scalars['Int'];
  media?: Maybe<Array<PresentationGroupMedia>>;
  mediaCount?: Maybe<Array<MediaCount>>;
  /** Name of presentation group */
  name?: Maybe<Scalars['String']>;
  /** Order of appearance */
  order: Scalars['Int'];
  presentation: Presentation;
  presentationFolder: PresentationFolder;
  /** Stage names of added talents */
  stageNames?: Maybe<Array<Scalars['String']>>;
  submissionIds: Array<Scalars['BigInt']>;
  /** Count of added talents */
  talentCount?: Maybe<Scalars['Int']>;
  /** Date when updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Presentation Group */
export type PresentationGroupMediaArgs = {
  mediaType?: InputMaybe<Scalars['String']>;
};

/** Represent relation between Presentation Group and Media */
export type PresentationGroupMedia = {
  id: Scalars['Int'];
  /** Media object */
  media: Media;
  /** Id of media */
  mediaId: Scalars['Int'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Order of appearance */
  order: Scalars['Int'];
};

export type PresentationGroupPublic = {
  cover?: Maybe<Media>;
  /** Date when created */
  createdAt: Scalars['DateTime'];
  /** ID of presentation group */
  id: Scalars['Int'];
  media?: Maybe<Array<PresentationGroupMedia>>;
  mediaCount?: Maybe<Array<MediaCount>>;
  /** Name of presentation group */
  name?: Maybe<Scalars['String']>;
  /** Order of appearance */
  order: Scalars['Int'];
  presentation?: Maybe<PresentationPublic>;
  presentationFolder?: Maybe<PresentationFolderPublic>;
  /** Stage names of added talents */
  stageNames?: Maybe<Array<Scalars['String']>>;
  submissionIds?: Maybe<Array<Scalars['BigInt']>>;
  submissions: Array<PresentationSubmissionPublic>;
  /** Count of added talents */
  talentCount?: Maybe<Scalars['Int']>;
  talents: Array<PresentationTalentPublic>;
  /** Date when updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PresentationGroupPublicMediaArgs = {
  mediaType?: InputMaybe<Scalars['String']>;
};

export type PresentationGroupPublicSubmissionsArgs = {
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
};

export type PresentationGroupPublicTalentsArgs = {
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
  userName?: InputMaybe<Scalars['String']>;
};

/** Presentation Group Submission */
export type PresentationGroupSubmission = {
  /** Total count of comments for the submission */
  commentCount: Scalars['Int'];
  /** Submission cover */
  cover?: Maybe<Media>;
  /** ID of the presentation group */
  groupId: Scalars['Int'];
  /** ID of connection between presentation group and submission */
  id: Scalars['Int'];
  /** Total count of picks for the submission */
  pickCount: Scalars['Int'];
  /** Custom presentation group talent role name. */
  roleName?: Maybe<Scalars['String']>;
  submission: Submission;
};

/** Paginated result with [PresentationGroupSubmission] objects */
export type PresentationGroupSubmissionPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of PresentationGroupSubmission objects */
  objects: Array<PresentationGroupSubmission>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Presentation Group Update input type */
export type PresentationGroupUpdateInput = {
  /** Name for presentation group */
  name?: InputMaybe<Scalars['String']>;
  /** Presentation Group ID */
  presentationGroupId: Scalars['Int'];
};

/** Send presentation link email input */
export type PresentationLinkEmailInput = {
  /** Email copy */
  cc?: InputMaybe<Array<Scalars['String']>>;
  /** Message */
  message: Scalars['String'];
  /** Presentation ID */
  presentationId: Scalars['Int'];
  /** Reply to */
  replyTo: Scalars['String'];
  /** Subject */
  subject: Scalars['String'];
  /** Email Recipients */
  to: Array<Scalars['String']>;
};

/** Presentation Log Report Folder Model */
export type PresentationLogFolderModel = {
  id: Scalars['ID'];
  items?: Maybe<Array<PresentationLogGroupModel>>;
  name?: Maybe<Scalars['String']>;
};

/** Presentation Log Report Payload Group Model */
export type PresentationLogGroupModel = {
  id: Scalars['ID'];
  items?: Maybe<Array<PresentationReportTalentModel>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** Presentation Log Report Presentation Model */
export type PresentationLogPresentationModel = {
  id: Scalars['ID'];
  items?: Maybe<Array<PresentationLogFolderModel>>;
  name?: Maybe<Scalars['String']>;
};

/** Presentation Log Report Model */
export type PresentationLogReportModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  displayTalentInfoOptions?: Maybe<Array<DisplayTalentInfoOptionsEnum>>;
  displayTalentPhotoOptions?: Maybe<Array<DisplayTalentPhotoOptionsEnum>>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<PresentationLogReportPayloadModel>;
  presentationFolderIds?: Maybe<Array<Scalars['Int']>>;
  presentationId: Scalars['Int'];
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Presentation Log Report Payload Model */
export type PresentationLogReportPayloadModel = {
  castingCompanyLogo?: Maybe<ReportMediaModel>;
  presentation?: Maybe<PresentationLogPresentationModel>;
  projectName: Scalars['String'];
};

/** Presentation Download Archive Input Type */
export type PresentationMediaDownloadInput = {
  /** GUID of Presentation */
  guid: Scalars['String'];
  /** Presenatation Folder Id */
  presentationFolderId: Scalars['Int'];
  /** Group Id of Presentation */
  presentationGroupId?: InputMaybe<Scalars['Int']>;
  /** Email Id of Unauthenticated User */
  unauthenticatedUserEmail?: InputMaybe<Scalars['String']>;
};

export type PresentationOrganizationPublic = {
  id: Scalars['Int'];
  logo?: Maybe<Media>;
  name?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
};

/** Presentation Pick Report Presentation Model */
export type PresentationPickPresentationModel = {
  id: Scalars['ID'];
  items?: Maybe<Array<PresentationPickRoleModel>>;
  name?: Maybe<Scalars['String']>;
};

/** Presentation Pick Report Model */
export type PresentationPickReportModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<PresentationPickReportPayloadModel>;
  presentationFolderIds?: Maybe<Array<Scalars['Int']>>;
  presentationId: Scalars['Int'];
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Presentation Pick Report Payload Model */
export type PresentationPickReportPayloadModel = {
  castingCompanyLogo?: Maybe<ReportMediaModel>;
  presentation?: Maybe<PresentationPickPresentationModel>;
  projectName: Scalars['String'];
};

/** Presentation Pick Report Role Model */
export type PresentationPickRoleModel = {
  id: Scalars['ID'];
  items?: Maybe<Array<PresentationReportTalentModel>>;
  name?: Maybe<Scalars['String']>;
};

export type PresentationProjectPublic = {
  id: Scalars['Int'];
  internalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type PresentationPublic = {
  /** Creation Date */
  createdAt: Scalars['DateTime'];
  /** Account of creator */
  createdBy?: Maybe<Account>;
  /** Creator Account ID */
  createdById: Scalars['Int'];
  folder?: Maybe<Array<PresentationFolderPublic>>;
  folders: Array<PresentationFolder>;
  /** Number of Folders in Presentation */
  foldersCount: Scalars['Int'];
  /** GUID */
  guid: Scalars['ID'];
  /** ID of presentation */
  id: Scalars['Int'];
  isLive: Scalars['Boolean'];
  /** Account of updater */
  linkExpired?: Maybe<Scalars['Boolean']>;
  /** Link expiration date */
  linkExpiresAt?: Maybe<Scalars['DateTime']>;
  /** Name of presentation */
  name: Scalars['String'];
  organization?: Maybe<PresentationOrganizationPublic>;
  passwordSet: Scalars['Boolean'];
  project?: Maybe<PresentationProjectPublic>;
  /** Project ID of presentation */
  projectId?: Maybe<Scalars['Int']>;
  settings?: Maybe<PresentationSettings>;
  /** presentation type */
  type?: Maybe<PresentationType>;
  /** Update Date */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Account of updater */
  updatedBy?: Maybe<Account>;
  /** Updated Account ID */
  updatedById?: Maybe<Scalars['Int']>;
};

export type PresentationPublicFolderArgs = {
  folderId: Array<Scalars['Int']>;
};

/** Presentation Report Create Input Arguments */
export type PresentationReportCreateInput = {
  clientNamesFilter?: InputMaybe<Array<Scalars['String']>>;
  displayOptions?: InputMaybe<PresentationReportDisplayOptionsModel>;
  presentationFolderIds?: InputMaybe<Array<Scalars['Int']>>;
  presentationId: Scalars['Int'];
};

export type PresentationReportCreatePublicInput = {
  presentationFolderIds?: InputMaybe<Array<Scalars['Int']>>;
  presentationKey: Scalars['ID'];
  publicUserName: Scalars['String'];
};

/** Presentation Report Display Options Model */
export type PresentationReportDisplayOptionsModel = {
  displayOnlyTalentWithPicks?: InputMaybe<Array<DisplayOnlyTalentWithPicksEnum>>;
  displayTalentInfoOptions?: InputMaybe<Array<DisplayTalentInfoOptionsEnum>>;
  displayTalentPerPageOptions?: InputMaybe<DisplayTalentPerPageOptionsEnum>;
  displayTalentPhotoOptions?: InputMaybe<Array<DisplayTalentPhotoOptionsEnum>>;
};

/** Presentation Report Talent Model */
export type PresentationReportTalentModel = {
  agencyContacts?: Maybe<ReportContactInfoModel>;
  cover?: Maybe<ReportMediaModel>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  genderAppearance?: Maybe<Array<SubFieldsGql>>;
  headshotMedia?: Maybe<ReportMediaModel>;
  id: Scalars['Int'];
  isPersonal?: Maybe<Scalars['Boolean']>;
  isRepresented?: Maybe<Scalars['Boolean']>;
  minor?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Array<Scalars['String']>>;
  orgAbbreviation?: Maybe<Scalars['String']>;
  orgLogo?: Maybe<OrganizationLogo>;
  orgName?: Maybe<Scalars['String']>;
  orgParentName?: Maybe<Scalars['String']>;
  orgPhone?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  sizeSheets?: Maybe<PresentationSizeSheetsTalentModel>;
  snapshotMedia?: Maybe<ReportMediaModel>;
  stageName?: Maybe<Scalars['String']>;
  talentEmail?: Maybe<Scalars['String']>;
  talentNote?: Maybe<PresentationReportTalentNoteModel>;
  talentUnions?: Maybe<Array<Scalars['String']>>;
};

/** Presentation Report Talent Note Comment */
export type PresentationReportTalentNoteCommentModel = {
  author: Scalars['String'];
  comment: Scalars['String'];
  id: Scalars['String'];
  submissionId: Scalars['BigInt'];
};

/** Presentation Report Talent Note */
export type PresentationReportTalentNoteModel = {
  comments?: Maybe<Array<PresentationReportTalentNoteCommentModel>>;
  commentsCount?: Maybe<Scalars['Float']>;
  pickedBy?: Maybe<Array<Scalars['String']>>;
  picksCount?: Maybe<Scalars['Float']>;
};

export type PresentationSettings = {
  allowDownloadMedia: Scalars['Boolean'];
  allowExportFormResponse: Scalars['Boolean'];
  leaveFeedback?: Maybe<Scalars['Boolean']>;
  seeOthersFeedback?: Maybe<Scalars['Boolean']>;
};

export type PresentationSettingsInput = {
  allowDownloadMedia?: InputMaybe<Scalars['Boolean']>;
  allowExportFormResponse?: InputMaybe<Scalars['Boolean']>;
  leaveFeedback?: InputMaybe<Scalars['Boolean']>;
  seeOthersFeedback?: InputMaybe<Scalars['Boolean']>;
};

export type PresentationSizeSheetsMetricsModel = {
  cm?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inch?: Maybe<Scalars['String']>;
  inchRounded?: Maybe<Scalars['String']>;
};

export type PresentationSizeSheetsProfileSizeModel = {
  category?: Maybe<SubFieldsGql>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  sizeModifier?: Maybe<SubFieldsGql>;
};

/** Presentation Size Sheets Report Model */
export type PresentationSizeSheetsReportModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  displayOnlyTalentWithPicks?: Maybe<Array<DisplayOnlyTalentWithPicksEnum>>;
  displayTalentInfoOptions?: Maybe<Array<DisplayTalentInfoOptionsEnum>>;
  displayTalentPerPageOptions?: Maybe<DisplayTalentPerPageOptionsEnum>;
  displayTalentPhotoOptions?: Maybe<Array<DisplayTalentPhotoOptionsEnum>>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<PresentationLogReportPayloadModel>;
  presentationFolderIds?: Maybe<Array<Scalars['Int']>>;
  presentationId: Scalars['Int'];
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PresentationSizeSheetsSizeMetricsModel = {
  category?: Maybe<SubFieldsGql>;
  gender?: Maybe<SubFieldsGql>;
  id: Scalars['Int'];
  shoeModifier?: Maybe<SubFieldsGql>;
  sizeAU?: Maybe<Scalars['String']>;
  sizeEU?: Maybe<Scalars['String']>;
  sizeUK?: Maybe<Scalars['String']>;
  sizeUS?: Maybe<Scalars['String']>;
};

/** Presentation Report Talent Model Size Sheets */
export type PresentationSizeSheetsTalentModel = {
  bust?: Maybe<PresentationSizeSheetsMetricsModel>;
  chestSize?: Maybe<PresentationSizeSheetsMetricsModel>;
  coat?: Maybe<Scalars['String']>;
  cupSize?: Maybe<Scalars['String']>;
  dressSize?: Maybe<PresentationSizeSheetsSizeMetricsModel>;
  gloveSize?: Maybe<PresentationSizeSheetsMetricsModel>;
  hatSize?: Maybe<PresentationSizeSheetsMetricsModel>;
  height?: Maybe<PresentationSizeSheetsMetricsModel>;
  hipSize?: Maybe<PresentationSizeSheetsMetricsModel>;
  inseam?: Maybe<PresentationSizeSheetsMetricsModel>;
  neck?: Maybe<PresentationSizeSheetsMetricsModel>;
  profilePants?: Maybe<Array<PresentationSizeSheetsSizeMetricsModel>>;
  profileShoeSizes?: Maybe<Array<PresentationSizeSheetsSizeMetricsModel>>;
  profileSizes?: Maybe<Array<PresentationSizeSheetsProfileSizeModel>>;
  profileTshirtSizes?: Maybe<Array<PresentationSizeSheetsProfileSizeModel>>;
  sleeve?: Maybe<PresentationSizeSheetsMetricsModel>;
  underbust?: Maybe<PresentationSizeSheetsMetricsModel>;
  waist?: Maybe<PresentationSizeSheetsMetricsModel>;
  weight?: Maybe<PresentationSizeSheetsWeightMetricsModel>;
};

export type PresentationSizeSheetsWeightMetricsModel = {
  id: Scalars['Int'];
  kg?: Maybe<Scalars['Float']>;
  lbs?: Maybe<Scalars['Float']>;
};

export type PresentationSubmissionComment = {
  comment: Scalars['String'];
  /** Author's Account ID is exists */
  createdBy?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  /** Presentation Group Talent Comment ID */
  id: Scalars['Int'];
  submissionId: Scalars['BigInt'];
  userName: Scalars['String'];
};

/** Paginated result with [PresentationSubmissionComment] objects */
export type PresentationSubmissionCommentPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of PresentationSubmissionComment objects */
  objects: Array<PresentationSubmissionComment>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type PresentationSubmissionFeedback = {
  commentsCount: Scalars['Int'];
  pickedBy: Array<SubmissionPick>;
};

export type PresentationSubmissionPublic = {
  /** Audition Snapshot Media Assets */
  auditionSnapshot?: Maybe<Media>;
  commentCount?: Maybe<Scalars['Int']>;
  contactInfo?: Maybe<ProfileContactInfo>;
  cover?: Maybe<Media>;
  /** Primary ID */
  id: Scalars['BigInt'];
  /** Media */
  media?: Maybe<Array<SubmissionMedia>>;
  mediaCount?: Maybe<Array<MediaCount>>;
  /** Note */
  note?: Maybe<Scalars['String']>;
  pickCount?: Maybe<Scalars['Int']>;
  pickedBy?: Maybe<Array<TalentPickPublic>>;
  profile?: Maybe<PublicProfile>;
  profileId?: Maybe<Scalars['Int']>;
  /** Profile Working Location ID */
  profileWorkingLocationId?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
  /** Requested Media */
  requestedMedia?: Maybe<Array<SubmissionMedia>>;
  roleId?: Maybe<Scalars['Int']>;
  roleName?: Maybe<Scalars['String']>;
  /** Customized Profile Working Location */
  workingLocation?: Maybe<ProfileWorkingLocation>;
};

export type PresentationTalentPublic = {
  commentCount?: Maybe<Scalars['Int']>;
  cover?: Maybe<Media>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  groupId?: Maybe<Scalars['Int']>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  minor?: Maybe<Scalars['Boolean']>;
  pickCount?: Maybe<Scalars['Int']>;
  pickedBy?: Maybe<Array<TalentPickPublic>>;
  projectGuid?: Maybe<Scalars['String']>;
  roleGuid?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
  submissionId?: Maybe<Scalars['BigInt']>;
  talentUnions?: Maybe<Array<TalentUnion>>;
};

/** Paginated result with [PresentationTalentPublic] objects */
export type PresentationTalentPublicPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of PresentationTalentPublic objects */
  objects: Array<PresentationTalentPublic>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** PresentationType object */
export type PresentationType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum PresentationTypeEnum {
  AuditionPresentation = 'AUDITION_PRESENTATION',
  CastingPackage = 'CASTING_PACKAGE',
  RepPackage = 'REP_PACKAGE',
  SendMediaToCastit = 'SEND_MEDIA_TO_CASTIT',
  SharedSelects = 'SHARED_SELECTS',
}

/** Presentation Update input type */
export type PresentationUpdateInput = {
  /** Date of link expiration */
  linkExpiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Name for presentation */
  name?: InputMaybe<Scalars['String']>;
  /** Password */
  password?: InputMaybe<Scalars['String']>;
  /** Presentation ID */
  presentationId: Scalars['Int'];
  settings?: InputMaybe<PresentationSettingsInput>;
};

export type PreviewSubscriptionInput = {
  billingPath?: InputMaybe<Scalars['String']>;
  couponCode?: InputMaybe<Scalars['String']>;
  subscriptionBillingCode: Scalars['String'];
};

export type PreviewSubscriptionRenewalInput = {
  billingInfoId: Scalars['String'];
  subscriptionId: Scalars['Int'];
};

/** Talent Profile - May be associated with Account */
export type Profile = {
  /** Checks if profile have granted access to roster */
  accessGranted: Scalars['Boolean'];
  accountId?: Maybe<Scalars['Float']>;
  ageInYears?: Maybe<Scalars['Boolean']>;
  /** Profile Artist ID */
  artistId?: Maybe<Scalars['Float']>;
  /** Profile audio assets */
  audios?: Maybe<Array<ProfileMedia>>;
  billingFeatures?: Maybe<Array<ArtistBillingFeatureModel>>;
  /** @deprecated Use 'billingFeatures' instead. ID will now return 0. */
  billingItems?: Maybe<Array<BillingItemModel>>;
  /** Blurb */
  blurb?: Maybe<Scalars['String']>;
  /** Bust Object */
  bust?: Maybe<Bust>;
  /** Chest size Object */
  chestSize?: Maybe<ChestSize>;
  /** Coat Object */
  coat?: Maybe<Coat>;
  /** Conflicts for the profile */
  conflicts: Array<ProfileConflict>;
  /** Dynamic field. Based on profile status and representative type */
  contactInfo?: Maybe<ProfileContactInfo>;
  contactedByRep?: Maybe<Array<ProfileContactedByRep>>;
  cover?: Maybe<Media>;
  /** Created at Date */
  created?: Maybe<Scalars['DateTime']>;
  /** Profile Credits */
  credits: Array<Credit>;
  /** Profile Credits Count */
  creditsCount: Scalars['Int'];
  /** Cup size Object */
  cupSize?: Maybe<CupSize>;
  customSlug?: Maybe<CustomSlug>;
  /** Date of birth */
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** stores/holds the id of the person who has deactivated the profile */
  deactivatedBy?: Maybe<Scalars['Float']>;
  /** Default submission note */
  defaultSubmissionNote?: Maybe<Scalars['String']>;
  /** Document type(s) */
  documentTypes?: Maybe<Array<Document>>;
  /** Profile document assets */
  documents?: Maybe<Array<ProfileMedia>>;
  /** Dress size Object */
  dressSize?: Maybe<ClothesSize>;
  /** Ethnic Appearance(s) */
  ethnicAppearances?: Maybe<Array<ProfileEthnicAppearance>>;
  /** Eye Color Object */
  eyeColor?: Maybe<EyeColor>;
  /** Facial Hair Type(s) */
  facialHair?: Maybe<Array<FacialHair>>;
  /** Gender Appearance(s) */
  genderAppearances?: Maybe<Array<GenderAppearance>>;
  /** Glove size Object */
  gloveSize?: Maybe<Glove>;
  /** GUID */
  guid?: Maybe<Scalars['String']>;
  /** Hair Color Object */
  hairColor?: Maybe<HairColor>;
  /** Hair Style Object */
  hairStyle?: Maybe<HairStyle>;
  /** Profile has audio reel */
  hasAudioReel?: Maybe<Scalars['Boolean']>;
  hasAuditionHistory: Scalars['Boolean'];
  /** has Representation */
  hasRepresentation?: Maybe<Scalars['Boolean']>;
  /** Profile has resume */
  hasResume?: Maybe<Scalars['Boolean']>;
  /** Profile has video reel */
  hasVideoReel?: Maybe<Scalars['Boolean']>;
  /** Hat size Object */
  hatSize?: Maybe<HatSize>;
  /** Height Object */
  height?: Maybe<Height>;
  /** Hide credit year */
  hideCreditYear: Scalars['Boolean'];
  /** Hip size Object */
  hipSize?: Maybe<Hip>;
  /** Primary ID */
  id: Scalars['ID'];
  /** Inseam size Object */
  inseam?: Maybe<Inseam>;
  isActive: Scalars['Boolean'];
  /**
   * Returns true if profile was deactivated by a Rep user, returns false if
   * profile was deactivated by a Talent, returns null when there is no information
   * about the deactivating account
   */
  isDeactivatedByReps?: Maybe<Scalars['Boolean']>;
  isDropped: Scalars['Boolean'];
  isInCastingList: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  /** If Profile is overscaled */
  isOverscaled: Scalars['Boolean'];
  /** Identify the personal or represented profile. */
  isPersonal: Scalars['Boolean'];
  isPlayableAgeLockedToTalent: Scalars['Boolean'];
  /** Identify represented profile */
  isRepresented: Scalars['Boolean'];
  /** Is talent seeking representation */
  isSeekingRepresentation?: Maybe<Scalars['Boolean']>;
  /** Last note for the profile */
  lastNote?: Maybe<Note>;
  /** Luminate profile Data */
  luminateProfile?: Maybe<LuminateProfile>;
  /** Manager Email */
  managerEmail?: Maybe<Scalars['String']>;
  /** All profile media */
  mediaAssets?: Maybe<Array<ProfileMedia>>;
  /** Profile's primary photo */
  mediaSummary: Array<MediaCount>;
  /** Is minor */
  minor?: Maybe<Scalars['Boolean']>;
  /** Multiple Birth Object */
  multipleBirth?: Maybe<MultipleBirth>;
  /** Neck size Object */
  neck?: Maybe<Neck>;
  /** Note */
  note?: Maybe<Scalars['String']>;
  /** Note to Representative */
  noteToRepresentative?: Maybe<Scalars['String']>;
  /** Notes for the profile */
  notes: Array<Note>;
  organization?: Maybe<Organization>;
  organizations?: Maybe<Array<Organization>>;
  /** Other experiences */
  otherExperiences?: Maybe<Scalars['String']>;
  /** Overscale Note */
  overscaleNote?: Maybe<Scalars['String']>;
  /** Parent Name */
  parent?: Maybe<Scalars['String']>;
  /** Profile phone */
  phone?: Maybe<Scalars['String']>;
  /** Profile photo assets */
  photos?: Maybe<Array<ProfileMedia>>;
  /** Note about piercing */
  piercingNote?: Maybe<Scalars['String']>;
  /** The age a talent can play */
  playableAge?: Maybe<Scalars['Float']>;
  /** The minimum age a talent can play */
  playableAgeMax?: Maybe<Scalars['Float']>;
  /** The maximum age a talent can play */
  playableAgeMin?: Maybe<Scalars['Float']>;
  /**
   * Date of pregnancy
   * @deprecated This field is deprecated and will be deleted once the compatible changes at the front end will be added everyewhere for pregnancyDue reference. Use pregnancyDue instead
   */
  pregnancy?: Maybe<Scalars['DateTime']>;
  /** Date of pregnancy */
  pregnancyDue?: Maybe<Scalars['DateTime']>;
  /** Profession */
  profession?: Maybe<Scalars['String']>;
  /** Access Token of a Profile, generated by Granting Access or Creating a Profile */
  profileAccessToken?: Maybe<Scalars['String']>;
  /** ProfileAccessToken created date */
  profileAccessTokenCreated?: Maybe<Scalars['DateTime']>;
  /** Profile Account System Role */
  profileAccountRole?: Maybe<Scalars['String']>;
  /** Get profile bookouts */
  profileBookOuts: Array<ProfileBookOuts>;
  /** Gender Identities */
  profileGenderIdentities?: Maybe<Array<ProfileGenderIdentity>>;
  /** Primary ID */
  profileId: Scalars['ID'];
  /** The Profile List ids where current profile participated */
  profileLists: Array<Scalars['Int']>;
  profileMainDivision?: Maybe<Organization>;
  profileMainOrganization?: Maybe<Organization>;
  /** media assets */
  profileMedia?: Maybe<Array<ProfileMedia>>;
  /** Profile Pants */
  profilePants: Array<Pants>;
  /** Profile pets */
  profilePets: Array<ProfilePet>;
  /** Profile piercings */
  profilePiercings: Array<Piercing>;
  /** Profile shoe sizes */
  profileShoeSizes: Array<ProfileShoeSize>;
  /** Profile general size */
  profileSizes: Array<ProfileSize>;
  /** Profile Status Object */
  profileStatus?: Maybe<ProfileStatus>;
  profileStatusId?: Maybe<Scalars['Int']>;
  /** Profile TshirtSize */
  profileTshirtSizes: Array<TShirtSize>;
  /** Profile Type */
  profileType?: Maybe<ProfileType>;
  /** Profile vehicles */
  profileVehicles: Array<ProfileVehicle>;
  /** profileVisibility Object */
  profileVisiblities?: Maybe<Array<ProfileVisibility>>;
  /** Wardrobes */
  profileWardrobes?: Maybe<Array<ProfileWardrobe>>;
  /** Project Type */
  projectType?: Maybe<Scalars['String']>;
  /** Prop Type(s) */
  props?: Maybe<Array<ProfileProp>>;
  /** Job Persuing Type(s) */
  pursuing?: Maybe<Array<Pursuing>>;
  /** User's resume document */
  resume?: Maybe<ProfileMedia>;
  /** Roster hidden */
  rosterHidden?: Maybe<Scalars['Boolean']>;
  /** Search hidden */
  searchHidden?: Maybe<Scalars['Boolean']>;
  /** SeekingRepresentation Object */
  seekingRepresentations?: Maybe<Array<SeekingRepresentation>>;
  selectionType: ProfileSelectionType;
  /** Skills and their Levels */
  skills?: Maybe<Array<ProfileSkill>>;
  /** Sleeve Object */
  sleeve?: Maybe<Sleeve>;
  /** Sport equipments */
  sportEquipments?: Maybe<Array<ProfileSportEquipment>>;
  /** Stage Name */
  stageName: Scalars['String'];
  /** Returns submission */
  submission?: Maybe<Submission>;
  /** Submission request's media assets */
  submissionRequestMedia: Array<SubmissionMedia>;
  /** talent's contacts regardless representative status */
  talentContactInfo?: Maybe<ProfileContactInfo>;
  /** Profile email */
  talentEmail?: Maybe<Scalars['String']>;
  talentFolders?: Maybe<Array<PresentationFolderTalentPresence>>;
  /** Talent unions */
  talentUnions?: Maybe<Array<TalentProfileUnion>>;
  /** Profile tattoo */
  tattoo?: Maybe<Tattoo>;
  /** Tattoo Note */
  tattooNote?: Maybe<Scalars['String']>;
  /** Underbust size Object */
  underbust?: Maybe<UnderBust>;
  /** Unique Attribute Type(s) */
  uniqueAttributes?: Maybe<Array<UniqueAttribute>>;
  /** Updated at Date */
  updated?: Maybe<Scalars['DateTime']>;
  /** Profile video assets */
  videos?: Maybe<Array<ProfileMedia>>;
  /** Waist Object */
  waist?: Maybe<Waist>;
  /** Weight Object */
  weight?: Maybe<Weight>;
  /** Willingness Objects */
  willingness: Array<Willingness>;
  /** Work permit expiration date */
  workPermitExp?: Maybe<Scalars['DateTime']>;
  /** Working Locations */
  workingLocations?: Maybe<Array<ProfileWorkingLocation>>;
};

/** Talent Profile - May be associated with Account */
export type ProfileHasAuditionHistoryArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};

/** Talent Profile - May be associated with Account */
export type ProfileIsInCastingListArgs = {
  listId: Scalars['ID'];
};

/** Talent Profile - May be associated with Account */
export type ProfilePhotosArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};

/** Talent Profile - May be associated with Account */
export type ProfileSelectionTypeArgs = {
  roleId: Scalars['Float'];
};

/** Talent Profile - May be associated with Account */
export type ProfileSubmissionArgs = {
  roleId: Scalars['Float'];
};

/** Talent Profile - May be associated with Account */
export type ProfileSubmissionRequestMediaArgs = {
  roleId: Scalars['Float'];
};

/** Talent Profile - May be associated with Account */
export type ProfileTalentFoldersArgs = {
  folderId: Scalars['Int'];
};

/** Profile Access Token */
export type ProfileAccessToken = {
  /** Artist Id */
  artistId: Scalars['Float'];
  /** Organization Id */
  organizationId: Scalars['Float'];
  /** ProfileAccessToken created date */
  profileAccessTokenCreated?: Maybe<Scalars['DateTime']>;
  /** Profile Id */
  profileId: Scalars['Float'];
};

/** Input type for Profile Book Out dates */
export type ProfileBookOutInput = {
  /** End Date */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** Profile Book Out Id */
  profileBookoutId?: InputMaybe<Scalars['Float']>;
  /** Profile Id */
  profileId?: InputMaybe<Scalars['Float']>;
  /** Start Date */
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type ProfileBookOuts = {
  /** End Date */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Profile Id */
  id?: Maybe<Scalars['ID']>;
  /** Profile Book Out Id */
  profileBookoutId?: Maybe<Scalars['Int']>;
  /** Profile Id */
  profileId?: Maybe<Scalars['Int']>;
  /** Start Date */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type ProfileConflict = {
  code?: Maybe<Scalars['String']>;
  conflictId?: Maybe<Scalars['Float']>;
  expiration?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  profileConflictId?: Maybe<Scalars['Float']>;
  profileId?: Maybe<Scalars['Float']>;
};

export type ProfileContactInfo = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ProfileContactedByRep = {
  createdAt?: Maybe<Scalars['DateTime']>;
  profileId?: Maybe<Scalars['Float']>;
};

/** Input type for Create profile */
export type ProfileCreateInput = {
  /** for linking Profile to account not from current context */
  accountId?: InputMaybe<Scalars['Float']>;
  /** for linking Profile to an existing artist */
  artistId?: InputMaybe<Scalars['Float']>;
  /** Date of birth */
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  /** First Name */
  firstName?: InputMaybe<Scalars['String']>;
  /** Invitation token from Roster Invitation Request */
  invitationToken?: InputMaybe<Scalars['String']>;
  /** Last Name */
  lastName?: InputMaybe<Scalars['String']>;
  /** is minor */
  minor?: InputMaybe<Scalars['Boolean']>;
  /** minor date of birth */
  minorDateOfBirth?: InputMaybe<Scalars['DateTime']>;
  /** Note */
  note?: InputMaybe<Scalars['String']>;
  /** Organization Id */
  organizationId?: InputMaybe<Scalars['Float']>;
  /** Profile access token granted by Rep */
  profileAccessToken?: InputMaybe<Scalars['String']>;
  /** profile status code */
  profileStatusCode?: InputMaybe<Scalars['String']>;
  /** profileStatusId */
  profileStatusId?: InputMaybe<Scalars['Int']>;
  /** Profile Type code */
  profileTypeCode?: InputMaybe<Scalars['String']>;
  /** Send Email */
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  /** Stage Name */
  stageName: Scalars['String'];
  /** Talent Email */
  talentEmail?: InputMaybe<Scalars['String']>;
};

/** Context object for Profile Direct Message */
export type ProfileDirectMessageContextAndProfile = {
  organizationDivision: Scalars['String'];
  organizationLogo: Scalars['String'];
  project?: Maybe<ProfileDirectMessageProjectContext>;
  role?: Maybe<ProfileDirectMessageRoleContext>;
};

/** Profile Direct Message Context input */
export type ProfileDirectMessageContextInput = {
  roleId?: InputMaybe<Scalars['Int']>;
  senderOrgId: Scalars['Int'];
};

/** SubContext object for Project in Profile Direct Message context */
export type ProfileDirectMessageProjectContext = {
  castingCompanyName: Scalars['String'];
  name: Scalars['String'];
};

/** SubContext object for Role in Profile Direct Message context */
export type ProfileDirectMessageRoleContext = {
  name: Scalars['String'];
};

/** Profile Gender Identity */
export type ProfileEthnicAppearance = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Order of appearance */
  order?: Maybe<Scalars['Float']>;
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type ProfileFilter = {
  id: Scalars['Int'];
  stageName: Scalars['String'];
};

/** All possible way for creating personal profile */
export enum ProfileFromEnum {
  New = 'NEW',
  None = 'NONE',
  Rep = 'REP',
  Unrep = 'UNREP',
}

/** Profile Gender Identity */
export type ProfileGenderIdentity = {
  /** Profile Gender Identity */
  genderIdentity: GenderIdentity;
  /** Profile Gender Other Identity Note */
  genderOtherIdentityNote?: Maybe<Scalars['String']>;
};

export type ProfileGqlModel = {
  stageName?: Maybe<Scalars['String']>;
  submissionPhotoUrl?: Maybe<Scalars['String']>;
};

/** Profile Ids by Account */
export type ProfileIdsByAccount = {
  /** AccountId */
  accountId?: Maybe<Scalars['Float']>;
  /** Profile ID */
  profileId?: Maybe<Scalars['Float']>;
};

/** Update or Create B21 Profile from BAU */
export type ProfileInput = {
  /** Artist ID */
  artistId?: InputMaybe<Scalars['Int']>;
  /** Profile max playable age */
  maxPlayableAge: Scalars['Int'];
  /** Profile min playable age */
  minPlayableAge: Scalars['Int'];
  /** Profile primary appearance ID */
  primaryAppearanceId: Scalars['Int'];
  /** Profile primary location ID */
  primaryLocationId: Scalars['Int'];
  /** Profile ID */
  profileId: Scalars['Int'];
};

/** Profile List Details */
export type ProfileList = {
  /** List creation date */
  created: Scalars['DateTime'];
  /** List's Creator Account */
  createdBy?: Maybe<Account>;
  /** List ID */
  id: Scalars['ID'];
  /** List Type Object */
  listType?: Maybe<ListType>;
  /** Account of last list editor */
  modifiedBy?: Maybe<Account>;
  /** List Name */
  name: Scalars['String'];
  /** Organization of represe */
  organization?: Maybe<Organization>;
  /** Organization ID */
  organizationId?: Maybe<Scalars['Int']>;
  profiles: ProfileSearch;
  /** Total count of profiles */
  totalProfiles: Scalars['Float'];
  /** Last list updated date */
  updated?: Maybe<Scalars['DateTime']>;
};

/** Profile List Details */
export type ProfileListProfilesArgs = {
  page: PageInput;
  profileSearchInput?: InputMaybe<ProfileSearchInput>;
};

/** Profile List Details */
export type ProfileListTotalProfilesArgs = {
  profileStatusToSearch?: InputMaybe<ProfileStatusSearchEnum>;
};

/** Paginated result with [ProfileList] objects */
export type ProfileListPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of ProfileList objects */
  objects: Array<ProfileList>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Profile Media */
export type ProfileMedia = {
  created: Scalars['DateTime'];
  /**
   * Deprecated
   * @deprecated This field is redundant as profile media visibility depends only on existence in profile_media table.
   */
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  media: Media;
  order: Scalars['Float'];
  /** @deprecated See the B21-2790: As for the photosCount field, it shouldn't be there at all. Profile photos count should be returned in a different place. */
  photosCount?: Maybe<Scalars['Int']>;
  profile: Profile;
  profileMediaId: Scalars['Float'];
};

/** Profile Media */
export type ProfileMediaInput = {
  /** Is media hidden on profile? */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** Is media primary? (Primary photo, video reel or audio reel) */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** Media order of appearance */
  order?: InputMaybe<Scalars['Int']>;
  /** Profile Media Id */
  profileMediaId: Scalars['ID'];
};

/** Profile pet */
export type ProfilePet = {
  /** child objects */
  childObjects: Array<Pet>;
  /** code */
  code: Scalars['String'];
  /** Count */
  count?: Maybe<Scalars['Float']>;
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Pet>;
  /** Pet Size */
  petSize?: Maybe<PetSize>;
  /** Profile pet relation id */
  profilePetId?: Maybe<Scalars['Float']>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for Profile Pet */
export type ProfilePetInput = {
  /** Pet count */
  count: Scalars['Int'];
  /** Pet Id */
  petId: Scalars['Int'];
  /** Pet Size Id */
  petSizeId?: InputMaybe<Scalars['Int']>;
};

export type ProfilePick = {
  /** ID Pick */
  id: Scalars['Int'];
  userName: Scalars['String'];
};

/** Profile prop */
export type ProfileProp = {
  /** child objects */
  childObjects: Array<Prop>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Prop>;
  /** Profile prop relation id */
  profilePropId?: Maybe<Scalars['Float']>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Profile search object */
export type ProfileSearch = {
  facets?: Maybe<Array<Facet>>;
  /** Search profile list */
  profiles?: Maybe<Array<Profile>>;
  /** Total number of profiles */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Search input */
export type ProfileSearchInput = {
  /** blurb */
  blurb?: InputMaybe<Scalars['ID']>;
  /** Range of IDs for bust size */
  bustId?: InputMaybe<IdRangeInput>;
  /** Range of IDs for chest size */
  chestSizeId?: InputMaybe<IdRangeInput>;
  /** Range for Coat Ids */
  coatId?: InputMaybe<IdRangeInput>;
  /** Range of IDs for cup size */
  cupSizeId?: InputMaybe<IdRangeInput>;
  /** Document Ids */
  documentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Range of IDs for dress size */
  dressId?: InputMaybe<IdRangeInput>;
  /** Enables search by luminate profiles, false by default */
  enableLuminate?: InputMaybe<Scalars['Boolean']>;
  /** Ethninc Appearance Ids */
  ethnicAppearanceIds?: InputMaybe<Array<Scalars['ID']>>;
  ethnicAppearances?: InputMaybe<Array<EthnicAppearanceEnum>>;
  excludeBookOutDates?: InputMaybe<DateRangeInput>;
  excludeConflictIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Representative Profile List Ids - search profile/s if it SHOULD NOT be included into Reps Profile List */
  excludeProfileListIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Work Unions Ids which should be excluded from the search */
  excludeTalentUnionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Work Unions Ids which should be excluded from the search */
  excludeTalentUnions?: InputMaybe<Array<TalentUnionEnum>>;
  /** Eye Color Id */
  eyeColorId?: InputMaybe<Array<Scalars['ID']>>;
  /** Facial Hair Ids */
  facialHairIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Gender Appearance Ids */
  genderAppearanceIds?: InputMaybe<Array<Scalars['ID']>>;
  genderAppearances?: InputMaybe<Array<GenderAppearanceEnum>>;
  /** Gender Identity Codes */
  genderIdentities?: InputMaybe<Array<GenderIdentityEnum>>;
  /** Gender Identity Ids */
  genderIdentityIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Range for Glove Size Ids */
  gloveSizeId?: InputMaybe<IdRangeInput>;
  /** Hair Color Id */
  hairColorId?: InputMaybe<Array<Scalars['ID']>>;
  /** Hair Style Id */
  hairStyleId?: InputMaybe<Array<Scalars['ID']>>;
  /** If set TRUE, will return only Profiles with Profile Pictures */
  hasCover?: InputMaybe<Scalars['Boolean']>;
  /** Has Representation */
  hasRepresentation?: InputMaybe<Scalars['Boolean']>;
  /** Range of Hat Size Ids */
  hatSizeId?: InputMaybe<IdRangeInput>;
  /** Height Range by Ids */
  heightRange?: InputMaybe<IdRangeInput>;
  /** Range of IDs for hip size */
  hipSizeId?: InputMaybe<IdRangeInput>;
  /** Range for Inseam size Ids */
  inseamId?: InputMaybe<IdRangeInput>;
  /** New profiles */
  isNew?: InputMaybe<Scalars['Boolean']>;
  /** Search by minor's age */
  minorAge?: InputMaybe<AgeRangeInput>;
  /** Multiple Birth Id */
  multipleBirthId?: InputMaybe<Array<Scalars['ID']>>;
  /** Range for Neck Ids */
  neckId?: InputMaybe<IdRangeInput>;
  /** Organization ID */
  organizationId?: InputMaybe<Scalars['Float']>;
  /** Other Experiences */
  otherExperiences?: InputMaybe<Scalars['String']>;
  /** Range of IDs for pants size */
  pantsId?: InputMaybe<IdRangeInput>;
  /** Pet Ids */
  petIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Pet Size Id */
  petSizeId?: InputMaybe<Scalars['Int']>;
  /** Piercing search by list of id and/or description */
  piercing?: InputMaybe<PiercingInput>;
  /** Playable Age Range */
  playableAge?: InputMaybe<AgeRangeInput>;
  /** Date Range */
  pregnancyDueRange?: InputMaybe<DateRangeInput>;
  /** Profession */
  profession?: InputMaybe<Scalars['String']>;
  /** Profile Ids to search in */
  profileIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Representative Profile List Ids - search profile/s if it included into Reps Profile List */
  profileListIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Profile Status Id */
  profileStatusId?: InputMaybe<Array<Scalars['ID']>>;
  /** Search By Profile Statuses */
  profileStatusToSearch?: InputMaybe<ProfileStatusSearchEnum>;
  /** Search by profile types */
  profileTypeCodes?: InputMaybe<Array<ProfileTypeEnum>>;
  /** Search by profile type */
  profileTypeId?: InputMaybe<Scalars['Int']>;
  /** Prop Id */
  propIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Pursuing Ids */
  pursuingIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Relationship To Minor Id */
  relationshipToMinorId?: InputMaybe<Array<Scalars['ID']>>;
  representationType?: InputMaybe<Array<ProfileSearchRepresentationType>>;
  /** Representative Ids */
  representativeIds?: InputMaybe<Array<Scalars['Int']>>;
  /** List Ids */
  representativeListIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Representation Ids */
  seekingRepresentations?: InputMaybe<Array<Scalars['ID']>>;
  /** Shoe Modifier ID */
  shoeModifierId?: InputMaybe<Scalars['ID']>;
  /** Range of Shoe Size IDs */
  shoeSizeId?: InputMaybe<IdRangeInput>;
  /** Range of General Size IDs */
  sizeId?: InputMaybe<IdRangeInput>;
  /** Size Modifier ID */
  sizeModifierId?: InputMaybe<Scalars['ID']>;
  skillOperator?: InputMaybe<FilterOperator>;
  /** Search by skill ids and their level ids */
  skills?: InputMaybe<Array<SkillSearchInput>>;
  /** Range for Sleeve Ids */
  sleeveId?: InputMaybe<IdRangeInput>;
  /** Search by Sport Equipment IDs */
  sportEquipmentIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Stage name */
  stageName?: InputMaybe<Scalars['String']>;
  /** Work Unions Ids */
  talentUnionIds?: InputMaybe<Array<Scalars['ID']>>;
  talentUnions?: InputMaybe<Array<TalentUnionEnum>>;
  /** Tattoo search by list of id and/or description */
  tattoo?: InputMaybe<TattooInput>;
  /** Range for t-shirt Size Ids */
  tshirtSizeId?: InputMaybe<IdRangeInput>;
  /** Range of IDs for underbust size */
  underbustId?: InputMaybe<IdRangeInput>;
  /** Unique Attribute Ids */
  uniqueAttributeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Search by vehicle object */
  vehicle?: InputMaybe<VehicleSearchInput>;
  /** Range for Waist Ids */
  waistId?: InputMaybe<IdRangeInput>;
  /** Wardrobe Ids */
  wardrobeIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Weight Range by IDs */
  weightRange?: InputMaybe<IdRangeInput>;
  /** Willingness Ids */
  willingnessIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Work permit expiration Range */
  workPermitExpRange?: InputMaybe<DateRangeInput>;
  /** Working Location Ids */
  workingLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** Profile search representation type */
export enum ProfileSearchRepresentationType {
  Luminate = 'Luminate',
  Represented = 'Represented',
  Unrepresented = 'Unrepresented',
}

/** Project by org id search result */
export type ProfileSearchResultByOrgId = {
  /** Profile Id */
  id?: Maybe<Scalars['Int']>;
  /** Profile stage name */
  stageName?: Maybe<Scalars['String']>;
};

/** Paginated result with [ProfileSearchResultByOrgId] objects */
export type ProfileSearchResultByOrgIdPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of ProfileSearchResultByOrgId objects */
  objects: Array<ProfileSearchResultByOrgId>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Profile sort field options */
export enum ProfileSearchSortField {
  FirstName = 'FirstName',
  LastName = 'LastName',
  Random = 'Random',
  Relevance = 'Relevance',
}

export type ProfileSearchSortOption = {
  fieldName: ProfileSearchSortField;
  /** Default value: ASC */
  sortOrder?: InputMaybe<SortOrderEnum>;
};

export enum ProfileSelectionType {
  None = 'NONE',
  Selected = 'SELECTED',
  SelectedForOtherRoles = 'SELECTED_FOR_OTHER_ROLES',
  Submitted = 'SUBMITTED',
  SubmittedForOtherRoles = 'SUBMITTED_FOR_OTHER_ROLES',
}

/** Profile Shoe Size */
export type ProfileShoeSize = {
  /** Gender */
  category: BasicSetting;
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Shoe Modifier */
  shoeModifier?: Maybe<ShoeModifier>;
  /** ShoeSize for AU */
  shoeSizeAU: Scalars['String'];
  /** ShoeSize for EU */
  shoeSizeEU: Scalars['String'];
  /** ShoeSize for UK */
  shoeSizeUK: Scalars['String'];
  /** ShoeSize for US */
  shoeSizeUS: Scalars['String'];
  /** short order */
  sortOrder: Scalars['Float'];
};

/** Input type for Profile Shoe Size */
export type ProfileShoeSizeInput = {
  /** Shoe Modifier Id */
  shoeModifierId?: InputMaybe<Scalars['Int']>;
  /** Shoe Size Id */
  shoeSizeId?: InputMaybe<Scalars['Int']>;
};

/** Profile Size */
export type ProfileSize = {
  category: BasicSetting;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Size gender ID */
  sizeGenderId: Scalars['ID'];
  /** Size Modifier */
  sizeModifier?: Maybe<SizeModifier>;
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for Profile Size */
export type ProfileSizeInput = {
  /** Size Id */
  sizeId?: InputMaybe<Scalars['Int']>;
  /** Size Modifier Id */
  sizeModifierId?: InputMaybe<Scalars['Int']>;
};

/** Skill and it`s level */
export type ProfileSkill = {
  /** child objects */
  childObjects: Array<Skill>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Skill level */
  level?: Maybe<SkillLevel>;
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Skill>;
  /** Profile media id */
  profileMediaId?: Maybe<Scalars['Float']>;
  /** Profile skill id */
  profileSkillId?: Maybe<Scalars['Float']>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type to update profile skill */
export type ProfileSkillInput = {
  /** Note */
  note?: InputMaybe<Scalars['ID']>;
  /** Profile Media Id */
  profileMediaId?: InputMaybe<Scalars['ID']>;
  /** Profile Skill Id */
  profileSkillId: Scalars['BigInt'];
  /** Skill Level Id */
  skillLevelId?: InputMaybe<Scalars['ID']>;
};

/** Profile sport equipment */
export type ProfileSportEquipment = {
  /** child objects */
  childObjects: Array<SportEquipment>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<SportEquipment>;
  /** Profile sport equipment relation id */
  profileSportEquipmentId?: Maybe<Scalars['Float']>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** ProfileStatus object */
export type ProfileStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum ProfileStatusEnum {
  Active = 'ACTIVE',
  AddIn = 'ADD_IN',
  CreateProfileStep_1 = 'CREATE_PROFILE_STEP_1',
  CreateProfileStep_2 = 'CREATE_PROFILE_STEP_2',
  CreateProfileStep_3 = 'CREATE_PROFILE_STEP_3',
  CreateProfileStep_4 = 'CREATE_PROFILE_STEP_4',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  MigratedIncomplete = 'MIGRATED_INCOMPLETE',
  SelectMembership = 'SELECT_MEMBERSHIP',
  Unclaimed = 'UNCLAIMED',
  VerifyAge = 'VERIFY_AGE',
}

export enum ProfileStatusSearchEnum {
  Current = 'CURRENT',
  Former = 'FORMER',
  FormerAndCurrent = 'FORMER_AND_CURRENT',
  RosterAndSearchHidden = 'ROSTER_AND_SEARCH_HIDDEN',
  RosterHidden = 'ROSTER_HIDDEN',
}

/** ProfileType object */
export type ProfileType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum ProfileTypeEnum {
  B21 = 'B21',
  Fastcast = 'FASTCAST',
  Luminate = 'LUMINATE',
}

export type ProfileUnionFilter = {
  excludeTalentUnions: Array<TalentUnionEnum>;
  roleId: Scalars['Float'];
  talentUnions: Array<TalentUnionEnum>;
};

/** Input type for update profile */
export type ProfileUpdateInput = {
  ageInYears?: InputMaybe<Scalars['Boolean']>;
  /** artist id */
  artistId?: InputMaybe<Scalars['ID']>;
  /** blurb */
  blurb?: InputMaybe<Scalars['ID']>;
  /** bustId */
  bustId?: InputMaybe<Scalars['ID']>;
  /** chestSizeId */
  chestSizeId?: InputMaybe<Scalars['ID']>;
  /** coatId */
  coatId?: InputMaybe<Scalars['ID']>;
  /** consentDate */
  consentDate?: InputMaybe<Scalars['DateTime']>;
  /** cupSizeId */
  cupSizeId?: InputMaybe<Scalars['ID']>;
  /** Date of birth */
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  /** managerEmail */
  defaultSubmissionNote?: InputMaybe<Scalars['String']>;
  /** documentIds */
  documentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** dressId */
  dressId?: InputMaybe<Scalars['ID']>;
  /** Ethnic Appearance Ids */
  ethnicAppearance?: InputMaybe<Array<OrderedItemInput>>;
  /** eyeColorId */
  eyeColorId?: InputMaybe<Scalars['ID']>;
  /** facialHairStyleIds */
  facialHairStyleIds?: InputMaybe<Array<Scalars['ID']>>;
  /** First Name */
  firstName?: InputMaybe<Scalars['String']>;
  /** gender */
  gender?: InputMaybe<GenderInput>;
  /** gloveSizeId */
  gloveSizeId?: InputMaybe<Scalars['ID']>;
  /** hairColorId */
  hairColorId?: InputMaybe<Scalars['ID']>;
  /** hairStyleId */
  hairStyleId?: InputMaybe<Scalars['ID']>;
  /** is Representation */
  hasRepresentation?: InputMaybe<Scalars['Boolean']>;
  /** hatSizeId */
  hatSizeId?: InputMaybe<Scalars['ID']>;
  /** heightId */
  heightId?: InputMaybe<Scalars['ID']>;
  /** Hide credit year */
  hideCreditYear?: InputMaybe<Scalars['Boolean']>;
  /** hipSizeId */
  hipSizeId?: InputMaybe<Scalars['ID']>;
  /** Profile id */
  id: Scalars['ID'];
  /** inseamId */
  inseamId?: InputMaybe<Scalars['ID']>;
  /** If Profile is overscaled */
  isOverscaled?: InputMaybe<Scalars['Boolean']>;
  /** is Seeking Representation */
  isSeekingRepresentation?: InputMaybe<Scalars['Boolean']>;
  /** Last Name */
  lastName?: InputMaybe<Scalars['String']>;
  /** managerEmail */
  managerEmail?: InputMaybe<Scalars['String']>;
  /** is minor */
  minor?: InputMaybe<Scalars['Boolean']>;
  /** Minor date of birth */
  minorDateOfBirth?: InputMaybe<Scalars['DateTime']>;
  /** multipleBirthId */
  multipleBirthId?: InputMaybe<Scalars['ID']>;
  /** neckId */
  neckId?: InputMaybe<Scalars['ID']>;
  /** Note to reps */
  noteToRepresentative?: InputMaybe<Scalars['String']>;
  /** otherExperiences */
  otherExperiences?: InputMaybe<Scalars['String']>;
  /** Overscale Note */
  overscaleNote?: InputMaybe<Scalars['String']>;
  /** Pants IDs */
  pantsIds?: InputMaybe<Array<Scalars['ID']>>;
  /** phone */
  phone?: InputMaybe<Scalars['String']>;
  /** piercingIds */
  piercingIds?: InputMaybe<Array<Scalars['ID']>>;
  /** piercing note */
  piercingNote?: InputMaybe<Scalars['String']>;
  /** playableAge at */
  playableAge?: InputMaybe<Scalars['Int']>;
  /** playableAge */
  playableAgeRange?: InputMaybe<AgeRangeInput>;
  /** pregnancyDueDate */
  pregnancyDue?: InputMaybe<Scalars['DateTime']>;
  /** profession */
  profession?: InputMaybe<Scalars['String']>;
  /** Profile access token granted by Rep */
  profileAccessToken?: InputMaybe<Scalars['String']>;
  /** profile status code */
  profileStatusCode?: InputMaybe<Scalars['String']>;
  /** pursuing ids */
  pursuingIds?: InputMaybe<Array<Scalars['ID']>>;
  /** _relationship_note */
  relationshipNote?: InputMaybe<Scalars['String']>;
  /** relationshipToMinorId */
  relationshipToMinorId?: InputMaybe<Scalars['ID']>;
  /** rosterIds */
  rosterIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Representation Ids */
  seekingRepresentations?: InputMaybe<Array<Scalars['ID']>>;
  /** Profile Shoe Size */
  shoeSize?: InputMaybe<Array<ProfileShoeSizeInput>>;
  /** Profile Size */
  size?: InputMaybe<Array<ProfileSizeInput>>;
  /** Skills and Skills Levels */
  skills?: InputMaybe<Array<SkillInput>>;
  /** sleeveId */
  sleeveId?: InputMaybe<Scalars['ID']>;
  /** Stage Name */
  stageName?: InputMaybe<Scalars['String']>;
  /** talentEmail */
  talentEmail?: InputMaybe<Scalars['String']>;
  /** Talent Unions */
  talentUnions?: InputMaybe<Array<TalentUnionInput>>;
  /** tattooId */
  tattooId?: InputMaybe<Scalars['ID']>;
  /** tattooNote */
  tattooNote?: InputMaybe<Scalars['String']>;
  /** tshirt size ids */
  tshirtSizeIds?: InputMaybe<Array<Scalars['Float']>>;
  /** underbustId */
  underbustId?: InputMaybe<Scalars['ID']>;
  /** unique atribute ids */
  uniqueAttributeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** waistId */
  waistId?: InputMaybe<Scalars['ID']>;
  /** wardrobe ids */
  wardrobeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** weightId */
  weightId?: InputMaybe<Scalars['ID']>;
  /** willingness ids */
  willingnessIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Work permit expiration date */
  workPermitExp?: InputMaybe<Scalars['DateTime']>;
  /** Working Locations */
  workingLocations?: InputMaybe<Array<OrderedItemInput>>;
};

/** Profile vehicle */
export type ProfileVehicle = {
  /** Profile vehicle relation id */
  profileVehicleId?: Maybe<Scalars['Float']>;
  /** Vehicle Name */
  vehicleName?: Maybe<Scalars['String']>;
  /** Vehicle type make */
  vehicleTypeMake?: Maybe<VehicleTypeMake>;
  /** Vehicle Year */
  vehicleYear?: Maybe<Scalars['Float']>;
};

/** Input type for Profile Vehicle */
export type ProfileVehicleInput = {
  /** Vehicle name */
  name?: InputMaybe<Scalars['String']>;
  /** Vehicle type make id */
  vehicleTypeMakeId: Scalars['Float'];
  /** Vehicle year */
  year?: InputMaybe<Scalars['Float']>;
};

/** Profile Visibility object */
export type ProfileVisibility = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Profile wardrobe */
export type ProfileWardrobe = {
  /** child objects */
  childObjects: Array<Wardrobe>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Wardrobe>;
  /** Profile wardrobe relation id */
  profileWardrobeId?: Maybe<Scalars['Float']>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Profile working location */
export type ProfileWorkingLocation = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Profile working location id */
  profileWorkingLocationId: Scalars['Float'];
  /** child objects */
  region?: Maybe<Region>;
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Custom URL Slug with relevant blocked keyword */
export type ProhibitedCustomSlug = {
  /** The relevant blocked keyword */
  blockedKeyword: Scalars['String'];
  /** The relevant blocked keyword id */
  blockedKeywordId: Scalars['ID'];
  /** The reason for the blocked keyword */
  blockedReasonNote?: Maybe<Scalars['String']>;
  /** Date when the slug was created and assigned to this user */
  createdDate: Scalars['DateTime'];
  /** Date when the slug is or will be expired */
  expiredDate?: Maybe<Scalars['DateTime']>;
  /** Slug Id */
  id: Scalars['ID'];
  /** Profile Id that owns the slug */
  profileId: Scalars['ID'];
  /** Slug Name */
  slugName: Scalars['String'];
};

/** Project object */
export type Project = {
  /** Account name */
  accountName?: Maybe<Scalars['String']>;
  /** Project accounts */
  accounts?: Maybe<Array<Account>>;
  /** AD agency */
  adAgency?: Maybe<Scalars['String']>;
  /** Additional provisions */
  additionalProvisions?: Maybe<Scalars['String']>;
  /** Project Attachment Security Id - show attachments to reps only - otherwise it will be also viewable on casting billboard */
  attachmentDisplayTypeId?: Maybe<Scalars['ID']>;
  /** Project audition address */
  auditionAddress?: Maybe<AuditionAddress>;
  /** Audition type */
  auditionType?: Maybe<AuditionType>;
  canTalentSubmitSelfTape?: Maybe<Scalars['Boolean']>;
  /** Casting assistant */
  castingAssistant?: Maybe<Scalars['String']>;
  /** Casting associate */
  castingAssociate?: Maybe<Scalars['String']>;
  /** Casting company name */
  castingCompany?: Maybe<Scalars['String']>;
  /** Casting Company Name */
  castingCompanyName?: Maybe<Scalars['String']>;
  /** Casting director */
  castingDirector?: Maybe<Scalars['String']>;
  /** Conflict note */
  conflictNote?: Maybe<Scalars['String']>;
  /** Contact email */
  contactEmail?: Maybe<Scalars['String']>;
  /** Contact info permissions */
  contactInfoPermissions?: Maybe<Scalars['Boolean']>;
  /** Contact phone */
  contactPhone?: Maybe<Scalars['String']>;
  /** Created date */
  created?: Maybe<Scalars['DateTime']>;
  /** Created By Account Id */
  createdBy?: Maybe<Scalars['Float']>;
  /** Is project created by a PC user */
  createdByPC: Scalars['Boolean'];
  /** Support User(Creator) Account ID */
  createdByTalentSystemUser?: Maybe<Scalars['Float']>;
  /**
   * Do not use this field
   * @deprecated Use 'created' field instead
   */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Director */
  director?: Maybe<Scalars['String']>;
  /** Displayed Project Union */
  displayedProjectUnion: Scalars['String'];
  /** Executive producer */
  executiveProducer?: Maybe<Scalars['String']>;
  fieldRestrictions?: Maybe<Array<ProjectFieldRestriction>>;
  /** Project public GUID */
  guid?: Maybe<Scalars['String']>;
  /** Is hidden */
  hidden?: Maybe<Scalars['Boolean']>;
  /** Primary ID */
  id: Scalars['ID'];
  /** Project internal name */
  internalName?: Maybe<Scalars['String']>;
  isAuditionInPerson?: Maybe<Scalars['Boolean']>;
  isSpecificLocation?: Maybe<Scalars['Boolean']>;
  lastModifiedAt: Scalars['DateTime'];
  /** Last Role Published Date */
  lastRolePublishedDate?: Maybe<Scalars['DateTime']>;
  /** Legacy instance ID */
  legacyInstance?: Maybe<Scalars['String']>;
  /** Legacy instance URL */
  legacyInstanceUrl?: Maybe<Scalars['String']>;
  /** Legacy project ID */
  legacyProjectId?: Maybe<Scalars['ID']>;
  /** Project asset */
  media?: Maybe<Array<Media>>;
  /** Project name */
  name?: Maybe<Scalars['String']>;
  /** Network */
  network?: Maybe<Scalars['String']>;
  /** Project Notes */
  notes?: Maybe<Scalars['String']>;
  /** Project organizations */
  organizations?: Maybe<Array<Organization>>;
  /** Parent Project- eg series */
  parent?: Maybe<Project>;
  pendingRequestCount?: Maybe<Scalars['Int']>;
  /** Photographer */
  photographer?: Maybe<Scalars['String']>;
  /** Producer */
  producer?: Maybe<Scalars['String']>;
  /** Production company */
  productionCompany?: Maybe<Scalars['String']>;
  /** Project Conflicts */
  projectConflicts?: Maybe<Array<Conflict>>;
  /** Project Id */
  projectId?: Maybe<Scalars['Float']>;
  /** Project internal name */
  projectInternalName?: Maybe<Scalars['String']>;
  /** Project name */
  projectName?: Maybe<Scalars['String']>;
  /** Parent ID */
  projectParentId?: Maybe<Scalars['ID']>;
  projectSeries?: Maybe<SeriesDetails>;
  /** Project type */
  projectStatus?: Maybe<ProjectStatus>;
  /** Project type */
  projectType?: Maybe<ProjectType>;
  /** Project Unions */
  projectUnion?: Maybe<Array<ProjectUnionDetails>>;
  /** Project rate */
  rate?: Maybe<Scalars['String']>;
  /** Release Locations DMA */
  releaseLocations?: Maybe<Array<Dma>>;
  /** Request brief */
  requestBrief?: Maybe<Scalars['Boolean']>;
  /** Request brief instruction */
  requestBriefInstruction?: Maybe<Scalars['String']>;
  roleDefaults?: Maybe<RoleDefaults>;
  /** Project roles */
  roles?: Maybe<Array<Role>>;
  /** Series Name */
  seriesName?: Maybe<Scalars['String']>;
  /** Project side visibility */
  sideVisibility?: Maybe<ProjectSidesVisibility>;
  /** Studio */
  studio?: Maybe<Scalars['String']>;
  /** Submissions due */
  submissionsDueBy?: Maybe<Scalars['DateTime']>;
  /** Zendesk support ticket id */
  supportTicketNumber?: Maybe<Scalars['String']>;
  /** Synopsis */
  synopsis?: Maybe<Scalars['String']>;
  /** Tile scheme ID */
  tileSchemeId?: Maybe<Scalars['ID']>;
  /** Total records count */
  totalCount?: Maybe<Scalars['Float']>;
  /** Updated date */
  updated?: Maybe<Scalars['DateTime']>;
  /** Usage */
  usage?: Maybe<Scalars['String']>;
  /** Writer */
  writer?: Maybe<Scalars['String']>;
};

/** Project object */
export type ProjectRolesArgs = {
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  roleStatusFilter?: InputMaybe<RoleStatusFilter>;
};

export type ProjectAttachSupportTicketNumber = {
  projectId: Scalars['Int'];
  supportTicketNumber: Scalars['String'];
};

/** Filters for searching suitable projects from backlot */
export type ProjectBacklotSearchInput = {
  /** Country Ids */
  countryIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Organization Id */
  organizationId?: InputMaybe<Scalars['Int']>;
  /** Project Creator Mail */
  projectCreatorEmail?: InputMaybe<Scalars['String']>;
  /** Project Id */
  projectId?: InputMaybe<Scalars['Int']>;
  /** Project Status Code */
  projectStatusCode?: InputMaybe<Array<Scalars['String']>>;
};

/** Search result for backlot projects */
export type ProjectBacklotSearchResult = {
  /** Casting Company Id */
  castingCompanyId?: Maybe<Scalars['Int']>;
  /** Casting Company Name */
  castingCompanyName?: Maybe<Scalars['String']>;
  /** Contact Email */
  contactEmail?: Maybe<Scalars['String']>;
  /** Countries */
  country?: Maybe<Country>;
  /** Project Created Date */
  created?: Maybe<Scalars['DateTime']>;
  /** Internal Name */
  internalName?: Maybe<Scalars['String']>;
  /** Project Name */
  name?: Maybe<Scalars['String']>;
  /** Project Id */
  projectId?: Maybe<Scalars['Int']>;
  /** Project Status */
  projectStatus?: Maybe<ProjectStatus>;
  /** Project Status Id */
  projectStatusId?: Maybe<Scalars['Int']>;
  /** Project Type */
  projectType?: Maybe<ProjectType>;
  /** Project Type Id */
  projectTypeId?: Maybe<Scalars['Int']>;
  /** Project Update Date */
  updated?: Maybe<Scalars['DateTime']>;
};

/** Paginated result with [ProjectBacklotSearchResult] objects */
export type ProjectBacklotSearchResultPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of ProjectBacklotSearchResult objects */
  objects: Array<ProjectBacklotSearchResult>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type ProjectBase = {
  /** Audition type */
  auditionType?: Maybe<AuditionType>;
  /** Casting Company Name */
  castingCompanyName?: Maybe<Scalars['String']>;
  /** Created date */
  created?: Maybe<Scalars['DateTime']>;
  /** Primary ID */
  id: Scalars['ID'];
  /** Project internal name */
  internalName?: Maybe<Scalars['String']>;
  lastModifiedAt: Scalars['DateTime'];
  /** Project name */
  name?: Maybe<Scalars['String']>;
  projectHasList: Scalars['Boolean'];
  /** Project type */
  projectStatus?: Maybe<ProjectStatus>;
  /** Project type */
  projectType?: Maybe<ProjectType>;
  /** Series Name */
  seriesName?: Maybe<Scalars['String']>;
  /** Zendesk support ticket id */
  supportTicketNumber?: Maybe<Scalars['String']>;
};

/** Paginated result with [ProjectBase] objects */
export type ProjectBasePaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of ProjectBase objects */
  objects: Array<ProjectBase>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type ProjectFieldRestriction = {
  fields: Array<ProjectFieldRestrictionFieldOption>;
  restrictedFor: ProjectFieldRestrictionOption;
};

export enum ProjectFieldRestrictionFieldOption {
  AdAgency = 'adAgency',
  Director = 'director',
  ExecutiveProducer = 'executiveProducer',
  Network = 'network',
  Photographer = 'photographer',
  Producer = 'producer',
  ProductionCompany = 'productionCompany',
  Studio = 'studio',
  Writer = 'writer',
}

export type ProjectFieldRestrictionInput = {
  fields: Array<ProjectFieldRestrictionFieldOption>;
  restrictedFor: ProjectFieldRestrictionOption;
};

export enum ProjectFieldRestrictionOption {
  Agent = 'AGENT',
  CastingBillboard = 'CastingBillboard',
}

export type ProjectFilter = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Filters for searching suitable projects */
export type ProjectFilterInput = {
  /** Which casting organization's project should it be? */
  castingOrganizationIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Which organization division should be considered? */
  dmaIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Has requests attached? */
  hasRequests?: InputMaybe<Scalars['Boolean']>;
  /** Has selects attached? */
  hasSelects?: InputMaybe<Scalars['Boolean']>;
  /** Has submissions attached? */
  hasSubmissions?: InputMaybe<Scalars['Boolean']>;
  /** Should the project contain only kids roles? */
  isKidsRolesOnly?: InputMaybe<Scalars['Boolean']>;
  /** Is project viewed */
  isViewed?: InputMaybe<Scalars['Boolean']>;
  /** Which organization this project belongs? */
  organizationId?: InputMaybe<Scalars['Float']>;
  /** Search in the name */
  projectName?: InputMaybe<Scalars['String']>;
  /** What project types (by ID) should project have? */
  projectTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Which project unions should be considered? */
  projectUnionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Search in role name */
  roleName?: InputMaybe<Scalars['String']>;
  /** Search by roleTypes array using codes */
  roleTypes?: InputMaybe<Array<Scalars['String']>>;
  /** What submission date should it be? */
  submissionDueDate?: InputMaybe<SubmissionDueDateSearchEnum>;
};

/** Project group type enum */
export enum ProjectGroupType {
  Series = 'Series',
  Status = 'Status',
  Type = 'Type',
}

export type ProjectLocation = {
  id: Scalars['ID'];
  projectId: Scalars['Int'];
  projectLocations: Array<Scalars['String']>;
};

/** Add Note Input Type */
export type ProjectNoteInput = {
  /** Text of the note */
  comment: Scalars['String'];
  /** Project ID */
  projectId?: InputMaybe<Scalars['BigInt']>;
};

export type ProjectNotificationToRepsInput = {
  message: Scalars['String'];
  projectId: Scalars['Int'];
};

/** Add organization as project attribute */
export type ProjectOrganizationInput = {
  accessTypeId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

/** Project Overview CD Report */
export type ProjectOverviewCdReportModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<ProjectOverviewCdReportPayloadModel>;
  projectId: Scalars['Int'];
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Project Overview CD Report Payload */
export type ProjectOverviewCdReportPayloadModel = {
  castingCompanyLogo?: Maybe<ReportMediaModel>;
  displayRoleOption: DisplayRoleOptionsEnum;
  projectInfo: ProjectReportInfoModel;
  projectRoles: Array<ProjectReportCdRoleModel>;
};

/** Project Overview Report */
export type ProjectOverviewReportModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<ProjectOverviewReportPayloadModel>;
  projectId: Scalars['Int'];
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Project Overview Report Payload */
export type ProjectOverviewReportPayloadModel = {
  divisionLogo?: Maybe<ReportMediaModel>;
  divisionName?: Maybe<Scalars['String']>;
  projectInfo: ProjectReportInfoModel;
  projectRoles: Array<ProjectReportRoleModel>;
};

/** Paginated result with [Project] objects */
export type ProjectPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of Project objects */
  objects: Array<Project>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Project Report Attachment */
export type ProjectReportAttachmentModel = {
  id: Scalars['Float'];
  name: Scalars['String'];
};

/** Project Report Input Arguments */
export type ProjectReportCdCreateInput = {
  displayRoleOption: DisplayRoleOptionsEnum;
  projectId: Scalars['Int'];
  roleTypes: Array<Scalars['String']>;
};

/** Project Report Role */
export type ProjectReportCdRoleModel = {
  ageRange: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayedRoleEthnicAppearance?: Maybe<Scalars['String']>;
  displayedRoleGenderAppearance: Scalars['String'];
  displayedRoleUnion?: Maybe<Scalars['String']>;
  ethnicAppearance?: Maybe<Array<Scalars['String']>>;
  gender: Array<Scalars['String']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  paidType?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<ReportMediaModel>>;
  rate?: Maybe<Scalars['String']>;
  rateNote?: Maybe<Scalars['String']>;
  ratePerType?: Maybe<Scalars['String']>;
  rateSummary?: Maybe<Scalars['String']>;
  requestMediaInstruction?: Maybe<Scalars['String']>;
  requestMediaTypes?: Maybe<Array<Scalars['String']>>;
  requestSubmissionsFrom?: Maybe<Array<ReportPublicationSettings>>;
  roleDateWithLocation?: Maybe<Array<ReportRoleDateWithLocation>>;
  roleEthnicityNationality?: Maybe<Scalars['String']>;
  roleSkills?: Maybe<Array<Scalars['String']>>;
  roleStatus: Scalars['String'];
  roleType: Scalars['String'];
  sexualSituationDescription?: Maybe<Scalars['String']>;
  sexualSituations?: Maybe<Array<Scalars['String']>>;
  sides?: Maybe<Array<ReportMediaObjectModel>>;
  sidesVisibility?: Maybe<Scalars['String']>;
  spot?: Maybe<Scalars['String']>;
  submissionInstruction?: Maybe<Scalars['String']>;
  wardrobe?: Maybe<Scalars['String']>;
  workRequirement?: Maybe<Scalars['String']>;
};

/** Project Report Input Arguments */
export type ProjectReportCreateInput = {
  projectId: Scalars['Int'];
};

/** Project Info */
export type ProjectReportInfoModel = {
  adAgency?: Maybe<Scalars['String']>;
  additionalProvisions?: Maybe<Scalars['String']>;
  attachments: Array<ProjectReportAttachmentModel>;
  castingAssistant?: Maybe<Scalars['String']>;
  castingAssociate?: Maybe<Scalars['String']>;
  castingCompany?: Maybe<Scalars['String']>;
  castingDirector?: Maybe<Scalars['String']>;
  conflictsNameList: Array<Scalars['String']>;
  conflictsNote?: Maybe<Scalars['String']>;
  director?: Maybe<Scalars['String']>;
  displayedProjectUnion?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  executiveProducer?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  network?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photographer?: Maybe<Scalars['String']>;
  producer?: Maybe<Scalars['String']>;
  productionCompany?: Maybe<Scalars['String']>;
  projectNotes?: Maybe<Scalars['String']>;
  projectSynopsis?: Maybe<Scalars['String']>;
  projectType: Scalars['String'];
  rate?: Maybe<Scalars['String']>;
  studio?: Maybe<Scalars['String']>;
  usageRun?: Maybe<Scalars['String']>;
  writer?: Maybe<Scalars['String']>;
};

/** Project Report Role */
export type ProjectReportRoleModel = {
  ageRange: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayedRoleUnion?: Maybe<Scalars['String']>;
  ethnicAppearance: Array<Scalars['String']>;
  gender: Array<Scalars['String']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  paidType?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['String']>;
  rateNote?: Maybe<Scalars['String']>;
  ratePer?: Maybe<Scalars['String']>;
  rateSummary?: Maybe<Scalars['String']>;
  roleType: Scalars['String'];
  sexualSituations: Array<Scalars['String']>;
  submissions: Array<Scalars['String']>;
};

export type ProjectRequestsInfo = {
  /** # of Requested talent in Project with worksheer request status of New, Rescheduled, Review */
  newProjectRequestsCount?: Maybe<Scalars['Float']>;
  /** Total Project Requests regardless of status */
  totalProjectRequestsCount: Scalars['Float'];
};

/** Project Series */
export type ProjectSeries = {
  /** Project Series ID */
  id: Scalars['ID'];
  /** Org ID */
  organizationId?: Maybe<Scalars['ID']>;
  /** Series Name */
  seriesName: Scalars['String'];
};

/** Paginated result with [ProjectSeries] objects */
export type ProjectSeriesPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of ProjectSeries objects */
  objects: Array<ProjectSeries>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Project Sides Visibility object */
export type ProjectSidesVisibility = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Project Status Object */
export type ProjectStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum ProjectStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  ArchiveInProgress = 'ARCHIVE_IN_PROGRESS',
  Draft = 'DRAFT',
  EditToActiveProject = 'EDIT_TO_ACTIVE_PROJECT',
  ModificationsComplete = 'MODIFICATIONS_COMPLETE',
  ModificationsRequested = 'MODIFICATIONS_REQUESTED',
  NewProjectPending = 'NEW_PROJECT_PENDING',
  NotYetPublished = 'NOT_YET_PUBLISHED',
  Rejected = 'REJECTED',
  RestoreInProgress = 'RESTORE_IN_PROGRESS',
  SubmissionsClosed = 'SUBMISSIONS_CLOSED',
}

/** Project status filter options */
export enum ProjectStatusFilterEnum {
  Archived = 'Archived',
  Current = 'Current',
}

/** Project Report Input Arguments */
export type ProjectSubmissionCreateInput = {
  projectId: Scalars['Int'];
  roleIds: Array<Scalars['Int']>;
  talentStatus: TalentStatusEnum;
};

/** Project Submission Report */
export type ProjectSubmissionReportModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<ProjectSubmissionReportPayloadModel>;
  projectId: Scalars['Int'];
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  talentStatus: TalentStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Project Overview CD Report Payload */
export type ProjectSubmissionReportPayloadModel = {
  divisionLogo?: Maybe<ReportMediaModel>;
  divisionName?: Maybe<Scalars['String']>;
  projectInfo: ProjectReportInfoModel;
  projectRolesAcceptingSubmissions?: Maybe<Array<ProjectSubmissionReportRoleModel>>;
  projectRolesClosedSubmissions?: Maybe<Array<ProjectSubmissionReportRoleModel>>;
};

/** Project Submission Report Role */
export type ProjectSubmissionReportRoleModel = {
  ageRange?: Maybe<Scalars['String']>;
  ethnicAppearance?: Maybe<Array<Scalars['String']>>;
  gender?: Maybe<Array<Scalars['String']>>;
  id: Scalars['Float'];
  name: Scalars['String'];
  roleType: Scalars['String'];
  submissions?: Maybe<Array<Scalars['String']>>;
};

/** Available project types */
export type ProjectType = {
  /** child objects */
  childObjects: Array<ProjectType>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<ProjectType>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum ProjectTypeEnum {
  Audio = 'AUDIO',
  AudioAudioBooks = 'AUDIO_AUDIO_BOOKS',
  AudioPodcast = 'AUDIO_PODCAST',
  AudioRadio = 'AUDIO_RADIO',
  AudioVoiceOver = 'AUDIO_VOICE_OVER',
  Commercial = 'COMMERCIAL',
  CommercialCommercial = 'COMMERCIAL_COMMERCIAL',
  CommercialIndustrial = 'COMMERCIAL_INDUSTRIAL',
  CommercialInfomercial = 'COMMERCIAL_INFOMERCIAL',
  CommercialInternetCommercial = 'COMMERCIAL_INTERNET_COMMERCIAL',
  CommercialPsa = 'COMMERCIAL_PSA',
  Episodic = 'EPISODIC',
  EpisodicDaytimeDrama = 'EPISODIC_DAYTIME_DRAMA',
  EpisodicGameShow = 'EPISODIC_GAME_SHOW',
  EpisodicMiniSeries = 'EPISODIC_MINI_SERIES',
  EpisodicPilot = 'EPISODIC_PILOT',
  EpisodicRealityTv = 'EPISODIC_REALITY_TV',
  EpisodicReEnactment = 'EPISODIC_RE_ENACTMENT',
  EpisodicStreamingSeries = 'EPISODIC_STREAMING_SERIES',
  EpisodicTalkShow = 'EPISODIC_TALK_SHOW',
  EpisodicTelevisionSeries = 'EPISODIC_TELEVISION_SERIES',
  EpisodicWebSeries = 'EPISODIC_WEB_SERIES',
  Film = 'FILM',
  FilmDocumentary = 'FILM_DOCUMENTARY',
  FilmFeatureFilm = 'FILM_FEATURE_FILM',
  FilmShortFilm = 'FILM_SHORT_FILM',
  FilmShortVideo = 'FILM_SHORT_VIDEO',
  FilmTelevisionFilm = 'FILM_TELEVISION_FILM',
  Gaming = 'GAMING',
  GamingMobileApp = 'GAMING_MOBILE_APP',
  GamingVideoGame = 'GAMING_VIDEO_GAME',
  GamingVirtualReality = 'GAMING_VIRTUAL_REALITY',
  LiveEvent = 'LIVE_EVENT',
  LiveEventBrandAmbassador = 'LIVE_EVENT_BRAND_AMBASSADOR',
  LiveEventCabaret = 'LIVE_EVENT_CABARET',
  LiveEventComedyShow = 'LIVE_EVENT_COMEDY_SHOW',
  LiveEventEducationalTraining = 'LIVE_EVENT_EDUCATIONAL_TRAINING',
  LiveEventLiveEvent = 'LIVE_EVENT_LIVE_EVENT',
  LiveEventPromotionalEvent = 'LIVE_EVENT_PROMOTIONAL_EVENT',
  LiveEventRunwayShow = 'LIVE_EVENT_RUNWAY_SHOW',
  LiveEventVarietyShow = 'LIVE_EVENT_VARIETY_SHOW',
  Other = 'OTHER',
  OtherDirectorsReel = 'OTHER_DIRECTORS_REEL',
  OtherMusic = 'OTHER_MUSIC',
  OtherMusicVideo = 'OTHER_MUSIC_VIDEO',
  OtherOther = 'OTHER_OTHER',
  OtherSketch = 'OTHER_SKETCH',
  Print = 'PRINT',
  PrintPrint = 'PRINT_PRINT',
  PrintStockPhotography = 'PRINT_STOCK_PHOTOGRAPHY',
  Promo = 'PROMO',
  Student = 'STUDENT',
  StudentStudentFilm = 'STUDENT_STUDENT_FILM',
  StudentStudentProject = 'STUDENT_STUDENT_PROJECT',
  Theatre = 'THEATRE',
  TheatreTheatre = 'THEATRE_THEATRE',
}

/** Project Union Details */
export type ProjectUnionDetails = {
  /** Union contract id */
  contractId?: Maybe<Scalars['String']>;
  /** Contract Status */
  contractStatus?: Maybe<ContractStatus>;
  /** Union contract status id */
  contractStatusId?: Maybe<Scalars['Int']>;
  /** Union details */
  union: TalentUnion;
};

/** Project Viewed Filter */
export enum ProjectViewFilterType {
  All = 'All',
  Unviewed = 'Unviewed',
  Viewed = 'Viewed',
}

export type ProjectVisibility = {
  /** States if the project has any role with selected talent from user current agency */
  isAnyRoleSelected: Scalars['Boolean'];
  /** States if the project has any role with submitted talent from user current agency */
  isAnyRoleSubmitted: Scalars['Boolean'];
  /** States if the project was viewed by the context user */
  isViewed: Scalars['Boolean'];
  /** Project ID */
  project: Project;
  /** States if the project has any requests for current reps agency */
  requestsInfo?: Maybe<ProjectRequestsInfo>;
};

export type ProjectVisibilityResult = {
  /** project_id - is_viewed pair */
  projectVisibilities: Array<ProjectVisibility>;
  /** Clusterized info about project locations */
  projectsLocations: Array<ProjectLocation>;
  /** Total number of projects */
  totalCount?: Maybe<Scalars['Int']>;
  /** Total number of new or updated requests */
  totalCountOfNewOrUpdatedRequests: Scalars['Int'];
  /** Total number of requests */
  totalCountOfRequests: Scalars['Int'];
};

/** Project for Workflow */
export type ProjectWorkflow = {
  castingCompany?: Maybe<Scalars['String']>;
  /** Conflict note */
  conflictNote?: Maybe<Scalars['String']>;
  createdByPC: Scalars['Boolean'];
  /** Displayed project union */
  displayedProjectUnion?: Maybe<Scalars['String']>;
  /** Displayed project union short */
  displayedProjectUnionShort?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** Project Notes */
  notes?: Maybe<Scalars['String']>;
  /** Project Conflicts */
  projectConflicts?: Maybe<Array<Conflict>>;
  /** Project Status */
  projectStatus?: Maybe<ProjectStatus>;
  projectType: ProjectType;
  projectUnions: Array<ProjectUnionDetails>;
  synopsis?: Maybe<Scalars['String']>;
};

/** Prop object */
export type Prop = {
  /** child objects */
  childObjects: Array<Prop>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Prop>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type PublicCredit = {
  /** Created at Date */
  created: Scalars['DateTime'];
  creditMedia: Array<Media>;
  /** Credit Sort ordering */
  creditOrder: Scalars['Int'];
  /** Credit Type */
  creditType?: Maybe<CreditType>;
  /** The director of the Project */
  director?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  /** Highlighted */
  highlighted: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Profile Credit Type Id */
  profileCreditTypeId: Scalars['Float'];
  /** Profile ID */
  profileId: Scalars['ID'];
  /** Role title */
  role?: Maybe<Scalars['String']>;
  /** Credit Type Sort ordering */
  sortOrder: Scalars['Int'];
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Updated at Date */
  updated: Scalars['DateTime'];
  /** Year */
  year?: Maybe<Scalars['Int']>;
};

export type PublicProfile = {
  /** Checks if profile have granted access to roster */
  accessGranted: Scalars['Boolean'];
  accountId?: Maybe<Scalars['Float']>;
  ageInYears?: Maybe<Scalars['Boolean']>;
  /** Profile Artist ID */
  artistId?: Maybe<Scalars['Float']>;
  /** Profile audio assets */
  audios?: Maybe<Array<ProfileMedia>>;
  billingFeatures?: Maybe<Array<ArtistBillingFeatureModel>>;
  /** @deprecated Use 'billingFeatures' instead. ID will now return 0. */
  billingItems?: Maybe<Array<BillingItemModel>>;
  /** Blurb */
  blurb?: Maybe<Scalars['String']>;
  /** Bust Object */
  bust?: Maybe<Bust>;
  /** Chest size Object */
  chestSize?: Maybe<ChestSize>;
  /** Coat Object */
  coat?: Maybe<Coat>;
  /** Conflicts for the profile */
  conflicts: Array<ProfileConflict>;
  /** Dynamic field. Based on profile status and representative type */
  contactInfo?: Maybe<ProfileContactInfo>;
  contactedByRep?: Maybe<Array<ProfileContactedByRep>>;
  /** Profile's primary photo */
  cover?: Maybe<Media>;
  /** Created at Date */
  created?: Maybe<Scalars['DateTime']>;
  /** Profile Credits */
  credits: Array<PublicCredit>;
  /** Profile Credits Count */
  creditsCount: Scalars['Int'];
  /** Cup size Object */
  cupSize?: Maybe<CupSize>;
  customSlug?: Maybe<CustomSlug>;
  /** Date of birth */
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** stores/holds the id of the person who has deactivated the profile */
  deactivatedBy?: Maybe<Scalars['Float']>;
  /** Default submission note */
  defaultSubmissionNote?: Maybe<Scalars['String']>;
  /** Document type(s) */
  documentTypes?: Maybe<Array<Document>>;
  /** Profile document assets */
  documents?: Maybe<Array<ProfileMedia>>;
  /** Dress size Object */
  dressSize?: Maybe<ClothesSize>;
  /** Ethnic Appearance(s) */
  ethnicAppearances?: Maybe<Array<ProfileEthnicAppearance>>;
  /** Eye Color Object */
  eyeColor?: Maybe<EyeColor>;
  /** Facial Hair Type(s) */
  facialHair?: Maybe<Array<FacialHair>>;
  folderCustomization?: Maybe<PublicProfileFolderCustomization>;
  /** Gender Appearance(s) */
  genderAppearances?: Maybe<Array<GenderAppearance>>;
  /** Glove size Object */
  gloveSize?: Maybe<Glove>;
  /** GUID */
  guid?: Maybe<Scalars['String']>;
  /** Hair Color Object */
  hairColor?: Maybe<HairColor>;
  /** Hair Style Object */
  hairStyle?: Maybe<HairStyle>;
  /** Profile has audio reel */
  hasAudioReel?: Maybe<Scalars['Boolean']>;
  hasAuditionHistory: Scalars['Boolean'];
  /** has Representation */
  hasRepresentation?: Maybe<Scalars['Boolean']>;
  /** Profile has resume */
  hasResume?: Maybe<Scalars['Boolean']>;
  /** Profile has video reel */
  hasVideoReel?: Maybe<Scalars['Boolean']>;
  /** Hat size Object */
  hatSize?: Maybe<HatSize>;
  /** Height Object */
  height?: Maybe<Height>;
  /** Hide credit year */
  hideCreditYear: Scalars['Boolean'];
  /** Hip size Object */
  hipSize?: Maybe<Hip>;
  /** Primary ID */
  id: Scalars['ID'];
  /** Inseam size Object */
  inseam?: Maybe<Inseam>;
  isActive: Scalars['Boolean'];
  /**
   * Returns true if profile was deactivated by a Rep user, returns false if
   * profile was deactivated by a Talent, returns null when there is no information
   * about the deactivating account
   */
  isDeactivatedByReps?: Maybe<Scalars['Boolean']>;
  isDropped: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  /** If Profile is overscaled */
  isOverscaled: Scalars['Boolean'];
  /** Identify the personal or represented profile. */
  isPersonal: Scalars['Boolean'];
  isPlayableAgeLockedToTalent: Scalars['Boolean'];
  /** Identify represented profile */
  isRepresented: Scalars['Boolean'];
  /** Is talent seeking representation */
  isSeekingRepresentation?: Maybe<Scalars['Boolean']>;
  /** Last note for the profile */
  lastNote?: Maybe<Note>;
  /** Luminate profile Data */
  luminateProfile?: Maybe<LuminateProfile>;
  /** Manager Email */
  managerEmail?: Maybe<Scalars['String']>;
  /** All profile media */
  mediaAssets?: Maybe<Array<ProfileMedia>>;
  /** Profile's primary photo */
  mediaSummary: Array<MediaCount>;
  /** Is minor */
  minor?: Maybe<Scalars['Boolean']>;
  /** Multiple Birth Object */
  multipleBirth?: Maybe<MultipleBirth>;
  /** Neck size Object */
  neck?: Maybe<Neck>;
  /** Note */
  note?: Maybe<Scalars['String']>;
  /** Note to Representative */
  noteToRepresentative?: Maybe<Scalars['String']>;
  /** Notes for the profile */
  notes: Array<Note>;
  organization?: Maybe<Organization>;
  organizations?: Maybe<Array<Organization>>;
  /** Other experiences */
  otherExperiences?: Maybe<Scalars['String']>;
  /** Overscale Note */
  overscaleNote?: Maybe<Scalars['String']>;
  /** Parent Name */
  parent?: Maybe<Scalars['String']>;
  /** Profile phone */
  phone?: Maybe<Scalars['String']>;
  /** Profile photo assets */
  photos?: Maybe<Array<ProfileMedia>>;
  /** Note about piercing */
  piercingNote?: Maybe<Scalars['String']>;
  /** The age a talent can play */
  playableAge?: Maybe<Scalars['Float']>;
  /** The minimum age a talent can play */
  playableAgeMax?: Maybe<Scalars['Float']>;
  /** The maximum age a talent can play */
  playableAgeMin?: Maybe<Scalars['Float']>;
  /**
   * Date of pregnancy
   * @deprecated This field is deprecated and will be deleted once the compatible changes at the front end will be added everyewhere for pregnancyDue reference. Use pregnancyDue instead
   */
  pregnancy?: Maybe<Scalars['DateTime']>;
  /** Date of pregnancy */
  pregnancyDue?: Maybe<Scalars['DateTime']>;
  /** Profession */
  profession?: Maybe<Scalars['String']>;
  /** Access Token of a Profile, generated by Granting Access or Creating a Profile */
  profileAccessToken?: Maybe<Scalars['String']>;
  /** ProfileAccessToken created date */
  profileAccessTokenCreated?: Maybe<Scalars['DateTime']>;
  /** Profile account country */
  profileAccountCountry?: Maybe<Country>;
  /** Profile Account System Role */
  profileAccountRole?: Maybe<Scalars['String']>;
  /** Get profile bookouts */
  profileBookOuts: Array<ProfileBookOuts>;
  /** Gender Identities */
  profileGenderIdentities?: Maybe<Array<ProfileGenderIdentity>>;
  /** Primary ID */
  profileId: Scalars['ID'];
  /** The Profile List ids where current profile participated */
  profileLists: Array<Scalars['Int']>;
  profileMainDivision?: Maybe<Organization>;
  profileMainOrganization?: Maybe<Organization>;
  /** media assets */
  profileMedia?: Maybe<Array<ProfileMedia>>;
  /** Profile Pants */
  profilePants: Array<Pants>;
  /** Profile pets */
  profilePets: Array<ProfilePet>;
  /** Profile piercings */
  profilePiercings: Array<Piercing>;
  /** Profile shoe sizes */
  profileShoeSizes: Array<ProfileShoeSize>;
  /** Profile general size */
  profileSizes: Array<ProfileSize>;
  /** Profile Status Object */
  profileStatus?: Maybe<ProfileStatus>;
  profileStatusId?: Maybe<Scalars['Int']>;
  /** Profile TshirtSize */
  profileTshirtSizes: Array<TShirtSize>;
  /** Profile Type */
  profileType?: Maybe<ProfileType>;
  /** Profile vehicles */
  profileVehicles: Array<ProfileVehicle>;
  /** profileVisibility Object */
  profileVisiblities?: Maybe<Array<ProfileVisibility>>;
  /** Wardrobes */
  profileWardrobes?: Maybe<Array<ProfileWardrobe>>;
  /** Project Type */
  projectType?: Maybe<Scalars['String']>;
  /** Prop Type(s) */
  props?: Maybe<Array<ProfileProp>>;
  /** Job Persuing Type(s) */
  pursuing?: Maybe<Array<Pursuing>>;
  /** User's resume document */
  resume?: Maybe<ProfileMedia>;
  /** Roster hidden */
  rosterHidden?: Maybe<Scalars['Boolean']>;
  /** Search hidden */
  searchHidden?: Maybe<Scalars['Boolean']>;
  /** SeekingRepresentation Object */
  seekingRepresentations?: Maybe<Array<SeekingRepresentation>>;
  selectionType: ProfileSelectionType;
  /** Skills and their Levels */
  skills?: Maybe<Array<ProfileSkill>>;
  /** Sleeve Object */
  sleeve?: Maybe<Sleeve>;
  /** Sport equipments */
  sportEquipments?: Maybe<Array<ProfileSportEquipment>>;
  /** Stage Name */
  stageName: Scalars['String'];
  /** Returns submission */
  submission?: Maybe<PublicSubmission>;
  /** Submission request's media assets */
  submissionRequestMedia: Array<SubmissionMedia>;
  /** talent's contacts regardless representative status */
  talentContactInfo?: Maybe<ProfileContactInfo>;
  /** Profile email */
  talentEmail?: Maybe<Scalars['String']>;
  talentFolders?: Maybe<Array<PresentationFolderTalentPresence>>;
  /** Talent unions */
  talentUnions?: Maybe<Array<TalentProfileUnion>>;
  /** Profile tattoo */
  tattoo?: Maybe<Tattoo>;
  /** Tattoo Note */
  tattooNote?: Maybe<Scalars['String']>;
  /** Underbust size Object */
  underbust?: Maybe<UnderBust>;
  /** Unique Attribute Type(s) */
  uniqueAttributes?: Maybe<Array<UniqueAttribute>>;
  /** Updated at Date */
  updated?: Maybe<Scalars['DateTime']>;
  /** Profile video assets */
  videos?: Maybe<Array<ProfileMedia>>;
  /** Waist Object */
  waist?: Maybe<Waist>;
  /** Weight Object */
  weight?: Maybe<Weight>;
  /** Willingness Objects */
  willingness: Array<Willingness>;
  /** Work permit expiration date */
  workPermitExp?: Maybe<Scalars['DateTime']>;
  /** Working Locations */
  workingLocations?: Maybe<Array<ProfileWorkingLocation>>;
};

export type PublicProfileFolderCustomizationArgs = {
  folderId: Scalars['Float'];
};

export type PublicProfileHasAuditionHistoryArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};

export type PublicProfilePhotosArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
};

export type PublicProfileSelectionTypeArgs = {
  roleId: Scalars['Float'];
};

export type PublicProfileSubmissionArgs = {
  roleId: Scalars['Float'];
};

export type PublicProfileSubmissionRequestMediaArgs = {
  roleId: Scalars['Float'];
};

export type PublicProfileTalentFoldersArgs = {
  folderId: Scalars['Int'];
};

export type PublicProfileFolderCustomization = {
  id: Scalars['Int'];
  isOverscale?: Maybe<Scalars['Boolean']>;
  media: Array<PublicProfileFolderMediaCustomization>;
  note?: Maybe<Scalars['String']>;
  overscaleNote?: Maybe<Scalars['String']>;
};

export type PublicProfileFolderMediaCustomization = {
  id: Scalars['Int'];
  isPrimary: Scalars['Boolean'];
  media: Media;
  mediaId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

/** Public Submission object */
export type PublicSubmission = {
  /** Audition Snapshot Media Assets */
  auditionSnapshot?: Maybe<Media>;
  contactInfo?: Maybe<ProfileContactInfo>;
  /** Primary ID */
  id: Scalars['BigInt'];
  /** Media */
  media?: Maybe<Array<SubmissionMedia>>;
  mediaCount?: Maybe<Array<MediaCount>>;
  /** Note */
  note?: Maybe<Scalars['String']>;
  profile?: Maybe<PublicProfile>;
  profileId?: Maybe<Scalars['Int']>;
  /** Profile Working Location ID */
  profileWorkingLocationId?: Maybe<Scalars['Int']>;
  /** Requested Media */
  requestedMedia?: Maybe<Array<SubmissionMedia>>;
  /** Customized Profile Working Location */
  workingLocation?: Maybe<ProfileWorkingLocation>;
};

/** Publication Setting object */
export type PublicationSetting = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum PublicationSettingsEnum {
  Representatives = 'REPRESENTATIVES',
  TalentOnTheCastingBillboard = 'TALENT_ON_THE_CASTING_BILLBOARD',
}

export type PurchaseMediaInput = {
  billingInfoId?: InputMaybe<Scalars['String']>;
  couponCode?: InputMaybe<Scalars['String']>;
  mediaIds?: InputMaybe<Array<Scalars['Int']>>;
  threeDSecureActionResultTokenId?: InputMaybe<Scalars['String']>;
};

export type PurchaseSubscriptionInput = {
  billingInfoId?: InputMaybe<Scalars['String']>;
  billingPath?: InputMaybe<Scalars['String']>;
  couponCode?: InputMaybe<Scalars['String']>;
  subscriptionBillingCode: Scalars['String'];
  /** Location in the app where subscription was purchased */
  subscriptionSiteLocation?: InputMaybe<Scalars['String']>;
  threeDSecureActionResultTokenId?: InputMaybe<Scalars['String']>;
  trialTermsAccepted?: InputMaybe<Scalars['Boolean']>;
};

/** Pursuing object */
export type Pursuing = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum PursuingEnum {
  Acting = 'ACTING',
  Dance = 'DANCE',
  Modeling = 'MODELING',
  Stunt = 'STUNT',
  VoiceOver = 'VOICE_OVER',
}

export type Query = {
  accountStatuses: Array<AccountStatus>;
  accountsIndexable: Scalars['JSON'];
  /** Get all active projects list */
  activeProjectListWithVisibilities: ProjectVisibilityResult;
  /** Get all active roles for a Project with optional parameter to filter roles for organization */
  activeProjectRoles: Array<Role>;
  artistByAccount: Array<Artist>;
  artistByAccountWithUnclaimed: Array<Artist>;
  /** Get Artist by BAU Account */
  artistByBauAccount: Array<BauArtist>;
  /** This method is used for getting the amount of total storage and individual media types an artist is using. */
  artistStorageUtilization: ArtistStorageUtilization;
  /** @deprecated Should be moved to the payments service or removed completely */
  artistSubscriptions: Array<SubscriptionModel>;
  /** Artists by ids */
  artists: Array<Artist>;
  artistsIndexable: Scalars['JSON'];
  askQuestionRequestDetails: AskQuestionRequest;
  askQuestionSubRequestDetails: AskQuestionSubRequest;
  /** Gets single record of Audition Group by provided group id */
  auditionGroup: AuditionGroup;
  /** Gets audition group ids record by profile and session id */
  auditionGroupIdsByProfile?: Maybe<Array<AuditionGroupTalent>>;
  auditionGrouping: AuditionsGroups;
  /** Gets paginated result of Audition Groups by provided session id */
  auditionGroupsBySession: AuditionGroupPaginatedResult;
  /** Gets paginated result of Audition Sessions by provided project id */
  auditionSessionsByProject: AuditionSessionPaginatedResult;
  auditionSources: Array<AuditionSource>;
  auditionTypes: Array<AuditionType>;
  auditions: AuditionPagedListSearch;
  authHealth: Scalars['String'];
  /** Returns true if username and password are matched, otherwise false */
  authenticate: Scalars['Boolean'];
  availableApplications: Array<Scalars['String']>;
  billingPlans: Array<BillingPlan>;
  buildRolesOrganizationMatchNotificationPayloads: RolesPublishedContextNotificationContextAndRecipient;
  busts: Array<Bust>;
  /** Projects with Fit For You roles */
  castingBillboardRoles?: Maybe<RoleSearchResult>;
  cdWorksheet: SubmissionPaginatedResult;
  chestSizes: Array<ChestSize>;
  coats: Array<Coat>;
  /** Compare a password with hashedPassword */
  comparePassword: Scalars['Boolean'];
  compensation: Array<Compensation>;
  conflicts: Array<Conflict>;
  contractStatuses: Array<ContractStatus>;
  countSubmissionsPerRole: Array<SubmissionCountPerRole>;
  countries: Array<Country>;
  countryWithDma: Array<Country>;
  creditTypes: Array<CreditType>;
  /** Get all the profile's credits in the db */
  credits?: Maybe<Array<Credit>>;
  cupSizes: Array<CupSize>;
  currentUser: BauAccount;
  divisionCastingOrganizations: Array<Organization>;
  dmas: Array<Dma>;
  documentTypes: Array<Document>;
  dresses: Array<ClothesSize>;
  emailHistory: EmailHistoryPaginatedResult;
  ethnicAppearances: Array<EthnicAppearance>;
  eyeColors: Array<EyeColor>;
  facialHairs: Array<FacialHair>;
  featureByType: Array<Feature>;
  features: Array<Feature>;
  genderAppearances: Array<GenderAppearance>;
  genderIdentities: Array<GenderIdentity>;
  generalSizes: Array<GeneralSize>;
  getAccountByIds: Array<Account>;
  getAccountIds: Array<Scalars['ID']>;
  /** List of sessions */
  getAccountSessions: Array<Session>;
  getAccountSystemRoleCodes: Array<AccountsSystemRoleCodes>;
  getActiveOnboardedPaypalAccounts: Array<AccountActiveOnboardedPaypal>;
  getActiveSubRequestsBySubmissionIds: Array<RequestSubmission>;
  getActivityLogsBySubmissionId: ActivityLogPagedList;
  getArtist: Artist;
  getArtistByProfileId: Artist;
  getArtistByProfiles: Array<Artist>;
  /** Audition history of the talent within the company */
  getAuditionHistory: AuditionHistoryPaginatedResult;
  /** Gets single record of Audition Session by provided session id */
  getAuditionSession?: Maybe<AuditionSession>;
  getAuditionTalentPool?: Maybe<AuditionTalentPool>;
  /** This endpoint is specifically designed for FastCapture adapater usage for retrieving the audition talentpool list */
  getAuditionTalentPoolFlat?: Maybe<AuditionTalentPool>;
  /** Returns tuple of min/max available seconds for shifting, e.g. [-3600, 3600] */
  getAvailableScheduleTimeShifting: Array<Scalars['Int']>;
  /** Returns available submission ids for request */
  getAvailableSubmissionIdsForRequest: Array<Scalars['BigInt']>;
  getBauCountries?: Maybe<Array<BauCountry>>;
  getBillingInfo: Array<BillingInfo>;
  /** Gets a paginated list of blocked keywords */
  getBlockedKeywordsPaginatedList: BlockedKeywordPaginatedResult;
  /** Fetch last calendar history change */
  getCalendarLastHistoryChange?: Maybe<ScheduleHistory>;
  /** Fetch calendar revision by calendar id and version */
  getCalendarRevision: ScheduleDetail;
  getCastingListProfile: GetCastingListProfileResult;
  getCastingListsByProfileAndProject: ListGqlModelPagedList;
  getChatMessageByTopic: GetChatMessagesByTopicModel;
  getCollaboratorInviteById: CollaboratorsInvite;
  getCollaboratorsByProjectId: CollaboratorsInvitePaginatedResult;
  getCollaboratorsForOrganization: CollaboratorsInOrganizationPaginatedResult;
  getCommentsForPresentationSubmissionPublic: Array<PresentationSubmissionComment>;
  getCommentsForPresentationSubmissionPublicBySubmissionId: PresentationSubmissionCommentPaginatedResult;
  getCommentsForSubmission: Array<PresentationSubmissionComment>;
  getCommentsForSubmissions: PresentationSubmissionCommentPaginatedResult;
  getCompanyDictionary: OrganizationDictionaryItemPagedList;
  getComplexAccountInfo: Account;
  getDefaultSavedSearch?: Maybe<SavedSearch>;
  getEnterpriseUserOrganization?: Maybe<UserOrganization>;
  /** Get fit for you values for the specific artist id (Used at Casting Billboard ) */
  getFitForYouValuesForArtist?: Maybe<DefaultFitForYouArtist>;
  getForm: FormGqlModel;
  getFormResponse: FormResponseGqlModel;
  getFormResponsesForMediaRequest: GqlRpcFormBulkResponse;
  getForms: FormGqlModelPagedList;
  /** @deprecated Search for another endpoint in payments */
  getFreeCreditItems: Array<FreeCreditItemModel>;
  getIdByLegacyId: Redirect;
  getInternalNotesByAccountId: NotePaginatedResult;
  getInternalWelcomeContext: InternalWelcomeContextNotificationContextAndRecipient;
  getLanguageByLocale?: Maybe<LanguageInfo>;
  getLanguageLocales: Array<LanguageInfo>;
  getLastActivityLogBySubmissionIds: Array<ActivityLog>;
  getLastNote: Array<Note>;
  /** Get account by legacy profileId */
  getLegacyAccountDetails: Array<LegacyAccount>;
  getLists: ListGqlModelPagedList;
  /** This method is used for getting the associated media(s) and relevant detail(s) for specific artist and media type. */
  getMediaByArtist: Array<ArtistMedia>;
  getMediaMediaTypeById: Array<MediaMediaType>;
  getMessageAfterId: GetChatMessagesByTopicModel;
  /** Get notification settings for specific account */
  getNotificationEntitySubscriptionByAccount?: Maybe<Array<NotificationEntitySubscription>>;
  /** Get notification settings for specific artist */
  getNotificationEntitySubscriptionByArtist?: Maybe<Array<NotificationEntitySubscription>>;
  /** Get notification settings for specific account */
  getNotificationNotificationSubscriptionByAccount?: Maybe<Array<NotificationTypeNotificationSubscription>>;
  /** Get notification settings for specific account */
  getNotificationNotificationSubscriptionByAccounts?: Maybe<Array<NotificationTypeNotificationSubscription>>;
  /** Get notification settings for specific artist */
  getNotificationNotificationSubscriptionByArtist?: Maybe<Array<NotificationTypeNotificationSubscription>>;
  /** Get notification settings for artists */
  getNotificationNotificationSubscriptionByArtists?: Maybe<Array<NotificationTypeNotificationSubscription>>;
  /** Returns paginated result of organization profile lists which logged in user belongs to. */
  getOrganizationProfileLists: ProfileListPaginatedResult;
  /** Returns paginated result of organization representative lists which logged in user belongs to. */
  getOrganizationRepresentativeLists: RepresentativeListPaginatedResult;
  /** Paginated query for get organizations by specified representation type and partial/full org name match */
  getOrganizationsByRepTypeAndName: OrganizationsByRepTypeAndNameResultPaginatedResult;
  getPackageFolderTalent: Array<PresentationFolderTalent>;
  getPhone: Array<Phone>;
  /** @deprecated Not used anymore and will be removed in the future */
  getPlanFeatures: Array<PlanFeatureModel>;
  /** Retrieve Presentation by filters */
  getPresentationByFilters: PresentationPublic;
  /** Retrieve Presentation filters Data */
  getPresentationFiltersData: PresentationFilterData;
  /** Query for getting Presentation folder talent(s) by folder ids */
  getPresentationFolderTalent: Array<PresentationFolderTalent>;
  /** Query for getting Presentation folder talent(s) by talent ids */
  getPresentationFolderTalentById: Array<PresentationFolderTalent>;
  /** Gets list of presentation folders for the presentation */
  getPresentationFolders: Array<PresentationFolder>;
  /** Gets presentation folders details */
  getPresentationFoldersDetails: Array<PresentationFolder>;
  /** Retrieve Presentation Group by filters */
  getPresentationGroupByFilters?: Maybe<PresentationGroupPublic>;
  getPresentationGroupPublic?: Maybe<PresentationGroupPublic>;
  getPresentationGroupSubmissions: PresentationGroupSubmissionPaginatedResult;
  /** Query for getting Presentation Groups for the Presentation Folder */
  getPresentationGroupsByFolder: Array<PresentationGroup>;
  getPresentationGroupsSubmissions: PresentationGroupSubmissionPaginatedResult;
  getPresentationGroupsSubmissionsPublic: PresentationTalentPublicPaginatedResult;
  /** Gets info about media which should be archived */
  getPresentationMediaDownloadInfo: Array<MediaToArchive>;
  /** Retrieve Presentation by GUID */
  getPresentationPublic: PresentationPublic;
  /** Retrieve Presentations by IDs */
  getPresentations: Array<Presentation>;
  /** Retrieve Presentations by project */
  getPresentationsByProject: Array<Presentation>;
  /** Get profileAccessToken details */
  getProfileByAccessToken?: Maybe<ProfileAccessToken>;
  getProfileByLegacyPersonId?: Maybe<Array<BauProfile>>;
  getProfileDirectMessageContext: ProfileDirectMessageContextAndProfile;
  getProfileSearchUnionFilterByRole: Array<ProfileUnionFilter>;
  /** Get all the profiles by artist */
  getProfilesByArtist: Array<Profile>;
  /** Get backlot project seach result */
  getProjectBacklot: ProjectBacklotSearchResultPaginatedResult;
  /** Get all roles for a Project by role Status */
  getProjectRolesByOrg: Array<Role>;
  /** Project Series */
  getProjectSeriesById: Array<ProjectSeries>;
  getPublishedOrganizationIdsForProject: Array<Scalars['Float']>;
  /** Retrieve Presentations for representative organizations */
  getRepPresentations: RepPresentations;
  getReportById?: Maybe<ReportUnion>;
  getReportByIdPublic?: Maybe<ReportUnion>;
  getReportCsvUrlById?: Maybe<Scalars['String']>;
  getReportCsvUrlByIdPublic?: Maybe<Scalars['String']>;
  getRepresentativeSystemRole: Array<RepresentationSystemRole>;
  /** Get all closed roles for representatives */
  getRepsReadOnlyProjectRoles: Array<Role>;
  /** Get role change log */
  getRoleChangeLogs: Array<RoleChangeLog>;
  getRolesFitForYouNotificationContext: Array<RolesFitForYouContext>;
  getSavedSearchByIds?: Maybe<Array<SavedSearch>>;
  getSavedSearchList?: Maybe<SavedSearchList>;
  getSavedSearchTypes: Array<Scalars['String']>;
  getScheduleReminderContext: ScheduleReminderContextNotificationContextAndRecipient;
  /** @deprecated Use getScheduleSlots instead */
  getScheduleSlot?: Maybe<ScheduleSlot>;
  getScheduleSlots?: Maybe<Array<ScheduleSlot>>;
  /** Fetch Slots summary by scheduleId */
  getScheduleSlotsSummary: Array<ScheduleSlotSummary>;
  getScheduleTimeframe: ScheduleTimeframe;
  /** Fetch Schedules Details by Ids */
  getSchedules: Array<ScheduleDetail>;
  getSlugByProfileId?: Maybe<CustomSlug>;
  getSubRequestsCount: Scalars['Float'];
  /** Get submissions for given role and profile id */
  getSubmissionByRoleProfile: Array<Submission>;
  getSubmissionFeedback: PresentationSubmissionFeedback;
  getSubmissionIdsByAuditionGroups: Array<Scalars['BigInt']>;
  /** Query for getting Submissions IDs by Presentation Group */
  getSubmissionIdsByPresentationGroupId: Array<Scalars['BigInt']>;
  getSubmissionParticipants: Array<Scalars['String']>;
  /** Get submission profile information for given submission id */
  getSubmissionProfile: SubmissionProfile;
  /** Returns list of submissions for given profileId */
  getSubmissionsByProfileId: Array<Submission>;
  getSubmissionsByProjectAndProfile: SubmissionPaginatedResult;
  getSubmissionsByRole: SubmissionPaginatedResult;
  /** Returns submissions data for workflow */
  getSubmissionsDataForWorkflow: Array<SubmissionWorkflow>;
  /** Returns submissions data for workflow. (Short) */
  getSubmissionsDataForWorkflowShort: Array<SubmissionWorkflowShort>;
  getTalentAccessProfileContext: TalentAccessProfileContextNotificationContextAndRecipient;
  /** Gets talent pool for the schedule */
  getTalentPoolForSchedule: Array<ScheduleTalentPool>;
  getTalentScoutHistory?: Maybe<TalentScoutPageListResult>;
  /** Gets the count for all the profiles for a project. */
  getTotalProfileCount: Scalars['Float'];
  /** Get account id, phone and legacy email for UNCLAIMED profile */
  getUnclaimedProfileRecipientAccount?: Maybe<Account>;
  /** Get account id, phone and legacy email for UNCLAIMED profile */
  getUnclaimedProfilesRecipientAccount?: Maybe<Array<UnclaimedAccount>>;
  getUnresolvedProhibitedSlugs: PaginatedProhibitedCustomSlug;
  getVerificationEmailContext: BaseEmailContextNotificationContextAndRecipient;
  getWelcomeContext: WelcomeContextNotificationContextAndRecipient;
  gloves: Array<Glove>;
  hairColors: Array<HairColor>;
  hairStyles: Array<HairStyle>;
  hasSavedSearchNotifyOption: Scalars['Boolean'];
  /** Hash a password */
  hashPassword: Scalars['String'];
  hatSizes: Array<HatSize>;
  heights: Array<Height>;
  hips: Array<Hip>;
  inseams: Array<Inseam>;
  isArtistEnrolledInBenefit: Array<HasActiveEnrolledBenefit>;
  isPresentationMediaArchiveReady: Scalars['Boolean'];
  lookupBauUsername: Scalars['String'];
  mediaAsset?: Maybe<Media>;
  mediaAssets: Array<Media>;
  mediaFileTypes: Array<MediaFileType>;
  mediaRequestDetails: MediaRequest;
  mediaRequestResponseCount: Array<MediaResponseCounts>;
  mediaSubRequestDetails: MediaSubRequest;
  mediaTypes: Array<MediaType>;
  multipleBirths: Array<MultipleBirth>;
  myAccount: Account;
  necks: Array<Neck>;
  organizationByAccount?: Maybe<Array<OrganizationAccount>>;
  organizationFeature?: Maybe<Array<Feature>>;
  /** Get projects for a organization */
  organizationProjects: ProjectBasePaginatedResult;
  /** Get projects groups for a organization */
  organizationProjectsGroups: OrganizationProjectGroupPaginatedResult;
  organizationStatus: Array<OrganizationStatus>;
  /** Return Organization objects from an id array */
  organizations: Array<Organization>;
  organizationsByRoleSubmissionsAndName: OrganizationsByRoleSubmissionsAndNamePaginatedResult;
  organizationsIndexable: Scalars['JSON'];
  paidTypes: Array<PaidType>;
  pants: Array<Pants>;
  /** Returns partial BAU account details by provided legacy login and instance */
  partialBauAccountDetails: PartialBauAccountDetails;
  permissions: Array<PermissionsModel>;
  petSizes: Array<PetSize>;
  pets: Array<Pet>;
  piercings: Array<Piercing>;
  preCancel: Retention;
  /** Query for getting Presentation Group details */
  presentationGroupDetails: Array<PresentationGroup>;
  presentationTypes: Array<PresentationType>;
  previewMediaPurchase: MediaInvoice;
  previewSubscriptionPurchase: SubscriptionInvoice;
  previewSubscriptionRenewal: SubscriptionRenewal;
  previousScheduleResponses: Array<ScheduleInvitePreviousResponse>;
  /** Get all the profile ids by account */
  profileIdsByAccounts?: Maybe<Array<ProfileIdsByAccount>>;
  /** Returns List Details for given list ID */
  profileListDetails: ProfileList;
  profileStatuses: Array<ProfileStatus>;
  profileTypes: Array<ProfileType>;
  /** Get all the profiles in the db */
  profiles?: Maybe<Array<Profile>>;
  profilesIndexable: Scalars['JSON'];
  /** Get project details by id */
  project: Project;
  /** Get Roles for project */
  projectRoles: Array<Role>;
  projectStatus: Array<ProjectStatus>;
  projectTypes: Array<ProjectType>;
  projectUnions: Array<TalentUnion>;
  /** Get projects by id's */
  projects: Array<Project>;
  /** Get project(s) modification(s) by id's */
  projectsModifications: Array<Project>;
  props: Array<Prop>;
  /**
   * Retrieve a public profile. Supports guid argument for backwards compatibility,
   * but prefer pathParam which can be either a guid or slug.
   */
  publicProfile?: Maybe<PublicProfile>;
  publicProjectName?: Maybe<Scalars['String']>;
  publicRoleName?: Maybe<Scalars['String']>;
  publicationHistory: RolePublicationPaginatedResult;
  pursuings: Array<Pursuing>;
  repOrganizationList?: Maybe<Array<RepOrganizationList>>;
  representationAgeCategory: Array<RepresentationAgeCategory>;
  representationCategories: Array<RepresentationCategory>;
  representationCategoryByType: Array<RepresentationCategory>;
  representationTypes: Array<RepresentationType>;
  /** Returns Representative List Details for given list ID */
  representativeListDetails: RepresentativeList;
  repsWorksheet: RepsWorksheetItemPagedList;
  repsWorksheetFilters: RepsWorksheetFilters;
  repsWorksheetGroups: RepsWorksheetGroups;
  requests: RequestPagedList;
  /** Get the role from modification by RoleId */
  roleModification?: Maybe<Role>;
  rolePreviousStatus: Array<RolePreviousStatus>;
  roleSidesVisibilities: Array<RoleSidesVisibility>;
  roleTargets: Array<RoleTarget>;
  roleTracker: RoleTrackerPagedList;
  roleTrackerGroups: RoleTrackerGroups;
  roleTrackerStats: RoleTrackerStatsResponse;
  roleTypes: Array<RoleType>;
  roleUnions: Array<RoleUnion>;
  /** Get the roles by id's */
  roles: Array<Role>;
  rolesIndexable: Scalars['JSON'];
  /**
   * Get roster invitation history for provided talent's name and email
   * @deprecated Use rosterTalentInvitationHistory instead
   */
  rosterInvitationTalentHistory: Array<RosterTalentInvitation>;
  rosterInvitations: RosterTalentInvitationPagedList;
  /** Get details for talent invitation by token */
  rosterTalentInvitationByToken: RosterTalentInvitation;
  /** Get roster invitation history for provided talent's name and email */
  rosterTalentInvitationHistory: Array<RosterTalentInvitation>;
  /** Fetch Schedule Details by scheduleId */
  scheduleDetails: ScheduleDetail;
  scheduleInviteRequestNotes: ScheduleInviteRequestNotes;
  scheduleInviteResponseStatusCounts: Array<ScheduleInviteResponseStatusCounts>;
  scheduleInviteSubRequestDetails: ScheduleInviteSubRequest;
  scheduleResponses: Array<ScheduleInviteResponse>;
  scheduleResponsesCanceled: Array<ScheduleInviteResponse>;
  searchAccounts: AccountItemPagedList;
  searchArtistMedia: PaginatedSearchArtistMediaResult;
  searchCompanies: OrganizationItemPagedList;
  searchCompanyDivisions: OrganizationItemPagedList;
  searchMedia: SearchMediaPaginatedResult;
  searchNotifications: NotificationPagedList;
  /** Search in organizations */
  searchOrganizations: OrganizationSearchResult;
  /** Searching Profiles */
  searchProfiles?: Maybe<ProfileSearch>;
  searchProfilesByOrgId: ProfileSearchResultByOrgIdPaginatedResult;
  /** Project Series */
  searchProjectSeriesByOrg: ProjectSeriesPaginatedResult;
  searchProjectsByOrg: ProjectPaginatedResult;
  searchRepresentatives: RepresentativeSearchResult;
  /** Searching Schedules */
  searchSchedules?: Maybe<SchedulePaginatedResult>;
  searchSubmissions: SubmissionPaginatedResult;
  searchSubmissionsByAuditionGroupId: SubmissionPaginatedResult;
  seekingRepresentations: Array<SeekingRepresentation>;
  sexualSituations: Array<SexualSituation>;
  shoeModifier: Array<ShoeModifier>;
  shoeSizes: Array<ShoeSize>;
  sizeModifiers: Array<SizeModifier>;
  skillLevels: Array<SkillLevel>;
  skills: Array<Skill>;
  sleeves: Array<Sleeve>;
  /** Fetch Slots with Submissions by scheduleId */
  slotsWithSubmissionsByScheduleId: Array<Slot>;
  sportEquipments: Array<SportEquipment>;
  states: Array<State>;
  submissionStatuses: Array<SubmissionStatus>;
  submissionTrends: SubmissionTrendsResult;
  /** Returns list of submissions for submissionIds */
  submissions: Array<Submission>;
  submissionsIndexable: Scalars['JSON'];
  systemRoles: Array<SystemRole>;
  /** Returns talent response by rep's request id */
  talentResponse?: Maybe<RepWorksheetResponse>;
  /** Searching Talent Scout Profiles */
  talentScoutSearch?: Maybe<ProfileSearch>;
  talentUnions: Array<TalentUnion>;
  tattoos: Array<Tattoo>;
  tileSchemaValues: Array<TileSchemaValue>;
  timeZones: Array<TimeZone>;
  tshirtSizes: Array<TShirtSize>;
  underBusts: Array<UnderBust>;
  uniqueAttributes: Array<UniqueAttribute>;
  vehicleMakes: Array<VehicleMake>;
  vehicleTypeMakes: Array<VehicleTypeMake>;
  vehicleTypes: Array<VehicleType>;
  /** Redirect user to Onboarding or B21 application */
  verifyAccount: Scalars['Json'];
  verifyAccountByBauCookie: Scalars['Json'];
  verifyToken: Scalars['Boolean'];
  voiceOverMediaRequests: Array<MediaSubRequest>;
  waists: Array<Waist>;
  wardrobes: Array<Wardrobe>;
  weights: Array<Weight>;
  wfApiHealth: Scalars['String'];
  willingnesses: Array<Willingness>;
};

export type QueryAccountStatusesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryAccountsIndexableArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryActiveProjectListWithVisibilitiesArgs = {
  filters?: InputMaybe<ProjectFilterInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryActiveProjectRolesArgs = {
  id: Scalars['Float'];
};

export type QueryArtistByAccountArgs = {
  accountId: Scalars['Float'];
};

export type QueryArtistByAccountWithUnclaimedArgs = {
  accountId: Scalars['Float'];
};

export type QueryArtistStorageUtilizationArgs = {
  artistId?: InputMaybe<Scalars['Int']>;
};

export type QueryArtistSubscriptionsArgs = {
  input: ArtistSubscriptionInput;
};

export type QueryArtistsArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryArtistsIndexableArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryAskQuestionRequestDetailsArgs = {
  id: Scalars['ID'];
};

export type QueryAskQuestionSubRequestDetailsArgs = {
  id: Scalars['ID'];
};

export type QueryAuditionGroupArgs = {
  groupId: Scalars['Int'];
};

export type QueryAuditionGroupIdsByProfileArgs = {
  profileIds: Array<Scalars['Int']>;
  sessionId: Scalars['Int'];
};

export type QueryAuditionGroupingArgs = {
  groupBy: AuditionsGroupBy;
  searchPage?: InputMaybe<SearchPageGroupInput>;
  sortOptions?: InputMaybe<Array<AuditionsSearchSortOptionsInput>>;
};

export type QueryAuditionGroupsBySessionArgs = {
  pageInput?: InputMaybe<PageInput>;
  sessionId: Scalars['Int'];
};

export type QueryAuditionSessionsByProjectArgs = {
  filter?: InputMaybe<AuditionSessionFilterInput>;
  pageInput?: InputMaybe<PageInput>;
  projectId: Array<Scalars['Int']>;
  sortInstruction?: InputMaybe<AuditionSessionSortInstructionInput>;
};

export type QueryAuditionSourcesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryAuditionTypesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryAuditionsArgs = {
  auditionsSearchFilter?: InputMaybe<AuditionsFilterInput>;
  searchPage?: InputMaybe<SearchPageInputWf>;
  sortOptions?: InputMaybe<Array<AuditionsSearchSortOptionsInput>>;
};

export type QueryAuthenticateArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type QueryBillingPlansArgs = {
  input?: InputMaybe<BillingPlansInput>;
};

export type QueryBuildRolesOrganizationMatchNotificationPayloadsArgs = {
  messageToReps?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Int'];
  projectId: Scalars['Int'];
  publicationTimestamp: Scalars['DateTime'];
  roleIds: Array<Scalars['Int']>;
};

export type QueryBustsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryCastingBillboardRolesArgs = {
  castingBillBoardFilters: CastingBillboardSearchInput;
  paginationConfig: PageInput;
  sortInput?: InputMaybe<SortConditionInput>;
};

export type QueryCdWorksheetArgs = {
  filters: CdWorksheetFilterInput;
  page?: InputMaybe<PageInput>;
};

export type QueryChestSizesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryCoatsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryComparePasswordArgs = {
  hashedPassword: Scalars['String'];
  password: Scalars['String'];
};

export type QueryCompensationArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryConflictsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryContractStatusesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryCountSubmissionsPerRoleArgs = {
  input: SubmissionCountPerRoleInput;
};

export type QueryCountriesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryCountryWithDmaArgs = {
  isLimitedOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCreditTypesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryCreditsArgs = {
  profileId: Scalars['String'];
};

export type QueryCupSizesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryDivisionCastingOrganizationsArgs = {
  organizationId: Scalars['Int'];
  page?: InputMaybe<PageInput>;
};

export type QueryDmasArgs = {
  input?: InputMaybe<FilterInput>;
  isLimitedOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDocumentTypesArgs = {
  input?: InputMaybe<FilterInput>;
  onlySelectable?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDressesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryEmailHistoryArgs = {
  page: PageInput;
  projectId: Scalars['Int'];
};

export type QueryEthnicAppearancesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryEyeColorsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryFacialHairsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryFeatureByTypeArgs = {
  divisionOnly?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<Scalars['Float']>;
};

export type QueryFeaturesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryGenderAppearancesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryGenderIdentitiesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryGeneralSizesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryGetAccountByIdsArgs = {
  accountIds: Array<Scalars['ID']>;
};

export type QueryGetAccountIdsArgs = {
  page: PageInput;
};

export type QueryGetAccountSessionsArgs = {
  accountId: Scalars['Float'];
};

export type QueryGetAccountSystemRoleCodesArgs = {
  accountIds: Array<Scalars['ID']>;
};

export type QueryGetActiveOnboardedPaypalAccountsArgs = {
  input: AccountPaypalLocationInput;
};

export type QueryGetActiveSubRequestsBySubmissionIdsArgs = {
  requestStatus?: InputMaybe<Array<RequestStatusUnion>>;
  requestType?: InputMaybe<RequestType>;
  submissionIds: Array<Scalars['Int']>;
};

export type QueryGetActivityLogsBySubmissionIdArgs = {
  id: Scalars['Int'];
  page: PageInput;
};

export type QueryGetArtistArgs = {
  artistId: Scalars['Int'];
};

export type QueryGetArtistByProfileIdArgs = {
  profileId: Scalars['Int'];
};

export type QueryGetArtistByProfilesArgs = {
  profileIds: Array<Scalars['Int']>;
};

export type QueryGetAuditionHistoryArgs = {
  page: PageInput;
  profileId: Scalars['Int'];
};

export type QueryGetAuditionSessionArgs = {
  sessionId: Scalars['Int'];
};

export type QueryGetAuditionTalentPoolArgs = {
  sessionId: Scalars['Int'];
};

export type QueryGetAuditionTalentPoolFlatArgs = {
  sessionId: Scalars['Int'];
};

export type QueryGetAvailableScheduleTimeShiftingArgs = {
  scheduleId: Scalars['Int'];
  timeframeId: Scalars['Int'];
};

export type QueryGetAvailableSubmissionIdsForRequestArgs = {
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  searchInput?: InputMaybe<SubmissionSearchInput>;
  tileSchemeValueCode?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGetBillingInfoArgs = {
  input: GetBillingInfoInput;
};

export type QueryGetBlockedKeywordsPaginatedListArgs = {
  filter?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCalendarLastHistoryChangeArgs = {
  calendarId: Scalars['ID'];
};

export type QueryGetCalendarRevisionArgs = {
  calendarId: Scalars['ID'];
  version: Scalars['Int'];
};

export type QueryGetCastingListProfileArgs = {
  input: GetCastingListProfileArgs;
};

export type QueryGetCastingListsByProfileAndProjectArgs = {
  pageNumber?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  profileId: Scalars['ID'];
  projectId: Scalars['Int'];
};

export type QueryGetChatMessageByTopicArgs = {
  pageSize: Scalars['Int'];
  topicKey: Scalars['String'];
};

export type QueryGetCollaboratorInviteByIdArgs = {
  input: GetCollaboratorInviteInput;
};

export type QueryGetCollaboratorsByProjectIdArgs = {
  page?: InputMaybe<PageInput>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetCollaboratorsForOrganizationArgs = {
  input?: InputMaybe<GetCollaboratorByEmailInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetCommentsForPresentationSubmissionPublicArgs = {
  groupId: Scalars['Int'];
  guid: Scalars['String'];
  submissionId: Scalars['BigInt'];
  userName?: InputMaybe<Scalars['String']>;
};

export type QueryGetCommentsForPresentationSubmissionPublicBySubmissionIdArgs = {
  page: PageInput;
  presentationId: Scalars['Int'];
  submissionIds: Array<Scalars['BigInt']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type QueryGetCommentsForSubmissionArgs = {
  submissionId: Scalars['BigInt'];
};

export type QueryGetCommentsForSubmissionsArgs = {
  page?: InputMaybe<PageInput>;
  submissionIds: Array<Scalars['BigInt']>;
  userName?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGetCompanyDictionaryArgs = {
  filters?: InputMaybe<SearchOrganizationsFiltersArgs>;
  page: PageInput;
};

export type QueryGetComplexAccountInfoArgs = {
  accountId: Scalars['Float'];
};

export type QueryGetFitForYouValuesForArtistArgs = {
  artistId?: InputMaybe<Scalars['Float']>;
};

export type QueryGetFormArgs = {
  formId: Scalars['Float'];
};

export type QueryGetFormResponseArgs = {
  formId: Scalars['Float'];
  formResponseId?: InputMaybe<Scalars['Float']>;
};

export type QueryGetFormResponsesForMediaRequestArgs = {
  formId: Scalars['Float'];
  mediaRequestId: Scalars['String'];
};

export type QueryGetFormsArgs = {
  filter?: InputMaybe<FormsFilterArgs>;
  pageInput?: InputMaybe<PageInput>;
  sortInstruction?: InputMaybe<FormSortConditionInput>;
};

export type QueryGetFreeCreditItemsArgs = {
  artistId: Scalars['Float'];
};

export type QueryGetIdByLegacyIdArgs = {
  input: RedirectInput;
};

export type QueryGetInternalNotesByAccountIdArgs = {
  accountId: Scalars['Int'];
  entityTypeCode?: InputMaybe<Array<EntityTypeEnum>>;
  noteTypeCode?: InputMaybe<NoteTypeEnum>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetInternalWelcomeContextArgs = {
  id: Scalars['Float'];
};

export type QueryGetLanguageByLocaleArgs = {
  locale: Scalars['String'];
};

export type QueryGetLastActivityLogBySubmissionIdsArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryGetLastNoteArgs = {
  entityIds: Array<Scalars['BigInt']>;
  entityTypeCode: EntityTypeEnum;
  noteTypeCode?: InputMaybe<NoteTypeEnum>;
  organizationId?: InputMaybe<Scalars['Int']>;
};

export type QueryGetLegacyAccountDetailsArgs = {
  profileId: Scalars['Int'];
};

export type QueryGetListsArgs = {
  filter?: CastingListFilterInput;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
};

export type QueryGetMediaByArtistArgs = {
  artistId?: InputMaybe<Scalars['Float']>;
  mediaTypeCode: Scalars['String'];
};

export type QueryGetMediaMediaTypeByIdArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryGetMessageAfterIdArgs = {
  afterMessageId: Scalars['String'];
  direction: SearchDirection;
  pageSize: Scalars['Int'];
  topicKey: Scalars['String'];
};

export type QueryGetNotificationEntitySubscriptionByAccountArgs = {
  accountId: Scalars['Float'];
};

export type QueryGetNotificationEntitySubscriptionByArtistArgs = {
  artistId: Scalars['Float'];
};

export type QueryGetNotificationNotificationSubscriptionByAccountArgs = {
  accountId: Scalars['Float'];
};

export type QueryGetNotificationNotificationSubscriptionByAccountsArgs = {
  accountIds: Array<Scalars['Int']>;
};

export type QueryGetNotificationNotificationSubscriptionByArtistArgs = {
  artistId: Scalars['Float'];
};

export type QueryGetNotificationNotificationSubscriptionByArtistsArgs = {
  artistIds: Array<Scalars['Int']>;
};

export type QueryGetOrganizationProfileListsArgs = {
  order?: InputMaybe<ListSortOrderEnum>;
  page: PageInput;
};

export type QueryGetOrganizationRepresentativeListsArgs = {
  input?: InputMaybe<GetOrganizationRepresentativeListsInput>;
  page?: InputMaybe<PageInput>;
};

export type QueryGetOrganizationsByRepTypeAndNameArgs = {
  input: OrganizationsByRepTypeAndNameInput;
};

export type QueryGetPackageFolderTalentArgs = {
  folderId: Scalars['Int'];
  pageInput?: InputMaybe<PageInput>;
  profileIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryGetPhoneArgs = {
  entityType: EntityTypeEnum;
  phoneNumber: Scalars['String'];
};

export type QueryGetPlanFeaturesArgs = {
  planId: Scalars['ID'];
};

export type QueryGetPresentationByFiltersArgs = {
  input: PresentationFilterInput;
};

export type QueryGetPresentationFiltersDataArgs = {
  presentationId: Scalars['Int'];
};

export type QueryGetPresentationFolderTalentArgs = {
  pageInput?: InputMaybe<PageInput>;
  presentationFolderIds: Array<Scalars['Int']>;
  profileIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryGetPresentationFolderTalentByIdArgs = {
  groupTalentIds: Array<Scalars['Int']>;
};

export type QueryGetPresentationFoldersArgs = {
  presentationId: Scalars['Int'];
};

export type QueryGetPresentationFoldersDetailsArgs = {
  folderIds: Array<Scalars['Int']>;
};

export type QueryGetPresentationGroupByFiltersArgs = {
  groupId: Scalars['Int'];
  guid: Scalars['String'];
  input: PresentationFilterInput;
};

export type QueryGetPresentationGroupPublicArgs = {
  groupId: Scalars['Int'];
  guid: Scalars['String'];
};

export type QueryGetPresentationGroupSubmissionsArgs = {
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
  page: PageInput;
  presentationGroupId: Scalars['Int'];
};

export type QueryGetPresentationGroupsByFolderArgs = {
  folderId: Scalars['Int'];
  hasPicks?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetPresentationGroupsSubmissionsArgs = {
  clientNames?: InputMaybe<Array<Scalars['String']>>;
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
  page: PageInput;
  presentationGroupIds: Array<Scalars['Int']>;
};

export type QueryGetPresentationGroupsSubmissionsPublicArgs = {
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
  page: PageInput;
  presentationGroupIds: Array<Scalars['Int']>;
  presentationId: Scalars['Int'];
  userName?: InputMaybe<Scalars['String']>;
};

export type QueryGetPresentationMediaDownloadInfoArgs = {
  folderId: Scalars['Int'];
  groupId?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPresentationPublicArgs = {
  guid: Scalars['String'];
};

export type QueryGetPresentationsArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryGetPresentationsByProjectArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
};

export type QueryGetProfileByAccessTokenArgs = {
  accessToken: Scalars['String'];
};

export type QueryGetProfileDirectMessageContextArgs = {
  input: ProfileDirectMessageContextInput;
};

export type QueryGetProfileSearchUnionFilterByRoleArgs = {
  roleIds: Array<Scalars['Int']>;
};

export type QueryGetProfilesByArtistArgs = {
  artistId: Scalars['Int'];
};

export type QueryGetProjectBacklotArgs = {
  input: ProjectBacklotSearchInput;
  page?: InputMaybe<PageInput>;
};

export type QueryGetProjectRolesByOrgArgs = {
  orgId: Scalars['Float'];
  projectId: Scalars['Float'];
  statusFilter: Array<Scalars['String']>;
};

export type QueryGetProjectSeriesByIdArgs = {
  input: GetProjectSeriesByIdInput;
};

export type QueryGetPublishedOrganizationIdsForProjectArgs = {
  page: PageInput;
  projectId: Scalars['Float'];
};

export type QueryGetRepPresentationsArgs = {
  page?: InputMaybe<PageInput>;
  sortOrder?: InputMaybe<SortOrderEnum>;
};

export type QueryGetReportByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetReportByIdPublicArgs = {
  id: Scalars['ID'];
  publicKey: Scalars['ID'];
};

export type QueryGetReportCsvUrlByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetReportCsvUrlByIdPublicArgs = {
  id: Scalars['ID'];
  publicKey: Scalars['ID'];
};

export type QueryGetRepsReadOnlyProjectRolesArgs = {
  id: Scalars['Float'];
};

export type QueryGetRoleChangeLogsArgs = {
  projectId: Scalars['Float'];
};

export type QueryGetRolesFitForYouNotificationContextArgs = {
  artistAndRoles: Array<FfyContextInput>;
  messageToTalents?: InputMaybe<Scalars['String']>;
};

export type QueryGetSavedSearchByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGetSavedSearchListArgs = {
  keyOffset?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
};

export type QueryGetScheduleReminderContextArgs = {
  calendarId: Scalars['Int'];
  calendarSlotId: Scalars['Int'];
  calendarTypeId: Scalars['Int'];
  submissionId: Scalars['Int'];
};

export type QueryGetScheduleSlotArgs = {
  id: Scalars['ID'];
};

export type QueryGetScheduleSlotsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGetScheduleSlotsSummaryArgs = {
  scheduleId: Scalars['ID'];
};

export type QueryGetScheduleTimeframeArgs = {
  timeframeId: Scalars['Int'];
};

export type QueryGetSchedulesArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryGetSlugByProfileIdArgs = {
  includeExpired?: InputMaybe<Scalars['Boolean']>;
  profileId: Scalars['Int'];
};

export type QueryGetSubRequestsCountArgs = {
  id: Scalars['ID'];
  status?: InputMaybe<Array<RequestStatus>>;
};

export type QueryGetSubmissionByRoleProfileArgs = {
  input: GetSubmissionByRoleProfileInput;
};

export type QueryGetSubmissionFeedbackArgs = {
  submissionId: Scalars['BigInt'];
};

export type QueryGetSubmissionIdsByAuditionGroupsArgs = {
  groupIds: Array<Scalars['Int']>;
};

export type QueryGetSubmissionIdsByPresentationGroupIdArgs = {
  groupId: Scalars['Int'];
};

export type QueryGetSubmissionParticipantsArgs = {
  roleId: Scalars['Float'];
};

export type QueryGetSubmissionProfileArgs = {
  id: Scalars['BigInt'];
};

export type QueryGetSubmissionsByProfileIdArgs = {
  input?: InputMaybe<SubmissionByProfileInput>;
  profileId: Scalars['Float'];
};

export type QueryGetSubmissionsByProjectAndProfileArgs = {
  pageInput?: InputMaybe<PageInput>;
  profileIds: Array<Scalars['Int']>;
  projectIds: Array<Scalars['Int']>;
};

export type QueryGetSubmissionsByRoleArgs = {
  pageInput?: InputMaybe<PageInput>;
  roleIds: Array<Scalars['Int']>;
  submissionStatusCodes: Array<Scalars['String']>;
  tileSchemeCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGetSubmissionsDataForWorkflowArgs = {
  ids: Array<Scalars['BigInt']>;
};

export type QueryGetSubmissionsDataForWorkflowShortArgs = {
  ids: Array<Scalars['BigInt']>;
};

export type QueryGetTalentAccessProfileContextArgs = {
  input: TalentAccessProfileContextInput;
};

export type QueryGetTalentPoolForScheduleArgs = {
  mediaRequestFilter?: InputMaybe<MediaRequestFilterType>;
  pageInput: PageInput;
  roleIds: Array<Scalars['Int']>;
  scheduleId: Scalars['Int'];
  scheduleSelectInput?: InputMaybe<SchedulesSelectInput>;
  tileSchemaValueCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGetTalentScoutHistoryArgs = {
  page?: InputMaybe<PageInput>;
};

export type QueryGetTotalProfileCountArgs = {
  id: Scalars['Float'];
};

export type QueryGetUnclaimedProfileRecipientAccountArgs = {
  profileId: Scalars['Float'];
};

export type QueryGetUnclaimedProfilesRecipientAccountArgs = {
  profileIds: Array<Scalars['Int']>;
};

export type QueryGetVerificationEmailContextArgs = {
  audience?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type QueryGetWelcomeContextArgs = {
  id: Scalars['Float'];
};

export type QueryGlovesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryHairColorsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryHairStylesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryHasSavedSearchNotifyOptionArgs = {
  artistId: Scalars['ID'];
};

export type QueryHashPasswordArgs = {
  password: Scalars['String'];
};

export type QueryHatSizesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryHeightsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryHipsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryInseamsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryIsArtistEnrolledInBenefitArgs = {
  input: HasActiveEnrolledBenefitInput;
};

export type QueryIsPresentationMediaArchiveReadyArgs = {
  fileKey: Scalars['String'];
};

export type QueryLookupBauUsernameArgs = {
  legacyEmail: Scalars['String'];
  legacyInstance: Scalars['String'];
  legacyPhone: Scalars['String'];
};

export type QueryMediaAssetArgs = {
  id: Scalars['Int'];
};

export type QueryMediaAssetsArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryMediaFileTypesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryMediaRequestDetailsArgs = {
  id: Scalars['ID'];
};

export type QueryMediaRequestResponseCountArgs = {
  id: Scalars['ID'];
};

export type QueryMediaSubRequestDetailsArgs = {
  id: Scalars['ID'];
};

export type QueryMediaTypesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryMultipleBirthsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryNecksArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryOrganizationByAccountArgs = {
  accountId: Scalars['Float'];
};

export type QueryOrganizationFeatureArgs = {
  organizationId: Scalars['Float'];
};

export type QueryOrganizationProjectsArgs = {
  filter?: InputMaybe<OrganizationProjectFilterInput>;
  name?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Int'];
  page?: InputMaybe<PageInput>;
  sortInstruction?: InputMaybe<SortConditionInput>;
  statusFilter?: InputMaybe<ProjectStatusFilterEnum>;
};

export type QueryOrganizationProjectsGroupsArgs = {
  filter?: InputMaybe<OrganizationProjectFilterInput>;
  page?: InputMaybe<PageInput>;
  projectGroupType: ProjectGroupType;
};

export type QueryOrganizationStatusArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryOrganizationsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryOrganizationsByRoleSubmissionsAndNameArgs = {
  divisionOrAgencyName?: InputMaybe<Scalars['String']>;
  page: PageInput;
  roleId: Scalars['Int'];
};

export type QueryOrganizationsIndexableArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryPaidTypesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryPantsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryPartialBauAccountDetailsArgs = {
  input: PartialBauAccountDetailsInput;
};

export type QueryPetSizesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryPetsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryPiercingsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryPreCancelArgs = {
  input: PreCancelInput;
};

export type QueryPresentationGroupDetailsArgs = {
  groupIds: Array<Scalars['Int']>;
};

export type QueryPresentationTypesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryPreviewMediaPurchaseArgs = {
  input: PurchaseMediaInput;
};

export type QueryPreviewSubscriptionPurchaseArgs = {
  input: PreviewSubscriptionInput;
};

export type QueryPreviewSubscriptionRenewalArgs = {
  input: PreviewSubscriptionRenewalInput;
};

export type QueryPreviousScheduleResponsesArgs = {
  scheduleId: Scalars['Int'];
  submissionIds: Array<Scalars['Int']>;
};

export type QueryProfileIdsByAccountsArgs = {
  accountIds: Array<Scalars['ID']>;
};

export type QueryProfileListDetailsArgs = {
  listId: Scalars['Float'];
};

export type QueryProfileStatusesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryProfileTypesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryProfilesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryProfilesIndexableArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryProjectArgs = {
  id: Scalars['Float'];
};

export type QueryProjectRolesArgs = {
  id: Scalars['Float'];
  page?: InputMaybe<PageInput>;
  roleStatusCodes?: InputMaybe<Array<RoleStatusCodes>>;
  roleStatusFilter?: InputMaybe<RoleStatusFilter>;
};

export type QueryProjectStatusArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryProjectTypesArgs = {
  input?: InputMaybe<FilterInput>;
  onlySelectable?: InputMaybe<Scalars['Boolean']>;
};

export type QueryProjectUnionsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryProjectsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryProjectsModificationsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryPropsArgs = {
  input?: InputMaybe<FilterInput>;
  onlySelectable?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPublicProfileArgs = {
  guid?: InputMaybe<Scalars['String']>;
  pathParam?: InputMaybe<Scalars['String']>;
};

export type QueryPublicProjectNameArgs = {
  guid: Scalars['String'];
};

export type QueryPublicRoleNameArgs = {
  guid: Scalars['String'];
};

export type QueryPublicationHistoryArgs = {
  page: PageInput;
  projectId: Scalars['Int'];
};

export type QueryPursuingsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryRepOrganizationListArgs = {
  input: RepOrganizationListInput;
  page?: InputMaybe<PageInput>;
};

export type QueryRepresentationAgeCategoryArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryRepresentationCategoriesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryRepresentationCategoryByTypeArgs = {
  typeId: Scalars['Float'];
};

export type QueryRepresentationTypesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryRepresentativeListDetailsArgs = {
  listId: Scalars['Float'];
};

export type QueryRepsWorksheetArgs = {
  filters?: InputMaybe<RepsWorksheetFiltersArgs>;
  page: PageInput;
  sortBy?: InputMaybe<RepWorksheetFieldOrder>;
  sortOptions?: InputMaybe<Array<RepWorksheetOrderOption>>;
};

export type QueryRepsWorksheetGroupsArgs = {
  filters?: InputMaybe<RepsWorksheetFiltersArgs>;
  groupId?: InputMaybe<Scalars['ID']>;
  groupType: RepsWorksheetGroupType;
  limit: Scalars['Int'];
  sortOrder?: InputMaybe<SortOrder>;
};

export type QueryRequestsArgs = {
  page: PageInput;
  projectId: Scalars['Int'];
};

export type QueryRoleModificationArgs = {
  roleId: Scalars['Float'];
};

export type QueryRolePreviousStatusArgs = {
  roleIds: Array<Scalars['Float']>;
  skipRoleStatuses: Array<Scalars['String']>;
};

export type QueryRoleSidesVisibilitiesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryRoleTargetsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryRoleTrackerArgs = {
  filters?: InputMaybe<RoleTrackerFiltersArgs>;
  page?: InputMaybe<PageInput>;
  sortOptions?: InputMaybe<Array<RoleTrackerOrderOption>>;
};

export type QueryRoleTrackerGroupsArgs = {
  groupId?: InputMaybe<Scalars['ID']>;
  groupType: RoleTrackerGroupType;
  limit: Scalars['Int'];
  sortOrder?: InputMaybe<SortOrder>;
};

export type QueryRoleTrackerStatsArgs = {
  interval?: InputMaybe<Scalars['Int']>;
  intervalType?: InputMaybe<RoleTrackerStatsIntervalEnum>;
  timeframe?: InputMaybe<Scalars['Int']>;
};

export type QueryRoleTypesArgs = {
  input?: InputMaybe<RoleTypeFilterInput>;
};

export type QueryRoleUnionsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryRolesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryRolesIndexableArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryRosterInvitationTalentHistoryArgs = {
  filter: RosterInvitationTalentHistoryFilterInput;
};

export type QueryRosterInvitationsArgs = {
  page: PageInput;
};

export type QueryRosterTalentInvitationByTokenArgs = {
  token: Scalars['String'];
};

export type QueryRosterTalentInvitationHistoryArgs = {
  filter: Array<RosterInvitationTalentHistoryFilterInput>;
};

export type QueryScheduleDetailsArgs = {
  id: Scalars['ID'];
};

export type QueryScheduleInviteRequestNotesArgs = {
  scheduleId: Scalars['Int'];
};

export type QueryScheduleInviteResponseStatusCountsArgs = {
  scheduleIds: Array<Scalars['Int']>;
};

export type QueryScheduleInviteSubRequestDetailsArgs = {
  id: Scalars['ID'];
};

export type QueryScheduleResponsesArgs = {
  scheduleId: Scalars['Int'];
};

export type QueryScheduleResponsesCanceledArgs = {
  scheduleId: Scalars['Int'];
};

export type QuerySearchAccountsArgs = {
  filters?: InputMaybe<SearchAccountsFiltersArgs>;
  page: PageInput;
};

export type QuerySearchArtistMediaArgs = {
  input: SearchArtistMediaInput;
};

export type QuerySearchCompaniesArgs = {
  filters?: InputMaybe<SearchOrganizationsFiltersArgs>;
  page: PageInput;
};

export type QuerySearchCompanyDivisionsArgs = {
  filters?: InputMaybe<SearchOrganizationsFiltersArgs>;
  page: PageInput;
};

export type QuerySearchMediaArgs = {
  input: SearchMediaInput;
};

export type QuerySearchNotificationsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<SearchNotificationFiltersArgs>;
  orderBy?: InputMaybe<NotificationOrderByInput>;
  page: PageInput;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type QuerySearchOrganizationsArgs = {
  input?: InputMaybe<OrganizationSearchInput>;
  page?: InputMaybe<PageInput>;
};

export type QuerySearchProfilesArgs = {
  input: ProfileSearchInput;
  page?: InputMaybe<PageInput>;
  resultType?: InputMaybe<SearchResultType>;
  sortOptions?: InputMaybe<Array<ProfileSearchSortOption>>;
};

export type QuerySearchProfilesByOrgIdArgs = {
  page?: InputMaybe<PageInput>;
  partialStageName?: InputMaybe<Scalars['String']>;
};

export type QuerySearchProjectSeriesByOrgArgs = {
  input: GetProjectSeriesByNameInput;
  page?: InputMaybe<PageInput>;
};

export type QuerySearchProjectsByOrgArgs = {
  page?: InputMaybe<PageInput>;
  partialProjectName?: InputMaybe<Scalars['String']>;
};

export type QuerySearchRepresentativesArgs = {
  input: SearchRepresentativeInput;
  page: PageInput;
};

export type QuerySearchSchedulesArgs = {
  input: ScheduleSearchInput;
  page: PageInput;
};

export type QuerySearchSubmissionsArgs = {
  input: SubmissionSearchInput;
  page?: InputMaybe<PageInput>;
  resultType?: InputMaybe<SearchResultType>;
  searchPage?: InputMaybe<SearchPageInput>;
  sortOptions?: InputMaybe<Array<SubmissionSearchSortOptionsInput>>;
};

export type QuerySearchSubmissionsByAuditionGroupIdArgs = {
  auditionGroupId: Scalars['Int'];
  page: PageInput;
};

export type QuerySeekingRepresentationsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QuerySexualSituationsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryShoeModifierArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryShoeSizesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QuerySizeModifiersArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QuerySkillLevelsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QuerySkillsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QuerySleevesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QuerySlotsWithSubmissionsByScheduleIdArgs = {
  scheduleId: Scalars['ID'];
};

export type QuerySportEquipmentsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryStatesArgs = {
  input?: InputMaybe<StateFilterInput>;
  page?: InputMaybe<PageInput>;
};

export type QuerySubmissionStatusesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QuerySubmissionTrendsArgs = {
  input: SubmissionTrendsInput;
};

export type QuerySubmissionsArgs = {
  ids: Array<Scalars['BigInt']>;
};

export type QuerySubmissionsIndexableArgs = {
  ids: Array<Scalars['Int']>;
};

export type QuerySystemRolesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryTalentResponseArgs = {
  id: Scalars['ID'];
};

export type QueryTalentScoutSearchArgs = {
  input: ProfileSearchInput;
  page?: InputMaybe<PageInput>;
  resultType?: InputMaybe<SearchResultType>;
  sortOptions?: InputMaybe<Array<ProfileSearchSortOption>>;
};

export type QueryTalentUnionsArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryTattoosArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryTileSchemaValuesArgs = {
  input?: InputMaybe<FilterInput>;
  tileSchema?: InputMaybe<TileSchemaEnum>;
};

export type QueryTimeZonesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryTshirtSizesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryUnderBustsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryUniqueAttributesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryVehicleMakesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryVehicleTypesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryVerifyAccountArgs = {
  input: VerifyAccountInput;
};

export type QueryVerifyAccountByBauCookieArgs = {
  input: VerifyAccountByCookieInput;
};

export type QueryVerifyTokenArgs = {
  authToken: Scalars['String'];
};

export type QueryVoiceOverMediaRequestsArgs = {
  roleId: Scalars['Int'];
  submissionIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryWaistsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryWardrobesArgs = {
  input?: InputMaybe<FilterInput>;
};

export type QueryWeightsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryWillingnessesArgs = {
  input?: InputMaybe<FilterInput>;
};

export enum RatePerEnum {
  FlatFee = 'FLAT_FEE',
  Per_2Hours = 'PER_2_HOURS',
  Per_3Hours = 'PER_3_HOURS',
  Per_4Hours = 'PER_4_HOURS',
  Per_5Hours = 'PER_5_HOURS',
  Per_6Hours = 'PER_6_HOURS',
  Per_7Hours = 'PER_7_HOURS',
  Per_8Hours = 'PER_8_HOURS',
  Per_9Hours = 'PER_9_HOURS',
  Per_10Hours = 'PER_10_HOURS',
  PerDay = 'PER_DAY',
  PerHour = 'PER_HOUR',
  PerWeek = 'PER_WEEK',
  SessionFee = 'SESSION_FEE',
}

/** RatePerType object */
export type RatePerType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Redirect object type */
export type Redirect = {
  /** B21 GUID */
  guid?: Maybe<Scalars['String']>;
  /** B21 ID */
  id?: Maybe<Scalars['ID']>;
  /** B21 Invitation Type Audition/Callback */
  inviationType?: Maybe<Scalars['String']>;
  /** B21 ID */
  presentationFolderId?: Maybe<Scalars['Int']>;
  /** B21 ID */
  presentationGroupId?: Maybe<Scalars['Int']>;
  /** B21 Profile GUID */
  profileGuid?: Maybe<Scalars['String']>;
  /** B21 Project ID */
  projectId?: Maybe<Scalars['ID']>;
};

/** Input type for entity type redirect */
export type RedirectInput = {
  /** Legacy ID from BAU */
  legacyId?: InputMaybe<Scalars['String']>;
  /** BAU Instance */
  legacyInstance: Scalars['String'];
  /** Legacy presentation group id */
  legacyPresentationGroupId?: InputMaybe<Scalars['String']>;
  legacyUserName?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Region */
export type Region = {
  /** code */
  code: Scalars['String'];
  /** country */
  country?: Maybe<Country>;
  /** dma objects */
  dmas: Array<Dma>;
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type RegionItem = {
  /** code */
  code: Scalars['String'];
  country?: Maybe<CountryItem>;
  /** Is this enbaled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type RegisterDeviceInput = {
  deviceType: DeviceTokenType;
  token: Scalars['String'];
};

export type RemoveBillingInfoInput = {
  billingInfoId: Scalars['String'];
};

export type RemoveProfileFromCastingListError = ValidationError;

export type RemoveProfileFromCastingListInput = {
  listId: Scalars['ID'];
  listProfileIds: Array<Scalars['ID']>;
};

export type RemoveProfileFromCastingListResult = {
  error?: Maybe<RemoveProfileFromCastingListError>;
  success: Scalars['Boolean'];
};

export type RenewSubscriptionInput = {
  billingInfoId: Scalars['String'];
  subscriptionId: Scalars['Int'];
};

export type ReorderError = MissingDataError | NotFoundError;

export type ReorderResult = {
  error?: Maybe<ReorderError>;
  success: Scalars['Boolean'];
};

export type RepOrganizationList = {
  name: Scalars['String'];
  organizationId: Scalars['Int'];
  parentName?: Maybe<Scalars['String']>;
};

export type RepOrganizationListInput = {
  countryCode?: InputMaybe<Array<Scalars['String']>>;
  divisionsOnly?: InputMaybe<Scalars['Boolean']>;
  organizationParentId?: InputMaybe<Array<Scalars['Int']>>;
  organizationStatusCode: Array<Scalars['String']>;
  representationTypeCode: Array<Scalars['String']>;
  sortColumn?: InputMaybe<RepOrganizationListSortEnum>;
};

/** Rep organization sorting enum */
export enum RepOrganizationListSortEnum {
  Name = 'NAME',
  ParentName = 'PARENT_NAME',
}

/** Rep Get Presentation by org Response */
export type RepPresentations = {
  /** Presentation objects */
  records: Array<Presentation>;
  /** Total count of presentation objects in db */
  totalCount: Scalars['Int'];
};

/** Status enum for rep's worksheet */
export enum RepRequestStatus {
  Cancelled = 'CANCELLED',
  CancelledRescheduled = 'CANCELLED_RESCHEDULED',
  ChangeRequested = 'CHANGE_REQUESTED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  NewMessage = 'NEW_MESSAGE',
  NewRequest = 'NEW_REQUEST',
  NotSent = 'NOT_SENT',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  Rescheduled = 'RESCHEDULED',
  RescheduleRequested = 'RESCHEDULE_REQUESTED',
  ReviewResponse = 'REVIEW_RESPONSE',
  Updated = 'UPDATED',
}

export enum RepRoleStatusEnum {
  InReview = 'IN_REVIEW',
  Reviewed = 'REVIEWED',
}

export enum RepWorksheetFieldOrder {
  DueDate = 'dueDate',
  ProjectName = 'projectName',
  ReceivedAt = 'receivedAt',
  SlotDate = 'slotDate',
  Status = 'status',
  TalentName = 'talentName',
  Type = 'type',
}

export type RepWorksheetItem = {
  /** Date when request was created */
  createdAt: Scalars['DateTime'];
  /** How many times a request has been forwarded to a talent */
  forwardCount?: Maybe<Scalars['Int']>;
  /** Request ID */
  id: Scalars['ID'];
  /** @deprecated Will be removed */
  project?: Maybe<RepWorksheetProject>;
  /** Response to a request */
  response?: Maybe<RepWorksheetResponse>;
  /**
   * Timezone of a Schedule
   * @deprecated Will be removed
   */
  scheduleTimeZone?: Maybe<WfTimeZone>;
  /**
   * Date of Slot
   * @deprecated Will be removed
   */
  slotDate?: Maybe<Scalars['DateTime']>;
  /** Request Status */
  status: RepRequestStatus;
  /** @deprecated Will be removed */
  talent?: Maybe<RepWorksheetTalent>;
  /** Talent's response to a request */
  talentResponse?: Maybe<RepWorksheetResponse>;
  /** Request Type */
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RepWorksheetOrderOption = {
  field: RepWorksheetFieldOrder;
  sortOrder?: InputMaybe<SortOrder>;
};

export type RepWorksheetOrganization = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** Talent info */
export type RepWorksheetProfile = {
  defaultSubmissionNote?: Maybe<Scalars['String']>;
  /** Talent's email */
  email?: Maybe<Scalars['String']>;
  /** Talent's ID */
  id: Scalars['Int'];
  /** Talents phone */
  phone?: Maybe<Scalars['String']>;
  profileMainDivision?: Maybe<RepWorksheetOrganization>;
  /** Talent's stage name */
  stageName: Scalars['String'];
  talentUnions?: Maybe<Array<RepWorksheetTalentUnion>>;
};

/** Project info */
export type RepWorksheetProject = {
  /** Casting company */
  castingCompany?: Maybe<Scalars['String']>;
  /** Project's ID */
  id: Scalars['Int'];
  /** Project's name */
  name: Scalars['String'];
};

/** Response type for Rep Worksheet */
export type RepWorksheetResponse = {
  mediaList?: Maybe<Array<MediaResponseItem>>;
  /**
   * The media files sent in response
   * @deprecated please use mediaList
   */
  medias?: Maybe<Array<WfMedia>>;
  /** Response note */
  note?: Maybe<Scalars['String']>;
  repliedAt?: Maybe<Scalars['DateTime']>;
  /** Response status */
  status: ResponseStatus;
};

export type RepWorksheetRole = {
  id: Scalars['Int'];
  name: Scalars['String'];
  project: RepWorksheetProject;
};

export type RepWorksheetScheduleAddress = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<RepWorksheetScheduleAddressCountry>;
  id: Scalars['Int'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  line3?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  otherDetail?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<RepWorksheetScheduleAddressState>;
};

export type RepWorksheetScheduleAddressCountry = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RepWorksheetScheduleAddressState = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type RepWorksheetScheduleDetail = {
  address?: Maybe<RepWorksheetScheduleAddress>;
  id?: Maybe<Scalars['ID']>;
  timeZone?: Maybe<RepWorksheetTimeZone>;
};

/** Submission info */
export type RepWorksheetSubmission = {
  /** Talent's ID */
  id: Scalars['Int'];
  isCreatedByCD?: Maybe<Scalars['Boolean']>;
  /** Submission's note */
  note?: Maybe<Scalars['String']>;
  profile?: Maybe<RepWorksheetProfile>;
  role: RepWorksheetRole;
};

/** Talent info */
export type RepWorksheetTalent = {
  /** Talent's cover */
  coverUrl?: Maybe<Scalars['String']>;
  /** Talent's email */
  email?: Maybe<Scalars['String']>;
  /** Talent's ID */
  id: Scalars['Int'];
  /** Talents phone */
  phone?: Maybe<Scalars['String']>;
  /** Talent's stage name */
  stageName: Scalars['String'];
};

export type RepWorksheetTalentUnion = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Measurement object */
export type RepWorksheetTimeZone = {
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  standardName: Scalars['String'];
};

export type ReportContactInfoModel = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ReportDma = {
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** Report Media */
export type ReportMediaModel = {
  fileKey: Scalars['String'];
  guid: Scalars['String'];
  id: Scalars['ID'];
  mediaStorageStatus: MediaStorageStatus;
  mediaType: MediaType;
  thumbnail?: Maybe<ReportMediaModel>;
  transformation?: Maybe<MediaTransformation>;
};

/** Report Media Sides */
export type ReportMediaObjectModel = {
  mediaObject: ReportMediaModel;
  name: Scalars['String'];
};

export type ReportPublicationSettings = {
  dmas: Array<ReportDma>;
  publicationSetting: ReportSetting;
};

export type ReportRoleDateType = {
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type ReportRoleDateWithLocation = {
  cityName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  roleDateType?: Maybe<ReportRoleDateType>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type ReportSetting = {
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum ReportStatusEnum {
  Completed = 'Completed',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Pending = 'Pending',
}

export enum ReportTypeEnum {
  AgencyCallList = 'AgencyCallList',
  AllTalentSubmission = 'AllTalentSubmission',
  BookingCallList = 'BookingCallList',
  FormsReport = 'FormsReport',
  Presentation = 'Presentation',
  PresentationLog = 'PresentationLog',
  PresentationPick = 'PresentationPick',
  PresentationSizeSheets = 'PresentationSizeSheets',
  ProjectOverview = 'ProjectOverview',
  ProjectOverviewCd = 'ProjectOverviewCD',
  ProjectSubmission = 'ProjectSubmission',
  Schedule = 'Schedule',
  ScheduleReport = 'ScheduleReport',
  ScheduleSizeSheets = 'ScheduleSizeSheets',
  TalentSubmission = 'TalentSubmission',
}

export type ReportUnion =
  | AgencyCallListReportModel
  | AllTalentSubmissionModel
  | BookingCallListsReportModel
  | FormsReportModel
  | PresentationLogReportModel
  | PresentationPickReportModel
  | PresentationSizeSheetsReportModel
  | ProjectOverviewCdReportModel
  | ProjectOverviewReportModel
  | ProjectSubmissionReportModel
  | ScheduleReportModel
  | ScheduleSizeSheetsReportModel
  | TalentSubmissionModel;

/** RepresentationAgeCategory object */
export type RepresentationAgeCategory = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum RepresentationAgeCategoryEnum {
  Adult = 'ADULT',
  AdultYouth = 'ADULT_YOUTH',
  Youth = 'YOUTH',
}

/** RepresentationCategory object */
export type RepresentationCategory = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum RepresentationCategoryEnum {
  Background = 'BACKGROUND',
  Commercial = 'COMMERCIAL',
  Dance = 'DANCE',
  Hosting = 'HOSTING',
  Influencers = 'INFLUENCERS',
  Principal = 'PRINCIPAL',
  Print = 'PRINT',
  RealPeople = 'REAL_PEOPLE',
  SportsStunts = 'SPORTS_STUNTS',
  Theatrical = 'THEATRICAL',
  VoiceOver = 'VOICE_OVER',
}

export type RepresentationSystemRole = {
  code?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  /** Primary ID */
  id: Scalars['ID'];
  internal?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Float']>;
  systemRoleId?: Maybe<Scalars['Float']>;
};

/** RepresentationType object */
export type RepresentationType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum RepresentationTypeEnum {
  Agency = 'AGENCY',
  CastingCompany = 'CASTING_COMPANY',
  DecisionMaker = 'DECISION_MAKER',
  Management = 'MANAGEMENT',
  ProjectCreator = 'PROJECT_CREATOR',
  SharedProjectUser = 'SHARED_PROJECT_USER',
  Studio = 'STUDIO',
}

/** Representative List Details */
export type RepresentativeList = {
  /** Account of list creator */
  account?: Maybe<Account>;
  /** List creation date */
  created: Scalars['DateTime'];
  /** List's Creator Account */
  createdBy?: Maybe<Account>;
  /** List ID */
  id: Scalars['ID'];
  /** Representative List Type Object */
  listType?: Maybe<ListType>;
  /** Account of last list editor */
  modifiedBy?: Maybe<Account>;
  /** List Name */
  name: Scalars['String'];
  /** Organization of represe */
  organization?: Maybe<Organization>;
  /** Organization ID */
  organizationId?: Maybe<Scalars['Int']>;
  representatives: Array<Organization>;
  /** Total count of representatives */
  totalRepresentatives: Scalars['Float'];
  /** Last list updated date */
  updated?: Maybe<Scalars['DateTime']>;
};

/** Paginated result with [RepresentativeList] objects */
export type RepresentativeListPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of RepresentativeList objects */
  objects: Array<RepresentativeList>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Representative search result */
export type RepresentativeSearchResult = {
  /** List of representatives */
  organizations: Array<Organization>;
  /** Total records count */
  totalCount: Scalars['Int'];
};

/** Enum of Represented Talents Submissions Types */
export enum RepsSubmissionTypeEnum {
  Selected = 'SELECTED',
  Submitted = 'SUBMITTED',
}

export type RepsWorksheetFilters = {
  organizations?: Maybe<Array<OrganizationFilter>>;
  profiles?: Maybe<Array<ProfileFilter>>;
  projects?: Maybe<Array<ProjectFilter>>;
};

export type RepsWorksheetFiltersArgs = {
  dueDateFrom?: InputMaybe<Scalars['DateTime']>;
  dueDateTo?: InputMaybe<Scalars['DateTime']>;
  fromOrgIds?: InputMaybe<Array<Scalars['Int']>>;
  profileIds?: InputMaybe<Array<Scalars['Int']>>;
  projectIds?: InputMaybe<Array<Scalars['Int']>>;
  receivedFrom?: InputMaybe<Scalars['DateTime']>;
  receivedTo?: InputMaybe<Scalars['DateTime']>;
  responseStatus?: InputMaybe<ResponseStatus>;
  slotDateFrom?: InputMaybe<Scalars['DateTime']>;
  slotDateTo?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<RepRequestStatus>>;
  talentResponseStatus?: InputMaybe<ResponseStatus>;
  type?: InputMaybe<RequestType>;
};

export type RepsWorksheetGroup = {
  details?: Maybe<RepsWorksheetGroupDetails>;
  id: Scalars['ID'];
  itemCount: Scalars['Int'];
};

export type RepsWorksheetGroupDetails = RepWorksheetProfile | RepWorksheetProject;

export enum RepsWorksheetGroupType {
  Profile = 'PROFILE',
  Project = 'PROJECT',
}

export type RepsWorksheetGroups = {
  groups: Array<RepsWorksheetGroup>;
  nextGroupId?: Maybe<Scalars['ID']>;
};

export type RepsWorksheetItem = {
  /** Date when request was created */
  createdAt: Scalars['DateTime'];
  dueDate?: Maybe<Scalars['DateTime']>;
  /** How many times a request has been forwarded to a talent */
  forwardCount?: Maybe<Scalars['Int']>;
  /** Request ID */
  id: Scalars['ID'];
  lastMessageId?: Maybe<Scalars['String']>;
  /** Response to a request */
  response?: Maybe<RepWorksheetResponse>;
  /** Schedule */
  schedule?: Maybe<RepWorksheetScheduleDetail>;
  /** Date of Slot */
  slotDate?: Maybe<Scalars['DateTime']>;
  /** Request Status */
  status: RepRequestStatus;
  submission?: Maybe<RepWorksheetSubmission>;
  /** Talent's response to a request */
  talentResponse?: Maybe<RepWorksheetResponse>;
  /** Request Type */
  type: Scalars['String'];
  unreadMessageCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RepsWorksheetItemPagedList = {
  data: Array<RepsWorksheetItem>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type Request = {
  /** returns aggregation of all the Reponse Statuses by Talents for Media Requests */
  cdMediaResponseStatusCounts?: Maybe<CdMediaResponseCounts>;
  /** Request ID */
  id: Scalars['ID'];
  /** returns if request is draft or not */
  isDraft?: Maybe<Scalars['Boolean']>;
  lastActionAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  sentAt?: Maybe<Scalars['DateTime']>;
  /** Request Type */
  type: RequestType;
};

/** Forms associated with Request */
export type RequestForms = {
  /** List of forms associated with the request */
  forms: Array<FormInfo>;
  /** ID of media request */
  requestId: Scalars['String'];
};

export enum RequestMediaType {
  Audio = 'AUDIO',
  Pdf = 'PDF',
  Photo = 'PHOTO',
  Video = 'VIDEO',
}

export type RequestPagedList = {
  data: Array<Request>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum RequestSendTo {
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  NewAndPending = 'NEW_AND_PENDING',
  OnlyNew = 'ONLY_NEW',
  Pending = 'PENDING',
}

export enum RequestStatus {
  AdditionalMediaAdded = 'ADDITIONAL_MEDIA_ADDED',
  Cancelled = 'CANCELLED',
  CancelledRescheduled = 'CANCELLED_RESCHEDULED',
  ChangeRequested = 'CHANGE_REQUESTED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  MediaAdded = 'MEDIA_ADDED',
  NewRequest = 'NEW_REQUEST',
  NotSent = 'NOT_SENT',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  Rescheduled = 'RESCHEDULED',
  ReviewResponse = 'REVIEW_RESPONSE',
}

export enum RequestStatusEnum {
  ActionRequiredNewRequest = 'ACTION_REQUIRED_NEW_REQUEST',
  ActionRequiredRescheduled = 'ACTION_REQUIRED_RESCHEDULED',
  ActionRequiredReviewResponse = 'ACTION_REQUIRED_REVIEW_RESPONSE',
  ActionRequiredSeeUpdates = 'ACTION_REQUIRED_SEE_UPDATES',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  PendingChangeRequested = 'PENDING_CHANGE_REQUESTED',
  PendingOnHold = 'PENDING_ON_HOLD',
}

/** Request status union */
export enum RequestStatusUnion {
  AdditionalMediaAdded = 'ADDITIONAL_MEDIA_ADDED',
  Answered = 'ANSWERED',
  Cancelled = 'CANCELLED',
  CancelledRescheduled = 'CANCELLED_RESCHEDULED',
  ChangeRequested = 'CHANGE_REQUESTED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  MediaAdded = 'MEDIA_ADDED',
  NotSent = 'NOT_SENT',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  Rescheduled = 'RESCHEDULED',
  RescheduleRequested = 'RESCHEDULE_REQUESTED',
  Updated = 'UPDATED',
}

export type RequestSubmission = {
  /** Request ID */
  id: Scalars['ID'];
  /** Submission ID */
  submissionId: Scalars['ID'];
};

export enum RequestType {
  AskQuestion = 'ASK_QUESTION',
  Audition = 'AUDITION',
  Callback = 'CALLBACK',
  Media = 'MEDIA',
  RosterInvitation = 'ROSTER_INVITATION',
}

export enum RequestTypeEnum {
  AskAQuestion = 'ASK_A_QUESTION',
  AuditionInvite = 'AUDITION_INVITE',
  CallbackInvite = 'CALLBACK_INVITE',
  QuestionToRepresentative = 'QUESTION_TO_REPRESENTATIVE',
  SubmissionMediaRequest = 'SUBMISSION_MEDIA_REQUEST',
  TalentRosterInvite = 'TALENT_ROSTER_INVITE',
}

export type RequestUnion = AskQuestionRequest | MediaRequest;

export type ResetSessionObserverUrlInput = {
  scheduleId: Scalars['ID'];
};

export type ResetSessionObserverUrlOutput = {
  virtualAudition?: Maybe<ScheduleVirtualAudition>;
};

export enum ResponseStatus {
  AdditionalMediaAdded = 'ADDITIONAL_MEDIA_ADDED',
  Answered = 'ANSWERED',
  ChangeRequested = 'CHANGE_REQUESTED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  MediaAdded = 'MEDIA_ADDED',
  RescheduleRequested = 'RESCHEDULE_REQUESTED',
}

export enum ResponseStatusEnum {
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Open = 'OPEN',
  Pending = 'PENDING',
  Sent = 'SENT',
}

export type RestoreArchivedMediaInput = {
  mediaIds: Array<Scalars['ID']>;
};

export type RestoreArchivedMediaResponse = {
  media: Array<Media>;
  success: Scalars['Boolean'];
};

export type RestoreDeletedMediaInput = {
  mediaIds: Array<Scalars['ID']>;
};

export type RestoreDeletedMediaResponse = {
  media: Array<Media>;
  success: Scalars['Boolean'];
};

export type ResumeSubscriptionInput = {
  subscriptionId: Scalars['Int'];
};

export type Retention = {
  /** The URL to redirect the user to start the retention process */
  url: Scalars['String'];
};

/** Role object */
export type Role = {
  /** Ages of roles showing in years */
  ageInYears: Scalars['Boolean'];
  /** Role age max month */
  ageMaxMonth?: Maybe<Scalars['Float']>;
  /** Role age min month */
  ageMinMonth?: Maybe<Scalars['Float']>;
  /** Age range */
  ageRange?: Maybe<Scalars['String']>;
  /** Audition Session ID */
  auditionSessionId?: Maybe<Scalars['ID']>;
  /** Number of intended talents */
  backgroundTalentIntended?: Maybe<Scalars['Int']>;
  /** Is close submissions */
  closeSubmissions?: Maybe<Scalars['Boolean']>;
  /** Role compensations */
  compensations?: Maybe<Array<RoleCompensation>>;
  countOfPairedRepsAndCB: RoleCountOfPairedRepsAndCb;
  /** Created Time */
  created?: Maybe<Scalars['DateTime']>;
  /** Currency Object */
  currency?: Maybe<Currency>;
  /** Role description */
  description: Scalars['String'];
  /** Displayed Role Ethnic Appearance */
  displayedRoleEthnicAppearance: Scalars['String'];
  /** Displayed Role Ethnic Appearance */
  displayedRoleGenderAppearance: Scalars['String'];
  /** Displayed Role Union */
  displayedRoleUnion: Scalars['String'];
  /** Role Ethnic Appearance */
  ethnicAppearance: Array<EthnicAppearance>;
  fieldRestrictions?: Maybe<Array<RoleFieldRestriction>>;
  /** Gender */
  genderAppearance?: Maybe<Array<GenderAppearance>>;
  /** Role public GUID */
  guid?: Maybe<Scalars['String']>;
  /** Role Primary ID */
  id: Scalars['ID'];
  /** Role internal name */
  internalName?: Maybe<Scalars['String']>;
  /** Is role published after others */
  isNew?: Maybe<Scalars['Boolean']>;
  /** Is open call */
  isOpenCall?: Maybe<Scalars['Boolean']>;
  isReadOnlyAccess: Scalars['Boolean'];
  /** Stores information about the date when the Project Creator user last saw submissions */
  lastViewedSubmissionPage?: Maybe<Scalars['DateTime']>;
  /** DMA */
  location?: Maybe<Dma>;
  /** Returns list of role date with location */
  locationDates?: Maybe<Array<RoleDateWithLocation>>;
  /** Location ID */
  locationId?: Maybe<Scalars['ID']>;
  /** Role name */
  name: Scalars['String'];
  /** Order of appearance on which sequence role will be displayed */
  orderOfAppearance?: Maybe<Scalars['Int']>;
  /** Organizationwise submissions for the role */
  organizationSubmissions: Array<Submission>;
  /** Paid Type */
  paidType?: Maybe<PaidType>;
  /** Role Photos */
  photos?: Maybe<Array<RoleMedia>>;
  /** Project */
  project: Project;
  /** Role project ID */
  projectId: Scalars['ID'];
  /** Project name */
  projectName?: Maybe<Scalars['String']>;
  projectReleaseLocations: Array<Dma>;
  projectType?: Maybe<ProjectType>;
  /** Project Unions */
  projectUnion?: Maybe<Array<ProjectUnionDetails>>;
  /** Publication settings for the role */
  publicationSettings: Array<RolePublicationSetting>;
  /** Role rate */
  rate?: Maybe<Scalars['Float']>;
  /** Role max rate */
  rateMax?: Maybe<Scalars['Float']>;
  /** Rate note */
  rateNote?: Maybe<Scalars['String']>;
  /** Rate Per Object */
  ratePer?: Maybe<RatePerType>;
  /** Rate summary */
  rateSummary?: Maybe<Scalars['String']>;
  /** Is rate type */
  rateType: Scalars['Boolean'];
  repSelectedSubmissionsCount: Scalars['Int'];
  repStatus: RepRoleStatusEnum;
  repSubmittedSubmissionsCount: Scalars['Int'];
  /** The media request instruction */
  requestMediaInstruction: Scalars['String'];
  requestMediaTypes: Array<Scalars['String']>;
  /** Ethnicity / nationality description */
  roleEthnicityNationality: Scalars['String'];
  /** Info about paired reps orgs */
  rolePairedOrganizations: RoleOrganizationPairPaginatedResult;
  /** Role Parent ID */
  roleParentId?: Maybe<Scalars['ID']>;
  /** Role Previous Status */
  rolePreviousStatus?: Maybe<RoleStatus>;
  /** Role published date when role become ACTIVE Or REQUEST_ADDITIONAL_SUBMISSIONS */
  rolePublishedAt?: Maybe<Scalars['DateTime']>;
  /** Role Status */
  roleStatus?: Maybe<RoleStatus>;
  /** Role Type */
  roleType?: Maybe<RoleType>;
  /** Role Union */
  roleUnion?: Maybe<RoleUnion>;
  /** Is rush call */
  rushCall: Scalars['Boolean'];
  /** Is scale */
  scale: Scalars['Boolean'];
  /** Scale modifier ID */
  scaleModifierId?: Maybe<Scalars['ID']>;
  /** Session */
  session?: Maybe<AuditionSession>;
  /** Sexy description */
  sexualSituationDescription: Scalars['String'];
  /** Sexual situations */
  sexualSituations?: Maybe<Array<SexualSituation>>;
  /** Role Sides */
  sides?: Maybe<Array<RoleMedia>>;
  /** Role Sides Visibility */
  sidesVisibility?: Maybe<RoleSidesVisibility>;
  /** Role skills */
  skills: Array<Skill>;
  /** Spot */
  spot?: Maybe<Scalars['String']>;
  submissionCount: Array<RoleSubmissionCount>;
  /** The submission due date */
  submissionDueDate?: Maybe<Scalars['DateTime']>;
  /** The submission instruction */
  submissionInstruction: Scalars['String'];
  /**
   * Submissions for the role. Fetches all the submissions for a role filtered by
   * the scheduled criteria for all users which system role are not equal to
   * Agent(reps), otherwise submissions will be filtered by profile organization
   * participation id.
   */
  submissions: Array<Submission>;
  /** Project system role id */
  systemRoleId?: Maybe<Scalars['ID']>;
  /** TimeZone */
  timeZone?: Maybe<TimeZone>;
  /** Wardrobe */
  wardrobe?: Maybe<Scalars['String']>;
  /** Work requirement */
  workRequirement: Scalars['String'];
};

/** Role object */
export type RoleOrganizationSubmissionsArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};

/** Role object */
export type RoleRolePairedOrganizationsArgs = {
  pageInput?: InputMaybe<PageInput>;
};

/** Role object */
export type RoleSidesArgs = {
  checkSidesVisibility?: InputMaybe<Scalars['Boolean']>;
};

/** Role object */
export type RoleSubmissionsArgs = {
  auditionScheduled?: InputMaybe<Scalars['Boolean']>;
  callbackScheduled?: InputMaybe<Scalars['Boolean']>;
  profileTypeCode?: InputMaybe<Scalars['String']>;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  statusCode?: InputMaybe<Array<Scalars['String']>>;
};

/** Role Previous Status */
export type RoleChangeLog = {
  /** Role Change Log Primary ID */
  id: Scalars['ID'];
  isOpenCall: Scalars['Boolean'];
  /** Modified Date */
  modifiedAt: Scalars['DateTime'];
  /** Account of role modifier */
  modifiedBy?: Maybe<Account>;
  /** Modifier Account ID */
  modifiedById: Scalars['Int'];
  /** Role */
  role?: Maybe<Role>;
  roleId: Scalars['Int'];
};

/** Role compensation object */
export type RoleCompensation = {
  compensation: Compensation;
  otherCompensation?: Maybe<Scalars['String']>;
  roleCompensationId: Scalars['Float'];
};

/** Input type for role compensation */
export type RoleCompensationInput = {
  /** Role compensation type code */
  compensationCode: Scalars['String'];
  /** Role other compensation description */
  otherCompensation?: InputMaybe<Scalars['String']>;
};

/** Role Paired orgs info */
export type RoleCountOfPairedRepsAndCb = {
  /** Is current role shared with Casting Billboard users(talents) */
  isSharedWithCB: Scalars['Boolean'];
  /**
   * Represents the Count of paired reps organizations for current published role,
   * or count of potentially paired reps organizations for not published role
   */
  repsOrgs: Scalars['Int'];
};

/** Input type for Role creation */
export type RoleCreateInput = {
  ageInYears?: InputMaybe<Scalars['Boolean']>;
  ageMaxMonths?: InputMaybe<Scalars['Int']>;
  ageMinMonths?: InputMaybe<Scalars['Int']>;
  ageMinor?: InputMaybe<Scalars['Int']>;
  backgroundTalentIntended?: InputMaybe<Scalars['Float']>;
  closeSubmissions?: InputMaybe<Scalars['Boolean']>;
  compensations?: InputMaybe<Array<RoleCompensationInput>>;
  /** Should session be created */
  createSubmissionMediaSession?: InputMaybe<Scalars['Boolean']>;
  /** CODE of Currency object */
  currencyCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** List of Ethnic Appearances associated with role */
  ethnicAppearances?: InputMaybe<Array<Scalars['String']>>;
  fieldRestrictionSettings?: InputMaybe<Array<RoleFieldRestrictionInput>>;
  internalName?: InputMaybe<Scalars['String']>;
  locationCode?: InputMaybe<Scalars['String']>;
  locationDateInformations?: InputMaybe<Array<RoleDateWithLocationInput>>;
  name: Scalars['String'];
  /** Paid Type CODE */
  paidTypeCode?: InputMaybe<Scalars['String']>;
  /** RoleMediaInput array of role media input for add/ update the media along with the relevant role */
  photos?: InputMaybe<Array<RoleMediaInput>>;
  projectId: Scalars['ID'];
  /** Rate */
  rate?: InputMaybe<Scalars['Float']>;
  /** rate max */
  rateMax?: InputMaybe<Scalars['Float']>;
  rateNote?: InputMaybe<Scalars['String']>;
  /** CODE of RatePer object */
  ratePerCode?: InputMaybe<Scalars['String']>;
  rateSummary?: InputMaybe<Scalars['String']>;
  rateType?: InputMaybe<Scalars['Boolean']>;
  /** List of Representative List Ids */
  representativesLists?: InputMaybe<Array<Scalars['Float']>>;
  requestMediaInstruction?: InputMaybe<Scalars['String']>;
  requestMediaTypeCodes?: InputMaybe<Array<Scalars['String']>>;
  roleEthnicityNationality?: InputMaybe<Scalars['String']>;
  roleGenderCodes?: InputMaybe<Array<Scalars['String']>>;
  roleParentId?: InputMaybe<Scalars['ID']>;
  /** List of Publication Setting Input */
  rolePublicationSettings?: InputMaybe<Array<RolePublicationSettingInput>>;
  /** Roles Side Visibility */
  roleSidesVisibilityCode?: InputMaybe<Scalars['String']>;
  roleSkills?: InputMaybe<Array<RoleSkillInput>>;
  roleStatusCode: Scalars['String'];
  roleType: RoleTypeInput;
  /** Role Union CODE */
  roleUnionCode?: InputMaybe<Scalars['String']>;
  rushCall?: InputMaybe<Scalars['Boolean']>;
  /** Scale */
  scale?: InputMaybe<Scalars['Boolean']>;
  sexualSituationCodes?: InputMaybe<Array<Scalars['String']>>;
  sexualSituationDescription?: InputMaybe<Scalars['String']>;
  sides?: InputMaybe<Array<RoleMediaInput>>;
  spot?: InputMaybe<Scalars['String']>;
  submissionDueDate?: InputMaybe<Scalars['DateTime']>;
  submissionInstruction?: InputMaybe<Scalars['String']>;
  /** Time Zone Code */
  timeZoneCode?: InputMaybe<Scalars['String']>;
  wardrobe?: InputMaybe<Scalars['String']>;
  workRequirement?: InputMaybe<Scalars['String']>;
};

/** Role Date type object */
export type RoleDateType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum RoleDateTypeEnum {
  AuditionLocation = 'AUDITION_LOCATION',
  CallbackAuditionLocation = 'CALLBACK_AUDITION_LOCATION',
  FittingLocation = 'FITTING_LOCATION',
  WorkingLocation = 'WORKING_LOCATION',
}

/** Role Date with Location object */
export type RoleDateWithLocation = {
  cityName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  /** RoleDateLocation Primary ID */
  id: Scalars['ID'];
  /** Role Date Type */
  roleDateType?: Maybe<RoleDateType>;
  startDate?: Maybe<Scalars['DateTime']>;
};

/** Role's Date with Location input type */
export type RoleDateWithLocationInput = {
  /** ID of RoleDate object. Used for update existing date. */
  dateId?: InputMaybe<Scalars['ID']>;
  /** CODE of DateType enum */
  dateTypeCode: Scalars['String'];
  /** End date */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** Name of City */
  locationName?: InputMaybe<Scalars['String']>;
  /** Start date */
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type RoleDefaultDateWithLocation = {
  /** CODE of DateType enum */
  dateTypeCode: Scalars['String'];
  /** End date */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Name of City */
  locationName?: Maybe<Scalars['String']>;
  /** Start date */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type RoleDefaultPublicationSetting = {
  /** List of DMAs CODEs for talents */
  dmas?: Maybe<Array<Scalars['String']>>;
  /** Publication Setting CODE */
  publicationSetting: Scalars['String'];
  /** List of Representation Age Categories CODEs */
  repAgeCategories?: Maybe<Array<Scalars['String']>>;
  /** List of Representation Categories CODEs */
  repCategories?: Maybe<Array<Scalars['String']>>;
  /** List of Representation Type CODEs */
  repTypes?: Maybe<Array<Scalars['String']>>;
  /** Representative IDs */
  representativeIds?: Maybe<Array<Scalars['Int']>>;
  /** Representative List ID Selected */
  representativeListId?: Maybe<Scalars['Int']>;
};

/** Role Default Options */
export type RoleDefaults = {
  fieldRestrictions?: Maybe<Array<RoleFieldRestriction>>;
  locationDates?: Maybe<Array<RoleDefaultDateWithLocation>>;
  publicationSettings?: Maybe<Array<RoleDefaultPublicationSetting>>;
  wardrobe?: Maybe<Scalars['String']>;
  workRequirements?: Maybe<Scalars['String']>;
};

/** Role Default Options */
export type RoleDefaultsInput = {
  fieldRestrictions?: InputMaybe<Array<RoleFieldRestrictionInput>>;
  locationDates?: InputMaybe<Array<RoleDateWithLocationInput>>;
  /** List of Publication Setting Input */
  publicationSettings?: InputMaybe<Array<RolePublicationSettingInput>>;
  wardrobe?: InputMaybe<Scalars['String']>;
  workRequirements?: InputMaybe<Scalars['String']>;
};

export type RoleFieldRestriction = {
  fields: Array<RoleFieldsRestrictionsFieldOption>;
  restrictedFor: ProjectFieldRestrictionOption;
};

export type RoleFieldRestrictionInput = {
  fields: Array<RoleFieldsRestrictionsFieldOption>;
  restrictedFor: ProjectFieldRestrictionOption;
};

export enum RoleFieldsRestrictionsFieldOption {
  AuditionCity = 'auditionCity',
  CallbackCity = 'callbackCity',
  FittingCity = 'fittingCity',
  WorkingCity = 'workingCity',
}

/** Role media object */
export type RoleMedia = {
  /** ID of role and media objects relation */
  id: Scalars['ID'];
  /** Is this sides media */
  isSides: Scalars['Boolean'];
  /** Media object */
  mediaObject: Media;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** ID of role and media objects relation */
  roleMediaId: Scalars['Int'];
  /** Role Side Url */
  url?: Maybe<Scalars['String']>;
};

/** Input type for role media */
export type RoleMediaInput = {
  /** Is this sides media */
  isSides: Scalars['Boolean'];
  /** Media ID */
  mediaId: Scalars['Float'];
  /** Name */
  name?: InputMaybe<Scalars['String']>;
};

/** Info about paired (with role) reps org */
export type RoleOrganizationPair = {
  /** Reps Organization */
  organization: Organization;
  organizationHasSubmissionsToRole: Scalars['Boolean'];
};

/** Paginated result with [RoleOrganizationPair] objects */
export type RoleOrganizationPairPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of RoleOrganizationPair objects */
  objects: Array<RoleOrganizationPair>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Role Previous Status */
export type RolePreviousStatus = {
  /** Role Previous Status */
  previousRoleStatus?: Maybe<RoleStatus>;
  roleId: Scalars['Float'];
};

export enum RoleProjectKeywordSearchEnum {
  All = 'ALL',
  ProjectName = 'PROJECT_NAME',
  ProjectOrRoleDescription = 'PROJECT_OR_ROLE_DESCRIPTION',
  RoleName = 'ROLE_NAME',
}

export type RolePublication = {
  createdAt?: Maybe<Scalars['DateTime']>;
  messageToReps?: Maybe<Scalars['String']>;
  messageToTalents?: Maybe<Scalars['String']>;
  publicationSettings: Array<PublicationSetting>;
  publicationType: RolePublishTypeEnum;
  roles: Array<Role>;
};

/** Paginated result with [RolePublication] objects */
export type RolePublicationPaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of RolePublication objects */
  objects: Array<RolePublication>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

/** Role Publication setting */
export type RolePublicationSetting = {
  /** List of Dma objects */
  dmas?: Maybe<Array<Dma>>;
  /** Publication Setting object */
  publicationSetting: PublicationSetting;
  /** List of Representation Age Categories objects */
  repAgeCategories?: Maybe<Array<RepresentationAgeCategory>>;
  /** List of Representation Categories objects */
  repCategories?: Maybe<Array<RepresentationCategory>>;
  /** List of Representation Type objects */
  repTypes?: Maybe<Array<RepresentationType>>;
  /** Representative List ID */
  representativeListIds: Array<Scalars['ID']>;
};

/** Publication Role setting */
export type RolePublicationSettingInput = {
  /** List of DMAs CODEs for talents */
  dmas?: InputMaybe<Array<Scalars['String']>>;
  /** Publication Setting CODE */
  publicationSetting: Scalars['String'];
  /** List of Representation Age Categories CODEs */
  repAgeCategories?: InputMaybe<Array<Scalars['String']>>;
  /** List of Representation Categories CODEs */
  repCategories?: InputMaybe<Array<Scalars['String']>>;
  /** List of Representation Type CODEs */
  repTypes?: InputMaybe<Array<Scalars['String']>>;
  /** Representative IDs */
  representativeIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Representative List ID Selected */
  representativeListId?: InputMaybe<Scalars['Int']>;
};

/** Role publication type */
export enum RolePublishTypeEnum {
  AdditionalSubmissions = 'additionalSubmissions',
  Approved = 'approved',
  Published = 'published',
  Widened = 'widened',
}

/** Role search result object */
export type RoleSearchResult = {
  /** Search role list */
  roles: Array<Role>;
  /** Result total count */
  totalCount: Scalars['Float'];
};

/** Role Sides Visibility object */
export type RoleSidesVisibility = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum RoleSidesVisibilityEnum {
  DontShow = 'DONT_SHOW',
  RepresentativesAndCb = 'REPRESENTATIVES_AND_CB',
  RepresentativesOnly = 'REPRESENTATIVES_ONLY',
}

/** Input type for skill */
export type RoleSkillInput = {
  /** Skill CODE */
  skillCode: Scalars['String'];
};

/** Role Status object */
export type RoleStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Role status codes options */
export enum RoleStatusCodes {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  ArchiveInProgress = 'ARCHIVE_IN_PROGRESS',
  EditToActiveRole = 'EDIT_TO_ACTIVE_ROLE',
  Internal = 'INTERNAL',
  ModificationsComplete = 'MODIFICATIONS_COMPLETE',
  ModificationsRequested = 'MODIFICATIONS_REQUESTED',
  NewRolePending = 'NEW_ROLE_PENDING',
  NotYetPublished = 'NOT_YET_PUBLISHED',
  Rejected = 'REJECTED',
  RequestingAdditionalSubmissions = 'REQUESTING_ADDITIONAL_SUBMISSIONS',
  RestoreInProgress = 'RESTORE_IN_PROGRESS',
  SubmissionsClosed = 'SUBMISSIONS_CLOSED',
}

export enum RoleStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  ArchiveInProgress = 'ARCHIVE_IN_PROGRESS',
  EditToActiveRole = 'EDIT_TO_ACTIVE_ROLE',
  Internal = 'INTERNAL',
  ModificationsComplete = 'MODIFICATIONS_COMPLETE',
  ModificationsRequested = 'MODIFICATIONS_REQUESTED',
  NewRolePending = 'NEW_ROLE_PENDING',
  NotYetPublished = 'NOT_YET_PUBLISHED',
  Rejected = 'REJECTED',
  RequestingAdditionalSubmissions = 'REQUESTING_ADDITIONAL_SUBMISSIONS',
  RestoreInProgress = 'RESTORE_IN_PROGRESS',
  SubmissionsClosed = 'SUBMISSIONS_CLOSED',
}

/** Role status filter options */
export enum RoleStatusFilter {
  Active = 'Active',
  All = 'All',
  Archived = 'Archived',
  Published = 'Published',
  UnderReview = 'UnderReview',
}

export type RoleSubmissionCount = {
  count: Scalars['Int'];
  roleId: Scalars['Int'];
  submissionStatus: SubmissionStatus;
};

/** Input type for Role Talents */
export type RoleTalent = {
  /** Role Id */
  roleId: Scalars['ID'];
  scheduleSelectInput?: InputMaybe<SchedulesSelectInput>;
  /** List of CODE of Selected Tile Schema */
  selectedTileSchemaCodes: Array<Scalars['String']>;
  /** List of Selected Tile Schemes */
  selectedTileSchemes?: InputMaybe<Array<Scalars['Float']>>;
  /** No. of talents in each slot */
  talentsInSlot: Scalars['Float'];
};

/** Role Target object */
export type RoleTarget = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum RoleTargetEnum {
  Background = 'BACKGROUND',
  Principal = 'PRINCIPAL',
}

export enum RoleTargetsEnum {
  Background = 'BACKGROUND',
  Principal = 'PRINCIPAL',
}

/** Role Tracker Model */
export type RoleTracker = {
  /** Created Date */
  created?: Maybe<Scalars['DateTime']>;
  /** Primary ID */
  id: Scalars['BigInt'];
  /** Last Status Update */
  lastStatusUpdated?: Maybe<Scalars['DateTime']>;
  /** Role associated with the Role Tracker */
  role?: Maybe<Role>;
  /** Role Tracker Status */
  roleTrackerStatus?: Maybe<RoleTrackerStatusEnum>;
  /** The source of the Role Tracker Submission */
  source?: Maybe<Scalars['String']>;
};

export enum RoleTrackerFieldOrder {
  Created = 'created',
  LastStatusUpdated = 'lastStatusUpdated',
}

export type RoleTrackerFiltersArgs = {
  orgIds?: InputMaybe<Array<Scalars['Int']>>;
  profileIds?: InputMaybe<Array<Scalars['Int']>>;
  submissionIds?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type RoleTrackerGroup = {
  id: Scalars['ID'];
  itemCount: Scalars['Int'];
  name: Scalars['String'];
};

export enum RoleTrackerGroupType {
  ProjectType = 'PROJECT_TYPE',
  RoleType = 'ROLE_TYPE',
  Source = 'SOURCE',
  Status = 'STATUS',
}

export type RoleTrackerGroups = {
  groups: Array<RoleTrackerGroup>;
  nextGroupId?: Maybe<Scalars['ID']>;
};

export type RoleTrackerOrderOption = {
  field?: InputMaybe<RoleTrackerFieldOrder>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type RoleTrackerPagedList = {
  data: Array<RoleTracker>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Role Tracker Stats Aggregate Model */
export type RoleTrackerStatsAggregate = {
  selected: Scalars['Int'];
  submitted: Scalars['Int'];
  total: Scalars['Int'];
  underConsideration: Scalars['Int'];
};

export enum RoleTrackerStatsIntervalEnum {
  Days = 'DAYS',
  Hours = 'HOURS',
  Weeks = 'WEEKS',
}

/** Role Tracker Stats Period Model */
export type RoleTrackerStatsPeriod = {
  interval?: Maybe<Scalars['Int']>;
  nthInterval?: Maybe<Scalars['Int']>;
  stats: RoleTrackerStatsAggregate;
};

/** Role Tracker Stats Model */
export type RoleTrackerStatsResponse = {
  currentPeriod: RoleTrackerStatsPeriod;
  previousPeriod: RoleTrackerStatsPeriod;
};

export enum RoleTrackerStatusEnum {
  Selected = 'SELECTED',
  Submitted = 'SUBMITTED',
  UnderConsideration = 'UNDER_CONSIDERATION',
}

/** RoleType object */
export type RoleType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  projectType: ProjectType;
  roleTarget: RoleTarget;
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum RoleTypeEnum {
  Background = 'BACKGROUND',
  BrandAmbassador = 'BRAND_AMBASSADOR',
  CoStar = 'CO_STAR',
  Ensemble = 'ENSEMBLE',
  FeaturedBackground = 'FEATURED_BACKGROUND',
  FractionalSeriesRegular = 'FRACTIONAL_SERIES_REGULAR',
  GuestStar = 'GUEST_STAR',
  Host = 'HOST',
  Lead = 'LEAD',
  Model = 'MODEL',
  OneDayGuestStar = 'ONE_DAY_GUEST_STAR',
  OnCamera = 'ON_CAMERA',
  Other = 'OTHER',
  PhotoDouble = 'PHOTO_DOUBLE',
  PrecisionDriver = 'PRECISION_DRIVER',
  Principal = 'PRINCIPAL',
  Recurring = 'RECURRING',
  RecurringCoStar = 'RECURRING_CO_STAR',
  RecurringGuestStar = 'RECURRING_GUEST_STAR',
  SeriesRegular = 'SERIES_REGULAR',
  SpecialAbility = 'SPECIAL_ABILITY',
  StandBy = 'STAND_BY',
  StandIn = 'STAND_IN',
  StudioAudience = 'STUDIO_AUDIENCE',
  Stunt = 'STUNT',
  Supporting = 'SUPPORTING',
  Swing = 'SWING',
  Under_5 = 'UNDER_5',
  VoiceOver = 'VOICE_OVER',
}

export type RoleTypeFilterInput = {
  keys?: InputMaybe<FilterInput>;
  projectType?: InputMaybe<FilterInput>;
  roleTarget?: InputMaybe<FilterInput>;
};

/** Input type for role type */
export type RoleTypeInput = {
  /** Project Type CODE */
  projectTypeCode: Scalars['String'];
  /** Role target CODE */
  roleTargetCode: Scalars['String'];
  /** Role type CODE */
  roleTypeCode: Scalars['String'];
};

/** RoleUnion object */
export type RoleUnion = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum RoleUnionEnum {
  NonUnion = 'NON_UNION',
  Union = 'UNION',
  UnionNonUnion = 'UNION_NON_UNION',
}

/** Input type for Role update */
export type RoleUpdateInput = {
  ageInYears?: InputMaybe<Scalars['Boolean']>;
  ageMaxMonths?: InputMaybe<Scalars['Int']>;
  ageMinMonths?: InputMaybe<Scalars['Int']>;
  auditionSessionId?: InputMaybe<Scalars['ID']>;
  backgroundTalentIntended?: InputMaybe<Scalars['Float']>;
  closeSubmissions?: InputMaybe<Scalars['Boolean']>;
  compensations?: InputMaybe<Array<RoleCompensationInput>>;
  currencyCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** List of Ethnic Appearances associated with role */
  ethnicAppearances?: InputMaybe<Array<Scalars['String']>>;
  fieldRestrictionSettings?: InputMaybe<Array<RoleFieldRestrictionInput>>;
  internalName?: InputMaybe<Scalars['String']>;
  locationCode?: InputMaybe<Scalars['String']>;
  locationDateInformations?: InputMaybe<Array<RoleDateWithLocationInput>>;
  name?: InputMaybe<Scalars['String']>;
  paidTypeCode?: InputMaybe<Scalars['String']>;
  /** UpdateRoleMediaInput array of role media input for add/ update the media along with the relevant role */
  photos?: InputMaybe<Array<UpdateRoleMediaInput>>;
  projectId?: InputMaybe<Scalars['ID']>;
  rate?: InputMaybe<Scalars['Float']>;
  rateMax?: InputMaybe<Scalars['Float']>;
  rateNote?: InputMaybe<Scalars['String']>;
  ratePerCode?: InputMaybe<Scalars['String']>;
  rateSummary?: InputMaybe<Scalars['String']>;
  rateType?: InputMaybe<Scalars['Boolean']>;
  requestMediaInstruction?: InputMaybe<Scalars['String']>;
  requestMediaTypeCodes?: InputMaybe<Array<Scalars['String']>>;
  roleAccount?: InputMaybe<Array<Scalars['ID']>>;
  roleEthnicityNationality?: InputMaybe<Scalars['String']>;
  roleGenderCodes?: InputMaybe<Array<Scalars['String']>>;
  roleId: Scalars['ID'];
  roleOrganization?: InputMaybe<Array<Scalars['ID']>>;
  roleParentId?: InputMaybe<Scalars['ID']>;
  rolePublicationSetting?: InputMaybe<RolePublicationSettingInput>;
  /** List of Publication Setting Input */
  rolePublicationSettings?: InputMaybe<Array<RolePublicationSettingInput>>;
  /** Role Sides Visibility */
  roleSidesVisibilityCode?: InputMaybe<Scalars['String']>;
  roleSkills?: InputMaybe<Array<RoleSkillInput>>;
  roleStatusCode?: InputMaybe<Scalars['String']>;
  roleType?: InputMaybe<RoleTypeInput>;
  roleUnionCode?: InputMaybe<Scalars['String']>;
  rushCall?: InputMaybe<Scalars['Boolean']>;
  scale?: InputMaybe<Scalars['Boolean']>;
  sexualSituationCodes?: InputMaybe<Array<Scalars['String']>>;
  sexualSituationDescription?: InputMaybe<Scalars['String']>;
  sides?: InputMaybe<Array<UpdateRoleMediaInput>>;
  spot?: InputMaybe<Scalars['String']>;
  submissionDueDate?: InputMaybe<Scalars['DateTime']>;
  submissionInstruction?: InputMaybe<Scalars['String']>;
  /** Time Zone Code */
  timeZoneCode?: InputMaybe<Scalars['String']>;
  /** updates role date info in all roles within the project */
  updateLocationDateInformationInProject?: InputMaybe<Scalars['Boolean']>;
  wardrobe?: InputMaybe<Scalars['String']>;
  workRequirement?: InputMaybe<Scalars['String']>;
};

export type RoleUpdateWardrobeInput = {
  roleId: Scalars['Int'];
  wardrobe: Scalars['String'];
};

/** Role for Workflow */
export type RoleWorkflow = {
  ageInYears: Scalars['Boolean'];
  ageMax?: Maybe<Scalars['Int']>;
  ageMin?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  /** Displayed role age range */
  displayedRoleAgeRange?: Maybe<Scalars['String']>;
  /** Displayed role ethnic appearance */
  displayedRoleEthnicAppearance?: Maybe<Scalars['String']>;
  /** Displayed role genders */
  displayedRoleGenders?: Maybe<Scalars['String']>;
  /** Displayed role union */
  displayedRoleUnion?: Maybe<Scalars['String']>;
  /** Ethnic Appearances */
  ethnicAppearance?: Maybe<Array<EthnicAppearance>>;
  /** Gender Appearances */
  genderAppearance?: Maybe<Array<GenderAppearance>>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  locationDates?: Maybe<Array<RoleDateWithLocation>>;
  name: Scalars['String'];
  project?: Maybe<Project>;
  publicationSettingIds?: Maybe<Array<Scalars['Int']>>;
  rate?: Maybe<Scalars['Float']>;
  rateMax?: Maybe<Scalars['Float']>;
  ratePerType?: Maybe<RatePerType>;
  /** Rate summary */
  rateSummary?: Maybe<Scalars['String']>;
  roleStatus: RoleStatus;
  roleType: RoleType;
  /** Sexual Situations */
  sexualSituations?: Maybe<Array<SexualSituation>>;
  sides?: Maybe<Array<RoleMedia>>;
  sidesVisibility?: Maybe<RoleSidesVisibility>;
  /** Wardrobe */
  wardrobe?: Maybe<Scalars['String']>;
};

/** Context object for FFY artist object */
export type RolesFitForYouArtistContext = {
  id: Scalars['Float'];
  manageEmailSettingsUrl: Scalars['String'];
  name: Scalars['String'];
  notificationSettingsURL: Scalars['String'];
  subjectRolePart: Scalars['String'];
  toProfileId?: Maybe<Scalars['Int']>;
};

/** Casting Company */
export type RolesFitForYouCastingCompanyContext = {
  logo?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Context object for roles fits for artist */
export type RolesFitForYouContext = {
  artists: Array<RolesFitForYouArtistContext>;
  project: RolesFitForYouProjectContext;
  roles: Array<RolesFitForYouRoleContext>;
};

/** Context object for FFY project object */
export type RolesFitForYouProjectContext = {
  castingCompany: RolesFitForYouCastingCompanyContext;
  id: Scalars['Float'];
  name: Scalars['String'];
  shootDate: Scalars['String'];
  type: Scalars['String'];
  union: Scalars['String'];
  url: Scalars['String'];
};

/** Context object for FFY role object */
export type RolesFitForYouRoleContext = {
  ageRange: Scalars['String'];
  buttonText: Scalars['String'];
  buttonURL: Scalars['String'];
  description: Scalars['String'];
  ethnicAppearance: Scalars['String'];
  genderAppearance: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  rateSummary: Scalars['String'];
  sexualSituations: Scalars['String'];
  type: Scalars['String'];
  union?: Maybe<Scalars['String']>;
};

export type RolesPublishedCastingCompanyContext = {
  castingAssistant: Scalars['String'];
  castingAssociate: Scalars['String'];
  castingDirector: Scalars['String'];
  email: Scalars['String'];
  logo: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

/** Contex object for roles published notification */
export type RolesPublishedContext = {
  /** Count of additional roles not in newRoles or roles fields */
  additionalRolesCount?: Maybe<Scalars['Float']>;
  buttonURL: Scalars['String'];
  manageEmailSettingsUrl: Scalars['String'];
  messageForReps: Scalars['String'];
  /** New published roles within the project */
  newRoles?: Maybe<Array<RolesPublishedRoleContext>>;
  project: RolesPublishedProjectContext;
  /** Published roles(currently or previously) which match divisions */
  roles: Array<RolesPublishedRoleContext>;
};

export type RolesPublishedContextNotificationContextAndRecipient = {
  context: RolesPublishedContext;
  to?: Maybe<Scalars['String']>;
  toAccountId?: Maybe<Scalars['Int']>;
  toOrgId?: Maybe<Scalars['Int']>;
  toProfileId?: Maybe<Scalars['Int']>;
};

export type RolesPublishedCreativeTeamContext = {
  adAgency: Scalars['String'];
  director: Scalars['String'];
  executiveProducer: Scalars['String'];
  photographer: Scalars['String'];
  producer: Scalars['String'];
  productionCompany: Scalars['String'];
  studio: Scalars['String'];
  writer: Scalars['String'];
};

export type RolesPublishedProjectContext = {
  additionalProvisionsMessage: Scalars['String'];
  attachmentDetails: Array<AttachmentDetails>;
  attachments: Array<Scalars['String']>;
  auditionType?: Maybe<AuditionType>;
  castingCompany: RolesPublishedCastingCompanyContext;
  conflicts: Scalars['String'];
  creativeTeam: RolesPublishedCreativeTeamContext;
  name: Scalars['String'];
  projectNotesMessage: Scalars['String'];
  projectSynopsisMessage: Scalars['String'];
  projectTypeId: Scalars['Int'];
  projectUnionIds: Array<Scalars['Int']>;
  rate: Scalars['String'];
  type: Scalars['String'];
  unions: Scalars['String'];
  usageMessage: Scalars['String'];
};

export type RolesPublishedRoleContext = {
  roleDetails: RolesPublishedRoleDetailsContext;
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
};

export type RolesPublishedRoleDetailsContext = {
  ageRange: Scalars['String'];
  ethnicAppearance: Scalars['String'];
  genderAppearance: Scalars['String'];
  id: Scalars['Int'];
  roleTargetId: Scalars['Int'];
  type: Scalars['String'];
};

/** 'Roster Invitation Request' type */
export type RosterInvitationRequest = {
  /** Request's ID */
  id: Scalars['ID'];
  /** Should be email address of invitor included into the request or not */
  includeOwnEmail: Scalars['Boolean'];
  invitations: Array<RosterTalentInvitation>;
  /** Note for the invited talent */
  note?: Maybe<Scalars['String']>;
};

/** Create Roster Invitation input */
export type RosterInvitationRequestInput = {
  includeOwnEmail: Scalars['Boolean'];
  invitations: Array<RosterInvitationTalentInput>;
  note?: InputMaybe<Scalars['String']>;
};

/** Roster Invitation request status */
export enum RosterInvitationRequestStatus {
  Expired = 'EXPIRED',
  InRoster = 'IN_ROSTER',
  Pending = 'PENDING',
}

export type RosterInvitationTalentHistoryFilterInput = {
  /** Filter by email */
  email: Scalars['String'];
  /** Filter by name */
  name: Scalars['String'];
};

export type RosterInvitationTalentInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

/** 'Roster Talent Invitation' type */
export type RosterTalentInvitation = {
  /** Invitation created date */
  createdAt: Scalars['DateTime'];
  /** Talent's email */
  email: Scalars['String'];
  /** Invitation expiration date */
  expiredAt: Scalars['DateTime'];
  /** Talent's invitation ID */
  id: Scalars['ID'];
  /** Talent's name */
  name: Scalars['String'];
  /** ID of organization which invited talent to the roster */
  organizationId: Scalars['Int'];
  /** Invitation profile Id */
  profileId?: Maybe<Scalars['Int']>;
  /** Current Profile Status */
  profileStatusCode?: Maybe<Scalars['String']>;
  /** Status of talent's invitation */
  status: RosterInvitationRequestStatus;
  /** Generated token for invited talent */
  token: Scalars['String'];
};

export type RosterTalentInvitationPagedList = {
  data: Array<RosterTalentInvitation>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** RPC Form Bulk Response Item */
export type RpcFormBulkResponseItem = {
  formResponseDetails: FormBulkResponseFormResponse;
  profileDetails: FormBulkResponseProfileDetails;
  repDetails?: Maybe<FormBulkResponseRepDetails>;
  roleDetails?: Maybe<RpcRoleInfo>;
};

/** RPC Role Info */
export type RpcRoleInfo = {
  id: Scalars['Int'];
  internalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type SaveDraftAskQuestionRequestArgs = {
  /** Updates draft if id is passed else creates a new draft */
  id?: InputMaybe<Scalars['ID']>;
  payload: AskQuestionRequestInput;
};

export type SaveDraftMediaRequestArgs = {
  /** Updates draft if id is passed else creates a new draft */
  id?: InputMaybe<Scalars['ID']>;
  payload: MediaRequestInput;
};

export type SaveFormResponseResult = {
  /** The ID of the saved form response */
  formResponseId?: Maybe<Scalars['BigInt']>;
};

export type SavedSearch = {
  attributes: SavedSearchAttributes;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  notificationType?: Maybe<Scalars['String']>;
};

export type SavedSearchAttributes = {
  ethnicAppearances?: Maybe<Array<EthnicAppearance>>;
  excludeSexualSituations?: Maybe<Scalars['Boolean']>;
  genderAppearances?: Maybe<Array<GenderAppearance>>;
  keywords?: Maybe<Scalars['String']>;
  paidTypes?: Maybe<Array<PaidType>>;
  playableAge?: Maybe<AgeRange>;
  projectTypes?: Maybe<Array<ProjectType>>;
  roleProjectKeywordSearch?: Maybe<RoleProjectKeywordSearchEnum>;
  roleTargets?: Maybe<Array<RoleTarget>>;
  showRolesNoLongerAcceptingSubmissions?: Maybe<Scalars['Boolean']>;
  talentUnions?: Maybe<Array<TalentUnion>>;
  workingLocations?: Maybe<Array<Dma>>;
};

export type SavedSearchList = {
  items: Array<SavedSearch>;
  nextKeyOffset?: Maybe<Scalars['String']>;
};

/** Schedule */
export type Schedule = {
  /** Primary ID */
  id: Scalars['ID'];
  /** canceled status */
  isCanceled?: Maybe<Scalars['Boolean']>;
  /** Updated Date */
  lastUpdated?: Maybe<Scalars['DateTime']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Schedule Date */
  scheduleDate?: Maybe<Scalars['DateTime']>;
  /** scheduleType */
  scheduleType?: Maybe<Scalars['String']>;
  statusCounts: ScheduleInviteResponseStatusCounts;
  /** Submissions Count */
  submissionCount?: Maybe<Scalars['BigInt']>;
  /** Virtual Audition Details */
  virtualAudition?: Maybe<ScheduleVirtualAudition>;
};

/** Schedule Details */
export type ScheduleDetail = {
  /** Schedule Address */
  address?: Maybe<Address>;
  /** Calendar Type */
  calendarType?: Maybe<CalendarType>;
  /** Schedule Date */
  date?: Maybe<Scalars['DateTime']>;
  hasScheduledRepsSubmissions: Scalars['Boolean'];
  /** Schedule Id */
  id?: Maybe<Scalars['ID']>;
  isLocationHidden: Scalars['Boolean'];
  /** Schedule Name */
  name: Scalars['String'];
  /** Note */
  note: Scalars['String'];
  /** Project Id */
  projectId?: Maybe<Scalars['Int']>;
  /** Schedule Id */
  scheduleId?: Maybe<Scalars['ID']>;
  scheduleInviteRequestNotes: ScheduleInviteRequestNotes;
  /** Time Zone */
  timeZone?: Maybe<TimeZone>;
  timeframes: Array<ScheduleTimeframe>;
  timeframesSummary: Array<ScheduleTimeframeSummary>;
  version?: Maybe<Scalars['Int']>;
  /** Virtual Audition Details */
  virtualAudition?: Maybe<ScheduleVirtualAudition>;
};

/** Schedule History */
export type ScheduleHistory = {
  /** Schedule Address */
  address?: Maybe<Address>;
  /** Calendar Type */
  calendarType?: Maybe<CalendarType>;
  /** Schedule History created date */
  created?: Maybe<Scalars['DateTime']>;
  /** Schedule Date */
  date?: Maybe<Scalars['DateTime']>;
  hasScheduledRepsSubmissions: Scalars['Boolean'];
  /** Schedule Id */
  id?: Maybe<Scalars['ID']>;
  isLocationHidden: Scalars['Boolean'];
  /** Schedule History modified by */
  modifiedBy?: Maybe<Scalars['Float']>;
  /** Schedule Name */
  name: Scalars['String'];
  /** Note */
  note: Scalars['String'];
  /** Schedule History operation */
  operation?: Maybe<Scalars['String']>;
  /** Schedule History operation time */
  operationTime?: Maybe<Scalars['DateTime']>;
  /** Project Id */
  projectId?: Maybe<Scalars['Int']>;
  /** Schedule Id */
  scheduleId?: Maybe<Scalars['ID']>;
  scheduleInviteRequestNotes: ScheduleInviteRequestNotes;
  /** Time Zone */
  timeZone?: Maybe<TimeZone>;
  timeframes: Array<ScheduleTimeframe>;
  timeframesSummary: Array<ScheduleTimeframeSummary>;
  version?: Maybe<Scalars['Int']>;
  /** Virtual Audition Details */
  virtualAudition?: Maybe<ScheduleVirtualAudition>;
};

/** Schedule Information */
export type ScheduleInfo = {
  /** Audition Scheduled */
  auditionScheduled?: Maybe<Scalars['Boolean']>;
  /** Callback Scheduled */
  callbackScheduled?: Maybe<Scalars['Boolean']>;
  /** Submission Id */
  submissionId?: Maybe<Scalars['BigInt']>;
};

export type ScheduleInvitePreviousResponse = {
  id: Scalars['ID'];
  responseNote?: Maybe<Scalars['String']>;
  schedule: WfSchedule;
  slotDate?: Maybe<Scalars['DateTime']>;
  status: ScheduleInviteResponseStatus;
  submissionId: Scalars['Int'];
};

/**
 * Schedule Invite Request input. The Schedule Invite is always sent from a
 * schedule and some fields are based on the schedule data.
 */
export type ScheduleInviteRequestInput = {
  /** A message to Representatives of the talents that are connected to submissions. */
  noteForRepresentative?: InputMaybe<Scalars['String']>;
  /** A message to Talent of the talents that are connected to submissions. */
  noteForTalents?: InputMaybe<Scalars['String']>;
  /** Filter Requests by role */
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Schedule Id */
  scheduleId: Scalars['Int'];
  /** Field for choosing which requests should trigger email notification. */
  scheduleReceiver: ScheduleReceiver;
  /** Schedule type: AUDITION / CALLBACK */
  type: ScheduleRequestType;
};

export type ScheduleInviteRequestNotes = {
  noteForRepresentative?: Maybe<Scalars['String']>;
  noteForTalents?: Maybe<Scalars['String']>;
};

/** Schedule request status */
export enum ScheduleInviteRequestStatus {
  Cancelled = 'CANCELLED',
  CancelledRescheduled = 'CANCELLED_RESCHEDULED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  Rescheduled = 'RESCHEDULED',
  RescheduleRequested = 'RESCHEDULE_REQUESTED',
  Updated = 'UPDATED',
}

export type ScheduleInviteResponse = {
  chatTopic?: Maybe<WfChatTopic>;
  chatTopicKey: Scalars['String'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  prevRevisionResponseStatus?: Maybe<ScheduleInviteRequestStatus>;
  repliedAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  slotId: Scalars['Int'];
  slotStartDate?: Maybe<Scalars['DateTime']>;
  status: ScheduleInviteRequestStatus;
  submissionId: Scalars['Int'];
};

/** Schedule Invite Response input that can be sent by a Representative or Talent user. */
export type ScheduleInviteResponseInput = {
  /** Schedule Invite Response id. */
  id: Scalars['ID'];
  /** A response: a user can confirm, decline or request rescheduling the Schedule. */
  response: ScheduleInviteResponseStatus;
  /** A note. Should be filled if declining or rescheduling the invite. */
  responseNote?: InputMaybe<Scalars['String']>;
};

export enum ScheduleInviteResponseStatus {
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  RescheduleRequested = 'RESCHEDULE_REQUESTED',
}

export type ScheduleInviteResponseStatusCounts = {
  confirmed: Scalars['Int'];
  declined: Scalars['Int'];
  notSent: Scalars['Int'];
  pending: Scalars['Int'];
  rescheduleRequested: Scalars['Int'];
  scheduleId: Scalars['Int'];
};

/** Schedule Invite Response input that can be sent by a Casting Director */
export type ScheduleInviteSetStatusInput = {
  /** Schedule Invite Response id. */
  id: Scalars['ID'];
  /** A response: a user can confirm, decline or request rescheduling the Schedule. */
  response: ScheduleInviteResponseStatus;
};

/**
 * Schedule Invite Request type. The Schedule Invite is always sent from a schedule
 * and some fields are based on the schedule data.
 */
export type ScheduleInviteSubRequest = {
  /** Cancellation note */
  cancellationNote?: Maybe<Scalars['String']>;
  chatTopicKey: Scalars['String'];
  /** Forwarded Note */
  forwardNote?: Maybe<Scalars['String']>;
  /** Schedule Invite Request id. */
  id: Scalars['ID'];
  /** A message to Representatives of the talents that are connected to submissions. */
  noteForRepresentative?: Maybe<Scalars['String']>;
  /** An instruction for Talents connected to submissions. */
  noteForTalents?: Maybe<Scalars['String']>;
  /** Previous Response Note */
  prevResponseNote?: Maybe<Scalars['String']>;
  /** Previous Response Status */
  prevResponseStatus?: Maybe<Scalars['String']>;
  /** Previous Slot Date */
  prevSlotDate?: Maybe<Scalars['DateTime']>;
  profile?: Maybe<WfProfile>;
  project: WfProject;
  /** Reps Worksheet Status */
  repsWorksheetStatus: Scalars['String'];
  responseNote?: Maybe<Scalars['String']>;
  /** Response Status */
  responseStatus?: Maybe<Scalars['String']>;
  role: WfRole;
  schedule?: Maybe<WfSchedule>;
  /** Date of Slot */
  slotDate?: Maybe<Scalars['DateTime']>;
  status: ScheduleInviteRequestStatus;
  submissionId: Scalars['Int'];
};

/** Paginated result with [Schedule] objects */
export type SchedulePaginatedResult = {
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of Schedule objects */
  objects: Array<Schedule>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export enum ScheduleReceiver {
  All = 'ALL',
  Updated = 'UPDATED',
  UpdatedAndUnconfirmed = 'UPDATED_AND_UNCONFIRMED',
}

export type ScheduleReminderCastingCompanyContext = {
  logo: Scalars['String'];
  name: Scalars['String'];
};

/** Contex object for audition/callback notification */
export type ScheduleReminderContext = {
  buttonText: Scalars['String'];
  buttonURL?: Maybe<Scalars['String']>;
  dividerText: Scalars['String'];
  heading: Scalars['String'];
  instructionForTalent?: Maybe<Scalars['String']>;
  locationNote?: Maybe<Scalars['String']>;
  manageEmailSettingsUrl: Scalars['String'];
  profile: ScheduleReminderProfileContext;
  project: ScheduleReminderProjectContext;
  receiverEmail?: Maybe<Scalars['String']>;
  role: ScheduleReminderRoleContext;
  scheduleDate: Scalars['String'];
  scheduleLocation: Scalars['String'];
  scheduleType: Scalars['String'];
  subHeading: Scalars['String'];
};

export type ScheduleReminderContextNotificationContextAndRecipient = {
  context: ScheduleReminderContext;
  to?: Maybe<Scalars['String']>;
  toAccountId?: Maybe<Scalars['Int']>;
  toOrgId?: Maybe<Scalars['Int']>;
  toProfileId?: Maybe<Scalars['Int']>;
};

export type ScheduleReminderProfileContext = {
  id: Scalars['Float'];
  name: Scalars['String'];
  primaryPhotoUrl: Scalars['String'];
};

export type ScheduleReminderProjectContext = {
  castingCompany: ScheduleReminderCastingCompanyContext;
  name: Scalars['String'];
};

export type ScheduleReminderRoleContext = {
  name: Scalars['String'];
};

/** Schedule Report Input Arguments */
export type ScheduleReportCreateInput = {
  castingNotes?: InputMaybe<Scalars['Boolean']>;
  confirmationStatuses?: InputMaybe<Scalars['Boolean']>;
  displayTalentInfoOptions?: InputMaybe<Array<DisplayTalentInfoOptionsEnum>>;
  displayTalentPhotoOptions?: InputMaybe<Array<DisplayTalentPhotoOptionsEnum>>;
  includeTimeframes?: InputMaybe<Scalars['Boolean']>;
  repContactInfo?: InputMaybe<Scalars['Boolean']>;
  scheduleId: Scalars['Int'];
  submissionNotes?: InputMaybe<Scalars['Boolean']>;
};

/** Schedule Report */
export type ScheduleReportModel = {
  castingNotes?: Maybe<Scalars['Boolean']>;
  confirmationStatuses?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  displayTalentInfoOptions?: Maybe<Array<DisplayTalentInfoOptionsEnum>>;
  displayTalentPhotoOptions?: Maybe<Array<DisplayTalentPhotoOptionsEnum>>;
  id: Scalars['ID'];
  includeTimeframes?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<ScheduleReportPayloadModel>;
  repContactInfo?: Maybe<Scalars['Boolean']>;
  reportType: ReportTypeEnum;
  scheduleId: Scalars['Int'];
  status: ReportStatusEnum;
  submissionNotes?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Schedule Report Payload */
export type ScheduleReportPayloadModel = {
  castingCompanyLogo?: Maybe<ReportMediaModel>;
  projectName: Scalars['String'];
  scheduleDate: Scalars['DateTime'];
  scheduleType: CalendarTypeEnum;
  timeZone?: Maybe<Scalars['String']>;
  timeframes: Array<ScheduleReportTimeframeModel>;
};

/** Schedule Report Submission */
export type ScheduleReportSubmissionModel = {
  /** Agency contacts for repped talent */
  agencyContacts?: Maybe<ReportContactInfoModel>;
  /** @deprecated use talentPhone */
  agencyPhone?: Maybe<Scalars['String']>;
  castingNote?: Maybe<Scalars['String']>;
  cover?: Maybe<ReportMediaModel>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  genderAppearances?: Maybe<Array<SubFieldsGql>>;
  headshotMedia?: Maybe<ReportMediaModel>;
  id: Scalars['Int'];
  isPersonal?: Maybe<Scalars['Boolean']>;
  isRepresented?: Maybe<Scalars['Boolean']>;
  minor?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Array<Scalars['String']>>;
  orgAbbreviation?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  orgParentName?: Maybe<Scalars['String']>;
  orgPhone?: Maybe<Array<Scalars['String']>>;
  organizationName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['Float']>;
  roleName: Scalars['String'];
  scheduleResponseStatus?: Maybe<Scalars['String']>;
  sizeSheets?: Maybe<PresentationSizeSheetsTalentModel>;
  snapshotMedia?: Maybe<ReportMediaModel>;
  stageName?: Maybe<Scalars['String']>;
  submissionNote?: Maybe<Scalars['String']>;
  talentEmail?: Maybe<Scalars['String']>;
  talentPhone?: Maybe<Scalars['String']>;
  talentUnions?: Maybe<Array<Scalars['String']>>;
};

/** Schedule Report Timeframe */
export type ScheduleReportTimeframeModel = {
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  isBreak: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  timeslots: Array<ScheduleReportTimeslotModel>;
};

/** Schedule Report Timeslot */
export type ScheduleReportTimeslotModel = {
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
  submissions: Array<ScheduleReportSubmissionModel>;
};

export enum ScheduleRequestType {
  Audition = 'AUDITION',
  Callback = 'CALLBACK',
}

/** Search input */
export type ScheduleSearchInput = {
  /** List of Project Id to search in */
  projectId?: InputMaybe<Array<Scalars['Int']>>;
  scheduleType?: InputMaybe<CalendarTypeEnum>;
};

/** Schedule Size Sheeets Report Input Arguments */
export type ScheduleSizeSheetsReportCreateInput = {
  displayTalentInfoOptions: Array<DisplayTalentInfoOptionsEnum>;
  displayTalentPerPageOptions: DisplayTalentPerPageOptionsEnum;
  displayTalentPhotoOptions: Array<DisplayTalentPhotoOptionsEnum>;
  scheduleId: Scalars['Int'];
};

/** Schedule Size Sheets Report */
export type ScheduleSizeSheetsReportModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  displayTalentInfoOptions?: Maybe<Array<DisplayTalentInfoOptionsEnum>>;
  displayTalentPerPageOptions?: Maybe<DisplayTalentPerPageOptionsEnum>;
  displayTalentPhotoOptions?: Maybe<Array<DisplayTalentPhotoOptionsEnum>>;
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<ScheduleReportPayloadModel>;
  reportType: ReportTypeEnum;
  scheduleId: Scalars['Int'];
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Object for representing a Schedule Slot. */
export type ScheduleSlot = {
  /** Slot Id */
  id?: Maybe<Scalars['ID']>;
  /** Slot End Time */
  slotEndTime?: Maybe<Scalars['DateTime']>;
  /** Slot Id */
  slotId?: Maybe<Scalars['Float']>;
  /** Slot Start Time */
  slotStartTime?: Maybe<Scalars['DateTime']>;
  submissions: Array<Submission>;
};

export type ScheduleSlotSummary = {
  endTime: Scalars['DateTime'];
  id: Scalars['Int'];
  roleCount: Scalars['Int'];
  startTime: Scalars['DateTime'];
  submissionCount: Scalars['Int'];
  timeframeId: Scalars['Int'];
};

export type ScheduleTalentPool = {
  roleId: Scalars['Int'];
  submissionCount: Scalars['Int'];
  submissions?: Maybe<Array<Submission>>;
};

/** Schedule Timeframe Request Summary */
export type ScheduleTimeframRequestSummary = {
  count: Scalars['Int'];
  status: Scalars['String'];
};

/** Schedule Timeframe Details */
export type ScheduleTimeframe = {
  /** Timeframe Duration */
  durations?: Maybe<Array<Scalars['Float']>>;
  /** Timeframe End Time */
  endTime?: Maybe<Scalars['DateTime']>;
  /** Timeframe Id */
  id?: Maybe<Scalars['ID']>;
  /** Is this timeframe represents a break */
  isBreak: Scalars['Boolean'];
  /** Timeframe Name */
  name: Scalars['String'];
  /** List of Slots in this Timeframe */
  slots?: Maybe<Array<ScheduleSlot>>;
  /** Timeframe Start Time */
  startTime?: Maybe<Scalars['DateTime']>;
};

/** Schedule Timeframe Role Summary */
export type ScheduleTimeframeRoleSummary = {
  id: Scalars['ID'];
  internalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  submissionCount: Scalars['Int'];
  timeframeId: Scalars['ID'];
};

export type ScheduleTimeframeShiftedResult = {
  submissionIds: Array<Scalars['BigInt']>;
  timeframeIds: Array<Scalars['Int']>;
};

/** Schedule Timeframe Summary */
export type ScheduleTimeframeSummary = {
  /** Timeframe Duration */
  durations?: Maybe<Array<Scalars['Float']>>;
  emptySlotsCount: Scalars['Int'];
  endTime: Scalars['DateTime'];
  /** Timeframe Id */
  id: Scalars['ID'];
  isBreak: Scalars['Boolean'];
  requestSummary: Array<ScheduleTimeframRequestSummary>;
  rolesSummary: Array<ScheduleTimeframeRoleSummary>;
  slotsCount: Scalars['Int'];
  startTime: Scalars['DateTime'];
};

/** Virtual Audition Details */
export type ScheduleVirtualAudition = {
  attendees: Array<VirtualAuditionAttendee>;
  id: Scalars['ID'];
  joinUrl?: Maybe<Scalars['String']>;
  observerUrl?: Maybe<Scalars['String']>;
};

/** Search Schedules input */
export type SchedulesSelectInput = {
  /** Schedule ids to search in */
  scheduleIds?: InputMaybe<Array<Scalars['Int']>>;
  /** List of Unscheduled submissions */
  unscheduled?: InputMaybe<Scalars['Boolean']>;
};

export type SearchAccountsFiltersArgs = {
  accountId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  former?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyEmail?: InputMaybe<Scalars['String']>;
  legacyInstance?: InputMaybe<Scalars['String']>;
  legacyLogin?: InputMaybe<Scalars['String']>;
  organizationIds?: InputMaybe<Array<Scalars['Int']>>;
  phone?: InputMaybe<Scalars['String']>;
  systemRoleIds?: InputMaybe<Array<Scalars['Int']>>;
};

export enum SearchArtistMediaIncludeActive {
  Active = 'ACTIVE',
  All = 'ALL',
  Inactive = 'INACTIVE',
}

export enum SearchArtistMediaIncludeStatus {
  All = 'ALL',
  Archived = 'ARCHIVED',
  Locked = 'LOCKED',
  Ready = 'READY',
  Unlocked = 'UNLOCKED',
}

export enum SearchArtistMediaIncludeType {
  All = 'ALL',
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Photo = 'PHOTO',
  Video = 'VIDEO',
}

export type SearchArtistMediaInput = {
  artistId?: InputMaybe<Scalars['Int']>;
  includeStatus?: InputMaybe<Array<SearchArtistMediaIncludeStatus>>;
  includeType?: InputMaybe<Array<SearchArtistMediaIncludeType>>;
  mediaIds?: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<PageInput>;
  query?: InputMaybe<SearchArtistMediaQueryInput>;
};

export type SearchArtistMediaQueryInput = {
  artistId: Scalars['Int'];
  includeActive?: InputMaybe<Array<SearchArtistMediaIncludeActive>>;
  includeType?: InputMaybe<Array<SearchArtistMediaIncludeType>>;
  mediaIds?: InputMaybe<Array<Scalars['Int']>>;
};

export enum SearchDirection {
  Asc = 'Asc',
  Dsc = 'Dsc',
}

export type SearchMediaFilter = {
  /** A filter for search results by status. Defaults to null meaning no filtering by status. */
  status?: InputMaybe<Array<SearchMediaStatus>>;
  /** A filter for search results by type. Defaults to null meaning no filtering by type. */
  type?: InputMaybe<Array<SearchMediaType>>;
};

/** Provides filtering, sorting, and pagination for Media searches. */
export type SearchMediaInput = {
  /** Sorts by primary media then non-archivable and finally archivable media before applying the sort */
  applyPrimarySort?: InputMaybe<Scalars['Boolean']>;
  /** Filters results to media that belongs to the specified artist. Defaults to null meaning no filtering by artist. */
  artistId?: InputMaybe<Scalars['ID']>;
  /** Filters for search results. Defaults to null meaning no filtering. */
  filter?: InputMaybe<SearchMediaFilter>;
  /** Pagination for search results. Defaults to first page with 10 results. */
  page: PageInput;
  /** Sorts search results. Defaults to Updated. */
  sortBy?: InputMaybe<SearchMediaSortBy>;
  /** Direction used to sort search results. Defaults to Desc. */
  sortOrder?: InputMaybe<SearchMediaSortOrder>;
};

export type SearchMediaPaginatedResult = {
  lastKey?: Maybe<Scalars['String']>;
  objects: Array<Media>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Value used to sort Media search results. */
export enum SearchMediaSortBy {
  Created = 'Created',
  Status = 'Status',
  Updated = 'Updated',
}

/** Direction used to sort Media search results. */
export enum SearchMediaSortOrder {
  Asc = 'Asc',
  Desc = 'Desc',
}

/** Value used to filter Media search results by status. */
export enum SearchMediaStatus {
  Archived = 'Archived',
  CanArchive = 'CanArchive',
  Ready = 'Ready',
}

/** Value used to filter Media search results by type. */
export enum SearchMediaType {
  Audio = 'Audio',
  Photo = 'Photo',
  Video = 'Video',
}

export type SearchNotificationFiltersArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
};

export type SearchOrganizationsFiltersArgs = {
  /** Organization name */
  name?: InputMaybe<Scalars['String']>;
  /** Organization Id */
  organizationId?: InputMaybe<Scalars['Int']>;
  /** Organization Parent Id */
  organizationParentId?: InputMaybe<Scalars['Int']>;
  /** Organization status code */
  organizationStatusCodes?: InputMaybe<Array<Scalars['String']>>;
  /** Organization Type Id */
  representationTypeId?: InputMaybe<Scalars['Int']>;
  /** System role code */
  systemRoles?: InputMaybe<Array<Scalars['String']>>;
};

export type SearchPageGroupInput = {
  /** Returns dataset after provided key */
  after?: InputMaybe<Array<GroupLastKeyInput>>;
  /** Page size */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type SearchPageInput = {
  /** Returns dataset after provided key */
  after?: InputMaybe<Scalars['String']>;
  /** Page number */
  pageNumber?: InputMaybe<Scalars['Int']>;
  /** Page size */
  pageSize: Scalars['Int'];
};

export type SearchPageInputWf = {
  /** Returns dataset after provided key */
  after?: InputMaybe<Array<Scalars['String']>>;
  /** Page size */
  pageSize: Scalars['Int'];
};

/** Input type for search representatives */
export type SearchRepresentativeInput = {
  /** Company name */
  companyName?: InputMaybe<Scalars['String']>;
  /** List of Dma CODEs */
  dmas?: InputMaybe<Array<Scalars['String']>>;
  /** List of Representative Age Category CODEs */
  representativeAgeCategoryCodes?: InputMaybe<Array<Scalars['String']>>;
  /** List of Representative Category CODEs */
  representativeCategoryCodes?: InputMaybe<Array<Scalars['String']>>;
  /** List of Representative Type CODEs */
  representativeTypeCodes?: InputMaybe<Array<Scalars['String']>>;
};

export enum SearchResultType {
  All = 'ALL',
  Documents = 'DOCUMENTS',
  Facets = 'FACETS',
  Groups = 'GROUPS',
}

/** SeekingRepresentation object */
export type SeekingRepresentation = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type SendDirectMessageToTalentsInput = {
  message: Scalars['String'];
  profileIds: Array<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['Int']>;
  subject?: InputMaybe<Scalars['String']>;
  talentCanSeeSenderAddress?: InputMaybe<Scalars['Boolean']>;
};

export type SendVoiceOverMediaRequestInput = {
  roleId: Scalars['Int'];
  submissionIds: Array<Scalars['Int']>;
  talentInstructions: Scalars['String'];
};

export type SeriesDetails = {
  projectSeriesEpisode?: Maybe<Scalars['Int']>;
  projectSeriesId?: Maybe<Scalars['ID']>;
  projectSeriesName?: Maybe<Scalars['String']>;
  projectSeriesSeason?: Maybe<Scalars['Int']>;
};

export type Session = {
  accessToken?: Maybe<Scalars['String']>;
  context?: Maybe<AccountContext>;
  deviceId?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type SetSubscriptionPaymentMethodInput = {
  billingInfoId: Scalars['String'];
  subscriptionId: Scalars['Int'];
};

/** SexualSituation object */
export type SexualSituation = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum SexualSituationEnum {
  Nudity = 'NUDITY',
  SexualSituations = 'SEXUAL_SITUATIONS',
}

/** Audition Presentation input type */
export type ShareSelectPresentationInput = {
  /** Date of link expiration */
  linkExpiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Project ID */
  projectId: Scalars['Int'];
  /** Role IDs */
  roleIds: Array<Scalars['Int']>;
  /** Tile Scheme Codes */
  tileSchemeCodes: Array<Scalars['String']>;
};

/** Parameters used to create a Share Talent Presentation */
export type ShareTalentPresentationInput = {
  /** Date of link expiration */
  linkExpiresAt?: InputMaybe<Scalars['DateTime']>;
  /** The name of the presentation */
  name: Scalars['String'];
  /** Project ID */
  projectId: Scalars['Int'];
  /** Talent from these roles that meet the request criteria will be added to the presentation. */
  roleIds: Array<Scalars['Int']>;
  /** The list of statuses of submissions to add to the presentation. */
  submissionStatuses: Array<SubmissionStatusEnum>;
  /** Determines which title schemes should be added to the presentation */
  tileSchemeCodes: Array<Scalars['String']>;
};

export type ShiftTimeframeInput = {
  newStartTime: Scalars['DateTime'];
  scheduleId: Scalars['Int'];
  timeframeId: Scalars['Int'];
};

/** Shoe modifier object */
export type ShoeModifier = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Measurement object */
export type ShoeSize = {
  /** Gender */
  category: BasicSetting;
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** ShoeSize for AU */
  shoeSizeAU: Scalars['String'];
  /** ShoeSize for EU */
  shoeSizeEU: Scalars['String'];
  /** ShoeSize for UK */
  shoeSizeUK: Scalars['String'];
  /** ShoeSize for US */
  shoeSizeUS: Scalars['String'];
  /** short order */
  sortOrder: Scalars['Float'];
};

export enum SizeGenderEnum {
  Boy = 'BOY',
  Female = 'FEMALE',
  Girl = 'GIRL',
  Infant = 'INFANT',
  Kid = 'KID',
  Male = 'MALE',
  Toddler = 'TODDLER',
}

/** Size Modifier object */
export type SizeModifier = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Skill object */
export type Skill = {
  /** child objects */
  childObjects: Array<Skill>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Skill>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum SkillEnum {
  ActingStyles = 'ACTING_STYLES',
  ActingStylesArtaud = 'ACTING_STYLES_ARTAUD',
  ActingStylesBrecht = 'ACTING_STYLES_BRECHT',
  ActingStylesGrotowsky = 'ACTING_STYLES_GROTOWSKY',
  ActingStylesImprovisationGeneral = 'ACTING_STYLES_IMPROVISATION_GENERAL',
  ActingStylesImprovisationLongForm = 'ACTING_STYLES_IMPROVISATION_LONG_FORM',
  ActingStylesImprovisationMusical = 'ACTING_STYLES_IMPROVISATION_MUSICAL',
  ActingStylesImprovisationShortForm = 'ACTING_STYLES_IMPROVISATION_SHORT_FORM',
  ActingStylesKabuki = 'ACTING_STYLES_KABUKI',
  ActingStylesMeisner = 'ACTING_STYLES_MEISNER',
  ActingStylesMethod = 'ACTING_STYLES_METHOD',
  ActingStylesShakespearean = 'ACTING_STYLES_SHAKESPEAREAN',
  ActingStylesSketch = 'ACTING_STYLES_SKETCH',
  ActingStylesStandUp = 'ACTING_STYLES_STAND_UP',
  ActingStylesStanislavski = 'ACTING_STYLES_STANISLAVSKI',
  ActingStylesViolaSpolin = 'ACTING_STYLES_VIOLA_SPOLIN',
  BandStyles = 'BAND_STYLES',
  BandStylesAlternative = 'BAND_STYLES_ALTERNATIVE',
  BandStylesArtRock = 'BAND_STYLES_ART_ROCK',
  BandStylesACappella = 'BAND_STYLES_A_CAPPELLA',
  BandStylesBachata = 'BAND_STYLES_BACHATA',
  BandStylesBigBand = 'BAND_STYLES_BIG_BAND',
  BandStylesBluegrass = 'BAND_STYLES_BLUEGRASS',
  BandStylesBlues = 'BAND_STYLES_BLUES',
  BandStylesBolero = 'BAND_STYLES_BOLERO',
  BandStylesBoyGroup = 'BAND_STYLES_BOY_GROUP',
  BandStylesCeltic = 'BAND_STYLES_CELTIC',
  BandStylesClassicalMusic = 'BAND_STYLES_CLASSICAL_MUSIC',
  BandStylesCorrido = 'BAND_STYLES_CORRIDO',
  BandStylesCountry = 'BAND_STYLES_COUNTRY',
  BandStylesCumbia = 'BAND_STYLES_CUMBIA',
  BandStylesElectronic = 'BAND_STYLES_ELECTRONIC',
  BandStylesFolk = 'BAND_STYLES_FOLK',
  BandStylesFunk = 'BAND_STYLES_FUNK',
  BandStylesGirlGroup = 'BAND_STYLES_GIRL_GROUP',
  BandStylesGospel = 'BAND_STYLES_GOSPEL',
  BandStylesHardcore = 'BAND_STYLES_HARDCORE',
  BandStylesHipHop = 'BAND_STYLES_HIP_HOP',
  BandStylesIndie = 'BAND_STYLES_INDIE',
  BandStylesIndustrial = 'BAND_STYLES_INDUSTRIAL',
  BandStylesInstrumental = 'BAND_STYLES_INSTRUMENTAL',
  BandStylesJazz = 'BAND_STYLES_JAZZ',
  BandStylesLatin = 'BAND_STYLES_LATIN',
  BandStylesMariachi = 'BAND_STYLES_MARIACHI',
  BandStylesMetal = 'BAND_STYLES_METAL',
  BandStylesNewWave = 'BAND_STYLES_NEW_WAVE',
  BandStylesNorteno = 'BAND_STYLES_NORTENO',
  BandStylesOneMan = 'BAND_STYLES_ONE_MAN',
  BandStylesOther = 'BAND_STYLES_OTHER',
  BandStylesPop = 'BAND_STYLES_POP',
  BandStylesPopRock = 'BAND_STYLES_POP_ROCK',
  BandStylesPunk = 'BAND_STYLES_PUNK',
  BandStylesRanchero = 'BAND_STYLES_RANCHERO',
  BandStylesRap = 'BAND_STYLES_RAP',
  BandStylesReggae = 'BAND_STYLES_REGGAE',
  BandStylesReggaeton = 'BAND_STYLES_REGGAETON',
  BandStylesRhythmAndBlues = 'BAND_STYLES_RHYTHM_AND_BLUES',
  BandStylesRock = 'BAND_STYLES_ROCK',
  BandStylesRockabilly = 'BAND_STYLES_ROCKABILLY',
  BandStylesRockAndRoll = 'BAND_STYLES_ROCK_AND_ROLL',
  BandStylesSalsa = 'BAND_STYLES_SALSA',
  BandStylesSwing = 'BAND_STYLES_SWING',
  BandStylesTribal = 'BAND_STYLES_TRIBAL',
  BandStylesWedding = 'BAND_STYLES_WEDDING',
  CircusSkills = 'CIRCUS_SKILLS',
  CircusSkillsAerialHoopLyra = 'CIRCUS_SKILLS_AERIAL_HOOP_LYRA',
  CircusSkillsAerialRope = 'CIRCUS_SKILLS_AERIAL_ROPE',
  CircusSkillsAerialSilks = 'CIRCUS_SKILLS_AERIAL_SILKS',
  CircusSkillsAerialWorkGeneral = 'CIRCUS_SKILLS_AERIAL_WORK_GENERAL',
  CircusSkillsClowning = 'CIRCUS_SKILLS_CLOWNING',
  CircusSkillsContortion = 'CIRCUS_SKILLS_CONTORTION',
  CircusSkillsCyrWheel = 'CIRCUS_SKILLS_CYR_WHEEL',
  CircusSkillsFireEating = 'CIRCUS_SKILLS_FIRE_EATING',
  CircusSkillsFireJuggling = 'CIRCUS_SKILLS_FIRE_JUGGLING',
  CircusSkillsHighWire = 'CIRCUS_SKILLS_HIGH_WIRE',
  CircusSkillsJuggling = 'CIRCUS_SKILLS_JUGGLING',
  CircusSkillsRhonradGermanWheel = 'CIRCUS_SKILLS_RHONRAD_GERMAN_WHEEL',
  CircusSkillsStiltWalking = 'CIRCUS_SKILLS_STILT_WALKING',
  CircusSkillsTightRope = 'CIRCUS_SKILLS_TIGHT_ROPE',
  CircusSkillsTrapeze = 'CIRCUS_SKILLS_TRAPEZE',
  CircusSkillsUnicycle = 'CIRCUS_SKILLS_UNICYCLE',
  Dance = 'DANCE',
  DanceAfrican = 'DANCE_AFRICAN',
  DanceBallet = 'DANCE_BALLET',
  DanceBallroom = 'DANCE_BALLROOM',
  DanceBelly = 'DANCE_BELLY',
  DanceBollywoodIndian = 'DANCE_BOLLYWOOD_INDIAN',
  DanceBreak = 'DANCE_BREAK',
  DanceChaCha = 'DANCE_CHA_CHA',
  DanceClog = 'DANCE_CLOG',
  DanceClubFreestyle = 'DANCE_CLUB_FREESTYLE',
  DanceContemporary = 'DANCE_CONTEMPORARY',
  DanceContraDance = 'DANCE_CONTRA_DANCE',
  DanceDisco = 'DANCE_DISCO',
  DanceExoticDancing = 'DANCE_EXOTIC_DANCING',
  DanceFlamenco = 'DANCE_FLAMENCO',
  DanceFlexingBoneBreaking = 'DANCE_FLEXING_BONE_BREAKING',
  DanceFolk = 'DANCE_FOLK',
  DanceHipHop = 'DANCE_HIP_HOP',
  DanceHouse = 'DANCE_HOUSE',
  DanceHula = 'DANCE_HULA',
  DanceIrish = 'DANCE_IRISH',
  DanceJazz = 'DANCE_JAZZ',
  DanceJive = 'DANCE_JIVE',
  DanceKrump = 'DANCE_KRUMP',
  DanceLine = 'DANCE_LINE',
  DanceModern = 'DANCE_MODERN',
  DanceModernJazz = 'DANCE_MODERN_JAZZ',
  DancePointe = 'DANCE_POINTE',
  DancePole = 'DANCE_POLE',
  DancePolka = 'DANCE_POLKA',
  DancePopAndLock = 'DANCE_POP_AND_LOCK',
  DanceReggae = 'DANCE_REGGAE',
  DanceRobot = 'DANCE_ROBOT',
  DanceRockAndRoll = 'DANCE_ROCK_AND_ROLL',
  DanceRumba = 'DANCE_RUMBA',
  DanceSalsa = 'DANCE_SALSA',
  DanceSamba = 'DANCE_SAMBA',
  DanceSquare = 'DANCE_SQUARE',
  DanceSwing = 'DANCE_SWING',
  DanceTango = 'DANCE_TANGO',
  DanceTap = 'DANCE_TAP',
  DanceTutting = 'DANCE_TUTTING',
  DanceVogue = 'DANCE_VOGUE',
  DanceWaacking = 'DANCE_WAACKING',
  DanceWaltz = 'DANCE_WALTZ',
  Dialects = 'DIALECTS',
  DialectsAmerican = 'DIALECTS_AMERICAN',
  DialectsAmericanMidwesternMinnesotaWisconsin = 'DIALECTS_AMERICAN_MIDWESTERN_MINNESOTA_WISCONSIN',
  DialectsAmericanMidAtlantic = 'DIALECTS_AMERICAN_MID_ATLANTIC',
  DialectsAmericanNewEnglandBoston = 'DIALECTS_AMERICAN_NEW_ENGLAND_BOSTON',
  DialectsAmericanNewJersey = 'DIALECTS_AMERICAN_NEW_JERSEY',
  DialectsAmericanNewYorkBronxBrooklynQueens = 'DIALECTS_AMERICAN_NEW_YORK_BRONX_BROOKLYN_QUEENS',
  DialectsAmericanNewYorkLongIsland = 'DIALECTS_AMERICAN_NEW_YORK_LONG_ISLAND',
  DialectsAmericanSouthern = 'DIALECTS_AMERICAN_SOUTHERN',
  DialectsArmenian = 'DIALECTS_ARMENIAN',
  DialectsAustralian = 'DIALECTS_AUSTRALIAN',
  DialectsAustralianAboriginal = 'DIALECTS_AUSTRALIAN_ABORIGINAL',
  DialectsBelgian = 'DIALECTS_BELGIAN',
  DialectsBritish = 'DIALECTS_BRITISH',
  DialectsCajun = 'DIALECTS_CAJUN',
  DialectsCanadian = 'DIALECTS_CANADIAN',
  DialectsChinese = 'DIALECTS_CHINESE',
  DialectsCockney = 'DIALECTS_COCKNEY',
  DialectsEastIndian = 'DIALECTS_EAST_INDIAN',
  DialectsFrench = 'DIALECTS_FRENCH',
  DialectsFrenchCanadian = 'DIALECTS_FRENCH_CANADIAN',
  DialectsGerman = 'DIALECTS_GERMAN',
  DialectsIrish = 'DIALECTS_IRISH',
  DialectsItalian = 'DIALECTS_ITALIAN',
  DialectsJamaican = 'DIALECTS_JAMAICAN',
  DialectsJapanese = 'DIALECTS_JAPANESE',
  DialectsLatinAmericaArgentine = 'DIALECTS_LATIN_AMERICA_ARGENTINE',
  DialectsLatinAmericaBrazilian = 'DIALECTS_LATIN_AMERICA_BRAZILIAN',
  DialectsLatinAmericaChilean = 'DIALECTS_LATIN_AMERICA_CHILEAN',
  DialectsLatinAmericaCoastal = 'DIALECTS_LATIN_AMERICA_COASTAL',
  DialectsLatinAmericaColombian = 'DIALECTS_LATIN_AMERICA_COLOMBIAN',
  DialectsLatinAmericaCuban = 'DIALECTS_LATIN_AMERICA_CUBAN',
  DialectsLatinAmericaNorthern = 'DIALECTS_LATIN_AMERICA_NORTHERN',
  DialectsLiverpool = 'DIALECTS_LIVERPOOL',
  DialectsMexican = 'DIALECTS_MEXICAN',
  DialectsMiddleEasternArabicPersian = 'DIALECTS_MIDDLE_EASTERN_ARABIC_PERSIAN',
  DialectsNewZealand = 'DIALECTS_NEW_ZEALAND',
  DialectsPuertoRican = 'DIALECTS_PUERTO_RICAN',
  DialectsReceivedPronunciation = 'DIALECTS_RECEIVED_PRONUNCIATION',
  DialectsRussian = 'DIALECTS_RUSSIAN',
  DialectsScandinavian = 'DIALECTS_SCANDINAVIAN',
  DialectsScottish = 'DIALECTS_SCOTTISH',
  DialectsSouthAfrican = 'DIALECTS_SOUTH_AFRICAN',
  DialectsSpanishAmericas = 'DIALECTS_SPANISH_AMERICAS',
  DialectsSpanishCastilian = 'DIALECTS_SPANISH_CASTILIAN',
  DialectsSpanishSpain = 'DIALECTS_SPANISH_SPAIN',
  DialectsSwissGerman = 'DIALECTS_SWISS_GERMAN',
  DialectsTorresStraitIslander = 'DIALECTS_TORRES_STRAIT_ISLANDER',
  DialectsWelsh = 'DIALECTS_WELSH',
  DrivingBoatingPiloting = 'DRIVING_BOATING_PILOTING',
  DrivingBoatingPiloting_4Wd = 'DRIVING_BOATING_PILOTING_4WD',
  DrivingBoatingPilotingAirplane = 'DRIVING_BOATING_PILOTING_AIRPLANE',
  DrivingBoatingPilotingAtv = 'DRIVING_BOATING_PILOTING_ATV',
  DrivingBoatingPilotingBoatGeneral = 'DRIVING_BOATING_PILOTING_BOAT_GENERAL',
  DrivingBoatingPilotingBoatJumping = 'DRIVING_BOATING_PILOTING_BOAT_JUMPING',
  DrivingBoatingPilotingCanoeing = 'DRIVING_BOATING_PILOTING_CANOEING',
  DrivingBoatingPilotingDuneBuggy = 'DRIVING_BOATING_PILOTING_DUNE_BUGGY',
  DrivingBoatingPilotingHelicopter = 'DRIVING_BOATING_PILOTING_HELICOPTER',
  DrivingBoatingPilotingHotAirBalloon = 'DRIVING_BOATING_PILOTING_HOT_AIR_BALLOON',
  DrivingBoatingPilotingJetSkiing = 'DRIVING_BOATING_PILOTING_JET_SKIING',
  DrivingBoatingPilotingKayaking = 'DRIVING_BOATING_PILOTING_KAYAKING',
  DrivingBoatingPilotingKiteSurfing = 'DRIVING_BOATING_PILOTING_KITE_SURFING',
  DrivingBoatingPilotingMotorboat = 'DRIVING_BOATING_PILOTING_MOTORBOAT',
  DrivingBoatingPilotingMotorcycleDirtBike = 'DRIVING_BOATING_PILOTING_MOTORCYCLE_DIRT_BIKE',
  DrivingBoatingPilotingMotorcycleRoad = 'DRIVING_BOATING_PILOTING_MOTORCYCLE_ROAD',
  DrivingBoatingPilotingPaddleBoarding = 'DRIVING_BOATING_PILOTING_PADDLE_BOARDING',
  DrivingBoatingPilotingRafting = 'DRIVING_BOATING_PILOTING_RAFTING',
  DrivingBoatingPilotingRoadDriving = 'DRIVING_BOATING_PILOTING_ROAD_DRIVING',
  DrivingBoatingPilotingRowing = 'DRIVING_BOATING_PILOTING_ROWING',
  DrivingBoatingPilotingSailing = 'DRIVING_BOATING_PILOTING_SAILING',
  DrivingBoatingPilotingSculling = 'DRIVING_BOATING_PILOTING_SCULLING',
  DrivingBoatingPilotingSemiTruck = 'DRIVING_BOATING_PILOTING_SEMI_TRUCK',
  DrivingBoatingPilotingSnowmobile = 'DRIVING_BOATING_PILOTING_SNOWMOBILE',
  DrivingBoatingPilotingStickShift = 'DRIVING_BOATING_PILOTING_STICK_SHIFT',
  DrivingBoatingPilotingTrackDriving = 'DRIVING_BOATING_PILOTING_TRACK_DRIVING',
  Hobbies = 'HOBBIES',
  HobbiesAstrology = 'HOBBIES_ASTROLOGY',
  HobbiesCarpentry = 'HOBBIES_CARPENTRY',
  HobbiesCooking = 'HOBBIES_COOKING',
  HobbiesCrochet = 'HOBBIES_CROCHET',
  HobbiesGamingVideoComputer = 'HOBBIES_GAMING_VIDEO_COMPUTER',
  HobbiesKnitting = 'HOBBIES_KNITTING',
  HobbiesPottery = 'HOBBIES_POTTERY',
  Instruments = 'INSTRUMENTS',
  InstrumentsAccordion = 'INSTRUMENTS_ACCORDION',
  InstrumentsAutoharp = 'INSTRUMENTS_AUTOHARP',
  InstrumentsBagpipes = 'INSTRUMENTS_BAGPIPES',
  InstrumentsBanjo = 'INSTRUMENTS_BANJO',
  InstrumentsBanjoUke = 'INSTRUMENTS_BANJO_UKE',
  InstrumentsBassoon = 'INSTRUMENTS_BASSOON',
  InstrumentsBassDoubleUpright = 'INSTRUMENTS_BASS_DOUBLE_UPRIGHT',
  InstrumentsBassGuitarAcoustic = 'INSTRUMENTS_BASS_GUITAR_ACOUSTIC',
  InstrumentsBassGuitarElectric = 'INSTRUMENTS_BASS_GUITAR_ELECTRIC',
  InstrumentsBones = 'INSTRUMENTS_BONES',
  InstrumentsBongos = 'INSTRUMENTS_BONGOS',
  InstrumentsCajonDeTapeo = 'INSTRUMENTS_CAJON_DE_TAPEO',
  InstrumentsCello = 'INSTRUMENTS_CELLO',
  InstrumentsClarinet = 'INSTRUMENTS_CLARINET',
  InstrumentsConga = 'INSTRUMENTS_CONGA',
  InstrumentsDrums = 'INSTRUMENTS_DRUMS',
  InstrumentsDulcimer = 'INSTRUMENTS_DULCIMER',
  InstrumentsFiddle = 'INSTRUMENTS_FIDDLE',
  InstrumentsFingerCymbals = 'INSTRUMENTS_FINGER_CYMBALS',
  InstrumentsFlute = 'INSTRUMENTS_FLUTE',
  InstrumentsFrenchHorn = 'INSTRUMENTS_FRENCH_HORN',
  InstrumentsGuiro = 'INSTRUMENTS_GUIRO',
  InstrumentsGuitarron = 'INSTRUMENTS_GUITARRON',
  InstrumentsGuitarAcoustic = 'INSTRUMENTS_GUITAR_ACOUSTIC',
  InstrumentsGuitarElectric = 'INSTRUMENTS_GUITAR_ELECTRIC',
  InstrumentsGuitarGeneral = 'INSTRUMENTS_GUITAR_GENERAL',
  InstrumentsHarmonica = 'INSTRUMENTS_HARMONICA',
  InstrumentsHarp = 'INSTRUMENTS_HARP',
  InstrumentsJarana = 'INSTRUMENTS_JARANA',
  InstrumentsKazoo = 'INSTRUMENTS_KAZOO',
  InstrumentsMandolin = 'INSTRUMENTS_MANDOLIN',
  InstrumentsMaracas = 'INSTRUMENTS_MARACAS',
  InstrumentsMarimba = 'INSTRUMENTS_MARIMBA',
  InstrumentsMelodica = 'INSTRUMENTS_MELODICA',
  InstrumentsOboe = 'INSTRUMENTS_OBOE',
  InstrumentsOrgan = 'INSTRUMENTS_ORGAN',
  InstrumentsPercusssion = 'INSTRUMENTS_PERCUSSSION',
  InstrumentsPianoGeneral = 'INSTRUMENTS_PIANO_GENERAL',
  InstrumentsPianoJazz = 'INSTRUMENTS_PIANO_JAZZ',
  InstrumentsPianoKeyboard = 'INSTRUMENTS_PIANO_KEYBOARD',
  InstrumentsPiccolo = 'INSTRUMENTS_PICCOLO',
  InstrumentsRecorder = 'INSTRUMENTS_RECORDER',
  InstrumentsSaxophone = 'INSTRUMENTS_SAXOPHONE',
  InstrumentsSynthesizer = 'INSTRUMENTS_SYNTHESIZER',
  InstrumentsTambourine = 'INSTRUMENTS_TAMBOURINE',
  InstrumentsTololoche = 'INSTRUMENTS_TOLOLOCHE',
  InstrumentsTrombone = 'INSTRUMENTS_TROMBONE',
  InstrumentsTrumpet = 'INSTRUMENTS_TRUMPET',
  InstrumentsTuba = 'INSTRUMENTS_TUBA',
  InstrumentsTubaSousaphone = 'INSTRUMENTS_TUBA_SOUSAPHONE',
  InstrumentsUkulele = 'INSTRUMENTS_UKULELE',
  InstrumentsViola = 'INSTRUMENTS_VIOLA',
  InstrumentsViolin = 'INSTRUMENTS_VIOLIN',
  Jobs = 'JOBS',
  JobsBartender = 'JOBS_BARTENDER',
  JobsBullfighter = 'JOBS_BULLFIGHTER',
  JobsCardDealer = 'JOBS_CARD_DEALER',
  JobsChef = 'JOBS_CHEF',
  JobsComedian = 'JOBS_COMEDIAN',
  JobsDiscJockey = 'JOBS_DISC_JOCKEY',
  JobsMassageTherapist = 'JOBS_MASSAGE_THERAPIST',
  JobsMedical = 'JOBS_MEDICAL',
  JobsStenographer = 'JOBS_STENOGRAPHER',
  JobsStripper = 'JOBS_STRIPPER',
  Languages = 'LANGUAGES',
  LanguagesAbkhazian = 'LANGUAGES_ABKHAZIAN',
  LanguagesAfrikaans = 'LANGUAGES_AFRIKAANS',
  LanguagesAlbanian = 'LANGUAGES_ALBANIAN',
  LanguagesAmericanSignLanguage = 'LANGUAGES_AMERICAN_SIGN_LANGUAGE',
  LanguagesAmharic = 'LANGUAGES_AMHARIC',
  LanguagesArabic = 'LANGUAGES_ARABIC',
  LanguagesArmenian = 'LANGUAGES_ARMENIAN',
  LanguagesAustralianAboriginal = 'LANGUAGES_AUSTRALIAN_ABORIGINAL',
  LanguagesAustralianSignLanguage = 'LANGUAGES_AUSTRALIAN_SIGN_LANGUAGE',
  LanguagesAymara = 'LANGUAGES_AYMARA',
  LanguagesAzerbaijani = 'LANGUAGES_AZERBAIJANI',
  LanguagesBelarusian = 'LANGUAGES_BELARUSIAN',
  LanguagesBengali = 'LANGUAGES_BENGALI',
  LanguagesBerber = 'LANGUAGES_BERBER',
  LanguagesBisaya = 'LANGUAGES_BISAYA',
  LanguagesBislama = 'LANGUAGES_BISLAMA',
  LanguagesBosnian = 'LANGUAGES_BOSNIAN',
  LanguagesBritishSignLanguage = 'LANGUAGES_BRITISH_SIGN_LANGUAGE',
  LanguagesBulgarian = 'LANGUAGES_BULGARIAN',
  LanguagesBurmese = 'LANGUAGES_BURMESE',
  LanguagesCatalan = 'LANGUAGES_CATALAN',
  LanguagesCelticGaelic = 'LANGUAGES_CELTIC_GAELIC',
  LanguagesChichewaNyanja = 'LANGUAGES_CHICHEWA_NYANJA',
  LanguagesChineseCantonese = 'LANGUAGES_CHINESE_CANTONESE',
  LanguagesChineseMandarin = 'LANGUAGES_CHINESE_MANDARIN',
  LanguagesChineseOtherVariety = 'LANGUAGES_CHINESE_OTHER_VARIETY',
  LanguagesCroatian = 'LANGUAGES_CROATIAN',
  LanguagesCzech = 'LANGUAGES_CZECH',
  LanguagesDanish = 'LANGUAGES_DANISH',
  LanguagesDari = 'LANGUAGES_DARI',
  LanguagesDhivehi = 'LANGUAGES_DHIVEHI',
  LanguagesDutch = 'LANGUAGES_DUTCH',
  LanguagesDzongkha = 'LANGUAGES_DZONGKHA',
  LanguagesEnglish = 'LANGUAGES_ENGLISH',
  LanguagesEstonian = 'LANGUAGES_ESTONIAN',
  LanguagesFijian = 'LANGUAGES_FIJIAN',
  LanguagesFilipino = 'LANGUAGES_FILIPINO',
  LanguagesFinnish = 'LANGUAGES_FINNISH',
  LanguagesFlemish = 'LANGUAGES_FLEMISH',
  LanguagesFrench = 'LANGUAGES_FRENCH',
  LanguagesFrenchCanada = 'LANGUAGES_FRENCH_CANADA',
  LanguagesGeorgian = 'LANGUAGES_GEORGIAN',
  LanguagesGerman = 'LANGUAGES_GERMAN',
  LanguagesGreek = 'LANGUAGES_GREEK',
  LanguagesGuarani = 'LANGUAGES_GUARANI',
  LanguagesGujarati = 'LANGUAGES_GUJARATI',
  LanguagesHaitianCreole = 'LANGUAGES_HAITIAN_CREOLE',
  LanguagesHebrew = 'LANGUAGES_HEBREW',
  LanguagesHindi = 'LANGUAGES_HINDI',
  LanguagesHiriMotu = 'LANGUAGES_HIRI_MOTU',
  LanguagesHungarian = 'LANGUAGES_HUNGARIAN',
  LanguagesIcelandic = 'LANGUAGES_ICELANDIC',
  LanguagesIlocano = 'LANGUAGES_ILOCANO',
  LanguagesIndonesian = 'LANGUAGES_INDONESIAN',
  LanguagesItalian = 'LANGUAGES_ITALIAN',
  LanguagesJapanese = 'LANGUAGES_JAPANESE',
  LanguagesKazakh = 'LANGUAGES_KAZAKH',
  LanguagesKhmer = 'LANGUAGES_KHMER',
  LanguagesKinyarwanda = 'LANGUAGES_KINYARWANDA',
  LanguagesKirundi = 'LANGUAGES_KIRUNDI',
  LanguagesKorean = 'LANGUAGES_KOREAN',
  LanguagesKurdish = 'LANGUAGES_KURDISH',
  LanguagesKyrgyz = 'LANGUAGES_KYRGYZ',
  LanguagesLao = 'LANGUAGES_LAO',
  LanguagesLatin = 'LANGUAGES_LATIN',
  LanguagesLatvian = 'LANGUAGES_LATVIAN',
  LanguagesLithuanian = 'LANGUAGES_LITHUANIAN',
  LanguagesLuxembourgish = 'LANGUAGES_LUXEMBOURGISH',
  LanguagesMacedonian = 'LANGUAGES_MACEDONIAN',
  LanguagesMalagasy = 'LANGUAGES_MALAGASY',
  LanguagesMalay = 'LANGUAGES_MALAY',
  LanguagesMaltese = 'LANGUAGES_MALTESE',
  LanguagesMaori = 'LANGUAGES_MAORI',
  LanguagesMoldovan = 'LANGUAGES_MOLDOVAN',
  LanguagesMongolian = 'LANGUAGES_MONGOLIAN',
  LanguagesMontenegrin = 'LANGUAGES_MONTENEGRIN',
  LanguagesNdebele = 'LANGUAGES_NDEBELE',
  LanguagesNepali = 'LANGUAGES_NEPALI',
  LanguagesNorthernSotho = 'LANGUAGES_NORTHERN_SOTHO',
  LanguagesNorwegian = 'LANGUAGES_NORWEGIAN',
  LanguagesOssetian = 'LANGUAGES_OSSETIAN',
  LanguagesPapiamento = 'LANGUAGES_PAPIAMENTO',
  LanguagesPashto = 'LANGUAGES_PASHTO',
  LanguagesPersianFarsi = 'LANGUAGES_PERSIAN_FARSI',
  LanguagesPolish = 'LANGUAGES_POLISH',
  LanguagesPortuguese = 'LANGUAGES_PORTUGUESE',
  LanguagesQuechua = 'LANGUAGES_QUECHUA',
  LanguagesRomanian = 'LANGUAGES_ROMANIAN',
  LanguagesRomansh = 'LANGUAGES_ROMANSH',
  LanguagesRussian = 'LANGUAGES_RUSSIAN',
  LanguagesSamoan = 'LANGUAGES_SAMOAN',
  LanguagesSango = 'LANGUAGES_SANGO',
  LanguagesSerbian = 'LANGUAGES_SERBIAN',
  LanguagesSeychelloisCreole = 'LANGUAGES_SEYCHELLOIS_CREOLE',
  LanguagesShona = 'LANGUAGES_SHONA',
  LanguagesSinhala = 'LANGUAGES_SINHALA',
  LanguagesSlovak = 'LANGUAGES_SLOVAK',
  LanguagesSlovene = 'LANGUAGES_SLOVENE',
  LanguagesSomali = 'LANGUAGES_SOMALI',
  LanguagesSotho = 'LANGUAGES_SOTHO',
  LanguagesSpanish = 'LANGUAGES_SPANISH',
  LanguagesSrananTongo = 'LANGUAGES_SRANAN_TONGO',
  LanguagesSwahili = 'LANGUAGES_SWAHILI',
  LanguagesSwati = 'LANGUAGES_SWATI',
  LanguagesSwedish = 'LANGUAGES_SWEDISH',
  LanguagesTagalog = 'LANGUAGES_TAGALOG',
  LanguagesTaiwanese = 'LANGUAGES_TAIWANESE',
  LanguagesTajik = 'LANGUAGES_TAJIK',
  LanguagesTamil = 'LANGUAGES_TAMIL',
  LanguagesTetum = 'LANGUAGES_TETUM',
  LanguagesThai = 'LANGUAGES_THAI',
  LanguagesTigre = 'LANGUAGES_TIGRE',
  LanguagesTigrinya = 'LANGUAGES_TIGRINYA',
  LanguagesToisan = 'LANGUAGES_TOISAN',
  LanguagesTokPisin = 'LANGUAGES_TOK_PISIN',
  LanguagesTongan = 'LANGUAGES_TONGAN',
  LanguagesTorresStraitIslander = 'LANGUAGES_TORRES_STRAIT_ISLANDER',
  LanguagesTsonga = 'LANGUAGES_TSONGA',
  LanguagesTswana = 'LANGUAGES_TSWANA',
  LanguagesTurkish = 'LANGUAGES_TURKISH',
  LanguagesTurkmen = 'LANGUAGES_TURKMEN',
  LanguagesUkrainian = 'LANGUAGES_UKRAINIAN',
  LanguagesUrdu = 'LANGUAGES_URDU',
  LanguagesUzbek = 'LANGUAGES_UZBEK',
  LanguagesVenda = 'LANGUAGES_VENDA',
  LanguagesVietnamese = 'LANGUAGES_VIETNAMESE',
  LanguagesWelsh = 'LANGUAGES_WELSH',
  LanguagesXhosa = 'LANGUAGES_XHOSA',
  LanguagesYiddish = 'LANGUAGES_YIDDISH',
  LanguagesYoruba = 'LANGUAGES_YORUBA',
  LanguagesZulu = 'LANGUAGES_ZULU',
  Military = 'MILITARY',
  MilitaryAirForce = 'MILITARY_AIR_FORCE',
  MilitaryArmy = 'MILITARY_ARMY',
  MilitaryCoastGuard = 'MILITARY_COAST_GUARD',
  MilitaryMarines = 'MILITARY_MARINES',
  MilitaryNationalGuard = 'MILITARY_NATIONAL_GUARD',
  MilitaryNavy = 'MILITARY_NAVY',
  MilitaryNavySeals = 'MILITARY_NAVY_SEALS',
  MilitaryOther = 'MILITARY_OTHER',
  MilitaryRoyalAirForce = 'MILITARY_ROYAL_AIR_FORCE',
  MilitaryROTC = 'MILITARY_R_O_T_C_',
  MilitarySpecialForces = 'MILITARY_SPECIAL_FORCES',
  PerformanceSkills = 'PERFORMANCE_SKILLS',
  PerformanceSkillsBurlesque = 'PERFORMANCE_SKILLS_BURLESQUE',
  PerformanceSkillsCardTricks = 'PERFORMANCE_SKILLS_CARD_TRICKS',
  PerformanceSkillsDrag = 'PERFORMANCE_SKILLS_DRAG',
  PerformanceSkillsEarPrompting = 'PERFORMANCE_SKILLS_EAR_PROMPTING',
  PerformanceSkillsHosting = 'PERFORMANCE_SKILLS_HOSTING',
  PerformanceSkillsImpersonationsImpressions = 'PERFORMANCE_SKILLS_IMPERSONATIONS_IMPRESSIONS',
  PerformanceSkillsMagic = 'PERFORMANCE_SKILLS_MAGIC',
  PerformanceSkillsMascot = 'PERFORMANCE_SKILLS_MASCOT',
  PerformanceSkillsMiming = 'PERFORMANCE_SKILLS_MIMING',
  PerformanceSkillsPuppetry = 'PERFORMANCE_SKILLS_PUPPETRY',
  PerformanceSkillsStandIn = 'PERFORMANCE_SKILLS_STAND_IN',
  PerformanceSkillsTeleprompter = 'PERFORMANCE_SKILLS_TELEPROMPTER',
  PerformanceSkillsVentriloquism = 'PERFORMANCE_SKILLS_VENTRILOQUISM',
  PerformanceSkillsVoiceOver = 'PERFORMANCE_SKILLS_VOICE_OVER',
  PerformanceSkillsWhistling = 'PERFORMANCE_SKILLS_WHISTLING',
  PerformanceSkillsYoYo = 'PERFORMANCE_SKILLS_YO_YO',
  Rodeo = 'RODEO',
  RodeoBarrelRacing = 'RODEO_BARREL_RACING',
  RodeoBullRiding = 'RODEO_BULL_RIDING',
  RodeoCala = 'RODEO_CALA',
  RodeoCharroCompleto = 'RODEO_CHARRO_COMPLETO',
  RodeoColas = 'RODEO_COLAS',
  RodeoEscaramuzaCharra = 'RODEO_ESCARAMUZA_CHARRA',
  RodeoJineteoDeYegua = 'RODEO_JINETEO_DE_YEGUA',
  RodeoJineteoToro = 'RODEO_JINETEO_TORO',
  RodeoManganasAPieYACaballo = 'RODEO_MANGANAS_A_PIE_Y_A_CABALLO',
  RodeoMuttonBusting = 'RODEO_MUTTON_BUSTING',
  RodeoPasoDeLaMuerte = 'RODEO_PASO_DE_LA_MUERTE',
  RodeoPiales = 'RODEO_PIALES',
  RodeoRoping = 'RODEO_ROPING',
  RodeoTernaEnElRuedo = 'RODEO_TERNA_EN_EL_RUEDO',
  SingingStyles = 'SINGING_STYLES',
  SingingStylesACappella = 'SINGING_STYLES_A_CAPPELLA',
  SingingStylesBeatboxing = 'SINGING_STYLES_BEATBOXING',
  SingingStylesBlues = 'SINGING_STYLES_BLUES',
  SingingStylesCeltic = 'SINGING_STYLES_CELTIC',
  SingingStylesCountry = 'SINGING_STYLES_COUNTRY',
  SingingStylesFlamenco = 'SINGING_STYLES_FLAMENCO',
  SingingStylesFolk = 'SINGING_STYLES_FOLK',
  SingingStylesGospel = 'SINGING_STYLES_GOSPEL',
  SingingStylesGregorianChant = 'SINGING_STYLES_GREGORIAN_CHANT',
  SingingStylesGutturalDeathGrowl = 'SINGING_STYLES_GUTTURAL_DEATH_GROWL',
  SingingStylesHeavyMetalHardcorePunk = 'SINGING_STYLES_HEAVY_METAL_HARDCORE_PUNK',
  SingingStylesJazz = 'SINGING_STYLES_JAZZ',
  SingingStylesLounge = 'SINGING_STYLES_LOUNGE',
  SingingStylesMotown = 'SINGING_STYLES_MOTOWN',
  SingingStylesMusicalTheatre = 'SINGING_STYLES_MUSICAL_THEATRE',
  SingingStylesOpera = 'SINGING_STYLES_OPERA',
  SingingStylesPop = 'SINGING_STYLES_POP',
  SingingStylesRanchero = 'SINGING_STYLES_RANCHERO',
  SingingStylesRap = 'SINGING_STYLES_RAP',
  SingingStylesReggae = 'SINGING_STYLES_REGGAE',
  SingingStylesRock = 'SINGING_STYLES_ROCK',
  SingingStylesRB = 'SINGING_STYLES_R_B',
  SingingStylesSingingGeneral = 'SINGING_STYLES_SINGING_GENERAL',
  SingingStylesVibrato = 'SINGING_STYLES_VIBRATO',
  SingingStylesYodeling = 'SINGING_STYLES_YODELING',
  Sports = 'SPORTS',
  SportsCombatSports = 'SPORTS_COMBAT_SPORTS',
  SportsCombatSportsBoxing = 'SPORTS_COMBAT_SPORTS_BOXING',
  SportsCombatSportsFencing = 'SPORTS_COMBAT_SPORTS_FENCING',
  SportsCombatSportsKickboxing = 'SPORTS_COMBAT_SPORTS_KICKBOXING',
  SportsCombatSportsMma = 'SPORTS_COMBAT_SPORTS_MMA',
  SportsCombatSportsSumo = 'SPORTS_COMBAT_SPORTS_SUMO',
  SportsCombatSportsWrestlingGeneral = 'SPORTS_COMBAT_SPORTS_WRESTLING_GENERAL',
  SportsCombatSportsWrestlingGrecoRoman = 'SPORTS_COMBAT_SPORTS_WRESTLING_GRECO_ROMAN',
  SportsCombatSportsWrestlingLuchaLibre = 'SPORTS_COMBAT_SPORTS_WRESTLING_LUCHA_LIBRE',
  SportsCycling = 'SPORTS_CYCLING',
  SportsCyclingBmx = 'SPORTS_CYCLING_BMX',
  SportsCyclingCyclingGeneral = 'SPORTS_CYCLING_CYCLING_GENERAL',
  SportsCyclingDownhillBiking = 'SPORTS_CYCLING_DOWNHILL_BIKING',
  SportsCyclingFlatlandFreestyle = 'SPORTS_CYCLING_FLATLAND_FREESTYLE',
  SportsCyclingHalfPipeVertWall = 'SPORTS_CYCLING_HALF_PIPE_VERT_WALL',
  SportsCyclingJumping = 'SPORTS_CYCLING_JUMPING',
  SportsCyclingMountainBiking = 'SPORTS_CYCLING_MOUNTAIN_BIKING',
  SportsCyclingRecumbent = 'SPORTS_CYCLING_RECUMBENT',
  SportsCyclingRoadBiking = 'SPORTS_CYCLING_ROAD_BIKING',
  SportsCyclingTrialsRiding = 'SPORTS_CYCLING_TRIALS_RIDING',
  SportsEquestrianSports = 'SPORTS_EQUESTRIAN_SPORTS',
  SportsEquestrianSportsBareBack = 'SPORTS_EQUESTRIAN_SPORTS_BARE_BACK',
  SportsEquestrianSportsDressage = 'SPORTS_EQUESTRIAN_SPORTS_DRESSAGE',
  SportsEquestrianSportsEnduranceRiding = 'SPORTS_EQUESTRIAN_SPORTS_ENDURANCE_RIDING',
  SportsEquestrianSportsEnglishRiding = 'SPORTS_EQUESTRIAN_SPORTS_ENGLISH_RIDING',
  SportsEquestrianSportsHarnessRacing = 'SPORTS_EQUESTRIAN_SPORTS_HARNESS_RACING',
  SportsEquestrianSportsHorsebackRidingGeneral = 'SPORTS_EQUESTRIAN_SPORTS_HORSEBACK_RIDING_GENERAL',
  SportsEquestrianSportsHorseJumping = 'SPORTS_EQUESTRIAN_SPORTS_HORSE_JUMPING',
  SportsEquestrianSportsPolo = 'SPORTS_EQUESTRIAN_SPORTS_POLO',
  SportsEquestrianSportsRodeo = 'SPORTS_EQUESTRIAN_SPORTS_RODEO',
  SportsEquestrianSportsRope = 'SPORTS_EQUESTRIAN_SPORTS_ROPE',
  SportsEquestrianSportsSideSaddle = 'SPORTS_EQUESTRIAN_SPORTS_SIDE_SADDLE',
  SportsEquestrianSportsWestern = 'SPORTS_EQUESTRIAN_SPORTS_WESTERN',
  SportsExtremeSports = 'SPORTS_EXTREME_SPORTS',
  SportsExtremeSportsBaseJumping = 'SPORTS_EXTREME_SPORTS_BASE_JUMPING',
  SportsExtremeSportsBungeeJumping = 'SPORTS_EXTREME_SPORTS_BUNGEE_JUMPING',
  SportsExtremeSportsCanopying = 'SPORTS_EXTREME_SPORTS_CANOPYING',
  SportsExtremeSportsFreediving = 'SPORTS_EXTREME_SPORTS_FREEDIVING',
  SportsExtremeSportsGliding = 'SPORTS_EXTREME_SPORTS_GLIDING',
  SportsExtremeSportsHangGliding = 'SPORTS_EXTREME_SPORTS_HANG_GLIDING',
  SportsExtremeSportsInlineSkatingGeneral = 'SPORTS_EXTREME_SPORTS_INLINE_SKATING_GENERAL',
  SportsExtremeSportsInlineSkatingStuntsCompetitive = 'SPORTS_EXTREME_SPORTS_INLINE_SKATING_STUNTS_COMPETITIVE',
  SportsExtremeSportsMountainBoarding = 'SPORTS_EXTREME_SPORTS_MOUNTAIN_BOARDING',
  SportsExtremeSportsParkour = 'SPORTS_EXTREME_SPORTS_PARKOUR',
  SportsExtremeSportsSkateboardingGeneral = 'SPORTS_EXTREME_SPORTS_SKATEBOARDING_GENERAL',
  SportsExtremeSportsSkateboardingHalfPipeVert = 'SPORTS_EXTREME_SPORTS_SKATEBOARDING_HALF_PIPE_VERT',
  SportsExtremeSportsSkateboardingSlalom = 'SPORTS_EXTREME_SPORTS_SKATEBOARDING_SLALOM',
  SportsExtremeSportsSkateboardingStreet = 'SPORTS_EXTREME_SPORTS_SKATEBOARDING_STREET',
  SportsExtremeSportsSkateboardingStuntsCompetitive = 'SPORTS_EXTREME_SPORTS_SKATEBOARDING_STUNTS_COMPETITIVE',
  SportsExtremeSportsSkyDiving = 'SPORTS_EXTREME_SPORTS_SKY_DIVING',
  SportsExtremeSportsSkySurfing = 'SPORTS_EXTREME_SPORTS_SKY_SURFING',
  SportsExtremeSportsSupercross = 'SPORTS_EXTREME_SPORTS_SUPERCROSS',
  SportsExtremeSportsWingSuit = 'SPORTS_EXTREME_SPORTS_WING_SUIT',
  SportsGymnasticSports = 'SPORTS_GYMNASTIC_SPORTS',
  SportsGymnasticSportsAcrobaticGymnastics = 'SPORTS_GYMNASTIC_SPORTS_ACROBATIC_GYMNASTICS',
  SportsGymnasticSportsBalanceBeam = 'SPORTS_GYMNASTIC_SPORTS_BALANCE_BEAM',
  SportsGymnasticSportsBatonTwirling = 'SPORTS_GYMNASTIC_SPORTS_BATON_TWIRLING',
  SportsGymnasticSportsCheerleading = 'SPORTS_GYMNASTIC_SPORTS_CHEERLEADING',
  SportsGymnasticSportsHighBar = 'SPORTS_GYMNASTIC_SPORTS_HIGH_BAR',
  SportsGymnasticSportsParallelBars = 'SPORTS_GYMNASTIC_SPORTS_PARALLEL_BARS',
  SportsGymnasticSportsPommelHorse = 'SPORTS_GYMNASTIC_SPORTS_POMMEL_HORSE',
  SportsGymnasticSportsRhythmicGymnastics = 'SPORTS_GYMNASTIC_SPORTS_RHYTHMIC_GYMNASTICS',
  SportsGymnasticSportsStillRings = 'SPORTS_GYMNASTIC_SPORTS_STILL_RINGS',
  SportsGymnasticSportsTrampoline = 'SPORTS_GYMNASTIC_SPORTS_TRAMPOLINE',
  SportsGymnasticSportsTumbling = 'SPORTS_GYMNASTIC_SPORTS_TUMBLING',
  SportsGymnasticSportsUnevenBars = 'SPORTS_GYMNASTIC_SPORTS_UNEVEN_BARS',
  SportsGymnasticSportsVault = 'SPORTS_GYMNASTIC_SPORTS_VAULT',
  SportsGymSports = 'SPORTS_GYM_SPORTS',
  SportsGymSportsAerobics = 'SPORTS_GYM_SPORTS_AEROBICS',
  SportsGymSportsBodyBuilding = 'SPORTS_GYM_SPORTS_BODY_BUILDING',
  SportsGymSportsCrossfitTraining = 'SPORTS_GYM_SPORTS_CROSSFIT_TRAINING',
  SportsGymSportsJogging = 'SPORTS_GYM_SPORTS_JOGGING',
  SportsGymSportsPilates = 'SPORTS_GYM_SPORTS_PILATES',
  SportsGymSportsRopeClimbing = 'SPORTS_GYM_SPORTS_ROPE_CLIMBING',
  SportsGymSportsSpinning = 'SPORTS_GYM_SPORTS_SPINNING',
  SportsGymSportsTaeBo = 'SPORTS_GYM_SPORTS_TAE_BO',
  SportsGymSportsWeightLifting = 'SPORTS_GYM_SPORTS_WEIGHT_LIFTING',
  SportsGymSportsYoga = 'SPORTS_GYM_SPORTS_YOGA',
  SportsGymSportsZumba = 'SPORTS_GYM_SPORTS_ZUMBA',
  SportsMartialArts = 'SPORTS_MARTIAL_ARTS',
  SportsMartialArtsAikido = 'SPORTS_MARTIAL_ARTS_AIKIDO',
  SportsMartialArtsBrazilianJiuJitsu = 'SPORTS_MARTIAL_ARTS_BRAZILIAN_JIU_JITSU',
  SportsMartialArtsCapoeira = 'SPORTS_MARTIAL_ARTS_CAPOEIRA',
  SportsMartialArtsEscrima = 'SPORTS_MARTIAL_ARTS_ESCRIMA',
  SportsMartialArtsGrappling = 'SPORTS_MARTIAL_ARTS_GRAPPLING',
  SportsMartialArtsHandToHand = 'SPORTS_MARTIAL_ARTS_HAND_TO_HAND',
  SportsMartialArtsHapkido = 'SPORTS_MARTIAL_ARTS_HAPKIDO',
  SportsMartialArtsJeetKuneDo = 'SPORTS_MARTIAL_ARTS_JEET_KUNE_DO',
  SportsMartialArtsJiuJitsu = 'SPORTS_MARTIAL_ARTS_JIU_JITSU',
  SportsMartialArtsJudo = 'SPORTS_MARTIAL_ARTS_JUDO',
  SportsMartialArtsKarate = 'SPORTS_MARTIAL_ARTS_KARATE',
  SportsMartialArtsKendo = 'SPORTS_MARTIAL_ARTS_KENDO',
  SportsMartialArtsKravMaga = 'SPORTS_MARTIAL_ARTS_KRAV_MAGA',
  SportsMartialArtsKungFu = 'SPORTS_MARTIAL_ARTS_KUNG_FU',
  SportsMartialArtsMuayLao = 'SPORTS_MARTIAL_ARTS_MUAY_LAO',
  SportsMartialArtsMuayThai = 'SPORTS_MARTIAL_ARTS_MUAY_THAI',
  SportsMartialArtsSambo = 'SPORTS_MARTIAL_ARTS_SAMBO',
  SportsMartialArtsSanshou = 'SPORTS_MARTIAL_ARTS_SANSHOU',
  SportsMartialArtsTaeKwonDo = 'SPORTS_MARTIAL_ARTS_TAE_KWON_DO',
  SportsMartialArtsTaiChi = 'SPORTS_MARTIAL_ARTS_TAI_CHI',
  SportsMartialArtsValeTudo = 'SPORTS_MARTIAL_ARTS_VALE_TUDO',
  SportsOutdoorSports = 'SPORTS_OUTDOOR_SPORTS',
  SportsOutdoorSportsArchery = 'SPORTS_OUTDOOR_SPORTS_ARCHERY',
  SportsOutdoorSportsClayShooting = 'SPORTS_OUTDOOR_SPORTS_CLAY_SHOOTING',
  SportsOutdoorSportsFishing = 'SPORTS_OUTDOOR_SPORTS_FISHING',
  SportsOutdoorSportsFlyFishing = 'SPORTS_OUTDOOR_SPORTS_FLY_FISHING',
  SportsOutdoorSportsGolfing = 'SPORTS_OUTDOOR_SPORTS_GOLFING',
  SportsOutdoorSportsHiking = 'SPORTS_OUTDOOR_SPORTS_HIKING',
  SportsOutdoorSportsHunting = 'SPORTS_OUTDOOR_SPORTS_HUNTING',
  SportsOutdoorSportsMountainClimbing = 'SPORTS_OUTDOOR_SPORTS_MOUNTAIN_CLIMBING',
  SportsOutdoorSportsRappelling = 'SPORTS_OUTDOOR_SPORTS_RAPPELLING',
  SportsOutdoorSportsRockClimbing = 'SPORTS_OUTDOOR_SPORTS_ROCK_CLIMBING',
  SportsOutdoorSportsSkeetShooting = 'SPORTS_OUTDOOR_SPORTS_SKEET_SHOOTING',
  SportsOutdoorSportsTrapShooting = 'SPORTS_OUTDOOR_SPORTS_TRAP_SHOOTING',
  SportsRacquetSports = 'SPORTS_RACQUET_SPORTS',
  SportsRacquetSportsBadminton = 'SPORTS_RACQUET_SPORTS_BADMINTON',
  SportsRacquetSportsFrontenis = 'SPORTS_RACQUET_SPORTS_FRONTENIS',
  SportsRacquetSportsPickleball = 'SPORTS_RACQUET_SPORTS_PICKLEBALL',
  SportsRacquetSportsRacquetball = 'SPORTS_RACQUET_SPORTS_RACQUETBALL',
  SportsRacquetSportsSquash = 'SPORTS_RACQUET_SPORTS_SQUASH',
  SportsRacquetSportsTennis = 'SPORTS_RACQUET_SPORTS_TENNIS',
  SportsRecreationalSports = 'SPORTS_RECREATIONAL_SPORTS',
  SportsRecreationalSportsBowling = 'SPORTS_RECREATIONAL_SPORTS_BOWLING',
  SportsRecreationalSportsCroquet = 'SPORTS_RECREATIONAL_SPORTS_CROQUET',
  SportsRecreationalSportsDarts = 'SPORTS_RECREATIONAL_SPORTS_DARTS',
  SportsRecreationalSportsFrisbee = 'SPORTS_RECREATIONAL_SPORTS_FRISBEE',
  SportsRecreationalSportsFrisbeeGolf = 'SPORTS_RECREATIONAL_SPORTS_FRISBEE_GOLF',
  SportsRecreationalSportsHackySack = 'SPORTS_RECREATIONAL_SPORTS_HACKY_SACK',
  SportsRecreationalSportsHulaHoop = 'SPORTS_RECREATIONAL_SPORTS_HULA_HOOP',
  SportsRecreationalSportsJumpRopeDoubleDutch = 'SPORTS_RECREATIONAL_SPORTS_JUMP_ROPE_DOUBLE_DUTCH',
  SportsRecreationalSportsJumpRopeGeneral = 'SPORTS_RECREATIONAL_SPORTS_JUMP_ROPE_GENERAL',
  SportsRecreationalSportsLaserTag = 'SPORTS_RECREATIONAL_SPORTS_LASER_TAG',
  SportsRecreationalSportsPaintball = 'SPORTS_RECREATIONAL_SPORTS_PAINTBALL',
  SportsRecreationalSportsPogoStick = 'SPORTS_RECREATIONAL_SPORTS_POGO_STICK',
  SportsRecreationalSportsRazorScooter = 'SPORTS_RECREATIONAL_SPORTS_RAZOR_SCOOTER',
  SportsRecreationalSportsRollerSkating = 'SPORTS_RECREATIONAL_SPORTS_ROLLER_SKATING',
  SportsRecreationalSportsSegwayRiding = 'SPORTS_RECREATIONAL_SPORTS_SEGWAY_RIDING',
  SportsRecreationalSportsShuffleBoard = 'SPORTS_RECREATIONAL_SPORTS_SHUFFLE_BOARD',
  SportsRecreationalSportsZorbing = 'SPORTS_RECREATIONAL_SPORTS_ZORBING',
  SportsTableSports = 'SPORTS_TABLE_SPORTS',
  SportsTableSportsAirHockey = 'SPORTS_TABLE_SPORTS_AIR_HOCKEY',
  SportsTableSportsBilliards = 'SPORTS_TABLE_SPORTS_BILLIARDS',
  SportsTableSportsFoosball = 'SPORTS_TABLE_SPORTS_FOOSBALL',
  SportsTableSportsPool = 'SPORTS_TABLE_SPORTS_POOL',
  SportsTableSportsSnooker = 'SPORTS_TABLE_SPORTS_SNOOKER',
  SportsTableSportsTableTennisPingPong = 'SPORTS_TABLE_SPORTS_TABLE_TENNIS_PING_PONG',
  SportsTeamSports = 'SPORTS_TEAM_SPORTS',
  SportsTeamSportsAmericanFootball = 'SPORTS_TEAM_SPORTS_AMERICAN_FOOTBALL',
  SportsTeamSportsAustralianFootball = 'SPORTS_TEAM_SPORTS_AUSTRALIAN_FOOTBALL',
  SportsTeamSportsBaseball = 'SPORTS_TEAM_SPORTS_BASEBALL',
  SportsTeamSportsBasketball = 'SPORTS_TEAM_SPORTS_BASKETBALL',
  SportsTeamSportsBeachVolleyball = 'SPORTS_TEAM_SPORTS_BEACH_VOLLEYBALL',
  SportsTeamSportsCricket = 'SPORTS_TEAM_SPORTS_CRICKET',
  SportsTeamSportsDodgeBall = 'SPORTS_TEAM_SPORTS_DODGE_BALL',
  SportsTeamSportsFieldHockey = 'SPORTS_TEAM_SPORTS_FIELD_HOCKEY',
  SportsTeamSportsHandball = 'SPORTS_TEAM_SPORTS_HANDBALL',
  SportsTeamSportsHurling = 'SPORTS_TEAM_SPORTS_HURLING',
  SportsTeamSportsIceHockey = 'SPORTS_TEAM_SPORTS_ICE_HOCKEY',
  SportsTeamSportsIndoorSoccer = 'SPORTS_TEAM_SPORTS_INDOOR_SOCCER',
  SportsTeamSportsLacrosse = 'SPORTS_TEAM_SPORTS_LACROSSE',
  SportsTeamSportsRollerHockey = 'SPORTS_TEAM_SPORTS_ROLLER_HOCKEY',
  SportsTeamSportsRugbyLeague = 'SPORTS_TEAM_SPORTS_RUGBY_LEAGUE',
  SportsTeamSportsRugbyUnion = 'SPORTS_TEAM_SPORTS_RUGBY_UNION',
  SportsTeamSportsSoccer = 'SPORTS_TEAM_SPORTS_SOCCER',
  SportsTeamSportsSoftball = 'SPORTS_TEAM_SPORTS_SOFTBALL',
  SportsTeamSportsVolleyball = 'SPORTS_TEAM_SPORTS_VOLLEYBALL',
  SportsTrackAndField = 'SPORTS_TRACK_AND_FIELD',
  SportsTrackAndFieldBroadJump = 'SPORTS_TRACK_AND_FIELD_BROAD_JUMP',
  SportsTrackAndFieldDecathlon = 'SPORTS_TRACK_AND_FIELD_DECATHLON',
  SportsTrackAndFieldDiscus = 'SPORTS_TRACK_AND_FIELD_DISCUS',
  SportsTrackAndFieldDistanceRunning = 'SPORTS_TRACK_AND_FIELD_DISTANCE_RUNNING',
  SportsTrackAndFieldHammer = 'SPORTS_TRACK_AND_FIELD_HAMMER',
  SportsTrackAndFieldHeptathlon = 'SPORTS_TRACK_AND_FIELD_HEPTATHLON',
  SportsTrackAndFieldHighJump = 'SPORTS_TRACK_AND_FIELD_HIGH_JUMP',
  SportsTrackAndFieldHurdle = 'SPORTS_TRACK_AND_FIELD_HURDLE',
  SportsTrackAndFieldJavelin = 'SPORTS_TRACK_AND_FIELD_JAVELIN',
  SportsTrackAndFieldLongJump = 'SPORTS_TRACK_AND_FIELD_LONG_JUMP',
  SportsTrackAndFieldMarathon = 'SPORTS_TRACK_AND_FIELD_MARATHON',
  SportsTrackAndFieldPoleVault = 'SPORTS_TRACK_AND_FIELD_POLE_VAULT',
  SportsTrackAndFieldRelay = 'SPORTS_TRACK_AND_FIELD_RELAY',
  SportsTrackAndFieldRunning = 'SPORTS_TRACK_AND_FIELD_RUNNING',
  SportsTrackAndFieldShotPut = 'SPORTS_TRACK_AND_FIELD_SHOT_PUT',
  SportsTrackAndFieldSprinting = 'SPORTS_TRACK_AND_FIELD_SPRINTING',
  SportsTrackAndFieldTrackGeneral = 'SPORTS_TRACK_AND_FIELD_TRACK_GENERAL',
  SportsTrackAndFieldTriathlon = 'SPORTS_TRACK_AND_FIELD_TRIATHLON',
  SportsTrackAndFieldTripleJump = 'SPORTS_TRACK_AND_FIELD_TRIPLE_JUMP',
  SportsWaterSports = 'SPORTS_WATER_SPORTS',
  SportsWaterSportsBodyboarding = 'SPORTS_WATER_SPORTS_BODYBOARDING',
  SportsWaterSportsBodySurfing = 'SPORTS_WATER_SPORTS_BODY_SURFING',
  SportsWaterSportsBoogieBoarding = 'SPORTS_WATER_SPORTS_BOOGIE_BOARDING',
  SportsWaterSportsDiving = 'SPORTS_WATER_SPORTS_DIVING',
  SportsWaterSportsScubaDiving = 'SPORTS_WATER_SPORTS_SCUBA_DIVING',
  SportsWaterSportsSkimBoarding = 'SPORTS_WATER_SPORTS_SKIM_BOARDING',
  SportsWaterSportsSnorkeling = 'SPORTS_WATER_SPORTS_SNORKELING',
  SportsWaterSportsSurfing = 'SPORTS_WATER_SPORTS_SURFING',
  SportsWaterSportsSwimmingBackStroke = 'SPORTS_WATER_SPORTS_SWIMMING_BACK_STROKE',
  SportsWaterSportsSwimmingBreastStroke = 'SPORTS_WATER_SPORTS_SWIMMING_BREAST_STROKE',
  SportsWaterSportsSwimmingButterfly = 'SPORTS_WATER_SPORTS_SWIMMING_BUTTERFLY',
  SportsWaterSportsSwimmingFreestyle = 'SPORTS_WATER_SPORTS_SWIMMING_FREESTYLE',
  SportsWaterSportsSwimmingGeneral = 'SPORTS_WATER_SPORTS_SWIMMING_GENERAL',
  SportsWaterSportsSwimmingSynchronized = 'SPORTS_WATER_SPORTS_SWIMMING_SYNCHRONIZED',
  SportsWaterSportsWakeboarding = 'SPORTS_WATER_SPORTS_WAKEBOARDING',
  SportsWaterSportsWaterPolo = 'SPORTS_WATER_SPORTS_WATER_POLO',
  SportsWaterSportsWaterSkiing = 'SPORTS_WATER_SPORTS_WATER_SKIING',
  SportsWaterSportsWindsurfing = 'SPORTS_WATER_SPORTS_WINDSURFING',
  SportsWinterSports = 'SPORTS_WINTER_SPORTS',
  SportsWinterSportsBiathlon = 'SPORTS_WINTER_SPORTS_BIATHLON',
  SportsWinterSportsCrossCountrySkiing = 'SPORTS_WINTER_SPORTS_CROSS_COUNTRY_SKIING',
  SportsWinterSportsFigureSkating = 'SPORTS_WINTER_SPORTS_FIGURE_SKATING',
  SportsWinterSportsIceSkating = 'SPORTS_WINTER_SPORTS_ICE_SKATING',
  SportsWinterSportsLuge = 'SPORTS_WINTER_SPORTS_LUGE',
  SportsWinterSportsSkiJumping = 'SPORTS_WINTER_SPORTS_SKI_JUMPING',
  SportsWinterSportsSnowboarding = 'SPORTS_WINTER_SPORTS_SNOWBOARDING',
  SportsWinterSportsSnowSkiing = 'SPORTS_WINTER_SPORTS_SNOW_SKIING',
  SportsWinterSportsSpeedSkating = 'SPORTS_WINTER_SPORTS_SPEED_SKATING',
  Stunts = 'STUNTS',
  StuntsAirRams = 'STUNTS_AIR_RAMS',
  StuntsBreakaways = 'STUNTS_BREAKAWAYS',
  StuntsBreakFalls = 'STUNTS_BREAK_FALLS',
  StuntsBuildingFalls = 'STUNTS_BUILDING_FALLS',
  StuntsCarHits = 'STUNTS_CAR_HITS',
  StuntsCreatureSuitWork = 'STUNTS_CREATURE_SUIT_WORK',
  StuntsFullFireBurns = 'STUNTS_FULL_FIRE_BURNS',
  StuntsHighFallsAbove_50 = 'STUNTS_HIGH_FALLS_ABOVE_50',
  StuntsHighFallsBelow_50 = 'STUNTS_HIGH_FALLS_BELOW_50',
  StuntsHorseFalls = 'STUNTS_HORSE_FALLS',
  StuntsPartialFireBurns = 'STUNTS_PARTIAL_FIRE_BURNS',
  StuntsPratfalls = 'STUNTS_PRATFALLS',
  StuntsPrecisionDriving = 'STUNTS_PRECISION_DRIVING',
  StuntsRatchets = 'STUNTS_RATCHETS',
  StuntsSaddleFalls = 'STUNTS_SADDLE_FALLS',
  StuntsSquibHits = 'STUNTS_SQUIB_HITS',
  StuntsStageCombat = 'STUNTS_STAGE_COMBAT',
  StuntsStairFalls = 'STUNTS_STAIR_FALLS',
  StuntsWireWork = 'STUNTS_WIRE_WORK',
  VocalRange = 'VOCAL_RANGE',
  VocalRangeAlto = 'VOCAL_RANGE_ALTO',
  VocalRangeBaritone = 'VOCAL_RANGE_BARITONE',
  VocalRangeBass = 'VOCAL_RANGE_BASS',
  VocalRangeMezzoSoprano = 'VOCAL_RANGE_MEZZO_SOPRANO',
  VocalRangeSoprano = 'VOCAL_RANGE_SOPRANO',
  VocalRangeTenor = 'VOCAL_RANGE_TENOR',
  WeaponsFirearms = 'WEAPONS_FIREARMS',
  WeaponsFirearmsAutomaticWeapon = 'WEAPONS_FIREARMS_AUTOMATIC_WEAPON',
  WeaponsFirearmsBoStaff = 'WEAPONS_FIREARMS_BO_STAFF',
  WeaponsFirearmsBroadsword = 'WEAPONS_FIREARMS_BROADSWORD',
  WeaponsFirearmsCrossBow = 'WEAPONS_FIREARMS_CROSS_BOW',
  WeaponsFirearmsDaggers = 'WEAPONS_FIREARMS_DAGGERS',
  WeaponsFirearmsHandgun = 'WEAPONS_FIREARMS_HANDGUN',
  WeaponsFirearmsKama = 'WEAPONS_FIREARMS_KAMA',
  WeaponsFirearmsLongBow = 'WEAPONS_FIREARMS_LONG_BOW',
  WeaponsFirearmsNunchucks = 'WEAPONS_FIREARMS_NUNCHUCKS',
  WeaponsFirearmsRapier = 'WEAPONS_FIREARMS_RAPIER',
  WeaponsFirearmsRifle = 'WEAPONS_FIREARMS_RIFLE',
  WeaponsFirearmsSai = 'WEAPONS_FIREARMS_SAI',
  WeaponsFirearmsShotgun = 'WEAPONS_FIREARMS_SHOTGUN',
  WeaponsFirearmsStaff = 'WEAPONS_FIREARMS_STAFF',
  WeaponsFirearmsSwords = 'WEAPONS_FIREARMS_SWORDS',
  WeaponsFirearmsThrowingKnives = 'WEAPONS_FIREARMS_THROWING_KNIVES',
  WeaponsFirearmsThrowingStars = 'WEAPONS_FIREARMS_THROWING_STARS',
  WeaponsFirearmsTonfa = 'WEAPONS_FIREARMS_TONFA',
  WeaponsFirearmsWhip = 'WEAPONS_FIREARMS_WHIP',
}

/** Input type for skill and skill level */
export type SkillInput = {
  id: Scalars['ID'];
  levelId?: InputMaybe<Scalars['Float']>;
  note?: InputMaybe<Scalars['String']>;
  profileMediaId?: InputMaybe<Scalars['Float']>;
};

/** SkillLevel object */
export type SkillLevel = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum SkillLevelEnum {
  Beginner = 'BEGINNER',
  Expert = 'EXPERT',
  Intermediate = 'INTERMEDIATE',
}

/** Input type for skill and skill level */
export type SkillSearchInput = {
  id: Scalars['ID'];
  levelId?: InputMaybe<Scalars['ID']>;
};

export type Sleeve = {
  /** Imperial Feet Inches */
  IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  cm: Scalars['Int'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
  /** Inches rounded */
  inchRounded: Scalars['String'];
  /** Show in imperial UI */
  showInImperialUI: Scalars['Boolean'];
};

/** Holds the input data for Slot. */
export type Slot = {
  /** Slot argument ID */
  slotArguments: Array<SlotArguments>;
  /** Slot ID */
  slotId: Scalars['Int'];
  /** Submission ID */
  submissionIds: Array<Scalars['Int']>;
};

/** Holds the link between slot and entity. */
export type SlotArguments = {
  /** entity ID */
  entityId: Scalars['BigInt'];
  /** Slot argument entity ID */
  entityType: Scalars['Int'];
  /** Slot argument ID */
  slotArgId: Scalars['Int'];
};

export type SlotSubmissionsInput = {
  /** Slot Id */
  slotId: Scalars['Float'];
  /** List of SubmissionIds. */
  submissionIds: Array<Scalars['BigInt']>;
};

/** Result Sort Conditions */
export type SortConditionInput = {
  /** Column name by which result should be sorted */
  orderColumn: Scalars['String'];
  /** Either ASC or DESC */
  orderDirection: Scalars['String'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Sport Equipment object */
export type SportEquipment = {
  /** child objects */
  childObjects: Array<SportEquipment>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<SportEquipment>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** State */
export type State = {
  /** code */
  code: Scalars['String'];
  /** Country Id */
  country: Country;
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type StateFilterInput = {
  /** Filter states by country ID or CODE values */
  country?: InputMaybe<FilterInput>;
  /** Filter states by ID or CODE values */
  state?: InputMaybe<FilterInput>;
};

export type SubFieldsGql = {
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

/** Submission object */
export type Submission = {
  /** Comments count left in presentations */
  commentsCount?: Maybe<Scalars['Int']>;
  /** Submission creator company name */
  companyName: Scalars['String'];
  contactInfo?: Maybe<ProfileContactInfo>;
  /** Submission's cover */
  cover?: Maybe<Media>;
  /** Created Date */
  created: Scalars['DateTime'];
  /** Submission creator account */
  creatorAccount: Account;
  /** Notes for the Submission object */
  fastcastNotes: Array<Note>;
  /** Determines has submission audio reel or not */
  hasAudioReel: Scalars['Boolean'];
  /** Determines has submission video reel or not */
  hasVideoReel: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['BigInt'];
  /** @deprecated DEPRECATED. Use submission.source instead. */
  isCreatedByCD?: Maybe<Scalars['Boolean']>;
  /** Identify the submission is cutomized or not. */
  isCustomized: Scalars['Boolean'];
  /** Represents that profile is overscale or not */
  isOverscale?: Maybe<Scalars['Boolean']>;
  /** Last note for the submission */
  lastNote?: Maybe<Note>;
  /** Submission status last changed date */
  lastStatusUpdated?: Maybe<Scalars['DateTime']>;
  /** Submission's media assets */
  media: Array<SubmissionMedia>;
  mediaCount: Array<MediaCount>;
  /** Note */
  note: Scalars['String'];
  /** Notes for the Submission object */
  notes: Array<Note>;
  otherSubmissionSelectionType: ProfileSelectionType;
  /** Over scale note */
  overscaleNote?: Maybe<Scalars['String']>;
  pickedBy: Array<SubmissionPick>;
  /** Picks count left in presentations */
  picksCount?: Maybe<Scalars['Int']>;
  /** Profile associated with the subscription */
  profile?: Maybe<Profile>;
  /** Submission Profile Id */
  profileId: Scalars['Int'];
  /** Profile Working Location ID */
  profileWorkingLocationId?: Maybe<Scalars['Int']>;
  /** Submission request's media assets */
  requestMedia: Array<SubmissionMedia>;
  /** Role associated with the submission */
  role: Role;
  /** Submission Role Id */
  roleId: Scalars['Int'];
  /** Submission Role Name */
  roleName?: Maybe<Scalars['String']>;
  /** Schedule Info */
  scheduleInfo?: Maybe<ScheduleInfo>;
  /** Schedule slot detail associated with submission */
  scheduleSlotDetail?: Maybe<SubmissionScheduleSlot>;
  /** Is Scheduled */
  scheduled?: Maybe<Scalars['Boolean']>;
  /** Snapshot media assets */
  snapshotMedia?: Maybe<Media>;
  /** Source of the Submission */
  source?: Maybe<SubmissionSourceType>;
  /** Submission Previous Status */
  submissionPreviousStatus?: Maybe<SubmissionStatus>;
  /** SSubmissions with responses details */
  submissionResponses?: Maybe<SubmissionResponses>;
  /** Submission Status */
  submissionStatus?: Maybe<SubmissionStatus>;
  /** Tile schema value object */
  tileSchemaValue?: Maybe<TileSchemaValue>;
  /** Tile Scheme Value ID */
  tileSchemeValueId?: Maybe<Scalars['Int']>;
  /** Customized Profile Working Location */
  workingLocation?: Maybe<ProfileWorkingLocation>;
};

/** Submission object */
export type SubmissionMediaCountArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  requested?: InputMaybe<Scalars['Boolean']>;
};

/** Submission object */
export type SubmissionPickedByArgs = {
  clientNames?: InputMaybe<Array<Scalars['String']>>;
};

/** Submission object */
export type SubmissionProfileArgs = {
  returnDeleted?: InputMaybe<Scalars['Boolean']>;
};

/** Submissions By Profile */
export type SubmissionByProfileInput = {
  /** Start Date */
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  /** End Date */
  dateTo?: InputMaybe<Scalars['DateTime']>;
  /** Offset */
  pageNumber?: InputMaybe<Scalars['Float']>;
  /** Page Size */
  pageSize?: InputMaybe<Scalars['Float']>;
};

/** Submission Count By Role object */
export type SubmissionCountPerRole = {
  /** Submission Role Id */
  roleId?: Maybe<Scalars['Int']>;
  /** Total count of rows */
  totalCount?: Maybe<Scalars['Int']>;
};

/** Submission Search input */
export type SubmissionCountPerRoleInput = {
  excludeRequestMasterId?: InputMaybe<Scalars['String']>;
  /** Profile Type Id */
  profileTypeId?: InputMaybe<Scalars['Int']>;
  /** List of Role IDs */
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  stageName?: InputMaybe<Scalars['String']>;
  /** List of Submission Status Codes */
  submissionStatusCodes?: InputMaybe<Array<Scalars['String']>>;
  /** List of Submission Tile Schema Codes */
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type SubmissionCreated = {
  created: Scalars['Boolean'];
  submissions: Array<Submission>;
};

/** Determine discrete boundaries for project filtering */
export enum SubmissionDueDateSearchEnum {
  Future = 'FUTURE',
  Today = 'TODAY',
}

/** Submission Media object */
export type SubmissionMedia = {
  accountId: Scalars['Float'];
  appearOnSubmissions: Scalars['Boolean'];
  appearancesOnProfiles: Scalars['Int'];
  archivableOn?: Maybe<Scalars['DateTime']>;
  created?: Maybe<Scalars['DateTime']>;
  /** Returns the Organization of the media create, if one exists */
  createdByOrg?: Maybe<Organization>;
  /** Formatted size with units */
  displaySize?: Maybe<MediaDisplaySize>;
  /** Enabled */
  enabled: Scalars['Boolean'];
  fileKey?: Maybe<Scalars['String']>;
  fileType: MediaFileType;
  filename?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  /** Media ID */
  id: Scalars['ID'];
  isLocked?: Maybe<Scalars['Boolean']>;
  /** Is Primary */
  isPrimary: Scalars['Boolean'];
  length?: Maybe<Scalars['Float']>;
  /** Media ID */
  mediaId: Scalars['Int'];
  mediaParentId?: Maybe<Scalars['Float']>;
  mediaStatus: MediaStatus;
  mediaStorageStatus: MediaStorageStatus;
  mediaType?: Maybe<MediaType>;
  moderationStatus: MediaModerationStatus;
  /** Customized media name */
  name?: Maybe<Scalars['String']>;
  /** Order */
  orderOfAppearance: Scalars['Int'];
  profiles?: Maybe<Array<ProfileMedia>>;
  purpose: MediaPurpose;
  /** @deprecated use url field instead */
  resizedPaths: Array<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Media>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  transformation?: Maybe<MediaTransformation>;
  transformations: Array<Media>;
  updated?: Maybe<Scalars['DateTime']>;
  /** @deprecated use url field instead */
  uploadPath?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** Submission Media */
export type SubmissionMediaInput = {
  /** Is media hidden on profile? */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** Is media primary? (Primary photo, video reel or audio reel) */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** Media Id */
  mediaId: Scalars['ID'];
  /** Customize media file name */
  name?: InputMaybe<Scalars['String']>;
  /** Media order of appearance */
  order?: InputMaybe<Scalars['Int']>;
};

/** Paginated result with [Submission] objects */
export type SubmissionPaginatedResult = {
  facets?: Maybe<Array<Facet>>;
  /** Last key */
  lastKey?: Maybe<Scalars['String']>;
  /** List of Submission objects */
  objects: Array<Submission>;
  /** Current page */
  page: Scalars['Int'];
  /** Total count of rows */
  totalCount: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type SubmissionPick = {
  created: Scalars['DateTime'];
  /** ID of Presentation Group Talent Pick */
  id: Scalars['Int'];
  userName: Scalars['String'];
};

/** Submission Profile Output */
export type SubmissionProfile = {
  /** Profile Information */
  profile?: Maybe<Profile>;
  /** Role Name */
  roleName: Scalars['String'];
};

/** Submission Quick Creation Input type */
export type SubmissionQuickAddInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  profileTypeCode: Scalars['String'];
  roleId: Scalars['Int'];
};

export type SubmissionRequestSearchInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  notInRequest?: InputMaybe<Array<Scalars['Boolean']>>;
  responseStatuses?: InputMaybe<Array<RequestStatusUnion>>;
};

/** Submission Response */
export type SubmissionResponse = {
  chatTopic?: Maybe<ChatTopic>;
  /** Chat Topic Key */
  chatTopicKey: Scalars['String'];
  masterId: Scalars['String'];
  /** Request Name */
  name?: Maybe<Scalars['String']>;
  /** Request Type */
  requestType: Scalars['String'];
  /** Response Id */
  responseId: Scalars['String'];
  /** Schedule Id */
  scheduleId?: Maybe<Scalars['Int']>;
  /** Status */
  status: Scalars['String'];
  /** Submission Id */
  submissionId?: Maybe<Scalars['Int']>;
};

/** Submissions Responses */
export type SubmissionResponses = {
  /** Submission Responses */
  responses?: Maybe<Array<SubmissionResponse>>;
  /** Submission Id */
  submissionId: Scalars['Int'];
};

export type SubmissionScheduleRequestSearchInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  notInRequest?: InputMaybe<Array<Scalars['Boolean']>>;
  responseStatuses?: InputMaybe<Array<ScheduleInviteRequestStatus>>;
};

/** Submission Schedule Slot Details */
export type SubmissionScheduleSlot = {
  /** Schedule EndTime */
  endTime?: Maybe<Scalars['DateTime']>;
  /** Schedule Date */
  scheduleDate?: Maybe<Scalars['DateTime']>;
  /** Schedule Id */
  scheduleId: Scalars['ID'];
  /** Calendar Slot EndTime */
  slotEndTime?: Maybe<Scalars['DateTime']>;
  /** Calendar Slot StartTime */
  slotStartTime?: Maybe<Scalars['DateTime']>;
  /** Schedule StartTime */
  startTime?: Maybe<Scalars['DateTime']>;
  /** Submission Id */
  submissionId: Scalars['ID'];
  /** Time Zone */
  timeZone?: Maybe<TimeZone>;
  /** Timezone Id */
  timeZoneId: Scalars['ID'];
};

/** Submission Search input */
export type SubmissionSearchInput = {
  /** Is submission scheduled for audition */
  auditionScheduled?: InputMaybe<Scalars['Boolean']>;
  /** Is submission scheduled for callback */
  callbackScheduled?: InputMaybe<Scalars['Boolean']>;
  /** List of Submission IDs to be excluded from the search results */
  excludeSubmissionsIds?: InputMaybe<Array<Scalars['Int']>>;
  hasComments?: InputMaybe<Scalars['Boolean']>;
  hasPicks?: InputMaybe<Scalars['Boolean']>;
  /** Profile Search Input */
  profileSearchInput?: InputMaybe<ProfileSearchInput>;
  /** List of Role IDs */
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Filter by Schedule */
  scheduleIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Is submission scheduled */
  scheduled?: InputMaybe<Scalars['Boolean']>;
  /** Filter by Audition Session */
  sessionIds?: InputMaybe<Array<Scalars['Int']>>;
  stageName?: InputMaybe<Scalars['String']>;
  /** List of Submission IDs */
  submissionIds?: InputMaybe<Array<Scalars['Int']>>;
  /** List of Submission Status Codes */
  submissionStatusCodes?: InputMaybe<Array<Scalars['String']>>;
  /** List of Submission Status IDs */
  submissionStatusIds?: InputMaybe<Array<Scalars['Int']>>;
  /** List of Submission Tile Schema Codes */
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
  /** List of Submission Tile Schema IDs */
  tileSchemeValueIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Enum of an available columns for sorting */
export enum SubmissionSearchSortColumn {
  LastName = 'LAST_NAME',
  Random = 'RANDOM',
  Randomize = 'RANDOMIZE',
  Role = 'ROLE',
  SelectionTag = 'SELECTION_TAG',
  StageName = 'STAGE_NAME',
  SubmittedDate = 'SUBMITTED_DATE',
}

export type SubmissionSearchSortOptionsInput = {
  /** Field name by which should be sorted */
  fieldName: SubmissionSearchSortColumn;
  /** Sort order value(asc or desc). Default value: asc */
  sortOrder?: InputMaybe<SortOrderEnum>;
};

/** Submission Selection Result object */
export type SubmissionSelectionResult = {
  /** Other Submissions of artist in the same project */
  otherSubmissions?: Maybe<Array<Submission>>;
  /** Current Submission updated */
  updatedSubmission: Submission;
};

/** Submission Source Type */
export type SubmissionSourceType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** SubmissionStatus object */
export type SubmissionStatus = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum SubmissionStatusCodeEnum {
  Booked = 'BOOKED',
  Draft = 'DRAFT',
  OnAvail = 'ON_AVAIL',
  Reviewed = 'REVIEWED',
  Selected = 'SELECTED',
  ToReview = 'TO_REVIEW',
  UnderConsideration = 'UNDER_CONSIDERATION',
  Unsubmitted = 'UNSUBMITTED',
}

export enum SubmissionStatusEnum {
  Booked = 'BOOKED',
  Draft = 'DRAFT',
  OnAvail = 'ON_AVAIL',
  Reviewed = 'REVIEWED',
  Selected = 'SELECTED',
  ToReview = 'TO_REVIEW',
  UnderConsideration = 'UNDER_CONSIDERATION',
  Unsubmitted = 'UNSUBMITTED',
}

export type SubmissionTrend = {
  date?: Maybe<Scalars['DateTime']>;
  selectionCount: Scalars['Int'];
  submissionCount: Scalars['Int'];
};

export type SubmissionTrendsInput = {
  interval?: InputMaybe<IntervalEnum>;
  timeFrame?: InputMaybe<TimeFrameEnum>;
};

export type SubmissionTrendsNoAccessError = IError & {
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type SubmissionTrendsResult = SubmissionTrendsNoAccessError | SubmissionTrendsSuccess;

export type SubmissionTrendsSuccess = {
  data: Array<SubmissionTrend>;
};

/** Input type for updating submission */
export type SubmissionUpdateInput = {
  /** Profile working location id */
  dmaId?: InputMaybe<Scalars['Int']>;
  /** Represents that profile is overscale or not */
  isOverscale?: InputMaybe<Scalars['Boolean']>;
  /** Note for submission */
  note?: InputMaybe<Scalars['String']>;
  /** Over scale note */
  overscaleNote?: InputMaybe<Scalars['String']>;
  /** Profile ID */
  profileId?: InputMaybe<Scalars['Int']>;
  /** Request media(s) */
  requestMedia?: InputMaybe<Array<SubmissionMediaInput>>;
  /** Submission status CODE */
  statusCode?: InputMaybe<Scalars['String']>;
  /** Submission ID */
  submissionId: Scalars['BigInt'];
  /** Submission profile media(s) */
  submissionProfileMedia?: InputMaybe<Array<SubmissionMediaInput>>;
  /** Tile Schema Value CODE */
  tileSchemeValueCode?: InputMaybe<Scalars['String']>;
};

/** Input type for updating submission */
export type SubmissionUpdateSelectionInput = {
  /** Submission status CODE */
  statusCode: Scalars['String'];
  /** Submission ID */
  submissionId: Scalars['BigInt'];
  /** Tile Schema Value Code */
  tileSchemeValueCode?: InputMaybe<Scalars['String']>;
};

/** Submission data for Workflow */
export type SubmissionWorkflow = {
  /** Talent's custom cover */
  cover?: Maybe<Media>;
  creatorAccountId: Scalars['Int'];
  id: Scalars['BigInt'];
  isCreatedByCD?: Maybe<Scalars['Boolean']>;
  isCustomized: Scalars['Boolean'];
  profile?: Maybe<Profile>;
  project: ProjectWorkflow;
  role: RoleWorkflow;
};

/** Submission data for Workflow. Short version */
export type SubmissionWorkflowShort = {
  agencyId?: Maybe<Scalars['Int']>;
  creatorAccountId: Scalars['Int'];
  id: Scalars['BigInt'];
  profileAccountId?: Maybe<Scalars['Int']>;
  profileArtistId?: Maybe<Scalars['Int']>;
  profileId: Scalars['Int'];
  projectId: Scalars['Int'];
  roleId: Scalars['Int'];
};

export type Subscription = {
  billingInfoId?: Maybe<Scalars['String']>;
  canRenew: Scalars['Boolean'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  /** Null unless subscription is paused or will pause at the end of the current billing period. */
  pausedAt?: Maybe<Scalars['DateTime']>;
  plan: BillingPlan;
  /** Null unless subscription is paused and will be resumed at the end of the current billing period. */
  resumeAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  transaction?: Maybe<Scalars['String']>;
  trialEndsAt?: Maybe<Scalars['DateTime']>;
  /**
   * The number of trial days offered to the user when they signed up for this
   * subscription. This can be different from the plan trialLengthDays.
   */
  trialLengthDays?: Maybe<Scalars['Int']>;
};

/** Subscription Invoice Model */
export type SubscriptionInvoice = {
  coupon?: Maybe<Coupon>;
  creditsApplied: Scalars['Float'];
  currency: Currency;
  discount: Scalars['Float'];
  email: Scalars['String'];
  invoiceId?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<PaymentDetails>;
  subscription: Subscription;
  total: Scalars['Float'];
};

export type SubscriptionMediaEntitlement = {
  count: Scalars['Int'];
  isUnlimited: Scalars['Boolean'];
  mediaType: Scalars['String'];
};

/** Subscription */
export type SubscriptionModel = {
  billingInfoId?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<PlanModel>;
  status: Scalars['String'];
  transaction?: Maybe<Scalars['String']>;
  trialEndsAt?: Maybe<Scalars['DateTime']>;
  trialLengthDays?: Maybe<Scalars['Int']>;
};

/** Subscription Renewal Model */
export type SubscriptionRenewal = {
  cardType: Scalars['String'];
  creditsApplied: Scalars['Float'];
  currency: Currency;
  discount: Scalars['Float'];
  email: Scalars['String'];
  invoiceId?: Maybe<Scalars['String']>;
  lastFour: Scalars['String'];
  paymentType?: Maybe<Scalars['String']>;
  subscription: BillingItemModel;
  total: Scalars['Float'];
};

export enum SubscriptionStatusEnum {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Future = 'FUTURE',
  Paused = 'PAUSED',
  Pending = 'PENDING',
}

/** Success Response */
export type SuccessResponse = {
  success: Scalars['Boolean'];
};

/** System role */
export type SystemRole = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Specifies, is it internal role */
  internal: Scalars['Boolean'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum SystemRoleCode {
  Agent = 'AGENT',
  Api = 'API',
  CastingDirector = 'CASTING_DIRECTOR',
  Creative = 'CREATIVE',
  LegacyUser = 'LEGACY_USER',
  Manager = 'MANAGER',
  ProjectCreator = 'PROJECT_CREATOR',
  RootUser = 'ROOT_USER',
  SharedProjectUser = 'SHARED_PROJECT_USER',
  Studio = 'STUDIO',
  Talent = 'TALENT',
}

export enum SystemRoleEnum {
  Agent = 'Agent',
  Api = 'Api',
  CastingDirector = 'CastingDirector',
  Creative = 'Creative',
  LegacyUser = 'LegacyUser',
  Manager = 'Manager',
  ProjectCreator = 'ProjectCreator',
  RootUser = 'RootUser',
  SharedProjectUser = 'SharedProjectUser',
  Studio = 'Studio',
  Talent = 'Talent',
}

/** Tshirt Measurement object */
export type TShirtSize = {
  /** Gender */
  category: BasicSetting;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Tag object */
export type Tag = {
  /** child objects */
  childObjects: Array<Tag>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Tag>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Contex object for talent access profile */
export type TalentAccessProfileContext = {
  buttonText: Scalars['String'];
  buttonURL: Scalars['String'];
  expiresDays: Scalars['Float'];
  manageEmailSettingsUrl: Scalars['String'];
  messageFromRepresentative: Scalars['String'];
  organizationDivision: Scalars['String'];
  organizationLogo: Scalars['String'];
  profile: TalentAccessProfileDetailsContext;
};

/** Talent Access Profile Context input */
export type TalentAccessProfileContextInput = {
  accessToken: Scalars['String'];
  email: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Int'];
  stageName: Scalars['String'];
};

export type TalentAccessProfileContextNotificationContextAndRecipient = {
  context: TalentAccessProfileContext;
  to?: Maybe<Scalars['String']>;
  toAccountId?: Maybe<Scalars['Int']>;
  toOrgId?: Maybe<Scalars['Int']>;
  toProfileId?: Maybe<Scalars['Int']>;
};

/** Context object for talent access profile object */
export type TalentAccessProfileDetailsContext = {
  name: Scalars['String'];
};

export type TalentPickPublic = {
  id: Scalars['String'];
  userName: Scalars['String'];
};

/** Talent Profile Union */
export type TalentProfileUnion = {
  /** child objects */
  childObjects: Array<TalentUnion>;
  /** code */
  code: Scalars['String'];
  /** country */
  country?: Maybe<Country>;
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<TalentUnion>;
  /** Represents project union for all true values */
  projectEnabled: Scalars['Boolean'];
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Represents talent union for all true values */
  talentEnabled: Scalars['Boolean'];
  /** Talent Union membership Id (usually number) */
  talentMembershipId?: Maybe<Scalars['String']>;
};

export type TalentScoutModel = {
  createdAt: Scalars['DateTime'];
  /** Account of creator */
  createdBy?: Maybe<Account>;
  id: Scalars['String'];
  message: Scalars['String'];
  profile?: Maybe<Profile>;
};

/** Talent scout history list */
export type TalentScoutPageListResult = {
  /** List of talent scout */
  data: Array<TalentScoutModel>;
  /** Current Page */
  page: Scalars['Int'];
  /** Total records count */
  totalCount: Scalars['Int'];
  /** Total Pages */
  totalPages: Scalars['Int'];
};

export enum TalentStatusEnum {
  SelectedTalent = 'SelectedTalent',
  SubmittedTalent = 'SubmittedTalent',
}

/** Talent Submission Item Payload */
export type TalentSubmissionItemModel = {
  ageRange: Scalars['String'];
  castingDirector?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  mediaRequest: Scalars['Boolean'];
  projectName: Scalars['String'];
  roleName: Scalars['String'];
  status: Scalars['String'];
  submissionDate: Scalars['DateTime'];
};

/** Talent Submission Report */
export type TalentSubmissionModel = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  csvFileName?: Maybe<Scalars['String']>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId?: Maybe<Scalars['Int']>;
  payload?: Maybe<TalentSubmissionPayloadModel>;
  profileId: Scalars['Int'];
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Talent Submission Report Payload */
export type TalentSubmissionPayloadModel = {
  castingCompanyInfo?: Maybe<CompanyInfoModel>;
  profile?: Maybe<TalentSubmissionProfileModel>;
  submissions?: Maybe<Array<TalentSubmissionItemModel>>;
};

/** Talent Submission Profile Model */
export type TalentSubmissionProfileModel = {
  created?: Maybe<Scalars['DateTime']>;
  stageName?: Maybe<Scalars['String']>;
};

/** Talent Submission Report Input Arguments */
export type TalentSubmissionReportCreateInput = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  profileId: Scalars['Int'];
};

/** Talent Union */
export type TalentUnion = {
  /** child objects */
  childObjects: Array<TalentUnion>;
  /** code */
  code: Scalars['String'];
  /** country */
  country?: Maybe<Country>;
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<TalentUnion>;
  /** Represents project union for all true values */
  projectEnabled: Scalars['Boolean'];
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
  /** Represents talent union for all true values */
  talentEnabled: Scalars['Boolean'];
};

export enum TalentUnionEnum {
  Actra = 'ACTRA',
  Aea = 'AEA',
  Antc = 'ANTC',
  BayAreaTheatre = 'BAY_AREA_THEATRE',
  BusinessTheatreEvents = 'BUSINESS_THEATRE_EVENTS',
  Cabaret = 'CABARET',
  Caea = 'CAEA',
  Casino = 'CASINO',
  Cat = 'CAT',
  Commercial = 'COMMERCIAL',
  CorporateEducationalNonBroadcast = 'CORPORATE_EDUCATIONAL_NON_BROADCAST',
  Corst = 'CORST',
  Cost = 'COST',
  DinnerTheatre = 'DINNER_THEATRE',
  DinnerTheatreArtist = 'DINNER_THEATRE_ARTIST',
  Disneyworld = 'DISNEYWORLD',
  Eligible = 'ELIGIBLE',
  Equity = 'EQUITY',
  Ficore = 'FICORE',
  GuestArtist = 'GUEST_ARTIST',
  Hat = 'HAT',
  Loa = 'LOA',
  LoaNyc = 'LOA_NYC',
  LortNonRep = 'LORT_NON_REP',
  LortRep = 'LORT_REP',
  LosAngelesTransitional_99SeatTheatreCode = 'LOS_ANGELES_TRANSITIONAL_99_SEAT_THEATRE_CODE',
  LowBudgetFilm = 'LOW_BUDGET_FILM',
  Meaa = 'MEAA',
  Member = 'MEMBER',
  MidsizeTheatres = 'MIDSIZE_THEATRES',
  Mini = 'MINI',
  ModifiedBat = 'MODIFIED_BAT',
  ModifiedLowBudgetFilm = 'MODIFIED_LOW_BUDGET_FILM',
  Msua = 'MSUA',
  MusicVideo = 'MUSIC_VIDEO',
  Neat = 'NEAT',
  NewMedia = 'NEW_MEDIA',
  Nola = 'NOLA',
  NonUnion = 'NON_UNION',
  NoUnionAffiliation = 'NO_UNION_AFFILIATION',
  Oat = 'OAT',
  OffBroadway = 'OFF_BROADWAY',
  Other = 'OTHER',
  OutdoorDrama = 'OUTDOOR_DRAMA',
  ProductionDisney = 'PRODUCTION_DISNEY',
  ProductionLeague = 'PRODUCTION_LEAGUE',
  Rmta = 'RMTA',
  SagAftra = 'SAG_AFTRA',
  ShortEngagementTouringAgreement = 'SHORT_ENGAGEMENT_TOURING_AGREEMENT',
  ShortFilm = 'SHORT_FILM',
  ShowcaseNy = 'SHOWCASE_NY',
  SoundRecordings = 'SOUND_RECORDINGS',
  SpecialAgreement = 'SPECIAL_AGREEMENT',
  SpecialAppearance = 'SPECIAL_APPEARANCE',
  SpecialProductionCentral = 'SPECIAL_PRODUCTION_CENTRAL',
  SpecialProductionEastern = 'SPECIAL_PRODUCTION_EASTERN',
  SpecialProductionWestern = 'SPECIAL_PRODUCTION_WESTERN',
  Spt = 'SPT',
  StudentFilm = 'STUDENT_FILM',
  Television = 'TELEVISION',
  TheatricalFilm = 'THEATRICAL_FILM',
  Transition = 'TRANSITION',
  Tya = 'TYA',
  UbcpActra = 'UBCP_ACTRA',
  Uda = 'UDA',
  UltraLowBudgetFilm = 'ULTRA_LOW_BUDGET_FILM',
  Urta = 'URTA',
  Wclo = 'WCLO',
  Workshop = 'WORKSHOP',
}

/** Input type for talent union */
export type TalentUnionInput = {
  unionId: Scalars['ID'];
  unionMemberId?: InputMaybe<Scalars['String']>;
};

export type TalentUnionModel = {
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  talentMembershipId?: Maybe<Scalars['String']>;
};

/** Tattoo object */
export type Tattoo = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for search by tattoo attributes */
export type TattooInput = {
  /** tattoo id list */
  tattooId?: InputMaybe<Array<Scalars['ID']>>;
  /** tattoo description */
  tattooNote?: InputMaybe<Scalars['String']>;
};

export enum ThumbnailType {
  Headshot = 'headshot',
  Snapshot = 'snapshot',
}

export enum TileSchemaEnum {
  Default = 'Default',
  PresentationPick = 'PresentationPick',
}

/** Publication Setting object */
export type TileSchemaValue = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum TileSchemaValueEnum {
  Cb = 'CB',
  PackagePick_1 = 'PACKAGE_PICK_1',
  PresentationPick_1 = 'PRESENTATION_PICK_1',
  '1' = '_1',
  '2' = '_2',
  '3' = '_3',
  '4' = '_4',
  '5' = '_5',
  '6' = '_6',
}

export enum TileSchemeValueEnum {
  Cb = 'CB',
  PackagePick_1 = 'PACKAGE_PICK_1',
  PresentationPick_1 = 'PRESENTATION_PICK_1',
  '1' = '_1',
  '2' = '_2',
  '3' = '_3',
  '4' = '_4',
  '5' = '_5',
  '6' = '_6',
}

/** Time frame */
export enum TimeFrameEnum {
  All = 'All',
  NinetyDays = 'NinetyDays',
  SixMonths = 'SixMonths',
  ThirtyDays = 'ThirtyDays',
  Year = 'Year',
}

/** Time Zone object */
export type TimeZone = {
  abbreviation?: Maybe<Scalars['String']>;
  /** code */
  code: Scalars['String'];
  codeBrowser: Scalars['String'];
  daylightSavingsCode?: Maybe<Scalars['String']>;
  dstOffset: Scalars['Float'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  gmtOffset: Scalars['Float'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name?: Maybe<Scalars['String']>;
  rawOffset: Scalars['Float'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
  standardName: Scalars['String'];
};

/** Account info */
export type UnclaimedAccount = {
  accountOrganization?: Maybe<Array<OrganizationInfo>>;
  accountOrganizationDivisionsAccess: Array<Organization>;
  accountOrganizationDivisionsAccessCount?: Maybe<Scalars['Float']>;
  /** Account status object */
  accountStatus?: Maybe<AccountStatus>;
  accountStatusId: Scalars['Int'];
  accountSystemRoles: Array<SystemRole>;
  accountUrlTypes?: Maybe<Array<AccountUrlType>>;
  artists: Array<Artist>;
  /** Account billing info */
  billingInfo?: Maybe<AccountBillingInfo>;
  /** Country object */
  country?: Maybe<Country>;
  countryId: Scalars['Int'];
  created: Scalars['DateTime'];
  /** Divisions that user belongs to */
  divisions?: Maybe<Array<Organization>>;
  email: Scalars['String'];
  /** Holds the generated token value, till it gets expired or the email update verification done successfully. */
  emailResetToken?: Maybe<Scalars['String']>;
  /** Holds the value to reset the original account email till it gets verified */
  emailToReset?: Maybe<Scalars['String']>;
  /** Returns organization features */
  features?: Maybe<Array<Feature>>;
  firstName: Scalars['String'];
  former?: Maybe<Scalars['Boolean']>;
  /** Primary ID */
  id: Scalars['Int'];
  ipcInfo: IpcInfo;
  /** Checks if user is in role Independent Project Creator */
  isAccountIPC: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  /** Checks if user is in role Casting Director */
  isCCD: Scalars['Boolean'];
  /** Checks if user is in role Independent Project Creator */
  isIPC: Scalars['Boolean'];
  /** Checks if the current/switched user is PC or not */
  isInPCContext: Scalars['Boolean'];
  /** Language Info object */
  language?: Maybe<LanguageInfo>;
  languageLocaleId?: Maybe<Scalars['Int']>;
  lastActiveSession?: Maybe<Scalars['DateTime']>;
  lastArtistId?: Maybe<Scalars['Int']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  /** Legacy email */
  legacyEmail?: Maybe<Scalars['String']>;
  /** Legacy instance */
  legacyInstance?: Maybe<Scalars['String']>;
  /** Legacy login */
  legacyLogin?: Maybe<Scalars['String']>;
  legacyProfileStatus?: Maybe<ProfileStatus>;
  linkedAccountDivisionCount?: Maybe<Scalars['Float']>;
  linkedAccountOrganization?: Maybe<Array<Organization>>;
  linkedOrganization?: Maybe<Scalars['Float']>;
  linkedToAccountId?: Maybe<Scalars['Int']>;
  loginAttemptCount?: Maybe<Scalars['Int']>;
  loginAttemptFailedAt?: Maybe<Scalars['DateTime']>;
  notificationSubscriptions: Array<NotificationTypeNotificationSubscription>;
  organizationDivisionsCount?: Maybe<Scalars['Float']>;
  /** Organizations that user belongs to */
  organizations?: Maybe<Array<Organization>>;
  passwordHash?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  preferences: AccountPreferences;
  profileId?: Maybe<Scalars['Float']>;
  /** Roster invitation token to hold the invitation token till logged in user will not create the profile using it */
  rosterInvitationToken?: Maybe<Scalars['String']>;
  /** @deprecated Use artist active subscription */
  subscription?: Maybe<ActiveSubscriptionModel>;
  /** system roles */
  systemRoles?: Maybe<Array<Scalars['String']>>;
  systemRolesIds?: Maybe<Array<Scalars['Float']>>;
  termsOfUse: Scalars['Boolean'];
  /** Time zone object */
  timeZone?: Maybe<TimeZone>;
  /** Updated at Date */
  updated?: Maybe<Scalars['DateTime']>;
};

/** Account info */
export type UnclaimedAccountAccountOrganizationArgs = {
  systemRoleCode?: InputMaybe<Array<Scalars['String']>>;
};

/** Account info */
export type UnclaimedAccountOrganizationsArgs = {
  systemRoleCode?: InputMaybe<Array<Scalars['String']>>;
};

export type UnderBust = {
  /** Imperial Feet Inches */
  IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  cm: Scalars['Int'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
  /** Inches rounded */
  inchRounded: Scalars['String'];
  /** Show in imperial UI */
  showInImperialUI: Scalars['Boolean'];
};

/** Unique Attributes */
export type UniqueAttribute = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Unit System object */
export type UnitSystem = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum UnitSystemEnum {
  Imperial = 'IMPERIAL',
  Metric = 'METRIC',
}

export type UnlockMediaInput = {
  mediaIds: Array<Scalars['ID']>;
};

export type UnlockMediaResponse = {
  media: Array<Media>;
  mediaExternalStatusIds: Array<Scalars['Int']>;
  success: Scalars['Boolean'];
};

/** Updates a user account based on given input values */
export type UpdateAccountInput = {
  accountId?: InputMaybe<Scalars['Int']>;
  countryId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  /** Holds the generated token value, till it gets expired or the email update verification done successfully. */
  emailResetToken?: InputMaybe<Scalars['String']>;
  /** Holds email reset token creation date and time till token gets expired or the email update verification done successfully. */
  emailResetTokenCreated?: InputMaybe<Scalars['DateTime']>;
  /** Used for holding the new email value going to be updated to the actual account email */
  emailToReset?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  languageLocaleId?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  loginAttemptCount?: InputMaybe<Scalars['Int']>;
  loginAttemptFailedAt?: InputMaybe<Scalars['DateTime']>;
  phone?: InputMaybe<Scalars['String']>;
  rosterInvitationToken?: InputMaybe<Scalars['String']>;
  timezoneId?: InputMaybe<Scalars['Int']>;
};

export type UpdateBillingInfoInput = {
  backupPaymentMethod?: InputMaybe<Scalars['Boolean']>;
  billingInfoId: Scalars['String'];
  primaryPaymentMethod?: InputMaybe<Scalars['Boolean']>;
  threeDSecureActionResultTokenId?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
};

export type UpdateDueDateInput = {
  dueDate?: InputMaybe<Scalars['DateTime']>;
  dueDateTimeZoneId?: InputMaybe<Scalars['Int']>;
};

/** Update Legacy Profile Result Object */
export type UpdateLegacyResult = {
  /** Update Legacy Profile Status */
  status?: Maybe<Scalars['Int']>;
  /** New JWT Token For Redirect */
  token?: Maybe<AuthTokenItem>;
};

/** Update List Name Input */
export type UpdateListNameInput = {
  /** List Id */
  listId: Scalars['Float'];
  /** List Name */
  listName: Scalars['String'];
};

export type UpdateMediaInput = {
  length?: InputMaybe<Scalars['Int']>;
  mediaId: Scalars['Int'];
  mediaModerationStatusCode?: InputMaybe<Scalars['String']>;
  mediaStatusId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
};

/** Update Note Input Type */
export type UpdateNoteInput = {
  /** Text of the note */
  comment?: InputMaybe<Scalars['String']>;
  /** Entity ID that note will be assigned to */
  entityId?: InputMaybe<Scalars['Int']>;
  /** Entity Type CODE */
  entityTypeCode?: InputMaybe<Scalars['String']>;
  /** Note ID which should be updated */
  noteId: Scalars['Int'];
  /** Note Modifier CODE */
  noteModifierCode?: InputMaybe<Scalars['String']>;
  /** Note Type CODE */
  noteTypeCode?: InputMaybe<Scalars['String']>;
};

/** Input type for Organization update */
export type UpdateOrganizationInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Array<AddressInput>>;
  contactList?: InputMaybe<Array<ContactInput>>;
  countryId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  deletedContactIds?: InputMaybe<Array<Scalars['Int']>>;
  diversityDisclaimer?: InputMaybe<Scalars['String']>;
  divisionFeaturesList?: InputMaybe<Array<FeatureInput>>;
  /** Devisions could have multiple locations/dma's */
  dmaIds?: InputMaybe<Array<Scalars['Int']>>;
  featureIds?: InputMaybe<Array<Scalars['Int']>>;
  logoMediaId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Int'];
  organizationParentId?: InputMaybe<Scalars['Int']>;
  organizationStatusCode?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profileListId?: InputMaybe<Scalars['Int']>;
  projectCastingAssistant?: InputMaybe<Scalars['String']>;
  projectCastingAssociate?: InputMaybe<Scalars['String']>;
  projectCastingDirector?: InputMaybe<Scalars['String']>;
  representationAgeCategoryIds?: InputMaybe<Array<Scalars['Int']>>;
  representationCategoryIds?: InputMaybe<Array<Scalars['Int']>>;
  representationTypeId?: InputMaybe<Scalars['Int']>;
  scheduleAddressNote?: InputMaybe<Scalars['String']>;
  systemRoleId?: InputMaybe<Scalars['Int']>;
  tileSchemeId?: InputMaybe<Scalars['Int']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

/** Update Organization Result Object */
export type UpdateOrganizationResult = {
  /** Update Organization Status */
  status?: Maybe<Scalars['Int']>;
  /** New JWT Token For Redirect */
  token?: Maybe<AuthTokenItem>;
};

/** Determined location Address Input for creating IPC project */
export type UpdatePcProjectDeterminedAddressInput = {
  /** City */
  city?: InputMaybe<Scalars['ID']>;
  /** Country CODE */
  countryCode?: InputMaybe<Scalars['ID']>;
  /** Location notes */
  locationNotes?: InputMaybe<Scalars['ID']>;
};

/** Input type for PC Project update */
export type UpdatePcProjectInput = {
  adAgency?: InputMaybe<Scalars['String']>;
  additionalProvisions?: InputMaybe<Scalars['String']>;
  /** Uploaded media ids */
  attachmentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Project sides visibility CODE */
  attachmentVisibilityCode?: InputMaybe<Scalars['String']>;
  auditionTypeId?: InputMaybe<Scalars['ID']>;
  canTalentSubmitSelfTape?: InputMaybe<Scalars['Boolean']>;
  castingAssistant?: InputMaybe<Scalars['String']>;
  castingAssociate?: InputMaybe<Scalars['String']>;
  castingDirector?: InputMaybe<Scalars['String']>;
  conflictIds?: InputMaybe<Array<Scalars['ID']>>;
  conflictNote?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactInfoPermissions?: InputMaybe<Scalars['Boolean']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  /** Contract Status Code */
  contractStatusCode?: InputMaybe<Scalars['String']>;
  determinedLocationInput?: InputMaybe<UpdatePcProjectDeterminedAddressInput>;
  director?: InputMaybe<Scalars['String']>;
  executiveProducer?: InputMaybe<Scalars['String']>;
  fieldRestrictionSettings?: InputMaybe<Array<ProjectFieldRestrictionInput>>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  hideProjectRate?: InputMaybe<Scalars['Boolean']>;
  internalName?: InputMaybe<Scalars['String']>;
  isAuditionInPerson?: InputMaybe<Scalars['Boolean']>;
  isSpecificLocation?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  network?: InputMaybe<Scalars['String']>;
  newSeriesName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  photographer?: InputMaybe<Scalars['String']>;
  producer?: InputMaybe<Scalars['String']>;
  productionCompany?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  projectOrganization?: InputMaybe<Array<ProjectOrganizationInput>>;
  projectParentId?: InputMaybe<Scalars['ID']>;
  projectSeriesEpisode?: InputMaybe<Scalars['Int']>;
  projectSeriesId?: InputMaybe<Scalars['ID']>;
  projectSeriesSeason?: InputMaybe<Scalars['Int']>;
  projectStatusCode?: InputMaybe<Scalars['String']>;
  projectStatusId?: InputMaybe<Scalars['ID']>;
  projectTypeId?: InputMaybe<Scalars['ID']>;
  projectUnionId?: InputMaybe<Scalars['ID']>;
  rate?: InputMaybe<Scalars['String']>;
  /** IPC project release location codes */
  releaseLocations?: InputMaybe<Array<Scalars['String']>>;
  requestBrief?: InputMaybe<Scalars['Boolean']>;
  requestBriefInstruction?: InputMaybe<Scalars['String']>;
  roleDefaults?: InputMaybe<RoleDefaultsInput>;
  specificLocationInput?: InputMaybe<UpdatePcProjectSpecificAddressInput>;
  studio?: InputMaybe<Scalars['String']>;
  submissionsDueBy?: InputMaybe<Scalars['DateTime']>;
  synopsis?: InputMaybe<Scalars['String']>;
  tileSchemeId?: InputMaybe<Scalars['ID']>;
  unionContractId?: InputMaybe<Scalars['String']>;
  usage?: InputMaybe<Scalars['String']>;
  writer?: InputMaybe<Scalars['String']>;
};

/** Specific location Address Input for creating IPC project */
export type UpdatePcProjectSpecificAddressInput = {
  /** Address Line 1 */
  addressLine1?: InputMaybe<Scalars['ID']>;
  /** Address Line 2 */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['ID']>;
  /** Country CODE */
  countryCode?: InputMaybe<Scalars['ID']>;
  /** Location notes */
  locationNotes?: InputMaybe<Scalars['String']>;
  /** ZIP/Postal Code */
  postalCode?: InputMaybe<Scalars['ID']>;
  /** State / Province Id */
  stateId?: InputMaybe<Scalars['Int']>;
};

/** Update profile conflict input */
export type UpdateProfileConflictInput = {
  conflictId?: InputMaybe<Scalars['Int']>;
  expiration?: InputMaybe<Scalars['DateTime']>;
  note?: InputMaybe<Scalars['String']>;
  profileConflictId: Scalars['Int'];
};

/** Input type for move profile to artist */
export type UpdateProfileMoveToArtistInput = {
  /** artist id */
  artistId: Scalars['ID'];
  /** Profile id */
  profileId: Scalars['ID'];
};

/** Input type for Update Profile Vehicle */
export type UpdateProfileVehicleInput = {
  /** Vehicle name */
  name?: InputMaybe<Scalars['String']>;
  /** Vehicle type make id */
  vehicleTypeMakeId: Scalars['Float'];
  /** Vehicle year */
  year?: InputMaybe<Scalars['Float']>;
};

/** Input type for Project update */
export type UpdateProjectInput = {
  adAgency?: InputMaybe<Scalars['String']>;
  additionalProvisions?: InputMaybe<Scalars['String']>;
  /** Uploaded media ids */
  attachmentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Project sides visibility CODE */
  attachmentVisibilityCode?: InputMaybe<Scalars['String']>;
  auditionTypeId?: InputMaybe<Scalars['ID']>;
  castingAssistant?: InputMaybe<Scalars['String']>;
  castingAssociate?: InputMaybe<Scalars['String']>;
  castingDirector?: InputMaybe<Scalars['String']>;
  conflictIds?: InputMaybe<Array<Scalars['ID']>>;
  conflictNote?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactInfoPermissions?: InputMaybe<Scalars['Boolean']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  /** Contract Status Code */
  contractStatusCode?: InputMaybe<Scalars['String']>;
  director?: InputMaybe<Scalars['String']>;
  executiveProducer?: InputMaybe<Scalars['String']>;
  fieldRestrictionSettings?: InputMaybe<Array<ProjectFieldRestrictionInput>>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  hideProjectRate?: InputMaybe<Scalars['Boolean']>;
  internalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  network?: InputMaybe<Scalars['String']>;
  newSeriesName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  photographer?: InputMaybe<Scalars['String']>;
  producer?: InputMaybe<Scalars['String']>;
  productionCompany?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  projectOrganization?: InputMaybe<Array<ProjectOrganizationInput>>;
  projectParentId?: InputMaybe<Scalars['ID']>;
  projectSeriesEpisode?: InputMaybe<Scalars['Int']>;
  projectSeriesId?: InputMaybe<Scalars['ID']>;
  projectSeriesSeason?: InputMaybe<Scalars['Int']>;
  projectStatusCode?: InputMaybe<Scalars['String']>;
  projectStatusId?: InputMaybe<Scalars['ID']>;
  projectTypeId?: InputMaybe<Scalars['ID']>;
  projectUnionId?: InputMaybe<Scalars['ID']>;
  rate?: InputMaybe<Scalars['String']>;
  /** IPC project release location codes */
  releaseLocations?: InputMaybe<Array<Scalars['String']>>;
  requestBrief?: InputMaybe<Scalars['Boolean']>;
  requestBriefInstruction?: InputMaybe<Scalars['String']>;
  roleDefaults?: InputMaybe<RoleDefaultsInput>;
  studio?: InputMaybe<Scalars['String']>;
  submissionsDueBy?: InputMaybe<Scalars['DateTime']>;
  synopsis?: InputMaybe<Scalars['String']>;
  tileSchemeId?: InputMaybe<Scalars['ID']>;
  unionContractId?: InputMaybe<Scalars['String']>;
  usage?: InputMaybe<Scalars['String']>;
  writer?: InputMaybe<Scalars['String']>;
};

/** Update Representative List Input */
export type UpdateRepresentativeListInput = {
  /** List Id */
  listId: Scalars['Float'];
  /** List of Representative List Ids */
  representativeListIds: Array<Scalars['BigInt']>;
};

/** Input type for role media */
export type UpdateRoleMediaInput = {
  /** Is this media marked for deletion */
  isDeleted: Scalars['Boolean'];
  /** Is this sides media */
  isSides: Scalars['Boolean'];
  /** Media id for creating new media record. */
  mediaId: Scalars['Float'];
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Role-Media relation ID */
  roleMediaId?: InputMaybe<Scalars['Float']>;
};

export type UpdateSavedSearchInput = {
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notificationType?: InputMaybe<Scalars['String']>;
};

/** Update Schedule Input */
export type UpdateScheduleInput = {
  /** Address Id */
  addressId?: InputMaybe<Scalars['Int']>;
  /** Is location hidden */
  isLocationHidden?: InputMaybe<Scalars['Boolean']>;
  /** Schedule Name */
  name?: InputMaybe<Scalars['String']>;
  /** Note */
  note?: InputMaybe<Scalars['String']>;
  /** Project Id */
  projectId?: InputMaybe<Scalars['Int']>;
  /** Schedule Date */
  scheduleDate?: InputMaybe<Scalars['String']>;
  /** Schedule Id */
  scheduleId: Scalars['Int'];
  /** Schedule Type Code */
  scheduleTypeCode?: InputMaybe<Scalars['String']>;
  /** Time Zone CODE */
  timeZoneCode?: InputMaybe<Scalars['String']>;
};

/** Input type for updating submission status */
export type UpdateSubmissionStatusInput = {
  /** ID of the creator */
  createdBy?: InputMaybe<Scalars['Int']>;
  /** New submissionstatus to set */
  newSubmissionStatusCode: Scalars['String'];
  /** Submission ID */
  submissionId: Scalars['BigInt'];
};

/** Url Type object */
export type UrlType = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum UrlTypeEnum {
  Facebook = 'FACEBOOK',
  Imdb = 'IMDB',
  Instagram = 'INSTAGRAM',
  PastWork = 'PAST_WORK',
  Portfolio = 'PORTFOLIO',
  Twitter = 'TWITTER',
  Vimeo = 'VIMEO',
  Website = 'WEBSITE',
  Youtube = 'YOUTUBE',
}

/** Organization Object */
export type UserOrganization = {
  /** Address ID */
  addressId?: Maybe<Scalars['Int']>;
  /** Representation age categories */
  ageCategories?: Maybe<Array<Scalars['Int']>>;
  /** City */
  city?: Maybe<Scalars['String']>;
  /** Company Address Line 1 */
  companyAddressLine1?: Maybe<Scalars['String']>;
  /** Company Address Line 2 */
  companyAddressLine2?: Maybe<Scalars['String']>;
  /** Company Name */
  companyName?: Maybe<Scalars['String']>;
  /** Company Phone */
  companyPhone?: Maybe<Scalars['String']>;
  /** Contact ID */
  contactId?: Maybe<Scalars['Int']>;
  /** Country */
  countryId?: Maybe<Scalars['Int']>;
  /** DMA id */
  dmaId?: Maybe<Scalars['Int']>;
  /** Organization ID */
  id?: Maybe<Scalars['Int']>;
  /** Organization Status */
  organizationStatus?: Maybe<Scalars['String']>;
  /** Parent Organization ID */
  parentOrganizationId?: Maybe<Scalars['Int']>;
  /** Primary Contacts Email */
  primaryContactEmail?: Maybe<Scalars['String']>;
  /** Primary Contacts Name */
  primaryContactName?: Maybe<Scalars['String']>;
  /** Primary Contacts Phone Number */
  primaryContactPhoneNumber?: Maybe<Scalars['String']>;
  /** Primary working location */
  primaryWorkingLocation?: Maybe<Array<Scalars['Int']>>;
  /** Publishing categories */
  publishingCategories?: Maybe<Array<Scalars['Int']>>;
  /** State id */
  stateId?: Maybe<Scalars['Int']>;
  /** Zip */
  zip?: Maybe<Scalars['String']>;
};

export type ValidationError = IError & {
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type ValidationErrorForm = IError & {
  code: Scalars['Int'];
  message: Scalars['String'];
};

/** VehicleMake object */
export type VehicleMake = {
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Input type for vehicle search */
export type VehicleSearchInput = {
  /** Vehicle Make ID */
  makeId?: InputMaybe<Scalars['Int']>;
  /** Vehicle name */
  name?: InputMaybe<Scalars['String']>;
  /** Vehicle type ID */
  typeId?: InputMaybe<Scalars['Int']>;
  /** Year range of vehicle */
  yearRange?: InputMaybe<IdRangeInput>;
};

/** Vehicle Type object */
export type VehicleType = {
  /** child objects */
  childObjects: Array<VehicleType>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<VehicleType>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Vehicle Type Make */
export type VehicleTypeMake = {
  /** Vehicle type make id */
  id: Scalars['Float'];
  /** Vehicle make */
  make: VehicleMake;
  /** Vehicle type */
  type: VehicleType;
};

export type VerifyAccountByCookieInput = {
  /** legacy instance for onboarding */
  instance: Scalars['String'];
  secureCookieToken: Scalars['String'];
};

/** login with B21 or BAU credentials */
export type VerifyAccountInput = {
  /** legacy instance for onboarding */
  instance?: InputMaybe<Scalars['String']>;
  /** accitional account claiming for onboarding */
  migratedAccountID?: InputMaybe<Scalars['Float']>;
  /** B21 email or legacy username */
  name: Scalars['String'];
  /** B21 password or legacy password */
  password: Scalars['String'];
};

/** Virtual Audition Attendee Details */
export type VirtualAuditionAttendee = {
  accountId?: Maybe<Scalars['ID']>;
  artistId?: Maybe<Scalars['ID']>;
  joinUrl: Scalars['String'];
  submissionId?: Maybe<Scalars['BigInt']>;
  type: VirtualAuditionAttendeeType;
};

export enum VirtualAuditionAttendeeType {
  Host = 'host',
  Observer = 'observer',
  Participant = 'participant',
}

export type Waist = {
  /** Imperial Feet Inches */
  IMPERIAL_FEET_INCHES?: Maybe<Scalars['String']>;
  /** Imperial Inches */
  IMPERIAL_INCHES: Scalars['Float'];
  /** Metric Centimeters */
  METRIC_CENTIMETERS: Scalars['Int'];
  /** Centimeters */
  cm: Scalars['Int'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
  /** Inches rounded */
  inchRounded: Scalars['String'];
  /** Show in imperial UI */
  showInImperialUI: Scalars['Boolean'];
};

/** Wardrobe object */
export type Wardrobe = {
  /** child objects */
  childObjects: Array<Wardrobe>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Wardrobe>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

/** General weight object */
export type Weight = {
  /** Imperial pounds */
  IMPERIAL_LBS: Scalars['Int'];
  /** Metric kg */
  METRIC_KG: Scalars['Float'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  kg: Scalars['Float'];
  kgRounded: Scalars['Float'];
  /** pounds */
  lbs: Scalars['Int'];
  /** Show in imperial UI */
  showInMetricUI: Scalars['Boolean'];
};

/** Context object for welcome email. */
export type WelcomeContext = {
  receiverEmail: Scalars['String'];
  receiverName: Scalars['String'];
};

export type WelcomeContextNotificationContextAndRecipient = {
  context: WelcomeContext;
  to?: Maybe<Scalars['String']>;
  toAccountId?: Maybe<Scalars['Int']>;
  toOrgId?: Maybe<Scalars['Int']>;
  toProfileId?: Maybe<Scalars['Int']>;
};

export type WfAddress = {
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  line3?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  /** Postal code */
  postalCode: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

/** Base object */
export type WfBasicSettingModel = {
  /** code */
  code: Scalars['String'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
};

export type WfChatMessage = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  messageBody: Scalars['String'];
  messageType: Scalars['String'];
  ownMessage: Scalars['Boolean'];
  senderAccount: ChatSenderAccount;
  senderAccountId?: Maybe<Scalars['Int']>;
  senderOrgId?: Maybe<Scalars['Int']>;
  topicKey: Scalars['String'];
};

export type WfChatTopic = {
  lastUserMessage?: Maybe<WfChatMessage>;
  topicKey: Scalars['String'];
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

export type WfComplexSetting = {
  childObjects: Array<WfComplexSetting>;
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  parentObject?: Maybe<Scalars['Int']>;
  selectable: Scalars['Boolean'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfContact = {
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  mainContact?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
};

export type WfContinent = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfContractStatus = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfCountry = {
  code: Scalars['String'];
  continent: WfContinent;
  currency?: Maybe<WfCurrency>;
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  regions?: Maybe<Array<WfRegion>>;
  sortOrder?: Maybe<Scalars['Int']>;
  unitSystem: WfUnitSystem;
};

/** Credit Attribute */
export type WfCredit = {
  /** Created at Date */
  created?: Maybe<Scalars['DateTime']>;
  /** Credit Sort ordering */
  creditOrder: Scalars['Int'];
  /** The director of the Project */
  director?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  /** Highlighted */
  highlighted: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Profile Credit Type Id */
  profileCreditTypeId: Scalars['Int'];
  /** Profile ID */
  profileId: Scalars['Int'];
  /** Role title */
  role?: Maybe<Scalars['String']>;
  /** Credit Type Sort ordering */
  sortOrder: Scalars['Int'];
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Updated at Date */
  updated: Scalars['DateTime'];
  /** Year */
  year?: Maybe<Scalars['Int']>;
};

export type WfCurrency = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  symbol: Scalars['String'];
};

export type WfDma = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  region?: Maybe<WfRegion>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfEntityType = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Measurement object */
export type WfMeasurementModel = {
  /** Centimeters */
  cm: Scalars['Int'];
  /** Primary ID */
  id: Scalars['ID'];
  /** Inches */
  inch: Scalars['Float'];
};

export type WfMedia = {
  fileKey?: Maybe<Scalars['String']>;
  fileType?: Maybe<WfMediaFileType>;
  filename: Scalars['String'];
  guid: Scalars['String'];
  isLocked?: Maybe<Scalars['Boolean']>;
  mediaId: Scalars['Int'];
  mediaStatus?: Maybe<WfMediaStatus>;
  mediaStorageStatus?: Maybe<WfMediaStatus>;
  mediaType?: Maybe<WfMediaType>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<WfMedia>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  transformation?: Maybe<WfMediaTransformation>;
  url?: Maybe<Scalars['String']>;
};

/** Media Count */
export type WfMediaCount = {
  /** Count of the media */
  count?: Maybe<Scalars['Int']>;
  /** Media Type */
  type?: Maybe<WfMediaType>;
};

export type WfMediaFileType = {
  code: Scalars['String'];
  id: Scalars['ID'];
  mediaType: WfMediaType;
  name: Scalars['String'];
};

export type WfMediaStatus = {
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type WfMediaTransformation = {
  height?: Maybe<Scalars['Float']>;
  rotate?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  xAxis?: Maybe<Scalars['Float']>;
  yAxis?: Maybe<Scalars['Float']>;
};

export type WfMediaType = {
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Note object type */
export type WfNote = {
  /** Account id who created the note */
  accountId: Scalars['Int'];
  /** Comment */
  comment: Scalars['String'];
  /** Created Date */
  created: Scalars['String'];
  /** Note Id */
  id: Scalars['Int'];
  noteModifier?: Maybe<WfNoteModifier>;
};

export type WfNoteModifier = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  entityType: WfEntityType;
  id: Scalars['ID'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

/** Organization object */
export type WfOrganization = {
  /** Abbreviation */
  abbreviation?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<WfContact>>;
  /** Primary ID */
  id: Scalars['Int'];
  /** If false, it is a division. */
  isOrganization?: Maybe<Scalars['Boolean']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Parent Name (if division) */
  parentName?: Maybe<Scalars['String']>;
  parentOrganization?: Maybe<WfOrganization>;
};

export type WfProfile = {
  /** Identify the access granted by agency or not. */
  accessGranted: Scalars['Boolean'];
  artistId?: Maybe<Scalars['Float']>;
  contactInfo?: Maybe<WfProfileContactInfo>;
  /** Talent's cover */
  cover?: Maybe<WfMedia>;
  /**
   * Talent's cover
   * @deprecated Replaced with cover field
   */
  coverUrl?: Maybe<Scalars['String']>;
  credits?: Maybe<Array<WfCredit>>;
  creditsCount: Scalars['Int'];
  /** Date of birth */
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  /** Talent's email */
  email?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  /** HairColor object */
  hairColor?: Maybe<WfBasicSettingModel>;
  /** HairStyle object */
  hairStyle?: Maybe<WfBasicSettingModel>;
  hasAuditionHistory?: Maybe<Scalars['Boolean']>;
  hasResume?: Maybe<Scalars['Boolean']>;
  /** Height object */
  height?: Maybe<WfMeasurementModel>;
  id: Scalars['Int'];
  /** If Profile is overscaled */
  isOverscaled?: Maybe<Scalars['Boolean']>;
  /** Identify the personal or represented profile. */
  isPersonal: Scalars['Boolean'];
  isRepresented?: Maybe<Scalars['Boolean']>;
  lastNote?: Maybe<WfNote>;
  /** All profile media */
  mediaAssets: Array<WfProfileMedia>;
  mediaSummary?: Maybe<Array<WfMediaCount>>;
  /** Is minor */
  minor?: Maybe<Scalars['Boolean']>;
  /**
   * Talent's name
   * @deprecated Deprecated. replaced with stageName field
   */
  name?: Maybe<Scalars['String']>;
  /** profile note */
  note?: Maybe<Scalars['String']>;
  /** all notes */
  notes?: Maybe<Array<WfNote>>;
  organization?: Maybe<WfOrganization>;
  organizations: Array<WfOrganization>;
  /** Overscale Note */
  overscaleNote?: Maybe<Scalars['String']>;
  /** Talents phone */
  phone?: Maybe<Scalars['String']>;
  /** The minimum age a talent can play */
  playableAgeMax?: Maybe<Scalars['Float']>;
  /** The maximum age a talent can play */
  playableAgeMin?: Maybe<Scalars['Float']>;
  /** Profession */
  profession?: Maybe<Scalars['String']>;
  /** Access Token of a Profile, generated by Granting Access or Creating a Profile */
  profileAccessToken?: Maybe<Scalars['String']>;
  profileMainDivision?: Maybe<WfOrganization>;
  profileMainOrganization?: Maybe<WfOrganization>;
  /** Profile Status Object */
  profileStatus?: Maybe<WfBasicSettingModel>;
  resume?: Maybe<WfProfileMedia>;
  /** Talent's stage name */
  stageName: Scalars['String'];
  /** Talent unions */
  talentUnions: Array<WfTalentProfileUnion>;
  /** Weight object */
  weight?: Maybe<WfWeight>;
  /** Working Locations */
  workingLocations: Array<WfDma>;
};

/** Profile Media */
export type WfProfileContactInfo = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Profile Media */
export type WfProfileMedia = {
  media: WfMedia;
  profileMediaId: Scalars['Float'];
};

export type WfProject = {
  castingCompany?: Maybe<Scalars['String']>;
  createdByPC: Scalars['Boolean'];
  displayedProjectUnion?: Maybe<Scalars['String']>;
  displayedProjectUnionShort?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  projectStatus: WfBasicSettingModel;
  projectType: WfProjectType;
  projectUnions?: Maybe<Array<WfProjectUnionDetails>>;
  synopsis?: Maybe<Scalars['String']>;
};

export type WfProjectType = {
  childObjects: Array<WfComplexSetting>;
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  parentObject?: Maybe<Scalars['Int']>;
  selectable: Scalars['Boolean'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfProjectUnionDetails = {
  contractId?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<WfContractStatus>;
  contractStatusId?: Maybe<Scalars['Int']>;
  union: WfTalentUnion;
};

export type WfRatePerType = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfRegion = {
  code: Scalars['String'];
  country?: Maybe<WfCountry>;
  dmas?: Maybe<Array<WfDma>>;
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfRole = {
  /** Ages of roles showing in years */
  ageInYears: Scalars['Boolean'];
  ageMax?: Maybe<Scalars['Int']>;
  ageMin?: Maybe<Scalars['Int']>;
  /** Role description */
  description?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Role internal name */
  internalName?: Maybe<Scalars['String']>;
  /** Role description */
  locationDates?: Maybe<Array<WfRoleLocationDate>>;
  name: Scalars['String'];
  project?: Maybe<WfProject>;
  publicationSettingIds?: Maybe<Array<Scalars['Int']>>;
  rate?: Maybe<Scalars['Float']>;
  rateMax?: Maybe<Scalars['Float']>;
  ratePerType?: Maybe<WfRatePerType>;
  /** Rate summary */
  rateSummary?: Maybe<Scalars['String']>;
  roleStatus: WfBasicSettingModel;
  roleType: WfRoleType;
  sides?: Maybe<Array<WfRoleMedia>>;
  sidesVisibility?: Maybe<WfRoleSidesVisibility>;
  synopsis?: Maybe<Scalars['String']>;
  wardrobe?: Maybe<Scalars['String']>;
};

export type WfRoleDateType = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfRoleLocationDate = {
  cityName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  /** RoleDateLocation Primary ID */
  id: Scalars['ID'];
  roleDateType?: Maybe<WfRoleDateType>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type WfRoleMedia = {
  isSides: Scalars['Boolean'];
  mediaObject: WfMedia;
  name?: Maybe<Scalars['String']>;
  roleMediaId: Scalars['Int'];
};

export type WfRoleShort = {
  id: Scalars['Int'];
  internalName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  projectId: Scalars['Int'];
  statusId: Scalars['Int'];
};

export type WfRoleSidesVisibility = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfRoleTarget = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfRoleType = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  projectType: WfProjectType;
  roleTarget: WfRoleTarget;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type WfSchedule = {
  address?: Maybe<WfAddress>;
  date?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isLocationHidden: Scalars['Boolean'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  /** Time Zone */
  timeZone?: Maybe<WfTimeZone>;
  type: ScheduleRequestType;
  virtualAudition?: Maybe<WfScheduleVirtualAudition>;
};

export type WfScheduleVirtualAudition = {
  joinUrl?: Maybe<Scalars['String']>;
};

export type WfSubmission = {
  contactInfo?: Maybe<WfProfileContactInfo>;
  /** Talent's customized cover */
  cover?: Maybe<WfMedia>;
  hasAudioReel?: Maybe<Scalars['Boolean']>;
  hasVideoReel?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  isCreatedByCD?: Maybe<Scalars['Boolean']>;
  isCustomized: Scalars['Boolean'];
  isOverscale?: Maybe<Scalars['Boolean']>;
  lastNote?: Maybe<WfNote>;
  mediaCount?: Maybe<Array<WfMediaCount>>;
  note?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<WfNote>>;
  overscaleNote?: Maybe<Scalars['String']>;
  /** Profile */
  profile?: Maybe<WfProfile>;
  /** Role */
  role?: Maybe<WfRole>;
  tileSchemaValue?: Maybe<WfBasicSettingModel>;
};

export type WfSubmissionMediaCountArgs = {
  requested?: InputMaybe<Scalars['Boolean']>;
};

export type WfSubmissionPagedList = {
  data: Array<WfSubmission>;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type WfSubmissionSearchInput = {
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  stageName?: InputMaybe<Scalars['String']>;
  tileSchemeValueCodes?: InputMaybe<Array<Scalars['String']>>;
};

/** General weight object */
export type WfTalentProfileUnion = {
  /** Code */
  code: Scalars['String'];
  /** Primary ID */
  id: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<WfTalentProfileUnion>;
};

export type WfTalentUnion = {
  childObjects: Array<WfTalentUnion>;
  code: Scalars['String'];
  country?: Maybe<WfCountry>;
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  parentObject?: Maybe<WfTalentUnion>;
  projectEnabled: Scalars['Boolean'];
  selectable: Scalars['Boolean'];
  sortOrder?: Maybe<Scalars['Int']>;
  talentEnabled: Scalars['Boolean'];
};

/** Measurement object */
export type WfTimeZone = {
  abbreviation?: Maybe<Scalars['String']>;
  /** code */
  code: Scalars['String'];
  codeBrowser: Scalars['String'];
  daylightSavingsCode?: Maybe<Scalars['String']>;
  dstOffset: Scalars['Float'];
  gmtOffset: Scalars['Float'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  rawOffset: Scalars['Float'];
  standardName: Scalars['String'];
};

export type WfUnitSystem = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

/** General weight object */
export type WfWeight = {
  /** Primary ID */
  id: Scalars['ID'];
  kg: Scalars['Float'];
  /** pounds */
  lbs: Scalars['Int'];
};

/** Willingness object */
export type Willingness = {
  /** child objects */
  childObjects: Array<Willingness>;
  /** code */
  code: Scalars['String'];
  /** Is this enabled? */
  enabled: Scalars['Boolean'];
  /** Primary ID */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  /** parent object */
  parentObject?: Maybe<Willingness>;
  /** Selectable Flag */
  selectable: Scalars['Boolean'];
  /** Sort order */
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum WillingnessEnum {
  Animals = 'ANIMALS',
  AnimalsWorkWithCats = 'ANIMALS_WORK_WITH_CATS',
  AnimalsWorkWithDogs = 'ANIMALS_WORK_WITH_DOGS',
  ExtrasWork = 'EXTRAS_WORK',
  Other = 'OTHER',
  OtherColorHair = 'OTHER_COLOR_HAIR',
  OtherCutHair = 'OTHER_CUT_HAIR',
  OtherEatMeat = 'OTHER_EAT_MEAT',
  OtherGrowOrShaveFacialHair = 'OTHER_GROW_OR_SHAVE_FACIAL_HAIR',
  OtherKissingScene = 'OTHER_KISSING_SCENE',
  OtherSameSexKissingScene = 'OTHER_SAME_SEX_KISSING_SCENE',
  OtherSexualSituations = 'OTHER_SEXUAL_SITUATIONS',
  OtherSmoke = 'OTHER_SMOKE',
  Reveal = 'REVEAL',
  RevealAppearInLingerieOrSwimsuit = 'REVEAL_APPEAR_IN_LINGERIE_OR_SWIMSUIT',
  RevealCrossDress = 'REVEAL_CROSS_DRESS',
  RevealNude = 'REVEAL_NUDE',
  RevealSemiNude = 'REVEAL_SEMI_NUDE',
}

export type AuditionGroupDetails = AuditionsOrganization | WfProfile | WfProject;

export enum ProjectSidesVisibilityEnum {
  RepresentativesAndCb = 'REPRESENTATIVES_AND_CB',
  RepresentativesOnly = 'REPRESENTATIVES_ONLY',
}

export type AddArtistFreeCreditsMutationVariables = Exact<{
  input: AddArtistFreeCreditsInput;
}>;

export type AddArtistFreeCreditsMutation = { addArtistFreeCredits: boolean };

export type AddBlockedKeywordMutationVariables = Exact<{
  keyword: Scalars['String'];
  blockedReason: CustomSlugBlockedReasonEnum;
  blockedReasonNote?: InputMaybe<Scalars['String']>;
}>;

export type AddBlockedKeywordMutation = {
  addBlockedKeyword: {
    __typename: 'BlockedKeyword';
    id: string;
    keyword: string;
    blockedReasonNote?: string | null;
    createdDate: any;
    blockedReason: {
      __typename: 'CustomUrlSlugExpiredReason';
      enabled: boolean;
      id: string;
      name: string;
      code: string;
    };
    createdByAccount: { __typename: 'Account'; id: number; firstName: string; lastName: string };
  };
};

export type BlockedKeywordResponseFragment = {
  __typename: 'BlockedKeyword';
  id: string;
  keyword: string;
  blockedReasonNote?: string | null;
  createdDate: any;
  blockedReason: { __typename: 'CustomUrlSlugExpiredReason'; enabled: boolean; id: string; name: string; code: string };
  createdByAccount: { __typename: 'Account'; id: number; firstName: string; lastName: string };
};

export type ArchiveMediaMutationVariables = Exact<{
  mediaId: Scalars['ID'];
}>;

export type ArchiveMediaMutation = { archiveMedia: { __typename: 'ArchiveMediaResponse'; success: boolean } };

export type ArchiveMediaV2MutationVariables = Exact<{
  mediaIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type ArchiveMediaV2Mutation = { archiveMediaAssetV2: boolean };

export type AuditionMediaFragment = {
  __typename: 'AuditionGroupMedia';
  id: number;
  mediaId: number;
  name: string;
  order: number;
  media: {
    __typename: 'Media';
    id: string;
    thumbnailUrl?: string | null;
    created?: any | null;
    mediaId: number;
    purchaseStatus?: MediaPurchaseStatus | null;
    accountId: number;
    archivableOn?: any | null;
    fileKey?: string | null;
    filename?: string | null;
    guid: string;
    height?: number | null;
    length?: number | null;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    appearOnSubmissions: boolean;
    appearOnMediaRequests: boolean;
    isLocked?: boolean | null;
    updated?: any | null;
    url?: string | null;
    width?: number | null;
    thumbnail?: {
      __typename: 'Media';
      id: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      url?: string | null;
      fileKey?: string | null;
      guid: string;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
    } | null;
    createdByOrg?: {
      __typename: 'Organization';
      id: number;
      name?: string | null;
      parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
    } | null;
    displaySize?: { size: number; unit: string } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    moderationStatus: { __typename: 'MediaModerationStatus'; id: string; code: string };
    purpose: { __typename: 'MediaPurpose'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    profiles?: Array<{
      __typename: 'ProfileMedia';
      id: string;
      isPrimary: boolean;
      order: number;
      profile: {
        __typename: 'Profile';
        id: string;
        profileId: string;
        stageName: string;
        isPersonal: boolean;
        profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
        organizations?: Array<{
          __typename: 'Organization';
          id: number;
          name?: string | null;
          parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
        }> | null;
      };
    }> | null;
  };
};

export type AuditionGroupDetailFragment = {
  created: any;
  id: number;
  name?: string | null;
  order?: number | null;
  submissionCount?: any | null;
  updated?: any | null;
  auditionSession?: {
    __typename: 'AuditionSession';
    id: number;
    name?: string | null;
    source?: { __typename: 'AuditionSource'; code: string; id: string; name: string } | null;
  } | null;
  cover?: {
    __typename: 'Media';
    id: string;
    thumbnailUrl?: string | null;
    created?: any | null;
    mediaId: number;
    purchaseStatus?: MediaPurchaseStatus | null;
    accountId: number;
    archivableOn?: any | null;
    fileKey?: string | null;
    filename?: string | null;
    guid: string;
    height?: number | null;
    length?: number | null;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    appearOnSubmissions: boolean;
    appearOnMediaRequests: boolean;
    isLocked?: boolean | null;
    updated?: any | null;
    url?: string | null;
    width?: number | null;
    thumbnail?: {
      __typename: 'Media';
      id: string;
      height?: number | null;
      width?: number | null;
      length?: number | null;
      url?: string | null;
      fileKey?: string | null;
      guid: string;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
    } | null;
    createdByOrg?: {
      __typename: 'Organization';
      id: number;
      name?: string | null;
      parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
    } | null;
    displaySize?: { size: number; unit: string } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    moderationStatus: { __typename: 'MediaModerationStatus'; id: string; code: string };
    purpose: { __typename: 'MediaPurpose'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    profiles?: Array<{
      __typename: 'ProfileMedia';
      id: string;
      isPrimary: boolean;
      order: number;
      profile: {
        __typename: 'Profile';
        id: string;
        profileId: string;
        stageName: string;
        isPersonal: boolean;
        profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
        organizations?: Array<{
          __typename: 'Organization';
          id: number;
          name?: string | null;
          parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
        }> | null;
      };
    }> | null;
  } | null;
  createdBy?: { __typename: 'Account'; id: number; firstName: string; lastName: string } | null;
  medias?: Array<{
    __typename: 'AuditionGroupMedia';
    id: number;
    mediaId: number;
    name: string;
    order: number;
    media: {
      __typename: 'Media';
      id: string;
      thumbnailUrl?: string | null;
      created?: any | null;
      mediaId: number;
      purchaseStatus?: MediaPurchaseStatus | null;
      accountId: number;
      archivableOn?: any | null;
      fileKey?: string | null;
      filename?: string | null;
      guid: string;
      height?: number | null;
      length?: number | null;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      appearOnSubmissions: boolean;
      appearOnMediaRequests: boolean;
      isLocked?: boolean | null;
      updated?: any | null;
      url?: string | null;
      width?: number | null;
      thumbnail?: {
        __typename: 'Media';
        id: string;
        height?: number | null;
        width?: number | null;
        length?: number | null;
        url?: string | null;
        fileKey?: string | null;
        guid: string;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      createdByOrg?: {
        __typename: 'Organization';
        id: number;
        name?: string | null;
        parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
      } | null;
      displaySize?: { size: number; unit: string } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      moderationStatus: { __typename: 'MediaModerationStatus'; id: string; code: string };
      purpose: { __typename: 'MediaPurpose'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      profiles?: Array<{
        __typename: 'ProfileMedia';
        id: string;
        isPrimary: boolean;
        order: number;
        profile: {
          __typename: 'Profile';
          id: string;
          profileId: string;
          stageName: string;
          isPersonal: boolean;
          profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
          organizations?: Array<{
            __typename: 'Organization';
            id: number;
            name?: string | null;
            parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
          }> | null;
        };
      }> | null;
    };
  }> | null;
  mediaCount?: Array<{
    __typename: 'MediaCount';
    count: number;
    type: { __typename: 'MediaType'; name: string; code: string; id: string };
  }> | null;
};

export type AuditionGroupQueryVariables = Exact<{
  groupId: Scalars['Int'];
}>;

export type AuditionGroupQuery = {
  auditionGroup: {
    __typename: 'AuditionGroup';
    created: any;
    id: number;
    name?: string | null;
    order?: number | null;
    submissionCount?: any | null;
    updated?: any | null;
    auditionSession?: {
      __typename: 'AuditionSession';
      id: number;
      name?: string | null;
      source?: { __typename: 'AuditionSource'; code: string; id: string; name: string } | null;
    } | null;
    cover?: {
      __typename: 'Media';
      id: string;
      thumbnailUrl?: string | null;
      created?: any | null;
      mediaId: number;
      purchaseStatus?: MediaPurchaseStatus | null;
      accountId: number;
      archivableOn?: any | null;
      fileKey?: string | null;
      filename?: string | null;
      guid: string;
      height?: number | null;
      length?: number | null;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      appearOnSubmissions: boolean;
      appearOnMediaRequests: boolean;
      isLocked?: boolean | null;
      updated?: any | null;
      url?: string | null;
      width?: number | null;
      thumbnail?: {
        __typename: 'Media';
        id: string;
        height?: number | null;
        width?: number | null;
        length?: number | null;
        url?: string | null;
        fileKey?: string | null;
        guid: string;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      createdByOrg?: {
        __typename: 'Organization';
        id: number;
        name?: string | null;
        parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
      } | null;
      displaySize?: { size: number; unit: string } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      moderationStatus: { __typename: 'MediaModerationStatus'; id: string; code: string };
      purpose: { __typename: 'MediaPurpose'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      profiles?: Array<{
        __typename: 'ProfileMedia';
        id: string;
        isPrimary: boolean;
        order: number;
        profile: {
          __typename: 'Profile';
          id: string;
          profileId: string;
          stageName: string;
          isPersonal: boolean;
          profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
          organizations?: Array<{
            __typename: 'Organization';
            id: number;
            name?: string | null;
            parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
          }> | null;
        };
      }> | null;
    } | null;
    createdBy?: { __typename: 'Account'; id: number; firstName: string; lastName: string } | null;
    medias?: Array<{
      __typename: 'AuditionGroupMedia';
      id: number;
      mediaId: number;
      name: string;
      order: number;
      media: {
        __typename: 'Media';
        id: string;
        thumbnailUrl?: string | null;
        created?: any | null;
        mediaId: number;
        purchaseStatus?: MediaPurchaseStatus | null;
        accountId: number;
        archivableOn?: any | null;
        fileKey?: string | null;
        filename?: string | null;
        guid: string;
        height?: number | null;
        length?: number | null;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        appearOnSubmissions: boolean;
        appearOnMediaRequests: boolean;
        isLocked?: boolean | null;
        updated?: any | null;
        url?: string | null;
        width?: number | null;
        thumbnail?: {
          __typename: 'Media';
          id: string;
          height?: number | null;
          width?: number | null;
          length?: number | null;
          url?: string | null;
          fileKey?: string | null;
          guid: string;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        } | null;
        createdByOrg?: {
          __typename: 'Organization';
          id: number;
          name?: string | null;
          parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
        } | null;
        displaySize?: { size: number; unit: string } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        moderationStatus: { __typename: 'MediaModerationStatus'; id: string; code: string };
        purpose: { __typename: 'MediaPurpose'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        profiles?: Array<{
          __typename: 'ProfileMedia';
          id: string;
          isPrimary: boolean;
          order: number;
          profile: {
            __typename: 'Profile';
            id: string;
            profileId: string;
            stageName: string;
            isPersonal: boolean;
            profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
            organizations?: Array<{
              __typename: 'Organization';
              id: number;
              name?: string | null;
              parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
            }> | null;
          };
        }> | null;
      };
    }> | null;
    mediaCount?: Array<{
      __typename: 'MediaCount';
      count: number;
      type: { __typename: 'MediaType'; name: string; code: string; id: string };
    }> | null;
  };
};

export type AllAuditionGroupsBySessionQueryVariables = Exact<{
  sessionId: Scalars['Int'];
}>;

export type AllAuditionGroupsBySessionQuery = {
  auditionGroupsBySession: {
    __typename: 'AuditionGroupPaginatedResult';
    totalCount: number;
    objects: Array<{ __typename: 'AuditionGroup'; id: number; order?: number | null; archived?: boolean | null }>;
  };
};

export type AuditionGroupSubProfileFragment = {
  __typename: 'Profile';
  dateOfBirth?: any | null;
  guid?: string | null;
  id: string;
  isPersonal: boolean;
  isRepresented: boolean;
  minor?: boolean | null;
  profileId: string;
  stageName: string;
  contactInfo?: { __typename: 'ProfileContactInfo'; email?: string | null; phone?: string | null } | null;
  notes: Array<{
    __typename: 'Note';
    id: number;
    noteType?: { __typename: 'NoteType'; id: string; code: string; name: string } | null;
  }>;
  profileType?: { __typename: 'ProfileType'; id: string; code: string } | null;
  organization?: {
    __typename: 'Organization';
    id: number;
    phone: Array<string>;
    contacts?: Array<{
      __typename: 'Contact';
      id: string;
      mainContact: boolean;
      email?: string | null;
      phone: string;
    }> | null;
    parentOrganization?: {
      __typename: 'Organization';
      id: number;
      phone: Array<string>;
      contacts?: Array<{
        __typename: 'Contact';
        id: string;
        mainContact: boolean;
        email?: string | null;
        phone: string;
      }> | null;
    } | null;
  } | null;
  profileMainOrganization?: {
    __typename: 'Organization';
    id: number;
    name?: string | null;
    abbreviation?: string | null;
    phone: Array<string>;
    contacts?: Array<{
      __typename: 'Contact';
      id: string;
      mainContact: boolean;
      email?: string | null;
      phone: string;
    }> | null;
  } | null;
  profileMainDivision?: {
    __typename: 'Organization';
    id: number;
    name?: string | null;
    abbreviation?: string | null;
  } | null;
};

export type AuditionGroupSubmissionFragment = {
  __typename: 'Submission';
  commentsCount?: number | null;
  id: any;
  isCustomized: boolean;
  picksCount?: number | null;
  profileId: number;
  roleName?: string | null;
  contactInfo?: { __typename: 'ProfileContactInfo'; email?: string | null; phone?: string | null } | null;
  cover?: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    thumbnail?: {
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  } | null;
  media: Array<{
    __typename: 'SubmissionMedia';
    fileKey?: string | null;
    filename?: string | null;
    guid: string;
    id: string;
    isLocked?: boolean | null;
    isPrimary: boolean;
    mediaId: number;
    name?: string | null;
    orderOfAppearance: number;
    fileType: { __typename: 'MediaFileType'; code: string; id: string; name: string };
    mediaType?: { __typename: 'MediaType'; code: string; id: string; name: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; code: string; name: string; id: string };
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; code: string; id: string; name: string };
  }>;
  profile?: {
    __typename: 'Profile';
    dateOfBirth?: any | null;
    guid?: string | null;
    id: string;
    isPersonal: boolean;
    isRepresented: boolean;
    minor?: boolean | null;
    profileId: string;
    stageName: string;
    contactInfo?: { __typename: 'ProfileContactInfo'; email?: string | null; phone?: string | null } | null;
    notes: Array<{
      __typename: 'Note';
      id: number;
      noteType?: { __typename: 'NoteType'; id: string; code: string; name: string } | null;
    }>;
    profileType?: { __typename: 'ProfileType'; id: string; code: string } | null;
    organization?: {
      __typename: 'Organization';
      id: number;
      phone: Array<string>;
      contacts?: Array<{
        __typename: 'Contact';
        id: string;
        mainContact: boolean;
        email?: string | null;
        phone: string;
      }> | null;
      parentOrganization?: {
        __typename: 'Organization';
        id: number;
        phone: Array<string>;
        contacts?: Array<{
          __typename: 'Contact';
          id: string;
          mainContact: boolean;
          email?: string | null;
          phone: string;
        }> | null;
      } | null;
    } | null;
    profileMainOrganization?: {
      __typename: 'Organization';
      id: number;
      name?: string | null;
      abbreviation?: string | null;
      phone: Array<string>;
      contacts?: Array<{
        __typename: 'Contact';
        id: string;
        mainContact: boolean;
        email?: string | null;
        phone: string;
      }> | null;
    } | null;
    profileMainDivision?: {
      __typename: 'Organization';
      id: number;
      name?: string | null;
      abbreviation?: string | null;
    } | null;
  } | null;
  pickedBy: Array<{ __typename: 'SubmissionPick'; id: number; userName: string }>;
  snapshotMedia?: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    thumbnail?: {
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  } | null;
  submissionStatus?: { __typename: 'SubmissionStatus'; id: string; code: string } | null;
  role: {
    __typename: 'Role';
    id: string;
    name: string;
    internalName?: string | null;
    guid?: string | null;
    project: { __typename: 'Project'; id: string; guid?: string | null };
  };
  fastcastNotes: Array<{
    __typename: 'Note';
    id: number;
    comment: string;
    created: any;
    account: {
      __typename: 'Account';
      id: number;
      firstName: string;
      lastName: string;
      timeZone?: { __typename: 'TimeZone'; id: string; standardName: string; abbreviation?: string | null } | null;
    };
  }>;
};

export type SearchSubmissionsByAuditionGroupIdQueryVariables = Exact<{
  groupId: Scalars['Int'];
  page: PageInput;
}>;

export type SearchSubmissionsByAuditionGroupIdQuery = {
  searchSubmissionsByAuditionGroupId: {
    totalCount: number;
    page: number;
    totalPages: number;
    objects: Array<{
      __typename: 'Submission';
      commentsCount?: number | null;
      id: any;
      isCustomized: boolean;
      picksCount?: number | null;
      profileId: number;
      roleName?: string | null;
      contactInfo?: { __typename: 'ProfileContactInfo'; email?: string | null; phone?: string | null } | null;
      cover?: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        thumbnail?: {
          __typename: 'Media';
          fileKey?: string | null;
          guid: string;
          height?: number | null;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          size?: number | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          width?: number | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      } | null;
      media: Array<{
        __typename: 'SubmissionMedia';
        fileKey?: string | null;
        filename?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        isPrimary: boolean;
        mediaId: number;
        name?: string | null;
        orderOfAppearance: number;
        fileType: { __typename: 'MediaFileType'; code: string; id: string; name: string };
        mediaType?: { __typename: 'MediaType'; code: string; id: string; name: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; code: string; name: string; id: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; code: string; id: string; name: string };
      }>;
      profile?: {
        __typename: 'Profile';
        dateOfBirth?: any | null;
        guid?: string | null;
        id: string;
        isPersonal: boolean;
        isRepresented: boolean;
        minor?: boolean | null;
        profileId: string;
        stageName: string;
        contactInfo?: { __typename: 'ProfileContactInfo'; email?: string | null; phone?: string | null } | null;
        notes: Array<{
          __typename: 'Note';
          id: number;
          noteType?: { __typename: 'NoteType'; id: string; code: string; name: string } | null;
        }>;
        profileType?: { __typename: 'ProfileType'; id: string; code: string } | null;
        organization?: {
          __typename: 'Organization';
          id: number;
          phone: Array<string>;
          contacts?: Array<{
            __typename: 'Contact';
            id: string;
            mainContact: boolean;
            email?: string | null;
            phone: string;
          }> | null;
          parentOrganization?: {
            __typename: 'Organization';
            id: number;
            phone: Array<string>;
            contacts?: Array<{
              __typename: 'Contact';
              id: string;
              mainContact: boolean;
              email?: string | null;
              phone: string;
            }> | null;
          } | null;
        } | null;
        profileMainOrganization?: {
          __typename: 'Organization';
          id: number;
          name?: string | null;
          abbreviation?: string | null;
          phone: Array<string>;
          contacts?: Array<{
            __typename: 'Contact';
            id: string;
            mainContact: boolean;
            email?: string | null;
            phone: string;
          }> | null;
        } | null;
        profileMainDivision?: {
          __typename: 'Organization';
          id: number;
          name?: string | null;
          abbreviation?: string | null;
        } | null;
      } | null;
      pickedBy: Array<{ __typename: 'SubmissionPick'; id: number; userName: string }>;
      snapshotMedia?: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        thumbnail?: {
          __typename: 'Media';
          fileKey?: string | null;
          guid: string;
          height?: number | null;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          size?: number | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          width?: number | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      } | null;
      submissionStatus?: { __typename: 'SubmissionStatus'; id: string; code: string } | null;
      role: {
        __typename: 'Role';
        id: string;
        name: string;
        internalName?: string | null;
        guid?: string | null;
        project: { __typename: 'Project'; id: string; guid?: string | null };
      };
      fastcastNotes: Array<{
        __typename: 'Note';
        id: number;
        comment: string;
        created: any;
        account: {
          __typename: 'Account';
          id: number;
          firstName: string;
          lastName: string;
          timeZone?: { __typename: 'TimeZone'; id: string; standardName: string; abbreviation?: string | null } | null;
        };
      }>;
    }>;
  };
};

export type AttachMediaToAuditionGroupMutationVariables = Exact<{
  inputModel: AuditionGroupMediaInput;
}>;

export type AttachMediaToAuditionGroupMutation = { attachMediaToAuditionGroup: boolean };

export type AuditionSessionViewQueryVariables = Exact<{
  sessionId: Scalars['Int'];
}>;

export type AuditionSessionViewQuery = {
  auditionSession?: {
    __typename: 'AuditionSession';
    id: number;
    projectId?: number | null;
    name?: string | null;
    created: any;
    source?: { __typename: 'AuditionSource'; id: string; code: string } | null;
    createdBy?: { __typename: 'Account'; id: number; firstName: string; lastName: string } | null;
  } | null;
};

export type AuditionSessionViewAuditionSessionFragment = {
  __typename: 'AuditionSession';
  id: number;
  projectId?: number | null;
  name?: string | null;
  created: any;
  source?: { __typename: 'AuditionSource'; id: string; code: string } | null;
  createdBy?: { __typename: 'Account'; id: number; firstName: string; lastName: string } | null;
};

export type AuditionSessionViewAuditionSessionGroupsQueryVariables = Exact<{
  sessionId: Scalars['Int'];
  pageInput?: InputMaybe<PageInput>;
  submissionsCount?: InputMaybe<Scalars['Float']>;
}>;

export type AuditionSessionViewAuditionSessionGroupsQuery = {
  auditionSessionGroups: {
    __typename: 'AuditionGroupPaginatedResult';
    totalCount: number;
    page: number;
    totalPages: number;
    objects: Array<{
      __typename: 'AuditionGroup';
      id: number;
      name?: string | null;
      created: any;
      updated?: any | null;
      auditionSessionId: number;
      order?: number | null;
      archived?: boolean | null;
      submissionCount?: any | null;
      mediaCount?: Array<{
        __typename: 'MediaCount';
        count: number;
        type: { __typename: 'MediaType'; id: string; code: string };
      }> | null;
      cover?: {
        __typename: 'Media';
        id: string;
        guid: string;
        fileKey?: string | null;
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        transformation?: { __typename: 'MediaTransformation'; startOffset?: string | null } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        thumbnail?: {
          __typename: 'Media';
          fileKey?: string | null;
          guid: string;
          height?: number | null;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          size?: number | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          width?: number | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        } | null;
      } | null;
      submissions?: Array<{
        __typename: 'Submission';
        id: any;
        roleName?: string | null;
        snapshotMedia?: {
          __typename: 'Media';
          isLocked?: boolean | null;
          fileKey?: string | null;
          guid: string;
          height?: number | null;
          id: string;
          mediaId: number;
          name?: string | null;
          size?: number | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          width?: number | null;
          thumbnail?: {
            __typename: 'Media';
            fileKey?: string | null;
            guid: string;
            height?: number | null;
            id: string;
            isLocked?: boolean | null;
            mediaId: number;
            name?: string | null;
            size?: number | null;
            source?: string | null;
            thumbnailUrl?: string | null;
            url?: string | null;
            width?: number | null;
            transformation?: {
              __typename: 'MediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
              startOffset?: string | null;
            } | null;
            mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
            fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
            mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
            mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        } | null;
        cover?: {
          __typename: 'Media';
          isLocked?: boolean | null;
          fileKey?: string | null;
          guid: string;
          height?: number | null;
          id: string;
          mediaId: number;
          name?: string | null;
          size?: number | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          width?: number | null;
          thumbnail?: {
            __typename: 'Media';
            fileKey?: string | null;
            guid: string;
            height?: number | null;
            id: string;
            isLocked?: boolean | null;
            mediaId: number;
            name?: string | null;
            size?: number | null;
            source?: string | null;
            thumbnailUrl?: string | null;
            url?: string | null;
            width?: number | null;
            transformation?: {
              __typename: 'MediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
              startOffset?: string | null;
            } | null;
            mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
            fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
            mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
            mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        } | null;
        profile?: {
          __typename: 'Profile';
          id: string;
          profileId: string;
          stageName: string;
          dateOfBirth?: any | null;
          minor?: boolean | null;
        } | null;
        role: { __typename: 'Role'; id: string; name: string; internalName?: string | null };
      }> | null;
    }>;
  };
};

export type AuditionSessionViewAuditionSessionGroupFragment = {
  __typename: 'AuditionGroup';
  id: number;
  name?: string | null;
  created: any;
  updated?: any | null;
  auditionSessionId: number;
  order?: number | null;
  archived?: boolean | null;
  submissionCount?: any | null;
  mediaCount?: Array<{
    __typename: 'MediaCount';
    count: number;
    type: { __typename: 'MediaType'; id: string; code: string };
  }> | null;
  cover?: {
    __typename: 'Media';
    id: string;
    guid: string;
    fileKey?: string | null;
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    transformation?: { __typename: 'MediaTransformation'; startOffset?: string | null } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    thumbnail?: {
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
  } | null;
  submissions?: Array<{
    __typename: 'Submission';
    id: any;
    roleName?: string | null;
    snapshotMedia?: {
      __typename: 'Media';
      isLocked?: boolean | null;
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      thumbnail?: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    cover?: {
      __typename: 'Media';
      isLocked?: boolean | null;
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      thumbnail?: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    profile?: {
      __typename: 'Profile';
      id: string;
      profileId: string;
      stageName: string;
      dateOfBirth?: any | null;
      minor?: boolean | null;
    } | null;
    role: { __typename: 'Role'; id: string; name: string; internalName?: string | null };
  }> | null;
};

export type AuditionSessionViewCoverMediaFragment = {
  __typename: 'Media';
  id: string;
  guid: string;
  fileKey?: string | null;
  mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
  mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  transformation?: { __typename: 'MediaTransformation'; startOffset?: string | null } | null;
  mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
  thumbnail?: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  } | null;
};

export type AuditionSessionViewAuditionSubmissionFragment = {
  __typename: 'Submission';
  id: any;
  roleName?: string | null;
  snapshotMedia?: {
    __typename: 'Media';
    isLocked?: boolean | null;
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    thumbnail?: {
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  } | null;
  cover?: {
    __typename: 'Media';
    isLocked?: boolean | null;
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    thumbnail?: {
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  } | null;
  profile?: {
    __typename: 'Profile';
    id: string;
    profileId: string;
    stageName: string;
    dateOfBirth?: any | null;
    minor?: boolean | null;
  } | null;
  role: { __typename: 'Role'; id: string; name: string; internalName?: string | null };
};

export type AuditionSessionsForProjectQueryVariables = Exact<{
  projectId: Scalars['Int'];
  page?: InputMaybe<PageInput>;
}>;

export type AuditionSessionsForProjectQuery = {
  auditionSessions: {
    __typename: 'AuditionSessionPaginatedResult';
    totalCount: number;
    objects: Array<{
      __typename: 'AuditionSession';
      id: number;
      name?: string | null;
      source?: { __typename: 'AuditionSource'; id: string; code: string } | null;
    }>;
  };
};

export type AuditionSessionsForProjectAuditionSessionFragment = {
  __typename: 'AuditionSession';
  id: number;
  name?: string | null;
  source?: { __typename: 'AuditionSource'; id: string; code: string } | null;
};

export type AuthTokensFragment = { __typename: 'AuthToken'; refresh: string; access: string };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type RefreshTokenMutation = { refreshToken: { __typename: 'AuthToken'; refresh: string; access: string } };

export type ImpersonateAccountMutationVariables = Exact<{
  accountId: Scalars['Float'];
}>;

export type ImpersonateAccountMutation = {
  loginImpersonatedAccount: { __typename: 'AuthToken'; refresh: string; access: string };
};

export type SwitchAuthContextMutationVariables = Exact<{
  refreshToken: Scalars['String'];
  role?: InputMaybe<SystemRoleCode>;
  artistId?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['Int']>;
}>;

export type SwitchAuthContextMutation = {
  switchAuthContext: { __typename: 'AuthToken'; refresh: string; access: string };
};

export type VerifyAccountEmailMutationVariables = Exact<{ [key: string]: never }>;

export type VerifyAccountEmailMutation = {
  verifyAccountEmail: { __typename: 'AuthToken'; refresh: string; access: string };
};

export type UserContextBaseMediaFragment = {
  __typename: 'Media';
  created?: any | null;
  fileKey?: string | null;
  guid: string;
  id: string;
  isLocked?: boolean | null;
  mediaId: number;
  name?: string | null;
  source?: string | null;
  thumbnailUrl?: string | null;
  url?: string | null;
  fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
  mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
  mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
  transformation?: {
    __typename: 'MediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
    startOffset?: string | null;
  } | null;
};

export type MediaAssetWithThumbnailFragment = {
  __typename: 'Media';
  created?: any | null;
  fileKey?: string | null;
  guid: string;
  id: string;
  isLocked?: boolean | null;
  mediaId: number;
  name?: string | null;
  source?: string | null;
  thumbnailUrl?: string | null;
  url?: string | null;
  thumbnail?: {
    __typename: 'Media';
    created?: any | null;
    fileKey?: string | null;
    guid: string;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
  } | null;
  fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
  mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
  mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
  transformation?: {
    __typename: 'MediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
    startOffset?: string | null;
  } | null;
};

export type AccountOrgFragment = {
  __typename: 'Organization';
  id: number;
  name?: string | null;
  isOrganization?: boolean | null;
  abbreviation?: string | null;
  parentName?: string | null;
  sytemRoleId?: number | null;
  systemRoleCode?: string | null;
  features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
  logo?: {
    __typename: 'Media';
    created?: any | null;
    fileKey?: string | null;
    guid: string;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
  } | null;
  parentOrganization?: {
    __typename: 'Organization';
    id: number;
    name?: string | null;
    logo?: {
      __typename: 'Media';
      created?: any | null;
      fileKey?: string | null;
      guid: string;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
    } | null;
  } | null;
};

export type AccountProfileFragment = {
  __typename: 'Profile';
  artistId?: number | null;
  created?: any | null;
  dateOfBirth?: any | null;
  deactivatedBy?: number | null;
  isPersonal: boolean;
  isPlayableAgeLockedToTalent: boolean;
  isSeekingRepresentation?: boolean | null;
  noteToRepresentative?: string | null;
  profileId: string;
  stageName: string;
  billingFeatures?: Array<{
    __typename: 'ArtistBillingFeatureModel';
    artistId: number;
    credit: number;
    itemCode: string;
  }> | null;
  organizations?: Array<{
    __typename: 'Organization';
    id: number;
    name?: string | null;
    isOrganization?: boolean | null;
    abbreviation?: string | null;
    parentName?: string | null;
    sytemRoleId?: number | null;
    systemRoleCode?: string | null;
    features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
    logo?: {
      __typename: 'Media';
      created?: any | null;
      fileKey?: string | null;
      guid: string;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
    } | null;
    parentOrganization?: {
      __typename: 'Organization';
      id: number;
      name?: string | null;
      logo?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
    } | null;
  }> | null;
  photos?: Array<{
    __typename: 'ProfileMedia';
    profileMediaId: number;
    isPrimary: boolean;
    media: {
      __typename: 'Media';
      created?: any | null;
      fileKey?: string | null;
      guid: string;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      thumbnail?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
    };
  }> | null;
  profileBookOuts: Array<{
    __typename: 'ProfileBookOuts';
    profileBookoutId?: number | null;
    profileId?: number | null;
    startDate?: any | null;
    endDate?: any | null;
  }>;
  profileMainOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
  profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string; name: string } | null;
  profileVisiblities?: Array<{ __typename: 'ProfileVisibility'; id: string; code: string; name: string }> | null;
  seekingRepresentations?: Array<{
    __typename: 'SeekingRepresentation';
    id: string;
    code: string;
    name: string;
  }> | null;
};

export type ArtistSubscriptionPlanFragment = {
  __typename: 'BillingPlan';
  name: string;
  id: number;
  code: string;
  isBasePlan: boolean;
  billingCode: string;
  level: number;
  isComped: boolean;
  term: { __typename: 'BillingPlanTerm'; id: number; code: string };
};

export type ArtistSubscriptionFragment = {
  __typename: 'Subscription';
  id: string;
  expirationDate?: any | null;
  trialEndsAt?: any | null;
  trialLengthDays?: number | null;
  billingInfoId?: string | null;
  status: string;
  nextBillingDate?: any | null;
  resumeAt?: any | null;
  plan: {
    __typename: 'BillingPlan';
    name: string;
    id: number;
    code: string;
    isBasePlan: boolean;
    billingCode: string;
    level: number;
    isComped: boolean;
    term: { __typename: 'BillingPlanTerm'; id: number; code: string };
  };
};

export type AccountArtistMediaEntitlementFragment = {
  __typename: 'SubscriptionMediaEntitlement';
  mediaType: string;
  count: number;
  isUnlimited: boolean;
};

export type AccountArtistFragment = {
  __typename: 'Artist';
  id: string;
  minor: boolean;
  artistId: number;
  firstName: string;
  lastName: string;
  created: any;
  hasAugeoBenefits?: boolean | null;
  activeSubscription?: {
    __typename: 'Subscription';
    id: string;
    expirationDate?: any | null;
    trialEndsAt?: any | null;
    trialLengthDays?: number | null;
    billingInfoId?: string | null;
    status: string;
    nextBillingDate?: any | null;
    resumeAt?: any | null;
    plan: {
      __typename: 'BillingPlan';
      name: string;
      id: number;
      code: string;
      isBasePlan: boolean;
      billingCode: string;
      level: number;
      isComped: boolean;
      term: { __typename: 'BillingPlanTerm'; id: number; code: string };
    };
  } | null;
  previousSubscription?: {
    __typename: 'Subscription';
    id: string;
    expirationDate?: any | null;
    trialEndsAt?: any | null;
    trialLengthDays?: number | null;
    billingInfoId?: string | null;
    status: string;
    nextBillingDate?: any | null;
    resumeAt?: any | null;
    plan: {
      __typename: 'BillingPlan';
      name: string;
      id: number;
      code: string;
      isBasePlan: boolean;
      billingCode: string;
      level: number;
      isComped: boolean;
      term: { __typename: 'BillingPlanTerm'; id: number; code: string };
    };
  } | null;
  profiles?: Array<{
    __typename: 'Profile';
    artistId?: number | null;
    created?: any | null;
    dateOfBirth?: any | null;
    deactivatedBy?: number | null;
    isPersonal: boolean;
    isPlayableAgeLockedToTalent: boolean;
    isSeekingRepresentation?: boolean | null;
    noteToRepresentative?: string | null;
    profileId: string;
    stageName: string;
    billingFeatures?: Array<{
      __typename: 'ArtistBillingFeatureModel';
      artistId: number;
      credit: number;
      itemCode: string;
    }> | null;
    organizations?: Array<{
      __typename: 'Organization';
      id: number;
      name?: string | null;
      isOrganization?: boolean | null;
      abbreviation?: string | null;
      parentName?: string | null;
      sytemRoleId?: number | null;
      systemRoleCode?: string | null;
      features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
      logo?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      parentOrganization?: {
        __typename: 'Organization';
        id: number;
        name?: string | null;
        logo?: {
          __typename: 'Media';
          created?: any | null;
          fileKey?: string | null;
          guid: string;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    photos?: Array<{
      __typename: 'ProfileMedia';
      profileMediaId: number;
      isPrimary: boolean;
      media: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        thumbnail?: {
          __typename: 'Media';
          created?: any | null;
          fileKey?: string | null;
          guid: string;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        } | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      };
    }> | null;
    profileBookOuts: Array<{
      __typename: 'ProfileBookOuts';
      profileBookoutId?: number | null;
      profileId?: number | null;
      startDate?: any | null;
      endDate?: any | null;
    }>;
    profileMainOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
    profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string; name: string } | null;
    profileVisiblities?: Array<{ __typename: 'ProfileVisibility'; id: string; code: string; name: string }> | null;
    seekingRepresentations?: Array<{
      __typename: 'SeekingRepresentation';
      id: string;
      code: string;
      name: string;
    }> | null;
  }> | null;
};

export type UserContextFragment = {
  __typename: 'Account';
  countryId: number;
  email: string;
  emailResetToken?: string | null;
  firstName: string;
  id: number;
  isAdmin: boolean;
  isIPC: boolean;
  isInPCContext: boolean;
  lastArtistId?: number | null;
  lastName: string;
  phone: string;
  rosterInvitationToken?: string | null;
  accountOrganization?: Array<{
    __typename: 'OrganizationInfo';
    id: number;
    name: string;
    category: Array<{ __typename: 'RepresentationCategory'; id: string; name: string; code: string }>;
  }> | null;
  accountStatus?: { __typename: 'AccountStatus'; id: string; name: string; code: string } | null;
  accountSystemRoles: Array<{ __typename: 'SystemRole'; id: string; name: string; code: string }>;
  artists?: Array<{
    __typename: 'Artist';
    id: string;
    minor: boolean;
    artistId: number;
    firstName: string;
    lastName: string;
    created: any;
    hasAugeoBenefits?: boolean | null;
    activeSubscription?: {
      __typename: 'Subscription';
      id: string;
      expirationDate?: any | null;
      trialEndsAt?: any | null;
      trialLengthDays?: number | null;
      billingInfoId?: string | null;
      status: string;
      nextBillingDate?: any | null;
      resumeAt?: any | null;
      plan: {
        __typename: 'BillingPlan';
        name: string;
        id: number;
        code: string;
        isBasePlan: boolean;
        billingCode: string;
        level: number;
        isComped: boolean;
        term: { __typename: 'BillingPlanTerm'; id: number; code: string };
      };
    } | null;
    previousSubscription?: {
      __typename: 'Subscription';
      id: string;
      expirationDate?: any | null;
      trialEndsAt?: any | null;
      trialLengthDays?: number | null;
      billingInfoId?: string | null;
      status: string;
      nextBillingDate?: any | null;
      resumeAt?: any | null;
      plan: {
        __typename: 'BillingPlan';
        name: string;
        id: number;
        code: string;
        isBasePlan: boolean;
        billingCode: string;
        level: number;
        isComped: boolean;
        term: { __typename: 'BillingPlanTerm'; id: number; code: string };
      };
    } | null;
    profiles?: Array<{
      __typename: 'Profile';
      artistId?: number | null;
      created?: any | null;
      dateOfBirth?: any | null;
      deactivatedBy?: number | null;
      isPersonal: boolean;
      isPlayableAgeLockedToTalent: boolean;
      isSeekingRepresentation?: boolean | null;
      noteToRepresentative?: string | null;
      profileId: string;
      stageName: string;
      billingFeatures?: Array<{
        __typename: 'ArtistBillingFeatureModel';
        artistId: number;
        credit: number;
        itemCode: string;
      }> | null;
      organizations?: Array<{
        __typename: 'Organization';
        id: number;
        name?: string | null;
        isOrganization?: boolean | null;
        abbreviation?: string | null;
        parentName?: string | null;
        sytemRoleId?: number | null;
        systemRoleCode?: string | null;
        features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
        logo?: {
          __typename: 'Media';
          created?: any | null;
          fileKey?: string | null;
          guid: string;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        } | null;
        parentOrganization?: {
          __typename: 'Organization';
          id: number;
          name?: string | null;
          logo?: {
            __typename: 'Media';
            created?: any | null;
            fileKey?: string | null;
            guid: string;
            id: string;
            isLocked?: boolean | null;
            mediaId: number;
            name?: string | null;
            source?: string | null;
            thumbnailUrl?: string | null;
            url?: string | null;
            fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
            mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
            mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
            mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
            transformation?: {
              __typename: 'MediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
              startOffset?: string | null;
            } | null;
          } | null;
        } | null;
      }> | null;
      photos?: Array<{
        __typename: 'ProfileMedia';
        profileMediaId: number;
        isPrimary: boolean;
        media: {
          __typename: 'Media';
          created?: any | null;
          fileKey?: string | null;
          guid: string;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          thumbnail?: {
            __typename: 'Media';
            created?: any | null;
            fileKey?: string | null;
            guid: string;
            id: string;
            isLocked?: boolean | null;
            mediaId: number;
            name?: string | null;
            source?: string | null;
            thumbnailUrl?: string | null;
            url?: string | null;
            fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
            mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
            mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
            mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
            transformation?: {
              __typename: 'MediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
              startOffset?: string | null;
            } | null;
          } | null;
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        };
      }> | null;
      profileBookOuts: Array<{
        __typename: 'ProfileBookOuts';
        profileBookoutId?: number | null;
        profileId?: number | null;
        startDate?: any | null;
        endDate?: any | null;
      }>;
      profileMainOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
      profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string; name: string } | null;
      profileVisiblities?: Array<{ __typename: 'ProfileVisibility'; id: string; code: string; name: string }> | null;
      seekingRepresentations?: Array<{
        __typename: 'SeekingRepresentation';
        id: string;
        code: string;
        name: string;
      }> | null;
    }> | null;
  }>;
  billingInfo?: {
    __typename: 'AccountBillingInfo';
    cardType?: string | null;
    lastFour?: string | null;
    cardExpirationYear?: number | null;
    cardExpirationMonth?: number | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  country?: {
    __typename: 'Country';
    id: string;
    name: string;
    code: string;
    continent: { __typename: 'Continent'; id: string; code: string };
    unitSystem: { __typename: 'UnitSystem'; id: string; code: string };
    supportingCountry?: { __typename: 'Country'; id: string; code: string } | null;
  } | null;
  divisions?: Array<{
    __typename: 'Organization';
    id: number;
    name?: string | null;
    isOrganization?: boolean | null;
    abbreviation?: string | null;
    parentName?: string | null;
    sytemRoleId?: number | null;
    systemRoleCode?: string | null;
    features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
    logo?: {
      __typename: 'Media';
      created?: any | null;
      fileKey?: string | null;
      guid: string;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
    } | null;
    parentOrganization?: {
      __typename: 'Organization';
      id: number;
      name?: string | null;
      logo?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
    } | null;
  }> | null;
  features?: Array<{ __typename: 'Feature'; id: string; code: string; enabled: boolean }> | null;
  language?: { __typename: 'LanguageInfo'; id: number; name: string; code: string; locale: string } | null;
  linkedAccountOrganization?: Array<{
    __typename: 'Organization';
    id: number;
    name?: string | null;
    contacts?: Array<{
      __typename: 'Contact';
      id: string;
      name?: string | null;
      phone: string;
      email?: string | null;
    }> | null;
    dmas?: Array<{ __typename: 'Dma'; id: string; name: string; code: string }> | null;
  }> | null;
  organizations?: Array<{
    __typename: 'Organization';
    id: number;
    name?: string | null;
    isOrganization?: boolean | null;
    abbreviation?: string | null;
    parentName?: string | null;
    sytemRoleId?: number | null;
    systemRoleCode?: string | null;
    features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
    logo?: {
      __typename: 'Media';
      created?: any | null;
      fileKey?: string | null;
      guid: string;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
    } | null;
    parentOrganization?: {
      __typename: 'Organization';
      id: number;
      name?: string | null;
      logo?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
    } | null;
  }> | null;
  preferences: {
    __typename: 'AccountPreferences';
    schedule?: {
      __typename: 'AccountSchedulePreferences';
      thumbnailType: ThumbnailType;
      showUnionStatus: boolean;
    } | null;
    projectListFilters?: {
      __typename: 'AccountProjectFiltersPreferences';
      hasRequests?: boolean | null;
      hasSelects?: boolean | null;
      hasSubmissions?: boolean | null;
      isKidsRolesOnly?: boolean | null;
      projectViewStatus?: ProjectViewFilterType | null;
      submissionDueDate?: string | null;
      castingOrganizations?: Array<{ __typename: 'Organization'; id: number; name?: string | null }> | null;
      projectRegion?: Array<{ __typename: 'Dma'; id: string; name: string }> | null;
      projectTypes?: Array<{ __typename: 'ProjectType'; id: string; name: string }> | null;
      projectUnion?: Array<{ __typename: 'TalentUnion'; id: string; name: string }> | null;
      roleTypes?: Array<{ __typename: 'RoleType'; id: string; code: string; name: string }> | null;
    } | null;
  };
  subscription?: {
    __typename: 'ActiveSubscriptionModel';
    id: string;
    plans?: Array<{
      __typename: 'PlanModel';
      id: string;
      isBasePlan?: boolean | null;
      price?: number | null;
      code?: string | null;
      billingCode?: string | null;
      level?: number | null;
      isComped?: boolean | null;
      currency?: { __typename: 'Currency'; id: string } | null;
    }> | null;
  } | null;
  timeZone?: {
    __typename: 'TimeZone';
    abbreviation?: string | null;
    code: string;
    gmtOffset: number;
    id: string;
    name?: string | null;
    standardName: string;
  } | null;
};

export type UserContextQueryVariables = Exact<{
  isTalent?: InputMaybe<Scalars['Boolean']>;
}>;

export type UserContextQuery = {
  myAccount: {
    __typename: 'Account';
    countryId: number;
    email: string;
    emailResetToken?: string | null;
    firstName: string;
    id: number;
    isAdmin: boolean;
    isIPC: boolean;
    isInPCContext: boolean;
    lastArtistId?: number | null;
    lastName: string;
    phone: string;
    rosterInvitationToken?: string | null;
    accountOrganization?: Array<{
      __typename: 'OrganizationInfo';
      id: number;
      name: string;
      category: Array<{ __typename: 'RepresentationCategory'; id: string; name: string; code: string }>;
    }> | null;
    accountStatus?: { __typename: 'AccountStatus'; id: string; name: string; code: string } | null;
    accountSystemRoles: Array<{ __typename: 'SystemRole'; id: string; name: string; code: string }>;
    artists?: Array<{
      __typename: 'Artist';
      id: string;
      minor: boolean;
      artistId: number;
      firstName: string;
      lastName: string;
      created: any;
      hasAugeoBenefits?: boolean | null;
      activeSubscription?: {
        __typename: 'Subscription';
        id: string;
        expirationDate?: any | null;
        trialEndsAt?: any | null;
        trialLengthDays?: number | null;
        billingInfoId?: string | null;
        status: string;
        nextBillingDate?: any | null;
        resumeAt?: any | null;
        plan: {
          __typename: 'BillingPlan';
          name: string;
          id: number;
          code: string;
          isBasePlan: boolean;
          billingCode: string;
          level: number;
          isComped: boolean;
          term: { __typename: 'BillingPlanTerm'; id: number; code: string };
        };
      } | null;
      previousSubscription?: {
        __typename: 'Subscription';
        id: string;
        expirationDate?: any | null;
        trialEndsAt?: any | null;
        trialLengthDays?: number | null;
        billingInfoId?: string | null;
        status: string;
        nextBillingDate?: any | null;
        resumeAt?: any | null;
        plan: {
          __typename: 'BillingPlan';
          name: string;
          id: number;
          code: string;
          isBasePlan: boolean;
          billingCode: string;
          level: number;
          isComped: boolean;
          term: { __typename: 'BillingPlanTerm'; id: number; code: string };
        };
      } | null;
      profiles?: Array<{
        __typename: 'Profile';
        artistId?: number | null;
        created?: any | null;
        dateOfBirth?: any | null;
        deactivatedBy?: number | null;
        isPersonal: boolean;
        isPlayableAgeLockedToTalent: boolean;
        isSeekingRepresentation?: boolean | null;
        noteToRepresentative?: string | null;
        profileId: string;
        stageName: string;
        billingFeatures?: Array<{
          __typename: 'ArtistBillingFeatureModel';
          artistId: number;
          credit: number;
          itemCode: string;
        }> | null;
        organizations?: Array<{
          __typename: 'Organization';
          id: number;
          name?: string | null;
          isOrganization?: boolean | null;
          abbreviation?: string | null;
          parentName?: string | null;
          sytemRoleId?: number | null;
          systemRoleCode?: string | null;
          features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
          logo?: {
            __typename: 'Media';
            created?: any | null;
            fileKey?: string | null;
            guid: string;
            id: string;
            isLocked?: boolean | null;
            mediaId: number;
            name?: string | null;
            source?: string | null;
            thumbnailUrl?: string | null;
            url?: string | null;
            fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
            mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
            mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
            mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
            transformation?: {
              __typename: 'MediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
              startOffset?: string | null;
            } | null;
          } | null;
          parentOrganization?: {
            __typename: 'Organization';
            id: number;
            name?: string | null;
            logo?: {
              __typename: 'Media';
              created?: any | null;
              fileKey?: string | null;
              guid: string;
              id: string;
              isLocked?: boolean | null;
              mediaId: number;
              name?: string | null;
              source?: string | null;
              thumbnailUrl?: string | null;
              url?: string | null;
              fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
              mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
              mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
              mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
              transformation?: {
                __typename: 'MediaTransformation';
                xAxis?: number | null;
                yAxis?: number | null;
                width?: number | null;
                height?: number | null;
                rotate?: number | null;
                startOffset?: string | null;
              } | null;
            } | null;
          } | null;
        }> | null;
        photos?: Array<{
          __typename: 'ProfileMedia';
          profileMediaId: number;
          isPrimary: boolean;
          media: {
            __typename: 'Media';
            created?: any | null;
            fileKey?: string | null;
            guid: string;
            id: string;
            isLocked?: boolean | null;
            mediaId: number;
            name?: string | null;
            source?: string | null;
            thumbnailUrl?: string | null;
            url?: string | null;
            thumbnail?: {
              __typename: 'Media';
              created?: any | null;
              fileKey?: string | null;
              guid: string;
              id: string;
              isLocked?: boolean | null;
              mediaId: number;
              name?: string | null;
              source?: string | null;
              thumbnailUrl?: string | null;
              url?: string | null;
              fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
              mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
              mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
              mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
              transformation?: {
                __typename: 'MediaTransformation';
                xAxis?: number | null;
                yAxis?: number | null;
                width?: number | null;
                height?: number | null;
                rotate?: number | null;
                startOffset?: string | null;
              } | null;
            } | null;
            fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
            mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
            mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
            mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
            transformation?: {
              __typename: 'MediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
              startOffset?: string | null;
            } | null;
          };
        }> | null;
        profileBookOuts: Array<{
          __typename: 'ProfileBookOuts';
          profileBookoutId?: number | null;
          profileId?: number | null;
          startDate?: any | null;
          endDate?: any | null;
        }>;
        profileMainOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
        profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string; name: string } | null;
        profileVisiblities?: Array<{ __typename: 'ProfileVisibility'; id: string; code: string; name: string }> | null;
        seekingRepresentations?: Array<{
          __typename: 'SeekingRepresentation';
          id: string;
          code: string;
          name: string;
        }> | null;
      }> | null;
    }>;
    billingInfo?: {
      __typename: 'AccountBillingInfo';
      cardType?: string | null;
      lastFour?: string | null;
      cardExpirationYear?: number | null;
      cardExpirationMonth?: number | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    country?: {
      __typename: 'Country';
      id: string;
      name: string;
      code: string;
      continent: { __typename: 'Continent'; id: string; code: string };
      unitSystem: { __typename: 'UnitSystem'; id: string; code: string };
      supportingCountry?: { __typename: 'Country'; id: string; code: string } | null;
    } | null;
    divisions?: Array<{
      __typename: 'Organization';
      id: number;
      name?: string | null;
      isOrganization?: boolean | null;
      abbreviation?: string | null;
      parentName?: string | null;
      sytemRoleId?: number | null;
      systemRoleCode?: string | null;
      features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
      logo?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      parentOrganization?: {
        __typename: 'Organization';
        id: number;
        name?: string | null;
        logo?: {
          __typename: 'Media';
          created?: any | null;
          fileKey?: string | null;
          guid: string;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    features?: Array<{ __typename: 'Feature'; id: string; code: string; enabled: boolean }> | null;
    language?: { __typename: 'LanguageInfo'; id: number; name: string; code: string; locale: string } | null;
    linkedAccountOrganization?: Array<{
      __typename: 'Organization';
      id: number;
      name?: string | null;
      contacts?: Array<{
        __typename: 'Contact';
        id: string;
        name?: string | null;
        phone: string;
        email?: string | null;
      }> | null;
      dmas?: Array<{ __typename: 'Dma'; id: string; name: string; code: string }> | null;
    }> | null;
    organizations?: Array<{
      __typename: 'Organization';
      id: number;
      name?: string | null;
      isOrganization?: boolean | null;
      abbreviation?: string | null;
      parentName?: string | null;
      sytemRoleId?: number | null;
      systemRoleCode?: string | null;
      features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
      logo?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      parentOrganization?: {
        __typename: 'Organization';
        id: number;
        name?: string | null;
        logo?: {
          __typename: 'Media';
          created?: any | null;
          fileKey?: string | null;
          guid: string;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    preferences: {
      __typename: 'AccountPreferences';
      schedule?: {
        __typename: 'AccountSchedulePreferences';
        thumbnailType: ThumbnailType;
        showUnionStatus: boolean;
      } | null;
      projectListFilters?: {
        __typename: 'AccountProjectFiltersPreferences';
        hasRequests?: boolean | null;
        hasSelects?: boolean | null;
        hasSubmissions?: boolean | null;
        isKidsRolesOnly?: boolean | null;
        projectViewStatus?: ProjectViewFilterType | null;
        submissionDueDate?: string | null;
        castingOrganizations?: Array<{ __typename: 'Organization'; id: number; name?: string | null }> | null;
        projectRegion?: Array<{ __typename: 'Dma'; id: string; name: string }> | null;
        projectTypes?: Array<{ __typename: 'ProjectType'; id: string; name: string }> | null;
        projectUnion?: Array<{ __typename: 'TalentUnion'; id: string; name: string }> | null;
        roleTypes?: Array<{ __typename: 'RoleType'; id: string; code: string; name: string }> | null;
      } | null;
    };
    subscription?: {
      __typename: 'ActiveSubscriptionModel';
      id: string;
      plans?: Array<{
        __typename: 'PlanModel';
        id: string;
        isBasePlan?: boolean | null;
        price?: number | null;
        code?: string | null;
        billingCode?: string | null;
        level?: number | null;
        isComped?: boolean | null;
        currency?: { __typename: 'Currency'; id: string } | null;
      }> | null;
    } | null;
    timeZone?: {
      __typename: 'TimeZone';
      abbreviation?: string | null;
      code: string;
      gmtOffset: number;
      id: string;
      name?: string | null;
      standardName: string;
    } | null;
  };
};

export type UpdateAccountMutationVariables = Exact<{
  accountId: Scalars['Float'];
  input: UpdateAccountInput;
  isTalent?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateAccountMutation = {
  updateAccount: {
    __typename: 'Account';
    countryId: number;
    email: string;
    emailResetToken?: string | null;
    firstName: string;
    id: number;
    isAdmin: boolean;
    isIPC: boolean;
    isInPCContext: boolean;
    lastArtistId?: number | null;
    lastName: string;
    phone: string;
    rosterInvitationToken?: string | null;
    accountOrganization?: Array<{
      __typename: 'OrganizationInfo';
      id: number;
      name: string;
      category: Array<{ __typename: 'RepresentationCategory'; id: string; name: string; code: string }>;
    }> | null;
    accountStatus?: { __typename: 'AccountStatus'; id: string; name: string; code: string } | null;
    accountSystemRoles: Array<{ __typename: 'SystemRole'; id: string; name: string; code: string }>;
    artists?: Array<{
      __typename: 'Artist';
      id: string;
      minor: boolean;
      artistId: number;
      firstName: string;
      lastName: string;
      created: any;
      hasAugeoBenefits?: boolean | null;
      activeSubscription?: {
        __typename: 'Subscription';
        id: string;
        expirationDate?: any | null;
        trialEndsAt?: any | null;
        trialLengthDays?: number | null;
        billingInfoId?: string | null;
        status: string;
        nextBillingDate?: any | null;
        resumeAt?: any | null;
        plan: {
          __typename: 'BillingPlan';
          name: string;
          id: number;
          code: string;
          isBasePlan: boolean;
          billingCode: string;
          level: number;
          isComped: boolean;
          term: { __typename: 'BillingPlanTerm'; id: number; code: string };
        };
      } | null;
      previousSubscription?: {
        __typename: 'Subscription';
        id: string;
        expirationDate?: any | null;
        trialEndsAt?: any | null;
        trialLengthDays?: number | null;
        billingInfoId?: string | null;
        status: string;
        nextBillingDate?: any | null;
        resumeAt?: any | null;
        plan: {
          __typename: 'BillingPlan';
          name: string;
          id: number;
          code: string;
          isBasePlan: boolean;
          billingCode: string;
          level: number;
          isComped: boolean;
          term: { __typename: 'BillingPlanTerm'; id: number; code: string };
        };
      } | null;
      profiles?: Array<{
        __typename: 'Profile';
        artistId?: number | null;
        created?: any | null;
        dateOfBirth?: any | null;
        deactivatedBy?: number | null;
        isPersonal: boolean;
        isPlayableAgeLockedToTalent: boolean;
        isSeekingRepresentation?: boolean | null;
        noteToRepresentative?: string | null;
        profileId: string;
        stageName: string;
        billingFeatures?: Array<{
          __typename: 'ArtistBillingFeatureModel';
          artistId: number;
          credit: number;
          itemCode: string;
        }> | null;
        organizations?: Array<{
          __typename: 'Organization';
          id: number;
          name?: string | null;
          isOrganization?: boolean | null;
          abbreviation?: string | null;
          parentName?: string | null;
          sytemRoleId?: number | null;
          systemRoleCode?: string | null;
          features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
          logo?: {
            __typename: 'Media';
            created?: any | null;
            fileKey?: string | null;
            guid: string;
            id: string;
            isLocked?: boolean | null;
            mediaId: number;
            name?: string | null;
            source?: string | null;
            thumbnailUrl?: string | null;
            url?: string | null;
            fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
            mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
            mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
            mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
            transformation?: {
              __typename: 'MediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
              startOffset?: string | null;
            } | null;
          } | null;
          parentOrganization?: {
            __typename: 'Organization';
            id: number;
            name?: string | null;
            logo?: {
              __typename: 'Media';
              created?: any | null;
              fileKey?: string | null;
              guid: string;
              id: string;
              isLocked?: boolean | null;
              mediaId: number;
              name?: string | null;
              source?: string | null;
              thumbnailUrl?: string | null;
              url?: string | null;
              fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
              mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
              mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
              mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
              transformation?: {
                __typename: 'MediaTransformation';
                xAxis?: number | null;
                yAxis?: number | null;
                width?: number | null;
                height?: number | null;
                rotate?: number | null;
                startOffset?: string | null;
              } | null;
            } | null;
          } | null;
        }> | null;
        photos?: Array<{
          __typename: 'ProfileMedia';
          profileMediaId: number;
          isPrimary: boolean;
          media: {
            __typename: 'Media';
            created?: any | null;
            fileKey?: string | null;
            guid: string;
            id: string;
            isLocked?: boolean | null;
            mediaId: number;
            name?: string | null;
            source?: string | null;
            thumbnailUrl?: string | null;
            url?: string | null;
            thumbnail?: {
              __typename: 'Media';
              created?: any | null;
              fileKey?: string | null;
              guid: string;
              id: string;
              isLocked?: boolean | null;
              mediaId: number;
              name?: string | null;
              source?: string | null;
              thumbnailUrl?: string | null;
              url?: string | null;
              fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
              mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
              mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
              mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
              transformation?: {
                __typename: 'MediaTransformation';
                xAxis?: number | null;
                yAxis?: number | null;
                width?: number | null;
                height?: number | null;
                rotate?: number | null;
                startOffset?: string | null;
              } | null;
            } | null;
            fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
            mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
            mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
            mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
            transformation?: {
              __typename: 'MediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
              startOffset?: string | null;
            } | null;
          };
        }> | null;
        profileBookOuts: Array<{
          __typename: 'ProfileBookOuts';
          profileBookoutId?: number | null;
          profileId?: number | null;
          startDate?: any | null;
          endDate?: any | null;
        }>;
        profileMainOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
        profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string; name: string } | null;
        profileVisiblities?: Array<{ __typename: 'ProfileVisibility'; id: string; code: string; name: string }> | null;
        seekingRepresentations?: Array<{
          __typename: 'SeekingRepresentation';
          id: string;
          code: string;
          name: string;
        }> | null;
      }> | null;
    }>;
    billingInfo?: {
      __typename: 'AccountBillingInfo';
      cardType?: string | null;
      lastFour?: string | null;
      cardExpirationYear?: number | null;
      cardExpirationMonth?: number | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    country?: {
      __typename: 'Country';
      id: string;
      name: string;
      code: string;
      continent: { __typename: 'Continent'; id: string; code: string };
      unitSystem: { __typename: 'UnitSystem'; id: string; code: string };
      supportingCountry?: { __typename: 'Country'; id: string; code: string } | null;
    } | null;
    divisions?: Array<{
      __typename: 'Organization';
      id: number;
      name?: string | null;
      isOrganization?: boolean | null;
      abbreviation?: string | null;
      parentName?: string | null;
      sytemRoleId?: number | null;
      systemRoleCode?: string | null;
      features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
      logo?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      parentOrganization?: {
        __typename: 'Organization';
        id: number;
        name?: string | null;
        logo?: {
          __typename: 'Media';
          created?: any | null;
          fileKey?: string | null;
          guid: string;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    features?: Array<{ __typename: 'Feature'; id: string; code: string; enabled: boolean }> | null;
    language?: { __typename: 'LanguageInfo'; id: number; name: string; code: string; locale: string } | null;
    linkedAccountOrganization?: Array<{
      __typename: 'Organization';
      id: number;
      name?: string | null;
      contacts?: Array<{
        __typename: 'Contact';
        id: string;
        name?: string | null;
        phone: string;
        email?: string | null;
      }> | null;
      dmas?: Array<{ __typename: 'Dma'; id: string; name: string; code: string }> | null;
    }> | null;
    organizations?: Array<{
      __typename: 'Organization';
      id: number;
      name?: string | null;
      isOrganization?: boolean | null;
      abbreviation?: string | null;
      parentName?: string | null;
      sytemRoleId?: number | null;
      systemRoleCode?: string | null;
      features?: Array<{ __typename: 'Feature'; id: string; code: string; name: string; enabled: boolean }> | null;
      logo?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      parentOrganization?: {
        __typename: 'Organization';
        id: number;
        name?: string | null;
        logo?: {
          __typename: 'Media';
          created?: any | null;
          fileKey?: string | null;
          guid: string;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    preferences: {
      __typename: 'AccountPreferences';
      schedule?: {
        __typename: 'AccountSchedulePreferences';
        thumbnailType: ThumbnailType;
        showUnionStatus: boolean;
      } | null;
      projectListFilters?: {
        __typename: 'AccountProjectFiltersPreferences';
        hasRequests?: boolean | null;
        hasSelects?: boolean | null;
        hasSubmissions?: boolean | null;
        isKidsRolesOnly?: boolean | null;
        projectViewStatus?: ProjectViewFilterType | null;
        submissionDueDate?: string | null;
        castingOrganizations?: Array<{ __typename: 'Organization'; id: number; name?: string | null }> | null;
        projectRegion?: Array<{ __typename: 'Dma'; id: string; name: string }> | null;
        projectTypes?: Array<{ __typename: 'ProjectType'; id: string; name: string }> | null;
        projectUnion?: Array<{ __typename: 'TalentUnion'; id: string; name: string }> | null;
        roleTypes?: Array<{ __typename: 'RoleType'; id: string; code: string; name: string }> | null;
      } | null;
    };
    subscription?: {
      __typename: 'ActiveSubscriptionModel';
      id: string;
      plans?: Array<{
        __typename: 'PlanModel';
        id: string;
        isBasePlan?: boolean | null;
        price?: number | null;
        code?: string | null;
        billingCode?: string | null;
        level?: number | null;
        isComped?: boolean | null;
        currency?: { __typename: 'Currency'; id: string } | null;
      }> | null;
    } | null;
    timeZone?: {
      __typename: 'TimeZone';
      abbreviation?: string | null;
      code: string;
      gmtOffset: number;
      id: string;
      name?: string | null;
      standardName: string;
    } | null;
  };
};

export type UpdatePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}>;

export type UpdatePasswordMutation = { updatePassword: string };

export type BaseMediaFragment = {
  __typename: 'Media';
  fileKey?: string | null;
  guid: string;
  height?: number | null;
  id: string;
  isLocked?: boolean | null;
  mediaId: number;
  name?: string | null;
  size?: number | null;
  source?: string | null;
  thumbnailUrl?: string | null;
  url?: string | null;
  width?: number | null;
  transformation?: {
    __typename: 'MediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
    startOffset?: string | null;
  } | null;
  mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
  fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
  mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
  mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
};

export type MediaAssetOnProfileFragment = {
  __typename: 'ProfileMedia';
  created: any;
  id: string;
  isPrimary: boolean;
  order: number;
  profileMediaId: number;
  media: {
    __typename: 'Media';
    created?: any | null;
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  };
};

export type MediaAssetOnSubmissionFragment = {
  __typename: 'SubmissionMedia';
  fileKey?: string | null;
  filename?: string | null;
  guid: string;
  id: string;
  isLocked?: boolean | null;
  isPrimary: boolean;
  mediaId: number;
  name?: string | null;
  orderOfAppearance: number;
  fileType: { __typename: 'MediaFileType'; code: string; id: string; name: string };
  mediaType?: { __typename: 'MediaType'; code: string; id: string; name: string } | null;
  mediaStatus: { __typename: 'MediaStatus'; code: string; name: string; id: string };
  mediaStorageStatus: { __typename: 'MediaStorageStatus'; code: string; id: string; name: string };
};

export type BaseMediaWithThumbnailFragment = {
  __typename: 'Media';
  fileKey?: string | null;
  guid: string;
  height?: number | null;
  id: string;
  isLocked?: boolean | null;
  mediaId: number;
  name?: string | null;
  size?: number | null;
  source?: string | null;
  thumbnailUrl?: string | null;
  url?: string | null;
  width?: number | null;
  thumbnail?: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  } | null;
  transformation?: {
    __typename: 'MediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
    startOffset?: string | null;
  } | null;
  mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
  fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
  mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
  mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
};

export type BaseballCardProfileFragment = {
  id: string;
  dateOfBirth?: any | null;
  isActive: boolean;
  minor?: boolean | null;
  profileId: string;
  stageName: string;
  hasAudioReel?: boolean | null;
  hasResume?: boolean | null;
  hasVideoReel?: boolean | null;
  cover?: {
    id: string;
    fileKey?: string | null;
    transformation?: {
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
      width?: number | null;
      xAxis?: number | null;
      yAxis?: number | null;
    } | null;
    thumbnail?: {
      id: string;
      fileKey?: string | null;
      transformation?: {
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
        width?: number | null;
        xAxis?: number | null;
        yAxis?: number | null;
      } | null;
    } | null;
  } | null;
  height?: {
    id: string;
    IMPERIAL_FEET_INCHES?: string | null;
    METRIC_CENTIMETERS: number;
    cm: number;
    showInImperialUI: boolean;
  } | null;
  talentUnions?: Array<{
    id: string;
    name: string;
    talentMembershipId?: string | null;
    parentObject?: { id: string; name: string } | null;
  }> | null;
  weight?: {
    id: string;
    IMPERIAL_LBS: number;
    kg: number;
    kgRounded: number;
    lbs: number;
    showInMetricUI: boolean;
  } | null;
  workingLocations?: Array<{
    id: string;
    code: string;
    name: string;
    region?: { code: string; name: string; country?: { code: string; name: string } | null } | null;
  }> | null;
};

export type BaseballCardMediaFragment = {
  id: string;
  fileKey?: string | null;
  transformation?: {
    height?: number | null;
    rotate?: number | null;
    startOffset?: string | null;
    width?: number | null;
    xAxis?: number | null;
    yAxis?: number | null;
  } | null;
  thumbnail?: {
    id: string;
    fileKey?: string | null;
    transformation?: {
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
      width?: number | null;
      xAxis?: number | null;
      yAxis?: number | null;
    } | null;
  } | null;
};

export type BaseballCardMediaTransformationFragment = {
  height?: number | null;
  rotate?: number | null;
  startOffset?: string | null;
  width?: number | null;
  xAxis?: number | null;
  yAxis?: number | null;
};

export type BaseballCardHeightFragment = {
  id: string;
  IMPERIAL_FEET_INCHES?: string | null;
  METRIC_CENTIMETERS: number;
  cm: number;
  showInImperialUI: boolean;
};

export type BaseballCardIndicatorsFragment = {
  id: string;
  hasAudioReel?: boolean | null;
  hasResume?: boolean | null;
  hasVideoReel?: boolean | null;
};

export type BaseballCardTalentUnionsFragment = {
  id: string;
  name: string;
  talentMembershipId?: string | null;
  parentObject?: { id: string; name: string } | null;
};

export type BaseballCardWeightFragment = {
  id: string;
  IMPERIAL_LBS: number;
  kg: number;
  kgRounded: number;
  lbs: number;
  showInMetricUI: boolean;
};

export type BaseballCardWorkingLocationsFragment = {
  id: string;
  code: string;
  name: string;
  region?: { code: string; name: string; country?: { code: string; name: string } | null } | null;
};

export type EnrollBenefitsMutationVariables = Exact<{
  input: EnrollBenefitsInput;
}>;

export type EnrollBenefitsMutation = { enrollBenefits: { success: boolean } };

export type CancelBenefitsMutationVariables = Exact<{
  input: CancelBenefitsInput;
}>;

export type CancelBenefitsMutation = { cancelBenefits: { success: boolean } };

export type GetNotificationNotificationSubscriptionByAccountQueryVariables = Exact<{
  accountId: Scalars['Float'];
}>;

export type GetNotificationNotificationSubscriptionByAccountQuery = {
  getNotificationNotificationSubscriptionByAccount?: Array<{
    __typename: 'NotificationTypeNotificationSubscription';
    notificationTypeId?: number | null;
    notificationNatureId?: number | null;
    notificationType?: { __typename: 'NotificationType'; id: string; code: string; name: string } | null;
    notificationNature?: { __typename: 'NotificationNature'; id: string; code: string; name: string } | null;
  }> | null;
};

export type UpdateNotificationNotificationTypeSubscriptionMutationVariables = Exact<{
  input: NotificationSubscriptionInput;
}>;

export type UpdateNotificationNotificationTypeSubscriptionMutation = {
  updateNotificationNotificationTypeSubscription: boolean;
};

export type CreateCollaboratorInviteMutationVariables = Exact<{
  input: CollaboratorInviteCreateInput;
}>;

export type CreateCollaboratorInviteMutation = { createCollaboratorInvite: string };

export type RevokeCollaboratorAccessFromOrgMutationVariables = Exact<{
  input: CollaboratorRevokeAccessFromOrgInput;
}>;

export type RevokeCollaboratorAccessFromOrgMutation = { revokeCollaboratorAccessFromOrg: boolean };

export type CollaboratorDetailsFragment = {
  __typename: 'CollaboratorsInvite';
  email: string;
  inviteId: string;
  inviteStatus: CollaboratorInviteStatusCode;
  collaborator?: {
    __typename: 'CollaboratorDetails';
    collaboratorId?: number | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  collaboratorInviteFromOrgDetails?: {
    __typename: 'CollaboratorInviteFromOrgDetails';
    orgId?: string | null;
    orgName?: string | null;
  } | null;
  collaboratorInviteProjectDetails?: {
    __typename: 'CollaboratorInviteProjectDetails';
    projectId?: number | null;
    projectName?: string | null;
  } | null;
};

export type CollaboratorsInOrganizationDetailsFragment = {
  __typename: 'CollaboratorsInOrganization';
  email?: string | null;
  collaboratorDetails?: {
    __typename: 'CollaboratorDetails';
    collaboratorId?: number | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  collaboratorsProjectDetails?: Array<{
    __typename: 'CollaboratorInviteProjectDetails';
    internalName?: string | null;
    projectId?: number | null;
    projectName?: string | null;
  }> | null;
};

export type GetCollaboratorsByProjectIdQueryVariables = Exact<{
  page?: InputMaybe<PageInput>;
  projectId?: InputMaybe<Scalars['ID']>;
}>;

export type GetCollaboratorsByProjectIdQuery = {
  getCollaboratorsByProjectId: {
    __typename: 'CollaboratorsInvitePaginatedResult';
    lastKey?: string | null;
    page: number;
    totalCount: number;
    totalPages: number;
    objects: Array<{
      __typename: 'CollaboratorsInvite';
      email: string;
      inviteId: string;
      inviteStatus: CollaboratorInviteStatusCode;
      collaborator?: {
        __typename: 'CollaboratorDetails';
        collaboratorId?: number | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      collaboratorInviteFromOrgDetails?: {
        __typename: 'CollaboratorInviteFromOrgDetails';
        orgId?: string | null;
        orgName?: string | null;
      } | null;
      collaboratorInviteProjectDetails?: {
        __typename: 'CollaboratorInviteProjectDetails';
        projectId?: number | null;
        projectName?: string | null;
      } | null;
    }>;
  };
};

export type CollaboratorByInviteIdQueryVariables = Exact<{
  input: GetCollaboratorInviteInput;
}>;

export type CollaboratorByInviteIdQuery = {
  getCollaboratorInviteById: {
    __typename: 'CollaboratorsInvite';
    email: string;
    inviteId: string;
    inviteStatus: CollaboratorInviteStatusCode;
    collaborator?: {
      __typename: 'CollaboratorDetails';
      collaboratorId?: number | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    collaboratorInviteFromOrgDetails?: {
      __typename: 'CollaboratorInviteFromOrgDetails';
      orgId?: string | null;
      orgName?: string | null;
    } | null;
    collaboratorInviteProjectDetails?: {
      __typename: 'CollaboratorInviteProjectDetails';
      projectId?: number | null;
      projectName?: string | null;
    } | null;
  };
};

export type CollaboratorsByOrganizationQueryVariables = Exact<{
  input?: InputMaybe<GetCollaboratorByEmailInput>;
  page?: InputMaybe<PageInput>;
  projectDetailsPage?: InputMaybe<PageInput>;
}>;

export type CollaboratorsByOrganizationQuery = {
  getCollaboratorsForOrganization: {
    __typename: 'CollaboratorsInOrganizationPaginatedResult';
    page: number;
    totalCount: number;
    totalPages: number;
    objects: Array<{
      __typename: 'CollaboratorsInOrganization';
      email?: string | null;
      collaboratorDetails?: {
        __typename: 'CollaboratorDetails';
        collaboratorId?: number | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      collaboratorsProjectDetails?: Array<{
        __typename: 'CollaboratorInviteProjectDetails';
        internalName?: string | null;
        projectId?: number | null;
        projectName?: string | null;
      }> | null;
    }>;
  };
};

export type RevokeCollaboratorAccessFromProjectMutationVariables = Exact<{
  input: CollaboratorRevokeAccessFromProjectInput;
}>;

export type RevokeCollaboratorAccessFromProjectMutation = { revokeCollaboratorAccessFromProject: boolean };

export type CreateCollaboratorAccountMutationVariables = Exact<{
  input: CollaboratorAccountCreateInput;
}>;

export type CreateCollaboratorAccountMutation = {
  createCollaboratorAccount: { __typename: 'AuthToken'; access: string; refresh: string };
};

export type GetCountriesWithDmaQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesWithDmaQuery = {
  countryWithDma: Array<{
    __typename: 'Country';
    id: string;
    code: string;
    name: string;
    sortOrder?: number | null;
    enabled: boolean;
    regions?: Array<{
      __typename: 'Region';
      id: string;
      code: string;
      name: string;
      sortOrder?: number | null;
      dmas: Array<{ __typename: 'Dma'; id: string; code: string; name: string }>;
    }> | null;
  }>;
};

export type CoverMediaFragment = {
  __typename: 'Media';
  isLocked?: boolean | null;
  fileKey?: string | null;
  guid: string;
  height?: number | null;
  id: string;
  mediaId: number;
  name?: string | null;
  size?: number | null;
  source?: string | null;
  thumbnailUrl?: string | null;
  url?: string | null;
  width?: number | null;
  thumbnail?: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  } | null;
  transformation?: {
    __typename: 'MediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
    startOffset?: string | null;
  } | null;
  mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
  fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
  mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
  mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
};

export type CreateCompedSubscriptionMutationVariables = Exact<{
  input: CreateCompedSubscriptionInput;
}>;

export type CreateCompedSubscriptionMutation = { createCompedSubscription: boolean };

export type CreditTypeFragment = { __typename: 'CreditType'; id: string; code: string; name: string };

export type GetCreditTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCreditTypesQuery = {
  creditTypes: Array<{ __typename: 'CreditType'; id: string; code: string; name: string }>;
};

export type CreditOnProfileFragment = {
  __typename: 'Credit';
  id: string;
  year?: number | null;
  title?: string | null;
  role?: string | null;
  director?: string | null;
  highlighted: boolean;
  sortOrder: number;
  creditOrder: number;
  profileCreditTypeId: number;
  creditType?: { __typename: 'CreditType'; id: string; code: string; name: string } | null;
  creditMedia: Array<{
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  }>;
};

export type ProfileWithCreditsFragment = {
  __typename: 'Profile';
  artistId?: number | null;
  id: string;
  profileId: string;
  stageName: string;
  hideCreditYear: boolean;
  credits: Array<{
    __typename: 'Credit';
    id: string;
    year?: number | null;
    title?: string | null;
    role?: string | null;
    director?: string | null;
    highlighted: boolean;
    sortOrder: number;
    creditOrder: number;
    profileCreditTypeId: number;
    creditType?: { __typename: 'CreditType'; id: string; code: string; name: string } | null;
    creditMedia: Array<{
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    }>;
  }>;
  mediaAssets?: Array<{
    __typename: 'ProfileMedia';
    created: any;
    id: string;
    isPrimary: boolean;
    order: number;
    profileMediaId: number;
    media: {
      __typename: 'Media';
      created?: any | null;
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    };
  }> | null;
};

export type GetProfileWithCreditsQueryVariables = Exact<{
  ids: Scalars['ID'];
}>;

export type GetProfileWithCreditsQuery = {
  profiles?: Array<{
    __typename: 'Profile';
    artistId?: number | null;
    id: string;
    profileId: string;
    stageName: string;
    hideCreditYear: boolean;
    credits: Array<{
      __typename: 'Credit';
      id: string;
      year?: number | null;
      title?: string | null;
      role?: string | null;
      director?: string | null;
      highlighted: boolean;
      sortOrder: number;
      creditOrder: number;
      profileCreditTypeId: number;
      creditType?: { __typename: 'CreditType'; id: string; code: string; name: string } | null;
      creditMedia: Array<{
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      }>;
    }>;
    mediaAssets?: Array<{
      __typename: 'ProfileMedia';
      created: any;
      id: string;
      isPrimary: boolean;
      order: number;
      profileMediaId: number;
      media: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      };
    }> | null;
  }> | null;
};

export type CreateCreditMutationVariables = Exact<{
  creditInput: CreditInput;
}>;

export type CreateCreditMutation = { createCredit: { __typename: 'Credit'; id: string } };

export type CreateArtistCreditsMutationVariables = Exact<{
  creditInput: ArtistCreditsInput;
}>;

export type CreateArtistCreditsMutation = { createArtistCredits: Array<{ __typename: 'Credit'; id: string }> };

export type UpdateCreditMutationVariables = Exact<{
  creditUpdateInput: CreditUpdateInput;
}>;

export type UpdateCreditMutation = {
  updateCredit: {
    __typename: 'Credit';
    id: string;
    year?: number | null;
    title?: string | null;
    role?: string | null;
    director?: string | null;
    highlighted: boolean;
    profileCreditTypeId: number;
    creditType?: { __typename: 'CreditType'; id: string; code: string; name: string } | null;
  };
};

export type DeleteCreditMutationVariables = Exact<{
  creditId: Scalars['Float'];
}>;

export type DeleteCreditMutation = { deleteCredit: boolean };

export type UpdateProfileCreditTypeSortOrderMutationVariables = Exact<{
  firstProfileCreditTypeId: Scalars['Float'];
  secondProfileCreditTypeId: Scalars['Float'];
}>;

export type UpdateProfileCreditTypeSortOrderMutation = { updateProfileCreditTypeSortOrder: boolean };

export type UpdateCreditOrderMutationVariables = Exact<{
  input: Array<CreditOrderInput> | CreditOrderInput;
}>;

export type UpdateCreditOrderMutation = { updateCreditOrder: boolean };

export type UpdateProfileHideCreditYearMutationVariables = Exact<{
  input: ProfileUpdateInput;
}>;

export type UpdateProfileHideCreditYearMutation = {
  updateProfile: { __typename: 'Profile'; id: string; profileId: string; hideCreditYear: boolean };
};

export type AttachMediaToCreditMutationVariables = Exact<{
  mediaIds: Array<Scalars['Float']> | Scalars['Float'];
  creditId: Scalars['Float'];
  checkPayment: Scalars['Boolean'];
}>;

export type AttachMediaToCreditMutation = {
  attachCreditVideo: Array<{ __typename: 'Media'; mediaId: number; isLocked?: boolean | null }>;
};

export type DeleteCreditMediaMutationVariables = Exact<{
  mediaId: Scalars['Float'];
  creditId: Scalars['Float'];
}>;

export type DeleteCreditMediaMutation = { deleteCreditMedia: boolean };

export type GetSlugByProfileIdQueryVariables = Exact<{
  profileId: Scalars['Int'];
  includeExpired?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetSlugByProfileIdQuery = {
  getSlugByProfileId?: {
    __typename: 'CustomSlug';
    createdDate: any;
    creationCooldownDate?: any | null;
    expiredDate?: any | null;
    id: string;
    slugName: string;
    expiredReason?: {
      __typename: 'CustomUrlSlugExpiredReason';
      enabled: boolean;
      id: string;
      name: string;
      code: string;
    } | null;
  } | null;
};

export type ExpireSlugMutationVariables = Exact<{
  profileId: Scalars['Int'];
  expiredReason: CustomSlugExpiredReasonEnum;
}>;

export type ExpireSlugMutation = { expireSlug: { __typename: 'SuccessResponse'; success: boolean } };

export type AddNewSlugMutationVariables = Exact<{
  profileId: Scalars['Int'];
  slugName: Scalars['String'];
}>;

export type AddNewSlugMutation = {
  addNewSlug: {
    __typename: 'CustomSlug';
    createdDate: any;
    creationCooldownDate?: any | null;
    expiredDate?: any | null;
    id: string;
    slugName: string;
    expiredReason?: {
      __typename: 'CustomUrlSlugExpiredReason';
      enabled: boolean;
      id: string;
      name: string;
      code: string;
    } | null;
  };
};

export type CustomSlugUrlFragment = {
  __typename: 'CustomSlug';
  createdDate: any;
  creationCooldownDate?: any | null;
  expiredDate?: any | null;
  id: string;
  slugName: string;
  expiredReason?: {
    __typename: 'CustomUrlSlugExpiredReason';
    enabled: boolean;
    id: string;
    name: string;
    code: string;
  } | null;
};

export type DeleteBlockedKeywordMutationVariables = Exact<{
  keyword: Scalars['String'];
}>;

export type DeleteBlockedKeywordMutation = { deleteBlockedKeyword: { success: boolean } };

export type DeleteMediaMutationVariables = Exact<{
  input: DeleteMediaInput;
}>;

export type DeleteMediaMutation = { deleteMedia: { __typename: 'DeleteMediaResponse'; success: boolean } };

export type DeleteMediaAssetMutationVariables = Exact<{
  mediaId: Scalars['Float'];
}>;

export type DeleteMediaAssetMutation = { deleteMediaAsset: boolean };

export type ContinentDetailsFragment = { __typename: 'Continent'; id: string; name: string; code: string };

export type CountryDetailsFragment = {
  __typename: 'Country';
  id: string;
  name: string;
  code: string;
  enabled: boolean;
  sortOrder?: number | null;
  continent: { __typename: 'Continent'; id: string; name: string; code: string };
  supportingCountry?: {
    __typename: 'Country';
    id: string;
    code: string;
    continent: { __typename: 'Continent'; id: string; name: string; code: string };
  } | null;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  countries: Array<{
    __typename: 'Country';
    id: string;
    name: string;
    code: string;
    enabled: boolean;
    sortOrder?: number | null;
    continent: { __typename: 'Continent'; id: string; name: string; code: string };
    supportingCountry?: {
      __typename: 'Country';
      id: string;
      code: string;
      continent: { __typename: 'Continent'; id: string; name: string; code: string };
    } | null;
  }>;
};

export type RegionDetailsFragment = {
  __typename: 'Region';
  id: string;
  name: string;
  code: string;
  country?: {
    __typename: 'Country';
    id: string;
    name: string;
    code: string;
    enabled: boolean;
    sortOrder?: number | null;
    continent: { __typename: 'Continent'; id: string; name: string; code: string };
    supportingCountry?: {
      __typename: 'Country';
      id: string;
      code: string;
      continent: { __typename: 'Continent'; id: string; name: string; code: string };
    } | null;
  } | null;
};

export type DmaDetailsFragment = {
  __typename: 'Dma';
  id: string;
  name: string;
  code: string;
  sortOrder?: number | null;
  enabled: boolean;
  region?: {
    __typename: 'Region';
    id: string;
    name: string;
    code: string;
    country?: {
      __typename: 'Country';
      id: string;
      name: string;
      code: string;
      enabled: boolean;
      sortOrder?: number | null;
      continent: { __typename: 'Continent'; id: string; name: string; code: string };
      supportingCountry?: {
        __typename: 'Country';
        id: string;
        code: string;
        continent: { __typename: 'Continent'; id: string; name: string; code: string };
      } | null;
    } | null;
  } | null;
};

export type GetDmasQueryVariables = Exact<{ [key: string]: never }>;

export type GetDmasQuery = {
  dmas: Array<{
    __typename: 'Dma';
    id: string;
    name: string;
    code: string;
    sortOrder?: number | null;
    enabled: boolean;
    region?: {
      __typename: 'Region';
      id: string;
      name: string;
      code: string;
      country?: {
        __typename: 'Country';
        id: string;
        name: string;
        code: string;
        enabled: boolean;
        sortOrder?: number | null;
        continent: { __typename: 'Continent'; id: string; name: string; code: string };
        supportingCountry?: {
          __typename: 'Country';
          id: string;
          code: string;
          continent: { __typename: 'Continent'; id: string; name: string; code: string };
        } | null;
      } | null;
    } | null;
  }>;
};

export type TimeZoneDetailsFragment = {
  __typename: 'TimeZone';
  id: string;
  name?: string | null;
  code: string;
  codeBrowser: string;
};

export type GetTimeZonesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTimeZonesQuery = {
  timeZones: Array<{ __typename: 'TimeZone'; id: string; name?: string | null; code: string; codeBrowser: string }>;
};

export type InitiateMediaBulkDownloadMutationVariables = Exact<{
  input: InitiateMediaBulkDownloadInput;
}>;

export type InitiateMediaBulkDownloadMutation = {
  initiateMediaBulkDownload: { __typename: 'InitiateMediaBulkDownloadResponse'; success: boolean };
};

export type EditProfileWithTalentEmailFragment = {
  __typename: 'Profile';
  id: string;
  profileId: string;
  stageName: string;
  talentEmail?: string | null;
};

export type GetProfileWithTalentEmailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProfileWithTalentEmailQuery = {
  profiles?: Array<{
    __typename: 'Profile';
    id: string;
    profileId: string;
    stageName: string;
    talentEmail?: string | null;
  }> | null;
};

export type UpdateProfileTalentEmailMutationVariables = Exact<{
  input: ProfileUpdateInput;
}>;

export type UpdateProfileTalentEmailMutation = {
  updateProfile: { __typename: 'Profile'; id: string; talentEmail?: string | null };
};

export type EmailHistoryItemFragment = {
  __typename: 'EmailHistory';
  emailType: EmailHistoryTypeEnum;
  createdAt?: any | null;
  messageToReps?: string | null;
  messageToTalents?: string | null;
  roles: Array<{ __typename: 'Role'; id: string; name: string }>;
  publicationSettings: Array<{ __typename: 'PublicationSetting'; code: string }>;
};

export type EmailHistoryQueryVariables = Exact<{
  projectId: Scalars['Int'];
  page: PageInput;
}>;

export type EmailHistoryQuery = {
  emailHistory: {
    __typename: 'EmailHistoryPaginatedResult';
    totalCount: number;
    totalPages: number;
    objects: Array<{
      __typename: 'EmailHistory';
      emailType: EmailHistoryTypeEnum;
      createdAt?: any | null;
      messageToReps?: string | null;
      messageToTalents?: string | null;
      roles: Array<{ __typename: 'Role'; id: string; name: string }>;
      publicationSettings: Array<{ __typename: 'PublicationSetting'; code: string }>;
    }>;
  };
};

export type ExpireCompedSubscriptionMutationVariables = Exact<{
  input: ExpireCompedSubscriptionInput;
}>;

export type ExpireCompedSubscriptionMutation = { expireCompedSubscription: boolean };

export type ProjectSeriesFragment = { __typename: 'ProjectSeries'; id: string; seriesName: string };

export type SearchProjectSeriesQueryVariables = Exact<{
  input: GetProjectSeriesByNameInput;
  page?: InputMaybe<PageInput>;
}>;

export type SearchProjectSeriesQuery = {
  searchProjectSeriesByOrg: {
    __typename: 'ProjectSeriesPaginatedResult';
    lastKey?: string | null;
    page: number;
    totalCount: number;
    totalPages: number;
    objects: Array<{ __typename: 'ProjectSeries'; id: string; seriesName: string }>;
  };
};

export type ProjectSessionFragment = {
  __typename: 'AuditionSession';
  id: number;
  name?: string | null;
  created: any;
  order: number;
};

export type SearchProjectSessionsQueryVariables = Exact<{
  projectId: Array<Scalars['Int']> | Scalars['Int'];
  pageInput?: InputMaybe<PageInput>;
}>;

export type SearchProjectSessionsQuery = {
  auditionSessionsByProject: {
    __typename: 'AuditionSessionPaginatedResult';
    lastKey?: string | null;
    page: number;
    totalCount: number;
    totalPages: number;
    objects: Array<{ __typename: 'AuditionSession'; id: number; name?: string | null; created: any; order: number }>;
  };
};

export type GetSavedSearchListQueryVariables = Exact<{
  limit: Scalars['Int'];
  keyOffset?: InputMaybe<Scalars['String']>;
}>;

export type GetSavedSearchListQuery = {
  savedSearchList?: {
    __typename: 'SavedSearchList';
    nextKeyOffset?: string | null;
    items: Array<{
      __typename: 'SavedSearch';
      id: string;
      name: string;
      isDefault: boolean;
      notificationType?: string | null;
      attributes: {
        __typename: 'SavedSearchAttributes';
        showRolesNoLongerAcceptingSubmissions?: boolean | null;
        roleProjectKeywordSearch?: RoleProjectKeywordSearchEnum | null;
        keywords?: string | null;
        workingLocations?: Array<{ __typename: 'Dma'; id: string; name: string }> | null;
        ethnicAppearances?: Array<{ __typename: 'EthnicAppearance'; id: string; code: string; name: string }> | null;
        genderAppearances?: Array<{ __typename: 'GenderAppearance'; id: string; code: string; name: string }> | null;
        payPreferences?: Array<{ __typename: 'PaidType'; id: string; code: string; name: string }> | null;
        roleTargets?: Array<{ __typename: 'RoleTarget'; id: string; code: string; name: string }> | null;
        projectTypes?: Array<{ __typename: 'ProjectType'; id: string; name: string }> | null;
        talentUnions?: Array<{
          __typename: 'TalentUnion';
          id: string;
          code: string;
          name: string;
          parentObject?: { __typename: 'TalentUnion'; id: string; code: string; name: string } | null;
        }> | null;
        playableAge?: {
          __typename: 'AgeRange';
          ageMax?: number | null;
          ageMin?: number | null;
          inMonths?: boolean | null;
        } | null;
      };
    }>;
  } | null;
};

export type ResetPasswordBauB21MutationVariables = Exact<{
  legacyInstance?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
}>;

export type ResetPasswordBauB21Mutation = { resetPasswordBauB21: any };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResetPasswordMutation = { resetPassword: string };

export type GetTotalFormsCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetTotalFormsCountQuery = { getForms: { __typename: 'FormGqlModelPagedList'; totalCount: number } };

export type GetFormsQueryVariables = Exact<{
  pageInput?: InputMaybe<PageInput>;
  sortInstruction?: InputMaybe<FormSortConditionInput>;
  associatedProjectsPageInput?: InputMaybe<PageInput>;
}>;

export type GetFormsQuery = {
  getForms: {
    __typename: 'FormGqlModelPagedList';
    page: number;
    totalCount: number;
    totalPages: number;
    data: Array<{
      __typename: 'FormGqlModel';
      formId: number;
      title?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      associatedProjects?: Array<{
        __typename: 'FormAssociatedProject';
        projectId: number;
        projectName: string;
      }> | null;
      createdBy?: { __typename: 'Account'; firstName: string; lastName: string } | null;
    }>;
  };
};

export type BaseFormFragment = {
  __typename: 'FormGqlModel';
  formId: number;
  title?: string | null;
  description?: string | null;
  formSchema?: any | null;
};

export type CreateFormMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  formSchema: Scalars['JSON'];
}>;

export type CreateFormMutation = {
  createForm: {
    __typename: 'CreateFormResult';
    formId?: number | null;
    error?: { __typename: 'ValidationErrorForm'; code: number; message: string } | null;
  };
};

export type GetFormQueryVariables = Exact<{
  formId: Scalars['Float'];
}>;

export type GetFormQuery = {
  getForm: {
    __typename: 'FormGqlModel';
    formId: number;
    title?: string | null;
    description?: string | null;
    formSchema?: any | null;
  };
};

export type GetFormsForMediaRequestQueryVariables = Exact<{
  pageInput?: InputMaybe<PageInput>;
  filter?: InputMaybe<FormsFilterArgs>;
}>;

export type GetFormsForMediaRequestQuery = {
  getForms: {
    __typename: 'FormGqlModelPagedList';
    page: number;
    totalCount: number;
    totalPages: number;
    data: Array<{ __typename: 'FormGqlModel'; formId: number; title?: string | null }>;
  };
};

export type GetFormResponseQueryVariables = Exact<{
  formId: Scalars['Float'];
  formResponseId?: InputMaybe<Scalars['Float']>;
}>;

export type GetFormResponseQuery = {
  getFormResponse: {
    __typename: 'FormResponseGqlModel';
    formResponseId?: any | null;
    formResponseSchema?: any | null;
    formId?: number | null;
    title?: string | null;
    description?: string | null;
    formSchema?: any | null;
  };
};

export type UpdateFormMutationVariables = Exact<{
  formId: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  formSchema?: InputMaybe<Scalars['JSON']>;
}>;

export type UpdateFormMutation = {
  updateForm: {
    __typename: 'FormGqlModel';
    formId: number;
    title?: string | null;
    description?: string | null;
    formSchema?: any | null;
  };
};

export type SaveFormResponseMutationVariables = Exact<{
  formId: Scalars['Int'];
  requestId: Scalars['String'];
  responseSchema: Scalars['JSON'];
}>;

export type SaveFormResponseMutation = { saveFormResponse: { formResponseId?: any | null } };

export type GetReportByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetReportByIdQuery = {
  getReportById?:
    | {
        __typename: 'FormsReportModel';
        id: string;
        formId: number;
        mediaRequestId: string;
        organizationId?: number | null;
        createdAt: any;
        createdBy?: number | null;
        csvFileName?: string | null;
        reportType: ReportTypeEnum;
        status: ReportStatusEnum;
        updatedAt?: any | null;
        payload?: {
          __typename: 'FormsReportPayload';
          formSchema: any;
          mediaRequestName: string;
          projectName: string;
          data: Array<{
            __typename: 'RpcFormBulkResponseItem';
            formResponseDetails: {
              __typename: 'FormBulkResponseFormResponse';
              formVersionId?: number | null;
              responseId: string;
              responseSchema: any;
            };
            profileDetails: {
              __typename: 'FormBulkResponseProfileDetails';
              id: number;
              email?: string | null;
              phone?: string | null;
              stageName: string;
              submissionPhotoUrl?: string | null;
            };
            repDetails?: {
              __typename: 'FormBulkResponseRepDetails';
              company?: string | null;
              division?: string | null;
              email?: string | null;
              phone?: string | null;
            } | null;
            roleDetails?: { __typename: 'RpcRoleInfo'; id: number; internalName?: string | null; name: string } | null;
          }>;
        } | null;
      }
    | {}
    | null;
};

export type GetReportByIdPublicQueryVariables = Exact<{
  id: Scalars['ID'];
  publicKey: Scalars['ID'];
}>;

export type GetReportByIdPublicQuery = {
  getReportByIdPublic?:
    | {
        __typename: 'FormsReportModel';
        createdAt: any;
        createdBy?: number | null;
        csvFileName?: string | null;
        formId: number;
        id: string;
        mediaRequestId: string;
        organizationId?: number | null;
        reportType: ReportTypeEnum;
        status: ReportStatusEnum;
        updatedAt?: any | null;
        payload?: {
          __typename: 'FormsReportPayload';
          formSchema: any;
          mediaRequestName: string;
          projectName: string;
          data: Array<{
            __typename: 'RpcFormBulkResponseItem';
            formResponseDetails: {
              __typename: 'FormBulkResponseFormResponse';
              formVersionId?: number | null;
              responseId: string;
              responseSchema: any;
            };
            profileDetails: {
              __typename: 'FormBulkResponseProfileDetails';
              id: number;
              email?: string | null;
              phone?: string | null;
              stageName: string;
              submissionPhotoUrl?: string | null;
            };
            repDetails?: {
              __typename: 'FormBulkResponseRepDetails';
              company?: string | null;
              division?: string | null;
              email?: string | null;
              phone?: string | null;
            } | null;
            roleDetails?: { __typename: 'RpcRoleInfo'; id: number; internalName?: string | null; name: string } | null;
          }>;
        } | null;
      }
    | {}
    | null;
};

export type CreateFormResponseReportMutationVariables = Exact<{
  formId: Scalars['Float'];
  mediaRequestId: Scalars['String'];
  sessionName?: InputMaybe<Scalars['String']>;
  presentationKey?: InputMaybe<Scalars['String']>;
}>;

export type CreateFormResponseReportMutation = { createFormResponseReport: string };

export type GetBlockedKeywordsQueryVariables = Exact<{
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Scalars['String']>;
}>;

export type GetBlockedKeywordsQuery = {
  getBlockedKeywordsPaginatedList: {
    __typename: 'BlockedKeywordPaginatedResult';
    lastKey?: string | null;
    page: number;
    totalCount: number;
    totalPages: number;
    objects: Array<{
      __typename: 'BlockedKeyword';
      id: string;
      keyword: string;
      blockedReasonNote?: string | null;
      createdDate: any;
      blockedReason: {
        __typename: 'CustomUrlSlugExpiredReason';
        enabled: boolean;
        id: string;
        name: string;
        code: string;
      };
      createdByAccount: { __typename: 'Account'; id: number; firstName: string; lastName: string };
    }>;
  };
};

export type GetBlockedKeywordsPaginatedListResponseFragment = {
  __typename: 'BlockedKeywordPaginatedResult';
  lastKey?: string | null;
  page: number;
  totalCount: number;
  totalPages: number;
  objects: Array<{
    __typename: 'BlockedKeyword';
    id: string;
    keyword: string;
    blockedReasonNote?: string | null;
    createdDate: any;
    blockedReason: {
      __typename: 'CustomUrlSlugExpiredReason';
      enabled: boolean;
      id: string;
      name: string;
      code: string;
    };
    createdByAccount: { __typename: 'Account'; id: number; firstName: string; lastName: string };
  }>;
};

export type GetUnresolvedProhibitedSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUnresolvedProhibitedSlugsQuery = {
  getUnresolvedProhibitedSlugs: {
    __typename: 'PaginatedProhibitedCustomSlug';
    lastKey?: string | null;
    page: number;
    totalCount: number;
    totalPages: number;
    objects: Array<{
      __typename: 'ProhibitedCustomSlug';
      id: string;
      slugName: string;
      profileId: string;
      createdDate: any;
      expiredDate?: any | null;
      blockedKeyword: string;
    }>;
  };
};

export type FlaggedArtistUrlsPaginatedResultFragment = {
  __typename: 'PaginatedProhibitedCustomSlug';
  lastKey?: string | null;
  page: number;
  totalCount: number;
  totalPages: number;
  objects: Array<{
    __typename: 'ProhibitedCustomSlug';
    id: string;
    slugName: string;
    profileId: string;
    createdDate: any;
    expiredDate?: any | null;
    blockedKeyword: string;
  }>;
};

export type ProhibitedCustomSlugResponseFragment = {
  __typename: 'ProhibitedCustomSlug';
  id: string;
  slugName: string;
  profileId: string;
  createdDate: any;
  expiredDate?: any | null;
  blockedKeyword: string;
};

export type GetPresentationFiltersDataQueryVariables = Exact<{
  presentationId: Scalars['Int'];
}>;

export type GetPresentationFiltersDataQuery = {
  getPresentationFiltersData: {
    __typename: 'PresentationFilterData';
    commentersAccountIds?: Array<string> | null;
    picksUser?: Array<string> | null;
    folders?: Array<{ __typename: 'PresentationFilterFolder'; id: number; name: string }> | null;
    roles?: Array<{ __typename: 'PresentationFilterRole'; id?: number | null; name: string }> | null;
  };
};

export type GetRoleChangeLogsQueryVariables = Exact<{
  projectId: Scalars['Float'];
}>;

export type GetRoleChangeLogsQuery = {
  getRoleChangeLogs: Array<{
    __typename: 'RoleChangeLog';
    id: string;
    roleId: number;
    isOpenCall: boolean;
    modifiedById: number;
    modifiedAt: any;
    role?: { __typename: 'Role'; id: string; name: string } | null;
    modifiedBy?: { __typename: 'Account'; id: number; firstName: string; lastName: string } | null;
  }>;
};

export type CreateAccountWithGoogleMutationVariables = Exact<{
  input: CreateAccountWithGoogleInput;
}>;

export type CreateAccountWithGoogleMutation = {
  createAccountWithGoogle: { __typename: 'AuthToken'; access: string; refresh: string };
};

export type LoginWithAuthProviderMutationVariables = Exact<{
  authCode: Scalars['String'];
  authProvider: ExternalAuthenticationProvider;
}>;

export type LoginWithAuthProviderMutation = {
  loginWithAuthProvider: { __typename: 'AuthToken'; access: string; refresh: string };
};

export type InviteTalentGetInvitationHistoryQueryVariables = Exact<{
  input: Array<RosterInvitationTalentHistoryFilterInput> | RosterInvitationTalentHistoryFilterInput;
}>;

export type InviteTalentGetInvitationHistoryQuery = {
  rosterTalentInvitationHistory: Array<{
    __typename: 'RosterTalentInvitation';
    id: string;
    profileId?: number | null;
    profileStatusCode?: string | null;
    name: string;
    email: string;
    status: RosterInvitationRequestStatus;
    expiredAt: any;
    createdAt: any;
  }>;
};

export type InviteTalentSendInvitationsMutationVariables = Exact<{
  input: RosterInvitationRequestInput;
}>;

export type InviteTalentSendInvitationsMutation = {
  createRosterInvitationRequest: {
    invitations: Array<{
      __typename: 'RosterTalentInvitation';
      id: string;
      email: string;
      name: string;
      status: RosterInvitationRequestStatus;
    }>;
  };
};

export type LanguageDetailsFragment = {
  __typename: 'LanguageInfo';
  id: number;
  name: string;
  locale: string;
  code: string;
};

export type GetAcctLangByLocaleQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type GetAcctLangByLocaleQuery = {
  getLanguageByLocale?: { __typename: 'LanguageInfo'; id: number; name: string; locale: string; code: string } | null;
};

export type ProfileWithManagerEmailFragment = {
  __typename: 'Profile';
  id: string;
  profileId: string;
  stageName: string;
  managerEmail?: string | null;
};

export type GetProfileWithManagerEmailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProfileWithManagerEmailQuery = {
  profiles?: Array<{
    __typename: 'Profile';
    id: string;
    profileId: string;
    stageName: string;
    managerEmail?: string | null;
  }> | null;
};

export type UpdateProfileManagerEmailMutationVariables = Exact<{
  input: ProfileUpdateInput;
}>;

export type UpdateProfileManagerEmailMutation = {
  updateProfile: { __typename: 'Profile'; id: string; managerEmail?: string | null };
};

export type UpdateMediaAssetMutationVariables = Exact<{
  input: UpdateMediaInput;
}>;

export type UpdateMediaAssetMutation = {
  updateMediaAsset: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  };
};

export type AttachMediaToProfileMutationVariables = Exact<{
  input: AttachMediaToProfileInput;
}>;

export type AttachMediaToProfileMutation = {
  attachMediaToProfile: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  };
};

export type AttachMediasToProfileMutationVariables = Exact<{
  mediaIds: Array<Scalars['Int']> | Scalars['Int'];
  profileId: Scalars['Int'];
}>;

export type AttachMediasToProfileMutation = { attachMediasToProfile: boolean };

export type UpdateProfileMediaMutationVariables = Exact<{
  profileMedia: ProfileMediaInput;
}>;

export type UpdateProfileMediaMutation = { updateProfileMedia: boolean };

export type DeleteProfileMediaMutationVariables = Exact<{
  mediaId: Scalars['Float'];
  profileId: Scalars['Float'];
}>;

export type DeleteProfileMediaMutation = { deleteProfileMedia: boolean };

export type EditMediaMutationVariables = Exact<{
  id: Scalars['Int'];
  input: MediaTransformationInput;
}>;

export type EditMediaMutation = {
  editMedia: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  };
};

export type EditMediaThumbnailMutationVariables = Exact<{
  id: Scalars['Int'];
  input: MediaTransformationInput;
}>;

export type EditMediaThumbnailMutation = {
  editMediaThumbnail: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  };
};

export type RearrangeProfileMediaMutationVariables = Exact<{
  profileMediaList: Array<ProfileMediaInput> | ProfileMediaInput;
}>;

export type RearrangeProfileMediaMutation = { updateProfileMediaList: boolean };

export type DeleteMediaV2MutationVariables = Exact<{
  input: DeleteMediaV2Input;
}>;

export type DeleteMediaV2Mutation = { deleteMediaV2: { __typename: 'DeleteMediaV2Response'; success: boolean } };

export type AttachMultipleMediaToProfileMutationVariables = Exact<{
  input: AttachMultipleMediaToProfileInput;
}>;

export type AttachMultipleMediaToProfileMutation = {
  attachMultipleMediaToProfile: Array<{
    __typename: 'ProfileMedia';
    created: any;
    id: string;
    isPrimary: boolean;
    order: number;
    profileMediaId: number;
    media: {
      __typename: 'Media';
      created?: any | null;
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    };
  }>;
};

export type MediaThumbnailFragment = {
  __typename: 'Media';
  id: string;
  height?: number | null;
  width?: number | null;
  length?: number | null;
  url?: string | null;
  fileKey?: string | null;
  guid: string;
  transformation?: {
    __typename: 'MediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
    startOffset?: string | null;
  } | null;
};

export type MediaTransformationFragment = {
  __typename: 'MediaTransformation';
  xAxis?: number | null;
  yAxis?: number | null;
  width?: number | null;
  height?: number | null;
  rotate?: number | null;
  startOffset?: string | null;
};

export type InitMediaFragment = {
  __typename: 'Media';
  guid: string;
  mediaId: number;
  filename?: string | null;
  name?: string | null;
  mediaType?: { __typename: 'MediaType'; code: string; id: string; name: string } | null;
  fileType: { __typename: 'MediaFileType'; code: string; id: string; name: string };
};

export type UploadMediaFragment = {
  __typename: 'Media';
  length?: number | null;
  filename?: string | null;
  guid: string;
  purchaseStatus?: MediaPurchaseStatus | null;
  fileKey?: string | null;
  height?: number | null;
  id: string;
  isLocked?: boolean | null;
  mediaId: number;
  name?: string | null;
  size?: number | null;
  source?: string | null;
  thumbnailUrl?: string | null;
  url?: string | null;
  width?: number | null;
  thumbnail?: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  } | null;
  transformation?: {
    __typename: 'MediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
    startOffset?: string | null;
  } | null;
  mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
  fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
  mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
  mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
};

export type InitiateMediaUploadV1MutationVariables = Exact<{
  MediaInitUploadInput: MediaInitUploadInput;
}>;

export type InitiateMediaUploadV1Mutation = {
  initMediaUpload: Array<{ __typename: 'Media'; guid: string; mediaId: number }>;
};

export type FinalizeMediaUploadV1MutationVariables = Exact<{
  MediaMetadataInput: Array<MediaMetadataInput> | MediaMetadataInput;
}>;

export type FinalizeMediaUploadV1Mutation = {
  finalizeMediaUpload: Array<{
    __typename: 'Media';
    length?: number | null;
    filename?: string | null;
    guid: string;
    purchaseStatus?: MediaPurchaseStatus | null;
    fileKey?: string | null;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    thumbnail?: {
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  }>;
};

export type CreateArtistMediaMutationVariables = Exact<{
  input: CreateArtistMediaInput;
}>;

export type CreateArtistMediaMutation = {
  createArtistMedia: {
    __typename: 'CreateArtistMediaResponse';
    media: Array<{ __typename: 'Media'; id: string; guid: string; mediaId: number }>;
  };
};

export type CompleteArtistMediaMutationVariables = Exact<{
  input: CompleteArtistMediaCreationInput;
}>;

export type CompleteArtistMediaMutation = {
  completeArtistMediaCreation: {
    __typename: 'CreateArtistMediaResponse';
    artist: {
      __typename: 'Artist';
      artistId: number;
      id: string;
      subscriptionMediaUsage?: {
        __typename: 'ArtistSubscriptionMediaEntitlement';
        available: Array<{
          __typename: 'SubscriptionMediaEntitlement';
          mediaType: string;
          count: number;
          isUnlimited: boolean;
        }>;
        entitle: Array<{
          __typename: 'SubscriptionMediaEntitlement';
          mediaType: string;
          count: number;
          isUnlimited: boolean;
        }>;
      } | null;
    };
    media: Array<{
      __typename: 'Media';
      length?: number | null;
      filename?: string | null;
      guid: string;
      purchaseStatus?: MediaPurchaseStatus | null;
      fileKey?: string | null;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      thumbnail?: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    }>;
  };
};

export type UpdateProfileMoveToArtistMutationVariables = Exact<{
  artistId: Scalars['ID'];
  profileId: Scalars['ID'];
}>;

export type UpdateProfileMoveToArtistMutation = { updateProfileMoveToArtist: { id: string; profileId: string } };

export type UpdateRoleOpenCallMutationVariables = Exact<{
  roleId: Scalars['Int'];
  isOpenCall: Scalars['Boolean'];
}>;

export type UpdateRoleOpenCallMutation = { updateRoleOpenCall?: { id: string; isOpenCall?: boolean | null } | null };

export type PaywallRemoveCouponMutationVariables = Exact<{ [key: string]: never }>;

export type PaywallRemoveCouponMutation = { removeActiveCouponRedemption: boolean };

export type PaywallSubscriptionPurchaseMutationVariables = Exact<{
  input: PurchaseSubscriptionInput;
}>;

export type PaywallSubscriptionPurchaseMutation = {
  purchaseSubscription: {
    __typename: 'SubscriptionInvoice';
    invoiceId?: string | null;
    creditsApplied: number;
    total: number;
    email: string;
    discount: number;
    currency: { __typename: 'Currency'; code: string; id: string; symbol: string };
    subscription: {
      __typename: 'Subscription';
      id: string;
      nextBillingDate?: any | null;
      expirationDate?: any | null;
      trialEndsAt?: any | null;
      plan: { name: string; billingCode: string; price: number; term: { name: string; code: string } };
    };
    paymentDetails?: { cardType?: string | null; lastFour?: string | null; paymentType?: string | null } | null;
  };
};

export type PaywallPreviewSubscriptionPurchaseQueryVariables = Exact<{
  input: PreviewSubscriptionInput;
}>;

export type PaywallPreviewSubscriptionPurchaseQuery = {
  previewSubscriptionPurchase: {
    __typename: 'SubscriptionInvoice';
    invoiceId?: string | null;
    creditsApplied: number;
    discount: number;
    total: number;
    email: string;
    coupon?: { code: string; isRedeemed: boolean; description: string } | null;
    currency: { __typename: 'Currency'; code: string; symbol: string; id: string };
    subscription: {
      canRenew: boolean;
      plan: { id: number; billingCode: string; name: string; price: number; term: { name: string; code: string } };
    };
    paymentDetails?: { lastFour?: string | null; cardType?: string | null } | null;
  };
};

export type PaywallGetBillingInfoQueryVariables = Exact<{
  input: GetBillingInfoInput;
}>;

export type PaywallGetBillingInfoQuery = {
  getBillingInfo: Array<{
    __typename: 'BillingInfo';
    id?: string | null;
    cardType?: string | null;
    lastFour?: string | null;
    paymentType?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    isBackupPaymentMethod?: boolean | null;
    isPrimaryPaymentMethod?: boolean | null;
    month?: number | null;
    postalCode?: string | null;
    year?: number | null;
  }>;
};

export type PaywallAddPaymentOptionMutationVariables = Exact<{
  input: AddBillingInfoInput;
}>;

export type PaywallAddPaymentOptionMutation = {
  addBillingInfo: {
    __typename: 'BillingInfo';
    id?: string | null;
    cardType?: string | null;
    lastFour?: string | null;
    paymentType?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    isBackupPaymentMethod?: boolean | null;
    isPrimaryPaymentMethod?: boolean | null;
    month?: number | null;
    postalCode?: string | null;
    year?: number | null;
  };
};

export type PaywallPreviewSubscriptionRenewalQueryVariables = Exact<{
  input: PreviewSubscriptionRenewalInput;
}>;

export type PaywallPreviewSubscriptionRenewalQuery = {
  previewSubscriptionRenewal: {
    __typename: 'SubscriptionRenewal';
    total: number;
    email: string;
    invoiceId?: string | null;
    lastFour: string;
    cardType: string;
    subscription: {
      __typename: 'BillingItemModel';
      id?: string | null;
      code: string;
      name?: string | null;
      price?: number | null;
    };
    currency: { __typename: 'Currency'; code: string; symbol: string; id: string };
  };
};

export type PaywallRenewSubscriptionMutationVariables = Exact<{
  input: RenewSubscriptionInput;
}>;

export type PaywallRenewSubscriptionMutation = {
  renewSubscription: {
    __typename: 'SubscriptionRenewal';
    invoiceId?: string | null;
    lastFour: string;
    cardType: string;
    email: string;
    paymentType?: string | null;
    total: number;
    currency: { __typename: 'Currency'; code: string };
    subscription: { __typename: 'BillingItemModel'; id?: string | null; code: string; name?: string | null };
  };
};

export type CreateCouponRedemptionMutationVariables = Exact<{
  input: CreateCouponRedemptionInput;
}>;

export type CreateCouponRedemptionMutation = { createCouponRedemption: boolean };

export type BillingPlansQueryVariables = Exact<{
  input?: InputMaybe<BillingPlansInput>;
}>;

export type BillingPlansQuery = {
  billingPlans: Array<{
    __typename: 'BillingPlan';
    id: number;
    code: string;
    name: string;
    price: number;
    isBasePlan: boolean;
    billingCode: string;
    trialLengthDays: number;
    level: number;
    term: { __typename: 'BillingPlanTerm'; id: number; code: string; name: string };
    currency: { __typename: 'Currency'; id: string; code: string; name: string; symbol: string };
  }>;
};

export type PreviewMediaPurchaseQueryVariables = Exact<{
  input: PurchaseMediaInput;
}>;

export type PreviewMediaPurchaseQuery = {
  previewMediaPurchase: {
    __typename: 'MediaInvoice';
    invoiceId?: string | null;
    email: string;
    discount: number;
    creditsApplied: number;
    total: number;
    items: Array<{
      __typename: 'MediaPurchase';
      price: number;
      currency: { __typename: 'Currency'; id: string; name: string; sortOrder?: number | null; symbol: string };
      media: {
        __typename: 'Media';
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        filename?: string | null;
        name?: string | null;
        url?: string | null;
        purchaseStatus?: MediaPurchaseStatus | null;
        thumbnailUrl?: string | null;
        fileKey?: string | null;
        mediaStatus: {
          __typename: 'MediaStatus';
          code: string;
          enabled: boolean;
          id: string;
          name: string;
          sortOrder?: number | null;
        };
        fileType: {
          __typename: 'MediaFileType';
          id: string;
          enabled: boolean;
          code: string;
          name: string;
          sortOrder?: number | null;
          mediaType: {
            __typename: 'MediaType';
            code: string;
            enabled: boolean;
            id: string;
            isRequestable: boolean;
            name: string;
            sortOrder?: number | null;
          };
        };
        mediaStorageStatus: { code: string; enabled: boolean; id: string; name: string; sortOrder?: number | null };
        mediaType?: {
          __typename: 'MediaType';
          code: string;
          enabled: boolean;
          id: string;
          isRequestable: boolean;
          name: string;
          sortOrder?: number | null;
        } | null;
        thumbnail?: {
          __typename: 'Media';
          thumbnail?: {
            thumbnailUrl?: string | null;
            url?: string | null;
            purchaseStatus?: MediaPurchaseStatus | null;
          } | null;
        } | null;
      };
    }>;
    currency: { __typename: 'Currency'; code: string; id: string; name: string; symbol: string };
    paymentDetails?: {
      __typename: 'PaymentDetails';
      cardType?: string | null;
      lastFour?: string | null;
      paymentType?: string | null;
    } | null;
    coupon?: { __typename: 'Coupon'; code: string; description: string; isRedeemed: boolean; state: string } | null;
  };
};

export type GetProfileContactEmailQueryVariables = Exact<{
  ids: Scalars['ID'];
}>;

export type GetProfileContactEmailQuery = {
  profiles?: Array<{ __typename: 'Profile'; profileId: string; talentEmail?: string | null }> | null;
};

export type GetAllAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAssetsQuery = {
  __typename: 'Query';
  vehicleTypes: Array<{ __typename: 'VehicleType'; id: string; code: string; name: string }>;
  vehicleTypeMakes: Array<{
    __typename: 'VehicleTypeMake';
    id: number;
    type: { __typename: 'VehicleType'; id: string; code: string; name: string };
    make: { __typename: 'VehicleMake'; id: string; code: string; name: string };
  }>;
  pets: Array<{
    __typename: 'Pet';
    id: string;
    code: string;
    name: string;
    parentObject?: { __typename: 'Pet'; id: string; code: string; name: string } | null;
  }>;
  petSizes: Array<{ __typename: 'PetSize'; id: string; code: string; name: string }>;
  props: Array<{
    __typename: 'Prop';
    id: string;
    code: string;
    name: string;
    parentObject?: { __typename: 'Prop'; id: string; code: string; name: string } | null;
  }>;
  wardrobes: Array<{
    __typename: 'Wardrobe';
    id: string;
    code: string;
    name: string;
    parentObject?: { __typename: 'Wardrobe'; id: string; code: string; name: string } | null;
  }>;
  sportEquipments: Array<{
    __typename: 'SportEquipment';
    id: string;
    code: string;
    name: string;
    parentObject?: { __typename: 'SportEquipment'; id: string; code: string; name: string } | null;
  }>;
};

export type GetProfileAssetsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProfileAssetsQuery = {
  profiles?: Array<{
    __typename: 'Profile';
    profileId: string;
    stageName: string;
    sportEquipments?: Array<{
      __typename: 'ProfileSportEquipment';
      profileSportEquipmentId?: number | null;
      id: string;
      code: string;
      name: string;
      parentObject?: { __typename: 'SportEquipment'; id: string; name: string; code: string } | null;
    }> | null;
    profileWardrobes?: Array<{
      __typename: 'ProfileWardrobe';
      profileWardrobeId?: number | null;
      id: string;
      code: string;
      name: string;
      sortOrder?: number | null;
      parentObject?: { __typename: 'Wardrobe'; id: string; name: string; code: string } | null;
    }> | null;
    props?: Array<{
      __typename: 'ProfileProp';
      profilePropId?: number | null;
      id: string;
      code: string;
      name: string;
      parentObject?: { __typename: 'Prop'; id: string; name: string; code: string } | null;
    }> | null;
    profilePets: Array<{
      __typename: 'ProfilePet';
      profilePetId?: number | null;
      id: string;
      code: string;
      name: string;
      petSize?: { __typename: 'PetSize'; id: string; code: string; name: string } | null;
      parentObject?: { __typename: 'Pet'; id: string; name: string; code: string } | null;
    }>;
    profileVehicles: Array<{
      __typename: 'ProfileVehicle';
      profileVehicleId?: number | null;
      vehicleName?: string | null;
      vehicleYear?: number | null;
      vehicleTypeMake?: {
        __typename: 'VehicleTypeMake';
        id: number;
        type: { __typename: 'VehicleType'; id: string; code: string; name: string };
        make: { __typename: 'VehicleMake'; id: string; code: string; name: string };
      } | null;
    }>;
  }> | null;
};

export type VehicleTypeMakeFieldsFragment = {
  __typename: 'VehicleTypeMake';
  id: number;
  type: { __typename: 'VehicleType'; id: string; name: string; code: string };
  make: { __typename: 'VehicleMake'; id: string; name: string; code: string };
};

export type AddProfileVehicleMutationVariables = Exact<{
  profileId: Scalars['Float'];
  input: ProfileVehicleInput;
}>;

export type AddProfileVehicleMutation = {
  addProfileVehicle: {
    __typename: 'ProfileVehicle';
    vehicleName?: string | null;
    vehicleYear?: number | null;
    vehicleTypeMake?: {
      __typename: 'VehicleTypeMake';
      id: number;
      type: { __typename: 'VehicleType'; id: string; name: string; code: string };
      make: { __typename: 'VehicleMake'; id: string; name: string; code: string };
    } | null;
  };
};

export type UpdateProfileVehicleMutationVariables = Exact<{
  profileId: Scalars['Float'];
  profileVehicleId: Scalars['Float'];
  input: UpdateProfileVehicleInput;
}>;

export type UpdateProfileVehicleMutation = {
  updateProfileVehicle: {
    __typename: 'ProfileVehicle';
    vehicleName?: string | null;
    vehicleYear?: number | null;
    vehicleTypeMake?: {
      __typename: 'VehicleTypeMake';
      id: number;
      type: { __typename: 'VehicleType'; id: string; name: string; code: string };
      make: { __typename: 'VehicleMake'; id: string; name: string; code: string };
    } | null;
  };
};

export type DeleteProfileVehicleMutationVariables = Exact<{
  profileId: Scalars['Float'];
  profileVehicleId: Scalars['Float'];
}>;

export type DeleteProfileVehicleMutation = { deleteProfileVehicle: boolean };

export type PetSizeFieldsFragment = { __typename: 'PetSize'; id: string; code: string; name: string };

export type AddProfilePetMutationVariables = Exact<{
  profileId: Scalars['Float'];
  input: ProfilePetInput;
}>;

export type AddProfilePetMutation = {
  addProfilePet: {
    __typename: 'ProfilePet';
    id: string;
    code: string;
    count?: number | null;
    petSize?: { __typename: 'PetSize'; id: string; code: string; name: string } | null;
  };
};

export type UpdateProfilePetMutationVariables = Exact<{
  profileId: Scalars['Float'];
  profilePetId: Scalars['Float'];
  input: ProfilePetInput;
}>;

export type UpdateProfilePetMutation = {
  updateProfilePet: {
    __typename: 'ProfilePet';
    id: string;
    code: string;
    count?: number | null;
    petSize?: { __typename: 'PetSize'; id: string; code: string; name: string } | null;
  };
};

export type DeleteProfilePetMutationVariables = Exact<{
  profileId: Scalars['Float'];
  profilePetId: Scalars['Float'];
}>;

export type DeleteProfilePetMutation = { deleteProfilePet: boolean };

export type AddProfilePropMutationVariables = Exact<{
  profileId: Scalars['Float'];
  propId: Scalars['Float'];
}>;

export type AddProfilePropMutation = {
  addProfileProp: {
    __typename: 'ProfileProp';
    id: string;
    code: string;
    name: string;
    childObjects: Array<{ __typename: 'Prop'; id: string; name: string; code: string }>;
  };
};

export type UpdateProfilePropMutationVariables = Exact<{
  profileId: Scalars['Float'];
  profilePropId: Scalars['Float'];
  propId: Scalars['Float'];
}>;

export type UpdateProfilePropMutation = {
  updateProfileProp: {
    __typename: 'ProfileProp';
    id: string;
    code: string;
    name: string;
    childObjects: Array<{ __typename: 'Prop'; id: string; name: string; code: string }>;
  };
};

export type DeleteProfilePropMutationVariables = Exact<{
  profileId: Scalars['Float'];
  profilePropId: Scalars['Float'];
}>;

export type DeleteProfilePropMutation = { deleteProfileProp: boolean };

export type AddProfileWardrobeMutationVariables = Exact<{
  profileId: Scalars['Float'];
  wardrobeId: Scalars['Float'];
}>;

export type AddProfileWardrobeMutation = {
  addProfileWardrobe: {
    __typename: 'ProfileWardrobe';
    id: string;
    code: string;
    name: string;
    childObjects: Array<{ __typename: 'Wardrobe'; id: string; name: string; code: string }>;
  };
};

export type UpdateProfileWardrobeMutationVariables = Exact<{
  profileId: Scalars['Float'];
  profileWardrobeId: Scalars['Float'];
  wardrobeId: Scalars['Float'];
}>;

export type UpdateProfileWardrobeMutation = {
  updateProfileWardrobe: {
    __typename: 'ProfileWardrobe';
    id: string;
    code: string;
    name: string;
    childObjects: Array<{ __typename: 'Wardrobe'; id: string; name: string; code: string }>;
  };
};

export type DeleteProfileWardrobeMutationVariables = Exact<{
  profileId: Scalars['Float'];
  profileWardrobeId: Scalars['Float'];
}>;

export type DeleteProfileWardrobeMutation = { deleteProfileWardrobe: boolean };

export type AddProfileSportsEquipmentMutationVariables = Exact<{
  profileId: Scalars['Float'];
  sportEquipmentId: Scalars['Float'];
}>;

export type AddProfileSportsEquipmentMutation = {
  addProfileSportsEquipment: {
    __typename: 'ProfileSportEquipment';
    id: string;
    code: string;
    name: string;
    childObjects: Array<{ __typename: 'SportEquipment'; id: string; name: string; code: string }>;
  };
};

export type UpdateProfileSportsEquipmentMutationVariables = Exact<{
  profileId: Scalars['Float'];
  profileSportEquipmentId: Scalars['Float'];
  sportEquipmentId: Scalars['Float'];
}>;

export type UpdateProfileSportsEquipmentMutation = {
  updateProfileSportsEquipment: {
    __typename: 'ProfileSportEquipment';
    id: string;
    code: string;
    name: string;
    childObjects: Array<{ __typename: 'SportEquipment'; id: string; name: string; code: string }>;
  };
};

export type DeleteProfileSportsEquipmentMutationVariables = Exact<{
  profileId: Scalars['Float'];
  profileSportEquipmentId: Scalars['Float'];
}>;

export type DeleteProfileSportsEquipmentMutation = { deleteProfileSportsEquipment: boolean };

export type ProfileMeasurementsFragment = {
  id: string;
  height?: {
    IMPERIAL_FEET_INCHES?: string | null;
    METRIC_CENTIMETERS: number;
    cm: number;
    id: string;
    showInImperialUI: boolean;
  } | null;
  weight?: {
    IMPERIAL_LBS: number;
    id: string;
    kg: number;
    kgRounded: number;
    lbs: number;
    showInMetricUI: boolean;
  } | null;
};

export type ProfileMeasurementsHeightFragment = {
  IMPERIAL_FEET_INCHES?: string | null;
  METRIC_CENTIMETERS: number;
  cm: number;
  id: string;
  showInImperialUI: boolean;
};

export type ProfileMeasurementsWeightFragment = {
  IMPERIAL_LBS: number;
  id: string;
  kg: number;
  kgRounded: number;
  lbs: number;
  showInMetricUI: boolean;
};

export type SetProfileResumeMutationVariables = Exact<{
  mediaId: Scalars['Int'];
  profileId: Scalars['Int'];
}>;

export type SetProfileResumeMutation = { setProfileResume: { __typename: 'Media'; mediaId: number; id: string } };

export type RemoveProfileResumeMutationVariables = Exact<{
  profileId: Scalars['Int'];
}>;

export type RemoveProfileResumeMutation = { removeProfileResume: boolean };

export type ProfileMainOrganizationAndMainDivisionFragment = {
  __typename: 'Profile';
  organization?: {
    __typename: 'Organization';
    id: number;
    phone: Array<string>;
    contacts?: Array<{
      __typename: 'Contact';
      id: string;
      mainContact: boolean;
      email?: string | null;
      phone: string;
    }> | null;
    parentOrganization?: {
      __typename: 'Organization';
      id: number;
      phone: Array<string>;
      contacts?: Array<{
        __typename: 'Contact';
        id: string;
        mainContact: boolean;
        email?: string | null;
        phone: string;
      }> | null;
    } | null;
  } | null;
  profileMainOrganization?: {
    __typename: 'Organization';
    id: number;
    name?: string | null;
    abbreviation?: string | null;
    phone: Array<string>;
    contacts?: Array<{
      __typename: 'Contact';
      id: string;
      mainContact: boolean;
      email?: string | null;
      phone: string;
    }> | null;
  } | null;
  profileMainDivision?: {
    __typename: 'Organization';
    id: number;
    name?: string | null;
    abbreviation?: string | null;
  } | null;
};

export type ProjectRolePubSettingSummaryFragment = {
  __typename: 'RoleCountOfPairedRepsAndCB';
  repsOrgs: number;
  isSharedWithCB: boolean;
};

export type ProjectRoleCompensationsFragment = {
  __typename: 'RoleCompensation';
  otherCompensation?: string | null;
  roleCompensationId: number;
  compensation: { __typename: 'Compensation'; id: string; code: string; name: string };
};

export type ProjectRoleEthnicAppFragment = { __typename: 'EthnicAppearance'; id: string; name: string; code: string };

export type ProjectRoleGenderAppFragment = { __typename: 'GenderAppearance'; id: string; name: string; code: string };

export type ProjectRoleFieldRestrictionFragment = {
  __typename: 'RoleFieldRestriction';
  fields: Array<RoleFieldsRestrictionsFieldOption>;
  restrictedFor: ProjectFieldRestrictionOption;
};

export type ProjectRolePaidTypeFragment = { __typename: 'PaidType'; id: string; name: string; code: string };

export type ProjectRoleLocationDateFragment = {
  __typename: 'RoleDateWithLocation';
  id: string;
  cityName?: string | null;
  endDate?: any | null;
  startDate?: any | null;
  roleDateType?: { __typename: 'RoleDateType'; id: string; code: string; name: string } | null;
};

export type ProjectRoleMediaItemFragment = {
  __typename: 'RoleMedia';
  id: string;
  isSides: boolean;
  name?: string | null;
  roleMediaId: number;
  mediaObject: {
    __typename: 'Media';
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    thumbnail?: {
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  };
};

export type ProjectRolePubSettingFragment = {
  __typename: 'RolePublicationSetting';
  representativeListIds: Array<string>;
  dmas?: Array<{
    __typename: 'Dma';
    id: string;
    code: string;
    name: string;
    region?: {
      __typename: 'Region';
      id: string;
      code: string;
      name: string;
      country?: { __typename: 'Country'; id: string; code: string; name: string } | null;
    } | null;
  }> | null;
  repCategories?: Array<{ __typename: 'RepresentationCategory'; id: string; code: string; name: string }> | null;
  repTypes?: Array<{ __typename: 'RepresentationType'; id: string; code: string; name: string }> | null;
  repAgeCategories?: Array<{ __typename: 'RepresentationAgeCategory'; id: string; code: string; name: string }> | null;
  publicationSetting: { __typename: 'PublicationSetting'; code: string; name: string; id: string };
};

export type ProjectRoleRatePerFragment = { __typename: 'RatePerType'; id: string; code: string; name: string };

export type ProjectRoleStatusFragment = { __typename: 'RoleStatus'; id: string; code: string; name: string };

export type ProjectRoleTypeFragment = {
  __typename: 'RoleType';
  id: string;
  code: string;
  name: string;
  projectType: { __typename: 'ProjectType'; id: string; code: string; name: string };
  roleTarget: { __typename: 'RoleTarget'; id: string; code: string; name: string };
};

export type ProjectRoleUnionFragment = { __typename: 'RoleUnion'; id: string; name: string; code: string };

export type ProjectRoleSexualSituationFragment = {
  __typename: 'SexualSituation';
  id: string;
  code: string;
  name: string;
};

export type ProjectRoleSidesVisibilityFragment = {
  __typename: 'RoleSidesVisibility';
  id: string;
  code: string;
  name: string;
};

export type ProjectRoleSkillFragment = { __typename: 'Skill'; id: string; code: string; name: string };

export type ProjectRoleSubmissionCountFragment = {
  __typename: 'RoleSubmissionCount';
  roleId: number;
  count: number;
  submissionStatus: { __typename: 'SubmissionStatus'; id: string; code: string; name: string };
};

export type ProjectRoleTimeZoneFragment = {
  __typename: 'TimeZone';
  id: string;
  abbreviation?: string | null;
  name?: string | null;
  code: string;
  standardName: string;
};

export type ProjectDetailRoleFragment = {
  __typename: 'Role';
  ageInYears: boolean;
  ageMaxMonth?: number | null;
  ageMinMonth?: number | null;
  backgroundTalentIntended?: number | null;
  closeSubmissions?: boolean | null;
  created?: any | null;
  description: string;
  displayedRoleUnion: string;
  id: string;
  internalName?: string | null;
  isOpenCall?: boolean | null;
  name: string;
  projectId: string;
  rateNote?: string | null;
  rateSummary?: string | null;
  rateType: boolean;
  requestMediaInstruction: string;
  requestMediaTypes: Array<string>;
  roleEthnicityNationality: string;
  sexualSituationDescription: string;
  spot?: string | null;
  submissionDueDate?: any | null;
  submissionInstruction: string;
  wardrobe?: string | null;
  workRequirement: string;
  countOfPairedRepsAndCB?: { __typename: 'RoleCountOfPairedRepsAndCB'; repsOrgs: number; isSharedWithCB: boolean };
  compensations?: Array<{
    __typename: 'RoleCompensation';
    otherCompensation?: string | null;
    roleCompensationId: number;
    compensation: { __typename: 'Compensation'; id: string; code: string; name: string };
  }> | null;
  ethnicAppearance: Array<{ __typename: 'EthnicAppearance'; id: string; name: string; code: string }>;
  fieldRestrictions?: Array<{
    __typename: 'RoleFieldRestriction';
    fields: Array<RoleFieldsRestrictionsFieldOption>;
    restrictedFor: ProjectFieldRestrictionOption;
  }> | null;
  genderAppearance?: Array<{ __typename: 'GenderAppearance'; id: string; name: string; code: string }> | null;
  locationDates?: Array<{
    __typename: 'RoleDateWithLocation';
    id: string;
    cityName?: string | null;
    endDate?: any | null;
    startDate?: any | null;
    roleDateType?: { __typename: 'RoleDateType'; id: string; code: string; name: string } | null;
  }> | null;
  paidType?: { __typename: 'PaidType'; id: string; name: string; code: string } | null;
  photos?: Array<{
    __typename: 'RoleMedia';
    id: string;
    isSides: boolean;
    name?: string | null;
    roleMediaId: number;
    mediaObject: {
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      thumbnail?: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    };
  }> | null;
  publicationSettings: Array<{
    __typename: 'RolePublicationSetting';
    representativeListIds: Array<string>;
    dmas?: Array<{
      __typename: 'Dma';
      id: string;
      code: string;
      name: string;
      region?: {
        __typename: 'Region';
        id: string;
        code: string;
        name: string;
        country?: { __typename: 'Country'; id: string; code: string; name: string } | null;
      } | null;
    }> | null;
    repCategories?: Array<{ __typename: 'RepresentationCategory'; id: string; code: string; name: string }> | null;
    repTypes?: Array<{ __typename: 'RepresentationType'; id: string; code: string; name: string }> | null;
    repAgeCategories?: Array<{
      __typename: 'RepresentationAgeCategory';
      id: string;
      code: string;
      name: string;
    }> | null;
    publicationSetting: { __typename: 'PublicationSetting'; code: string; name: string; id: string };
  }>;
  ratePer?: { __typename: 'RatePerType'; id: string; code: string; name: string } | null;
  roleStatus?: { __typename: 'RoleStatus'; id: string; code: string; name: string } | null;
  roleType?: {
    __typename: 'RoleType';
    id: string;
    code: string;
    name: string;
    projectType: { __typename: 'ProjectType'; id: string; code: string; name: string };
    roleTarget: { __typename: 'RoleTarget'; id: string; code: string; name: string };
  } | null;
  roleUnion?: { __typename: 'RoleUnion'; id: string; name: string; code: string } | null;
  session?: { __typename: 'AuditionSession'; id: number } | null;
  sexualSituations?: Array<{ __typename: 'SexualSituation'; id: string; code: string; name: string }> | null;
  sides?: Array<{
    __typename: 'RoleMedia';
    id: string;
    isSides: boolean;
    name?: string | null;
    roleMediaId: number;
    mediaObject: {
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      thumbnail?: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    };
  }> | null;
  sidesVisibility?: { __typename: 'RoleSidesVisibility'; id: string; code: string; name: string } | null;
  skills: Array<{ __typename: 'Skill'; id: string; code: string; name: string }>;
  submissionCount: Array<{
    __typename: 'RoleSubmissionCount';
    roleId: number;
    count: number;
    submissionStatus: { __typename: 'SubmissionStatus'; id: string; code: string; name: string };
  }>;
  timeZone?: {
    __typename: 'TimeZone';
    id: string;
    abbreviation?: string | null;
    name?: string | null;
    code: string;
    standardName: string;
  } | null;
};

export type ProjectDetailAuditionTypeFragment = { __typename: 'AuditionType'; id: string; code: string; name: string };

export type ProjectDetailFieldRestrictionsFragment = {
  __typename: 'ProjectFieldRestriction';
  fields: Array<ProjectFieldRestrictionFieldOption>;
  restrictedFor: ProjectFieldRestrictionOption;
};

export type ProjectDetailMediaFragment = {
  __typename: 'Media';
  id: string;
  mediaId: number;
  name?: string | null;
  url?: string | null;
};

export type ProjectDetailConflictsFragment = { __typename: 'Conflict'; id: string; code: string; name: string };

export type ProjectDetailRoleDefaultsFragment = {
  __typename: 'RoleDefaults';
  wardrobe?: string | null;
  workRequirements?: string | null;
  fieldRestrictions?: Array<{
    __typename: 'RoleFieldRestriction';
    fields: Array<RoleFieldsRestrictionsFieldOption>;
    restrictedFor: ProjectFieldRestrictionOption;
  }> | null;
  publicationSettings?: Array<{
    __typename: 'RoleDefaultPublicationSetting';
    publicationSetting: string;
    dmas?: Array<string> | null;
    repAgeCategories?: Array<string> | null;
    repCategories?: Array<string> | null;
    repTypes?: Array<string> | null;
    representativeListId?: number | null;
    representativeIds?: Array<number> | null;
  }> | null;
  locationDates?: Array<{
    __typename: 'RoleDefaultDateWithLocation';
    locationName?: string | null;
    dateTypeCode: string;
    startDate?: any | null;
    endDate?: any | null;
  }> | null;
};

export type ProjectDetailSeriesFragment = {
  __typename: 'SeriesDetails';
  projectSeriesEpisode?: number | null;
  projectSeriesId?: string | null;
  projectSeriesName?: string | null;
  projectSeriesSeason?: number | null;
};

export type ProjectDetailStatusFragment = { __typename: 'ProjectStatus'; id: string; code: string; name: string };

export type ProjectDetailTypeFragment = {
  __typename: 'ProjectType';
  id: string;
  code: string;
  name: string;
  parentObject?: { __typename: 'ProjectType'; id: string; code: string; name: string } | null;
};

export type ProjectDetailUnionFragment = {
  __typename: 'ProjectUnionDetails';
  contractId?: string | null;
  contractStatus?: { __typename: 'ContractStatus'; id: string; code: string; name: string } | null;
  union: {
    __typename: 'TalentUnion';
    id: string;
    code: string;
    name: string;
    parentObject?: { __typename: 'TalentUnion'; id: string; code: string; name: string } | null;
  };
};

export type ProjectDetailSidesVisibilityFragment = {
  __typename: 'ProjectSidesVisibility';
  id: string;
  code: string;
  name: string;
};

export type ProjectDetailReleaseLocationsFragment = {
  __typename: 'Dma';
  id: string;
  code: string;
  name: string;
  sortOrder?: number | null;
};

export type CastingProjectDetailFragment = {
  __typename: 'Project';
  adAgency?: string | null;
  additionalProvisions?: string | null;
  attachmentDisplayTypeId?: string | null;
  castingAssistant?: string | null;
  castingAssociate?: string | null;
  castingCompany?: string | null;
  castingDirector?: string | null;
  conflictNote?: string | null;
  contactEmail?: string | null;
  contactInfoPermissions?: boolean | null;
  contactPhone?: string | null;
  createdBy?: number | null;
  createdByTalentSystemUser?: number | null;
  director?: string | null;
  displayedProjectUnion: string;
  executiveProducer?: string | null;
  id: string;
  internalName?: string | null;
  name?: string | null;
  network?: string | null;
  notes?: string | null;
  photographer?: string | null;
  producer?: string | null;
  productionCompany?: string | null;
  projectId?: number | null;
  rate?: string | null;
  studio?: string | null;
  supportTicketNumber?: string | null;
  synopsis?: string | null;
  usage?: string | null;
  writer?: string | null;
  auditionType?: { __typename: 'AuditionType'; id: string; code: string; name: string } | null;
  fieldRestrictions?: Array<{
    __typename: 'ProjectFieldRestriction';
    fields: Array<ProjectFieldRestrictionFieldOption>;
    restrictedFor: ProjectFieldRestrictionOption;
  }> | null;
  media?: Array<{ __typename: 'Media'; id: string; mediaId: number; name?: string | null; url?: string | null }> | null;
  projectConflicts?: Array<{ __typename: 'Conflict'; id: string; code: string; name: string }> | null;
  projectSeries?: {
    __typename: 'SeriesDetails';
    projectSeriesEpisode?: number | null;
    projectSeriesId?: string | null;
    projectSeriesName?: string | null;
    projectSeriesSeason?: number | null;
  } | null;
  projectStatus?: { __typename: 'ProjectStatus'; id: string; code: string; name: string } | null;
  projectType?: {
    __typename: 'ProjectType';
    id: string;
    code: string;
    name: string;
    parentObject?: { __typename: 'ProjectType'; id: string; code: string; name: string } | null;
  } | null;
  projectUnion?: Array<{
    __typename: 'ProjectUnionDetails';
    contractId?: string | null;
    contractStatus?: { __typename: 'ContractStatus'; id: string; code: string; name: string } | null;
    union: {
      __typename: 'TalentUnion';
      id: string;
      code: string;
      name: string;
      parentObject?: { __typename: 'TalentUnion'; id: string; code: string; name: string } | null;
    };
  }> | null;
  roleDefaults?: {
    __typename: 'RoleDefaults';
    wardrobe?: string | null;
    workRequirements?: string | null;
    fieldRestrictions?: Array<{
      __typename: 'RoleFieldRestriction';
      fields: Array<RoleFieldsRestrictionsFieldOption>;
      restrictedFor: ProjectFieldRestrictionOption;
    }> | null;
    publicationSettings?: Array<{
      __typename: 'RoleDefaultPublicationSetting';
      publicationSetting: string;
      dmas?: Array<string> | null;
      repAgeCategories?: Array<string> | null;
      repCategories?: Array<string> | null;
      repTypes?: Array<string> | null;
      representativeListId?: number | null;
      representativeIds?: Array<number> | null;
    }> | null;
    locationDates?: Array<{
      __typename: 'RoleDefaultDateWithLocation';
      locationName?: string | null;
      dateTypeCode: string;
      startDate?: any | null;
      endDate?: any | null;
    }> | null;
  } | null;
  sideVisibility?: { __typename: 'ProjectSidesVisibility'; id: string; code: string; name: string } | null;
  releaseLocations?: Array<{
    __typename: 'Dma';
    id: string;
    code: string;
    name: string;
    sortOrder?: number | null;
  }> | null;
};

export type CastingProjectDetailsQueryVariables = Exact<{
  id: Scalars['Float'];
  includeFieldRestrictions?: Scalars['Boolean'];
}>;

export type CastingProjectDetailsQuery = {
  project: {
    __typename: 'Project';
    adAgency?: string | null;
    additionalProvisions?: string | null;
    attachmentDisplayTypeId?: string | null;
    castingAssistant?: string | null;
    castingAssociate?: string | null;
    castingCompany?: string | null;
    castingDirector?: string | null;
    conflictNote?: string | null;
    contactEmail?: string | null;
    contactInfoPermissions?: boolean | null;
    contactPhone?: string | null;
    createdBy?: number | null;
    createdByTalentSystemUser?: number | null;
    director?: string | null;
    displayedProjectUnion: string;
    executiveProducer?: string | null;
    id: string;
    internalName?: string | null;
    name?: string | null;
    network?: string | null;
    notes?: string | null;
    photographer?: string | null;
    producer?: string | null;
    productionCompany?: string | null;
    projectId?: number | null;
    rate?: string | null;
    studio?: string | null;
    supportTicketNumber?: string | null;
    synopsis?: string | null;
    usage?: string | null;
    writer?: string | null;
    auditionType?: { __typename: 'AuditionType'; id: string; code: string; name: string } | null;
    fieldRestrictions?: Array<{
      __typename: 'ProjectFieldRestriction';
      fields: Array<ProjectFieldRestrictionFieldOption>;
      restrictedFor: ProjectFieldRestrictionOption;
    }> | null;
    media?: Array<{
      __typename: 'Media';
      id: string;
      mediaId: number;
      name?: string | null;
      url?: string | null;
    }> | null;
    projectConflicts?: Array<{ __typename: 'Conflict'; id: string; code: string; name: string }> | null;
    projectSeries?: {
      __typename: 'SeriesDetails';
      projectSeriesEpisode?: number | null;
      projectSeriesId?: string | null;
      projectSeriesName?: string | null;
      projectSeriesSeason?: number | null;
    } | null;
    projectStatus?: { __typename: 'ProjectStatus'; id: string; code: string; name: string } | null;
    projectType?: {
      __typename: 'ProjectType';
      id: string;
      code: string;
      name: string;
      parentObject?: { __typename: 'ProjectType'; id: string; code: string; name: string } | null;
    } | null;
    projectUnion?: Array<{
      __typename: 'ProjectUnionDetails';
      contractId?: string | null;
      contractStatus?: { __typename: 'ContractStatus'; id: string; code: string; name: string } | null;
      union: {
        __typename: 'TalentUnion';
        id: string;
        code: string;
        name: string;
        parentObject?: { __typename: 'TalentUnion'; id: string; code: string; name: string } | null;
      };
    }> | null;
    roleDefaults?: {
      __typename: 'RoleDefaults';
      wardrobe?: string | null;
      workRequirements?: string | null;
      fieldRestrictions?: Array<{
        __typename: 'RoleFieldRestriction';
        fields: Array<RoleFieldsRestrictionsFieldOption>;
        restrictedFor: ProjectFieldRestrictionOption;
      }> | null;
      publicationSettings?: Array<{
        __typename: 'RoleDefaultPublicationSetting';
        publicationSetting: string;
        dmas?: Array<string> | null;
        repAgeCategories?: Array<string> | null;
        repCategories?: Array<string> | null;
        repTypes?: Array<string> | null;
        representativeListId?: number | null;
        representativeIds?: Array<number> | null;
      }> | null;
      locationDates?: Array<{
        __typename: 'RoleDefaultDateWithLocation';
        locationName?: string | null;
        dateTypeCode: string;
        startDate?: any | null;
        endDate?: any | null;
      }> | null;
    } | null;
    sideVisibility?: { __typename: 'ProjectSidesVisibility'; id: string; code: string; name: string } | null;
    releaseLocations?: Array<{
      __typename: 'Dma';
      id: string;
      code: string;
      name: string;
      sortOrder?: number | null;
    }> | null;
  };
};

export type CastingProjectRolesQueryVariables = Exact<{
  id: Scalars['Float'];
  includeFieldRestrictions: Scalars['Boolean'];
}>;

export type CastingProjectRolesQuery = {
  projectRoles: Array<{
    __typename: 'Role';
    ageInYears: boolean;
    ageMaxMonth?: number | null;
    ageMinMonth?: number | null;
    backgroundTalentIntended?: number | null;
    closeSubmissions?: boolean | null;
    created?: any | null;
    description: string;
    displayedRoleUnion: string;
    id: string;
    internalName?: string | null;
    isOpenCall?: boolean | null;
    name: string;
    projectId: string;
    rateNote?: string | null;
    rateSummary?: string | null;
    rateType: boolean;
    requestMediaInstruction: string;
    requestMediaTypes: Array<string>;
    roleEthnicityNationality: string;
    sexualSituationDescription: string;
    spot?: string | null;
    submissionDueDate?: any | null;
    submissionInstruction: string;
    wardrobe?: string | null;
    workRequirement: string;
    countOfPairedRepsAndCB?: { __typename: 'RoleCountOfPairedRepsAndCB'; repsOrgs: number; isSharedWithCB: boolean };
    compensations?: Array<{
      __typename: 'RoleCompensation';
      otherCompensation?: string | null;
      roleCompensationId: number;
      compensation: { __typename: 'Compensation'; id: string; code: string; name: string };
    }> | null;
    ethnicAppearance: Array<{ __typename: 'EthnicAppearance'; id: string; name: string; code: string }>;
    fieldRestrictions?: Array<{
      __typename: 'RoleFieldRestriction';
      fields: Array<RoleFieldsRestrictionsFieldOption>;
      restrictedFor: ProjectFieldRestrictionOption;
    }> | null;
    genderAppearance?: Array<{ __typename: 'GenderAppearance'; id: string; name: string; code: string }> | null;
    locationDates?: Array<{
      __typename: 'RoleDateWithLocation';
      id: string;
      cityName?: string | null;
      endDate?: any | null;
      startDate?: any | null;
      roleDateType?: { __typename: 'RoleDateType'; id: string; code: string; name: string } | null;
    }> | null;
    paidType?: { __typename: 'PaidType'; id: string; name: string; code: string } | null;
    photos?: Array<{
      __typename: 'RoleMedia';
      id: string;
      isSides: boolean;
      name?: string | null;
      roleMediaId: number;
      mediaObject: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        thumbnail?: {
          __typename: 'Media';
          fileKey?: string | null;
          guid: string;
          height?: number | null;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          size?: number | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          width?: number | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      };
    }> | null;
    publicationSettings: Array<{
      __typename: 'RolePublicationSetting';
      representativeListIds: Array<string>;
      dmas?: Array<{
        __typename: 'Dma';
        id: string;
        code: string;
        name: string;
        region?: {
          __typename: 'Region';
          id: string;
          code: string;
          name: string;
          country?: { __typename: 'Country'; id: string; code: string; name: string } | null;
        } | null;
      }> | null;
      repCategories?: Array<{ __typename: 'RepresentationCategory'; id: string; code: string; name: string }> | null;
      repTypes?: Array<{ __typename: 'RepresentationType'; id: string; code: string; name: string }> | null;
      repAgeCategories?: Array<{
        __typename: 'RepresentationAgeCategory';
        id: string;
        code: string;
        name: string;
      }> | null;
      publicationSetting: { __typename: 'PublicationSetting'; code: string; name: string; id: string };
    }>;
    ratePer?: { __typename: 'RatePerType'; id: string; code: string; name: string } | null;
    roleStatus?: { __typename: 'RoleStatus'; id: string; code: string; name: string } | null;
    roleType?: {
      __typename: 'RoleType';
      id: string;
      code: string;
      name: string;
      projectType: { __typename: 'ProjectType'; id: string; code: string; name: string };
      roleTarget: { __typename: 'RoleTarget'; id: string; code: string; name: string };
    } | null;
    roleUnion?: { __typename: 'RoleUnion'; id: string; name: string; code: string } | null;
    session?: { __typename: 'AuditionSession'; id: number } | null;
    sexualSituations?: Array<{ __typename: 'SexualSituation'; id: string; code: string; name: string }> | null;
    sides?: Array<{
      __typename: 'RoleMedia';
      id: string;
      isSides: boolean;
      name?: string | null;
      roleMediaId: number;
      mediaObject: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        thumbnail?: {
          __typename: 'Media';
          fileKey?: string | null;
          guid: string;
          height?: number | null;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          size?: number | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          width?: number | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      };
    }> | null;
    sidesVisibility?: { __typename: 'RoleSidesVisibility'; id: string; code: string; name: string } | null;
    skills: Array<{ __typename: 'Skill'; id: string; code: string; name: string }>;
    submissionCount: Array<{
      __typename: 'RoleSubmissionCount';
      roleId: number;
      count: number;
      submissionStatus: { __typename: 'SubmissionStatus'; id: string; code: string; name: string };
    }>;
    timeZone?: {
      __typename: 'TimeZone';
      id: string;
      abbreviation?: string | null;
      name?: string | null;
      code: string;
      standardName: string;
    } | null;
  }>;
};

export type UpdateProjectStatusMutationVariables = Exact<{
  projectId: Scalars['ID'];
  projectStatusCode: Scalars['String'];
}>;

export type UpdateProjectStatusMutation = {
  updateProject: {
    __typename: 'Project';
    id: string;
    name?: string | null;
    internalName?: string | null;
    projectStatus?: { __typename: 'ProjectStatus'; id: string; name: string; code: string } | null;
  };
};

export type SubmitPcProjectForApprovalMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type SubmitPcProjectForApprovalMutation = { submitPCProjectForApproval: boolean };

export type SetPcProjectStatusMutationVariables = Exact<{
  projectId: Scalars['ID'];
  projectStatusCode: Scalars['String'];
}>;

export type SetPcProjectStatusMutation = { setProjectStatus: boolean };

export type AttachProjectSupportTicketNumberMutationVariables = Exact<{
  input: ProjectAttachSupportTicketNumber;
}>;

export type AttachProjectSupportTicketNumberMutation = { attachProjectSupportTicketNumber: boolean };

export type SendProjectUpdateMessageToRepsMutationVariables = Exact<{
  input: ProjectNotificationToRepsInput;
}>;

export type SendProjectUpdateMessageToRepsMutation = { sendProjectUpdateMessageToReps: boolean };

export type ChangeRoleOrderMutationVariables = Exact<{
  moveBehind: Scalars['Boolean'];
  positionalRoleId: Scalars['Int'];
  roleId: Scalars['Int'];
  projectId: Scalars['Int'];
}>;

export type ChangeRoleOrderMutation = { changeRoleOrder: boolean };

export type UpdateRoleStatusMutationVariables = Exact<{
  roleId: Scalars['ID'];
  roleStatusCode: Scalars['String'];
}>;

export type UpdateRoleStatusMutation = {
  updateRole?: {
    __typename: 'Role';
    id: string;
    internalName?: string | null;
    name: string;
    roleStatus?: { __typename: 'RoleStatus'; id: string; code: string; name: string } | null;
  } | null;
};

export type ArchiveRoleMutationVariables = Exact<{
  roleIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type ArchiveRoleMutation = { archiveRole: string };

export type RestoreRoleMutationVariables = Exact<{
  roleIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type RestoreRoleMutation = { restoreRole: string };

export type DeleteRoleMutationVariables = Exact<{
  roleId: Scalars['Float'];
}>;

export type DeleteRoleMutation = { deleteRole: boolean };

export type RequestAddlSubsForRolesMutationVariables = Exact<{
  messageToTalents?: InputMaybe<Scalars['String']>;
  messageToReps?: InputMaybe<Scalars['String']>;
  roleIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type RequestAddlSubsForRolesMutation = { requestSubmissionsForRoles: boolean };

export type ProjectDetailViewMediaRequestAddTalentMutationVariables = Exact<{
  id: Scalars['ID'];
  submissionIds: Array<Scalars['Int']> | Scalars['Int'];
  searchInput?: InputMaybe<WfSubmissionSearchInput>;
  page: PageInput;
  statuses?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type ProjectDetailViewMediaRequestAddTalentMutation = {
  addTalentToMediaRequest: {
    __typename: 'MediaRequest';
    id: string;
    responses: {
      __typename: 'MediaResponsePagedList';
      page: number;
      totalCount: number;
      totalPages: number;
      data: Array<{
        __typename: 'MediaResponse';
        id: string;
        note?: string | null;
        status: MediaRequestStatus;
        sentAt?: any | null;
        repliedAt?: any | null;
        cancelledAt?: any | null;
        chatTopicKey: string;
        mediaList?: Array<{
          __typename: 'MediaResponseItem';
          id: number;
          isAdditional?: boolean | null;
          media: {
            __typename: 'WfMedia';
            guid: string;
            mediaId: number;
            fileKey?: string | null;
            thumbnailUrl?: string | null;
            name?: string | null;
            url?: string | null;
            tag?: string | null;
            id: number;
            thumbnail?: {
              __typename: 'WfMedia';
              guid: string;
              mediaId: number;
              fileKey?: string | null;
              thumbnailUrl?: string | null;
              name?: string | null;
              url?: string | null;
              tag?: string | null;
              id: number;
              mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
              fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
              mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
              mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
              transformation?: {
                __typename: 'WfMediaTransformation';
                xAxis?: number | null;
                yAxis?: number | null;
                width?: number | null;
                height?: number | null;
                rotate?: number | null;
              } | null;
            } | null;
            mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
            fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
            mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
            mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
            transformation?: {
              __typename: 'WfMediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
            } | null;
          };
        }> | null;
        submission: {
          __typename: 'WfSubmission';
          id: number;
          isOverscale?: boolean | null;
          overscaleNote?: string | null;
          isCustomized: boolean;
          note?: string | null;
          hasVideoReel?: boolean | null;
          hasAudioReel?: boolean | null;
          contactInfo?: { __typename: 'WfProfileContactInfo'; phone?: string | null; email?: string | null } | null;
          requestedMediaCount?: Array<{
            __typename: 'WfMediaCount';
            count?: number | null;
            type?: { __typename: 'WfMediaType'; id: string; code: string } | null;
          }> | null;
          cover?: {
            __typename: 'WfMedia';
            guid: string;
            mediaId: number;
            fileKey?: string | null;
            thumbnailUrl?: string | null;
            name?: string | null;
            url?: string | null;
            tag?: string | null;
            id: number;
            thumbnail?: {
              __typename: 'WfMedia';
              guid: string;
              mediaId: number;
              fileKey?: string | null;
              thumbnailUrl?: string | null;
              name?: string | null;
              url?: string | null;
              tag?: string | null;
              id: number;
              mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
              fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
              mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
              mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
              transformation?: {
                __typename: 'WfMediaTransformation';
                xAxis?: number | null;
                yAxis?: number | null;
                width?: number | null;
                height?: number | null;
                rotate?: number | null;
              } | null;
            } | null;
            mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
            fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
            mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
            mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
            transformation?: {
              __typename: 'WfMediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
            } | null;
          } | null;
          role?: {
            __typename: 'WfRole';
            id: number;
            guid?: string | null;
            name: string;
            internalName?: string | null;
            wardrobe?: string | null;
            project?: { __typename: 'WfProject'; id: number; guid?: string | null } | null;
          } | null;
          profile?: {
            __typename: 'WfProfile';
            id: number;
            guid?: string | null;
            stageName: string;
            overscaleNote?: string | null;
            isOverscaled?: boolean | null;
            minor?: boolean | null;
            dateOfBirth?: any | null;
            isPersonal: boolean;
            isRepresented?: boolean | null;
            hasAuditionHistory?: boolean | null;
            creditsCount: number;
            hasResume?: boolean | null;
            contactInfo?: { __typename: 'WfProfileContactInfo'; phone?: string | null; email?: string | null } | null;
            talentUnions: Array<{
              __typename: 'WfTalentProfileUnion';
              id: string;
              name: string;
              code: string;
              parentObject?: { __typename: 'WfTalentProfileUnion'; id: string; name: string } | null;
            }>;
            height?: { __typename: 'WfMeasurementModel'; id: string; inch: number; cm: number } | null;
            weight?: { __typename: 'WfWeight'; id: string; kg: number; lbs: number } | null;
            workingLocations: Array<{
              __typename: 'WfDma';
              id: string;
              code: string;
              name: string;
              sortOrder?: number | null;
            }>;
            notes?: Array<{
              __typename: 'WfNote';
              id: number;
              comment: string;
              created: string;
              noteModifier?: { __typename: 'WfNoteModifier'; id: string } | null;
            }> | null;
            organizations: Array<{
              __typename: 'WfOrganization';
              id: number;
              contacts?: Array<{
                __typename: 'WfContact';
                id: number;
                mainContact?: boolean | null;
                email?: string | null;
                phone?: string | null;
              }> | null;
              parentOrganization?: {
                __typename: 'WfOrganization';
                id: number;
                contacts?: Array<{
                  __typename: 'WfContact';
                  id: number;
                  mainContact?: boolean | null;
                  email?: string | null;
                  phone?: string | null;
                }> | null;
              } | null;
            }>;
            profileMainOrganization?: {
              __typename: 'WfOrganization';
              id: number;
              name?: string | null;
              abbreviation?: string | null;
            } | null;
            profileMainDivision?: {
              __typename: 'WfOrganization';
              id: number;
              name?: string | null;
              abbreviation?: string | null;
            } | null;
            lastNote?: {
              __typename: 'WfNote';
              id: number;
              comment: string;
              created: string;
              noteModifier?: { __typename: 'WfNoteModifier'; id: string; code: string } | null;
            } | null;
          } | null;
          tileSchemaValue?: { __typename: 'WfBasicSettingModel'; id: string; code: string; name: string } | null;
          lastNote?: { __typename: 'WfNote'; id: number; comment: string; created: string } | null;
        };
        chatTopic?: {
          topicKey: string;
          unreadMessageCount?: number | null;
          lastUserMessage?: { id: string } | null;
        } | null;
        formDetails?: Array<{
          __typename: 'MediaResponseFormDetails';
          formId: number;
          formTitle: string;
          formResponseId?: number | null;
        }> | null;
      }>;
    };
  };
};

export type ProjectDetailViewMediaRequestUpdateRepsMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
}>;

export type ProjectDetailViewMediaRequestUpdateRepsMessageMutation = {
  updateMediaRequestMessageForRepresentatives: { shouldResend: boolean };
};

export type ProjectDetailViewMediaRequestCancelRequestMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CancelRequestInput;
}>;

export type ProjectDetailViewMediaRequestCancelRequestMutation = { cancelRequest: boolean };

export type ProjectDetailViewMediaRequestDeleteRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectDetailViewMediaRequestDeleteRequestMutation = { deleteRequest: boolean };

export type ProjectDetailViewMediaRequestResponsesQueryVariables = Exact<{
  id: Scalars['ID'];
  page: PageInput;
  statuses?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type ProjectDetailViewMediaRequestResponsesQuery = {
  mediaRequestDetails: {
    __typename: 'MediaRequest';
    id: string;
    name: string;
    projectId: number;
    allowAdditionalMedia?: boolean | null;
    includeSides: boolean;
    submissionIds: Array<number>;
    talentInstructions?: string | null;
    talentInstructionsUpdatedAt?: any | null;
    messageForRepresentatives?: string | null;
    messageForRepresentativesUpdatedAt?: any | null;
    maxNumberOfMediaFiles: number;
    mediaTypes: Array<RequestMediaType>;
    createdAt: any;
    updatedAt?: any | null;
    shouldResend: boolean;
    dueDate?: any | null;
    dueDateTimeZone?: {
      __typename: 'WfTimeZone';
      id: string;
      abbreviation?: string | null;
      standardName: string;
    } | null;
    formDetails?: Array<{ __typename: 'FormDetails'; formId: number; title?: string | null }> | null;
    responses: {
      __typename: 'MediaResponsePagedList';
      page: number;
      totalCount: number;
      totalPages: number;
      data: Array<{
        __typename: 'MediaResponse';
        id: string;
        note?: string | null;
        status: MediaRequestStatus;
        sentAt?: any | null;
        repliedAt?: any | null;
        cancelledAt?: any | null;
        chatTopicKey: string;
        mediaList?: Array<{
          __typename: 'MediaResponseItem';
          id: number;
          isAdditional?: boolean | null;
          media: {
            __typename: 'WfMedia';
            guid: string;
            mediaId: number;
            fileKey?: string | null;
            thumbnailUrl?: string | null;
            name?: string | null;
            url?: string | null;
            tag?: string | null;
            id: number;
            thumbnail?: {
              __typename: 'WfMedia';
              guid: string;
              mediaId: number;
              fileKey?: string | null;
              thumbnailUrl?: string | null;
              name?: string | null;
              url?: string | null;
              tag?: string | null;
              id: number;
              mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
              fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
              mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
              mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
              transformation?: {
                __typename: 'WfMediaTransformation';
                xAxis?: number | null;
                yAxis?: number | null;
                width?: number | null;
                height?: number | null;
                rotate?: number | null;
              } | null;
            } | null;
            mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
            fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
            mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
            mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
            transformation?: {
              __typename: 'WfMediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
            } | null;
          };
        }> | null;
        submission: {
          __typename: 'WfSubmission';
          id: number;
          isOverscale?: boolean | null;
          overscaleNote?: string | null;
          isCustomized: boolean;
          note?: string | null;
          hasVideoReel?: boolean | null;
          hasAudioReel?: boolean | null;
          contactInfo?: { __typename: 'WfProfileContactInfo'; phone?: string | null; email?: string | null } | null;
          requestedMediaCount?: Array<{
            __typename: 'WfMediaCount';
            count?: number | null;
            type?: { __typename: 'WfMediaType'; id: string; code: string } | null;
          }> | null;
          cover?: {
            __typename: 'WfMedia';
            guid: string;
            mediaId: number;
            fileKey?: string | null;
            thumbnailUrl?: string | null;
            name?: string | null;
            url?: string | null;
            tag?: string | null;
            id: number;
            thumbnail?: {
              __typename: 'WfMedia';
              guid: string;
              mediaId: number;
              fileKey?: string | null;
              thumbnailUrl?: string | null;
              name?: string | null;
              url?: string | null;
              tag?: string | null;
              id: number;
              mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
              fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
              mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
              mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
              transformation?: {
                __typename: 'WfMediaTransformation';
                xAxis?: number | null;
                yAxis?: number | null;
                width?: number | null;
                height?: number | null;
                rotate?: number | null;
              } | null;
            } | null;
            mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
            fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
            mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
            mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
            transformation?: {
              __typename: 'WfMediaTransformation';
              xAxis?: number | null;
              yAxis?: number | null;
              width?: number | null;
              height?: number | null;
              rotate?: number | null;
            } | null;
          } | null;
          role?: {
            __typename: 'WfRole';
            id: number;
            guid?: string | null;
            name: string;
            internalName?: string | null;
            wardrobe?: string | null;
            project?: { __typename: 'WfProject'; id: number; guid?: string | null } | null;
          } | null;
          profile?: {
            __typename: 'WfProfile';
            id: number;
            guid?: string | null;
            stageName: string;
            overscaleNote?: string | null;
            isOverscaled?: boolean | null;
            minor?: boolean | null;
            dateOfBirth?: any | null;
            isPersonal: boolean;
            isRepresented?: boolean | null;
            hasAuditionHistory?: boolean | null;
            creditsCount: number;
            hasResume?: boolean | null;
            contactInfo?: { __typename: 'WfProfileContactInfo'; phone?: string | null; email?: string | null } | null;
            talentUnions: Array<{
              __typename: 'WfTalentProfileUnion';
              id: string;
              name: string;
              code: string;
              parentObject?: { __typename: 'WfTalentProfileUnion'; id: string; name: string } | null;
            }>;
            height?: { __typename: 'WfMeasurementModel'; id: string; inch: number; cm: number } | null;
            weight?: { __typename: 'WfWeight'; id: string; kg: number; lbs: number } | null;
            workingLocations: Array<{
              __typename: 'WfDma';
              id: string;
              code: string;
              name: string;
              sortOrder?: number | null;
            }>;
            notes?: Array<{
              __typename: 'WfNote';
              id: number;
              comment: string;
              created: string;
              noteModifier?: { __typename: 'WfNoteModifier'; id: string } | null;
            }> | null;
            organizations: Array<{
              __typename: 'WfOrganization';
              id: number;
              contacts?: Array<{
                __typename: 'WfContact';
                id: number;
                mainContact?: boolean | null;
                email?: string | null;
                phone?: string | null;
              }> | null;
              parentOrganization?: {
                __typename: 'WfOrganization';
                id: number;
                contacts?: Array<{
                  __typename: 'WfContact';
                  id: number;
                  mainContact?: boolean | null;
                  email?: string | null;
                  phone?: string | null;
                }> | null;
              } | null;
            }>;
            profileMainOrganization?: {
              __typename: 'WfOrganization';
              id: number;
              name?: string | null;
              abbreviation?: string | null;
            } | null;
            profileMainDivision?: {
              __typename: 'WfOrganization';
              id: number;
              name?: string | null;
              abbreviation?: string | null;
            } | null;
            lastNote?: {
              __typename: 'WfNote';
              id: number;
              comment: string;
              created: string;
              noteModifier?: { __typename: 'WfNoteModifier'; id: string; code: string } | null;
            } | null;
          } | null;
          tileSchemaValue?: { __typename: 'WfBasicSettingModel'; id: string; code: string; name: string } | null;
          lastNote?: { __typename: 'WfNote'; id: number; comment: string; created: string } | null;
        };
        chatTopic?: {
          topicKey: string;
          unreadMessageCount?: number | null;
          lastUserMessage?: { id: string } | null;
        } | null;
        formDetails?: Array<{
          __typename: 'MediaResponseFormDetails';
          formId: number;
          formTitle: string;
          formResponseId?: number | null;
        }> | null;
      }>;
    };
  };
};

export type ProjectDetailViewMediaRequestResponsePagedResultsFragment = {
  __typename: 'MediaResponsePagedList';
  page: number;
  totalCount: number;
  totalPages: number;
  data: Array<{
    __typename: 'MediaResponse';
    id: string;
    note?: string | null;
    status: MediaRequestStatus;
    sentAt?: any | null;
    repliedAt?: any | null;
    cancelledAt?: any | null;
    chatTopicKey: string;
    mediaList?: Array<{
      __typename: 'MediaResponseItem';
      id: number;
      isAdditional?: boolean | null;
      media: {
        __typename: 'WfMedia';
        guid: string;
        mediaId: number;
        fileKey?: string | null;
        thumbnailUrl?: string | null;
        name?: string | null;
        url?: string | null;
        tag?: string | null;
        id: number;
        thumbnail?: {
          __typename: 'WfMedia';
          guid: string;
          mediaId: number;
          fileKey?: string | null;
          thumbnailUrl?: string | null;
          name?: string | null;
          url?: string | null;
          tag?: string | null;
          id: number;
          mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
          fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
          mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
          mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
          transformation?: {
            __typename: 'WfMediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
          } | null;
        } | null;
        mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
        fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
        mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
        mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
        transformation?: {
          __typename: 'WfMediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
        } | null;
      };
    }> | null;
    submission: {
      __typename: 'WfSubmission';
      id: number;
      isOverscale?: boolean | null;
      overscaleNote?: string | null;
      isCustomized: boolean;
      note?: string | null;
      hasVideoReel?: boolean | null;
      hasAudioReel?: boolean | null;
      contactInfo?: { __typename: 'WfProfileContactInfo'; phone?: string | null; email?: string | null } | null;
      requestedMediaCount?: Array<{
        __typename: 'WfMediaCount';
        count?: number | null;
        type?: { __typename: 'WfMediaType'; id: string; code: string } | null;
      }> | null;
      cover?: {
        __typename: 'WfMedia';
        guid: string;
        mediaId: number;
        fileKey?: string | null;
        thumbnailUrl?: string | null;
        name?: string | null;
        url?: string | null;
        tag?: string | null;
        id: number;
        thumbnail?: {
          __typename: 'WfMedia';
          guid: string;
          mediaId: number;
          fileKey?: string | null;
          thumbnailUrl?: string | null;
          name?: string | null;
          url?: string | null;
          tag?: string | null;
          id: number;
          mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
          fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
          mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
          mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
          transformation?: {
            __typename: 'WfMediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
          } | null;
        } | null;
        mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
        fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
        mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
        mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
        transformation?: {
          __typename: 'WfMediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
        } | null;
      } | null;
      role?: {
        __typename: 'WfRole';
        id: number;
        guid?: string | null;
        name: string;
        internalName?: string | null;
        wardrobe?: string | null;
        project?: { __typename: 'WfProject'; id: number; guid?: string | null } | null;
      } | null;
      profile?: {
        __typename: 'WfProfile';
        id: number;
        guid?: string | null;
        stageName: string;
        overscaleNote?: string | null;
        isOverscaled?: boolean | null;
        minor?: boolean | null;
        dateOfBirth?: any | null;
        isPersonal: boolean;
        isRepresented?: boolean | null;
        hasAuditionHistory?: boolean | null;
        creditsCount: number;
        hasResume?: boolean | null;
        contactInfo?: { __typename: 'WfProfileContactInfo'; phone?: string | null; email?: string | null } | null;
        talentUnions: Array<{
          __typename: 'WfTalentProfileUnion';
          id: string;
          name: string;
          code: string;
          parentObject?: { __typename: 'WfTalentProfileUnion'; id: string; name: string } | null;
        }>;
        height?: { __typename: 'WfMeasurementModel'; id: string; inch: number; cm: number } | null;
        weight?: { __typename: 'WfWeight'; id: string; kg: number; lbs: number } | null;
        workingLocations: Array<{
          __typename: 'WfDma';
          id: string;
          code: string;
          name: string;
          sortOrder?: number | null;
        }>;
        notes?: Array<{
          __typename: 'WfNote';
          id: number;
          comment: string;
          created: string;
          noteModifier?: { __typename: 'WfNoteModifier'; id: string } | null;
        }> | null;
        organizations: Array<{
          __typename: 'WfOrganization';
          id: number;
          contacts?: Array<{
            __typename: 'WfContact';
            id: number;
            mainContact?: boolean | null;
            email?: string | null;
            phone?: string | null;
          }> | null;
          parentOrganization?: {
            __typename: 'WfOrganization';
            id: number;
            contacts?: Array<{
              __typename: 'WfContact';
              id: number;
              mainContact?: boolean | null;
              email?: string | null;
              phone?: string | null;
            }> | null;
          } | null;
        }>;
        profileMainOrganization?: {
          __typename: 'WfOrganization';
          id: number;
          name?: string | null;
          abbreviation?: string | null;
        } | null;
        profileMainDivision?: {
          __typename: 'WfOrganization';
          id: number;
          name?: string | null;
          abbreviation?: string | null;
        } | null;
        lastNote?: {
          __typename: 'WfNote';
          id: number;
          comment: string;
          created: string;
          noteModifier?: { __typename: 'WfNoteModifier'; id: string; code: string } | null;
        } | null;
      } | null;
      tileSchemaValue?: { __typename: 'WfBasicSettingModel'; id: string; code: string; name: string } | null;
      lastNote?: { __typename: 'WfNote'; id: number; comment: string; created: string } | null;
    };
    chatTopic?: {
      topicKey: string;
      unreadMessageCount?: number | null;
      lastUserMessage?: { id: string } | null;
    } | null;
    formDetails?: Array<{
      __typename: 'MediaResponseFormDetails';
      formId: number;
      formTitle: string;
      formResponseId?: number | null;
    }> | null;
  }>;
};

export type ProjectDetailViewMediaRequestResponseFragment = {
  __typename: 'MediaResponse';
  id: string;
  note?: string | null;
  status: MediaRequestStatus;
  sentAt?: any | null;
  repliedAt?: any | null;
  cancelledAt?: any | null;
  chatTopicKey: string;
  mediaList?: Array<{
    __typename: 'MediaResponseItem';
    id: number;
    isAdditional?: boolean | null;
    media: {
      __typename: 'WfMedia';
      guid: string;
      mediaId: number;
      fileKey?: string | null;
      thumbnailUrl?: string | null;
      name?: string | null;
      url?: string | null;
      tag?: string | null;
      id: number;
      thumbnail?: {
        __typename: 'WfMedia';
        guid: string;
        mediaId: number;
        fileKey?: string | null;
        thumbnailUrl?: string | null;
        name?: string | null;
        url?: string | null;
        tag?: string | null;
        id: number;
        mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
        fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
        mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
        mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
        transformation?: {
          __typename: 'WfMediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
        } | null;
      } | null;
      mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
      fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
      mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
      mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
      transformation?: {
        __typename: 'WfMediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
      } | null;
    };
  }> | null;
  submission: {
    __typename: 'WfSubmission';
    id: number;
    isOverscale?: boolean | null;
    overscaleNote?: string | null;
    isCustomized: boolean;
    note?: string | null;
    hasVideoReel?: boolean | null;
    hasAudioReel?: boolean | null;
    contactInfo?: { __typename: 'WfProfileContactInfo'; phone?: string | null; email?: string | null } | null;
    requestedMediaCount?: Array<{
      __typename: 'WfMediaCount';
      count?: number | null;
      type?: { __typename: 'WfMediaType'; id: string; code: string } | null;
    }> | null;
    cover?: {
      __typename: 'WfMedia';
      guid: string;
      mediaId: number;
      fileKey?: string | null;
      thumbnailUrl?: string | null;
      name?: string | null;
      url?: string | null;
      tag?: string | null;
      id: number;
      thumbnail?: {
        __typename: 'WfMedia';
        guid: string;
        mediaId: number;
        fileKey?: string | null;
        thumbnailUrl?: string | null;
        name?: string | null;
        url?: string | null;
        tag?: string | null;
        id: number;
        mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
        fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
        mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
        mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
        transformation?: {
          __typename: 'WfMediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
        } | null;
      } | null;
      mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
      fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
      mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
      mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
      transformation?: {
        __typename: 'WfMediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
      } | null;
    } | null;
    role?: {
      __typename: 'WfRole';
      id: number;
      guid?: string | null;
      name: string;
      internalName?: string | null;
      wardrobe?: string | null;
      project?: { __typename: 'WfProject'; id: number; guid?: string | null } | null;
    } | null;
    profile?: {
      __typename: 'WfProfile';
      id: number;
      guid?: string | null;
      stageName: string;
      overscaleNote?: string | null;
      isOverscaled?: boolean | null;
      minor?: boolean | null;
      dateOfBirth?: any | null;
      isPersonal: boolean;
      isRepresented?: boolean | null;
      hasAuditionHistory?: boolean | null;
      creditsCount: number;
      hasResume?: boolean | null;
      contactInfo?: { __typename: 'WfProfileContactInfo'; phone?: string | null; email?: string | null } | null;
      talentUnions: Array<{
        __typename: 'WfTalentProfileUnion';
        id: string;
        name: string;
        code: string;
        parentObject?: { __typename: 'WfTalentProfileUnion'; id: string; name: string } | null;
      }>;
      height?: { __typename: 'WfMeasurementModel'; id: string; inch: number; cm: number } | null;
      weight?: { __typename: 'WfWeight'; id: string; kg: number; lbs: number } | null;
      workingLocations: Array<{
        __typename: 'WfDma';
        id: string;
        code: string;
        name: string;
        sortOrder?: number | null;
      }>;
      notes?: Array<{
        __typename: 'WfNote';
        id: number;
        comment: string;
        created: string;
        noteModifier?: { __typename: 'WfNoteModifier'; id: string } | null;
      }> | null;
      organizations: Array<{
        __typename: 'WfOrganization';
        id: number;
        contacts?: Array<{
          __typename: 'WfContact';
          id: number;
          mainContact?: boolean | null;
          email?: string | null;
          phone?: string | null;
        }> | null;
        parentOrganization?: {
          __typename: 'WfOrganization';
          id: number;
          contacts?: Array<{
            __typename: 'WfContact';
            id: number;
            mainContact?: boolean | null;
            email?: string | null;
            phone?: string | null;
          }> | null;
        } | null;
      }>;
      profileMainOrganization?: {
        __typename: 'WfOrganization';
        id: number;
        name?: string | null;
        abbreviation?: string | null;
      } | null;
      profileMainDivision?: {
        __typename: 'WfOrganization';
        id: number;
        name?: string | null;
        abbreviation?: string | null;
      } | null;
      lastNote?: {
        __typename: 'WfNote';
        id: number;
        comment: string;
        created: string;
        noteModifier?: { __typename: 'WfNoteModifier'; id: string; code: string } | null;
      } | null;
    } | null;
    tileSchemaValue?: { __typename: 'WfBasicSettingModel'; id: string; code: string; name: string } | null;
    lastNote?: { __typename: 'WfNote'; id: number; comment: string; created: string } | null;
  };
  chatTopic?: { topicKey: string; unreadMessageCount?: number | null; lastUserMessage?: { id: string } | null } | null;
  formDetails?: Array<{
    __typename: 'MediaResponseFormDetails';
    formId: number;
    formTitle: string;
    formResponseId?: number | null;
  }> | null;
};

export type ProjectDetailViewMediaRequestWfSubmissionResponseFragment = {
  __typename: 'WfSubmission';
  id: number;
  isOverscale?: boolean | null;
  overscaleNote?: string | null;
  isCustomized: boolean;
  note?: string | null;
  hasVideoReel?: boolean | null;
  hasAudioReel?: boolean | null;
  contactInfo?: { __typename: 'WfProfileContactInfo'; phone?: string | null; email?: string | null } | null;
  requestedMediaCount?: Array<{
    __typename: 'WfMediaCount';
    count?: number | null;
    type?: { __typename: 'WfMediaType'; id: string; code: string } | null;
  }> | null;
  cover?: {
    __typename: 'WfMedia';
    guid: string;
    mediaId: number;
    fileKey?: string | null;
    thumbnailUrl?: string | null;
    name?: string | null;
    url?: string | null;
    tag?: string | null;
    id: number;
    thumbnail?: {
      __typename: 'WfMedia';
      guid: string;
      mediaId: number;
      fileKey?: string | null;
      thumbnailUrl?: string | null;
      name?: string | null;
      url?: string | null;
      tag?: string | null;
      id: number;
      mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
      fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
      mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
      mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
      transformation?: {
        __typename: 'WfMediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
      } | null;
    } | null;
    mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
    fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
    mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
    mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
    transformation?: {
      __typename: 'WfMediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
    } | null;
  } | null;
  role?: {
    __typename: 'WfRole';
    id: number;
    guid?: string | null;
    name: string;
    internalName?: string | null;
    wardrobe?: string | null;
    project?: { __typename: 'WfProject'; id: number; guid?: string | null } | null;
  } | null;
  profile?: {
    __typename: 'WfProfile';
    id: number;
    guid?: string | null;
    stageName: string;
    overscaleNote?: string | null;
    isOverscaled?: boolean | null;
    minor?: boolean | null;
    dateOfBirth?: any | null;
    isPersonal: boolean;
    isRepresented?: boolean | null;
    hasAuditionHistory?: boolean | null;
    creditsCount: number;
    hasResume?: boolean | null;
    contactInfo?: { __typename: 'WfProfileContactInfo'; phone?: string | null; email?: string | null } | null;
    talentUnions: Array<{
      __typename: 'WfTalentProfileUnion';
      id: string;
      name: string;
      code: string;
      parentObject?: { __typename: 'WfTalentProfileUnion'; id: string; name: string } | null;
    }>;
    height?: { __typename: 'WfMeasurementModel'; id: string; inch: number; cm: number } | null;
    weight?: { __typename: 'WfWeight'; id: string; kg: number; lbs: number } | null;
    workingLocations: Array<{ __typename: 'WfDma'; id: string; code: string; name: string; sortOrder?: number | null }>;
    notes?: Array<{
      __typename: 'WfNote';
      id: number;
      comment: string;
      created: string;
      noteModifier?: { __typename: 'WfNoteModifier'; id: string } | null;
    }> | null;
    organizations: Array<{
      __typename: 'WfOrganization';
      id: number;
      contacts?: Array<{
        __typename: 'WfContact';
        id: number;
        mainContact?: boolean | null;
        email?: string | null;
        phone?: string | null;
      }> | null;
      parentOrganization?: {
        __typename: 'WfOrganization';
        id: number;
        contacts?: Array<{
          __typename: 'WfContact';
          id: number;
          mainContact?: boolean | null;
          email?: string | null;
          phone?: string | null;
        }> | null;
      } | null;
    }>;
    profileMainOrganization?: {
      __typename: 'WfOrganization';
      id: number;
      name?: string | null;
      abbreviation?: string | null;
    } | null;
    profileMainDivision?: {
      __typename: 'WfOrganization';
      id: number;
      name?: string | null;
      abbreviation?: string | null;
    } | null;
    lastNote?: {
      __typename: 'WfNote';
      id: number;
      comment: string;
      created: string;
      noteModifier?: { __typename: 'WfNoteModifier'; id: string; code: string } | null;
    } | null;
  } | null;
  tileSchemaValue?: { __typename: 'WfBasicSettingModel'; id: string; code: string; name: string } | null;
  lastNote?: { __typename: 'WfNote'; id: number; comment: string; created: string } | null;
};

export type ProjectDetailViewMediaRequestBaseWorkflowMediaFragment = {
  __typename: 'WfMedia';
  guid: string;
  mediaId: number;
  fileKey?: string | null;
  thumbnailUrl?: string | null;
  name?: string | null;
  url?: string | null;
  tag?: string | null;
  id: number;
  mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
  fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
  mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
  mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
  transformation?: {
    __typename: 'WfMediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
  } | null;
};

export type ProjectDetailViewMediaRequestWorkflowMediaFragment = {
  __typename: 'WfMedia';
  guid: string;
  mediaId: number;
  fileKey?: string | null;
  thumbnailUrl?: string | null;
  name?: string | null;
  url?: string | null;
  tag?: string | null;
  id: number;
  thumbnail?: {
    __typename: 'WfMedia';
    guid: string;
    mediaId: number;
    fileKey?: string | null;
    thumbnailUrl?: string | null;
    name?: string | null;
    url?: string | null;
    tag?: string | null;
    id: number;
    mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
    fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
    mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
    mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
    transformation?: {
      __typename: 'WfMediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
    } | null;
  } | null;
  mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
  fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
  mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
  mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
  transformation?: {
    __typename: 'WfMediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
  } | null;
};

export type ProjectDetailViewMediaRequestWorkflowCoverMediaFragment = {
  __typename: 'WfMedia';
  guid: string;
  mediaId: number;
  fileKey?: string | null;
  thumbnailUrl?: string | null;
  name?: string | null;
  url?: string | null;
  tag?: string | null;
  id: number;
  thumbnail?: {
    __typename: 'WfMedia';
    guid: string;
    mediaId: number;
    fileKey?: string | null;
    thumbnailUrl?: string | null;
    name?: string | null;
    url?: string | null;
    tag?: string | null;
    id: number;
    mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
    fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
    mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
    mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
    transformation?: {
      __typename: 'WfMediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
    } | null;
  } | null;
  mediaStorageStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
  fileType?: { __typename: 'WfMediaFileType'; id: string; code: string; name: string } | null;
  mediaType?: { __typename: 'WfMediaType'; id: string; code: string } | null;
  mediaStatus?: { __typename: 'WfMediaStatus'; id: string; code: string } | null;
  transformation?: {
    __typename: 'WfMediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
  } | null;
};

export type ProjectDetailViewMediaRequestTitleSchemaValueFragment = {
  __typename: 'WfSubmission';
  tileSchemaValue?: { __typename: 'WfBasicSettingModel'; id: string; code: string; name: string } | null;
};

export type ProjectDetailViewMediaRequestSubmissionLastNoteFragment = {
  __typename: 'WfSubmission';
  lastNote?: { __typename: 'WfNote'; id: number; comment: string; created: string } | null;
};

export type ProjectDetailViewMediaRequestWfProfileLastNoteFragment = {
  __typename: 'WfProfile';
  lastNote?: {
    __typename: 'WfNote';
    id: number;
    comment: string;
    created: string;
    noteModifier?: { __typename: 'WfNoteModifier'; id: string; code: string } | null;
  } | null;
};

export type ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivisionFragment = {
  __typename: 'WfProfile';
  organizations: Array<{
    __typename: 'WfOrganization';
    id: number;
    contacts?: Array<{
      __typename: 'WfContact';
      id: number;
      mainContact?: boolean | null;
      email?: string | null;
      phone?: string | null;
    }> | null;
    parentOrganization?: {
      __typename: 'WfOrganization';
      id: number;
      contacts?: Array<{
        __typename: 'WfContact';
        id: number;
        mainContact?: boolean | null;
        email?: string | null;
        phone?: string | null;
      }> | null;
    } | null;
  }>;
  profileMainOrganization?: {
    __typename: 'WfOrganization';
    id: number;
    name?: string | null;
    abbreviation?: string | null;
  } | null;
  profileMainDivision?: {
    __typename: 'WfOrganization';
    id: number;
    name?: string | null;
    abbreviation?: string | null;
  } | null;
};

export type ProjectListRowFragment = {
  __typename: 'ProjectBase';
  id: string;
  name?: string | null;
  internalName?: string | null;
  supportTicketNumber?: string | null;
  castingCompanyName?: string | null;
  seriesName?: string | null;
  lastModifiedAt: any;
  created?: any | null;
  projectStatus?: { __typename: 'ProjectStatus'; id: string; code: string; name: string } | null;
  projectType?: { __typename: 'ProjectType'; id: string; name: string } | null;
};

export type ProjectListRowsQueryVariables = Exact<{
  organizationId: Scalars['Int'];
  statusFilter?: InputMaybe<ProjectStatusFilterEnum>;
  sortInstruction?: InputMaybe<SortConditionInput>;
  page?: InputMaybe<PageInput>;
  name?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrganizationProjectFilterInput>;
}>;

export type ProjectListRowsQuery = {
  organizationProjects: {
    __typename: 'ProjectBasePaginatedResult';
    lastKey?: string | null;
    page: number;
    totalCount: number;
    totalPages: number;
    objects: Array<{
      __typename: 'ProjectBase';
      id: string;
      name?: string | null;
      internalName?: string | null;
      supportTicketNumber?: string | null;
      castingCompanyName?: string | null;
      seriesName?: string | null;
      lastModifiedAt: any;
      created?: any | null;
      projectStatus?: { __typename: 'ProjectStatus'; id: string; code: string; name: string } | null;
      projectType?: { __typename: 'ProjectType'; id: string; name: string } | null;
    }>;
  };
};

export type ProjectListGroupSeriesFragment = { __typename: 'ProjectSeries'; id: string; seriesName: string };

export type ProjectListGroupStatusFragment = {
  __typename: 'ProjectStatus';
  code: string;
  enabled: boolean;
  id: string;
  name: string;
  sortOrder?: number | null;
};

export type ProjectListGroupTypeFragment = {
  __typename: 'ProjectType';
  code: string;
  enabled: boolean;
  id: string;
  name: string;
  selectable: boolean;
  sortOrder?: number | null;
  parentObject?: { __typename: 'ProjectType'; id: string; name: string; code: string } | null;
  childObjects: Array<{ __typename: 'ProjectType'; id: string; name: string; code: string }>;
};

export type ProjectListGroupsQueryVariables = Exact<{
  projectGroupType: ProjectGroupType;
  page?: InputMaybe<PageInput>;
  filter?: InputMaybe<OrganizationProjectFilterInput>;
}>;

export type ProjectListGroupsQuery = {
  organizationProjectsGroups: {
    __typename: 'OrganizationProjectGroupPaginatedResult';
    lastKey?: string | null;
    page: number;
    totalCount: number;
    totalPages: number;
    objects: Array<{
      __typename: 'OrganizationProjectGroup';
      itemCount: number;
      details:
        | { __typename: 'ProjectSeries'; id: string; seriesName: string }
        | {
            __typename: 'ProjectStatus';
            code: string;
            enabled: boolean;
            id: string;
            name: string;
            sortOrder?: number | null;
          }
        | {
            __typename: 'ProjectType';
            code: string;
            enabled: boolean;
            id: string;
            name: string;
            selectable: boolean;
            sortOrder?: number | null;
            parentObject?: { __typename: 'ProjectType'; id: string; name: string; code: string } | null;
            childObjects: Array<{ __typename: 'ProjectType'; id: string; name: string; code: string }>;
          };
    }>;
  };
};

export type CreateProjectSeriesMutationVariables = Exact<{
  input: CreateProjectSeriesInput;
}>;

export type CreateProjectSeriesMutation = { createProjectSeries: number };

export type GetProjectSeriesByNameQueryVariables = Exact<{
  input: GetProjectSeriesByNameInput;
  page?: InputMaybe<PageInput>;
}>;

export type GetProjectSeriesByNameQuery = {
  searchProjectSeriesByOrg: {
    __typename: 'ProjectSeriesPaginatedResult';
    page: number;
    totalCount: number;
    totalPages: number;
    objects: Array<{ __typename: 'ProjectSeries'; id: string; seriesName: string }>;
  };
};

export type PurchaseMediaMutationVariables = Exact<{
  input: PurchaseMediaInput;
}>;

export type PurchaseMediaMutation = {
  purchaseMedia: {
    __typename: 'MediaInvoice';
    invoiceId?: string | null;
    email: string;
    discount: number;
    creditsApplied: number;
    total: number;
    currency: {
      __typename: 'Currency';
      code: string;
      id: string;
      name: string;
      sortOrder?: number | null;
      symbol: string;
    };
    coupon?: { __typename: 'Coupon'; code: string; description: string; state: string; isRedeemed: boolean } | null;
    paymentDetails?: {
      __typename: 'PaymentDetails';
      cardType?: string | null;
      lastFour?: string | null;
      paymentType?: string | null;
    } | null;
    items: Array<{
      __typename: 'MediaPurchase';
      price: number;
      media: {
        __typename: 'Media';
        id: string;
        accountId: number;
        appearancesOnProfiles: number;
        appearOnSubmissions: boolean;
        archivableOn?: any | null;
        created?: any | null;
        fileKey?: string | null;
        filename?: string | null;
        guid: string;
        height?: number | null;
        isLocked?: boolean | null;
        length?: number | null;
        mediaId: number;
        mediaParentId?: number | null;
        thumbnailUrl?: string | null;
        updated?: any | null;
        url?: string | null;
        width?: number | null;
        purchaseStatus?: MediaPurchaseStatus | null;
        appearOnMediaRequests: boolean;
      };
      currency: {
        __typename: 'Currency';
        code: string;
        id: string;
        name: string;
        sortOrder?: number | null;
        symbol: string;
      };
    }>;
  };
};

export type PreviewSubscriptionRenewalQueryVariables = Exact<{
  input: PreviewSubscriptionRenewalInput;
}>;

export type PreviewSubscriptionRenewalQuery = {
  previewSubscriptionRenewal: {
    __typename: 'SubscriptionRenewal';
    total: number;
    subscription: {
      __typename: 'BillingItemModel';
      id?: string | null;
      code: string;
      name?: string | null;
      price?: number | null;
    };
    currency: { __typename: 'Currency'; code: string };
  };
};

export type RenewSubscriptionMutationVariables = Exact<{
  input: RenewSubscriptionInput;
}>;

export type RenewSubscriptionMutation = {
  renewSubscription: {
    __typename: 'SubscriptionRenewal';
    invoiceId?: string | null;
    lastFour: string;
    cardType: string;
    email: string;
    paymentType?: string | null;
    subscription: { __typename: 'BillingItemModel'; id?: string | null; code: string };
  };
};

export type DownloadCsvQueryVariables = Exact<{
  reportId: Scalars['ID'];
}>;

export type DownloadCsvQuery = { getReportCsvUrlById?: string | null };

export type DownloadCsvPublicQueryVariables = Exact<{
  reportId: Scalars['ID'];
  publicKey: Scalars['ID'];
}>;

export type DownloadCsvPublicQuery = { getReportCsvUrlByIdPublic?: string | null };

export type CreateAllTalentSubmissionReportMutationVariables = Exact<{
  input: AllTalentSubmissionReportCreateInput;
}>;

export type CreateAllTalentSubmissionReportMutation = { createAllTalentSubmissionReport: string };

export type CreateTalentSubmissionReportMutationVariables = Exact<{
  input: TalentSubmissionReportCreateInput;
}>;

export type CreateTalentSubmissionReportMutation = { createTalentSubmissionReport: string };

export type AllTalentReportSubmissionFragment = {
  __typename: 'AllTalentSubmissionCountModel';
  auditionsCount: number;
  callbacksCount: number;
  mediaRequestCount: number;
  submissionsCount: number;
  profile?: {
    __typename: 'AllTalentSubmissionProfileModel';
    isFormerClient: boolean;
    profileId: number;
    stageName?: string | null;
  } | null;
};

export type AllTalentReportFragment = {
  __typename: 'AllTalentSubmissionModel';
  createdAt: any;
  createdBy?: number | null;
  csvFileName?: string | null;
  dateFrom: any;
  dateTo: any;
  id: string;
  includeFormerClient?: boolean | null;
  organizationId: number;
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  updatedAt?: any | null;
  payload?: {
    __typename: 'AllTalentSubmissionPayloadModel';
    castingCompanyInfo?: {
      __typename: 'CompanyInfoModel';
      name?: string | null;
      parentName?: string | null;
      logo?: {
        __typename: 'ReportMediaModel';
        fileKey: string;
        id: string;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      } | null;
    } | null;
    data: Array<{
      __typename: 'AllTalentSubmissionCountModel';
      auditionsCount: number;
      callbacksCount: number;
      mediaRequestCount: number;
      submissionsCount: number;
      profile?: {
        __typename: 'AllTalentSubmissionProfileModel';
        isFormerClient: boolean;
        profileId: number;
        stageName?: string | null;
      } | null;
    }>;
  } | null;
};

export type RepsAllTalentReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RepsAllTalentReportQuery = {
  getReportById?:
    | { __typename: 'AgencyCallListReportModel' }
    | {
        __typename: 'AllTalentSubmissionModel';
        createdAt: any;
        createdBy?: number | null;
        csvFileName?: string | null;
        dateFrom: any;
        dateTo: any;
        id: string;
        includeFormerClient?: boolean | null;
        organizationId: number;
        reportType: ReportTypeEnum;
        status: ReportStatusEnum;
        updatedAt?: any | null;
        payload?: {
          __typename: 'AllTalentSubmissionPayloadModel';
          castingCompanyInfo?: {
            __typename: 'CompanyInfoModel';
            name?: string | null;
            parentName?: string | null;
            logo?: {
              __typename: 'ReportMediaModel';
              fileKey: string;
              id: string;
              mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
            } | null;
          } | null;
          data: Array<{
            __typename: 'AllTalentSubmissionCountModel';
            auditionsCount: number;
            callbacksCount: number;
            mediaRequestCount: number;
            submissionsCount: number;
            profile?: {
              __typename: 'AllTalentSubmissionProfileModel';
              isFormerClient: boolean;
              profileId: number;
              stageName?: string | null;
            } | null;
          }>;
        } | null;
      }
    | { __typename: 'BookingCallListsReportModel' }
    | { __typename: 'FormsReportModel' }
    | { __typename: 'PresentationLogReportModel' }
    | { __typename: 'PresentationPickReportModel' }
    | { __typename: 'PresentationSizeSheetsReportModel' }
    | { __typename: 'ProjectOverviewCDReportModel' }
    | { __typename: 'ProjectOverviewReportModel' }
    | { __typename: 'ProjectSubmissionReportModel' }
    | { __typename: 'ScheduleReportModel' }
    | { __typename: 'ScheduleSizeSheetsReportModel' }
    | { __typename: 'TalentSubmissionModel' }
    | null;
};

export type SingleTalentReportFragment = {
  __typename: 'TalentSubmissionModel';
  id: string;
  reportType: ReportTypeEnum;
  status: ReportStatusEnum;
  profileId: number;
  createdAt: any;
  organizationId?: number | null;
  dateFrom: any;
  dateTo: any;
  payload?: {
    __typename: 'TalentSubmissionPayloadModel';
    profile?: { __typename: 'TalentSubmissionProfileModel'; stageName?: string | null; created?: any | null } | null;
    castingCompanyInfo?: {
      __typename: 'CompanyInfoModel';
      name?: string | null;
      parentName?: string | null;
      logo?: {
        __typename: 'ReportMediaModel';
        id: string;
        fileKey: string;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      } | null;
    } | null;
    submissions?: Array<{
      __typename: 'TalentSubmissionItemModel';
      id: number;
      submissionDate: any;
      ageRange: string;
      projectName: string;
      castingDirector?: string | null;
      status: string;
      roleName: string;
      mediaRequest: boolean;
    }> | null;
  } | null;
};

export type RepsSingleTalentReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RepsSingleTalentReportQuery = {
  getReportById?:
    | { __typename: 'AgencyCallListReportModel' }
    | { __typename: 'AllTalentSubmissionModel' }
    | { __typename: 'BookingCallListsReportModel' }
    | { __typename: 'FormsReportModel' }
    | { __typename: 'PresentationLogReportModel' }
    | { __typename: 'PresentationPickReportModel' }
    | { __typename: 'PresentationSizeSheetsReportModel' }
    | { __typename: 'ProjectOverviewCDReportModel' }
    | { __typename: 'ProjectOverviewReportModel' }
    | { __typename: 'ProjectSubmissionReportModel' }
    | { __typename: 'ScheduleReportModel' }
    | { __typename: 'ScheduleSizeSheetsReportModel' }
    | {
        __typename: 'TalentSubmissionModel';
        id: string;
        reportType: ReportTypeEnum;
        status: ReportStatusEnum;
        profileId: number;
        createdAt: any;
        organizationId?: number | null;
        dateFrom: any;
        dateTo: any;
        payload?: {
          __typename: 'TalentSubmissionPayloadModel';
          profile?: {
            __typename: 'TalentSubmissionProfileModel';
            stageName?: string | null;
            created?: any | null;
          } | null;
          castingCompanyInfo?: {
            __typename: 'CompanyInfoModel';
            name?: string | null;
            parentName?: string | null;
            logo?: {
              __typename: 'ReportMediaModel';
              id: string;
              fileKey: string;
              mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
            } | null;
          } | null;
          submissions?: Array<{
            __typename: 'TalentSubmissionItemModel';
            id: number;
            submissionDate: any;
            ageRange: string;
            projectName: string;
            castingDirector?: string | null;
            status: string;
            roleName: string;
            mediaRequest: boolean;
          }> | null;
        } | null;
      }
    | null;
};

export type SaveDraftMediaRequestMutationVariables = Exact<{
  input: SaveDraftMediaRequestArgs;
}>;

export type SaveDraftMediaRequestMutation = {
  saveDraftMediaRequest: {
    id: string;
    name: string;
    projectId: number;
    includeSides: boolean;
    talentInstructions?: string | null;
    messageForRepresentatives?: string | null;
    maxNumberOfMediaFiles: number;
    mediaTypes: Array<RequestMediaType>;
    createdAt: any;
    updatedAt?: any | null;
    shouldResend: boolean;
    talentInstructionsUpdatedAt?: any | null;
    messageForRepresentativesUpdatedAt?: any | null;
    dueDate?: any | null;
    allowAdditionalMedia?: boolean | null;
    isDraft?: boolean | null;
    submissionIds: Array<number>;
  };
};

export type SaveDraftAskQuestionRequestMutationVariables = Exact<{
  input: SaveDraftAskQuestionRequestArgs;
}>;

export type SaveDraftAskQuestionRequestMutation = {
  saveDraftAskQuestionRequest: {
    id: string;
    name: string;
    question: string;
    projectId: number;
    includeSides: boolean;
    messageForRepresentatives?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    dueDate?: any | null;
    shouldResend: boolean;
    isDraft?: boolean | null;
    submissionIds: Array<number>;
  };
};

export type DeleteRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteRequestMutation = { deleteRequest: boolean };

export type ResetSessionObserverUrlMutationVariables = Exact<{
  input: ResetSessionObserverUrlInput;
}>;

export type ResetSessionObserverUrlMutation = {
  resetSessionObserverUrl: {
    __typename: 'ResetSessionObserverUrlOutput';
    virtualAudition?: {
      __typename: 'ScheduleVirtualAudition';
      id: string;
      joinUrl?: string | null;
      observerUrl?: string | null;
    } | null;
  };
};

export type RestoreArchivedMediaMutationVariables = Exact<{
  input: RestoreArchivedMediaInput;
}>;

export type RestoreArchivedMediaMutation = {
  restoreArchivedMedia: {
    __typename: 'RestoreArchivedMediaResponse';
    success: boolean;
    media: Array<{ __typename: 'Media'; id: string }>;
  };
};

export type ResumeSubscriptionMutationVariables = Exact<{
  input: ResumeSubscriptionInput;
}>;

export type ResumeSubscriptionMutation = { resumeSubscription: boolean };

export type PreCancelQueryVariables = Exact<{
  input: PreCancelInput;
}>;

export type PreCancelQuery = { preCancel: { url: string } };

export type GetRoleTrackerStatsQueryVariables = Exact<{
  timeframe?: InputMaybe<Scalars['Int']>;
  interval?: InputMaybe<Scalars['Int']>;
}>;

export type GetRoleTrackerStatsQuery = {
  roleTrackerStats: {
    __typename: 'RoleTrackerStatsResponse';
    currentPeriod: {
      stats: {
        __typename: 'RoleTrackerStatsAggregate';
        selected: number;
        submitted: number;
        underConsideration: number;
      };
    };
    previousPeriod: {
      stats: {
        __typename: 'RoleTrackerStatsAggregate';
        selected: number;
        submitted: number;
        underConsideration: number;
      };
    };
  };
};

export type StatsFragment = {
  stats: { __typename: 'RoleTrackerStatsAggregate'; selected: number; submitted: number; underConsideration: number };
};

export type RoleTrackerQueryVariables = Exact<{
  filters?: InputMaybe<RoleTrackerFiltersArgs>;
  sortOptions?: InputMaybe<Array<RoleTrackerOrderOption> | RoleTrackerOrderOption>;
  page?: InputMaybe<PageInput>;
}>;

export type RoleTrackerQuery = {
  roleTracker: {
    __typename: 'RoleTrackerPagedList';
    page: number;
    totalCount: number;
    totalPages: number;
    data: Array<{
      __typename: 'RoleTracker';
      created?: any | null;
      id: any;
      lastStatusUpdated?: any | null;
      roleTrackerStatus?: RoleTrackerStatusEnum | null;
      source?: string | null;
      role?: {
        __typename: 'Role';
        id: string;
        name: string;
        roleType?: { __typename: 'RoleType'; id: string; name: string } | null;
        project: {
          __typename: 'Project';
          id: string;
          projectName?: string | null;
          name?: string | null;
          projectType?: { __typename: 'ProjectType'; id: string; name: string } | null;
        };
      } | null;
    }>;
  };
};

export type RoleTrackerDataFragment = {
  __typename: 'RoleTracker';
  created?: any | null;
  id: any;
  lastStatusUpdated?: any | null;
  roleTrackerStatus?: RoleTrackerStatusEnum | null;
  source?: string | null;
  role?: {
    __typename: 'Role';
    id: string;
    name: string;
    roleType?: { __typename: 'RoleType'; id: string; name: string } | null;
    project: {
      __typename: 'Project';
      id: string;
      projectName?: string | null;
      name?: string | null;
      projectType?: { __typename: 'ProjectType'; id: string; name: string } | null;
    };
  } | null;
};

export type RoleTrackerRoleFragment = {
  __typename: 'Role';
  id: string;
  name: string;
  roleType?: { __typename: 'RoleType'; id: string; name: string } | null;
  project: {
    __typename: 'Project';
    id: string;
    projectName?: string | null;
    name?: string | null;
    projectType?: { __typename: 'ProjectType'; id: string; name: string } | null;
  };
};

export type RoleTrackerProjectFragment = {
  __typename: 'Project';
  id: string;
  projectName?: string | null;
  name?: string | null;
  projectType?: { __typename: 'ProjectType'; id: string; name: string } | null;
};

export type PublishRolesMutationVariables = Exact<{
  roleIds: Array<Scalars['ID']> | Scalars['ID'];
  messageToReps?: InputMaybe<Scalars['String']>;
  messageToTalents?: InputMaybe<Scalars['String']>;
}>;

export type PublishRolesMutation = { publishRoles: boolean };

export type SearchSchedulesQueryVariables = Exact<{
  projectId: Scalars['Int'];
  pageNum: Scalars['Int'];
  pageSize?: Scalars['Int'];
}>;

export type SearchSchedulesQuery = {
  searchSchedules?: {
    __typename: 'SchedulePaginatedResult';
    totalCount: number;
    schedules: Array<{
      __typename: 'Schedule';
      id: string;
      name?: string | null;
      lastUpdated?: any | null;
      isCanceled?: boolean | null;
      scheduleType?: string | null;
      virtualAudition?: {
        __typename: 'ScheduleVirtualAudition';
        id: string;
        joinUrl?: string | null;
        observerUrl?: string | null;
      } | null;
      statusCounts: {
        __typename: 'ScheduleInviteResponseStatusCounts';
        notSent: number;
        confirmed: number;
        declined: number;
        pending: number;
        rescheduleRequested: number;
      };
    }>;
  } | null;
};

export type ScheduleDetailQueryVariables = Exact<{
  scheduleId: Scalars['ID'];
}>;

export type ScheduleDetailQuery = {
  scheduleDetails: {
    __typename: 'ScheduleDetail';
    id?: string | null;
    virtualAudition?: {
      __typename: 'ScheduleVirtualAudition';
      id: string;
      joinUrl?: string | null;
      observerUrl?: string | null;
    } | null;
  };
};

export type ArtistMediaCountQueryVariables = Exact<{
  artistId: Scalars['Int'];
}>;

export type ArtistMediaCountQuery = {
  searchArtistMedia: {
    __typename: 'PaginatedSearchArtistMediaResult';
    totalCount: number;
    artist: {
      __typename: 'Artist';
      artistId: number;
      id: string;
      subscriptionMediaUsage?: {
        __typename: 'ArtistSubscriptionMediaEntitlement';
        available: Array<{
          __typename: 'SubscriptionMediaEntitlement';
          mediaType: string;
          count: number;
          isUnlimited: boolean;
        }>;
        entitle: Array<{
          __typename: 'SubscriptionMediaEntitlement';
          mediaType: string;
          count: number;
          isUnlimited: boolean;
        }>;
      } | null;
    };
  };
};

export type SearchArtistMediaQueryVariables = Exact<{
  input: SearchArtistMediaInput;
}>;

export type SearchArtistMediaQuery = {
  searchArtistMedia: {
    __typename: 'PaginatedSearchArtistMediaResult';
    totalCount: number;
    artist: {
      __typename: 'Artist';
      artistId: number;
      id: string;
      subscriptionMediaUsage?: {
        __typename: 'ArtistSubscriptionMediaEntitlement';
        available: Array<{
          __typename: 'SubscriptionMediaEntitlement';
          mediaType: string;
          count: number;
          isUnlimited: boolean;
        }>;
        entitle: Array<{
          __typename: 'SubscriptionMediaEntitlement';
          mediaType: string;
          count: number;
          isUnlimited: boolean;
        }>;
      } | null;
    };
    objects: Array<{
      __typename: 'Media';
      id: string;
      thumbnailUrl?: string | null;
      created?: any | null;
      mediaId: number;
      purchaseStatus?: MediaPurchaseStatus | null;
      accountId: number;
      archivableOn?: any | null;
      fileKey?: string | null;
      filename?: string | null;
      guid: string;
      height?: number | null;
      length?: number | null;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      appearOnSubmissions: boolean;
      appearOnMediaRequests: boolean;
      isLocked?: boolean | null;
      updated?: any | null;
      url?: string | null;
      width?: number | null;
      thumbnail?: {
        __typename: 'Media';
        id: string;
        height?: number | null;
        width?: number | null;
        length?: number | null;
        url?: string | null;
        fileKey?: string | null;
        guid: string;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      createdByOrg?: {
        __typename: 'Organization';
        id: number;
        name?: string | null;
        parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
      } | null;
      displaySize?: { size: number; unit: string } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      moderationStatus: { __typename: 'MediaModerationStatus'; id: string; code: string };
      purpose: { __typename: 'MediaPurpose'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      profiles?: Array<{
        __typename: 'ProfileMedia';
        id: string;
        isPrimary: boolean;
        order: number;
        profile: {
          __typename: 'Profile';
          id: string;
          profileId: string;
          stageName: string;
          isPersonal: boolean;
          profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
          organizations?: Array<{
            __typename: 'Organization';
            id: number;
            name?: string | null;
            parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
          }> | null;
        };
      }> | null;
    }>;
  };
};

export type ArtistMediaCtxFragment = {
  __typename: 'Artist';
  artistId: number;
  id: string;
  subscriptionMediaUsage?: {
    __typename: 'ArtistSubscriptionMediaEntitlement';
    available: Array<{
      __typename: 'SubscriptionMediaEntitlement';
      mediaType: string;
      count: number;
      isUnlimited: boolean;
    }>;
    entitle: Array<{
      __typename: 'SubscriptionMediaEntitlement';
      mediaType: string;
      count: number;
      isUnlimited: boolean;
    }>;
  } | null;
};

export type SearchMediaResultFragment = {
  __typename: 'Media';
  id: string;
  thumbnailUrl?: string | null;
  created?: any | null;
  mediaId: number;
  purchaseStatus?: MediaPurchaseStatus | null;
  accountId: number;
  archivableOn?: any | null;
  fileKey?: string | null;
  filename?: string | null;
  guid: string;
  height?: number | null;
  length?: number | null;
  name?: string | null;
  size?: number | null;
  source?: string | null;
  appearOnSubmissions: boolean;
  appearOnMediaRequests: boolean;
  isLocked?: boolean | null;
  updated?: any | null;
  url?: string | null;
  width?: number | null;
  thumbnail?: {
    __typename: 'Media';
    id: string;
    height?: number | null;
    width?: number | null;
    length?: number | null;
    url?: string | null;
    fileKey?: string | null;
    guid: string;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
  } | null;
  createdByOrg?: {
    __typename: 'Organization';
    id: number;
    name?: string | null;
    parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
  } | null;
  displaySize?: { size: number; unit: string } | null;
  mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
  mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
  moderationStatus: { __typename: 'MediaModerationStatus'; id: string; code: string };
  purpose: { __typename: 'MediaPurpose'; id: string; code: string };
  fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
  transformation?: {
    __typename: 'MediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
    startOffset?: string | null;
  } | null;
  mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  profiles?: Array<{
    __typename: 'ProfileMedia';
    id: string;
    isPrimary: boolean;
    order: number;
    profile: {
      __typename: 'Profile';
      id: string;
      profileId: string;
      stageName: string;
      isPersonal: boolean;
      profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
      organizations?: Array<{
        __typename: 'Organization';
        id: number;
        name?: string | null;
        parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
      }> | null;
    };
  }> | null;
};

export type SearchMediaProfileMediaFragment = {
  __typename: 'ProfileMedia';
  id: string;
  isPrimary: boolean;
  order: number;
  profile: {
    __typename: 'Profile';
    id: string;
    profileId: string;
    stageName: string;
    isPersonal: boolean;
    profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
    organizations?: Array<{
      __typename: 'Organization';
      id: number;
      name?: string | null;
      parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
    }> | null;
  };
};

export type SearchMediaBaseResultFragment = {
  __typename: 'Media';
  id: string;
  accountId: number;
  archivableOn?: any | null;
  created?: any | null;
  fileKey?: string | null;
  filename?: string | null;
  guid: string;
  height?: number | null;
  length?: number | null;
  mediaId: number;
  name?: string | null;
  size?: number | null;
  source?: string | null;
  appearOnSubmissions: boolean;
  appearOnMediaRequests: boolean;
  isLocked?: boolean | null;
  updated?: any | null;
  url?: string | null;
  width?: number | null;
  fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
  transformation?: {
    __typename: 'MediaTransformation';
    xAxis?: number | null;
    yAxis?: number | null;
    width?: number | null;
    height?: number | null;
    rotate?: number | null;
    startOffset?: string | null;
  } | null;
  mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  profiles?: Array<{
    __typename: 'ProfileMedia';
    id: string;
    isPrimary: boolean;
    order: number;
    profile: {
      __typename: 'Profile';
      id: string;
      profileId: string;
      stageName: string;
      isPersonal: boolean;
      profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
      organizations?: Array<{
        __typename: 'Organization';
        id: number;
        name?: string | null;
        parentOrganization?: { __typename: 'Organization'; id: number; name?: string | null } | null;
      }> | null;
    };
  }> | null;
};

export type SelectProfilesFromRosterMutationVariables = Exact<{
  profileIds: Array<Scalars['Int']> | Scalars['Int'];
  roleId: Scalars['Int'];
}>;

export type SelectProfilesFromRosterMutation = { selectProfilesFromRoster: number };

export type ImportPresentationFromAuditionMutationVariables = Exact<{
  auditionIds: Array<Scalars['Int']> | Scalars['Int'];
  isLive: Scalars['Boolean'];
  projectId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  presentationId: Scalars['Int'];
}>;

export type ImportPresentationFromAuditionMutation = { importPresentationFromAudition: number };

export type AuditionListCountQueryVariables = Exact<{
  projectId: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type AuditionListCountQuery = {
  auditionSessionsByProject: { __typename: 'AuditionSessionPaginatedResult'; totalCount: number };
};

export type AuditionListQueryVariables = Exact<{
  projectId: Array<Scalars['Int']> | Scalars['Int'];
  page?: InputMaybe<PageInput>;
  projectIdPresentations: Scalars['Int'];
  sortInstruction?: InputMaybe<AuditionSessionSortInstructionInput>;
  filter?: InputMaybe<AuditionSessionFilterInput>;
}>;

export type AuditionListQuery = {
  auditionSessionsByProject: {
    __typename: 'AuditionSessionPaginatedResult';
    totalCount: number;
    objects: Array<{
      __typename: 'AuditionSession';
      id: number;
      name?: string | null;
      created: any;
      source?: { __typename: 'AuditionSource'; id: string; code: string; name: string; enabled: boolean } | null;
      createdBy?: { __typename: 'Account'; id: number; firstName: string; lastName: string } | null;
    }>;
  };
  getPresentationsByProject: Array<{ __typename: 'Presentation'; id: number; name: string }>;
};

export type CreateShareTalentSelectsPresentationMutationVariables = Exact<{
  input: ShareTalentPresentationInput;
}>;

export type CreateShareTalentSelectsPresentationMutation = {
  createShareTalentPresentation: { id: number; guid: string; name: string };
};

export type ShareTalentSelectsProjectRolesQueryVariables = Exact<{
  projectId: Scalars['Float'];
}>;

export type ShareTalentSelectsProjectRolesQuery = {
  projectRoles: Array<{ id: string; name: string; internalName?: string | null }>;
};

export type AcceptProfileInviteQueryVariables = Exact<{
  accessToken: Scalars['String'];
}>;

export type AcceptProfileInviteQuery = { getProfileByAccessToken?: { profileAccessTokenCreated?: any | null } | null };

export type SubmitAcceptProfileTokenQueryVariables = Exact<{
  accessToken: Scalars['String'];
}>;

export type SubmitAcceptProfileTokenQuery = {
  getProfileByAccessToken?: { profileAccessTokenCreated?: any | null } | null;
};

export type CreateAccountMutationVariables = Exact<{
  input: AccountCreateInput;
  isEmailVerificationRequired?: InputMaybe<Scalars['Boolean']>;
  audience?: InputMaybe<Scalars['String']>;
}>;

export type CreateAccountMutation = { createAccount: { refresh: string; access: string } };

export type SendEmailVerificationCodeMutationVariables = Exact<{ [key: string]: never }>;

export type SendEmailVerificationCodeMutation = { sendAccountEmailVerificationCode: string };

export type SkillWithParentAndChildrenFragment = {
  __typename: 'Skill';
  id: string;
  name: string;
  code: string;
  childObjects: Array<{
    __typename: 'Skill';
    id: string;
    name: string;
    code: string;
    childObjects: Array<{ __typename: 'Skill'; id: string; name: string; code: string }>;
  }>;
  parentObject?: { __typename: 'Skill'; id: string; code: string; name: string } | null;
};

export type GetSkillsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSkillsQuery = {
  skills: Array<{
    __typename: 'Skill';
    id: string;
    name: string;
    code: string;
    childObjects: Array<{
      __typename: 'Skill';
      id: string;
      name: string;
      code: string;
      childObjects: Array<{ __typename: 'Skill'; id: string; name: string; code: string }>;
    }>;
    parentObject?: { __typename: 'Skill'; id: string; code: string; name: string } | null;
  }>;
};

export type SkillLevelFragment = { __typename: 'SkillLevel'; id: string; code: string; name: string };

export type GetSkillLevelsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSkillLevelsQuery = {
  skillLevels: Array<{ __typename: 'SkillLevel'; id: string; code: string; name: string }>;
};

export type SkillOnProfileFragment = {
  __typename: 'ProfileSkill';
  profileMediaId?: number | null;
  profileSkillId?: number | null;
  id: string;
  code: string;
  name: string;
  parentObject?: {
    __typename: 'Skill';
    id: string;
    code: string;
    name: string;
    parentObject?: { __typename: 'Skill'; id: string; code: string; name: string } | null;
  } | null;
  level?: { __typename: 'SkillLevel'; id: string; code: string; name: string } | null;
};

export type GetProfileWithSkillsQueryVariables = Exact<{
  ids: Scalars['ID'];
}>;

export type GetProfileWithSkillsQuery = {
  profiles?: Array<{
    __typename: 'Profile';
    artistId?: number | null;
    id: string;
    profileId: string;
    stageName: string;
    skills?: Array<{
      __typename: 'ProfileSkill';
      profileMediaId?: number | null;
      profileSkillId?: number | null;
      id: string;
      code: string;
      name: string;
      parentObject?: {
        __typename: 'Skill';
        id: string;
        code: string;
        name: string;
        parentObject?: { __typename: 'Skill'; id: string; code: string; name: string } | null;
      } | null;
      level?: { __typename: 'SkillLevel'; id: string; code: string; name: string } | null;
    }> | null;
    mediaAssets?: Array<{
      __typename: 'ProfileMedia';
      created: any;
      id: string;
      isPrimary: boolean;
      order: number;
      profileMediaId: number;
      media: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      };
    }> | null;
  }> | null;
};

export type AddProfileSkillMutationVariables = Exact<{
  skillIds: Array<Scalars['Float']> | Scalars['Float'];
  profileId: Scalars['Float'];
}>;

export type AddProfileSkillMutation = { addProfileSkill: boolean };

export type AttachMediaToProfileSkillMutationVariables = Exact<{
  mediaId: Scalars['Int'];
  profileSkillId: Scalars['BigInt'];
  profileId: Scalars['Int'];
  checkPayment: Scalars['Boolean'];
}>;

export type AttachMediaToProfileSkillMutation = {
  attachMediaToProfileSkill: { mediaId: number; isLocked?: boolean | null };
};

export type UpdateProfileSkillMutationVariables = Exact<{
  input: ProfileSkillInput;
}>;

export type UpdateProfileSkillMutation = { updateProfileSkill: boolean };

export type DeleteProfileSkillMutationVariables = Exact<{
  profileSkillId: Scalars['BigInt'];
}>;

export type DeleteProfileSkillMutation = { deleteProfileSkill: boolean };

export type SubmissionSelectionFieldsFragment = {
  __typename: 'Submission';
  id: any;
  otherSubmissionSelectionType: ProfileSelectionType;
  cover?: {
    __typename: 'Media';
    created?: any | null;
    fileKey?: string | null;
    guid: string;
    id: string;
    isLocked?: boolean | null;
    mediaId: number;
    name?: string | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    thumbnail?: {
      __typename: 'Media';
      created?: any | null;
      fileKey?: string | null;
      guid: string;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
    } | null;
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
  } | null;
  profile?: {
    __typename: 'Profile';
    id: string;
    profileId: string;
    isPersonal: boolean;
    isRepresented: boolean;
    stageName: string;
    profileMainDivision?: {
      __typename: 'Organization';
      id: number;
      abbreviation?: string | null;
      name?: string | null;
      parentName?: string | null;
    } | null;
    profileType?: { __typename: 'ProfileType'; id: string; code: string } | null;
  } | null;
  role: { __typename: 'Role'; id: string; internalName?: string | null; name: string };
  submissionStatus?: { __typename: 'SubmissionStatus'; code: string; id: string } | null;
  tileSchemaValue?: { __typename: 'TileSchemaValue'; code: string; id: string } | null;
};

export type UpdateSubmissionSelectionMutationVariables = Exact<{
  input: SubmissionUpdateSelectionInput;
}>;

export type UpdateSubmissionSelectionMutation = {
  updateSubmissionSelection: {
    __typename: 'SubmissionSelectionResult';
    updatedSubmission: {
      __typename: 'Submission';
      id: any;
      otherSubmissionSelectionType: ProfileSelectionType;
      cover?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        thumbnail?: {
          __typename: 'Media';
          created?: any | null;
          fileKey?: string | null;
          guid: string;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        } | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      profile?: {
        __typename: 'Profile';
        id: string;
        profileId: string;
        isPersonal: boolean;
        isRepresented: boolean;
        stageName: string;
        profileMainDivision?: {
          __typename: 'Organization';
          id: number;
          abbreviation?: string | null;
          name?: string | null;
          parentName?: string | null;
        } | null;
        profileType?: { __typename: 'ProfileType'; id: string; code: string } | null;
      } | null;
      role: { __typename: 'Role'; id: string; internalName?: string | null; name: string };
      submissionStatus?: { __typename: 'SubmissionStatus'; code: string; id: string } | null;
      tileSchemaValue?: { __typename: 'TileSchemaValue'; code: string; id: string } | null;
    };
    otherSubmissions?: Array<{
      __typename: 'Submission';
      id: any;
      otherSubmissionSelectionType: ProfileSelectionType;
      cover?: {
        __typename: 'Media';
        created?: any | null;
        fileKey?: string | null;
        guid: string;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        thumbnail?: {
          __typename: 'Media';
          created?: any | null;
          fileKey?: string | null;
          guid: string;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
        } | null;
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
      } | null;
      profile?: {
        __typename: 'Profile';
        id: string;
        profileId: string;
        isPersonal: boolean;
        isRepresented: boolean;
        stageName: string;
        profileMainDivision?: {
          __typename: 'Organization';
          id: number;
          abbreviation?: string | null;
          name?: string | null;
          parentName?: string | null;
        } | null;
        profileType?: { __typename: 'ProfileType'; id: string; code: string } | null;
      } | null;
      role: { __typename: 'Role'; id: string; internalName?: string | null; name: string };
      submissionStatus?: { __typename: 'SubmissionStatus'; code: string; id: string } | null;
      tileSchemaValue?: { __typename: 'TileSchemaValue'; code: string; id: string } | null;
    }> | null;
  };
};

export type MoveSubmissionsToRoleMutationVariables = Exact<{
  submissionIds: Array<Scalars['BigInt']> | Scalars['BigInt'];
  tileSchemaValueCode: Scalars['String'];
  roleId: Scalars['Int'];
  submissionStatusCode?: InputMaybe<SubmissionStatusCodeEnum>;
  isWorksheet: Scalars['Boolean'];
}>;

export type MoveSubmissionsToRoleMutation = {
  submission: Array<{
    __typename: 'Submission';
    id: any;
    roleId: number;
    isOverscale?: boolean | null;
    isCustomized: boolean;
    overscaleNote?: string | null;
    hasVideoReel: boolean;
    hasAudioReel: boolean;
    picksCount?: number | null;
    commentsCount?: number | null;
    note: string;
    cover?: {
      __typename: 'Media';
      isLocked?: boolean | null;
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      thumbnail?: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    contactInfo?: { __typename: 'ProfileContactInfo'; email?: string | null; phone?: string | null } | null;
    requestedMediaCount: Array<{
      __typename: 'MediaCount';
      count: number;
      type: { __typename: 'MediaType'; id: string; code: string };
    }>;
    submissionStatus?: { __typename: 'SubmissionStatus'; id: string; name: string; code: string } | null;
    lastNote?: { __typename: 'Note'; id: number; comment: string } | null;
    tileSchemaValue?: { __typename: 'TileSchemaValue'; id: string; code: string; name: string } | null;
    workingLocation?: { __typename: 'ProfileWorkingLocation'; id: string; code: string; name: string } | null;
    role: {
      __typename: 'Role';
      id: string;
      name: string;
      internalName?: string | null;
      guid?: string | null;
      project: { __typename: 'Project'; id: string; guid?: string | null };
    };
    profile?: {
      __typename: 'Profile';
      id: string;
      guid?: string | null;
      profileId: string;
      stageName: string;
      note?: string | null;
      overscaleNote?: string | null;
      isOverscaled: boolean;
      isPersonal: boolean;
      isRepresented: boolean;
      creditsCount: number;
      hasResume?: boolean | null;
      hasAuditionHistory: boolean;
      profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
      profileType?: { __typename: 'ProfileType'; id: string; code: string } | null;
      contactInfo?: { __typename: 'ProfileContactInfo'; email?: string | null; phone?: string | null } | null;
      cover?: {
        __typename: 'Media';
        isLocked?: boolean | null;
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        thumbnail?: {
          __typename: 'Media';
          fileKey?: string | null;
          guid: string;
          height?: number | null;
          id: string;
          isLocked?: boolean | null;
          mediaId: number;
          name?: string | null;
          size?: number | null;
          source?: string | null;
          thumbnailUrl?: string | null;
          url?: string | null;
          width?: number | null;
          transformation?: {
            __typename: 'MediaTransformation';
            xAxis?: number | null;
            yAxis?: number | null;
            width?: number | null;
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
          } | null;
          mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
          fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
          mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
          mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
        } | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      } | null;
      workingLocations?: Array<{
        __typename: 'ProfileWorkingLocation';
        id: string;
        code: string;
        name: string;
        sortOrder?: number | null;
      }> | null;
      lastNote?: {
        __typename: 'Note';
        id: number;
        comment: string;
        created: any;
        noteModifier?: { __typename: 'NoteModifier'; id: string; code: string } | null;
      } | null;
      talentUnions?: Array<{
        __typename: 'TalentProfileUnion';
        id: string;
        name: string;
        code: string;
        parentObject?: { __typename: 'TalentUnion'; id: string; name: string } | null;
      }> | null;
      organization?: {
        __typename: 'Organization';
        id: number;
        phone: Array<string>;
        contacts?: Array<{
          __typename: 'Contact';
          id: string;
          mainContact: boolean;
          email?: string | null;
          phone: string;
        }> | null;
        parentOrganization?: {
          __typename: 'Organization';
          id: number;
          phone: Array<string>;
          contacts?: Array<{
            __typename: 'Contact';
            id: string;
            mainContact: boolean;
            email?: string | null;
            phone: string;
          }> | null;
        } | null;
      } | null;
      profileMainOrganization?: {
        __typename: 'Organization';
        id: number;
        name?: string | null;
        abbreviation?: string | null;
        phone: Array<string>;
        contacts?: Array<{
          __typename: 'Contact';
          id: string;
          mainContact: boolean;
          email?: string | null;
          phone: string;
        }> | null;
      } | null;
      profileMainDivision?: {
        __typename: 'Organization';
        id: number;
        name?: string | null;
        abbreviation?: string | null;
      } | null;
    } | null;
  }>;
};

export type TalentScoutContactTalentMutationVariables = Exact<{
  input: CreateTalentScoutHistoryInput;
}>;

export type TalentScoutContactTalentMutation = {
  createTalentScoutHistory: {
    id: string;
    profile?: { id: string; contactHistory?: Array<{ createdAt?: any | null }> | null } | null;
  };
};

export type TalentScoutCorrespondenceHistoryQueryVariables = Exact<{
  page: PageInput;
}>;

export type TalentScoutCorrespondenceHistoryQuery = {
  getTalentScoutHistory?: {
    page: number;
    totalCount: number;
    totalPages: number;
    data: Array<{
      id: string;
      message: string;
      createdAt: any;
      profile?: { stageName: string } | null;
      createdBy?: { firstName: string; lastName: string } | null;
    }>;
  } | null;
};

export type TalentScoutSearchProfilesQueryVariables = Exact<{
  page?: InputMaybe<PageInput>;
  input: ProfileSearchInput;
  sortOptions?: InputMaybe<Array<ProfileSearchSortOption> | ProfileSearchSortOption>;
}>;

export type TalentScoutSearchProfilesQuery = {
  talentScoutSearch?: {
    totalCount?: number | null;
    profiles?: Array<{
      id: string;
      dateOfBirth?: any | null;
      isActive: boolean;
      minor?: boolean | null;
      profileId: string;
      stageName: string;
      noteToRepresentative?: string | null;
      hasAudioReel?: boolean | null;
      hasResume?: boolean | null;
      hasVideoReel?: boolean | null;
      cover?: {
        id: string;
        fileKey?: string | null;
        transformation?: {
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
          width?: number | null;
          xAxis?: number | null;
          yAxis?: number | null;
        } | null;
        thumbnail?: {
          id: string;
          fileKey?: string | null;
          transformation?: {
            height?: number | null;
            rotate?: number | null;
            startOffset?: string | null;
            width?: number | null;
            xAxis?: number | null;
            yAxis?: number | null;
          } | null;
        } | null;
      } | null;
      height?: {
        id: string;
        IMPERIAL_FEET_INCHES?: string | null;
        METRIC_CENTIMETERS: number;
        cm: number;
        showInImperialUI: boolean;
      } | null;
      talentUnions?: Array<{
        id: string;
        name: string;
        talentMembershipId?: string | null;
        parentObject?: { id: string; name: string } | null;
      }> | null;
      weight?: {
        id: string;
        IMPERIAL_LBS: number;
        kg: number;
        kgRounded: number;
        lbs: number;
        showInMetricUI: boolean;
      } | null;
      workingLocations?: Array<{
        id: string;
        code: string;
        name: string;
        region?: { code: string; name: string; country?: { code: string; name: string } | null } | null;
      }> | null;
      contactHistory?: Array<{ createdAt?: any | null }> | null;
      seekingRepresentation?: Array<{ id: string; name: string }> | null;
    }> | null;
    facets?: Array<{ name: string; buckets: Array<{ docCount: number; key: string }> }> | null;
  } | null;
};

export type TalentScoutProfileFragment = {
  id: string;
  dateOfBirth?: any | null;
  isActive: boolean;
  minor?: boolean | null;
  profileId: string;
  stageName: string;
  noteToRepresentative?: string | null;
  hasAudioReel?: boolean | null;
  hasResume?: boolean | null;
  hasVideoReel?: boolean | null;
  cover?: {
    id: string;
    fileKey?: string | null;
    transformation?: {
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
      width?: number | null;
      xAxis?: number | null;
      yAxis?: number | null;
    } | null;
    thumbnail?: {
      id: string;
      fileKey?: string | null;
      transformation?: {
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
        width?: number | null;
        xAxis?: number | null;
        yAxis?: number | null;
      } | null;
    } | null;
  } | null;
  height?: {
    id: string;
    IMPERIAL_FEET_INCHES?: string | null;
    METRIC_CENTIMETERS: number;
    cm: number;
    showInImperialUI: boolean;
  } | null;
  talentUnions?: Array<{
    id: string;
    name: string;
    talentMembershipId?: string | null;
    parentObject?: { id: string; name: string } | null;
  }> | null;
  weight?: {
    id: string;
    IMPERIAL_LBS: number;
    kg: number;
    kgRounded: number;
    lbs: number;
    showInMetricUI: boolean;
  } | null;
  workingLocations?: Array<{
    id: string;
    code: string;
    name: string;
    region?: { code: string; name: string; country?: { code: string; name: string } | null } | null;
  }> | null;
  contactHistory?: Array<{ createdAt?: any | null }> | null;
  seekingRepresentation?: Array<{ id: string; name: string }> | null;
};

export type TalentScoutProfileContactHistoryFragment = {
  id: string;
  contactHistory?: Array<{ createdAt?: any | null }> | null;
};

export type TalentScoutProfilePitchNotesFragment = { id: string; noteToRepresentative?: string | null };

export type TalentScoutProfileSeekingRepresentationFragment = {
  id: string;
  seekingRepresentation?: Array<{ id: string; name: string }> | null;
};

export type TalentScoutSeekingRepresentationFragment = { id: string; name: string };

export type UnlockMediaMutationVariables = Exact<{
  mediaIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UnlockMediaMutation = { unlockMedia: { success: boolean } };

export type UpdateProfileMediaVisibilityMutationVariables = Exact<{
  mediaId: Scalars['Int'];
  profileIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type UpdateProfileMediaVisibilityMutation = { updateProfileMediaVisibility: boolean };

export type WorksheetSubmissionFragment = {
  __typename: 'Submission';
  id: any;
  isOverscale?: boolean | null;
  isCustomized: boolean;
  overscaleNote?: string | null;
  hasVideoReel: boolean;
  hasAudioReel: boolean;
  picksCount?: number | null;
  commentsCount?: number | null;
  roleId: number;
  note: string;
  cover?: {
    __typename: 'Media';
    isLocked?: boolean | null;
    fileKey?: string | null;
    guid: string;
    height?: number | null;
    id: string;
    mediaId: number;
    name?: string | null;
    size?: number | null;
    source?: string | null;
    thumbnailUrl?: string | null;
    url?: string | null;
    width?: number | null;
    thumbnail?: {
      __typename: 'Media';
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      isLocked?: boolean | null;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    transformation?: {
      __typename: 'MediaTransformation';
      xAxis?: number | null;
      yAxis?: number | null;
      width?: number | null;
      height?: number | null;
      rotate?: number | null;
      startOffset?: string | null;
    } | null;
    mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
    fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
    mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
    mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
  } | null;
  contactInfo?: { __typename: 'ProfileContactInfo'; email?: string | null; phone?: string | null } | null;
  requestedMediaCount: Array<{
    __typename: 'MediaCount';
    count: number;
    type: { __typename: 'MediaType'; id: string; code: string };
  }>;
  submissionStatus?: { __typename: 'SubmissionStatus'; id: string; name: string; code: string } | null;
  lastNote?: { __typename: 'Note'; id: number; comment: string } | null;
  tileSchemaValue?: { __typename: 'TileSchemaValue'; id: string; code: string; name: string } | null;
  workingLocation?: { __typename: 'ProfileWorkingLocation'; id: string; code: string; name: string } | null;
  role: {
    __typename: 'Role';
    id: string;
    name: string;
    internalName?: string | null;
    guid?: string | null;
    project: { __typename: 'Project'; id: string; guid?: string | null };
  };
  profile?: {
    __typename: 'Profile';
    id: string;
    guid?: string | null;
    profileId: string;
    stageName: string;
    note?: string | null;
    overscaleNote?: string | null;
    isOverscaled: boolean;
    isPersonal: boolean;
    isRepresented: boolean;
    creditsCount: number;
    hasResume?: boolean | null;
    hasAuditionHistory: boolean;
    profileStatus?: { __typename: 'ProfileStatus'; id: string; code: string } | null;
    profileType?: { __typename: 'ProfileType'; id: string; code: string } | null;
    contactInfo?: { __typename: 'ProfileContactInfo'; email?: string | null; phone?: string | null } | null;
    cover?: {
      __typename: 'Media';
      isLocked?: boolean | null;
      fileKey?: string | null;
      guid: string;
      height?: number | null;
      id: string;
      mediaId: number;
      name?: string | null;
      size?: number | null;
      source?: string | null;
      thumbnailUrl?: string | null;
      url?: string | null;
      width?: number | null;
      thumbnail?: {
        __typename: 'Media';
        fileKey?: string | null;
        guid: string;
        height?: number | null;
        id: string;
        isLocked?: boolean | null;
        mediaId: number;
        name?: string | null;
        size?: number | null;
        source?: string | null;
        thumbnailUrl?: string | null;
        url?: string | null;
        width?: number | null;
        transformation?: {
          __typename: 'MediaTransformation';
          xAxis?: number | null;
          yAxis?: number | null;
          width?: number | null;
          height?: number | null;
          rotate?: number | null;
          startOffset?: string | null;
        } | null;
        mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
        fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
        mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
        mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
      } | null;
      transformation?: {
        __typename: 'MediaTransformation';
        xAxis?: number | null;
        yAxis?: number | null;
        width?: number | null;
        height?: number | null;
        rotate?: number | null;
        startOffset?: string | null;
      } | null;
      mediaStorageStatus: { __typename: 'MediaStorageStatus'; id: string; code: string };
      fileType: { __typename: 'MediaFileType'; id: string; code: string; name: string };
      mediaType?: { __typename: 'MediaType'; id: string; code: string } | null;
      mediaStatus: { __typename: 'MediaStatus'; id: string; code: string };
    } | null;
    workingLocations?: Array<{
      __typename: 'ProfileWorkingLocation';
      id: string;
      code: string;
      name: string;
      sortOrder?: number | null;
    }> | null;
    lastNote?: {
      __typename: 'Note';
      id: number;
      comment: string;
      created: any;
      noteModifier?: { __typename: 'NoteModifier'; id: string; code: string } | null;
    } | null;
    talentUnions?: Array<{
      __typename: 'TalentProfileUnion';
      id: string;
      name: string;
      code: string;
      parentObject?: { __typename: 'TalentUnion'; id: string; name: string } | null;
    }> | null;
    organization?: {
      __typename: 'Organization';
      id: number;
      phone: Array<string>;
      contacts?: Array<{
        __typename: 'Contact';
        id: string;
        mainContact: boolean;
        email?: string | null;
        phone: string;
      }> | null;
      parentOrganization?: {
        __typename: 'Organization';
        id: number;
        phone: Array<string>;
        contacts?: Array<{
          __typename: 'Contact';
          id: string;
          mainContact: boolean;
          email?: string | null;
          phone: string;
        }> | null;
      } | null;
    } | null;
    profileMainOrganization?: {
      __typename: 'Organization';
      id: number;
      name?: string | null;
      abbreviation?: string | null;
      phone: Array<string>;
      contacts?: Array<{
        __typename: 'Contact';
        id: string;
        mainContact: boolean;
        email?: string | null;
        phone: string;
      }> | null;
    } | null;
    profileMainDivision?: {
      __typename: 'Organization';
      id: number;
      name?: string | null;
      abbreviation?: string | null;
    } | null;
  } | null;
};

export const MediaTransformationDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MediaTransformationFragment, unknown>;
export const SearchMediaProfileMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaProfileMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentOrganization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchMediaProfileMediaFragment, unknown>;
export const SearchMediaBaseResultDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaBaseResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivableOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnSubmissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnMediaRequests' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaProfileMedia' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaProfileMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentOrganization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchMediaBaseResultFragment, unknown>;
export const MediaThumbnailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MediaThumbnailFragment, unknown>;
export const SearchMediaResultDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaBaseResult' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaThumbnail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByOrg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displaySize' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moderationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purpose' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaProfileMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentOrganization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaBaseResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivableOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnSubmissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnMediaRequests' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaProfileMedia' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchMediaResultFragment, unknown>;
export const AuditionMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionGroupMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaResult' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaProfileMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentOrganization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaBaseResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivableOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnSubmissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnMediaRequests' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaProfileMedia' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaBaseResult' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaThumbnail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByOrg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displaySize' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moderationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purpose' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionMediaFragment, unknown>;
export const AuditionGroupDetailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionGroupDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditionSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaResult' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'medias' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionMedia' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaProfileMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentOrganization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaBaseResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivableOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnSubmissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnMediaRequests' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaProfileMedia' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaBaseResult' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaThumbnail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByOrg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displaySize' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moderationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purpose' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionGroupMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaResult' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionGroupDetailFragment, unknown>;
export const BaseMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseMediaFragment, unknown>;
export const BaseMediaWithThumbnailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMediaWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseMediaWithThumbnailFragment, unknown>;
export const MediaAssetOnSubmissionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetOnSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmissionMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderOfAppearance' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MediaAssetOnSubmissionFragment, unknown>;
export const AuditionGroupSubProfileDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionGroupSubProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'noteType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionGroupSubProfileFragment, unknown>;
export const AuditionGroupSubmissionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionGroupSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Submission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'picksCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMediaWithThumbnail' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetOnSubmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionGroupSubProfile' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMediaWithThumbnail' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fastcastNotes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeZone' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'standardName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMediaWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetOnSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmissionMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderOfAppearance' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionGroupSubProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'noteType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionGroupSubmissionFragment, unknown>;
export const AuditionSessionViewAuditionSessionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionViewAuditionSession' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionSession' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionSessionViewAuditionSessionFragment, unknown>;
export const AuditionSessionViewCoverMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionViewCoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionSessionViewCoverMediaFragment, unknown>;
export const CoverMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoverMediaFragment, unknown>;
export const AuditionSessionViewAuditionSubmissionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionViewAuditionSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Submission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverMedia' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverMedia' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roleName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionSessionViewAuditionSubmissionFragment, unknown>;
export const AuditionSessionViewAuditionSessionGroupDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionViewAuditionSessionGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'auditionSessionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionSessionViewCoverMedia' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'submissionsCount' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionSessionViewAuditionSubmission' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionViewCoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionViewAuditionSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Submission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverMedia' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverMedia' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roleName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionSessionViewAuditionSessionGroupFragment, unknown>;
export const AuditionSessionsForProjectAuditionSessionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionsForProjectAuditionSession' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionSession' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionSessionsForProjectAuditionSessionFragment, unknown>;
export const AuthTokensDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthTokens' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthToken' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refresh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthTokensFragment, unknown>;
export const ArtistSubscriptionPlanDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscriptionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingPlan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBasePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isComped' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'term' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArtistSubscriptionPlanFragment, unknown>;
export const ArtistSubscriptionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscription' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialLengthDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingInfoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscriptionPlan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscriptionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingPlan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBasePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isComped' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'term' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArtistSubscriptionFragment, unknown>;
export const UserContextBaseMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContextBaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserContextBaseMediaFragment, unknown>;
export const AccountOrgDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sytemRoleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemRoleCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContextBaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountOrgFragment, unknown>;
export const MediaAssetWithThumbnailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContextBaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MediaAssetWithThumbnailFragment, unknown>;
export const AccountProfileDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPlayableAgeLockedToTalent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeekingRepresentation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noteToRepresentative' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPrimary' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetWithThumbnail' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileBookOuts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileBookoutId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileVisiblities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seekingRepresentations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContextBaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sytemRoleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemRoleCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountProfileFragment, unknown>;
export const AccountArtistDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountArtist' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Artist' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAugeoBenefits' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscription' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscription' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountProfile' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscriptionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingPlan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBasePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isComped' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'term' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContextBaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sytemRoleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemRoleCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscription' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialLengthDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingInfoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscriptionPlan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPlayableAgeLockedToTalent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeekingRepresentation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noteToRepresentative' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPrimary' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetWithThumbnail' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileBookOuts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileBookoutId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileVisiblities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seekingRepresentations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountArtistFragment, unknown>;
export const UserContextDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContext' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailResetToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isIPC' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInPCContext' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastArtistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rosterInvitationToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSystemRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'artists' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'isTalent' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtist' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardExpirationYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardExpirationMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'continent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unitSystem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'supportingCountry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'divisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedAccountOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dmas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showUnionStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectListFilters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRequests' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSelects' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSubmissions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isKidsRolesOnly' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectViewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submissionDueDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'castingOrganizations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectRegion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectUnion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isBasePlan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComped' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeZone' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmtOffset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'standardName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscriptionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingPlan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBasePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isComped' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'term' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscription' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialLengthDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingInfoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscriptionPlan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContextBaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sytemRoleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemRoleCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPlayableAgeLockedToTalent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeekingRepresentation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noteToRepresentative' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPrimary' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetWithThumbnail' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileBookOuts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileBookoutId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileVisiblities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seekingRepresentations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountArtist' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Artist' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAugeoBenefits' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscription' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscription' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountProfile' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserContextFragment, unknown>;
export const CollaboratorDetailsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollaboratorDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollaboratorsInvite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaborator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collaboratorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaboratorInviteFromOrgDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaboratorInviteProjectDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollaboratorDetailsFragment, unknown>;
export const CollaboratorsInOrganizationDetailsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollaboratorsInOrganizationDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollaboratorsInOrganization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaboratorDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collaboratorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaboratorsProjectDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageProjects' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectDetailsPage' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollaboratorsInOrganizationDetailsFragment, unknown>;
export const CreditTypeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreditTypeFragment, unknown>;
export const CreditOnProfileDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditOnProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'director' } },
          { kind: 'Field', name: { kind: 'Name', value: 'highlighted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileCreditTypeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreditOnProfileFragment, unknown>;
export const MediaAssetOnProfileDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetOnProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MediaAssetOnProfileFragment, unknown>;
export const ProfileWithCreditsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileWithCredits' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCreditYear' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditOnProfile' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaAssets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetOnProfile' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditOnProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'director' } },
          { kind: 'Field', name: { kind: 'Name', value: 'highlighted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileCreditTypeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetOnProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileWithCreditsFragment, unknown>;
export const CustomSlugUrlDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomSlugURL' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomSlug' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creationCooldownDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expiredReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slugName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomSlugUrlFragment, unknown>;
export const ContinentDetailsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContinentDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Continent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContinentDetailsFragment, unknown>;
export const CountryDetailsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CountryDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Country' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'continent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContinentDetails' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supportingCountry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'continent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContinentDetails' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContinentDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Continent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountryDetailsFragment, unknown>;
export const RegionDetailsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Region' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CountryDetails' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContinentDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Continent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CountryDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Country' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'continent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContinentDetails' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supportingCountry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'continent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContinentDetails' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegionDetailsFragment, unknown>;
export const DmaDetailsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DmaDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dma' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RegionDetails' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContinentDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Continent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CountryDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Country' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'continent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContinentDetails' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supportingCountry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'continent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContinentDetails' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Region' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CountryDetails' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DmaDetailsFragment, unknown>;
export const TimeZoneDetailsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeZoneDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TimeZone' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codeBrowser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TimeZoneDetailsFragment, unknown>;
export const EditProfileWithTalentEmailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditProfileWithTalentEmail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'talentEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditProfileWithTalentEmailFragment, unknown>;
export const EmailHistoryItemDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailHistoryItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmailHistory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'emailType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'messageToReps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageToTalents' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailHistoryItemFragment, unknown>;
export const ProjectSeriesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectSeries' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectSeries' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seriesName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectSeriesFragment, unknown>;
export const ProjectSessionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectSession' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionSession' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectSessionFragment, unknown>;
export const BaseFormDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseForm' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FormGqlModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formSchema' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseFormFragment, unknown>;
export const BlockedKeywordResponseDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockedKeywordResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlockedKeyword' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blockedReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'blockedReasonNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockedKeywordResponseFragment, unknown>;
export const GetBlockedKeywordsPaginatedListResponseDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetBlockedKeywordsPaginatedListResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlockedKeywordPaginatedResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'objects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockedKeywordResponse' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockedKeywordResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlockedKeyword' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blockedReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'blockedReasonNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBlockedKeywordsPaginatedListResponseFragment, unknown>;
export const ProhibitedCustomSlugResponseDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProhibitedCustomSlugResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProhibitedCustomSlug' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slugName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blockedKeyword' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProhibitedCustomSlugResponseFragment, unknown>;
export const FlaggedArtistUrlsPaginatedResultDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlaggedArtistUrlsPaginatedResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginatedProhibitedCustomSlug' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'objects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProhibitedCustomSlugResponse' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProhibitedCustomSlugResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProhibitedCustomSlug' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slugName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blockedKeyword' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FlaggedArtistUrlsPaginatedResultFragment, unknown>;
export const LanguageDetailsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LanguageInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LanguageDetailsFragment, unknown>;
export const ProfileWithManagerEmailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileWithManagerEmail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileWithManagerEmailFragment, unknown>;
export const InitMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InitMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitMediaFragment, unknown>;
export const UploadMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UploadMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadMediaFragment, unknown>;
export const VehicleTypeMakeFieldsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VehicleTypeMakeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VehicleTypeMake' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'make' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VehicleTypeMakeFieldsFragment, unknown>;
export const PetSizeFieldsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PetSizeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PetSize' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PetSizeFieldsFragment, unknown>;
export const ProfileMeasurementsHeightDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsHeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Height' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_FEET_INCHES' } },
          { kind: 'Field', name: { kind: 'Name', value: 'METRIC_CENTIMETERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInImperialUI' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileMeasurementsHeightFragment, unknown>;
export const ProfileMeasurementsWeightDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsWeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Weight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_LBS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kgRounded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInMetricUI' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileMeasurementsWeightFragment, unknown>;
export const ProfileMeasurementsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurements' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'height' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMeasurementsHeight' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMeasurementsWeight' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsHeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Height' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_FEET_INCHES' } },
          { kind: 'Field', name: { kind: 'Name', value: 'METRIC_CENTIMETERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInImperialUI' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsWeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Weight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_LBS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kgRounded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInMetricUI' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileMeasurementsFragment, unknown>;
export const ProjectRolePubSettingSummaryDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRolePubSettingSummary' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleCountOfPairedRepsAndCB' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repsOrgs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSharedWithCB' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRolePubSettingSummaryFragment, unknown>;
export const ProjectRoleCompensationsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleCompensations' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleCompensation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherCompensation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleCompensationId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compensation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleCompensationsFragment, unknown>;
export const ProjectRoleEthnicAppDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleEthnicApp' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EthnicAppearance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleEthnicAppFragment, unknown>;
export const ProjectRoleFieldRestrictionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleFieldRestriction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleFieldRestriction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedFor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleFieldRestrictionFragment, unknown>;
export const ProjectRoleGenderAppDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleGenderApp' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GenderAppearance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleGenderAppFragment, unknown>;
export const ProjectRoleLocationDateDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleLocationDate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleDateWithLocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleDateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleLocationDateFragment, unknown>;
export const ProjectRolePaidTypeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRolePaidType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaidType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRolePaidTypeFragment, unknown>;
export const ProjectRoleMediaItemDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleMediaItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSides' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleMediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thumbnail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleMediaItemFragment, unknown>;
export const ProjectRolePubSettingDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRolePubSetting' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RolePublicationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'representativeListIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dmas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'region' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repAgeCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicationSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRolePubSettingFragment, unknown>;
export const ProjectRoleRatePerDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleRatePer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RatePerType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleRatePerFragment, unknown>;
export const ProjectRoleStatusDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleStatusFragment, unknown>;
export const ProjectRoleTypeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleTarget' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleTypeFragment, unknown>;
export const ProjectRoleUnionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleUnion' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleUnion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleUnionFragment, unknown>;
export const ProjectRoleSexualSituationDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSexualSituation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SexualSituation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleSexualSituationFragment, unknown>;
export const ProjectRoleSidesVisibilityDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSidesVisibility' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleSidesVisibility' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleSidesVisibilityFragment, unknown>;
export const ProjectRoleSkillDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSkill' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Skill' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleSkillFragment, unknown>;
export const ProjectRoleSubmissionCountDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSubmissionCount' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleSubmissionCount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleSubmissionCountFragment, unknown>;
export const ProjectRoleTimeZoneDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleTimeZone' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TimeZone' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'standardName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectRoleTimeZoneFragment, unknown>;
export const ProjectDetailRoleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailRole' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Role' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ageInYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ageMaxMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ageMinMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundTalentIntended' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closeSubmissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayedRoleUnion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOpenCall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestMediaInstruction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestMediaTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleEthnicityNationality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sexualSituationDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'spot' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionInstruction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wardrobe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workRequirement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countOfPairedRepsAndCB' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRolePubSettingSummary' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compensations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleCompensations' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ethnicAppearance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleEthnicApp' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldRestrictions' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleFieldRestriction' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'genderAppearance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleGenderApp' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleLocationDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paidType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRolePaidType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleMediaItem' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRolePubSetting' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ratePer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleRatePer' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleStatus' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleUnion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleUnion' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sexualSituations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleSexualSituation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sides' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleMediaItem' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sidesVisibility' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleSidesVisibility' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleSkill' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleSubmissionCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeZone' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleTimeZone' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRolePubSettingSummary' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleCountOfPairedRepsAndCB' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repsOrgs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSharedWithCB' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleCompensations' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleCompensation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherCompensation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleCompensationId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compensation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleEthnicApp' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EthnicAppearance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleFieldRestriction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleFieldRestriction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedFor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleGenderApp' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GenderAppearance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleLocationDate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleDateWithLocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleDateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRolePaidType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaidType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleMediaItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSides' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleMediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thumbnail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRolePubSetting' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RolePublicationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'representativeListIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dmas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'region' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repAgeCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicationSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleRatePer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RatePerType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleTarget' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleUnion' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleUnion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSexualSituation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SexualSituation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSidesVisibility' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleSidesVisibility' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSkill' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Skill' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSubmissionCount' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleSubmissionCount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleTimeZone' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TimeZone' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'standardName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailRoleFragment, unknown>;
export const ProjectDetailAuditionTypeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailAuditionType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailAuditionTypeFragment, unknown>;
export const ProjectDetailFieldRestrictionsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailFieldRestrictions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectFieldRestriction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedFor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailFieldRestrictionsFragment, unknown>;
export const ProjectDetailMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailMediaFragment, unknown>;
export const ProjectDetailConflictsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailConflicts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conflict' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailConflictsFragment, unknown>;
export const ProjectDetailSeriesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailSeries' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeriesDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesEpisode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesSeason' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailSeriesFragment, unknown>;
export const ProjectDetailStatusDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailStatusFragment, unknown>;
export const ProjectDetailTypeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailTypeFragment, unknown>;
export const ProjectDetailUnionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailUnion' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectUnionDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'union' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentObject' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailUnionFragment, unknown>;
export const ProjectDetailRoleDefaultsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailRoleDefaults' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleDefaults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wardrobe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workRequirements' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldRestrictions' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restrictedFor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicationSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dmas' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repAgeCategories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repCategories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'representativeListId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'representativeIds' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateTypeCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailRoleDefaultsFragment, unknown>;
export const ProjectDetailSidesVisibilityDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailSidesVisibility' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectSidesVisibility' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailSidesVisibilityFragment, unknown>;
export const ProjectDetailReleaseLocationsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailReleaseLocations' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dma' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailReleaseLocationsFragment, unknown>;
export const CastingProjectDetailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CastingProjectDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adAgency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalProvisions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentDisplayTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'castingAssistant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'castingAssociate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'castingCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'castingDirector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conflictNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactInfoPermissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByTalentSystemUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'director' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayedProjectUnion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'executiveProducer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'network' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photographer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'producer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productionCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportTicketNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'synopsis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditionType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailAuditionType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldRestrictions' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailFieldRestrictions' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailMedia' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectConflicts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailConflicts' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectSeries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailSeries' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailStatus' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectUnion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailUnion' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleDefaults' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailRoleDefaults' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sideVisibility' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailSidesVisibility' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releaseLocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailReleaseLocations' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailAuditionType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailFieldRestrictions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectFieldRestriction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedFor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailConflicts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conflict' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailSeries' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeriesDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesEpisode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesSeason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailUnion' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectUnionDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'union' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentObject' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailRoleDefaults' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleDefaults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wardrobe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workRequirements' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldRestrictions' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restrictedFor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicationSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dmas' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repAgeCategories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repCategories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'representativeListId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'representativeIds' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateTypeCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailSidesVisibility' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectSidesVisibility' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailReleaseLocations' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dma' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CastingProjectDetailFragment, unknown>;
export const ProjectDetailViewMediaRequestBaseWorkflowMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailViewMediaRequestBaseWorkflowMediaFragment, unknown>;
export const ProjectDetailViewMediaRequestWorkflowMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailViewMediaRequestWorkflowMediaFragment, unknown>;
export const ProjectDetailViewMediaRequestWorkflowCoverMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailViewMediaRequestWorkflowCoverMediaFragment, unknown>;
export const ProjectDetailViewMediaRequestTitleSchemaValueDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileSchemaValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailViewMediaRequestTitleSchemaValueFragment, unknown>;
export const ProjectDetailViewMediaRequestSubmissionLastNoteDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailViewMediaRequestSubmissionLastNoteFragment, unknown>;
export const ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivisionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivisionFragment, unknown>;
export const ProjectDetailViewMediaRequestWfProfileLastNoteDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'noteModifier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailViewMediaRequestWfProfileLastNoteFragment, unknown>;
export const ProjectDetailViewMediaRequestWfSubmissionResponseDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfSubmissionResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOverscale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'requestedMediaCount' },
            name: { kind: 'Name', value: 'mediaCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requested' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wardrobe' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOverscaled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAuditionHistory' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'talentUnions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inch' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingLocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noteModifier' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileSchemaValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'noteModifier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailViewMediaRequestWfSubmissionResponseFragment, unknown>;
export const ProjectDetailViewMediaRequestResponseDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelledAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAdditional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowMedia' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfSubmissionResponse' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'chatTopicKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chatTopic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'topicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastUserMessage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formResponseId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileSchemaValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'noteModifier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfSubmissionResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOverscale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'requestedMediaCount' },
            name: { kind: 'Name', value: 'mediaCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requested' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wardrobe' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOverscaled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAuditionHistory' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'talentUnions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inch' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingLocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noteModifier' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailViewMediaRequestResponseFragment, unknown>;
export const ProjectDetailViewMediaRequestResponsePagedResultsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponsePagedResults' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaResponsePagedList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponse' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileSchemaValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'noteModifier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfSubmissionResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOverscale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'requestedMediaCount' },
            name: { kind: 'Name', value: 'mediaCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requested' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wardrobe' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOverscaled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAuditionHistory' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'talentUnions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inch' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingLocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noteModifier' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelledAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAdditional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowMedia' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfSubmissionResponse' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'chatTopicKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chatTopic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'topicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastUserMessage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formResponseId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDetailViewMediaRequestResponsePagedResultsFragment, unknown>;
export const ProjectListRowDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectListRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectBase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportTicketNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'castingCompanyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seriesName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastModifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectListRowFragment, unknown>;
export const ProjectListGroupSeriesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectListGroupSeries' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectSeries' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seriesName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectListGroupSeriesFragment, unknown>;
export const ProjectListGroupStatusDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectListGroupStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectListGroupStatusFragment, unknown>;
export const ProjectListGroupTypeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectListGroupType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childObjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectListGroupTypeFragment, unknown>;
export const AllTalentReportSubmissionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllTalentReportSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllTalentSubmissionCountModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'auditionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callbacksCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaRequestCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFormerClient' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionsCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllTalentReportSubmissionFragment, unknown>;
export const AllTalentReportDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllTalentReport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllTalentSubmissionModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeFormerClient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'castingCompanyInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mediaStorageStatus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllTalentReportSubmission' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllTalentReportSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllTalentSubmissionCountModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'auditionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callbacksCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaRequestCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFormerClient' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionsCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllTalentReportFragment, unknown>;
export const SingleTalentReportDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleTalentReport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TalentSubmissionModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateTo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'castingCompanyInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mediaStorageStatus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submissionDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ageRange' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'castingDirector' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roleName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mediaRequest' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleTalentReportFragment, unknown>;
export const StatsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stats' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleTrackerStatsPeriod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submitted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'underConsideration' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatsFragment, unknown>;
export const RoleTrackerProjectDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleTrackerProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoleTrackerProjectFragment, unknown>;
export const RoleTrackerRoleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleTrackerRole' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Role' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleTrackerProject' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleTrackerProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoleTrackerRoleFragment, unknown>;
export const RoleTrackerDataDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleTrackerData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleTracker' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastStatusUpdated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleTrackerStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleTrackerRole' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleTrackerProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleTrackerRole' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Role' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleTrackerProject' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoleTrackerDataFragment, unknown>;
export const AccountArtistMediaEntitlementDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionMediaEntitlement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnlimited' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountArtistMediaEntitlementFragment, unknown>;
export const ArtistMediaCtxDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistMediaCtx' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Artist' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionMediaUsage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'available' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entitle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionMediaEntitlement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnlimited' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArtistMediaCtxFragment, unknown>;
export const SkillWithParentAndChildrenDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkillWithParentAndChildren' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Skill' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childObjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childObjects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SkillWithParentAndChildrenFragment, unknown>;
export const SkillLevelDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkillLevel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SkillLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SkillLevelFragment, unknown>;
export const SkillOnProfileDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkillOnProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileSkill' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileSkillId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentObject' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'level' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SkillOnProfileFragment, unknown>;
export const SubmissionSelectionFieldsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubmissionSelectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Submission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherSubmissionSelectionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetWithThumbnail' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileMainDivision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileSchemaValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContextBaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmissionSelectionFieldsFragment, unknown>;
export const BaseballCardIndicatorsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardIndicators' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseballCardIndicatorsFragment, unknown>;
export const BaseballCardMediaTransformationDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardMediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseballCardMediaTransformationFragment, unknown>;
export const BaseballCardMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMediaTransformation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMediaTransformation' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardMediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseballCardMediaFragment, unknown>;
export const BaseballCardHeightDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardHeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Height' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMeasurementsHeight' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsHeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Height' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_FEET_INCHES' } },
          { kind: 'Field', name: { kind: 'Name', value: 'METRIC_CENTIMETERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInImperialUI' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseballCardHeightFragment, unknown>;
export const BaseballCardTalentUnionsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardTalentUnions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TalentProfileUnion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'talentMembershipId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseballCardTalentUnionsFragment, unknown>;
export const BaseballCardWeightDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardWeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Weight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMeasurementsWeight' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsWeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Weight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_LBS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kgRounded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInMetricUI' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseballCardWeightFragment, unknown>;
export const BaseballCardWorkingLocationsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardWorkingLocations' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileWorkingLocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseballCardWorkingLocationsFragment, unknown>;
export const BaseballCardProfileDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardIndicators' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMedia' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'height' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardHeight' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talentUnions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardTalentUnions' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardWeight' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workingLocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardWorkingLocations' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardMediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsHeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Height' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_FEET_INCHES' } },
          { kind: 'Field', name: { kind: 'Name', value: 'METRIC_CENTIMETERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInImperialUI' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsWeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Weight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_LBS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kgRounded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInMetricUI' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardIndicators' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMediaTransformation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMediaTransformation' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardHeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Height' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMeasurementsHeight' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardTalentUnions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TalentProfileUnion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'talentMembershipId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardWeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Weight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMeasurementsWeight' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardWorkingLocations' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileWorkingLocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseballCardProfileFragment, unknown>;
export const TalentScoutProfileContactHistoryDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfileContactHistory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'contactHistory' },
            name: { kind: 'Name', value: 'contactedByRep' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TalentScoutProfileContactHistoryFragment, unknown>;
export const TalentScoutProfilePitchNotesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfilePitchNotes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noteToRepresentative' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TalentScoutProfilePitchNotesFragment, unknown>;
export const TalentScoutSeekingRepresentationDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutSeekingRepresentation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeekingRepresentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TalentScoutSeekingRepresentationFragment, unknown>;
export const TalentScoutProfileSeekingRepresentationDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfileSeekingRepresentation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'seekingRepresentation' },
            name: { kind: 'Name', value: 'seekingRepresentations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutSeekingRepresentation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutSeekingRepresentation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeekingRepresentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TalentScoutProfileSeekingRepresentationFragment, unknown>;
export const TalentScoutProfileDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardProfile' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutProfileContactHistory' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutProfilePitchNotes' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutProfileSeekingRepresentation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardIndicators' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardMediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMediaTransformation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMediaTransformation' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsHeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Height' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_FEET_INCHES' } },
          { kind: 'Field', name: { kind: 'Name', value: 'METRIC_CENTIMETERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInImperialUI' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardHeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Height' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMeasurementsHeight' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardTalentUnions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TalentProfileUnion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'talentMembershipId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsWeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Weight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_LBS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kgRounded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInMetricUI' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardWeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Weight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMeasurementsWeight' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardWorkingLocations' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileWorkingLocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutSeekingRepresentation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeekingRepresentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardIndicators' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMedia' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'height' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardHeight' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talentUnions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardTalentUnions' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardWeight' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workingLocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardWorkingLocations' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfileContactHistory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'contactHistory' },
            name: { kind: 'Name', value: 'contactedByRep' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfilePitchNotes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noteToRepresentative' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfileSeekingRepresentation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'seekingRepresentation' },
            name: { kind: 'Name', value: 'seekingRepresentations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutSeekingRepresentation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TalentScoutProfileFragment, unknown>;
export const ProfileMainOrganizationAndMainDivisionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMainOrganizationAndMainDivision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileMainOrganizationAndMainDivisionFragment, unknown>;
export const WorksheetSubmissionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorksheetSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Submission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOverscale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverMedia' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'picksCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'requestedMediaCount' },
            name: { kind: 'Name', value: 'mediaCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requested' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileSchemaValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workingLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOverscaled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cover' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverMedia' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingLocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastNote' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noteModifier' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'talentUnions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMainOrganizationAndMainDivision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAuditionHistory' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMainOrganizationAndMainDivision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorksheetSubmissionFragment, unknown>;
export const AddArtistFreeCreditsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddArtistFreeCredits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddArtistFreeCreditsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addArtistFreeCredits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddArtistFreeCreditsMutation, AddArtistFreeCreditsMutationVariables>;
export const AddBlockedKeywordDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddBlockedKeyword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'keyword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'blockedReason' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomSlugBlockedReasonEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'blockedReasonNote' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addBlockedKeyword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keyword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'keyword' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'blockedReason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'blockedReason' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'blockedReasonNote' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'blockedReasonNote' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockedKeywordResponse' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockedKeywordResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlockedKeyword' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blockedReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'blockedReasonNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddBlockedKeywordMutation, AddBlockedKeywordMutationVariables>;
export const ArchiveMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ArchiveMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mediaIds' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveMediaMutation, ArchiveMediaMutationVariables>;
export const ArchiveMediaV2Doc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ArchiveMediaV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveMediaAssetV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveMediaV2Mutation, ArchiveMediaV2MutationVariables>;
export const AuditionGroupQueryDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuditionGroupQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditionGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionGroupDetail' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaProfileMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentOrganization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaBaseResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivableOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnSubmissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnMediaRequests' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaProfileMedia' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaBaseResult' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaThumbnail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByOrg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displaySize' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moderationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purpose' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionGroupMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaResult' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionGroupDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditionSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaResult' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'medias' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionMedia' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionGroupQuery, AuditionGroupQueryVariables>;
export const AllAuditionGroupsBySessionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllAuditionGroupsBySession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditionGroupsBySession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllAuditionGroupsBySessionQuery, AllAuditionGroupsBySessionQueryVariables>;
export const SearchSubmissionsByAuditionGroupIdDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchSubmissionsByAuditionGroupId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchSubmissionsByAuditionGroupId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'auditionGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionGroupSubmission' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMediaWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetOnSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmissionMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderOfAppearance' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionGroupSubProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'noteType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionGroupSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Submission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'picksCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMediaWithThumbnail' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetOnSubmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionGroupSubProfile' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pickedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMediaWithThumbnail' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fastcastNotes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeZone' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'standardName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchSubmissionsByAuditionGroupIdQuery, SearchSubmissionsByAuditionGroupIdQueryVariables>;
export const AttachMediaToAuditionGroupDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AttachMediaToAuditionGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputModel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionGroupMediaInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachMediaToAuditionGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputModel' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputModel' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachMediaToAuditionGroupMutation, AttachMediaToAuditionGroupMutationVariables>;
export const AuditionSessionViewDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuditionSessionView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'auditionSession' },
            name: { kind: 'Name', value: 'getAuditionSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionSessionViewAuditionSession' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionViewAuditionSession' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionSession' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionSessionViewQuery, AuditionSessionViewQueryVariables>;
export const AuditionSessionViewAuditionSessionGroupsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuditionSessionViewAuditionSessionGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'submissionsCount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'auditionSessionGroups' },
            name: { kind: 'Name', value: 'auditionGroupsBySession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AuditionSessionViewAuditionSessionGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionViewCoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionViewAuditionSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Submission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverMedia' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverMedia' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roleName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionViewAuditionSessionGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'auditionSessionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionSessionViewCoverMedia' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'submissionsCount' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditionSessionViewAuditionSubmission' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuditionSessionViewAuditionSessionGroupsQuery,
  AuditionSessionViewAuditionSessionGroupsQueryVariables
>;
export const AuditionSessionsForProjectDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuditionSessionsForProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'auditionSessions' },
            name: { kind: 'Name', value: 'auditionSessionsByProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'ListValue',
                  values: [{ kind: 'Variable', name: { kind: 'Name', value: 'projectId' } }],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AuditionSessionsForProjectAuditionSession' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditionSessionsForProjectAuditionSession' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionSession' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionSessionsForProjectQuery, AuditionSessionsForProjectQueryVariables>;
export const RefreshTokenDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefreshToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'refreshToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refreshToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refreshToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'refreshToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthTokens' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthTokens' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthToken' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refresh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ImpersonateAccountDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImpersonateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginImpersonatedAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthTokens' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthTokens' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthToken' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refresh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImpersonateAccountMutation, ImpersonateAccountMutationVariables>;
export const SwitchAuthContextDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchAuthContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'refreshToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SystemRoleCode' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchAuthContext' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refreshToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'refreshToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'artistId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthTokens' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthTokens' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthToken' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refresh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchAuthContextMutation, SwitchAuthContextMutationVariables>;
export const VerifyAccountEmailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyAccountEmail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyAccountEmail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthTokens' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthTokens' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthToken' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refresh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyAccountEmailMutation, VerifyAccountEmailMutationVariables>;
export const UserContextQueryDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserContextQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isTalent' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContext' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscriptionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingPlan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBasePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isComped' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'term' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscription' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialLengthDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingInfoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscriptionPlan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContextBaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sytemRoleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemRoleCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPlayableAgeLockedToTalent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeekingRepresentation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noteToRepresentative' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPrimary' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetWithThumbnail' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileBookOuts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileBookoutId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileVisiblities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seekingRepresentations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountArtist' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Artist' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAugeoBenefits' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscription' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscription' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountProfile' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContext' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailResetToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isIPC' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInPCContext' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastArtistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rosterInvitationToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSystemRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'artists' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'isTalent' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtist' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardExpirationYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardExpirationMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'continent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unitSystem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'supportingCountry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'divisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedAccountOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dmas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showUnionStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectListFilters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRequests' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSelects' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSubmissions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isKidsRolesOnly' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectViewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submissionDueDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'castingOrganizations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectRegion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectUnion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isBasePlan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComped' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeZone' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmtOffset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'standardName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserContextQuery, UserContextQueryVariables>;
export const UpdateAccountDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAccountInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isTalent' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContext' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscriptionPlan' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingPlan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBasePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isComped' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'term' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistSubscription' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trialLengthDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingInfoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscriptionPlan' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContextBaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountOrg' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sytemRoleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemRoleCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPlayableAgeLockedToTalent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeekingRepresentation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noteToRepresentative' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'credit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isPrimary' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetWithThumbnail' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileBookOuts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileBookoutId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileVisiblities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seekingRepresentations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountArtist' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Artist' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAugeoBenefits' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscription' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previousSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistSubscription' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountProfile' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContext' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailResetToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isIPC' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInPCContext' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastArtistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rosterInvitationToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSystemRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'artists' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'isTalent' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtist' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardExpirationYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardExpirationMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'continent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unitSystem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'supportingCountry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'divisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedAccountOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dmas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountOrg' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showUnionStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectListFilters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasRequests' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSelects' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasSubmissions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isKidsRolesOnly' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectViewStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submissionDueDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'castingOrganizations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectRegion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectUnion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isBasePlan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isComped' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeZone' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmtOffset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'standardName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const UpdatePasswordDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oldPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oldPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'oldPassword' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const EnrollBenefitsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnrollBenefits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnrollBenefitsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollBenefits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnrollBenefitsMutation, EnrollBenefitsMutationVariables>;
export const CancelBenefitsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelBenefits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelBenefitsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelBenefits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelBenefitsMutation, CancelBenefitsMutationVariables>;
export const GetNotificationNotificationSubscriptionByAccountDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationNotificationSubscriptionByAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getNotificationNotificationSubscriptionByAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notificationTypeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationNature' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'notificationNatureId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNotificationNotificationSubscriptionByAccountQuery,
  GetNotificationNotificationSubscriptionByAccountQueryVariables
>;
export const UpdateNotificationNotificationTypeSubscriptionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNotificationNotificationTypeSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationSubscriptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotificationNotificationTypeSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNotificationNotificationTypeSubscriptionMutation,
  UpdateNotificationNotificationTypeSubscriptionMutationVariables
>;
export const CreateCollaboratorInviteDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCollaboratorInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CollaboratorInviteCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCollaboratorInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCollaboratorInviteMutation, CreateCollaboratorInviteMutationVariables>;
export const RevokeCollaboratorAccessFromOrgDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RevokeCollaboratorAccessFromOrg' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CollaboratorRevokeAccessFromOrgInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revokeCollaboratorAccessFromOrg' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RevokeCollaboratorAccessFromOrgMutation, RevokeCollaboratorAccessFromOrgMutationVariables>;
export const GetCollaboratorsByProjectIdDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCollaboratorsByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCollaboratorsByProjectId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollaboratorDetails' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollaboratorDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollaboratorsInvite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaborator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collaboratorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaboratorInviteFromOrgDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaboratorInviteProjectDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCollaboratorsByProjectIdQuery, GetCollaboratorsByProjectIdQueryVariables>;
export const CollaboratorByInviteIdDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CollaboratorByInviteId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetCollaboratorInviteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCollaboratorInviteById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollaboratorDetails' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollaboratorDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollaboratorsInvite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaborator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collaboratorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaboratorInviteFromOrgDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orgName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaboratorInviteProjectDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inviteStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollaboratorByInviteIdQuery, CollaboratorByInviteIdQueryVariables>;
export const CollaboratorsByOrganizationDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CollaboratorsByOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetCollaboratorByEmailInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectDetailsPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCollaboratorsForOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollaboratorsInOrganizationDetails' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollaboratorsInOrganizationDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollaboratorsInOrganization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaboratorDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collaboratorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaboratorsProjectDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageProjects' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectDetailsPage' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollaboratorsByOrganizationQuery, CollaboratorsByOrganizationQueryVariables>;
export const RevokeCollaboratorAccessFromProjectDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RevokeCollaboratorAccessFromProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CollaboratorRevokeAccessFromProjectInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revokeCollaboratorAccessFromProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RevokeCollaboratorAccessFromProjectMutation,
  RevokeCollaboratorAccessFromProjectMutationVariables
>;
export const CreateCollaboratorAccountDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCollaboratorAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CollaboratorAccountCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCollaboratorAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'access' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refresh' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCollaboratorAccountMutation, CreateCollaboratorAccountMutationVariables>;
export const GetCountriesWithDmaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCountriesWithDma' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryWithDma' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dmas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCountriesWithDmaQuery, GetCountriesWithDmaQueryVariables>;
export const CreateCompedSubscriptionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCompedSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCompedSubscriptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCompedSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCompedSubscriptionMutation, CreateCompedSubscriptionMutationVariables>;
export const GetCreditTypesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCreditTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCreditTypesQuery, GetCreditTypesQueryVariables>;
export const GetProfileWithCreditsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfileWithCredits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'ListValue', values: [{ kind: 'Variable', name: { kind: 'Name', value: 'ids' } }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileWithCredits' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditOnProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Credit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'director' } },
          { kind: 'Field', name: { kind: 'Name', value: 'highlighted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileCreditTypeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetOnProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileWithCredits' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideCreditYear' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditOnProfile' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaAssets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetOnProfile' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProfileWithCreditsQuery, GetProfileWithCreditsQueryVariables>;
export const CreateCreditDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCredit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditInput' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCredit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'credit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCreditMutation, CreateCreditMutationVariables>;
export const CreateArtistCreditsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateArtistCredits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ArtistCreditsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createArtistCredits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'credit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateArtistCreditsMutation, CreateArtistCreditsMutationVariables>;
export const UpdateCreditDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCredit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditUpdateInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCredit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'credit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditUpdateInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'director' } },
                { kind: 'Field', name: { kind: 'Name', value: 'highlighted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileCreditTypeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creditType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCreditMutation, UpdateCreditMutationVariables>;
export const DeleteCreditDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCredit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCredit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCreditMutation, DeleteCreditMutationVariables>;
export const UpdateProfileCreditTypeSortOrderDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileCreditTypeSortOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstProfileCreditTypeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'secondProfileCreditTypeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileCreditTypeSortOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstProfileCreditTypeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstProfileCreditTypeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'secondProfileCreditTypeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'secondProfileCreditTypeId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfileCreditTypeSortOrderMutation,
  UpdateProfileCreditTypeSortOrderMutationVariables
>;
export const UpdateCreditOrderDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCreditOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditOrderInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCreditOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCreditOrderMutation, UpdateCreditOrderMutationVariables>;
export const UpdateProfileHideCreditYearDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileHideCreditYear' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hideCreditYear' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileHideCreditYearMutation, UpdateProfileHideCreditYearMutationVariables>;
export const AttachMediaToCreditDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AttachMediaToCredit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkPayment' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachCreditVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkPayment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkPayment' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachMediaToCreditMutation, AttachMediaToCreditMutationVariables>;
export const DeleteCreditMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCreditMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCreditMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCreditMediaMutation, DeleteCreditMediaMutationVariables>;
export const GetSlugByProfileIdDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSlugByProfileId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeExpired' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSlugByProfileId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeExpired' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeExpired' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomSlugURL' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomSlugURL' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomSlug' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creationCooldownDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expiredReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slugName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSlugByProfileIdQuery, GetSlugByProfileIdQueryVariables>;
export const ExpireSlugDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExpireSlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'expiredReason' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomSlugExpiredReasonEnum' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expireSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'expiredReason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'expiredReason' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExpireSlugMutation, ExpireSlugMutationVariables>;
export const AddNewSlugDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddNewSlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slugName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addNewSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slugName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slugName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomSlugURL' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomSlugURL' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomSlug' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creationCooldownDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expiredReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slugName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddNewSlugMutation, AddNewSlugMutationVariables>;
export const DeleteBlockedKeywordDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBlockedKeyword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'keyword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBlockedKeyword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keyword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'keyword' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBlockedKeywordMutation, DeleteBlockedKeywordMutationVariables>;
export const DeleteMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteMediaInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteMediaMutation, DeleteMediaMutationVariables>;
export const DeleteMediaAssetDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMediaAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMediaAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteMediaAssetMutation, DeleteMediaAssetMutationVariables>;
export const GetCountriesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCountries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CountryDetails' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContinentDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Continent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CountryDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Country' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'continent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContinentDetails' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supportingCountry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'continent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContinentDetails' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetDmasDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDmas' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dmas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DmaDetails' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContinentDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Continent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CountryDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Country' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'continent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContinentDetails' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supportingCountry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'continent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContinentDetails' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Region' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CountryDetails' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DmaDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dma' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RegionDetails' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDmasQuery, GetDmasQueryVariables>;
export const GetTimeZonesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTimeZones' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeZones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TimeZoneDetails' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TimeZoneDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TimeZone' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codeBrowser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTimeZonesQuery, GetTimeZonesQueryVariables>;
export const InitiateMediaBulkDownloadDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateMediaBulkDownload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiateMediaBulkDownloadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateMediaBulkDownload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiateMediaBulkDownloadMutation, InitiateMediaBulkDownloadMutationVariables>;
export const GetProfileWithTalentEmailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfileWithTalentEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'ListValue', values: [{ kind: 'Variable', name: { kind: 'Name', value: 'id' } }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EditProfileWithTalentEmail' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditProfileWithTalentEmail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'talentEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProfileWithTalentEmailQuery, GetProfileWithTalentEmailQueryVariables>;
export const UpdateProfileTalentEmailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileTalentEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'talentEmail' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileTalentEmailMutation, UpdateProfileTalentEmailMutationVariables>;
export const EmailHistoryDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailHistoryItem' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailHistoryItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmailHistory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'emailType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'messageToReps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'messageToTalents' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailHistoryQuery, EmailHistoryQueryVariables>;
export const ExpireCompedSubscriptionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExpireCompedSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ExpireCompedSubscriptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expireCompedSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExpireCompedSubscriptionMutation, ExpireCompedSubscriptionMutationVariables>;
export const SearchProjectSeriesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchProjectSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetProjectSeriesByNameInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchProjectSeriesByOrg' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectSeries' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectSeries' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectSeries' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seriesName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchProjectSeriesQuery, SearchProjectSeriesQueryVariables>;
export const SearchProjectSessionsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchProjectSessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditionSessionsByProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectSession' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectSession' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionSession' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchProjectSessionsQuery, SearchProjectSessionsQueryVariables>;
export const GetSavedSearchListDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSavedSearchList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'keyOffset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'savedSearchList' },
            name: { kind: 'Name', value: 'getSavedSearchList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keyOffset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'keyOffset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nextKeyOffset' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workingLocations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ethnicAppearances' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'genderAppearances' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'payPreferences' },
                              name: { kind: 'Name', value: 'paidTypes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'roleTargets' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'projectTypes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'talentUnions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentObject' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'playableAge' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'ageMax' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ageMin' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'inMonths' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'showRolesNoLongerAcceptingSubmissions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'roleProjectKeywordSearch' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSavedSearchListQuery, GetSavedSearchListQueryVariables>;
export const ResetPasswordBauB21Doc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetPasswordBauB21' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'legacyInstance' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'login' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPasswordBauB21' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'legacyInstance' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'legacyInstance' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'login' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'login' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetPasswordBauB21Mutation, ResetPasswordBauB21MutationVariables>;
export const ResetPasswordDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const GetTotalFormsCountDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTotalFormsCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTotalFormsCountQuery, GetTotalFormsCountQueryVariables>;
export const GetFormsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetForms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortInstruction' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FormSortConditionInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'associatedProjectsPageInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageInput' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortInstruction' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortInstruction' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'associatedProjects' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'pageProjects' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'associatedProjectsPageInput' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFormsQuery, GetFormsQueryVariables>;
export const CreateFormDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formSchema' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formSchema' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formSchema' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IError' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValidationErrorForm' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFormMutation, CreateFormMutationVariables>;
export const GetFormDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseForm' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseForm' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FormGqlModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formSchema' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFormQuery, GetFormQueryVariables>;
export const GetFormsForMediaRequestDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFormsForMediaRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FormsFilterArgs' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageInput' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFormsForMediaRequestQuery, GetFormsForMediaRequestQueryVariables>;
export const GetFormResponseDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFormResponse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formResponseId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFormResponse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formResponseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formResponseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formResponseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formResponseSchema' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formSchema' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFormResponseQuery, GetFormResponseQueryVariables>;
export const UpdateFormDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formSchema' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formSchema' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formSchema' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseForm' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseForm' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FormGqlModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formSchema' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFormMutation, UpdateFormMutationVariables>;
export const SaveFormResponseDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveFormResponse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'responseSchema' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveFormResponse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'responseSchema' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'responseSchema' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'formResponseId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveFormResponseMutation, SaveFormResponseMutationVariables>;
export const GetReportByIdDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReportById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReportById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FormsReportModel' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mediaRequestId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'csvFileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reportType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'formSchema' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaRequestName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'formResponseDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'formVersionId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'responseId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'responseSchema' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profileDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'submissionPhotoUrl' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'repDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'division' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'roleDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportByIdQuery, GetReportByIdQueryVariables>;
export const GetReportByIdPublicDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReportByIdPublic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReportByIdPublic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publicKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FormsReportModel' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'csvFileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mediaRequestId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reportType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'formSchema' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaRequestName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'formResponseDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'formVersionId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'responseId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'responseSchema' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profileDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'submissionPhotoUrl' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'repDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'division' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'roleDetails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportByIdPublicQuery, GetReportByIdPublicQueryVariables>;
export const CreateFormResponseReportDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFormResponseReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaRequestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'presentationKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFormResponseReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'formId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'presentationKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'presentationKey' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFormResponseReportMutation, CreateFormResponseReportMutationVariables>;
export const GetBlockedKeywordsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBlockedKeywords' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'NullValue' },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'NullValue' },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'NullValue' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBlockedKeywordsPaginatedList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GetBlockedKeywordsPaginatedListResponse' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockedKeywordResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlockedKeyword' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keyword' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blockedReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'blockedReasonNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GetBlockedKeywordsPaginatedListResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BlockedKeywordPaginatedResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'objects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BlockedKeywordResponse' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBlockedKeywordsQuery, GetBlockedKeywordsQueryVariables>;
export const GetUnresolvedProhibitedSlugsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUnresolvedProhibitedSlugs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUnresolvedProhibitedSlugs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlaggedArtistUrlsPaginatedResult' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProhibitedCustomSlugResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProhibitedCustomSlug' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slugName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blockedKeyword' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlaggedArtistUrlsPaginatedResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginatedProhibitedCustomSlug' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'objects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProhibitedCustomSlugResponse' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUnresolvedProhibitedSlugsQuery, GetUnresolvedProhibitedSlugsQueryVariables>;
export const GetPresentationFiltersDataDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPresentationFiltersData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'presentationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPresentationFiltersData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'presentationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'presentationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'commentersAccountIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'picksUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'folders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPresentationFiltersDataQuery, GetPresentationFiltersDataQueryVariables>;
export const GetRoleChangeLogsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRoleChangeLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRoleChangeLogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpenCall' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedById' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'modifiedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRoleChangeLogsQuery, GetRoleChangeLogsQueryVariables>;
export const CreateAccountWithGoogleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAccountWithGoogle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateAccountWithGoogleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAccountWithGoogle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'access' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refresh' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAccountWithGoogleMutation, CreateAccountWithGoogleMutationVariables>;
export const LoginWithAuthProviderDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LoginWithAuthProvider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authProvider' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ExternalAuthenticationProvider' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginWithAuthProvider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authProvider' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authProvider' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'access' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refresh' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginWithAuthProviderMutation, LoginWithAuthProviderMutationVariables>;
export const InviteTalentGetInvitationHistoryDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InviteTalentGetInvitationHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'RosterInvitationTalentHistoryFilterInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rosterTalentInvitationHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileStatusCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteTalentGetInvitationHistoryQuery, InviteTalentGetInvitationHistoryQueryVariables>;
export const InviteTalentSendInvitationsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteTalentSendInvitations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RosterInvitationRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRosterInvitationRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteTalentSendInvitationsMutation, InviteTalentSendInvitationsMutationVariables>;
export const GetAcctLangByLocaleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAcctLangByLocale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLanguageByLocale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LanguageDetails' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LanguageInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAcctLangByLocaleQuery, GetAcctLangByLocaleQueryVariables>;
export const GetProfileWithManagerEmailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfileWithManagerEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'ListValue', values: [{ kind: 'Variable', name: { kind: 'Name', value: 'id' } }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileWithManagerEmail' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileWithManagerEmail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managerEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProfileWithManagerEmailQuery, GetProfileWithManagerEmailQueryVariables>;
export const UpdateProfileManagerEmailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileManagerEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'managerEmail' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileManagerEmailMutation, UpdateProfileManagerEmailMutationVariables>;
export const UpdateMediaAssetDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMediaAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMediaInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMediaAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMediaAssetMutation, UpdateMediaAssetMutationVariables>;
export const AttachMediaToProfileDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AttachMediaToProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AttachMediaToProfileInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachMediaToProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachMediaToProfileMutation, AttachMediaToProfileMutationVariables>;
export const AttachMediasToProfileDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AttachMediasToProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachMediasToProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachMediasToProfileMutation, AttachMediasToProfileMutationVariables>;
export const UpdateProfileMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileMedia' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMediaInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileMedia' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileMedia' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileMediaMutation, UpdateProfileMediaMutationVariables>;
export const DeleteProfileMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProfileMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProfileMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProfileMediaMutation, DeleteProfileMediaMutationVariables>;
export const EditMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditMediaMutation, EditMediaMutationVariables>;
export const EditMediaThumbnailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditMediaThumbnail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editMediaThumbnail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditMediaThumbnailMutation, EditMediaThumbnailMutationVariables>;
export const RearrangeProfileMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RearrangeProfileMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileMediaList' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMediaInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileMediaList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileMediaList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileMediaList' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RearrangeProfileMediaMutation, RearrangeProfileMediaMutationVariables>;
export const DeleteMediaV2Doc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMediaV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteMediaV2Input' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMediaV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteMediaV2Mutation, DeleteMediaV2MutationVariables>;
export const AttachMultipleMediaToProfileDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AttachMultipleMediaToProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AttachMultipleMediaToProfileInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachMultipleMediaToProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetOnProfile' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetOnProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachMultipleMediaToProfileMutation, AttachMultipleMediaToProfileMutationVariables>;
export const InitiateMediaUploadV1Doc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateMediaUploadV1' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'MediaInitUploadInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaInitUploadInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initMediaUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'MediaInitUploadInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiateMediaUploadV1Mutation, InitiateMediaUploadV1MutationVariables>;
export const FinalizeMediaUploadV1Doc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FinalizeMediaUploadV1' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'MediaMetadataInput' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaMetadataInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalizeMediaUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'MediaMetadataInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UploadMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UploadMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinalizeMediaUploadV1Mutation, FinalizeMediaUploadV1MutationVariables>;
export const CreateArtistMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateArtistMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateArtistMediaInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createArtistMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateArtistMediaMutation, CreateArtistMediaMutationVariables>;
export const CompleteArtistMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteArtistMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CompleteArtistMediaCreationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeArtistMediaCreation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistMediaCtx' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UploadMedia' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionMediaEntitlement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnlimited' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistMediaCtx' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Artist' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionMediaUsage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'available' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entitle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UploadMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteArtistMediaMutation, CompleteArtistMediaMutationVariables>;
export const UpdateProfileMoveToArtistDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileMoveToArtist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileMoveToArtist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'profileId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileMoveToArtistMutation, UpdateProfileMoveToArtistMutationVariables>;
export const UpdateRoleOpenCallDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRoleOpenCall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isOpenCall' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRoleOpenCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isOpenCall' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isOpenCall' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpenCall' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRoleOpenCallMutation, UpdateRoleOpenCallMutationVariables>;
export const PaywallRemoveCouponDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PaywallRemoveCoupon' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'removeActiveCouponRedemption' } }],
      },
    },
  ],
} as unknown as DocumentNode<PaywallRemoveCouponMutation, PaywallRemoveCouponMutationVariables>;
export const PaywallSubscriptionPurchaseDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PaywallSubscriptionPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseSubscriptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsApplied' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextBillingDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trialEndsAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'term' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaywallSubscriptionPurchaseMutation, PaywallSubscriptionPurchaseMutationVariables>;
export const PaywallPreviewSubscriptionPurchaseDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PaywallPreviewSubscriptionPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PreviewSubscriptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewSubscriptionPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsApplied' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coupon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRedeemed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'canRenew' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'term' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaywallPreviewSubscriptionPurchaseQuery, PaywallPreviewSubscriptionPurchaseQueryVariables>;
export const PaywallGetBillingInfoDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PaywallGetBillingInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetBillingInfoInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBillingInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBackupPaymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimaryPaymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaywallGetBillingInfoQuery, PaywallGetBillingInfoQueryVariables>;
export const PaywallAddPaymentOptionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PaywallAddPaymentOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddBillingInfoInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addBillingInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBackupPaymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimaryPaymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaywallAddPaymentOptionMutation, PaywallAddPaymentOptionMutationVariables>;
export const PaywallPreviewSubscriptionRenewalDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PaywallPreviewSubscriptionRenewal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PreviewSubscriptionRenewalInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewSubscriptionRenewal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaywallPreviewSubscriptionRenewalQuery, PaywallPreviewSubscriptionRenewalQueryVariables>;
export const PaywallRenewSubscriptionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PaywallRenewSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RenewSubscriptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renewSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaywallRenewSubscriptionMutation, PaywallRenewSubscriptionMutationVariables>;
export const CreateCouponRedemptionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCouponRedemption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCouponRedemptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCouponRedemption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCouponRedemptionMutation, CreateCouponRedemptionMutationVariables>;
export const BillingPlansDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillingPlans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingPlansInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingPlans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBasePlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialLengthDays' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'term' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillingPlansQuery, BillingPlansQueryVariables>;
export const PreviewMediaPurchaseDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PreviewMediaPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseMediaInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewMediaPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsApplied' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mediaStatus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fileType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mediaType' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isRequestable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mediaStorageStatus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mediaType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isRequestable' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thumbnail' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'thumbnail' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coupon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRedeemed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewMediaPurchaseQuery, PreviewMediaPurchaseQueryVariables>;
export const GetProfileContactEmailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfileContactEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'ListValue', values: [{ kind: 'Variable', name: { kind: 'Name', value: 'ids' } }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'talentEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProfileContactEmailQuery, GetProfileContactEmailQueryVariables>;
export const GetAllAssetsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllAssets' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicleTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vehicleTypeMakes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'make' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentObject' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'petSizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'props' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentObject' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wardrobes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentObject' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sportEquipments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentObject' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllAssetsQuery, GetAllAssetsQueryVariables>;
export const GetProfileAssetsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfileAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'ListValue', values: [{ kind: 'Variable', name: { kind: 'Name', value: 'id' } }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sportEquipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileSportEquipmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileWardrobes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileWardrobeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'props' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profilePropId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profilePets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profilePetId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'petSize' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileVehicles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileVehicleId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vehicleName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vehicleYear' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vehicleTypeMake' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'make' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProfileAssetsQuery, GetProfileAssetsQueryVariables>;
export const AddProfileVehicleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddProfileVehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileVehicleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProfileVehicle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vehicleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vehicleYear' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicleTypeMake' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VehicleTypeMakeFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VehicleTypeMakeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VehicleTypeMake' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'make' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddProfileVehicleMutation, AddProfileVehicleMutationVariables>;
export const UpdateProfileVehicleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileVehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileVehicleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateProfileVehicleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileVehicle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileVehicleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileVehicleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'vehicleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vehicleYear' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vehicleTypeMake' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VehicleTypeMakeFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VehicleTypeMakeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VehicleTypeMake' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'make' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileVehicleMutation, UpdateProfileVehicleMutationVariables>;
export const DeleteProfileVehicleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProfileVehicle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileVehicleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProfileVehicle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileVehicleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileVehicleId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProfileVehicleMutation, DeleteProfileVehicleMutationVariables>;
export const AddProfilePetDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddProfilePet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfilePetInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProfilePet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'petSize' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PetSizeFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PetSizeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PetSize' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddProfilePetMutation, AddProfilePetMutationVariables>;
export const UpdateProfilePetDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfilePet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profilePetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfilePetInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfilePet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profilePetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profilePetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'petSize' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PetSizeFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PetSizeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PetSize' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfilePetMutation, UpdateProfilePetMutationVariables>;
export const DeleteProfilePetDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProfilePet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profilePetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProfilePet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profilePetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profilePetId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProfilePetMutation, DeleteProfilePetMutationVariables>;
export const AddProfilePropDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddProfileProp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'propId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProfileProp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'propId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childObjects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddProfilePropMutation, AddProfilePropMutationVariables>;
export const UpdateProfilePropDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileProp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profilePropId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'propId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileProp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profilePropId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profilePropId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'propId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childObjects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfilePropMutation, UpdateProfilePropMutationVariables>;
export const DeleteProfilePropDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProfileProp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profilePropId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProfileProp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profilePropId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profilePropId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProfilePropMutation, DeleteProfilePropMutationVariables>;
export const AddProfileWardrobeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddProfileWardrobe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'wardrobeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProfileWardrobe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'wardrobeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'wardrobeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childObjects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddProfileWardrobeMutation, AddProfileWardrobeMutationVariables>;
export const UpdateProfileWardrobeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileWardrobe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileWardrobeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'wardrobeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileWardrobe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileWardrobeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileWardrobeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'wardrobeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'wardrobeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childObjects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileWardrobeMutation, UpdateProfileWardrobeMutationVariables>;
export const DeleteProfileWardrobeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProfileWardrobe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileWardrobeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProfileWardrobe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileWardrobeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileWardrobeId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProfileWardrobeMutation, DeleteProfileWardrobeMutationVariables>;
export const AddProfileSportsEquipmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddProfileSportsEquipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sportEquipmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProfileSportsEquipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sportEquipmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sportEquipmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childObjects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddProfileSportsEquipmentMutation, AddProfileSportsEquipmentMutationVariables>;
export const UpdateProfileSportsEquipmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileSportsEquipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileSportEquipmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sportEquipmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileSportsEquipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileSportEquipmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileSportEquipmentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sportEquipmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sportEquipmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childObjects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileSportsEquipmentMutation, UpdateProfileSportsEquipmentMutationVariables>;
export const DeleteProfileSportsEquipmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProfileSportsEquipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileSportEquipmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProfileSportsEquipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileSportEquipmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileSportEquipmentId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProfileSportsEquipmentMutation, DeleteProfileSportsEquipmentMutationVariables>;
export const SetProfileResumeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetProfileResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setProfileResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetProfileResumeMutation, SetProfileResumeMutationVariables>;
export const RemoveProfileResumeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveProfileResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeProfileResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveProfileResumeMutation, RemoveProfileResumeMutationVariables>;
export const CastingProjectDetailsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CastingProjectDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CastingProjectDetail' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailAuditionType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailFieldRestrictions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectFieldRestriction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedFor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailConflicts' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Conflict' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailSeries' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeriesDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesEpisode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectSeriesSeason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailUnion' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectUnionDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contractStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'union' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentObject' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailRoleDefaults' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleDefaults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wardrobe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workRequirements' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldRestrictions' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'restrictedFor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicationSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dmas' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repAgeCategories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repCategories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'repTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'representativeListId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'representativeIds' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateTypeCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailSidesVisibility' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectSidesVisibility' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailReleaseLocations' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Dma' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CastingProjectDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adAgency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalProvisions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentDisplayTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'castingAssistant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'castingAssociate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'castingCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'castingDirector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'conflictNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactInfoPermissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdByTalentSystemUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'director' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayedProjectUnion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'executiveProducer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'network' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photographer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'producer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productionCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportTicketNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'synopsis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'writer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditionType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailAuditionType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldRestrictions' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailFieldRestrictions' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailMedia' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectConflicts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailConflicts' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectSeries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailSeries' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailStatus' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectUnion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailUnion' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleDefaults' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailRoleDefaults' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sideVisibility' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailSidesVisibility' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releaseLocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailReleaseLocations' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CastingProjectDetailsQuery, CastingProjectDetailsQueryVariables>;
export const CastingProjectRolesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CastingProjectRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailRole' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRolePubSettingSummary' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleCountOfPairedRepsAndCB' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repsOrgs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSharedWithCB' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleCompensations' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleCompensation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherCompensation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleCompensationId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compensation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleEthnicApp' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EthnicAppearance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleFieldRestriction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleFieldRestriction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fields' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restrictedFor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleGenderApp' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GenderAppearance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleLocationDate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleDateWithLocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleDateType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRolePaidType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaidType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleMediaItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSides' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleMediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thumbnail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRolePubSetting' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RolePublicationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'representativeListIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dmas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'region' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'repAgeCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicationSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleRatePer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RatePerType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleTarget' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleUnion' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleUnion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSexualSituation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SexualSituation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSidesVisibility' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleSidesVisibility' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSkill' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Skill' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleSubmissionCount' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleSubmissionCount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectRoleTimeZone' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TimeZone' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'standardName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailRole' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Role' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ageInYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ageMaxMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ageMinMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundTalentIntended' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closeSubmissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayedRoleUnion' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOpenCall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateSummary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestMediaInstruction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestMediaTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleEthnicityNationality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sexualSituationDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'spot' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionInstruction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wardrobe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workRequirement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countOfPairedRepsAndCB' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRolePubSettingSummary' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compensations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleCompensations' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ethnicAppearance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleEthnicApp' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fieldRestrictions' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeFieldRestrictions' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleFieldRestriction' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'genderAppearance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleGenderApp' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleLocationDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paidType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRolePaidType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleMediaItem' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRolePubSetting' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ratePer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleRatePer' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleStatus' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleUnion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleUnion' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sexualSituations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleSexualSituation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sides' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleMediaItem' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sidesVisibility' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleSidesVisibility' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleSkill' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleSubmissionCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timeZone' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectRoleTimeZone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CastingProjectRolesQuery, CastingProjectRolesQueryVariables>;
export const UpdateProjectStatusDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProjectStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectStatusCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectStatusCode' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectStatusCode' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables>;
export const SubmitPcProjectForApprovalDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitPcProjectForApproval' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitPCProjectForApproval' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitPcProjectForApprovalMutation, SubmitPcProjectForApprovalMutationVariables>;
export const SetPcProjectStatusDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetPcProjectStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectStatusCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setProjectStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectStatusCodeToUpdate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectStatusCode' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetPcProjectStatusMutation, SetPcProjectStatusMutationVariables>;
export const AttachProjectSupportTicketNumberDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AttachProjectSupportTicketNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectAttachSupportTicketNumber' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachProjectSupportTicketNumber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AttachProjectSupportTicketNumberMutation,
  AttachProjectSupportTicketNumberMutationVariables
>;
export const SendProjectUpdateMessageToRepsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendProjectUpdateMessageToReps' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectNotificationToRepsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendProjectUpdateMessageToReps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendProjectUpdateMessageToRepsMutation, SendProjectUpdateMessageToRepsMutationVariables>;
export const ChangeRoleOrderDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeRoleOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'moveBehind' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'positionalRoleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeRoleOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moveBehind' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'moveBehind' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'positionalRoleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'positionalRoleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangeRoleOrderMutation, ChangeRoleOrderMutationVariables>;
export const UpdateRoleStatusDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRoleStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleStatusCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'roleId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'roleStatusCode' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'roleStatusCode' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roleStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRoleStatusMutation, UpdateRoleStatusMutationVariables>;
export const ArchiveRoleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ArchiveRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roleIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchiveRoleMutation, ArchiveRoleMutationVariables>;
export const RestoreRoleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RestoreRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roleIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestoreRoleMutation, RestoreRoleMutationVariables>;
export const DeleteRoleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const RequestAddlSubsForRolesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestAddlSubsForRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageToTalents' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageToReps' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestSubmissionsForRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageToTalents' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'messageToTalents' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageToReps' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'messageToReps' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roleIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestAddlSubsForRolesMutation, RequestAddlSubsForRolesMutationVariables>;
export const ProjectDetailViewMediaRequestAddTalentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestAddTalent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'submissionIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmissionSearchInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTalentToMediaRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'submissionIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'submissionIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responses' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'statuses' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponsePagedResults' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileSchemaValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'noteModifier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfSubmissionResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOverscale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'requestedMediaCount' },
            name: { kind: 'Name', value: 'mediaCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requested' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wardrobe' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOverscaled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAuditionHistory' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'talentUnions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inch' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingLocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noteModifier' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelledAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAdditional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowMedia' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfSubmissionResponse' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'chatTopicKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chatTopic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'topicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastUserMessage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formResponseId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponsePagedResults' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaResponsePagedList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponse' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectDetailViewMediaRequestAddTalentMutation,
  ProjectDetailViewMediaRequestAddTalentMutationVariables
>;
export const ProjectDetailViewMediaRequestUpdateRepsMessageDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestUpdateRepsMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMediaRequestMessageForRepresentatives' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shouldResend' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectDetailViewMediaRequestUpdateRepsMessageMutation,
  ProjectDetailViewMediaRequestUpdateRepsMessageMutationVariables
>;
export const ProjectDetailViewMediaRequestCancelRequestDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestCancelRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectDetailViewMediaRequestCancelRequestMutation,
  ProjectDetailViewMediaRequestCancelRequestMutationVariables
>;
export const ProjectDetailViewMediaRequestDeleteRequestDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestDeleteRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectDetailViewMediaRequestDeleteRequestMutation,
  ProjectDetailViewMediaRequestDeleteRequestMutationVariables
>;
export const ProjectDetailViewMediaRequestResponsesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaRequestDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allowAdditionalMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: 'includeSides' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submissionIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'talentInstructions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'talentInstructionsUpdatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageForRepresentatives' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageForRepresentativesUpdatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxNumberOfMediaFiles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mediaTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shouldResend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dueDateTimeZone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'standardName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responses' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'statuses' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponsePagedResults' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestBaseWorkflowMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileSchemaValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'noteModifier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfSubmissionResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WfSubmission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOverscale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'requestedMediaCount' },
            name: { kind: 'Name', value: 'mediaCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requested' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowCoverMedia' },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestTitleSchemaValue' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestSubmissionLastNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wardrobe' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOverscaled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectDetailViewMediaRequestWfProfileMainOrganizationAndMainDivision',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAuditionHistory' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'talentUnions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inch' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingLocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noteModifier' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfProfileLastNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponse' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelledAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAdditional' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWorkflowMedia' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestWfSubmissionResponse' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'chatTopicKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chatTopic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'topicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastUserMessage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formResponseId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponsePagedResults' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaResponsePagedList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectDetailViewMediaRequestResponse' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectDetailViewMediaRequestResponsesQuery,
  ProjectDetailViewMediaRequestResponsesQueryVariables
>;
export const ProjectListRowsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectListRows' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectStatusFilterEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortInstruction' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortConditionInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationProjectFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statusFilter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statusFilter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortInstruction' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortInstruction' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectListRow' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectListRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectBase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportTicketNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'castingCompanyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seriesName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastModifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectListRowsQuery, ProjectListRowsQueryVariables>;
export const ProjectListGroupsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectListGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectGroupType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectGroupType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationProjectFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationProjectsGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectGroupType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectGroupType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'details' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectListGroupSeries' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectListGroupType' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectListGroupStatus' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectListGroupSeries' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectSeries' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seriesName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectListGroupType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childObjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectListGroupStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectListGroupsQuery, ProjectListGroupsQueryVariables>;
export const CreateProjectSeriesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProjectSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProjectSeriesInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectSeries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProjectSeriesMutation, CreateProjectSeriesMutationVariables>;
export const GetProjectSeriesByNameDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProjectSeriesByName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetProjectSeriesByNameInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchProjectSeriesByOrg' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'seriesName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectSeriesByNameQuery, GetProjectSeriesByNameQueryVariables>;
export const PurchaseMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PurchaseMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseMediaInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsApplied' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coupon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRedeemed' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'appearancesOnProfiles' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'appearOnSubmissions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'archivableOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaParentId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'appearOnMediaRequests' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PurchaseMediaMutation, PurchaseMediaMutationVariables>;
export const PreviewSubscriptionRenewalDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PreviewSubscriptionRenewal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PreviewSubscriptionRenewalInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewSubscriptionRenewal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewSubscriptionRenewalQuery, PreviewSubscriptionRenewalQueryVariables>;
export const RenewSubscriptionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenewSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RenewSubscriptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renewSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenewSubscriptionMutation, RenewSubscriptionMutationVariables>;
export const DownloadCsvDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReportCsvUrlById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadCsvQuery, DownloadCsvQueryVariables>;
export const DownloadCsvPublicDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadCsvPublic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReportCsvUrlByIdPublic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publicKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicKey' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadCsvPublicQuery, DownloadCsvPublicQueryVariables>;
export const CreateAllTalentSubmissionReportDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAllTalentSubmissionReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AllTalentSubmissionReportCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAllTalentSubmissionReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAllTalentSubmissionReportMutation, CreateAllTalentSubmissionReportMutationVariables>;
export const CreateTalentSubmissionReportDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTalentSubmissionReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TalentSubmissionReportCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTalentSubmissionReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTalentSubmissionReportMutation, CreateTalentSubmissionReportMutationVariables>;
export const RepsAllTalentReportDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RepsAllTalentReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReportById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllTalentReport' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllTalentReportSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllTalentSubmissionCountModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'auditionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callbacksCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaRequestCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFormerClient' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'submissionsCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllTalentReport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AllTalentSubmissionModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvFileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'includeFormerClient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'castingCompanyInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mediaStorageStatus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AllTalentReportSubmission' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RepsAllTalentReportQuery, RepsAllTalentReportQueryVariables>;
export const RepsSingleTalentReportDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RepsSingleTalentReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReportById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SingleTalentReport' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleTalentReport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TalentSubmissionModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateTo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'castingCompanyInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mediaStorageStatus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'submissionDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ageRange' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'castingDirector' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roleName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mediaRequest' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RepsSingleTalentReportQuery, RepsSingleTalentReportQueryVariables>;
export const SaveDraftMediaRequestDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveDraftMediaRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SaveDraftMediaRequestArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveDraftMediaRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'includeSides' } },
                { kind: 'Field', name: { kind: 'Name', value: 'talentInstructions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageForRepresentatives' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxNumberOfMediaFiles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mediaTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shouldResend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'talentInstructionsUpdatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageForRepresentativesUpdatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allowAdditionalMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submissionIds' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveDraftMediaRequestMutation, SaveDraftMediaRequestMutationVariables>;
export const SaveDraftAskQuestionRequestDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveDraftAskQuestionRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SaveDraftAskQuestionRequestArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveDraftAskQuestionRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'includeSides' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageForRepresentatives' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shouldResend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submissionIds' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveDraftAskQuestionRequestMutation, SaveDraftAskQuestionRequestMutationVariables>;
export const DeleteRequestDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRequestMutation, DeleteRequestMutationVariables>;
export const ResetSessionObserverUrlDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetSessionObserverUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResetSessionObserverUrlInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetSessionObserverUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualAudition' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'joinUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'observerUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetSessionObserverUrlMutation, ResetSessionObserverUrlMutationVariables>;
export const RestoreArchivedMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RestoreArchivedMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RestoreArchivedMediaInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreArchivedMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestoreArchivedMediaMutation, RestoreArchivedMediaMutationVariables>;
export const ResumeSubscriptionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResumeSubscriptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumeSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>;
export const PreCancelDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'preCancel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PreCancelInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preCancel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreCancelQuery, PreCancelQueryVariables>;
export const GetRoleTrackerStatsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRoleTrackerStats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'timeframe' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'interval' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleTrackerStats' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timeframe' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'timeframe' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'interval' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'interval' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Stats' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Stats' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stats' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleTrackerStatsPeriod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
                { kind: 'Field', name: { kind: 'Name', value: 'submitted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'underConsideration' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRoleTrackerStatsQuery, GetRoleTrackerStatsQueryVariables>;
export const RoleTrackerDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RoleTracker' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleTrackerFiltersArgs' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortOptions' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleTrackerOrderOption' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleTracker' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortOptions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortOptions' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleTrackerData' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleTrackerProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projectName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleTrackerRole' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Role' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleTrackerProject' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RoleTrackerData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RoleTracker' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastStatusUpdated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roleTrackerStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RoleTrackerRole' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoleTrackerQuery, RoleTrackerQueryVariables>;
export const PublishRolesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageToReps' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageToTalents' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roleIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageToReps' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'messageToReps' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageToTalents' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'messageToTalents' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublishRolesMutation, PublishRolesMutationVariables>;
export const SearchSchedulesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchSchedules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageNum' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
          defaultValue: { kind: 'IntValue', value: '100' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchSchedules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'Variable', name: { kind: 'Name', value: 'projectId' } }],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageNum' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageSize' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'schedules' },
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastUpdated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCanceled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduleType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualAudition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'joinUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'observerUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'confirmed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'declined' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rescheduleRequested' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchSchedulesQuery, SearchSchedulesQueryVariables>;
export const ScheduleDetailDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'scheduleDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduleDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scheduleId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualAudition' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'joinUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'observerUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScheduleDetailQuery, ScheduleDetailQueryVariables>;
export const ArtistMediaCountDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArtistMediaCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchArtistMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'artistId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'includeActive' },
                            value: { kind: 'EnumValue', value: 'ALL' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'includeType' },
                            value: { kind: 'EnumValue', value: 'ALL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistMediaCtx' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionMediaEntitlement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnlimited' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistMediaCtx' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Artist' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionMediaUsage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'available' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entitle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArtistMediaCountQuery, ArtistMediaCountQueryVariables>;
export const SearchArtistMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchArtistMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchArtistMediaInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchArtistMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ArtistMediaCtx' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaResult' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionMediaEntitlement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnlimited' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaProfileMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentOrganization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaBaseResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archivableOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnSubmissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appearOnMediaRequests' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaProfileMedia' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaTransformation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArtistMediaCtx' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Artist' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionMediaUsage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'available' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entitle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountArtistMediaEntitlement' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchMediaResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SearchMediaBaseResult' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaThumbnail' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByOrg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displaySize' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moderationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purpose' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchArtistMediaQuery, SearchArtistMediaQueryVariables>;
export const SelectProfilesFromRosterDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SelectProfilesFromRoster' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectProfilesFromRoster' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectProfilesFromRosterMutation, SelectProfilesFromRosterMutationVariables>;
export const ImportPresentationFromAuditionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportPresentationFromAudition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'auditionIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isLive' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'presentationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importPresentationFromAudition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'auditionIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'auditionIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isLive' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isLive' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'presentationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'presentationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImportPresentationFromAuditionMutation, ImportPresentationFromAuditionMutationVariables>;
export const AuditionListCountDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuditionListCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditionSessionsByProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionListCountQuery, AuditionListCountQueryVariables>;
export const AuditionListDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuditionList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectIdPresentations' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortInstruction' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionSessionSortInstructionInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuditionSessionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditionSessionsByProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortInstruction' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortInstruction' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPresentationsByProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectIdPresentations' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditionListQuery, AuditionListQueryVariables>;
export const CreateShareTalentSelectsPresentationDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateShareTalentSelectsPresentation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareTalentPresentationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createShareTalentPresentation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateShareTalentSelectsPresentationMutation,
  CreateShareTalentSelectsPresentationMutationVariables
>;
export const ShareTalentSelectsProjectRolesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShareTalentSelectsProjectRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleStatusFilter' },
                value: { kind: 'EnumValue', value: 'Active' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShareTalentSelectsProjectRolesQuery, ShareTalentSelectsProjectRolesQueryVariables>;
export const AcceptProfileInviteDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AcceptProfileInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accessToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProfileByAccessToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accessToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'profileAccessTokenCreated' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptProfileInviteQuery, AcceptProfileInviteQueryVariables>;
export const SubmitAcceptProfileTokenDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubmitAcceptProfileToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accessToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProfileByAccessToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accessToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'profileAccessTokenCreated' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitAcceptProfileTokenQuery, SubmitAcceptProfileTokenQueryVariables>;
export const CreateAccountDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isEmailVerificationRequired' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'audience' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isEmailVerificationRequired' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isEmailVerificationRequired' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'audience' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'audience' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'refresh' } },
                { kind: 'Field', name: { kind: 'Name', value: 'access' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAccountMutation, CreateAccountMutationVariables>;
export const SendEmailVerificationCodeDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendEmailVerificationCode' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sendAccountEmailVerificationCode' } }],
      },
    },
  ],
} as unknown as DocumentNode<SendEmailVerificationCodeMutation, SendEmailVerificationCodeMutationVariables>;
export const GetSkillsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSkills' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SkillWithParentAndChildren' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkillWithParentAndChildren' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Skill' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childObjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childObjects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSkillsQuery, GetSkillsQueryVariables>;
export const GetSkillLevelsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSkillLevels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skillLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SkillLevel' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkillLevel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SkillLevel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSkillLevelsQuery, GetSkillLevelsQueryVariables>;
export const GetProfileWithSkillsDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfileWithSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'ListValue', values: [{ kind: 'Variable', name: { kind: 'Name', value: 'ids' } }] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'artistId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SkillOnProfile' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mediaAssets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetOnProfile' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkillOnProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileSkill' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileSkillId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentObject' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'level' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetOnProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileMediaId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProfileWithSkillsQuery, GetProfileWithSkillsQueryVariables>;
export const AddProfileSkillDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddProfileSkill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skillIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProfileSkill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skillIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skillIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddProfileSkillMutation, AddProfileSkillMutationVariables>;
export const AttachMediaToProfileSkillDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AttachMediaToProfileSkill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileSkillId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigInt' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkPayment' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachMediaToProfileSkill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileSkillId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileSkillId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkPayment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkPayment' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachMediaToProfileSkillMutation, AttachMediaToProfileSkillMutationVariables>;
export const UpdateProfileSkillDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfileSkill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileSkillInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileSkill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileSkillMutation, UpdateProfileSkillMutationVariables>;
export const DeleteProfileSkillDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProfileSkill' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileSkillId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigInt' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProfileSkill' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileSkillId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileSkillId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProfileSkillMutation, DeleteProfileSkillMutationVariables>;
export const UpdateSubmissionSelectionDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSubmissionSelection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmissionUpdateSelectionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubmissionSelection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedSubmission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubmissionSelectionFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'otherSubmissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubmissionSelectionFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserContextBaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaAssetWithThumbnail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserContextBaseMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubmissionSelectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Submission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'otherSubmissionSelectionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaAssetWithThumbnail' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileMainDivision' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileSchemaValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSubmissionSelectionMutation, UpdateSubmissionSelectionMutationVariables>;
export const MoveSubmissionsToRoleDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveSubmissionsToRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'submissionIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigInt' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tileSchemaValueCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'submissionStatusCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmissionStatusCodeEnum' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isWorksheet' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'submission' },
            name: { kind: 'Name', value: 'moveSubmissionsToRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'submissionIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'submissionIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tileSchemaValueCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tileSchemaValueCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roleId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'submissionStatusCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'submissionStatusCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'isWorksheet' } },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roleId' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'isWorksheet' } },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorksheetSubmission' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'isWorksheet' } },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStorageStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoverMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseMedia' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMainOrganizationAndMainDivision' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileMainDivision' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorksheetSubmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Submission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOverscale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverMedia' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'picksCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'requestedMediaCount' },
            name: { kind: 'Name', value: 'mediaCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requested' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tileSchemaValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workingLocation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overscaleNote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOverscaled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPersonal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRepresented' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cover' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoverMedia' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingLocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortOrder' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creditsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastNote' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noteModifier' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'talentUnions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentObject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMainOrganizationAndMainDivision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAuditionHistory' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoveSubmissionsToRoleMutation, MoveSubmissionsToRoleMutationVariables>;
export const TalentScoutContactTalentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TalentScoutContactTalent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTalentScoutHistoryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTalentScoutHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutProfileContactHistory' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfileContactHistory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'contactHistory' },
            name: { kind: 'Name', value: 'contactedByRep' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TalentScoutContactTalentMutation, TalentScoutContactTalentMutationVariables>;
export const TalentScoutCorrespondenceHistoryDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TalentScoutCorrespondenceHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTalentScoutHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'stageName' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TalentScoutCorrespondenceHistoryQuery, TalentScoutCorrespondenceHistoryQueryVariables>;
export const TalentScoutSearchProfilesDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TalentScoutSearchProfiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileSearchInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortOptions' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileSearchSortOption' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talentScoutSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortOptions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortOptions' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutProfile' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'facets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buckets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardIndicators' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAudioReel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasResume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasVideoReel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardMediaTransformation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaTransformation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startOffset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xAxis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yAxis' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardMedia' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Media' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMediaTransformation' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transformation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMediaTransformation' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsHeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Height' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_FEET_INCHES' } },
          { kind: 'Field', name: { kind: 'Name', value: 'METRIC_CENTIMETERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInImperialUI' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardHeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Height' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMeasurementsHeight' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardTalentUnions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TalentProfileUnion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'talentMembershipId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentObject' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileMeasurementsWeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Weight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'IMPERIAL_LBS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kgRounded' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lbs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showInMetricUI' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardWeight' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Weight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProfileMeasurementsWeight' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardWorkingLocations' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProfileWorkingLocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseballCardProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardIndicators' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardMedia' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'height' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardHeight' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stageName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talentUnions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardTalentUnions' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardWeight' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workingLocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardWorkingLocations' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfileContactHistory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'contactHistory' },
            name: { kind: 'Name', value: 'contactedByRep' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfilePitchNotes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noteToRepresentative' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutSeekingRepresentation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeekingRepresentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfileSeekingRepresentation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'seekingRepresentation' },
            name: { kind: 'Name', value: 'seekingRepresentations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutSeekingRepresentation' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TalentScoutProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Profile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseballCardProfile' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutProfileContactHistory' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutProfilePitchNotes' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TalentScoutProfileSeekingRepresentation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TalentScoutSearchProfilesQuery, TalentScoutSearchProfilesQueryVariables>;
export const UnlockMediaDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnlockMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unlockMedia' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mediaIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnlockMediaMutation, UnlockMediaMutationVariables>;
export const UpdateProfileMediaVisibilityDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProfileMediaVisibility' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'profileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileMediaVisibility' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'profileIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileMediaVisibilityMutation, UpdateProfileMediaVisibilityMutationVariables>;
