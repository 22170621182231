import React, { FC } from 'react';
import styled from 'styled-components';
import { BasicContainer } from '../Basic';
import { DivProps } from '../types';

type StyledRibbonProps = DivProps & { $offset: number; $direction?: 'left' | 'right' };

const StyledRibbon = styled(BasicContainer)<StyledRibbonProps>`
  &::before {
    content: '';
    position: absolute;
    ${({ $direction }) => ($direction === 'right' ? `right: 0;` : '')}
    ${({ $direction }) => ($direction === 'left' ? `left: 0;` : '')}
    bottom: ${({ $offset }) => `${$offset}rem`};
    width: 0;
    height: 0;
    border-left: ${({ $direction, $offset, theme, bgColor }) =>
      $direction === 'right'
        ? `${-0.5 * $offset}rem solid ${theme.designSystem.bgColors[bgColor]}`
        : `${-0.5 * $offset}rem solid transparent`};
    border-right: ${({ $direction, $offset, theme, bgColor }) =>
      $direction === 'left'
        ? `${-0.5 * $offset}rem solid ${theme.designSystem.bgColors[bgColor]}`
        : `${-0.5 * $offset}rem solid transparent`};
    border-bottom: ${({ $offset }) => `${-0.5 * $offset}rem solid transparent`};
    border-top: ${({ $offset, bgColor, theme }) =>
      `${-0.5 * $offset}rem solid ${theme.designSystem.bgColors[bgColor]}`};
    filter: brightness(80%);
  }
`;

type RibbonSizes = 'sm';

type RibbonDirections = 'left' | 'right';

export type RibbonProps = Omit<DivProps, 'right' | 'left' | 'customBordersRadius'> & {
  size?: RibbonSizes;
  direction?: RibbonDirections;
};

const SIZE_OFFSETS: Record<RibbonSizes, number> = {
  sm: -0.5,
};

const BORDER_RADIUS_OFFSETS: Record<RibbonSizes, number> = {
  sm: 0.3,
};

export const Ribbon: FC<RibbonProps> = ({ children, size = 'sm', direction = 'right', ...props }) => {
  const offset = SIZE_OFFSETS[size] ?? SIZE_OFFSETS.sm;
  const borderRadius = BORDER_RADIUS_OFFSETS[size] ?? BORDER_RADIUS_OFFSETS.sm;

  return (
    <StyledRibbon
      $offset={offset}
      $direction={direction}
      className="cn_absolute cn_shadow-md cn_atom_px-4 cn_atom_py-2"
      {...props}
      right={direction === 'right' ? offset : undefined}
      left={direction === 'left' ? offset : undefined}
      customBordersRadius={{
        bottomLeft: direction === 'right' ? borderRadius : 0,
        topLeft: direction === 'right' ? borderRadius : 0,
        bottomRight: direction === 'left' ? borderRadius : 0,
        topRight: direction === 'left' ? borderRadius : 0,
      }}
    >
      {children}
    </StyledRibbon>
  );
};
